import { Component, Input, OnInit } from '@angular/core';
import { PricingBundle } from 'src/app/pages/pricing/interfaces/pricing-bundle.interface';
import { SelectBundleService } from '../../services/select-bundle.service';
import { Router } from '@angular/router';
import { SubscriptionPeriod } from 'src/app/shared/interfaces/SubscriptionPeriod.enum';

@Component({
  selector: 'app-new-bundle-card',
  templateUrl: './new-bundle-card.component.html',
  styleUrls: ['./new-bundle-card.component.scss'],
})
export class NewBundleCardComponent implements OnInit {
  @Input() bundle: PricingBundle;
  @Input() type: SubscriptionPeriod;
  ceil = Math.ceil;

  SubscriptionPeriod = SubscriptionPeriod;

  constructor(private _bundleService: SelectBundleService, private _router: Router) {}

  ngOnInit(): void {}

  public addToCart(bundle) {
    switch (this.type) {
      case SubscriptionPeriod.monthly:
        this._bundleService.bundleList = this._bundleService.monthlyBundleList;
        break;
      case SubscriptionPeriod.yearly:
        this._bundleService.bundleList = this._bundleService.yearlyBundleList;
        break;
      default:
        break;
    }

    this._router.navigate(['purchase-tokens/' + bundle.id], { queryParams: { ...this._bundleService.selectedBundle } });
  }
}
