import { Component, OnInit } from '@angular/core';
import { PricingBundle } from '../pricing/interfaces/pricing-bundle.interface';
import { defaultResellerBundles } from '../pricing/default-readls-bundles/default-readls-bundles.constants';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/services/auth/auth.service';
import { get, sortBy, isEmpty } from 'lodash';
import { User } from 'src/app/shared/models/user.model';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { RoleService } from 'src/app/services/roles/role.service';
import { GlobalConfigurationHelper } from 'src/app/services/utils/global-configuration-helper';

@Component({
  selector: 'app-programs-pricing',
  templateUrl: './programs-pricing.component.html',
  styleUrls: ['./programs-pricing.component.scss'],
})
export class ProgramsPricingComponent implements OnInit {
  public show: 'regular' | 'b2c' | 'outsider';

  public isPatron = false;
  public isOutsider = false;
  public isB2cPortal = false;
  public resellerTokens = 0;
  public resellerEmail = '';
  public user: User;
  public singlePurchasePrice;

  public outsiderBundles: PricingBundle[] = [
    {
      id: '2',
      title: 'Single.',
      price: 1275,
      studentsIncluded: 1,
      hiddenPrice: false,
      currency: 'USD',
    },
    {
      id: '3',
      title: 'Trio.',
      price: 3300,
      studentsIncluded: 3,
      hiddenPrice: false,
      currency: 'USD',
    },
    {
      id: '4',
      title: 'Pro.',
      price: 0,
      hiddenPrice: true,
      message:
        '<span>Please email us at <a href="mailto:aspiremg.ls@gmail.com">aspiremg.ls@gmail.com</a> for special pricing and discounts for educators and mentors.</span>',
      currency: 'USD',
    },
    {
      id: '4',
      title: 'First purchase',
      price: 250,
      currency: 'USD',
      studentsIncluded: 2,
      isFirstPurchaseBundle: true,
    },
  ];

  public bundles: PricingBundle[];

  public features: string[] = [
    'Web Based',
    'Access to admin portal',
    'Online Training',
    'Option to pick levels',
    'Placement Test',
    'Option to customize flow',
    '40+ hours of engaging research-based games',
    'Create organization managers',
    'Progress Graph',
    'Import Lists',
  ];

  public showInfo = false;
  private replaceRegex = /##replace##/g;
  public message =
    '<span>Please email us at <a href="mailto:##replace##">##replace##</a> for special pricing and discounts for educators and mentors.</span>';
  public checkIcon: IconDefinition = faCheck;

  constructor(
    public _authService: AuthService,
    public _rest: RestAPIService,
    private _logger: LoggerService,
    private _roles: RoleService,
    private globalConfiguration: GlobalConfigurationHelper,
  ) {}

  async ngOnInit() {
    this.user = this._roles.user;
    const organization =
      this.user.organization || (await this._rest.get('organization/self', { msg: 'Could not get organization.' }));
    this.isOutsider = get(organization, 'isOutsider', get(organization, 'organization.isOutsider', false));
    this.isPatron = this._roles.isPatron();
    this.isB2cPortal = this._roles.isB2CPortal();

    this._setShow();

    if (this.isOutsider && !this.isB2cPortal) {
      const resellerEmail = get(this.user, 'belongsTo.email', get(this._authService.getOrgAcc(), 'belongsTo.email'));
      this.message = this.message.replace(this.replaceRegex, resellerEmail);
      await this.resellerCredits();
      await this.getOutsiderBundles();
    }

    if (organization && !this.isOutsider && !this.isPatron) {
      const regularBundles = await this._rest.get(
        'bundle/regularBundles/' + get(organization, 'id', get(organization, 'organization.id')),
        { msg: 'Could not get bundle/regularBundles/:organization.' },
      );

      if (!regularBundles) {
        return;
      }

      this.bundles = (await this.globalConfiguration.getProgramBundles()) || defaultResellerBundles;

      regularBundles.bundles.forEach((b) => {
        this.bundles.splice(b.order, 1, b);
      });
    }

    if (this.isB2cPortal) {
      const provider = get(this.user, 'patron.orgId');
      const providerOrg = await this._rest.get('organization/findById/' + provider, {
        msg: 'Could not get organization/findById/:provider.',
      });

      this.message = this.message.replace(this.replaceRegex, providerOrg.organization.email);
    }

    this.showInfo = true;
  }

  private _setShow() {
    if (!this.isB2cPortal && !this.isOutsider) {
      this.show = 'regular';
    } else if (this.isB2cPortal) {
      this.show = 'b2c';
    } else {
      this.show = 'outsider';
    }
  }

  async resellerCredits() {
    try {
      const resellerId = this.user?.id;
      const { tokens } = await this._rest.get(`token/${resellerId}`, {
        msg: 'Could not get token/:resellerId.',
      });

      this.resellerEmail = get(this.user, 'belongsTo.email', get(this._authService.getOrgAcc(), 'belongsTo.email'));

      this.resellerTokens = tokens.filter((t) => !t.studentId && t.paymentConfirmed).length;

      this.resellerTokens -= tokens.filter((t) => t.studentId && !t.paymentConfirmed).length;
    } catch (err) {
      this._logger.error(err);
    }
  }

  async getOutsiderBundles(orgId?: string) {
    let resellerId: string;

    if (orgId) {
      resellerId = orgId;
    } else {
      resellerId = get(this.user, 'belongsTo.id', get(this._authService.getOrgAcc(), 'belongsTo.id'));
    }

    const bundlesResponse = await this._rest.get(`bundle/${resellerId}`, {
      msg: 'Could not get bundle/:resellerId.',
    });
    const { bundles } = bundlesResponse;

    let defaultBundles = bundles.filter((b) => isEmpty(b.belongsTo));
    const outsiderBundles = bundles.filter((b) => b.belongsTo === this.user.id);

    defaultBundles.forEach((e) => {
      this.outsiderBundles.splice(e.order, 1, e);
    });
    defaultBundles = this.outsiderBundles;
    defaultBundles = sortBy(defaultBundles, 'order');

    if (outsiderBundles.length > 0) {
      outsiderBundles.forEach((e) => {
        defaultBundles.splice(e.order, 1, e);
      });
    }

    defaultBundles = defaultBundles.filter((b: PricingBundle) =>
      this.user.firstTokenPurchased ? !b.isFirstPurchaseBundle : b.isFirstPurchaseBundle,
    );

    this.outsiderBundles = defaultBundles;
  }
}
