import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-confimation-modal',
  templateUrl: './confimation-modal.component.html',
  styleUrls: ['./confimation-modal.component.scss'],
})
export class ConfirmationModalComponent implements OnInit {
  public readonly closeIcon: IconDefinition = faTimesCircle;

  public closeButtonRoute = '';
  public titleInput;
  public textInput;
  public confirmButton;
  public cancelButton;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConfirmationModalComponent>,
    public route: Router,
  ) {}

  ngOnInit() {
    this.initialize();
  }

  private initialize() {
    this.titleInput = this.data.titleInput;
    this.textInput = this.data.textInput;
    this.confirmButton = this.data.confirmInput;
    this.cancelButton = this.data.cancelInput;

    if (this.data.closeButtonRoute && this.data.closeButtonRoute !== '') {
      this.closeButtonRoute = this.data.closeButtonRoute;
    }
  }

  public confirm() {
    this.dialogRef.close(true);
  }

  public cancel() {
    this.dialogRef.close(false);
  }

  public closeRoute() {
    this.dialogRef.close(true);
    this.route.navigate([this.closeButtonRoute]);
  }
}
