import { Injectable } from '@angular/core';
import { RestAPIService } from '../rest/rest-api.service';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionRequestService {
  constructor(private _restService: RestAPIService) {}

  public async checkIfUserHaveSubscriptions(): Promise<boolean> {
    const checkForSubscriptions = await this._restService.get('subscriptions/checkIfUserHaveSubscription', {
      msg: 'Could check if the user have subscriptions.',
    });

    if (!checkForSubscriptions) return false;

    return checkForSubscriptions.accountHasSubscriptions;
  }
}
