import { Component } from '@angular/core';
import { CoursesService } from '../../../services/courses/courses.service';

@Component({
  selector: 'learning-section-course-completion',
  templateUrl: './course-completion.component.html',
  styleUrls: ['./course-completion.component.scss'],
})
export class CourseCompletionComponent {
  public course$ = this._courses.currentCourse$;

  constructor(private _courses: CoursesService) {}
}
