<h2 mat-dialog-title>Are you sure?</h2>
<mat-dialog-content
  >When you override the student level, their first placement assessment will be
  deleted and they will be set to the new level of your choice. Are you sure you
  want to continue?
</mat-dialog-content>
<mat-dialog-actions>
  <button
    mat-button
    mat-dialog-close
    mat-dialog-close="true"
  >
    Yes, please override.
  </button>
  <button
    mat-button
    mat-dialog-close
    mat-dialog-close="false"
  >
    Cancel
  </button>
</mat-dialog-actions>
