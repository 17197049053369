<app-main-layout
  [breadcrumbs]="[
    { name: 'Home', url: '/' },
    { name: 'Subscription control center', url: '/subscription-control-center' }
  ]"
>
  <div
    *ngIf="pageLoading"
    class="pageLoading"
  >
    <mat-spinner></mat-spinner>
  </div>

  <div
    class="noSubscriptionWarning"
    *ngIf="
      resellerSubscriptions.length === 0 &&
      regularSubscriptions.length === 0 &&
      !loading
    "
  >
    Currently, there is no active subscription
  </div>

  <div
    class="loading d-flex justify-content-center align-items-center"
    *ngIf="loading"
  >
    <div>
      <fa-icon
        class="regularIcon"
        [spin]="true"
        [icon]="loadingIcon"
      ></fa-icon>
    </div>
  </div>

  <div class="col-7 px-0 d-flex justify-content-center optionMenu">
    <div
      class="d-flex my-3"
      *ngIf="
        resellerSubscriptions.length > 0 && regularSubscriptions.length > 0
      "
    >
      <div
        class="mx-2 clickable"
        (click)="selectedOption = subscriptionMenuOptions.outsiderSubscriptions"
        [ngClass]="{
          chosenOption:
            selectedOption === subscriptionMenuOptions.outsiderSubscriptions
        }"
      >
        Outsiders subscriptions
      </div>

      <div
        class="mx-2 clickable"
        (click)="selectedOption = subscriptionMenuOptions.mySubscriptions"
        [ngClass]="{
          chosenOption:
            selectedOption === subscriptionMenuOptions.mySubscriptions
        }"
      >
        My subscriptions
      </div>
    </div>
  </div>

  <div
    class="mainContainer col-10 px-0 resellerControlCenter m-auto"
    *ngIf="
      isReseller &&
      selectedOption === subscriptionMenuOptions.outsiderSubscriptions &&
      resellerSubscriptions.length > 0 &&
      !loading
    "
  >
    <app-reseller-subscriptions
      [resellerSubscriptions]="resellerSubscriptions"
      (startLoading)="startLoading($event)"
    >
    </app-reseller-subscriptions>
  </div>

  <div
    class="mainContainer col-10 px-0 regularOrgControlCenter m-auto"
    *ngIf="
      selectedOption === subscriptionMenuOptions.mySubscriptions &&
      regularSubscriptions.length > 0 &&
      !loading
    "
  >
    <app-non-reseller-subscriptions
      [regularSubscriptions]="regularSubscriptions"
      (startLoading)="startLoading($event)"
    >
    </app-non-reseller-subscriptions>
  </div>
</app-main-layout>
