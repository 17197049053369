import { Component, ViewChild } from '@angular/core';
import { StudentsListComponent } from './students-list/students-list.component';

@Component({
  selector: 'users-clients-menu',
  templateUrl: './clients-menu.component.html',
  styleUrls: ['./clients-menu.component.scss'],
})
export class ClientsMenuComponent {
  // For this use case we can use the StudentsListComponent
  // to call a public method on it to get students whenever
  // ClientListComponent outputs a getStudents event
  @ViewChild(StudentsListComponent) studentsComponent: StudentsListComponent;

  constructor() {}

  public async handleGetStudents(id: string): Promise<void> {
    this.studentsComponent.getStudents(id);
  }
}
