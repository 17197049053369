<div class="flex-container">
  <quiz-choice-list-item
    *ngFor="let choice of choices; let i = index"
    [choice]="choice"
    [selected]="choice === selectedAnswer"
    (multiAnswerCheck)="checkMultiAnswerChoice($event)"
    (choiceSelected)="onChoiceSelected($event, i)"
  >
  </quiz-choice-list-item>
</div>
