export enum Roles {
  Owner = 'Owner',
  PortalOwner = 'Portal Owner',
  OrgOwner = 'Organization Owner',
  OrgAdmin = 'Organization Admin',
  OrgManager = 'Organization Manager',
  B2C = 'B2C',
  B2B = 'B2B',
  B2CProvider = 'B2C Provider',
  Reseller = 'Reseller',
  Patron = 'Patron',
  Client = 'Client',
  Organization = 'Organization',
  Student = 'Student',
}
