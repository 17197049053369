import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RestAPIService } from '../../../services/rest/rest-api.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RegisterFormComponent } from './register.component';
import { MaterialComponentsModule } from '../../modules/material-components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutComponentsModule } from '../layout/layout-components.module';
import { FooterModule } from '../footer/footer.module';
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';

@NgModule({
  declarations: [RegisterFormComponent],
  imports: [
    BrowserModule,
    RouterModule,
    FontAwesomeModule,
    MaterialComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutComponentsModule,
    FooterModule,
    NgxGpAutocompleteModule,
  ],
  providers: [RestAPIService],
  exports: [RegisterFormComponent],
})
export class RegisterFormModule {}
