import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { User } from 'src/app/shared/models';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root',
})
export class PasswordUpdatedGuard {
  constructor(private _authService: AuthService, private router: Router) {}

  async canActivate() {
    const user: User = await this._authService.getUser();

    if (user && user.passwordUpdated) {
      return true;
    } else if (user && !user.passwordUpdated) {
      this.router.navigate(['/register'], { queryParams: { action: 'Password' } });
      return false;
    }

    return false;
  }
}
