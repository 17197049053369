export function applyFilter(event: Event, dataSource) {
  if (!event?.target) return;

  const filterValue = (event.target as HTMLInputElement).value;
  const normalizedFilter = filterValue.trim().toLowerCase();

  dataSource.filterPredicate = (data, filter: string): boolean => {
    if (!data || !filter) return false;

    return Object.keys(data)
      .filter((key) => typeof data[key] === 'string')
      .some((key) => data[key]?.toLowerCase().includes(filter));
  };

  dataSource.filter = normalizedFilter;
  return dataSource;
}
