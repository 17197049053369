import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { PortalTypeService } from './portal-type.service';
import { RestAPIService } from '../../services/rest/rest-api.service';
import { includes, get } from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { OrgFinderComponent } from '../../shared/dialogs/org-finder-dialog/org-finder.dialog.component';
import { HttpParams } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class PortalTypeGuard {
  constructor(
    private _portalService: PortalTypeService,
    private _restService: RestAPIService,
    private _authService: AuthService,
    private dialog: MatDialog,
  ) {}

  async canActivate(): Promise<boolean> {
    let hostname = '';

    if (includes(window.location.href, '?auth=true&from=')) {
      hostname = window.location.href.split(`${environment.ROOT_DOMAIN_URL}/?auth=true&from=`)[1];
    } else if (includes(window.location.href, '?signup=true&from=')) {
      hostname = window.location.href.split(`${environment.ROOT_DOMAIN_URL}/?signup=true&from=`)[1];
    } else {
      hostname = window.location.hostname.split('.' + environment.ROOT_DOMAIN_URL)[0];
    }

    // use the portal service to check if the hostname are on our regularPortalHosts array
    const host = this._portalService.regularPortalHosts.find((h) => h === hostname);

    // if it is , the user is not accessing the portal via an org url
    if (host) {
      this._portalService.setOrgPortal(false);
    } else {
      // if the hostname is not on our regularPortalHosts array it means the user is accessing the portal via an org url
      // So , we set up the envinronment for an org portal
      const response = await this._restService.get('organization/' + hostname, {
        params: new HttpParams().set('errorType', 'hidden'),
        msg: 'Check if the organization name is correct on the url.',
      });

      if (get(response, 'organization')) {
        this._portalService.setOrgPortal(true);
        this._portalService.setOrgName(response.organization.name);
        this._portalService.setPortalOwner(response.organization);
        this._authService.setOrgSubdomain(response.organization.subdomain);
      } else {
        if (this._authService.isAuthenticated()) {
          await this._authService.getUser();

          const organizationAccount = this._authService.getOrgAcc();

          const subdomain: string = get(organizationAccount, 'organization.subdomain');

          if (includes(this._portalService.regularPortalHosts, hostname) === false && subdomain !== hostname) {
            const { protocol } = window.location;
            window.location.href = `${protocol}//${subdomain}.${environment.ROOT_DOMAIN_URL}`;
          }
        } else {
          this.dialog.open(OrgFinderComponent, {
            width: '600px',
            height: '400px',
            disableClose: true,
          });
        }
      }
    }

    return true;
  }
}
