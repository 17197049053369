import { NgModule } from '@angular/core';
import { CourseOverviewPageComponent } from './course-overview.component';
import { CommonModule } from '@angular/common';
import { LayoutsModule } from 'src/app/shared/layouts/layouts.module';
import { LayoutComponentsModule } from 'src/app/shared/components/layout/layout-components.module';
import { DescriptionComponent } from './description/description.component';
import { GotoCourseButtonComponent } from './goto-course-button/goto-course-button.component';
import { GridComponent } from './grid/grid.component';
import { TitleComponent } from './title/title.component';
import { CoursesSharedModule } from '../../shared/courses.shared.module';
import { CurtainModule } from 'src/app/shared/components/curtain/curtain.module';
import { SectionItemComponent } from './section-item/section-item.component';
import { LessonItemComponent } from './lesson-item/lesson-item.component';

@NgModule({
  declarations: [
    CourseOverviewPageComponent,
    DescriptionComponent,
    GotoCourseButtonComponent,
    GridComponent,
    TitleComponent,
    SectionItemComponent,
    LessonItemComponent,
  ],
  imports: [CommonModule, LayoutsModule, LayoutComponentsModule, CoursesSharedModule, CurtainModule],
})
export class CourseOverviewPageModule {}
