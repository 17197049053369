<div class="mainContent">
  <div class="header text-center">Add the theme information</div>

  <div class="name mt-4 col-10 px-0 mx-auto text-center">
    <div>
      <mat-form-field appearance="outline">
        <mat-label>Theme name</mat-label>
        <input
          [(ngModel)]="themeName"
          matInput
          type="text"
          placeholder="Theme name"
        />
      </mat-form-field>
    </div>
  </div>

  <div
    class="enabledLanguages my-3 col-10 px-0 mx-auto"
    *ngIf="data"
  >
    <div class="subtitle text-center">Languages</div>

    <table
      mat-table
      [dataSource]="langDataSource"
      class="col-12 px-0"
      multiTemplateDataRows
    >
      <ng-container matColumnDef="name">
        <th
          mat-header-cell
          *matHeaderCellDef
        >
          Name
        </th>
        <td
          mat-cell
          *matCellDef="let lang"
        >
          {{ lang.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="code">
        <th
          mat-header-cell
          *matHeaderCellDef
        >
          Code
        </th>
        <td
          mat-cell
          *matCellDef="let lang"
        >
          {{ lang.languageCode }}
        </td>
      </ng-container>

      <ng-container matColumnDef="enabled">
        <th
          class="text-center"
          mat-header-cell
          *matHeaderCellDef
        >
          Enabled
        </th>
        <td
          mat-cell
          *matCellDef="let lang"
          class="text-center"
        >
          <mat-checkbox (click)="addLanguage(lang)"> </mat-checkbox>
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="langDisplayedColumns"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: langDisplayedColumns"
        class="example-element-row"
      ></tr>
    </table>
  </div>

  <div class="enabledLevels text-center my-4 col-10 px-0 mx-auto">
    <span class="subtitle">Enabled levels</span> <br />

    <mat-accordion multi>
      <mat-expansion-panel
        *ngFor="let languages of enabledLanguages"
        class="my-1"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ returnLanguageLabel(languages.languageId) }}
          </mat-panel-title>
          <mat-panel-description>
            Expand to see the enabled levels
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="levels my-1">
          <div class="pb-3 pt-2 px-1">
            <div *ngFor="let levels of languages.enabledLevels; let i = index">
              <mat-checkbox [(ngModel)]="levels.enabled"
                >{{ levels.level }}
              </mat-checkbox>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <div
      class="warning"
      *ngIf="enabledLanguages.length === 0"
    >
      You dont have any active languages. Add a language to pick the enabled
      levels for it
    </div>
  </div>

  <div class="categories my-3 text-center col-10 px-0 m-auto">
    <span class="subtitle">Enabled categories</span> <br />

    <table
      class="col-12"
      mat-table
      [dataSource]="categoriesDataSource"
      multiTemplateDataRows
    >
      <ng-container matColumnDef="name">
        <th
          class="text-center"
          mat-header-cell
          *matHeaderCellDef
        >
          Name
        </th>
        <td
          mat-cell
          *matCellDef="let category"
        >
          {{ category.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="enabled">
        <th
          class="text-center"
          mat-header-cell
          *matHeaderCellDef
        >
          Enabled
        </th>
        <td
          mat-cell
          *matCellDef="let category"
        >
          <mat-checkbox (click)="addNewCategory(category)"> </mat-checkbox>
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="categoriesDisplayedColumns"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: categoriesDisplayedColumns"
      ></tr>
    </table>
  </div>

  <div class="buttons d-flex mt-3 justify-content-center">
    <app-simple-button
      class="mx-3"
      [text]="'SAVE'"
      (click)="createTheme()"
    >
    </app-simple-button>

    <app-simple-button
      class="mx-3"
      [text]="'CANCEL'"
      (click)="closeDialog()"
    >
    </app-simple-button>
  </div>
</div>
