import { Component, Input, OnChanges } from '@angular/core';

import {
  IconDefinition,
  faVideo,
  faFileLines,
  faCircleQuestion,
  faFilePowerpoint,
} from '@fortawesome/free-solid-svg-icons';
import { LessonType } from '../../utils/LessonType.type';

@Component({
  selector: 'courses-shared-lesson-type-icon',
  templateUrl: './lesson-type-icon.component.html',
  styleUrls: ['./lesson-type-icon.component.scss'],
})
export class LessonTypeIconComponent implements OnChanges {
  @Input() type: LessonType;
  @Input() remSize = 1;

  public icon: IconDefinition;
  public tooltip: string;

  public readonly video: IconDefinition = faVideo;
  public readonly text: IconDefinition = faFileLines;
  public readonly quiz: IconDefinition = faCircleQuestion;
  public readonly slides: IconDefinition = faFilePowerpoint;

  ngOnChanges(): void {
    if (this.type) {
      this._selectIcon();
      this._setupTooltip();
    }
  }

  private _selectIcon(): void {
    switch (this.type) {
      case 'text':
        this.icon = this.text;
        break;
      case 'video':
        this.icon = this.video;
        break;
      case 'quiz':
        this.icon = this.quiz;
        break;
      case 'slides':
        this.icon = this.slides;
        break;
    }
  }

  private _setupTooltip() {
    this.tooltip = this.type.charAt(0).toUpperCase() + this.type.slice(1) + ' lesson';
  }
}
