import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

interface PublicMessage {
  enabled: boolean;
  text: string;
}

const defaultPublicMessage: PublicMessage = {
  enabled: false,
  text: '',
};

@Injectable({
  providedIn: 'root',
})
export class PublicMessageBannerService {
  private publicMessage = new BehaviorSubject(defaultPublicMessage);
  public publicMessage$ = this.publicMessage.asObservable();

  constructor(private _http: HttpClient) {
    this.refresh();
  }

  refresh() {
    this._http.get<PublicMessage>(`${environment.API_URL}/globalConfig/publicWarningMessage`).subscribe((result) => {
      this.publicMessage.next({ enabled: result.enabled, text: result.text });
    });
  }

  public update(msg: { password: string; text: string; enabled: boolean }) {
    return this._http
      .put<PublicMessage>(`${environment.API_URL}/globalConfig/updatePublicWarningMessage`, msg)
      .subscribe((result) => {
        if (result) {
          this.refresh();
        }
      });
  }
}
