<div class="dateChangerHeader">
  Click to change the date of the {{data.title}} session
</div>
<div class="content my-3">
  <div mat-dialog-content>
    <mat-form-field appearance="fill">
      <mat-label>Choose a date</mat-label>
      <input
        matInput
        [matDatepicker]="picker"
        [(ngModel)]="programDate"
        [min]="today"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="picker"
      ></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>
</div>

<div class="buttons">
  <button
    mat-raised-button
    (click)="submit()"
  >
    Submit
  </button>
</div>
