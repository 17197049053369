<ng-container *ngIf="course">
  <div class="wrapper">
    <header>
      <courses-list-card-title
        [title]="course?.title"
      ></courses-list-card-title>

      <courses-list-card-lesson-count
        [count]="course?.numberOfLessons"
      ></courses-list-card-lesson-count>

      <courses-progress-bar
        [percent]="course?.percentOfCourseComplete"
      ></courses-progress-bar>
    </header>

    <nav>
      <courses-list-card-button
        [courseId]="course?.id"
        label="See Overview"
        goto="overview"
      ></courses-list-card-button>

      <courses-list-card-button
        [courseId]="course?.id"
        [label]="course?.hasCourseStarted ? 'Resume Course' : 'Start Course'"
        goto="course"
      ></courses-list-card-button>
    </nav>
  </div>

  <courses-list-card-image [image]="course?.image"></courses-list-card-image>
</ng-container>
