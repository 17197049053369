export const colors = {
  cognitiveTherapy: {
    primary: '#ad2121',
  },
  readingExercises: {
    primary: '#1e90ff',
  },
  speedReading: {
    primary: '#e3bc08',
  },
};
