<div class="mainContent">
  <div class="header">Transfer outsiders</div>

  <div class="content">
    <div class="p-3 body">
      Choose the organization you want to import the outsiders
    </div>

    <div>
      <select
        required
        class="basicInput basicSelectable mx-auto"
        [(ngModel)]="orgId"
        aria-placeholder="Select the organization"
      >
        <option
          class="disabledOption"
          value=""
          disabled
          selected
        >
          Choose the organization
        </option>
        <option
          class="p-2"
          *ngFor="let org of orgList"
          [value]="org.id"
          autocomplete="off"
        >
          {{ org.name }}
        </option>
      </select>
    </div>

    <div
      class="mt-5"
      *ngIf="!transferingOutsiders"
    >
      <app-simple-button
        class="mx-2"
        [text]="'Confirm'"
        (click)="confirm()"
      >
      </app-simple-button>

      <app-simple-button
        class="mx-2"
        [text]="'Cancel'"
        (click)="close()"
      >
      </app-simple-button>
    </div>

    <div
      *ngIf="transferingOutsiders"
      class="mt-5"
    >
      <div class="d-flex justify-content-center align-items-center">
        <fa-icon
          class="mx-2"
          [icon]="spinnerIcon"
          [spin]="true"
        >
        </fa-icon>

        <div>Please wait, we're transfering the outsiders.</div>
      </div>
    </div>
  </div>
</div>
