import { Component, Input } from '@angular/core';
import { CourseFEM } from '../../../services/courses/courses.service.models';

/**
 * @scope Displays a small card that shows a course title, image and progress indicator.
 */
@Component({
  selector: 'courses-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {
  @Input() course: CourseFEM;
}
