import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { faArrowTurnDown, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { OrdersByProvince } from 'src/app/shared/interfaces/classwallet.interface';
import { ShopifyControllerGetOrders200ResponseCompletedOrdersInner } from 'src/app/core/openapi';

enum DateOptions {
  IN_THE_LAST = 'In the last',
  IS_EQUAL_TO = 'Is equal to',
  IS_BETWEEN = 'Is between',
  IS_AFTER = 'Is after',
  IS_BEFORE = 'Is before',
}

enum MomentTimeOptions {
  HOURS = 'hours',
  DAYS = 'days',
  MONTHS = 'months',
}

@Component({
  selector: 'app-cw-date-filter',
  templateUrl: './cw-date-filter.component.html',
  styleUrl: './cw-date-filter.component.scss',
})
export class CwDateFilterComponent implements OnInit {
  constructor() {}

  public options = [
    DateOptions.IN_THE_LAST,
    DateOptions.IS_EQUAL_TO,
    DateOptions.IS_BETWEEN,
    DateOptions.IS_AFTER,
    DateOptions.IS_BEFORE,
  ];

  public momentTimeOptions = [MomentTimeOptions.HOURS, MomentTimeOptions.DAYS, MomentTimeOptions.MONTHS];

  @Input() dateField: 'createdAt' | 'approvedAt' = 'createdAt';
  @Input() activeList: OrdersByProvince[] = [];

  public selectedOption: DateOptions = DateOptions.IN_THE_LAST;
  public readonly arrowDown: IconDefinition = faArrowTurnDown;

  public datePickerRange = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  public exactDate: Date;

  public daysSelectedOption: moment.DurationInputArg2 = MomentTimeOptions.DAYS;
  public timeSelectInput: moment.DurationInputArg1 = 0;

  public filterIsActive: boolean = false;

  public originalTable: OrdersByProvince[] = [];

  public removedContent: OrdersByProvince[] = [];

  ngOnInit(): void {}

  public applyFilter() {
    this.resetFilter(false);

    switch (this.selectedOption) {
      case DateOptions.IN_THE_LAST:
        this.filterByTime(this.timeSelectInput, this.daysSelectedOption);
        break;
      case DateOptions.IS_BETWEEN:
        this.filterByDateRange();
        break;
      case DateOptions.IS_EQUAL_TO:
        this.filterByExactDate(this.exactDate);
        break;
      case DateOptions.IS_AFTER:
        this.filterByDateAfter(this.exactDate);
        break;
      case DateOptions.IS_BEFORE:
        this.filterByDateBefore(this.exactDate);
        break;
      default:
        break;
    }

    this.filterIsActive = true;
  }

  public addRemovedItems(
    orders: ShopifyControllerGetOrders200ResponseCompletedOrdersInner[],
    filteredValue: ShopifyControllerGetOrders200ResponseCompletedOrdersInner[],
    province: string,
  ) {
    if (orders.length === filteredValue.length) {
      return;
    }

    const removedItems = orders.filter((item) => !filteredValue.includes(item));

    const newEntry: OrdersByProvince = {
      orders: removedItems,
      province: province,
      dataSource: null,
    };

    this.removedContent.push(newEntry);
  }

  public resetFilter(fullReset: boolean = true) {
    for (const item of this.activeList) {
      const removedcontent = this.removedContent.find((c) => c.province === item.province);

      if (!removedcontent) {
        continue;
      }

      const dataSource = item.dataSource;
      const newData = [...dataSource.data, ...removedcontent.orders];
      newData.sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt)));

      dataSource.data = newData;
    }

    if (fullReset) {
      this.exactDate = undefined;
      this.datePickerRange.reset();
      this.filterIsActive = false;
    }

    this.removedContent = [];
  }

  public getRemovedContent(province: string) {
    const removedcontent = this.removedContent.find((c) => c.province === province);

    if (!removedcontent) {
      return [];
    }

    return removedcontent.orders;
  }

  public onSelectionChange() {
    this.exactDate = undefined;
    this.datePickerRange.reset();
    this.daysSelectedOption = MomentTimeOptions.DAYS;
  }

  public shouldDisableBtn() {
    switch (this.selectedOption) {
      case DateOptions.IN_THE_LAST:
        return (
          Number(this.timeSelectInput) <= 0 ||
          (this.timeSelectInput === undefined && this.daysSelectedOption === undefined)
        );
      case DateOptions.IS_BETWEEN:
        return !this.datePickerRange.controls.end.value || !this.datePickerRange.controls.start.value;
      case DateOptions.IS_EQUAL_TO:
        return this.exactDate === undefined;
      case DateOptions.IS_AFTER:
        return this.exactDate === undefined;
      case DateOptions.IS_BEFORE:
        return this.exactDate === undefined;
      default:
        return true;
    }
  }

  // FILTER METHODS

  public filterByDateRange() {
    for (const item of this.activeList) {
      const dataSource = item.dataSource;

      const itemsToSearch = [...dataSource.data, ...this.getRemovedContent(item.province)];

      const filteredValue = itemsToSearch.filter((item) => {
        const date = moment(item[this.dateField]).startOf('day').format('ddd MMM DD YYYY 00:00:00');

        return (
          ((moment(date).isBefore(this.datePickerRange.controls.end.value) ||
            moment(date).isSame(this.datePickerRange.controls.end.value)) &&
            moment(date).isAfter(this.datePickerRange.controls.start.value)) ||
          moment(date).isSame(this.datePickerRange.controls.start.value)
        );
      });

      this.addRemovedItems(dataSource.data, filteredValue, item.province);

      dataSource.data = filteredValue;
    }
  }

  public filterByTime(timeAmount: moment.DurationInputArg1, time: moment.DurationInputArg2) {
    const dateToConsider = moment().subtract(timeAmount, time);

    if (time !== MomentTimeOptions.HOURS) {
      dateToConsider.startOf('day').format('ddd MMM DD YYYY 00:00:00');
    }

    for (const item of this.activeList) {
      const dataSource = item.dataSource;

      const itemsToSearch = [...dataSource.data, ...this.getRemovedContent(item.province)];
      const filteredValue = itemsToSearch.filter((item) => {
        const date = moment(item[this.dateField]).startOf('day').format('ddd MMM DD YYYY 00:00:00');
        return moment(date).isAfter(dateToConsider) || moment(date).isSame(dateToConsider);
      });

      this.addRemovedItems(dataSource.data, filteredValue, item.province);

      dataSource.data = filteredValue;
    }
  }

  public filterByExactDate(date: Date) {
    if (!date) {
      return;
    }

    const selectedDate = moment(date).startOf('day').format('ddd MMM DD YYYY 00:00:00');

    for (const item of this.activeList) {
      const dataSource = item.dataSource;
      const itemsToSearch = [...dataSource.data, ...this.getRemovedContent(item.province)];
      const filteredValue = itemsToSearch.filter((item) => {
        const date = moment(item[this.dateField]).startOf('day').format('ddd MMM DD YYYY 00:00:00');
        return moment(date).isSame(selectedDate);
      });

      this.addRemovedItems(dataSource.data, filteredValue, item.province);

      dataSource.data = filteredValue;
    }
  }

  public filterByDateAfter(date: Date) {
    if (!date) {
      return;
    }

    const selectedDate = moment(date).startOf('day').format('ddd MMM DD YYYY 00:00:00');

    for (const item of this.activeList) {
      const dataSource = item.dataSource;
      const itemsToSearch = [...dataSource.data, ...this.getRemovedContent(item.province)];
      const filteredValue = itemsToSearch.filter((item) => {
        const date = moment(item[this.dateField]).startOf('day').format('ddd MMM DD YYYY 00:00:00');
        return moment(date).isAfter(selectedDate);
      });

      this.addRemovedItems(dataSource.data, filteredValue, item.province);

      dataSource.data = filteredValue;
    }
  }

  public filterByDateBefore(date: Date) {
    if (!date) {
      return;
    }

    const selectedDate = moment(date).startOf('day').format('ddd MMM DD YYYY 00:00:00');

    for (const item of this.activeList) {
      const dataSource = item.dataSource;
      const itemsToSearch = [...dataSource.data, ...this.getRemovedContent(item.province)];

      const filteredValue = itemsToSearch.filter((item) => {
        const date = moment(item[this.dateField]).startOf('day').format('ddd MMM DD YYYY 00:00:00');
        return moment(date).isBefore(selectedDate);
      });

      this.addRemovedItems(dataSource.data, filteredValue, item.province);

      dataSource.data = filteredValue;
    }
  }
}
