import { Tutorials } from 'src/app/shared/components/tutorial-modal/tutorial-modal.constants';

export const b2cTutorialList = [
  {
    section: 'Students',
    tutorials: [Tutorials.ACTIVE_STUDENTS, Tutorials.ADD_STUDENTS],
  },
  {
    section: 'Programs',
    tutorials: [Tutorials.PROGRAMS, Tutorials.READLS],
  },
  {
    section: 'Demo',
    tutorials: [Tutorials.DEMO],
  },
  {
    section: 'Tutorials',
    tutorials: [Tutorials.TRAINING],
  },
];

export const clientTutorialList = [
  {
    section: 'Students',
    tutorials: [Tutorials.ACTIVE_STUDENTS_CLIENT],
  },
  {
    section: 'Programs',
    tutorials: [Tutorials.READLS_CLIENT],
  },
  {
    section: 'Demo',
    tutorials: [Tutorials.DEMO],
  },
  {
    section: 'Training',
    tutorials: [Tutorials.TRAINING],
  },
];

export const organizationTutorialList = [
  {
    section: 'Students',
    tutorials: [Tutorials.ACTIVE_USERS, Tutorials.ADD_STUDENT_ORG],
  },
  {
    section: 'Programs',
    tutorials: [Tutorials.PURCHASING_CREDITS_ORG, Tutorials.NEURALIGN_ORG],
  },
  {
    section: 'Demo',
    tutorials: [Tutorials.DEMO_ORG],
  },
  {
    section: 'Training',
    tutorials: [Tutorials.TRAINING_ORG],
  },
];

export const tutorialList = {
  B2C: b2cTutorialList,
  'Organization Owner': organizationTutorialList,
  Client: clientTutorialList,
};
