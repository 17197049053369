<ng-container *ngIf="(sideMenu.view | async) === 'mobile'">
  <div class="mobile">
    <div
      class="sidemenu"
      [@mobileOpenClose]="
        (sideMenu.preferences | async).isOpen ? 'open' : 'closed'
      "
    >
      <app-side-menu *ngIf="sideMenu.canShow | async"></app-side-menu>
    </div>

    <div
      class="content"
      *ngIf="!(sideMenu.preferences | async).isOpen"
    >
      <header *ngIf="sideMenu.canShow | async">
        <fa-icon
          [icon]="menuIcon"
          (click)="mobileToggleSideMenu()"
          style="cursor: pointer"
        >
        </fa-icon>
        <div class="logo">
          <img
            src="assets/img/NeurAlign text logo.png"
            width="100%"
          />
        </div>
      </header>

      <app-header *ngIf="showHeader"></app-header>

      <app-breadcrumb
        *ngIf="breadcrumbs && showBreadcrumbs"
        [menuItems]="breadcrumbs"
      ></app-breadcrumb>

      <main>
        <ng-container *ngTemplateOutlet="contentTmpl"></ng-container>
      </main>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="(sideMenu.view | async) === 'desktop'">
  <div class="desktop">
    <div
      class="sidemenu"
      [@openClose]="(sideMenu.preferences | async).isOpen ? 'open' : 'closed'"
      *ngIf="sideMenu.canShow | async"
    >
      <app-side-menu></app-side-menu>
    </div>

    <app-header *ngIf="showHeader"></app-header>

    <app-breadcrumb
      *ngIf="breadcrumbs && showBreadcrumbs"
      [menuItems]="breadcrumbs"
    ></app-breadcrumb>

    <main>
      <ng-container *ngTemplateOutlet="contentTmpl"></ng-container>
    </main>
  </div>
</ng-container>

<!-- Templates -->

<ng-template #contentTmpl>
  <ng-content></ng-content>
</ng-template>
