<div class="main">
  <div class="container">
    <div class="title">
      <h2 *ngIf="data.type == 'create'">Create Client</h2>
      <h2 *ngIf="data.type == 'update'">Update Client</h2>
    </div>

    <div class="profile-photo-container">
      <div
        *ngIf="clientForm.image"
        class="profile-photo-preview"
      >
        <img
          [src]="clientForm.image || './assets/img/StudentImagePlaceholder.png'"
          alt="Student photo preview"
          class="preview-image"
          (click)="addClientImage()"
        />
      </div>
      <div
        *ngIf="!clientForm.image"
        class="profile-photo-button"
      >
        <button (click)="addClientImage()">
          <fa-icon [icon]="camera"></fa-icon>
        </button>
      </div>
    </div>

    <form
      #form="ngForm"
      novalidate
    >
      <div class="input-block">
        <label for="clientForm.fullname">Full Name <span>*</span></label>
        <input
          type="text"
          name="clientForm.fullname"
          [(ngModel)]="clientForm.fullname"
          required
          #fullname="ngModel"
          fullname
          [ngClass]="{
            'is-invalid':
              fullname.invalid && (fullname.dirty || fullname.touched)
          }"
        />
        <div
          *ngIf="fullname.invalid && (fullname.dirty || fullname.touched)"
          class="invalid-feedback"
        >
          <div *ngIf="fullname.errors?.required">The fullname is required.</div>
        </div>
      </div>

      <div class="input-block">
        <label for="clientForm.nickname">Nickname</label>
        <input
          type="text"
          name="clientForm.nickname"
          [(ngModel)]="clientForm.nickname"
        />
      </div>

      <div class="input-block">
        <label for="clientForm.email">Email <span>*</span></label>
        <input
          type="email"
          name="clientForm.email"
          [(ngModel)]="clientForm.email"
          required
          #email="ngModel"
          email
          [ngClass]="{
            'is-invalid': email.invalid && (email.dirty || email.touched)
          }"
        />
        <div
          *ngIf="email.invalid && (email.dirty || email.touched)"
          class="invalid-feedback"
        >
          <div *ngIf="email.errors?.required">The email is required.</div>
          <div *ngIf="email.errors?.email">The Email is invalid.</div>
        </div>
      </div>
      <div class="button-group">
        <button
          *ngIf="!stateSave"
          class="btn-secondary"
          (click)="close()"
        >
          Close
        </button>
        <button
          [disabled]="!form.valid"
          *ngIf="!stateSave"
          class="btn-primary"
          (click)="save()"
        >
          Save
        </button>
        <div
          *ngIf="stateSave"
          class="spinner-container"
        >
          <div class="d-flex justify-content-center align-items-center">
            <fa-icon
              class="mx-2"
              [icon]="spinnerIcon"
              [spin]="true"
            >
            </fa-icon>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
