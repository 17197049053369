import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { exportTypes } from 'src/app/shared/consts/global-constants';
import { FormControl, FormGroup } from '@angular/forms';
import { ExcelService } from 'src/app/services/excel/excel.service';

@Component({
  selector: 'app-exports',
  templateUrl: './exports.component.html',
  styleUrls: ['./exports.component.scss'],
})
export class ExportsComponent implements OnInit {
  @Output() setLoading = new EventEmitter();

  public exportType;
  public selectedProgressType: string;
  public report;
  public date = new Date();
  public studentsOptList;
  public progressQuery = new FormGroup({
    formFilterType: new FormControl(),
    formFilterValue: new FormControl(),
    formFilterStart: new FormControl<Date | null>(null),
    formFilterEnd: new FormControl<Date | null>(null),
  });
  public studentQuery = new FormGroup({
    formFilterType: new FormControl(),
    formFilterValue: new FormControl(),
    formFilterStart: new FormControl<Date | null>(null),
    formFilterEnd: new FormControl<Date | null>(null),
  });

  constructor(private _rest: RestAPIService, private excel: ExcelService) {}

  async ngOnInit() {
    await this.getStudentsOptList();
  }

  private async getStudentsOptList() {
    this.studentsOptList = await this._rest.get('admin/report/students/optionslist', {
      msg: 'Could not get options list',
    });
  }

  public async exportData(exportType, progressType?) {
    this.setLoading.emit(true);

    if (exportType === exportTypes.USER) {
      const { users } = await this._rest.get('admin/users', { msg: 'Could not get admin/users' });
      if (!users) {
        this.setLoading.emit(false);
        return;
      }
      this.report = users.map((user) => {
        const { id, email, createdAt } = user;
        let userType;
        let name;

        if (user.patron) {
          userType = 'Patron';
          name = user.patron.givenName + ' ' + user.patron.familyName;
        } else if (user.organization) {
          userType = 'Organization';
          name = user.organization.name;
        }
        return {
          id,
          name,
          userType,
          email,
          creationDate: new Date(createdAt).toLocaleDateString(),
        };
      });
    } else if (exportType === exportTypes.PROGRESS) {
      const formData = this.progressQuery.getRawValue();

      const filterStart = formData.formFilterStart.toISOString() ?? '';
      const filterEnd = formData.formFilterEnd.toISOString() ?? '';
      // @todo: Convert range to query params
      const url = `/admin/report/gameTag/${progressType}/startDate/${filterStart}/endDate/${filterEnd}`;
      const progress = await this._rest.get(url, {
        msg: 'Could not get progress/' + progressType,
      });

      if (!progress) {
        this.setLoading.emit(false);
        return;
      }
      this.report = progress;
    }

    this.setLoading.emit(false);

    this.exportAsXLSX(exportType, progressType);
  }

  public async reportStudents() {
    this.setLoading.emit(true);

    const formData = this.studentQuery.getRawValue();

    const filterType = formData.formFilterType ? formData.formFilterType : '';
    const filterValue = formData.formFilterValue ? formData.formFilterValue : '';
    const filterStart = formData.formFilterStart ? formData.formFilterStart.toISOString() : '';
    const filterEnd = formData.formFilterEnd ? formData.formFilterEnd.toISOString() : '';

    const studentsReport = await this._rest.get(
      'admin/report/students?filterType=' +
        filterType +
        '&filterValue=' +
        filterValue +
        '&filterStart=' +
        filterStart +
        '&filterEnd=' +
        filterEnd,
      {
        msg: 'Could not get students report',
      },
    );

    this.report = studentsReport;

    this.setLoading.emit(false);

    this.exportAsXLSX('students');
  }

  public exportAsXLSX(exportType: string, progressType?: string): void {
    let reportName;

    if (exportType === 'user') {
      reportName = 'User Report - ';
    } else if (exportType === 'progress') {
      reportName = 'Progress Report - ' + progressType;
    } else if (exportType === 'students') {
      reportName = 'Students Report - ';
    }

    this.excel.exportAsExcelFile(this.report, reportName + '_' + this.date.toLocaleDateString());
  }
}
