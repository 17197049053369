import { Component, OnInit } from '@angular/core';
import { PricingBundle } from '../../../pricing/interfaces/pricing-bundle.interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SelectBundleService } from '../../services/select-bundle.service';
import { SubscriptionPeriod } from 'src/app/shared/interfaces/SubscriptionPeriod.enum';
import { GlobalConfigurationHelper } from 'src/app/services/utils/global-configuration-helper';

@Component({
  selector: 'pricing-regular-org',
  templateUrl: './regular-org-pricing.component.html',
  styleUrls: ['./regular-org-pricing.component.scss'],
})
export class RegularOrgPricingComponent implements OnInit {
  activeTab: SubscriptionPeriod = SubscriptionPeriod.monthly;

  bundles: PricingBundle[];

  singleBundle: PricingBundle;

  subscriptionBundles: PricingBundle[];

  basicBundle: PricingBundle;
  mostPopularBundle: PricingBundle;
  bestValueBundle: PricingBundle;

  userIsBuyingForFirstTime = !this._auth.user.firstTokenPurchased;
  firstPurchaseBundle: PricingBundle | null = null;

  SubscriptionPeriod = SubscriptionPeriod;

  public loading = true;

  public defaultFirstPurchaseBundle = {
    title: 'First purchase',
    price: 250,
    fullPrice: 250,
    currency: 'CAD',
    studentsIncluded: 2,
    isFirstPurchaseBundle: true,
  };

  constructor(
    private _auth: AuthService,
    private bundleService: SelectBundleService,
    private _globalConfig: GlobalConfigurationHelper,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadAllBundles();
    this.loadSingleBundle();
    this.loadSubscriptionBundles();
    this.loadfirstPurchaseBundle();
    this.loading = false;
  }

  private async loadAllBundles() {
    this.bundles = await this._globalConfig.getProgramBundles();
  }

  private loadSingleBundle() {
    // filter to get the option that is not subscription, even if you receive more than one option , use the first
    this.singleBundle = this.bundles.filter((b) => !b.subscription)[0];
  }

  private loadSubscriptionBundles() {
    // filter to get the options that are subscriptions, and only show three of those options
    this.subscriptionBundles = this.bundles.filter((b) => b.subscription).slice(0, 3);

    this.bundleService.monthlyBundleList = this.subscriptionBundles;

    this.bundleService.yearlyBundleList = this.subscriptionBundles.map((b) => {
      return {
        ...b,
        price: b.yearlyPrice,
        studentsIncluded: b.studentsIncluded * 12,
        interval: 'year',
      };
    });
  }

  private loadfirstPurchaseBundle() {
    // if the user hasn't completed the first purchase, show only the first time purchase bundle
    if (this.userIsBuyingForFirstTime) {
      this.firstPurchaseBundle = this.bundles.filter((b) => b.isFirstPurchaseBundle)[0];

      if (!this.firstPurchaseBundle) {
        this.firstPurchaseBundle = this.defaultFirstPurchaseBundle;
      }
    }
  }

  handleTabSwitch(tab: SubscriptionPeriod) {
    switch (tab) {
      case SubscriptionPeriod.yearly:
        this.activeTab = SubscriptionPeriod.yearly;
        break;
      case SubscriptionPeriod.monthly:
        this.activeTab = SubscriptionPeriod.monthly;
        break;

      default:
        break;
    }
  }
}
