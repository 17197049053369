<ng-container *ngIf="lessons$ | async as lessons">
  <div class="list">
    <div *ngFor="let lesson of lessons">
      <lessons-list-lessons-header
        [section]="lesson"
      ></lessons-list-lessons-header>

      <div class="wrapper">
        <div *ngFor="let section of lesson.sections">
          <section-item [section]="section"></section-item>
        </div>
      </div>
    </div>
  </div>
</ng-container>
