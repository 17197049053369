import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { IconDefinition, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'back-to-portal-button',
  templateUrl: './back-to-portal-button.component.html',
  styleUrls: ['./back-to-portal-button.component.scss'],
})
export class BackToPortalButtonComponent {
  constructor(private _router: Router) {}

  public backIcon: IconDefinition = faArrowLeft;

  public onBackToPortal() {
    this._router.navigate(['/courses'], { queryParams: { reload: true } });
  }
}
