<app-public-layout>
  <div
    class="content-block"
    *ngFor="let bio of biographyList"
    id="{{ bio.id }}"
  >
    <div
      fxLayout="row wrap"
      fxLayout.lt-md="column wrap"
      fxLayoutWrap
      fxLayoutGap="3%"
      fxLayoutAlign="center top"
    >
      <div
        class="left-side"
        fxFlex="25"
        fxFlex.lt-md="100"
      >
        <img src="./assets/img/team/{{ bio.imageUrl }}" />
      </div>

      <div
        class="bioText"
        fxFlex="65"
        fxFlex.lt-md="100"
        fxFlexFill
      >
        <p class="name">{{ bio.name }}</p>
        <p class="jobTitle">{{ bio.jobTitle }}</p>
        <p *ngFor="let paragraph of bio.body">{{ paragraph }}</p>
        <a (click)="goTo('about-us')">&lt;&lt; Back</a>
      </div>
    </div>
    <div class="clear-both"></div>
    <hr
      size="2"
      align="center"
    />
  </div>
</app-public-layout>
