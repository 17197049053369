import { Injectable } from '@angular/core';
import { BehaviorSubject, find, map, mergeAll, Observable } from 'rxjs';
import { CourseFEM, LessonFEM, SectionFEM, defaultLessons, defaultSections } from '../courses/courses.service.models';
import { LessonEnum } from '../../utils/LessonType.type';
import { QuizService } from '../quiz/quiz.service';
import { SlidesService } from '../slides/slides.service';
import { ProgressService } from '../progress/progress.service';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { MatDialog } from '@angular/material/dialog';
import { CourseCompletionDialog } from '../../utils/course-completion-dialog/course-completion-dialog.component';
import { CourseProgress } from 'src/app/pages/configuration-pages/interfaces/global-config.interfaces';
import { flatten } from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LessonsService {
  // DATA --
  private sectionsSubject = new BehaviorSubject<SectionFEM[]>(defaultSections);
  public sections$ = this.sectionsSubject.asObservable();

  private lessonsSubject = new BehaviorSubject<LessonFEM[]>(defaultLessons);
  public lessons$ = this.lessonsSubject.asObservable();

  private currentLessonSubject = new BehaviorSubject<LessonFEM>(defaultLessons[0]);
  public currentLesson$ = this.currentLessonSubject.asObservable();

  private currentSectionSubject = new BehaviorSubject<SectionFEM>(defaultSections[0]);
  public currentSection$ = this.currentSectionSubject.asObservable();

  public selectedCourse: CourseFEM = null;

  isSectionLast(): Observable<boolean> {
    return this.currentSection$.pipe(map((x) => x.isCompleted));
  }

  // INIT --

  constructor(
    private _quiz: QuizService,
    private _slides: SlidesService,
    private _progress: ProgressService,
    private _rest: RestAPIService,
    private _dialog: MatDialog,
    private _router: Router,
    private _route: ActivatedRoute,
  ) {}

  // API --

  public loadLessons(course: CourseFEM) {
    this.selectedCourse = course;
    this.lessonsSubject.next(course.lessons);
    this.sectionsSubject.next(course.lessons.map((s) => s.sections).flat());
    this.currentLessonSubject.next(this.lessonsSubject.value[0]);
    this.selectSectionById(this.currentLessonSubject.value.sections[0].id);
  }

  public selectSectionById(id: string) {
    this.sections$
      .pipe(
        mergeAll(),
        find((section) => section.id === id),
      )
      .subscribe((section) => {
        const lessonIndex = this.lessonsSubject.value.findIndex((lesson) => lesson.id === section.belongsTo);
        const lesson = this.lessonsSubject.value[lessonIndex];
        this.currentLessonSubject.next(lesson);

        this.getSection(section);
      });
  }

  public async selectNextLesson(): Promise<void> {}

  public async saveProgress(progress) {
    await this._rest.put('courseProgress/update', progress);
  }

  public selectPrevLesson(): void {
    if (this.currentLessonSubject.value.isFirstInCourse && this.currentSectionSubject.value.isFirstInLesson) {
      return; // do nothing
    } else {
      // select prev lesson
      const prevSection = this.sectionsSubject.value[this.currentSectionSubject.value.index - 1];
      this.getSection(prevSection);
    }
  }

  public markCurrentLessonComplete() {
    this.currentLessonSubject.value.isCompleted = true;
    this._progress.markLessonComplete(this.currentLessonSubject.value);
  }

  // PRIVATE --

  private getSection(section: SectionFEM) {
    if (!section) {
      return;
    }

    if (section.isLastInLesson) {
      const selectedLesson = this.lessonsSubject.value.find((l) => l.id === section.belongsTo);
      this.currentLessonSubject.next(selectedLesson);
    }

    this.currentSectionSubject.next(section);

    if (section.type === LessonEnum.Slides) {
      this._slides.loadSlides(section);
    }
    if (section.type === LessonEnum.Quiz) {
      this._quiz.loadQuiz(section);
    }
  }

  public isLastSectionInLesson() {
    return this.currentSectionSubject.value.isLastInLesson;
  }

  public isLastLesson() {
    return this.currentLessonSubject.value.isLastInCourse;
  }

  private _goToNextLesson(currentLesson) {
    const nextLessonIndex = this.lessonsSubject.value.findIndex((lesson) => lesson.id === currentLesson.id) + 1;
    const nextLesson = this.lessonsSubject.value[nextLessonIndex];
    this.currentLessonSubject.next(nextLesson);
    const nextSection = this.sectionsSubject.value.find((section) => section.belongsTo === nextLesson.id);
    this.getSection(nextSection);
  }

  private _goToNextSection() {
    const nextSection = this.sectionsSubject.value[this.currentSectionSubject.value.index + 1];
    this.getSection(nextSection);
  }

  public async selectNext(): Promise<void> {
    const currentSection = this.currentSectionSubject.value;
    const currentLesson = this.currentLessonSubject.value;
    const currentCourseId = this.selectedCourse.id;
    const userProgresses = this._progress.getProgresses();
    const selectedProgress = userProgresses.find((progress) => progress.courseId === currentCourseId);
    const selectedLesson = selectedProgress.lessons.find((l) => l.lessonId === currentLesson.id);
    const selectedSection = selectedLesson.sections.find((s) => s.sectionId === currentSection.id);

    if (selectedSection) {
      selectedSection.completed = true;
    }
    if (currentSection) {
      currentSection.isCompleted = true;
    }

    this.saveProgress(selectedProgress);
    this.selectedCourse.percentOfCourseComplete = this.calculateCourseProgress();

    if (currentSection.isLastInLesson && currentLesson.isLastInCourse) {
      if (this.isCourseCompleted(selectedProgress)) {
        // complete course

        this._dialog.open(CourseCompletionDialog, {
          data: { courseName: this.selectedCourse.title },
          panelClass: 'modal-border',
          width: '400px',
          disableClose: true,
        });

        if (this.selectedCourse.hasCertificate && !selectedProgress.completed) {
          // send certificate
          await this._rest.post(
            '/course/certificate/course/' + currentCourseId + '/user/' + selectedProgress.accountId,
            {},
          );

          selectedProgress.certificateIssued = true;
        }

        selectedProgress.completed = true;
        this.saveProgress(selectedProgress);

        return;
      } else {
        // course is yet not completed
        this._router.navigate(['courses']);
        return;
      }
    } else if (currentSection.isLastInLesson) {
      return this._goToNextLesson(currentLesson);
    } else {
      this._goToNextSection();
    }
  }

  public isCourseCompleted(progress: CourseProgress): boolean {
    const booleanArray: boolean[] = flatten(progress.lessons.map((l) => l.sections.map((s) => s.completed)));

    return booleanArray.every((bool) => bool === true);
  }

  public calculateCourseProgress() {
    const totalSections = this.sectionsSubject.value.length;
    const completedSections = this.sectionsSubject.value.filter((section) => section.isCompleted).length;
    const percent = (completedSections / totalSections) * 100;
    return parseInt(percent.toFixed(0));
  }
}
