import { Component, OnInit } from '@angular/core';
import { IconDefinition, faShoppingCart, faCheck } from '@fortawesome/free-solid-svg-icons';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';

@Component({
  selector: 'app-new-bundle-dialog.component',
  templateUrl: './new-bundle-dialog.component.html',
  styleUrls: ['./new-bundle-dialog.component.scss'],
})
export class NewBundleDialog implements OnInit {
  public checkIcon: IconDefinition = faCheck;
  public bundleFormGroup: UntypedFormGroup;
  public cartIcon: IconDefinition = faShoppingCart;
  public amount: number;
  public id: string;
  public isGlobalBundle = false;
  public index: number;

  constructor(
    private _rest: RestAPIService,
    protected _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<NewBundleDialog>,
  ) {}

  ngOnInit() {
    this.bundleFormGroup = new UntypedFormGroup({
      title: new UntypedFormControl('', [Validators.required]),
      price: new UntypedFormControl(0, [Validators.required]),
      yearlyPrice: new UntypedFormControl(0, [Validators.required]),
      currency: new UntypedFormControl('CAD', [Validators.required]),
      studentsIncluded: new UntypedFormControl(0, [Validators.required]),
      subscription: new UntypedFormControl(false, [Validators.required]),
      isFirstPurchaseBundle: new UntypedFormControl(false, [Validators.required]),
    });
  }

  public async save() {
    const bundleToSave = this.bundleFormGroup.value;
    bundleToSave.isRegularBundle = true;
    bundleToSave.order = 0;

    await this._rest.post('globalConfig/bundles/create', bundleToSave);

    this.dialogRef.close(true);

    this._snackBar.open(`New price bundle created!`, 'Close', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  public closeDialog() {
    this.dialogRef.close();
  }
}
