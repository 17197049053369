import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { MessagesModalComponent } from '../../messages-modal/messages-modal.component';

@Component({
  selector: 'messages-add-button',
  templateUrl: './messages-header-dashboard-button.component.html',
  styleUrls: ['./messages-header-dashboard-button.component.scss'],
})
export class MessagesHeaderDashboardButtonComponent {
  @Output() public newMessage = new EventEmitter<any>();
  @Input() enable: boolean;

  public faPlus = faPlus;

  private readonly MODAL_WIDTH = '1000px';
  constructor(public dialog: MatDialog) {}

  public handleClick() {
    const dialog = this.dialog.open(MessagesModalComponent, {
      width: this.MODAL_WIDTH,
      disableClose: true,
      data: {},
    });

    dialog.afterClosed().subscribe(() => {
      if (dialog?.componentRef?.instance.data.id) {
        this.newMessage.emit(dialog.componentRef.instance.data);
      }
    });
  }
}
