import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GameComponent } from './game.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { savedGameDialogComponent } from './saved-game-dialog/saved-game-dialog.component';
import { LayoutComponentsModule } from '../layout/layout-components.module';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [GameComponent, savedGameDialogComponent],
  imports: [CommonModule, MatProgressBarModule, LayoutComponentsModule, TranslateModule.forChild(), FontAwesomeModule],
  exports: [GameComponent],
})
export class GameModule {}
