<section #box>
  <p
    montserrat
    thin
    mediumSize
    zeroMargin
    primaryColour
  >
    Students who used Neuralign read an <br />
    average of 50% more words in 10 weeks, <br />
    according to a report prepared for LSWorks <br />
    by the Center for Applied Cognitive Research <br />
    at the Department of Cognitive Science at <br />
    Carleton University.
  </p>

  <button
    primaryBtn
    smallSize
    (click)="goToReport()"
  >
    Read the Report
  </button>

  <p
    montserrat
    thin
    mediumSize
    zeroMargin
    primaryColour
  >
    Other data shows that 99% of students <br />
    improve by at least two grade levels after 12 <br />
    weeks.
  </p>
</section>

<div #bg>
  <img
    src="assets/img/brains/colourful-brain-right.webp"
    alt=""
    width="100%"
  />
</div>
