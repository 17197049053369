<app-main-layout [breadcrumbs]="[{ name: 'Messages', url: '/messages' }]">
  <app-message-header-dashboard
    [rows]="rows"
    (filterRows)="filterRows($event)"
    (newMessage)="newMessage($event)"
  >
  </app-message-header-dashboard>

  <body>
    <app-messages-table
      [columns]="['Source', 'Message', 'Created Date']"
      [rows]="filteredRows"
      (deletedMessage)="deletedMessage($event)"
      (updatedMessage)="updatedMessage($event)"
    >
    </app-messages-table>
  </body>
</app-main-layout>
