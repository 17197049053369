import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AboutUsComponent } from './about-us.component';
import { TextHighlightModule } from 'src/app/shared/components/highlight/highlight.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { LayoutsModule } from 'src/app/shared/layouts/layouts.module';

@NgModule({
  declarations: [AboutUsComponent],
  imports: [BrowserModule, TextHighlightModule, FlexLayoutModule, RouterModule, LayoutsModule],
})
export class AboutUsModule {}
