import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { MaterialComponentsModule } from 'src/app/shared/modules/material-components.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDividerModule } from '@angular/material/divider';
import { LayoutComponentsModule } from 'src/app/shared/components/layout/layout-components.module';
import { LayoutsModule } from 'src/app/shared/layouts/layouts.module';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { SafeUrlModule } from 'src/app/shared/pipes/safe-url/safe-url.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { ThemesComponent } from './components/themes/themes.component';
import { LanguagesComponent } from './components/languages/languages.component';
import { ThemeCreationDialogComponent } from './components/themes/theme-creation-dialog/theme-creation-dialog.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { UpdateLanguageDialog } from './components/languages/update-language-dialog/update-language-dialog';
import { NewCategoryDialog } from './components/categories/new-categories-dialog/new-categories-dialog';
import { BonusPackDialog } from './components/categories/bonus-pack-dialog/bonus-pack-dialog';
import { ContentConfigurationsComponent } from './content-configurations.component';

@NgModule({
  declarations: [
    ContentConfigurationsComponent,
    ThemesComponent,
    LanguagesComponent,
    ThemeCreationDialogComponent,
    CategoriesComponent,
    UpdateLanguageDialog,
    NewCategoryDialog,
    BonusPackDialog,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    HttpClientModule,
    MaterialComponentsModule,
    FontAwesomeModule,
    MatTooltipModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatTableModule,
    MatCheckboxModule,
    MatButtonModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatExpansionModule,
    LayoutComponentsModule,
    LayoutsModule,
    AngularEditorModule,
    SafeUrlModule,
    DragDropModule,
  ],
  providers: [RestAPIService],
})
export class ContentConfigurationsModule {}
