import { isEmpty, get } from 'lodash';

// To make it easier to map all the possible errors we use this validator and add all the errors to an array

export function validateTheme(theme) {
  const errors = [];

  if (isEmpty(get(theme, 'label.en_ca'))) {
    errors.push('The theme must have a name');
  }

  if (theme.languages.length < 1) {
    errors.push('The theme must have at least one enabled language');
  } else if (checkIfLanguagesHaveEnabledLevels(theme.languages)) {
    errors.push('You must enabled at lest one level for each enabled language');
  }

  if (checkIfCategoryHaveEnabledItems(theme.categories)) {
    errors.push('The theme must have at least one category enabled');
  }

  return {
    isValid: errors.length > 0 ? false : true,
    errors,
  };
}

const checkIfCategoryHaveEnabledItems = (categories): boolean => {
  const booleanArray = [];

  for (const category of categories) {
    booleanArray.push(category.enabled);
  }

  return booleanArray.every((e) => e === false);
};

const checkIfLanguagesHaveEnabledLevels = (languages): boolean => {
  const booleanArray = [];

  for (const language of languages) {
    for (const levels of language.enabledLevels) {
      booleanArray.push(levels.enabled);
    }
  }

  return booleanArray.every((e) => e === false);
};
