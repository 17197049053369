import { Component } from '@angular/core';
import { LessonsService } from '../../../services/lessons/lessons.service';

@Component({
  selector: 'learning-section-lessons-list',
  templateUrl: './lessons-list.component.html',
  styleUrls: ['./lessons-list.component.scss'],
})
export class LessonsListComponent {
  public lessons$ = this._lessons.lessons$;

  constructor(private _lessons: LessonsService) {}
}
