import { Component, Input } from '@angular/core';

@Component({
  selector: 'courses-list-card-lesson-count',
  templateUrl: './lesson-count.component.html',
  styleUrls: ['./lesson-count.component.scss'],
})
export class LessonCountComponent {
  @Input() count: number;
}
