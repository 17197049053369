import { Directive, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { styleElement } from '../../helpers/styleElement.function';
import { Styles } from '../../styles/lsw.styles';

@Directive({
  selector: '[outlineBtn]',
})
export class OutlineBtnDirective implements OnInit {
  constructor(private el: ElementRef, private _renderer: Renderer2) {}

  ngOnInit() {
    styleElement(this._renderer, this.el, {
      color: Styles.Colours.PRIMARY_COLOUR,
      backgroundColor: Styles.Colours.WHITE,
      fontFamily: Styles.Fonts.PRIMARY_FONT,
      fontSize: Styles.Buttons.DEFAULT_FONT_SIZE,
      padding: Styles.Buttons.DEFAULT_PADDING,
      borderRadius: Styles.Buttons.DEFAULT_BORDER_RADIUS,
      border: `${Styles.Buttons.DEFAULT_BORDER_WIDTH} solid ${Styles.Colours.PRIMARY_COLOUR}`,
      transition: Styles.Transitions.FADE,
    });
  }

  @HostListener('mouseenter') onMouseEnter() {
    styleElement(this._renderer, this.el, {
      color: Styles.Colours.WHITE,
      backgroundColor: Styles.Colours.PRIMARY_COLOUR,
    });
  }

  @HostListener('mouseleave') onMouseLeave() {
    styleElement(this._renderer, this.el, {
      color: Styles.Colours.PRIMARY_COLOUR,
      backgroundColor: Styles.Colours.WHITE,
    });
  }
}
