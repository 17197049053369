<h1
  class="title"
  mat-dialog-title
>
  Sorry, an error has occurred.
</h1>

<div mat-dialog-content>
  <div class="error-message">
    <p class="message">{{ UIerrorMessage }}</p>
    <p class="message">{{ APIerrorMessage }}</p>
  </div>

  <!-- Http errors have extra info in a dropdown -->
  <div class="http-error">
    <details *ngIf="stack || request || response">
      <summary>More details</summary>
      <ul>
        <li>{{ autoErrorMessage }}</li>
        <li *ngIf="request">
          <strong>Request:</strong>
          {{ request }}
        </li>
        <li *ngIf="response">
          <strong>Response:</strong>
          {{ response }}
        </li>
        <li *ngIf="body">
          <strong>Body:</strong>
          {{ body | json }}
        </li>
        <li *ngIf="stack">
          <strong>Stack:</strong>
          {{ stack }}
        </li>
      </ul>
    </details>
  </div>
</div>

<p class="user-message">
  Please try refreshing the page. <br />
  If the error persists,
  <span
    class="click-here"
    (click)="sendErrorEmail()"
    >click here to report it to us</span
  >.
</p>

<!-- Buttons -->
<div
  class="buttons"
  mat-dialog-actions
>
  <button
    mat-raised-button
    mat-dialog-close
    color="primary"
    (click)="refresh()"
  >
    Refresh Page
  </button>
  <button
    class="stroked"
    mat-button
    mat-dialog-close
    (click)="backToHome()"
  >
    Back To Home
  </button>
  <button
    *ngIf="isDevMode"
    mat-dialog-close
    mat-raised-button
    mat-dialog-close
    color="primary"
  >
    Close
  </button>
</div>
