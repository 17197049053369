import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LayoutsModule } from 'src/app/shared/layouts/layouts.module';
import { OrganizationSignupComplete } from './org-signup-completion.component';
import { MaterialComponentsModule } from 'src/app/shared/modules/material-components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutComponentsModule } from 'src/app/shared/components/layout/layout-components.module';
import { FooterModule } from 'src/app/shared/components/footer/footer.module';
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { TermsOfServiceModule } from '../terms-of-service/terms.module';
import { TermsOfServiceBoxComponent } from './terms-of-service-box/terms-of-service-box.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TextTransformModule } from 'src/app/shared/pipes/text-config/text-transform.module';

@NgModule({
  declarations: [OrganizationSignupComplete, TermsOfServiceBoxComponent],
  imports: [
    BrowserModule,
    RouterModule,
    FontAwesomeModule,
    LayoutsModule,
    MaterialComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutComponentsModule,
    FooterModule,
    NgxGpAutocompleteModule,
    TermsOfServiceModule,
    BrowserAnimationsModule,
    TextTransformModule,
  ],
  providers: [AuthService],
})
export class OrganizationUpdateModule {}
