import { Component, Input, OnInit } from '@angular/core';
import { VideoLessonContentFEM } from 'src/app/pages/courses/services/courses/courses.service.models';
import { EventService } from 'src/app/pages/courses/services/event/event.service';

@Component({
  selector: 'display-video-lesson',
  templateUrl: './video-lesson.component.html',
  styleUrls: ['./video-lesson.component.scss'],
})
export class VideoLessonComponent implements OnInit {
  @Input() content: VideoLessonContentFEM;

  constructor(private _events: EventService) {}

  ngOnInit() {
    this._events.emitIsReadyForNextLesson(true);
  }

  getVideoUrl() {
    if (this.content.url.includes('youtube')) {
      const searchPattern = /https?:\/\/(?:www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]{11})/;
      const replacementPattern = 'https://www.youtube.com/embed/$1';
      const newUrl: string = this.content.url.replace(searchPattern, replacementPattern);

      return newUrl;
    } else {
      return this.content.url;
    }
  }
}
