import { Component, Inject, Input, OnInit, ChangeDetectorRef, ViewChild, AfterViewInit } from '@angular/core';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  IconDefinition,
  faVideo,
  faFileLines,
  faCloudArrowDown,
  faRectangleList,
  faCircleQuestion,
  faPlus,
  faPenToSquare,
  faTrashCan,
  faFilePowerpoint,
  faEllipsisVertical,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import {
  CourseContainerOptions,
  QuizTypes,
  SectionTypes,
  SurveyTypes,
  UserTypes,
  getCourseAsGameCategory,
  urlHandler,
} from '../utils/course-utils';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { SectionEditor } from '../section-editor/section-editor.component';
import { includes, isEmpty, get } from 'lodash';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { PresentationManager } from '../presentation-manager/presentation-manager';
import { CertificateTypes } from '../constants/courses-constants';
import { AuthService } from 'src/app/services/auth/auth.service';
import {
  Course,
  CourseLesson,
  Quiz,
  QuizChoice,
  Section,
} from 'src/app/pages/configuration-pages/interfaces/global-config.interfaces';
import { MatExpansionPanel } from '@angular/material/expansion';
import { AllowedUsersDialog } from '../allowed-users-dialog/allowed-users-dialog.component';
import { Messages } from 'src/app/shared/interfaces/Message.interface';
import { GlobalConfigurationHelper } from 'src/app/services/utils/global-configuration-helper';
import { Router } from '@angular/router';
import { LoadingService } from 'src/app/services/utils/loading.service';
import { CategoriesControllerService } from 'src/app/core/openapi';
import { firstValueFrom } from 'rxjs';

declare let cloudinary: any;

@Component({
  selector: 'app-course-editor',
  templateUrl: './course-editor.component.html',
  styleUrls: ['./course-editor.component.scss'],
})
export class CourseEditor implements OnInit, AfterViewInit {
  @ViewChild(MatExpansionPanel) panel: MatExpansionPanel;

  public courses = [];
  public loading = true;
  public checked = true;
  public unchecked = false;
  public creatingCategory = false;
  public choosenLesson: CourseLesson;
  public choosenSection: Section;
  public quizTypes = QuizTypes;
  public selectedSlidesList = [];
  @Input() courseList: Course[];

  public readonly video: IconDefinition = faVideo;
  public readonly text: IconDefinition = faFileLines;
  public readonly download: IconDefinition = faCloudArrowDown;
  public readonly slides: IconDefinition = faFilePowerpoint;
  public readonly survey: IconDefinition = faRectangleList;
  public readonly quiz: IconDefinition = faCircleQuestion;
  public readonly plus: IconDefinition = faPlus;
  public readonly edit: IconDefinition = faPenToSquare;
  public readonly trash: IconDefinition = faTrashCan;
  public readonly handleDots: IconDefinition = faEllipsisVertical;
  public readonly load: IconDefinition = faSpinner;

  public sectionIcons = {
    video: this.video,
    text: this.text,
    survey: this.survey,
    quiz: this.quiz,
    slides: this.slides,
  };

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '300px',
    minHeight: '0',
    maxHeight: '300px',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter content here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [['bold', 'italic'], ['fontSize']],
  };

  public courseContainerOptions = CourseContainerOptions;
  public userTypes = UserTypes;

  public courseHeaderOptions = [
    {
      text: 'Course information',
    },

    {
      text: 'Lessons information',
    },
  ];

  public certificateTypesOption = CertificateTypes;

  public selectedOption = 'Course information';

  public loadingPreview = false;

  constructor(
    public rest: RestAPIService,
    @Inject(MAT_DIALOG_DATA) public data: Course,
    public confirm: ConfirmationService,
    public snack: MatSnackBar,
    public dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    private auth: AuthService,
    private dialogRef: MatDialogRef<CourseEditor>,
    private globalConfig: GlobalConfigurationHelper,
    private router: Router,
    private loadingService: LoadingService,
    private _categoriesService: CategoriesControllerService,
  ) {}

  async ngOnInit() {}

  ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  public openAllowedOrgsDialog() {
    const dialog = this.dialog.open(AllowedUsersDialog, {
      width: '600px',
      height: '650px',
      disableClose: true,
      data: this.data,
      panelClass: 'modal-border',
    });

    dialog.afterClosed().subscribe((shouldSave: boolean) => {
      if (shouldSave) {
        this.saveCourse();
      }
    });
  }

  public handleDraftStatus(type?: string) {
    const draftStatus = this.getDraftStatus(this.data);

    if (draftStatus !== true && type === 'course') {
      const text = `We have an news! A new course "${this.data.title}" has been created. Would you like to view it?`;
      this.createNotification({
        text: text,
        image: this.data.image ? this.data.image : '',
        courseId: this.data.id,
        organizationId: this.data.allowedOrganizations ? this.data.allowedOrganizations.join(',') : '',
        isRead: false,
        allowedUsers: this.data.allowedUsers!,
      });
    }

    if (this.choosenLesson?.draft !== true && type === 'lesson') {
      const titleLesson = this.choosenLesson.title;
      const text = `We have an update! A new lesson "${titleLesson}" has been created for the course ${this.data.title}. Would you like to view it?`;
      this.createNotification({
        text: text,
        image: this.data.image ? this.data.image : '',
        courseId: this.data?.id,
        organizationId: this.data.allowedOrganizations ? this.data.allowedOrganizations.join(',') : '',
        isRead: false,
        allowedUsers: this.data.allowedUsers!,
      });
    }
    this.saveCourse();
  }

  public createNotification(data: Messages) {
    this.confirm
      .createConfirmation('Neuralign', 'Do you want to create a notification about that?', 'Yes', 'No')
      .then(async () => {
        const newMessage = {
          text: data.text,
          image: data.image ? data.image : '',
          courseId: data?.id ? data.id : data.courseId,
          organizationId: data.allowedOrganizations ? data.allowedOrganizations.join(',') : '',
          isRead: false,
          allowedUsers: data.allowedUsers!,
          createdAt: new Date(),
        };
        await this.rest.post('message', newMessage);
      });
  }

  public getCourseContainerOptions() {
    return Object.keys(this.courseContainerOptions);
  }

  public selectSlide(slide: string) {
    const selectedSlide = this.selectedSlidesList.find((s) => s === slide);

    if (selectedSlide) {
      const slideIndex = this.selectedSlidesList.indexOf(selectedSlide);

      this.selectedSlidesList.splice(slideIndex, 1);
    } else {
      this.selectedSlidesList.push(slide);
    }
  }

  public getCertificateTypes() {
    return Object.keys(this.certificateTypesOption);
  }

  public changeCertificateType(type: string) {
    if (this.certificateTypesOption[type] === CertificateTypes.None) {
      this.data.hasCertificate = false;
    } else {
      this.data.certificateType = this.certificateTypesOption[type];
      this.data.hasCertificate = true;
    }

    this.cdRef.detectChanges();
  }

  public checkCertificateType(type: string): boolean {
    const certificateType = get(this.data, 'certificateType', CertificateTypes.None);

    if (!this.data.hasCertificate && this.certificateTypesOption[type] === CertificateTypes.None) {
      return true;
    } else if (this.data.hasCertificate && type.toLowerCase() === certificateType.toLowerCase()) {
      return true;
    }

    return false;
  }

  public selectAllSlides(section: Section) {
    this.selectedSlidesList = [];

    this.selectedSlidesList = section.content.slides;
  }

  public checkIfSlideIsSelected(selectedSlide: string): boolean {
    return includes(this.selectedSlidesList, selectedSlide);
  }

  public deleteSlides() {
    this.confirm
      .createConfirmation(
        'Warning',
        'This action cannot be undone, are you sure you want to delete those slides?',
        'Yes',
        'No',
      )
      .then(async () => {
        this.choosenSection.content.slides = this.choosenSection.content.slides.filter(
          (s) => !includes(this.selectedSlidesList, s),
        );

        await this.rest.put('edit/courses', this.data);

        this.snack.open('Presentation slides deleted!', 'Close', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      });
  }

  public reverseSlideSelection() {
    const filteredSlides = this.choosenSection.content.slides.filter(
      (slide) => !includes(this.selectedSlidesList, slide),
    );
    this.selectedSlidesList = filteredSlides;
  }

  public dropSection(event: CdkDragDrop<Section[]>, lesson: CourseLesson) {
    moveItemInArray(lesson.sections, event.previousIndex, event.currentIndex);
  }

  public dropLesson(event: CdkDragDrop<CourseLesson[]>) {
    moveItemInArray(this.data.lessons, event.previousIndex, event.currentIndex);
  }

  public dropSlide(event: CdkDragDrop<string[]>, section: Section) {
    moveItemInArray(section.content.slides, event.previousIndex, event.currentIndex);
  }

  public getUserTypes() {
    return Object.keys(this.userTypes);
  }

  public addAllowedUser(user: string) {
    if (includes(this.data.allowedUsers, user)) {
      const index = this.data.allowedUsers.indexOf(user);
      this.data.allowedUsers.splice(index, 1);
    } else {
      this.data.allowedUsers.push(user);
    }
  }

  public checkUserType(user: string): boolean {
    return includes(this.data.allowedUsers, user);
  }

  public getCloudnaryConfig() {
    return {
      cloud_name: environment.CLOUDINARY_CLOUD_NAME,
      upload_preset: environment.CLOUDINARY_UPLOAD_PRESET,
      secure: true,
    };
  }

  public addSlide(section: Section) {
    const dialog = this.dialog.open(PresentationManager, {
      width: '400px',
      height: '400px',
      panelClass: 'modal-border',
      data: {
        section,
        cloudinaryConfig: this.getCloudnaryConfig(),
      },
    });

    dialog.afterClosed().subscribe(async (shouldSave: boolean) => {
      if (shouldSave) {
        await this.rest.put('edit/courses', this.data);
      }
    });
  }

  public async replaceSlide(section: Section, selectedSlide: string) {
    const cloudinaryConfig = this.getCloudnaryConfig();
    const index = section.content.slides.indexOf(selectedSlide);

    cloudinary.openUploadWidget(cloudinaryConfig, (error, result) => {
      if (result) {
        const [imageResponse] = result;
        const { secure_url, url } = imageResponse;

        section.content.slides[index] = secure_url || url;

        this.saveCourse();
      } else if (error && error.message !== 'User closed widget') {
        this.snack.open('There was an error while uploading the image, please try again later', 'OK', {
          duration: 5000,
        });
      }
    });
  }

  public removeSlide(section: Section, selectedSlide: string) {
    const index = section.content.slides.indexOf(selectedSlide);
    section.content.slides.splice(index, 1);

    this.snack.open('Slide deleted!', 'Close', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });

    this.saveCourse();
  }

  public removeFullPresentation(section: Section) {
    this.confirm
      .createConfirmation(
        'Warning',
        'This action cannot be undone, are you sure you want to delete this presentation?',
        'Yes',
        'No',
      )
      .then(async () => {
        section.content.slides = [];

        await this.rest.put('edit/courses', this.data);

        this.snack.open('Presentation deleted!', 'Close', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      });
  }

  public returnVideoUrl(url: string) {
    return urlHandler(url);
  }

  public getQuizTypeOptions() {
    return Object.keys(QuizTypes);
  }

  public getSurveyypeOptions() {
    return Object.keys(SurveyTypes);
  }

  public getQuizType(type: string) {
    return QuizTypes[type];
  }

  public getSurveyType(type: string) {
    return SurveyTypes[type];
  }

  public addChoice(quiz: Quiz) {
    const newChoice = {
      correctChoice: false,
      text: '',
    };
    quiz.choices.push(newChoice);

    this.saveCourse();
  }

  public removeChoice(choice: QuizChoice, quiz: Quiz) {
    const index = quiz.choices.indexOf(choice);
    quiz.choices.splice(index, 1);

    this.saveCourse();
  }

  public addQuestion(section: Section) {
    const newQuestion = {
      question: '',
      type: section.type === SectionTypes.quiz ? QuizTypes.oneCorrectAnswer : SurveyTypes.oneAnswer,
      choices: [],
      correctQuestion: '',
      isOptional: false,
      selectedChoices: [],
      feedBack: '',
    };

    section.type === SectionTypes.quiz
      ? section.content.quizes.push(newQuestion)
      : section.content.survey.push(newQuestion);

    this.saveCourse();
  }

  public deleteQuestion(section: Section, selectedQuestion: Quiz) {
    const index = section.content.quizes
      ? section.content.quizes.indexOf(selectedQuestion)
      : section.content.survey.indexOf(selectedQuestion);
    section.content.quizes ? section.content.quizes.splice(index, 1) : section.content.survey.splice(index, 1);

    this.saveCourse();
  }

  public checkAnswerType(choice: QuizChoice, quiz: Quiz) {
    // if the selected quiz have only one correct choice we need to be sure that only one is selected as correct

    if (quiz.type === QuizTypes.oneCorrectAnswer) {
      for (const quizChoice of quiz.choices) {
        if (!choice.correctChoice && quizChoice != choice) {
          quizChoice.correctChoice = false;
        }
      }
    }
  }

  public async editImage() {
    const cloudinaryConfig = this.getCloudnaryConfig();

    cloudinary.openUploadWidget(cloudinaryConfig, (error, result) => {
      if (result) {
        const [imageResponse] = result;
        const { secure_url, url } = imageResponse;

        this.data.image = secure_url || url;

        this.saveCourse();
      } else if (error && error.message !== 'User closed widget') {
        this.snack.open('There was an error while uploading the image, please try again later', 'OK', {
          duration: 5000,
        });
      }
    });
  }

  public async uploadVideo(section: Section) {
    const cloudinaryConfig = this.getCloudnaryConfig();

    cloudinary.openUploadWidget(cloudinaryConfig, (error, result) => {
      if (result) {
        const [imageResponse] = result;
        const { secure_url, url } = imageResponse;

        section.content.url = secure_url || url;

        this.saveCourse();
      } else if (error && error.message !== 'User closed widget') {
        this.snack.open('There was an error while uploading the image, please try again later', 'OK', {
          duration: 5000,
        });
      }
    });
  }

  public async getCloudnaryFileLink(): Promise<string> {
    const cloudinaryConfig = this.getCloudnaryConfig();

    await cloudinary.openUploadWidget(cloudinaryConfig, (error, result) => {
      if (result) {
        const [imageResponse] = result;
        const { secure_url, url } = imageResponse;

        result = secure_url || url;

        return secure_url || url;
      } else if (error && error.message !== 'User closed widget') {
        this.snack.open('There was an error while uploading the image, please try again later', 'OK', {
          duration: 5000,
        });
      }
    });

    return;
  }

  public getSectionIcon(type: string) {
    if (!this.sectionIcons[type]) {
      return this.video;
    }

    return this.sectionIcons[type];
  }

  public selectSection(section: Section) {
    this.selectedSlidesList = [];
    this.choosenSection = section;
  }

  public selectLesson(lesson: CourseLesson) {
    this.choosenLesson = lesson;
    this.choosenSection = undefined;
  }

  public async saveCourse() {
    await this.rest.put('edit/courses', this.data);

    this.snack.open('Course updated!', 'Close', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  public async deleteLesson(lesson: CourseLesson) {
    this.confirm
      .createConfirmation(
        'Warning',
        'This action cannot be undone, are you sure you want to delete this lesson?',
        'Yes',
        'No',
      )
      .then(async () => {
        const index = this.data.lessons.indexOf(lesson);
        this.data.lessons.splice(index, 1);
        await this.rest.put('edit/courses', this.data);
        this.choosenLesson = undefined;
        this.choosenSection = undefined;

        this.snack.open('Lesson deleted!', 'Close', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      });
  }

  public openSectionCreator(lesson: CourseLesson) {
    const dialog = this.dialog.open(SectionEditor, {
      width: '400px',
      height: '400px',
      panelClass: 'modal-border',
      data: {
        lessonId: lesson._id,
        course: this.data,
      },
    });

    dialog.afterClosed().subscribe(async (newSection: Section) => {
      if (newSection) {
        lesson.sections.push(newSection);

        this.snack.open('Section created!', 'Close', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }
    });
  }

  public async deleteSection(section: Section) {
    this.confirm
      .createConfirmation(
        'Warning',
        'This action cannot be undone, are you sure you want to delete this section?',
        'Yes',
        'No',
      )
      .then(async () => {
        const index = this.choosenLesson.sections.indexOf(section);
        this.choosenLesson.sections.splice(index, 1);

        this.choosenSection = undefined;

        await this.rest.put('edit/courses', this.data);

        this.snack.open('Course updated!', 'Close', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      });
  }

  public async createLesson() {
    try {
      const updatedCourse = await this.rest.post(`/course/addLesson`, this.data);
      this.data = updatedCourse;

      this.snack.open('Lesson created!', 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    } catch (error) {
      this.snack.open('Could not create this lesson!', 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }
  }

  public isEmptyLesson(lesson: CourseLesson): boolean {
    return lesson.sections.length === 0 || lesson.sections.every((l) => this.isEmptySection(l));
  }

  public isEmptySection(section: Section): boolean {
    switch (section.type) {
      case SectionTypes.quiz:
        return section.content.quizes.length === 0;
      case SectionTypes.slides:
        return section.content.slides.length === 0;
      case SectionTypes.text:
        return isEmpty(section.content.text);
      case SectionTypes.video:
        return isEmpty(section.content.url);
    }
  }

  public isEmptyCourse(): boolean {
    return this.data.lessons.every((l) => this.isEmptyLesson(l));
  }

  public getDraftStatus(content: CourseLesson | Section | Course) {
    return content.draft;
  }

  public handleLessonDraftStatus(lesson: CourseLesson) {
    if (this.isEmptyLesson(lesson) && lesson.draft) {
      return;
    }

    lesson.draft = !lesson.draft;
  }

  public handleSectionDraftStatus(section: Section) {
    if (this.isEmptySection(section) && section.draft) {
      return;
    }

    section.draft = !section.draft;
  }

  public async previewCertificate() {
    this.loadingPreview = true;

    const adminUser = await this.auth.getUser();

    await this.rest.post('admin/previewCertificate/user/' + adminUser.id, this.data);

    this.loadingPreview = false;

    this.snack.open('A preview of this course certificate was sent to your email!', 'Close', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  public async addCourseAsGameCategory() {
    this.data.useForGameCategory = true;
    this.cdRef.detectChanges();

    try {
      this.confirm
        .createConfirmation(
          'Warning',
          'Using this course as a game category will make it unavaliable on the courses page. Are you sure you want to proceed',
          'Yes',
          'No',
        )
        .then(async () => {
          this.loadingService.ActivateLoading();
          const gameCategory = getCourseAsGameCategory(this.data);

          const response = await this.rest.put('edit/courses', this.data);

          if (!response) {
            throw new Error('Could not edit this course, please try again');
          }

          const newCategory = await firstValueFrom(
            this._categoriesService.categoriesControllerCreateCategory(gameCategory),
          );

          if (!newCategory) {
            throw new Error('Could not add this course as game category, please try again');
          }

          this.snack.open('This course is now a game category!', 'Close', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });

          await this.globalConfig.getCategories(true);

          this.loadingService.DisableLoading();
        })
        .catch((error) => {
          this.data.useForGameCategory = false;
          this.cdRef.detectChanges();
        });
    } catch (error) {
      this.loadingService.DisableLoading();
      this.data.useForGameCategory = false;
      this.cdRef.detectChanges();

      this.snack.open(error.message, 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }
  }

  public changeEnabledStatus(status: boolean) {
    this.data.enabledToAll = status;
    this.cdRef.detectChanges();
  }

  public async removeCourseAsGameCategory() {
    try {
      this.loadingService.ActivateLoading();
      this.data.useForGameCategory = false;
      this.cdRef.detectChanges();
      const response = await this.rest.put('edit/courses', this.data);

      if (!response) {
        throw new Error('Could not edit this course, please try again');
      }

      const categories = await this.globalConfig.getCategories();
      const selectedCategory = categories.find((c) => c.courseId === this.data.id);

      if (selectedCategory) {
        await firstValueFrom(this._categoriesService.categoriesControllerDeleteCategory(selectedCategory.id));
      }

      this.snack.open('Course is no longer a game category!', 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });

      await this.globalConfig.getCategories(true);

      this.loadingService.DisableLoading();
    } catch (error) {
      this.loadingService.DisableLoading();
      this.data.useForGameCategory = true;
      this.cdRef.detectChanges();

      this.snack.open(error.message, 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }
  }

  public redirectToCategoryDashboard() {
    this.dialogRef.close();
    this.router.navigate(['content-configurations'], { queryParams: { option: 'categories', courseId: this.data.id } });
  }
}
