export const studentLevelMap = {
  LEVEL_JR: 'jr',
  LEVEL_P1: 'p1',
  LEVEL_P2: 'p2',
  LEVEL_P3: 'p3',
  LEVEL_P4: 'p4',
};

export const languages = [
  {
    name: 'en_ca',
    label: 'English',
  },
  {
    name: 'fr_ca',
    label: 'French',
  },
];

export const themes = [
  {
    name: 'Classic Neuralign',
    label: 'Neuralign',
  },
  {
    name: 'CDH - A Christmas Carol',
    label: 'A Christmas Carol',
  },
];

export enum gameNamesCDH {
  honeycombcdh = 'Christmas Tree',
  egyptcdh = 'Ledger',
  pinballcdh = 'Fireplace',
  mosaiccdh = 'Streetlights',
  chinacdh = 'Blackboard',
  australiacdh = 'Feast',
  hollandcdh = 'House',
  sunnyscotlandcdh = 'Sunny Graveyard',
  scotlandcdh = 'Dark Graveyard',
  colourhoppercdh = 'ColourHopper',
  memorycdh = 'Memory',
  pathfindercdh = 'Pathfinder',
  readingcompcdh = 'Reading Comp',
  sillymachinecdh = 'Silly Machine',
  homereadingcdh = 'Home Reading',
  wordbuilderhollandcdh = 'Word Builder Holland',
  speedreadingcdh = 'Sppeed Reading',
}

export const userTypes = {
  STUDENT: 'student',
  ADMIN: 'Organization Admin',
  MANAGER: 'Organization Manager',
};

export const exportTypes = {
  USER: 'user',
  PROGRESS: 'progress',
};

export const progressTypes = {
  ASSESSMENT: 'assessment',
  COGNITIVE_THERAPY: 'cognitive therapy',
  SPEED_READING: 'speed reading',
  READING_EXERCISES: 'reading exercises',
  BRAIN_GAMES: 'brain games',
};

// TODO find a way to add dynamic categories to allow this feature to be future proof

export const defaultCategoryTypes = [
  { name: progressTypes.ASSESSMENT, enabled: true },
  { name: progressTypes.COGNITIVE_THERAPY, enabled: true },
  { name: progressTypes.SPEED_READING, enabled: true },
  { name: progressTypes.READING_EXERCISES, enabled: true },
];

export const tutorialVideos = {
  organizations: 'https://share.synthesia.io/embeds/videos/ca3eb888-7a28-4dd7-9e8d-3847e441301b',
  clients: 'https://share.synthesia.io/embeds/videos/7fc08258-a80c-439d-8b37-73f9a5038fe4',
  b2c: 'https://share.synthesia.io/embeds/videos/a8cb5829-9cf6-4e1a-88c8-19f452e57189',
};

export const ageBasedLevel = 'ageBasedLevel';

export const b2cActivationWarning =
  'You are asking to activate the home version, that means clients will be allowed to register under your organization and purchase credits directly from you. Are you sure you want to proceed?';
