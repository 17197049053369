import { Component, OnInit } from '@angular/core';
import {
  faCircle,
  faReceipt,
  faMoneyCheckAlt,
  faTimes,
  faCheckCircle,
  faSpinner,
  faStopCircle,
} from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { subscriptionMenuOptions } from './subscription-consts/subscription.consts';
import { RoleService } from 'src/app/services/roles/role.service';
import { get } from 'lodash';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-subscription-control-center',
  templateUrl: './subscription-control-center.component.html',
  styleUrls: ['./subscription-control-center.component.scss'],
})
export class SubscriptionControlCenterComponent implements OnInit {
  public readonly circle: IconDefinition = faCircle;
  public readonly receipt: IconDefinition = faReceipt;
  public readonly changePrice: IconDefinition = faMoneyCheckAlt;
  public readonly cancel: IconDefinition = faTimes;
  public readonly reactivate: IconDefinition = faCheckCircle;
  public readonly loadingIcon: IconDefinition = faSpinner;
  public readonly stopIcon: IconDefinition = faStopCircle;

  public readonly subscriptionMenuOptions = subscriptionMenuOptions;

  public organization;
  public isReseller = false;
  public selectedOption = subscriptionMenuOptions.outsiderSubscriptions;
  public loading = true;
  public pageLoading = false;

  public resellerSubscriptions = [];
  public regularSubscriptions = [];

  constructor(private _rest: RestAPIService, private roleService: RoleService, private authService: AuthService) {}

  async ngOnInit() {
    this.organization = get(this.roleService, 'user.organization') || get(this.authService.getOrgAcc(), 'organization');
    this.isReseller = this.organization.isReseller;
    await this.findSubscriptions();
    this.loading = false;
  }

  public async findSubscriptions() {
    const subscriptions = await this._rest.get('subscriptions/getActiveSubscriptions', {
      msg: 'Could not get subscriptions/getActiveSubscriptions.',
    });

    this.resellerSubscriptions = subscriptions.resellerSubscriptions.filter((s) => s.user.organization) || [];
    this.regularSubscriptions = subscriptions.regularSubscriptions || [];

    this.selectedOption =
      this.isReseller && this.resellerSubscriptions.length > 0
        ? subscriptionMenuOptions.outsiderSubscriptions
        : subscriptionMenuOptions.mySubscriptions;
  }

  public startLoading(shouldActivateLoading: boolean) {
    if (shouldActivateLoading) {
      this.pageLoading = true;
    } else {
      this.pageLoading = false;
    }
  }
}
