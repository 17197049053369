<div class="cogman">
  <fa-icon
    class="icon"
    [icon]="cogmanIcon"
  >
  </fa-icon>
  <div
    class="updates-badge upper-right"
    *ngIf="totalUpdates > 0 && !isB2CPortal && !isOrgClient"
  >
    <span>{{ totalUpdates }}</span>
  </div>
</div>

<ng-container *ngIf="isCogmanMenuOpen && handlePasswordUpdated">
  <div class="triangle"></div>
  <nav class="cogman-buttons">
    <button
      *ngIf="
        !isOrgClient &&
        !isOrgManager &&
        !isOrgAdmin &&
        !pendingRegistration() &&
        !isSchoolStudent
      "
      (click)="accountSettings()"
    >
      Account Settings
    </button>

    <button
      *ngIf="
        !isOrgClient &&
        !isB2CPortal &&
        !isOrgManager &&
        !pendingRegistration() &&
        !isSchoolStudent
      "
      (click)="askForB2cActivation()"
    >
      Activate purchase home
    </button>

    <button
      *ngIf="
        !isOrgClient &&
        !isOrgManager &&
        !isOrgAdmin &&
        !isB2CPortal &&
        !pendingRegistration() &&
        !isSchoolStudent
      "
      (click)="goToRoute('public-profile')"
    >
      Public profile
    </button>

    <button
      *ngIf="!isB2CPortal && !isOrgClient && !pendingRegistration()"
      (click)="toggleUpdates()"
    >
      <span
        class="updates-badge"
        *ngIf="totalUpdates > 0"
        >{{ totalUpdates }}</span
      >
      Update{{ totalUpdates > 1 ? 's' : '' }}
    </button>

    <button (click)="goToRoute('help')">Help</button>

    <button
      (click)="
        gotoOutsideLink('https://neuralign.freshdesk.com/support/solutions')
      "
    >
      Support
    </button>

    <button (click)="logout()">Logout</button>
  </nav>
</ng-container>

<ng-container *ngIf="isCogmanMenuOpen && !handlePasswordUpdated">
  <div class="triangle"></div>
  <nav class="cogman-buttons">
    <button
      (click)="
        gotoOutsideLink('https://neuralign.freshdesk.com/support/solutions')
      "
    >
      Support
    </button>

    <button (click)="logout()">Logout</button>
  </nav>
</ng-container>
