export enum PaymentStatus {
  Paid = 'paid',
  Failed = 'paymentFailed',
  Waiting = 'waitingForPayment',
}

export enum PaymentTypes {
  DEFAULT = 'default',
  PROGRAM_RERUN = 'programRerun',
}

export enum InvoiceStatus {
  Paid = 'paid',
  Draft = 'draft',
  Open = 'open',
}

export enum PaymentPeriod {
  MONTH = 'Month',
  YEAR = 'Year',
}
