<app-main-layout
  [breadcrumbs]="[
    { name: 'Home', url: '/' },
    getSecondMenuItem(),
    { name: 'Programs', url: '/' }
  ]"
>
  <div
    class="inner-content"
    [ngClass]="{ empty: !programs || programs.length === 0 }"
  >
    <ng-container *ngIf="programs && programs.length > 0">
      <h2>Student Programs</h2>

      <table
        mat-table
        class="mat-elevation-z8"
        [dataSource]="programs"
      >
        <ng-container matColumnDef="name">
          <th
            mat-header-cell
            *matHeaderCellDef
          >
            Program
          </th>
          <td
            mat-cell
            *matCellDef="let row"
          >
            {{ row.name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th
            mat-header-cell
            *matHeaderCellDef
          >
            Actions
          </th>
          <td
            mat-cell
            *matCellDef="let row"
          >
            <a
              [routerLink]="'/program/' + studentId + '/Neuralign'"
              matTooltip="Access Neuralign"
              matTooltipPosition="above"
            >
              <fa-icon [icon]="gamepadIcon"></fa-icon>
            </a>
          </td>
        </ng-container>

        <ng-container matColumnDef="assessmentLevel">
          <th
            mat-header-cell
            *matHeaderCellDef
          >
            Level Override
            <fa-icon
              [icon]="helpIcon"
              matTooltip="Override deletes the first placement assessment, and you will choose the level for your client."
              matTooltipPosition="above"
            >
            </fa-icon>
          </th>

          <td
            mat-cell
            *matCellDef="let row"
          >
            <select
              class="levelSelect"
              [(ngModel)]="studentAssessmentLevel"
              *ngIf="progress && themes"
            >
              <option
                *ngFor="let studentLevel of allowedStudentsLevels()"
                value="{{ studentLevel.assessmentLevel }}"
              >
                {{ studentLevel.level }}
              </option>
            </select>
            <button
              mat-raised-button
              (click)="openDialog()"
            >
              Save
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="allowCompleteAtHome">
          <th
            mat-header-cell
            *matHeaderCellDef
          >
            Remote Access
          </th>
          <td
            mat-cell
            *matCellDef="let row"
          >
            <div
              class="checkbox"
              [ngClass]="{ active: allowCompleteAtHome }"
              (click)="
                allowCompleteAtHome = !allowCompleteAtHome;
                saveAllowCompleteAtHome()
              "
            >
              <fa-icon [icon]="checkIcon"></fa-icon>
            </div>
          </td>
        </ng-container>
        <tr
          [ngClass]="{ loading: loading }"
          mat-header-row
          *matHeaderRowDef="displayedColumns"
        ></tr>
        <tr
          [ngClass]="{ loading: loading }"
          mat-row
          *matRowDef="let row; columns: displayedColumns"
        ></tr>
      </table>
    </ng-container>

    <app-empty-state
      *ngIf="!programs || programs.length === 0"
      text="No programs associated with this user yet"
      [icon]="bookIcon"
    ></app-empty-state>
  </div>
</app-main-layout>
