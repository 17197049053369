import { ElementRef, Renderer2 } from '@angular/core';
import { CSSObject } from './CSSObject.type';

/**
 * This function takes in Angular's Renderer2 object, an ElementRef and an object of CSS values
 * and loops through each CSS property to render the element passed in with those styles.
 */
export function styleElement(renderer: Renderer2, element: ElementRef, values: CSSObject): void {
  Object.entries(values).forEach((value) => {
    renderer.setStyle(element.nativeElement, value[0], value[1]);
  });
}
