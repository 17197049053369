<div class="button-container">
  <button
    [disabled]="disabled"
    [matTooltip]="tooltip"
  >
    <span>{{ label }}</span>
    <fa-icon
      class="icon"
      [ngClass]="size"
      [icon]="icon"
    ></fa-icon>
  </button>
</div>
