<app-main-layout
  [breadcrumbs]="[
    { name: 'Home', url: '/' },
    { name: 'Users', url: '/users' },
    { name: 'Coupon' }
  ]"
>
  <div class="header-container">
    <span>Coupons List</span>
    <div class="actions">
      <input
        type="text"
        placeholder="{{
          showCoupons !== 'organizations'
            ? 'Search coupon'
            : 'Search coupons or by Organization..'
        }}"
        [(ngModel)]="searchTerm"
        (input)="search()"
      />
      <div
        class="add-button"
        *ngIf="template !== 'loading'"
      >
        <button
          mat-raised-button
          (click)="handleClick()"
        >
          Add new
          <fa-icon [icon]="faPlus"></fa-icon>
        </button>
      </div>
    </div>
  </div>

  <body>
    <div
      class="table_responsive"
      *ngIf="template === 'table'"
    >
      <div class="coupon-toggle">
        <button
          class="toggle-btn"
          [class.active]="showCoupons === 'clients'"
          (click)="toggleCoupons('clients')"
        >
          User Coupons
        </button>
        <button
          class="toggle-btn"
          [class.active]="showCoupons === 'organizations'"
          (click)="toggleCoupons('organizations')"
        >
          Organization Coupons
        </button>
        <button
          class="toggle-btn"
          [class.active]="showCoupons === 'global'"
          (click)="toggleCoupons('global')"
        >
          Global Coupons
        </button>
      </div>

      <span *ngIf="coupons && coupons.length"
        >Total of {{ coupons.length }} coupons
      </span>
      <table>
        <thead>
          <tr>
            <th
              *ngFor="let column of columns"
              (click)="sortTable(column)"
            >
              {{ getColumnDisplayName(column) | uppercase }}
              <span *ngIf="sortField === column && sortDirection === 'asc'"
                >&#9650;</span
              >
              <span *ngIf="sortField === column && sortDirection === 'desc'"
                >&#9660;</span
              >
            </th>
            <th>ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let coupon of coupons">
            <ng-container *ngIf="coupons.length > 0">
              <td *ngFor="let column of columns">
                {{ getColumnValue(coupon, column) }}
              </td>
            </ng-container>
            <td>
              <div class="action_btn">
                <button (click)="deleteCoupon(coupon)">
                  <fa-icon [icon]="faTrash"></fa-icon>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </body>
  <ng-container *ngIf="template === 'loading'"
    ><app-loading-indicator></app-loading-indicator
  ></ng-container>
</app-main-layout>
