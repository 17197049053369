import { Component, Input, OnInit } from '@angular/core';

import {
  faTrashAlt,
  faExclamationTriangle,
  faPlus,
  faMinus,
  faGamepad,
  faEdit,
  faRedo,
  faTag,
  faCheck,
  faSchool,
  faBagShopping,
  faCoins,
  faFileUpload,
  faFileLines,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'users-table-action-icon',
  templateUrl: './table-action-icon.component.html',
  styleUrls: ['./table-action-icon.component.scss'],
})
export class TableActionIconComponent implements OnInit {
  @Input() icon:
    | 'default'
    | 'trash'
    | 'plus'
    | 'minus'
    | 'edit'
    | 'redo'
    | 'game'
    | 'tag'
    | 'checkmark'
    | 'learning'
    | 'shop'
    | 'coins'
    | 'upload'
    | 'file';
  @Input() tooltip = '';
  @Input() action;
  @Input() disabled = false;

  public iconToDisplay: any = faExclamationTriangle;

  constructor() {}

  ngOnInit(): void {
    this.initIcon();
  }

  private initIcon() {
    switch (this.icon) {
      case 'trash':
        this.iconToDisplay = faTrashAlt;
        break;
      case 'plus':
        this.iconToDisplay = faPlus;
        break;
      case 'minus':
        this.iconToDisplay = faMinus;
        break;
      case 'edit':
        this.iconToDisplay = faEdit;
        break;
      case 'game':
        this.iconToDisplay = faGamepad;
        break;
      case 'redo':
        this.iconToDisplay = faRedo;
        break;
      case 'tag':
        this.iconToDisplay = faTag;
        break;
      case 'checkmark':
        this.iconToDisplay = faCheck;
        break;
      case 'learning':
        this.iconToDisplay = faSchool;
        break;
      case 'shop':
        this.iconToDisplay = faBagShopping;
        break;
      case 'coins':
        this.iconToDisplay = faCoins;
        break;
      case 'upload':
        this.iconToDisplay = faFileUpload;
        break;
      case 'file':
        this.iconToDisplay = faFileLines;
        break;
      default:
        this.iconToDisplay = faExclamationTriangle;
    }
  }
}
