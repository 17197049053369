import { Component, Inject, isDevMode, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { get } from 'lodash';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
})
export class ErrorDialogComponent implements OnInit {
  isDevMode = isDevMode();

  UIerrorMessage: string;
  APIerrorMessage: string;
  autoErrorMessage: string;

  type: 'javascript' | 'http';
  stack: string;
  request: string;
  response: string;
  body: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data,
    private snack: MatSnackBar,
    private rest: RestAPIService,
    private auth: AuthService,
    private _router: Router,
  ) {}

  ngOnInit() {
    this.getErrorType();
    this.constructError();

    // Send error to clarity
    (window as any)?.clarity?.('event', 'error');
  }

  /**
   * Choose which type of error to construct
   */
  constructError() {
    if (this.type === 'javascript') {
      return this.constructJavaScriptError();
    } else {
      return this.constructHttpError();
    }
  }

  /**
   * Construct JavaScript error details
   */
  constructJavaScriptError() {
    this.UIerrorMessage = get(this.data, 'message', '');
    this.stack = get(this.data, 'stack');
    this.formatStackTrace();

    // Check for Unhandled promise errors
    if (this.UIerrorMessage.includes('Uncaught (in promise)')) {
      this.UIerrorMessage =
        'An error just happened, try refreshing the page, if the error persists, please send an error report.';
    }
  }

  /**
   * Construct Http Error details
   */
  constructHttpError() {
    // Create messages
    this.UIerrorMessage = get(this.data, 'body.msg', '');
    this.APIerrorMessage = get(this.data, 'error.message', '') || get(this.data, 'error.error.message', '');
    this.autoErrorMessage = get(this.data, 'message', '');

    // Extra details
    this.request = get(this.data, 'method', '') + ' ' + get(this.data, 'url', '');
    this.response = get(this.data, 'status', '') + ' ' + get(this.data, 'statusText', '');

    // Body only shows in dev
    this.isDevMode ? (this.body = get(this.data, 'body', '')) : (this.body = '');
  }

  /**
   * Sends an error report by email.
   */
  async sendErrorEmail() {
    this.snack.open('Message sent, thank you!', 'Close', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });

    const user = await this.auth.getUser();
    const profile = this.auth.getProfile();

    await this.rest.post(
      'email-error',
      {
        user: {
          name: get(profile, 'displayName', 'No display name.'),
          organization: get(user, 'organization.name', 'No organization.'),
          phoneNumber: get(user, 'organization.phoneNumber', 'No phone number.'),
          email: get(profile, 'email', 'No email.'),
          emailVerified: get(profile, 'email_verified', 'No email verified.'),
          role: get(user, 'role', 'No user role'),
        },
        data: {
          currentUrl: window.location.href,
          status: get(this.data, 'status', 'No status.'),
          method: get(this.data, 'method', 'No method.'),
          endpoint: get(this.data, 'url', 'No url.'),
          message: get(this.data, 'message', 'No message.'),
          errorMessage: get(this.data, 'error.message', 'No error message.'),
          uiMessage: get(this.data, 'body.msg', 'No ui message.'),
        },
        debugData: JSON.stringify(this.data),
      },
      { msg: 'Could not post contact' },
    );

    this.refresh();
  }

  /**
   * Helpers
   */

  refresh(): void {
    window.location.reload();
  }

  backToHome() {
    this._router.navigate(['/']);
  }

  formatStackTrace() {
    if (this.stack.length > 0) {
      this.stack = this.stack.split(' at ').slice(0, 4).join('\n at ');
      this.stack = '\n' + this.stack + '\n ... See console for more details';
    }
  }

  getErrorType() {
    if (this.data?.name === 'HttpErrorResponse') {
      this.type = 'http';
    } else {
      this.type = 'javascript';
    }
  }
}
