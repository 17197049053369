import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LessonsListComponent } from './lessons-list.component';
import { LessonsHeaderComponent } from './lessons-header/lessons-header.component';
import { SectionItemComponent } from './section-item/section-item.component';
import { CoursesSharedModule } from '../../../shared/courses.shared.module';

@NgModule({
  declarations: [LessonsListComponent, LessonsHeaderComponent, SectionItemComponent],
  imports: [CommonModule, CoursesSharedModule],
  exports: [LessonsListComponent],
})
export class LessonsListModule {}
