import { get } from 'lodash';

let delayed;

export const ChartJSDoughnutOptions = {
  responsive: false,
  title: {},
  legend: {
    display: false,
  },
  scales: {
    display: false,
  },
  animation: {
    onComplete: () => {
      delayed = true;
    },
    delay: (context) => {
      let delay = 0;
      if (context.type === 'data' && context.mode === 'default' && !delayed) {
        delay = context.dataIndex * 300 + context.datasetIndex * 100;
      }
      return delay;
    },
  },
  tooltips: {
    // Disable the on-canvas tooltip
    enabled: false,

    callbacks: {
      label(tooltipItem, data) {
        return data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
      },
    },

    custom(tooltipModel) {
      // Tooltip Element
      let tooltipEl: any = document.getElementById('chartjs-tooltip');

      // Create element on first render
      if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.id = 'chartjs-tooltip';
        tooltipEl.innerHTML = '<table></table>';
        document.body.appendChild(tooltipEl);
      }

      // Hide if no tooltip
      if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
      }

      // Set caret Position
      tooltipEl.classList.remove('above', 'below', 'no-transform');
      if (tooltipModel.yAlign) {
        tooltipEl.classList.add(tooltipModel.yAlign);
      } else {
        tooltipEl.classList.add('no-transform');
      }

      function getBody(bodyItem) {
        return bodyItem.lines;
      }

      // Set Text
      if (tooltipModel.body) {
        const titleLines = tooltipModel.title || [];
        const bodyLines = tooltipModel.body.map(getBody);

        let innerHtml = '<thead style="font-weight:bold">';

        titleLines.forEach((title) => {
          innerHtml += '<tr><th>' + title + '</th></tr>';
        });
        innerHtml += '</thead><tbody>';

        bodyLines.forEach((body, i) => {
          if (body.length > 0) {
            const colors = get(tooltipModel, `labelColors[${i}]`, 'red');
            const style = `background: ${colors.backgroundColor};
            border-color: ${colors.borderColor};
            border-width: 2px;`;

            const span = `<span style="${style}"></span>`;
            innerHtml += `<tr><td>Score: ${span} ${get(body, '[0].y', 0)} </td></tr>`;
            innerHtml += `<tr><td> Date: ${span} ${get(body, '[0].date', new Date())} </td></tr>`;
            innerHtml += `<tr><td class="italic"> Click for details </td></tr>`;
          }
        });
        innerHtml += '</tbody>';

        const tableRoot = tooltipEl.querySelector('table');
        tableRoot.innerHTML = innerHtml;
      }

      // `this` will be the overall tooltip
      const position = this._chart.canvas.getBoundingClientRect();

      // Display, position, and set styles for font
      tooltipEl.style.opacity = 1;
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
      tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
      tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
      tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
      tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
      tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
      tooltipEl.style.pointerEvents = 'none';
      tooltipEl.style.backgroundColor = 'white';
      tooltipEl.style.borderRadius = '5px';
      tooltipEl.style.color = 'black';
      tooltipEl.style.transition = '0.2s ease';
      tooltipEl.style.boxShadow = '0 0 10px 0 rgba(0, 0, 0, 0.5)';

      // show the tooltip in front of the mat dialog
      tooltipEl.style['z-index'] = 1000;
    },
  },
};
