import { Injectable } from '@angular/core';
import { Student } from 'src/app/pages/students/interfaces/student.interface';
import { get } from 'lodash';
import { ThemeService } from '../themes/themes.service';
import { GameStundentDTO } from 'src/app/shared/components/game/game.interface';

@Injectable({
  providedIn: 'root',
})
export class StudentHelperService {
  constructor(private _themeService: ThemeService) {}

  public async getProfileQueryParams(student: Student, themeLabel: string = '') {
    const tokens = get(student, 'tokens', []).length > 0 ? student.tokens.map((t) => JSON.stringify(t)) : [];

    if (!themeLabel) {
      themeLabel = await this._getStudentThemeLabel(student);
    }

    const queryParams = { ...student, themeLabel, tokens };

    return queryParams;
  }

  private async _getStudentThemeLabel(student: Student): Promise<any> {
    const themes = await this._themeService.getEnabledThemes();

    let theme = themes.find((t) => t.id === student.theme);

    if (!theme) {
      theme = this._themeService.getClassicTheme();
    }

    return theme.label.en_ca;
  }

  public _transformStudentAge(student: Student | GameStundentDTO): number {
    if (!student.birthdate) {
      return 0;
    }

    const ageDifMs = Date.now() - new Date(student.birthdate).getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }
}
