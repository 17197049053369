import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';

interface DialogData {
  courseName: string;
}

@Component({
  selector: 'app-course-completion-dialog',
  templateUrl: './course-completion-dialog.html',
  styleUrls: ['./course-completion-dialog.scss'],
})
export class CourseCompletionDialog implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialofRef: MatDialogRef<CourseCompletionDialog>,
    private router: Router,
  ) {}

  ngOnInit() {}

  public closeDialog() {
    this.dialofRef.close();
    this.router.navigate(['/courses'], { queryParams: { reload: true } });
  }
}
