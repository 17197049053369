import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'users-table-credits-badge-item',
  templateUrl: './table-credits-badge-item.component.html',
  styleUrls: ['./table-credits-badge-item.component.scss'],
})
export class TableCreditsBadgeItemComponent implements OnInit {
  @Input() credits: number;
  label: string;

  constructor() {}

  ngOnInit(): void {
    if (this.credits === 1) {
      this.label = this.credits + ' credit';
    } else {
      this.label = this.credits + ' credits';
    }
  }
}
