import { Component, isDevMode } from '@angular/core';
import { domainUrl } from 'src/app/services/auth/auth-constants';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PortalTypeService } from 'src/app/services/portal/portal-type.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'header-login-btn',
  templateUrl: './login-btn.component.html',
  styleUrls: ['./login-btn.component.scss'],
})
export class LoginBtnComponent {
  constructor(private _auth: AuthService, private portalService: PortalTypeService) {}

  public login() {
    if (!this.portalService.chekIfIsOrgPortal || isDevMode()) {
      this._auth.show();
    } else {
      // if the user is trying to login using a org portal url, first redirect it to our root domain so auth0 dont block the cookies

      window.location = ('//' + environment.ROOT_DOMAIN_URL + domainUrl + '/?orgPortal=true') as string &
        globalThis.Location;
    }
  }
}
