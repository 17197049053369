import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EXCLUDED_EXERCISES_TYPE } from 'src/app/pages/program/consts/program-game-consts';
import { includes, startCase } from 'lodash';
import { Student } from 'src/app/pages/students/interfaces/student.interface';
import { Progress } from 'src/app/shared/models';
import { IconDefinition, faXmark } from '@fortawesome/free-solid-svg-icons';
import { GameState } from '../game.constants';

interface Data {
  student: Student;
  savedGame: Progress;
}

@Component({
  selector: 'app-saved-game-dialog.component',
  templateUrl: './saved-game-dialog.component.html',
  styleUrls: ['./saved-game-dialog.component.scss'],
})
export class savedGameDialogComponent implements OnInit {
  public student: Student;
  public savedGame: Progress;
  public exercises = [];
  public close: IconDefinition = faXmark;

  constructor(@Inject(MAT_DIALOG_DATA) public values: Data, public dialogRef: MatDialogRef<savedGameDialogComponent>) {}

  ngOnInit() {
    (this.student = this.values.student), (this.savedGame = this.values.savedGame);
    this.exercises = this.savedGame.metadata.exerciseList.filter(
      (exercise) => includes(EXCLUDED_EXERCISES_TYPE, exercise.name) === false,
    );
  }

  public cancel() {
    this.dialogRef.close();
  }

  public playFromBeginning() {
    this.dialogRef.close(GameState.PLAY_FROM_BEGGINING);
  }

  public loadSavedGame() {
    this.dialogRef.close(GameState.LOAD_GAME);
  }

  public tagName(tag: string) {
    return startCase(tag);
  }
}
