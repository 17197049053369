<div class="logo">
  <img
    routerLink="/"
    class="logo"
    *ngIf="logo"
    [src]="logo"
    width="100%"
    (click)="closeMobileMenu()"
  />
</div>
