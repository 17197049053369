<div class="mainContainer h-100">
  <div
    class="header col-10 px-0 m-auto text-center"
    *ngIf="!organization && !searchComplete"
  >
    We could not find this organization. <br />
    <br />
    Type the name of the organization you're trying to find.
  </div>
  <div
    class="content mx-auto mt-5 col-10 px-0 d-flex justify-content-center align-items-center"
    *ngIf="!organization && !searchComplete"
  >
    <div class="inputs text-center">
      <mat-form-field appearance="fill">
        <mat-label>Organization name</mat-label>
        <input
          matInput
          type="text"
          [(ngModel)]="orgName"
        />
      </mat-form-field>

      <app-simple-button
        (click)="findOrganization()"
        *ngIf="!loading"
        class="mx-1"
        [text]="'Find organization'"
      >
      </app-simple-button>

      <fa-icon
        *ngIf="loading"
        [icon]="loadingIcon"
        [spin]="true"
      >
      </fa-icon>
    </div>
  </div>

  <div
    class="orgContainer"
    *ngIf="organization"
  >
    <div class="header col-10 px-0 m-auto text-center">
      Is this the organization you're trying to find?
    </div>

    <div
      class="content col-12 px-0 text-center m-auto d-flex justify-content-center align-items-center"
    >
      <div>
        <div class="orgInfo d-flex justify-content-center align-items-center">
          <div>
            <div class="orgName">
              Organization Name : {{ organization.name }}
            </div>

            <div class="url">
              Organization Url :
              <span
                class="orgUrl"
                (click)="redirectToUrl(organization.subdomain)"
                >{{ organization.subdomain }}.ls.works
              </span>
            </div>
          </div>
        </div>

        <div class="buttons">
          <app-simple-button
            (click)="resetOrganization()"
            class="mx-1"
            [text]="'Search again'"
          >
          </app-simple-button>

          <app-simple-button
            (click)="redirectToUrl(organization.subdomain)"
            class="mx-1"
            [text]="'Go to org url'"
          >
          </app-simple-button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="orgContainer"
    *ngIf="!organization && searchComplete"
  >
    <div class="errorHeader">
      <div>We could not find the organization you're looking for.</div>
      <div>Try searching again or go to LS Works website.</div>
    </div>

    <div class="buttons btnSearchOrg">
      <app-simple-button
        (click)="resetOrganization()"
        [text]="'Search again'"
      >
      </app-simple-button>

      <app-simple-button
        (click)="redirectToLsWorks()"
        [text]="'Go to LS Works website'"
      >
      </app-simple-button>
    </div>
  </div>
</div>
