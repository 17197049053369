<app-main-layout
  [breadcrumbs]="[
    { name: 'Home', url: '/' },
    { name: 'Documents', url: '/Documents' }
  ]"
>
  <div class="container">
    <div class="lang">
      <mat-form-field appearance="fill">
        <mat-label>Language</mat-label>
        <mat-select
          (selectionChange)="selectLanguage($event)"
          [(value)]="languageSelected"
        >
          <mat-option
            *ngFor="let language of languageList"
            [value]="language"
          >
            {{ language }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <h1>Document list</h1>
    <div class="card-list">
      <mat-card
        class="card p-2"
        *ngFor="let doc of filteredDocuments"
        (click)="goTo(doc.url)"
      >
        <div class="h-25">
          <h2 class="text-center">{{ doc.title }}</h2>
        </div>

        <div class="h-75 d-flex align-items-center">
          <img
            class="w-100"
            mat-card-image
            [src]="doc.thumbnail"
          />
        </div>
      </mat-card>
    </div>
  </div>
</app-main-layout>
