<app-public-layout>
  <div
    id="Pricing"
    class="inner-content"
  >
    <div class="img-container">
      <img src="./assets/img/NeurAlign text logo.png" />
    </div>

    <div class="row">
      <div class="plans col-4">
        <app-pricing-card
          *ngFor="let bundle of bundles"
          [isProBundle]="bundle.isProBundle"
          [title]="bundle.title"
          [price]="bundle.price"
          [includedFeatures]="bundle.includedFeatures"
          [studentsIncluded]="bundle.studentsIncluded"
        ></app-pricing-card>
      </div>

      <div class="description col-8">
        <div class="innerContent">
          <p class="title">Neuralign</p>
          <b>A comprehensive research based reading intervention.</b><br />
          This 40+ hours web based reading intervention implements strategies
          based on the latest research to stimulate the auditory visual sensory
          system.<br />
          <br />
          LSWorks is a not-for-profit learning intervention tech company
          striving to deliver high end learning interventions in a way that is
          accessible for as many individuals as possible.
        </div>
      </div>

      <div
        class="whatYouNeed"
        style="margin: 10px 0px 30px 0px"
      >
        <p class="title">What you need:</p>
        <p>
          Over­-ear headset, keyboard, external mouse, computer, 21” to 35”
          monitor or TV.<br /><br />
          <b>Recommended specs for a PC desktop:</b> i3 or above processor, 6GB
          or more RAM, graphics card with 2GB internal memory.<br /><br />
          <b>Recommended specs for Mac:</b> Any mac0S.<br /><br />
          <b
            >For best results while using a smaller laptop, connect it to a
            larger screen.</b
          ><br /><br />
          Neuralign is a WebGL based SaaS. Firefox browser is preferred.
          <a
            target="_blank"
            href="https://get.webgl.org/"
            >CLICK HERE</a
          >
          to test your browser’s compatibility. If the square is turning then
          your browser supports WebGL.<br /><br />
          Set up your work station in a quiet uninterrupted place.
        </p>
      </div>
    </div>

    <!-- <p class="obs" >
    * Pro bundles are only available for organizations.
  </p> -->
  </div>
</app-public-layout>
