<div class="mainContainer">
  <div class="col-12 px-0 header text-center">{{ data?.name }}</div>

  <div class="categoryInfo p-4">
    <div *ngFor="let info of getInfoObjectKeys()">
      <span class="infoTitle"> {{ info }} </span> :
      <span> {{ categoryInfo[info] }} </span>
    </div>
    <div *ngIf="data.isBonus">
      <span class="infoTitle"> Starting session </span> : {{ data.name }} is a
      bonus category and begins on session {{ data.startingSession + 1 }} giving
      you {{ data.maxSessions }} extra sessions.
    </div>
  </div>
</div>
