<div
  class="mat-elevation-z8 col-10 px-0 m-auto"
  *ngIf="regularSubscriptions"
>
  <table
    mat-table
    [dataSource]="dataSource"
    class="col-12 px-0 text-center mt-4 subscriptionInfo"
  >
    <div class="table">
      <ng-container
        matColumnDef="cost"
        class="text-center"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          class="text-center"
        >
          Cost
        </th>
        <td
          mat-cell
          *matCellDef="let subscription"
          class="text-center"
        >
          {{ subscription.currency }} {{ subscription.cost }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="tokens"
        class="text-center"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          class="text-center"
        >
          Tokens
        </th>
        <td
          mat-cell
          *matCellDef="let subscription"
          class="text-center"
        >
          {{ subscription.tokens }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="status"
        class="text-center"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          class="text-center"
        >
          Status
        </th>
        <td
          mat-cell
          *matCellDef="let subscription"
          class="text-center"
        >
          <span>
            <fa-icon
              class="mr-2 {{ getSubscriptionClass(subscription) }}"
              [icon]="circle"
            >
            </fa-icon>
            {{ getSubscriptionStatus(subscription) }}
          </span>
        </td>
      </ng-container>

      <ng-container
        matColumnDef="next charge"
        class="text-center"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          class="text-center"
        >
          Next charge
        </th>
        <td
          mat-cell
          *matCellDef="let subscription"
          class="text-center"
        >
          {{ getDate(subscription.currentPeriodEnd) }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="card"
        class="text-center"
      >
        <th
          class="text-center"
          mat-header-cell
          *matHeaderCellDef
        >
          Card
        </th>
        <td
          mat-cell
          *matCellDef="let subscription"
          class="text-center"
        >
          <fa-icon
            *ngIf="isActiveSubscription(subscription)"
            class="cardIcon"
            [icon]="returnCardIcon(subscription)"
          ></fa-icon>
          <br />
          {{ returnSubscriptionSource(subscription) }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="actions"
        class="text-center"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          class="text-center"
        >
          Actions
        </th>
        <td
          mat-cell
          *matCellDef="let subscription"
          class="actionIcons text-center"
        >
          <a
            matTooltip="Cancel subscription"
            matTooltipPosition="above"
          >
            <fa-icon
              class="mx-2 regularIcon"
              [icon]="cancel"
              (click)="cancelSubscription(subscription)"
              [ngClass]="{ disabledIcon: subscription.underCancelation }"
            >
            </fa-icon>
          </a>

          <a
            matTooltip="Activate subscription"
            matTooltipPosition="above"
          >
            <fa-icon
              class="mx-2 regularIcon"
              [icon]="reactivate"
              (click)="activateSubscription(subscription)"
              [ngClass]="{ disabledIcon: !subscription.underCancelation }"
            >
            </fa-icon>
          </a>

          <a
            matTooltip="Check last payments"
            matTooltipPosition="above"
          >
            <fa-icon
              class="mx-2 regularIcon"
              [icon]="receipt"
              (click)="findSubscriptionInvoices(subscription)"
            >
            </fa-icon>
          </a>

          <a
            matTooltip="Change payment settings"
            matTooltipPosition="above"
          >
            <fa-icon
              (click)="adjustPaymentSettings(subscription)"
              class="mx-2 regularIcon"
              [icon]="card"
            >
            </fa-icon>
          </a>
        </td>
      </ng-container>
    </div>

    <tr
      mat-header-row
      *matHeaderRowDef="displayedColumns"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
    ></tr>
  </table>

  <mat-paginator
    class="col-12 row px-0 mx-0"
    [pageSize]="5"
    aria-label="Select page"
  ></mat-paginator>
</div>
