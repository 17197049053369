import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AdminsListService } from './admins-list.service';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { UsersHelperService } from '../../../users-helper.service';
import { MatDialog } from '@angular/material/dialog';
import { OrgAdminAndManagerCreateModalComponent } from '../../../modals/org-admin-manager-create-modal/org-admin-manager-create-modal.component';
import { Subject } from 'rxjs';
import { AdminManager, ManagerActions, Templates, UsersType } from 'src/app/shared/interfaces/Manager.interface';
import { applyFilter } from 'src/app/shared/helpers/applyFilter';

@Component({
  selector: 'users-admins-list',
  templateUrl: './admins-list.component.html',
  styleUrls: ['./admins-list.component.scss'],
})
export class AdminsListComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  protected template: Templates = Templates.TABLE;
  protected dataSource = new MatTableDataSource();

  constructor(
    private _adminsListService: AdminsListService,
    private _logger: LoggerService,
    private _confirm: ConfirmationService,
    private _rest: RestAPIService,
    private _usersService: UsersHelperService,
    private _dialog: MatDialog,
  ) {
    this._adminsListService.currentAdmin.subscribe(async (newAdmin) => {
      if (newAdmin) {
        await this.loadAdmins();
      }
    });
  }

  async ngOnInit() {
    this.template = Templates.LOADING;
    const data = await this._adminsListService.getAdmins();
    this.dataSource = new MatTableDataSource(data);
    this.template = Templates.TABLE;
  }

  async loadAdmins() {
    this.template = Templates.LOADING;
    const data = await this._adminsListService.getAdmins({ refresh: true });
    this.dataSource = new MatTableDataSource(data);
    this.template = Templates.TABLE;
  }

  public createAdmin() {
    this._dialog.open(OrgAdminAndManagerCreateModalComponent, {
      data: {
        type: ManagerActions.CREATE,
        admin: {
          type: UsersType.ADMIN,
        },
      },
    });
  }
  public editAdmin(admin: AdminManager) {
    this._dialog.open(OrgAdminAndManagerCreateModalComponent, {
      data: {
        type: ManagerActions.UPDATE,
        admin: admin,
      },
    });
  }

  public resendEmailVerification(admin: AdminManager) {
    this._adminsListService.resendAdminVerificationEmail(admin);
  }

  public async deleteAdmin(admin: AdminManager) {
    this._confirm
      .createConfirmation('Warning', 'Are you sure you want to delete this admin?', 'Yes', 'No')
      .then(async () => {
        try {
          await this._rest.delete('patron/' + admin.patron.id, { msg: 'Could not delete admin.' });

          const data = await this._adminsListService.getAdmins({ refresh: true });
          this.dataSource = new MatTableDataSource(data);
        } catch (err) {
          this._logger.error(err);
        }
      });
  }

  public applyFilter(event: Event): void {
    this.dataSource = applyFilter(event, this.dataSource);
  }

  getEmailVerifiedTooltip(isEmailVerified: boolean | undefined): string {
    return this._usersService.getEmailVerifiedTooltip(isEmailVerified);
  }

  public isDataSourceEmpty(): boolean {
    return this.dataSource.data.length === 0;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
