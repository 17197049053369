import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'custom-snackbar',
  templateUrl: './custom-snackbar.component.html',
})
export class CustomSnackBar {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public errors: string[],
    private snackBarRef: MatSnackBarRef<CustomSnackBar>,
  ) {}

  public closeSnackBar() {
    this.snackBarRef.dismiss();
  }
}
