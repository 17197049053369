import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PricingBundle } from './interfaces/pricing-bundle.interface';
import { environment } from 'src/environments/environment';
import { pricingBundles } from './pricing.data';

/**
 * App Pricing Component
 *
 * This component stores bundles using its getBundles() method.
 *
 * @param router An instance of Angular Router.
 * @param auth An instance of our custom auth service.
 *
 * @remarks If openAuth() is not needed, then neither will we need to inject AuthService. See below.
 */
@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss'],
})
export class PricingComponent {
  public bundles: PricingBundle[] = this.getBundles();
  public contactUs = false;

  constructor(private router: Router, private auth: AuthService) {}

  /**
   * @remarks getBundles is used by admin-pricing.component and programs-pricing.component.
   * @remarks Would it make more sense for this method to be in a service?
   * @remarks What's the best way to get this method to fire in the browser?
   *
   * @remarks What is the purpose of the if statement? What's being done here?
   */
  public getBundles() {
    // Get bundle data from pricing.data.ts
    let bundles = pricingBundles;

    // If the location starts with 'read' set
    // the bundles to just basic features
    if (location.hostname.split('.')[0] === 'read') {
      bundles = [bundles[0]];

      // Else if, location is equal to the envirnoment
      // move the user to the contact page
    } else if (location.hostname === environment.ROOT_DOMAIN_URL) {
      this.router.navigate(['/contact']);

      // Else, navigate the user to the home page
    } else {
      this.router.navigate(['/']);
    }

    // Return either intro bundle or all the bundles
    return bundles;
  }

  /**
   * @remarks What's calling this method?
   * @remarks It doesn't appear to be called in the codebase.
   * @remarks Perhaps we deprecate it?
   */
  public openAuth(isProBundle: boolean): void {
    if (isProBundle) {
      this.auth.isAuthenticated() ? this.router.navigate(['register']) : this.auth.show();
    } else {
      (window as any).location = '//read.' + environment.ROOT_DOMAIN_URL + '?auth=true';
    }
  }
}
