import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { RestAPIService } from '../../services/rest/rest-api.service';
import { MaterialComponentsModule } from 'src/app/shared/modules/material-components.module';
import { LayoutComponentsModule } from 'src/app/shared/components/layout/layout-components.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PurchaseComponent } from './purchase.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { LayoutsModule } from 'src/app/shared/layouts/layouts.module';
import { RoundUpModule } from 'src/app/shared/pipes/round-up/round-up.module';

@NgModule({
  declarations: [PurchaseComponent],
  imports: [
    BrowserModule,
    RouterModule,
    HttpClientModule,
    MaterialComponentsModule,
    LayoutComponentsModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule,
    LayoutsModule,
    RoundUpModule,
  ],
  providers: [RestAPIService],
})
export class PurchaseModule {}
