<app-public-layout>
  <div class="about-us">
    <div class="hero">
      <p>
        Understanding dyslexic brains is what sets us apart. Neuralign was built
        by dyslexics, for dyslexics.
      </p>
      <img
        src="./assets/img/AboutUsHeader.jpg"
        alt="Boy learning to read."
        width="100%"
      />
    </div>

    <div class="intro">
      <h2>{{ data.intro.title }}</h2>
      <p>{{ data.intro.mission }}</p>
    </div>

    <div class="foundedBy">
      <p *ngFor="let paragraph of data.foundedBy.paragraphs">{{ paragraph }}</p>
    </div>

    <div class="ingridsStory">
      <h1>{{ data.ingridsStory.title }}</h1>
      <p *ngFor="let paragraph of data.ingridsStory.paragraphs">
        {{ paragraph }}
      </p>
    </div>

    <div class="green-hr"></div>

    <!-- MEET THE TEAM -->
    <h2 class="our-team">Our Team</h2>
    <div class="team">
      <!-- Admin -->
      <div
        class="person"
        *ngFor="let member of teamMembers"
      >
        <div
          class="image"
          [style.backgroundImage]="'url(' + member.image + ')'"
        >
          &nbsp;
        </div>
        <p class="name">{{ member.name }}</p>
        <p class="title">{{ member.title }}</p>
        <p class="bio">
          <a
            *ngIf="member.hasBio"
            [routerLink]="['/biography']"
            [queryParams]="{ debug: true }"
            fragment="{{ member.id }}"
          >
            >>Go to biography page
          </a>
        </p>
      </div>
    </div>
  </div>
</app-public-layout>
