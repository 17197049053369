export const french = {
  beforeYouStart: {
    title: 'Tu es presque prêt! Avant de commencer, voici quelques conseils:',
    quietLocation: 'Travaillez dans un endroit calme et sans distraction.',
    wearConfortable: 'Portez un casque d’écoute confortable. Assurez-vous que l’audio gauche et droit fonctionnent.',
    externalMouse: 'Utilisez une souris externe.',
    keyboardHandy: 'Ayez un clavier à portée de main.',
    clickOnArrows: 'Optimisez toujours vos jeux en cliquant sur les flèches dans le coin inférieur droit.',
    userWideMonitor: 'Utilisez un moniteur de 21 à 35 pouces pour de meilleurs résultats.',
    pressP: 'Appuyez sur la lettre «P» de votre clavier pour mettre en pause et réactiver le programme.',
    pressSpaceBar: 'Appuyez sur la barre d’espace pour répéter l’audio.',
    takeDeepBreath:
      'Respirez profondément, détendez-vous et appuyez sur le bouton «Thérapie cognitive» lorsque vous êtes prêt à commencer.',
    scoresRecorded:
      'Lorsque vous avez terminé la session, vos scores seront enregistrés et s’afficheront automatiquement sur le graphique.',
    gotIt: 'JE L’AI!',
    start: 'Départ!',
  },
  program: {
    week: 'Semaine',
    day: 'Jour',
    hours: 'heures',
    minutes: 'minutes',
    seconds: 'secondes',
    playNextSessionAllowed: 'Vous pouvez jouer la prochaine session.',
    completedCognitiveTherapy: 'Félicitations, vous avez terminé la Thérapie Cognitive.',
    playNextSessionTimeTo: 'Vous pourrez jouer la prochaine session (session ${session}) en ',
    triesLeft: 'Il vous reste ${tries} essais.',
    description1: 'Vous jouez à ${level} session ${session}',
    description2: "Vous complétez l'évaluation ${session}",
    description3: 'Vous jouez à ${level} Niveau ${currentWeek} Jour ${day}',
    playFromBeginning: 'Jouer depuis le début',
    cancel: 'Cancel',
    loadGame: 'Charger le jeu',
    cognitiveTherapy: {
      title: 'Thérapie Cognitive',
      subTitle: 'You are playing % Level % Day %',
    },
    speedReading: {
      title: 'Lecture Rapide',
      subTitle: '',
    },
    readingExercises: {
      title: 'Exercices de lecture',
      subTitle: '',
    },
    assessment: {
      title: 'Évaluation',
      subTitle: '',
    },
  },
  warnings: {
    unsupportedBrowser:
      'Your current browser does not support WebGL, which may result in reduced functionality and performance issues while using Neuralign. Please switch to a compatible browser before continuing. Visit get.webgl.org for a list of compatible browsers.', // translation needed
  },
  header: {
    welcomeText: 'Salut',
  },
};
