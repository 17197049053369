import { Component, OnInit } from '@angular/core';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { ProgramInfo } from '../students/interfaces/program-info.interface';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faGamepad } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.scss'],
})
export class ProgramsComponent implements OnInit {
  public readonly displayedColumns: string[] = ['name', 'actions'];
  public programs: ProgramInfo[] = [];
  public gamepadIcon: IconDefinition = faGamepad;

  constructor(private _rest: RestAPIService) {}

  ngOnInit() {
    this._loadPrograms();
  }

  private async _loadPrograms(): Promise<void> {
    try {
      const response = await this._rest.get('programs', { msg: 'Could not get programs.' });
      this.programs = response.programs;
      // eslint-disable-next-line no-empty
    } catch {}
  }
}
