import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IconDefinition, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { PricingBundle } from 'src/app/pages/pricing/interfaces/pricing-bundle.interface';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UpdateBundleDialogComponent } from './update-bundle-dialog/update-bundle-dialog.component';

@Component({
  selector: 'app-default-bundle-card',
  templateUrl: './default-bundle-card.component.html',
  styleUrls: ['./default-bundle-card.component.scss'],
})
export class DefaultBundleCardComponent {
  @Input() bundle: PricingBundle;
  @Input() user;
  @Input() order;
  @Input() customRegularBundles;
  @Output() saveEvent = new EventEmitter<any>();
  @Output() bundleToDefaultEvent = new EventEmitter<any>();

  public editIcon: IconDefinition = faEdit;
  public deleteIcon: IconDefinition = faTrash;
  public amount: number;

  constructor(private _router: Router, private dialog: MatDialog) {}

  public editBundle(bundle) {
    const dialog = this.dialog.open(UpdateBundleDialogComponent, {
      data: {
        bundle,
        user: this.user,
        order: this.order,
      },
      width: '350px',
      panelClass: 'modal-border',
    });

    dialog.afterClosed().subscribe((shouldReload) => {
      if (shouldReload) {
        this.saveEvent.emit(this.user);
      }
    });
  }

  public async setRegularBundleToDefault(id) {
    this.bundleToDefaultEvent.emit(id);
  }
}
