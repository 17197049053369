import { isDevMode } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from 'src/environments/environment';

// Set environment tag for Sentry

export function initializeSentry() {
  // initializing Sentry more than once breaks the app because of multipe instances of replay integrations
  const isAlreadyInitialized = Sentry.getClient();
  if (isAlreadyInitialized !== undefined) {
    return; // if Sentry is already initialized, no op
  }

  if (isDevMode()) {
    console.log('Loading Sentry...');
  }

  let env: 'dev' | 'prod' | 'localhost';

  const url = window.location.toString();

  if (url.includes('localhost')) {
    // uncomment to debug localhost, otherwise no-op
    // env = 'localhost';
  } else if (url.includes('dev')) {
    env = 'dev';
  } else {
    env = 'prod';
  }

  Sentry.init({
    dsn: environment.SENTRY_DSN,
    environment: env,
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      Sentry.browserTracingIntegration(),
      // Registers the Replay integration,
      // which automatically captures Session Replays
      Sentry.replayIntegration(),
      // Enable canvas recording with Replay
      Sentry.replayCanvasIntegration(),
      // Add browser profiling integration to the list of integrations
      new Sentry.BrowserProfilingIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^\//],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // results in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 1.0,

    beforeSend(event, hint) {
      if (hint?.data?.code == 401) {
        // filter out 401 errors
        return null;
      }

      return event;
    },
  });
}
