import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DatepickerService {
  constructor() {}

  public maxIterationDays = 120;

  public studentAgenda = {
    cognitiveTherapy: [],
    readingExercises: [],
    speedReading: [],
  };

  public datePicker = (startDate, weekDays) => this.studentAgendaBuilder(startDate, this.maxIterationDays, weekDays);

  public reset() {
    this.studentAgenda = {
      cognitiveTherapy: [],
      readingExercises: [],
      speedReading: [],
    };
  }

  public fillReadinExercisesDates(weekDays) {
    const selectedDates = this.studentAgenda.cognitiveTherapy;
    const endDate: Date = selectedDates[selectedDates.length - 1];
    const dayOftheWeek = endDate.getDay();
    const goToNextWeek = 6 - dayOftheWeek + 1;
    const readingExercisesStartDate = new Date(endDate).setDate(endDate.getDate() + goToNextWeek);
    const dateFormat = new Date(readingExercisesStartDate);

    for (let i = 0; i < 70; i++) {
      const date = new Date(dateFormat).setDate(dateFormat.getDate() + i);
      const newDate = new Date(date);
      const allowedDays = weekDays.find((d) => d === newDate.getDay());

      if (allowedDays) {
        this.studentAgenda.readingExercises.push(newDate);
      }
    }
  }

  public fillSpeedReadingDates(weekDays) {
    const selectedDates = this.studentAgenda.cognitiveTherapy;
    const start = new Date(selectedDates[9]);

    for (let i = 0; i < 70; i++) {
      const date = new Date(start).setDate(start.getDate() + i);
      const newDate = new Date(date);
      const allowedDays = weekDays.find((d) => d === newDate.getDay());

      if (allowedDays) {
        this.studentAgenda.speedReading.push(newDate);
      }
    }
  }

  private studentAgendaBuilder(startDate, days, weekDays) {
    this.reset();

    let iterableDate = new Date(startDate);

    for (let i = 0; i < days; i++) {
      if (this.studentAgenda.cognitiveTherapy.length === 15) {
        this.fillSpeedReadingDates(weekDays);
        this.fillReadinExercisesDates(weekDays);
        return this.studentAgenda;
      }

      const day = iterableDate.getDay();
      const allowedDays = weekDays.find((d) => d === day);

      if (allowedDays) {
        this.studentAgenda.cognitiveTherapy.push(iterableDate);
        const newDate = new Date(iterableDate).setDate(iterableDate.getDate() + 1);
        iterableDate = new Date(newDate);
      } else {
        const newDate = new Date(iterableDate).setDate(iterableDate.getDate() + 1);
        iterableDate = new Date(newDate);
      }
    }
  }
}
