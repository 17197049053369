<div class="main">
  <div class="container">
    <div class="title">
      <h2 *ngIf="data.type == 'create'">Create Student</h2>
      <h2 *ngIf="data.type == 'update'">Update Student</h2>
    </div>

    <div class="profile-photo-container">
      <div
        *ngIf="studentForm.image"
        class="profile-photo-preview"
      >
        <img
          [src]="
            studentForm.image || './assets/img/StudentImagePlaceholder.png'
          "
          alt="Student photo preview"
          class="preview-image"
          (click)="addStudentImage()"
        />
      </div>
      <div
        *ngIf="!studentForm.image"
        class="profile-photo-button"
      >
        <button (click)="addStudentImage()">
          <fa-icon [icon]="camera"></fa-icon>
        </button>
      </div>
    </div>

    <form
      #form="ngForm"
      novalidate
    >
      <div class="input-group">
        <div class="input-block">
          <label for="studentForm.fullname">Full Name <span>*</span></label>
          <input
            type="text"
            name="studentForm.fullname"
            [(ngModel)]="studentForm.fullname"
            required
            #fullname="ngModel"
            fullname
            [ngClass]="{
              'is-invalid':
                fullname.invalid && (fullname.dirty || fullname.touched)
            }"
          />
          <div
            *ngIf="fullname.invalid && (fullname.dirty || fullname.touched)"
            class="invalid-feedback"
          >
            <div *ngIf="fullname.errors?.required">
              The fullname is required.
            </div>
          </div>
        </div>

        <div class="input-block">
          <label for="studentForm.nickname">Nickname</label>
          <input
            type="text"
            name="studentForm.nickname"
            [(ngModel)]="studentForm.nickname"
          />
        </div>
      </div>

      <div
        *ngIf="isSchoolSetup"
        class="input-group"
      >
        <div class="input-block">
          <label for="studentEmail">Email <span>*</span></label>
          <input
            [disabled]="student"
            type="text"
            name="studentEmail"
            [(ngModel)]="studentEmail"
          />
        </div>
        <div class="input-block">
          <label>Grade</label>
          <input
            min="0"
            type="number"
            name="grade"
            [(ngModel)]="studentForm.grade"
          />
        </div>
      </div>

      <!-- input-group select(theme e language) -->
      <div class="input-group">
        <div class="input-block-select">
          <label>Theme <span>*</span></label>
          <select
            [(ngModel)]="studentForm.theme"
            name="theme"
            id="organizationForm.theme"
            required
            #theme="ngModel"
            [ngClass]="{
              'is-invalid': theme.invalid && (theme.dirty || theme.touched)
            }"
          >
            <option
              value=""
              disabled
              selected
            >
              Select Theme
            </option>
            <option
              *ngFor="let theme of themes"
              [value]="theme.id"
            >
              {{ theme.label?.en_ca }}
            </option>
          </select>
          <div
            *ngIf="theme.invalid && (theme.dirty || theme.touched)"
            class="invalid-feedback"
          >
            <div *ngIf="theme.errors?.['required']">
              Theme selection is required
            </div>
          </div>
        </div>

        <div class="input-block-select">
          <label>Language <span>*</span></label>
          <select
            [(ngModel)]="studentForm.language"
            name="language"
            id="organizationForm.language"
            required
            #language="ngModel"
            [ngClass]="{
              'is-invalid':
                language.invalid && (language.dirty || language.touched)
            }"
          >
            <option
              value=""
              disabled
              selected
            >
              Select Language
            </option>
            <option
              *ngFor="let lang of languages"
              [value]="lang.languageCode"
            >
              {{ lang.name }}
            </option>
          </select>
          <div
            *ngIf="language.invalid && (language.dirty || language.touched)"
            class="invalid-feedback"
          >
            <div *ngIf="language.errors?.['required']">
              Language selection is required
            </div>
          </div>
        </div>
      </div>
      <!-- input-block birthdate -->
      <div class="input-group">
        <div class="input-block">
          <label>Birthdate</label>
          <input
            type="date"
            name="birthdate"
            [(ngModel)]="studentForm.birthdate"
          />
        </div>

        <div class="input-block-select">
          <label>Gender</label>
          <select
            [(ngModel)]="studentForm.gender"
            name="gender"
          >
            <option
              value=""
              disabled
              selected
            >
              Select Gender
            </option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
            <option value="Prefer not to disclosure">
              Prefer not to disclose
            </option>
          </select>
        </div>
      </div>

      <div class="student-config">
        <div class="student-config-left">
          <div class="checkbox-container">
            <label class="custom-checkbox">
              <input
                type="checkbox"
                id="studentForm.enableHomeAccess"
                name="enableHomeAccess"
                [(ngModel)]="studentForm.enableHomeAccess"
              />
              <span class="checkmark"></span>
              <span class="checkbox-label">Allow home access</span>
            </label>
          </div>
          <div class="checkbox-container">
            <label class="custom-checkbox">
              <input
                type="checkbox"
                id="studentForm.alertOnSessionEnd"
                name="alertOnSessionEnd"
                [(ngModel)]="studentForm.alertOnSessionEnd"
              />
              <span class="checkmark"></span>
              <span class="checkbox-label"
                >Send me an email when student completes a session</span
              >
            </label>
          </div>
        </div>
        <div class="stutend-config-right">
          <button
            *ngIf="!checkIfHasSavedAgenda()"
            class="btn-primary"
            (click)="openOptionsDialog()"
          >
            <fa-icon [icon]="calendar"></fa-icon>
            <span>Create Agenda</span>
          </button>

          <button
            *ngIf="checkIfHasSavedAgenda()"
            class="btn-primary"
            (click)="openStudentAgenda()"
          >
            <fa-icon [icon]="calendar"></fa-icon>
            <span>Open Agenda</span>
          </button>
        </div>
      </div>

      <div
        class="button-group"
        *ngIf="!loading"
      >
        <button
          class="btn-secondary"
          (click)="close()"
        >
          Close
        </button>
        <button
          [disabled]="!form.valid"
          [ngClass]="{ disabled: shouldDisableSubmitBtn() }"
          class="btn-primary"
          (click)="save()"
        >
          Save
        </button>
      </div>

      <div
        class="loading mt-2"
        *ngIf="loading"
      >
        <fa-icon
          class="mx-2"
          [icon]="spinnerIcon"
          [spin]="true"
        >
        </fa-icon>

        <div>Please wait, the student is being saved.</div>
      </div>
    </form>
  </div>
</div>
