<div
  class="col-12 themesContainer px-0 d-flex mt-2"
  *ngIf="globalThemes && langDataSource && languagesList && organizationList"
>
  <div class="col-5 px-0 firstContainer h-100">
    <div class="col-10 px-0 my-4 d-flex justify-content-center">
      <button
        (click)="openNewThemeDialog()"
        class="m-auto"
        mat-raised-button
        color="primary"
      >
        Add new theme
      </button>
    </div>

    <div
      class="row col-10 py-2 border"
      *ngFor="let themes of globalThemes; let i = index"
      (click)="selectTheme(themes)"
      [ngClass]="{ selected: selectedTheme === themes }"
    >
      <div class="col-9 themeName">{{ themes.label.en_ca }}</div>
      <div
        class="col-2 enabled"
        *ngIf="themes.isEnabled"
      >
        <fa-icon [icon]="save"></fa-icon>
      </div>
      <div
        class="col-2 disabled"
        *ngIf="!themes.isEnabled"
      >
        <fa-icon [icon]="cancel"></fa-icon>
      </div>
    </div>
  </div>

  <div
    class="col-4 p-3 secondContainer mt-4 ml-1 h-100"
    *ngIf="selectedTheme"
  >
    <div class="saveButton ml-3 text-center mb-3">
      <button
        mat-raised-button
        color="primary"
        class="mx-2"
        (click)="updateTheme()"
      >
        Save
      </button>

      <button
        mat-raised-button
        class="ml-3"
        color="primary"
        class="mx-2"
        (click)="deleteTheme()"
      >
        Delete
      </button>
    </div>

    <div class="toggleTheme ml-2 text-center">
      <mat-radio-group
        name="themeEnable"
        [value]="selectedTheme.isEnabled"
      >
        <mat-radio-button
          class="example-margin mx-2"
          [value]="selectedTheme.isEnabled"
          [checked]="selectedTheme.isEnabled"
          [disabled]="selectedTheme.isEnabled"
          (click)="toggleTheme()"
          [ngClass]="{ disabled: selectedTheme.isEnabled }"
          *ngIf="!selectedTheme.isDefault"
          >Enable</mat-radio-button
        >

        <mat-radio-button
          class="example-margin mx-2"
          [value]="!selectedTheme.isEnabled"
          [checked]="!selectedTheme.isEnabled"
          [disabled]="!selectedTheme.isEnabled"
          (click)="toggleTheme()"
          [ngClass]="{ disabled: !selectedTheme.isEnabled }"
          *ngIf="!selectedTheme.isDefault"
          >Disable</mat-radio-button
        >

        <mat-radio-button
          class="example-margin mx-2"
          [checked]="selectedTheme.isDefault"
          [disabled]="selectedTheme.isDefault"
          (click)="toggleTheme()"
          [ngClass]="{ disabled: selectedTheme.isDefault }"
          *ngIf="selectedTheme.isDefault"
          >Default theme ( Always enabled )</mat-radio-button
        >
      </mat-radio-group>
    </div>

    <div
      class="col-7 mx-auto px-0 d-flex justify-content-between align-items-center my-3"
    >
      <mat-checkbox [(ngModel)]="selectedTheme.appendToGame">
        Append to Game
      </mat-checkbox>

      <fa-icon
        class="defaultIcon mx-3"
        [icon]="interrogationIcon"
        matTooltipClass="instructionTooltip"
        [matTooltip]="getInstructionMessage('appendToGame')"
        matTooltipPosition="above"
      >
      </fa-icon>
    </div>

    <div
      class="col-7 mx-auto px-0 d-flex justify-content-between align-items-center my-3"
    >
      <mat-checkbox [(ngModel)]="selectedTheme.avaliableForDemo">
        Enabled for demos
      </mat-checkbox>

      <fa-icon
        class="defaultIcon mx-3"
        [icon]="interrogationIcon"
        matTooltipClass="instructionTooltip"
        [matTooltip]="getInstructionMessage('enabledForDemos')"
        matTooltipPosition="above"
      >
      </fa-icon>
    </div>

    <div class="label my-2 ml-3">
      <div class="d-flex align-items-center">
        <span class="subTitle"> Label </span>
        <fa-icon
          *ngIf="!addingNewLang"
          class="submit mx-2"
          [icon]="plusLine"
          matTooltip="Add new label"
          matTooltipPosition="above"
          (click)="addingNewLang = true"
        ></fa-icon>
      </div>

      <div
        *ngIf="addingNewLang"
        class="addLang"
      >
        <input
          class="addLangInput"
          [(ngModel)]="lang"
          autocomplete="off"
          placeholder="New label"
        />
        <fa-icon
          class="mt-1 mx-2 submit"
          [icon]="save"
          (click)="saveLabel()"
        ></fa-icon>
        <fa-icon
          class="mt-1 mx-2 cancel"
          [icon]="cancel"
          (click)="addingNewLang = !addingNewLang"
        ></fa-icon>
      </div>

      <div class="p-2 col-12">
        <div
          *ngFor="let label of themeLabel"
          class="my-2 inputLabel col-12 px-0"
        >
          <div class="col-3 px-0">
            <span class="labelInput"> {{ label }} </span>
          </div>

          <div class="col-8 px-0">
            <input
              class="w-100"
              [(ngModel)]="selectedTheme.label[label]"
            />
          </div>

          <div class="col-1 px-0">
            <fa-icon
              *ngIf="label !== 'en_ca' && label !== 'fr_ca'"
              class="ml-2 cancel"
              [icon]="cancel"
              matTooltip="Delete label"
              matTooltipPosition="above"
              (click)="deleteLabel(label)"
            ></fa-icon>
          </div>
        </div>
      </div>
    </div>

    <div class="variableName my-2 ml-3">
      <div class="d-flex align-items-center">
        <span class="subTitle"> Variable name</span> <br />
        <fa-icon
          class="defaultIcon mx-3"
          [icon]="interrogationIcon"
          matTooltipClass="instructionTooltip"
          [matTooltip]="getInstructionMessage('variableName')"
          matTooltipPosition="above"
        >
        </fa-icon>
      </div>

      <div class="keyInput d-flex">
        <div class="col-3 px-0 d-flex align-items-center">
          <span class="variableNameInput"> name </span>
        </div>

        <div class="col-8 px-0">
          <input
            class="w-100"
            [(ngModel)]="selectedTheme.variableName"
          />
        </div>
      </div>
    </div>

    <div class="index my-2 ml-3">
      <div class="d-flex align-items-center">
        <span class="subTitle"> Overlay Index</span> <br />

        <fa-icon
          class="defaultIcon mx-3"
          [icon]="interrogationIcon"
          matTooltipClass="instructionTooltip"
          [matTooltip]="getInstructionMessage('overlay')"
          matTooltipPosition="above"
        >
        </fa-icon>
      </div>

      <div class="keyInput d-flex mx-2">
        <div class="col-3 px-0 d-flex align-items-center">
          <span class="variableNameInput"> overlay </span>
        </div>

        <div class="col-8 px-0">
          <input
            type="number"
            class="w-100 defaultInput"
            [(ngModel)]="selectedTheme.overlay"
          />
        </div>
      </div>
    </div>

    <div class="enabledLanguages my-2 ml-3">
      <span class="subTitle">Languages</span> <br />

      <table
        mat-table
        [dataSource]="langDataSource"
        class="col-12 px-0"
        multiTemplateDataRows
      >
        <ng-container matColumnDef="name">
          <th
            mat-header-cell
            *matHeaderCellDef
          >
            Name
          </th>
          <td
            mat-cell
            *matCellDef="let lang"
          >
            {{ lang.name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="code">
          <th
            mat-header-cell
            *matHeaderCellDef
          >
            Code
          </th>
          <td
            mat-cell
            *matCellDef="let lang"
          >
            {{ lang.languageCode }}
          </td>
        </ng-container>

        <ng-container matColumnDef="enabled">
          <th
            class="text-center"
            mat-header-cell
            *matHeaderCellDef
          >
            Enabled
          </th>
          <td
            mat-cell
            *matCellDef="let lang"
            class="text-center"
          >
            <mat-checkbox
              (click)="addNewLanguage(lang)"
              [checked]="checkIfLanguageIsEnabled(lang.id)"
            >
            </mat-checkbox>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="langDisplayedColumns"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: langDisplayedColumns"
          class="example-element-row"
        ></tr>
      </table>
    </div>

    <div
      class="enabledLevels my-2 ml-3"
      *ngIf="selectedTheme.languages"
    >
      <span class="subTitle">Enabled levels</span> <br />

      <mat-accordion
        multi
        *ngIf="selectedTheme.languages.length > 0"
      >
        <mat-expansion-panel
          *ngFor="let languages of selectedTheme.languages"
          class="my-1"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ returnLanguageLabel(languages.languageId) }}
            </mat-panel-title>
            <mat-panel-description>
              Expand to see the enabled levels
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div class="levels my-1">
            <div class="pb-3 pt-2 px-1">
              <div
                *ngFor="let levels of languages.enabledLevels; let i = index"
              >
                <mat-checkbox [(ngModel)]="levels.enabled"
                  >{{ levels.level }}
                </mat-checkbox>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

      <div
        class="warning"
        *ngIf="selectedTheme.languages.length === 0"
      >
        You dont have any active languages. Add a language to pick the enabled
        levels for it
      </div>
    </div>

    <div class="categories my-2 ml-3">
      <span class="subTitle">Enabled categories</span> <br />

      <table
        *ngIf="categoriesDataSource"
        class="col-12"
        mat-table
        [dataSource]="categoriesDataSource"
        multiTemplateDataRows
      >
        <ng-container matColumnDef="name">
          <th
            mat-header-cell
            *matHeaderCellDef
          >
            Name
          </th>
          <td
            mat-cell
            *matCellDef="let category"
          >
            {{ category.name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="enabled">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="text-center"
          >
            Enabled
          </th>
          <td
            mat-cell
            *matCellDef="let category"
            class="text-center"
          >
            <mat-checkbox
              (click)="addNewCategory(category)"
              [checked]="checkIfCategoryIsEnabled(category.id, category.name)"
            >
            </mat-checkbox>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="categoriesDisplayedColumns"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: categoriesDisplayedColumns"
        ></tr>
      </table>
    </div>
  </div>

  <div
    class="orgListContainer mt-4 col-4 px-0"
    *ngIf="selectedTheme && selectedTheme.isEnabled"
  >
    <div class="mx-3">
      <mat-radio-group
        class="d-flex"
        name="themeEnablePerOrg"
        [value]="selectedTheme.enabledToAll"
      >
        <mat-radio-button
          class="example-margin mx-2"
          [value]="selectedTheme.enabledToAll"
          [checked]="selectedTheme.enabledToAll"
          [disabled]="selectedTheme.enabledToAll"
          [ngClass]="{ disabled: selectedTheme.enabledToAll }"
          *ngIf="!selectedTheme.isDefault"
          (click)="changeEnabledStatus()"
          >Enable to all users
        </mat-radio-button>

        <mat-radio-button
          class="example-margin mx-2"
          [value]="!selectedTheme.enabledToAll"
          [checked]="!selectedTheme.enabledToAll"
          [disabled]="!selectedTheme.enabledToAll"
          [ngClass]="{ disabled: !selectedTheme.enabledToAll }"
          *ngIf="!selectedTheme.isDefault"
          (click)="changeEnabledStatus()"
          >Enable manually
        </mat-radio-button>

        <mat-radio-button
          class="mx-2"
          [value]="selectedTheme.enabledToAll"
          [checked]="selectedTheme.enabledToAll"
          *ngIf="selectedTheme.isDefault"
          [ngClass]="{ disabled: selectedTheme.isDefault }"
          [disabled]="selectedTheme.isDefault"
          >Default theme ( Always enabled to all users )
        </mat-radio-button>
      </mat-radio-group>

      <div
        class="buttons d-flex mt-4"
        *ngIf="!selectedTheme.enabledToAll"
      >
        <div class="mx-3 text-center mb-3">
          <button
            class="basicBtn"
            mat-raised-button
            (click)="selectAllUsers()"
            color="primary"
          >
            Select all
          </button>
        </div>

        <div class="mx-3 text-center mb-3">
          <button
            class="basicBtn"
            mat-raised-button
            (click)="reverseSelection()"
            color="primary"
          >
            Reverse selection
          </button>
        </div>
      </div>

      <mat-checkbox
        *ngIf="!selectedTheme.enabledToAll"
        (click)="showAllowedUsers()"
        class="allowedUsersCheckbox mx-3 my-3 w-100"
        [(ngModel)]="allowedUsers"
      >
        Show only allowed users
      </mat-checkbox>

      <div
        class="orgList text-center"
        *ngIf="organizationList && !selectedTheme.enabledToAll"
      >
        <mat-form-field
          appearance="fill"
          class="col-11 px-0"
        >
          <mat-label>Filter</mat-label>
          <input
            matInput
            (keyup)="applyFilter($event)"
            #input
          />
        </mat-form-field>

        <table
          *ngIf="dataSource"
          mat-table
          [dataSource]="dataSource"
          class="mat-elevation-z8 col-12 px-0"
        >
          <ng-container matColumnDef="name">
            <th
              mat-header-cell
              *matHeaderCellDef
            >
              Name
            </th>
            <td
              mat-cell
              *matCellDef="let acc"
            >
              {{ acc.organization ? acc.organization.name : acc.patron.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="enabled">
            <th
              mat-header-cell
              *matHeaderCellDef
            >
              Enabled
            </th>
            <td
              mat-cell
              *matCellDef="let acc"
            >
              <mat-checkbox
                class="mx-3"
                (click)="addEnabledOrg(acc)"
                [checked]="checkEnabledOrgs(acc)"
              >
              </mat-checkbox>
            </td>
          </ng-container>
          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
          ></tr>

          <tr
            class="mat-row"
            *matNoDataRow
          >
            <td
              class="mat-cell"
              colspan="4"
            >
              No data matching the filter "{{ input.value }}"
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
