import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';
import { MatDialogRef } from '@angular/material/dialog';
import { SectionTypes, UserTypes } from '../utils/course-utils';
import { environment } from 'src/environments/environment';
import { Course } from 'src/app/pages/configuration-pages/interfaces/global-config.interfaces';

declare let cloudinary: any;

@Component({
  selector: 'app-course-creator',
  templateUrl: './course-creator.component.html',
  styleUrls: ['./course-creator.component.scss'],
})
export class CourseCreator implements OnInit {
  public courses = [];
  public loading = true;

  public sectionTypes = SectionTypes;
  public sectionTitle: string;
  public sectionType: string;

  public courseName: string;
  public courseTitle: string;
  public courseImage = 'assets/img/Image_Placeholder.jpg';

  @Output() reset = new EventEmitter();

  constructor(
    public rest: RestAPIService,
    public snack: MatSnackBar,
    public confirm: ConfirmationService,
    private dialogRef: MatDialogRef<CourseCreator>,
  ) {}

  async ngOnInit() {}

  public saveCourse() {
    const newCourse: Course = {
      name: this.courseName,
      title: this.courseTitle,
      image: this.courseImage,
      lessons: [],
      allowedUsers: [UserTypes.B2C, UserTypes.CLIENTS, UserTypes.ORGANIZATIONS],
      draft: true,
      hasCertificate: false,
      enabledToAll: true,
      allowedOrganizations: [],
    };

    this.dialogRef.close(newCourse);
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  public addCourseImage() {
    const cloudinaryConfig = {
      cloud_name: environment.CLOUDINARY_CLOUD_NAME,
      upload_preset: environment.CLOUDINARY_UPLOAD_PRESET,
      secure: true,
    };

    cloudinary.openUploadWidget(cloudinaryConfig, (error, result) => {
      if (result) {
        const [imageResponse] = result;
        const { secure_url, url } = imageResponse;
        this.courseImage = secure_url || url;
      } else if (error && error.message !== 'User closed widget') {
        this.snack.open('There was an error while uploading the image, please try again later', 'OK', {
          duration: 5000,
        });
      }
    });
  }
}
