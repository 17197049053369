<lsw-curtain></lsw-curtain>

<app-main-layout
  [showHeader]="false"
  [showBreadcrumbs]="false"
>
  <div class="learning-section">
    <learning-section-header></learning-section-header>

    <div class="main-view">
      <main-view-header></main-view-header>

      <ng-container *ngIf="currentSection$ | async as section">
        <div [ngSwitch]="section.type">
          <ng-container *ngSwitchCase="'text'">
            <display-text-lesson
              [content]="section.content"
            ></display-text-lesson>
          </ng-container>

          <ng-container *ngSwitchCase="'video'">
            <display-video-lesson [content]="section.content">
            </display-video-lesson>
          </ng-container>

          <ng-container *ngSwitchCase="'slides'">
            <display-slides-lesson></display-slides-lesson>
          </ng-container>

          <ng-container *ngSwitchCase="'quiz'">
            <display-quiz-lesson></display-quiz-lesson>
          </ng-container>
        </div>
        <main-view-controls></main-view-controls>
      </ng-container>
    </div>

    <div class="column">
      <learning-section-course-completion></learning-section-course-completion>
      <learning-section-lessons-list></learning-section-lessons-list>
    </div>
  </div>
</app-main-layout>
