import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from 'src/environments/environment';
import { Games, GraphTypes } from '../utils/categories-utils';
import { get, includes, camelCase } from 'lodash';
import { faMagnifyingGlass, faXmark, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { GraphPreview } from 'src/app/shared/dialogs/graph-preview/graph-preview.component';
import { Category, UnlockCondition } from 'src/app/pages/configuration-pages/interfaces/global-config.interfaces';

declare let cloudinary: any;

interface DialogData {
  categoriesList: Category[];
}

@Component({
  selector: 'app-new-category',
  templateUrl: './new-categories-dialog.html',
  styleUrls: ['./new-categories-dialog.scss'],
})
export class NewCategoryDialog implements OnInit {
  constructor(
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<NewCategoryDialog>,
    public dialog: MatDialog,
  ) {}

  public gamesList = Games;
  public graphsList = GraphTypes;
  public unlockConditionsDataSource: MatTableDataSource<UnlockCondition>;
  public gamesDataSource: MatTableDataSource<string>;
  public categoryName: string;
  public categorySession = 0;
  public filteredCategories = [];
  public checked = true;
  public unchecked = false;
  public readonly remove: IconDefinition = faXmark;
  public readonly preview: IconDefinition = faMagnifyingGlass;

  public readonly gamesColums: string[] = ['games', 'enabled'];
  public readonly conditionsColums: string[] = ['category', 'session', 'actions'];

  public newCategory: Partial<Category> = {
    name: '',
    img: 'assets/img/Image_Placeholder.jpg',
    lockedImg: 'assets/img/Image_Placeholder.jpg',
    games: [],
    unlockCondition: [],
    title: '',
    description: '',
    maxSessions: 0,
    graphType: 'bar',
    sessionInterval: 0,
  };

  ngOnInit() {
    this.getFilteredCategoriesList();
    this.gamesDataSource = new MatTableDataSource(Object.keys(Games));
  }

  public imageIsPlaceHolder(img): boolean {
    return img.includes('Placeholder');
  }

  public addCategoryImage(property: string) {
    const cloudinaryConfig = {
      cloud_name: environment.CLOUDINARY_CLOUD_NAME,
      upload_preset: environment.CLOUDINARY_UPLOAD_PRESET,
      secure: true,
    };

    cloudinary.openUploadWidget(cloudinaryConfig, (error, result) => {
      if (result) {
        const [imageResponse] = result;
        const { secure_url, url } = imageResponse;
        this.newCategory[property] = secure_url || url;
      } else if (error && error.message !== 'User closed widget') {
        this.snackBar.open('There was an error while uploading the image, please try again later', 'OK', {
          duration: 5000,
        });
      }
    });
  }

  public addNewCondition() {
    const category = this.data.categoriesList.find((c: Category) => c.name === this.categoryName);
    const session = this.categorySession;
    const unlockCondition = { name: category.name, session };
    this.newCategory.unlockCondition.push(unlockCondition);
    this.buildUnlockConditionsTable(this.newCategory);
  }

  public buildUnlockConditionsTable(selectedCategory: Partial<Category>) {
    this.getFilteredCategoriesList();
    this.unlockConditionsDataSource = new MatTableDataSource(selectedCategory.unlockCondition);
  }

  public getFilteredCategoriesList() {
    const unlockConditions = this.newCategory.unlockCondition.map((c) => c.name);

    this.filteredCategories = this.data.categoriesList.filter(
      (c) => !includes(unlockConditions, c.name) && c !== this.newCategory,
    );

    this.categoryName = get(this.filteredCategories[0], 'name', '');
  }

  public getCategoryMaxSession() {
    const selectedCategory = this.data.categoriesList.find((c: Category) => c.name === this.categoryName);

    return selectedCategory ? selectedCategory.maxSessions : 0;
  }

  public resetSession() {
    this.categorySession = 0;
  }

  public isGameEnabled(gameName: string) {
    const game = this.gamesList[gameName];
    const categoryGameList = this.newCategory.games;

    return categoryGameList.find((g) => g.type === game.toLowerCase());
  }

  public addGameToList(gameName: string) {
    const game = this.gamesList[gameName];
    const categoryGameList = this.newCategory.games;
    const selectedGame = categoryGameList.find((g) => g.type === game.toLowerCase());

    if (selectedGame) {
      const index = categoryGameList.indexOf(selectedGame);
      categoryGameList.splice(index, 1);
    } else {
      const newGameEntry = { type: game.toLowerCase() };
      categoryGameList.push(newGameEntry);
    }
  }

  public saveCategory() {
    this.newCategory.title = camelCase(this.newCategory.name);
    this.dialogRef.close(this.newCategory);
  }

  public removeCondition(condition: UnlockCondition) {
    const index = this.newCategory.unlockCondition.indexOf(condition);
    this.newCategory.unlockCondition.splice(index, 1);
    this.buildUnlockConditionsTable(this.newCategory);
  }

  public openGraphPreviewDialog() {
    this.dialog.open(GraphPreview, {
      data: this.newCategory.graphType,
      width: '700px',
      height: '700px',
      panelClass: 'modal-border',
    });
  }
}
