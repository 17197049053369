import { Component, Input, TemplateRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'users-table-status-item',
  templateUrl: './table-status-item.component.html',
  styleUrls: ['./table-status-item.component.scss'],
})
export class TableStatusItemComponent {
  @Input() status;

  @ViewChild('active') activeTmpl: TemplateRef<any>;
  @ViewChild('notActive') notActiveTmpl: TemplateRef<any>;
  @ViewChild('error') errorTmpl: TemplateRef<any>;

  public questionMarkIcon = faQuestion;

  public errorTooltip = '';

  constructor(private _changeDetector: ChangeDetectorRef) {}

  ngAfterContentChecked(): void {
    this._changeDetector.detectChanges(); // When using @ViewChild templates, we detect changes to avoid error NG0100: ExpressionChangedAfterItHasBeenCheckedError
  }

  public getTemplate() {
    switch (this.status) {
      case 'active':
        return this.activeTmpl;
      case 'not-active':
        return this.notActiveTmpl;
      case 'error':
        this.errorTooltip = 'E-mail not found, verify user e-mail';
        return this.errorTmpl;
      default:
        return;
    }
  }
}
