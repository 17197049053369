import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Organization, OrganizationControllerService } from 'src/app/core/openapi';
import { MatSnackBar } from '@angular/material/snack-bar';
import { B2cPaymentTypes } from 'src/app/pages/users/components/adjust-b2c-prices/constants/b2c-prices.constants';

@Component({
  selector: 'app-b2c-payment-types',
  templateUrl: './b2c-payment-types.component.html',
  styleUrls: ['./b2c-payment-types.component.scss'],
})
export class B2cPaymentTypesDialog implements OnInit {
  public form = new FormGroup({
    single: new FormControl<boolean>({ value: true, disabled: true }),
    split: new FormControl<boolean>({ value: true, disabled: false }),
    monthly: new FormControl<boolean>({ value: false, disabled: false }),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public org: Organization,
    private _organizationService: OrganizationControllerService,
    public dialogRef: MatDialogRef<B2cPaymentTypesDialog>,
    private _snackBar: MatSnackBar,
  ) {}

  ngOnInit() {
    this.form.setValue({
      single: this.org.b2cAllowedPaymentTypes.includes(B2cPaymentTypes.SinglePayment),
      split: this.org.b2cAllowedPaymentTypes.includes(B2cPaymentTypes.SplittedPayment),
      monthly: this.org.b2cAllowedPaymentTypes.includes(B2cPaymentTypes.MonthlyInstallmentPayment),
    });
  }

  public confirm() {
    try {
      const org = this.org;
      org.b2cAllowedPaymentTypes = [];
      if (this.form.controls.single.value) org.b2cAllowedPaymentTypes.push(B2cPaymentTypes.SinglePayment);
      if (this.form.controls.split.value) org.b2cAllowedPaymentTypes.push(B2cPaymentTypes.SplittedPayment);
      if (this.form.controls.monthly.value) org.b2cAllowedPaymentTypes.push(B2cPaymentTypes.MonthlyInstallmentPayment);

      this._organizationService.organizationControllerUpdateOrg(org).subscribe();

      this._snackBar.open('B2C payment types updated!', 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    } catch (error) {
      this._snackBar.open(error.message, 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }

    this.dialogRef.close();
  }

  public cancel() {
    this.dialogRef.close();
  }
}
