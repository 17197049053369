<div class="mainContent">
  <div class="header col-12 p-4 text-center">Payment card info</div>
  <form class="col-12 p-5">
    <div
      class="cardInfo"
      id="card-number-element"
    ></div>

    <div class="creditCardsAccepted mb-1 mt-1 ml-2">
      We accept
      <fa-icon
        class="ccIcon"
        [icon]="visa"
      ></fa-icon>
      <fa-icon
        class="ccIcon"
        [icon]="master"
      ></fa-icon>
      <fa-icon
        class="ccIcon"
        [icon]="amex"
      >
      </fa-icon>
    </div>

    <div
      *ngIf="updating"
      class="mt-3"
    >
      <div class="d-flex justify-content-center align-items-center">
        <fa-icon
          class="mx-2"
          [icon]="spinnerIcon"
          [spin]="true"
        >
        </fa-icon>

        <div>Please wait, we're updating the payment information.</div>
      </div>
    </div>

    <div
      class="buttons d-flex mt-3"
      *ngIf="!updating"
    >
      <button
        [ngClass]="{ disabledIcon: sendingInformation }"
        [disabled]="sendingInformation"
        type="submit"
        class="ml-auto mr-1"
      >
        SUBMIT
      </button>

      <button
        [ngClass]="{ disabledIcon: sendingInformation }"
        [disabled]="sendingInformation"
        type="button"
        (click)="close()"
        class="mr-auto ml-1"
      >
        CANCEL
      </button>
    </div>
  </form>
</div>
