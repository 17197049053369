export const getUsStates = [
  { name: 'Alaska', acronym: 'AK' },
  { name: 'Alabama', acronym: 'AL' },
  { name: 'Arkansas', acronym: 'AR' },
  { name: 'Arizona', acronym: 'AZ' },
  { name: 'California', acronym: 'CA' },
  { name: 'Colorado', acronym: 'CO' },
  { name: 'District of Columbia', acronym: 'DC' },
  { name: 'Delaware', acronym: 'DE' },
  { name: 'Florida', acronym: 'FL' },
  { name: 'Georgia', acronym: 'GA' },
  { name: 'Hawaii', acronym: 'HI' },
  { name: 'Iowa', acronym: 'IA' },
  { name: 'Illinois', acronym: 'IL' },
  { name: 'Indiana', acronym: 'IN' },
  { name: 'Kansas', acronym: 'KS' },
  { name: 'Kentucky', acronym: 'KY' },
  { name: 'Massachusetts', acronym: 'MA' },
  { name: 'Maryland', acronym: 'MD' },
  { name: 'Maine', acronym: 'ME' },
  { name: 'Michigan', acronym: 'MI' },
  { name: 'Minnesota', acronym: 'MN' },
  { name: 'Missouri', acronym: 'MO' },
  { name: 'Mississippi', acronym: 'MS' },
  { name: 'North Carolina', acronym: 'NC' },
  { name: 'North Dakota', acronym: 'ND' },
  { name: 'Nebraska', acronym: 'NE' },
  { name: 'New Hampshire', acronym: 'NH' },
  { name: 'New Jersey', acronym: 'NJ' },
  { name: 'New Mexico', acronym: 'NM' },
  { name: 'Nevada', acronym: 'NV' },
  { name: 'New York', acronym: 'NY' },
  { name: 'Ohio', acronym: 'OH' },
  { name: 'Oklahoma', acronym: 'OK' },
  { name: 'Oregon', acronym: 'OR' },
  { name: 'Pennsylvania', acronym: 'PA' },
  { name: 'Puerto Rico', acronym: 'PR' },
  { name: 'Rhode Island', acronym: 'RI' },
  { name: 'South Carolina', acronym: 'SC' },
  { name: 'South Dakota', acronym: 'SD' },
  { name: 'Tennessee', acronym: 'TN' },
  { name: 'Texas', acronym: 'TX' },
  { name: 'Utah', acronym: 'UT' },
  { name: 'Virginia', acronym: 'VA' },
  { name: 'Vermont', acronym: 'VT' },
  { name: 'Washington', acronym: 'WA' },
  { name: 'Wisconsin', acronym: 'WI' },
  { name: 'West Virginia', acronym: 'WV' },
  { name: 'Wyoming', acronym: 'WY' },
];
