<div class="mainContent">
  <div class="col-12 mx-0 px-0 mb-2 justify-content-center">
    <div class="header col-12 text-center">Select the categories</div>

    <table
      mat-table
      [dataSource]="dataSource"
      class="col-12 px-0"
      multiTemplateDataRows
    >
      <ng-container matColumnDef="name">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="text-center"
        >
          Name
        </th>
        <td
          mat-cell
          *matCellDef="let data"
          class="text-center"
        >
          {{ data.category.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="included">
        <th
          class="text-center"
          mat-header-cell
          *matHeaderCellDef
        >
          Included
          <fa-icon
            class="mx-2"
            [icon]="question"
            matTooltip="Mark as included the categories you want to create a new progress"
            matTooltipPosition="above"
          ></fa-icon>
        </th>
        <td
          mat-cell
          *matCellDef="let data"
          class="text-center"
        >
          <mat-checkbox
            *ngIf="shouldDisableInput(data.category)"
            [(ngModel)]="unchecked"
            disabled
            matTooltip="Student already have the maximum created progress for this category"
            matTooltipPosition="above"
          >
          </mat-checkbox>

          <mat-checkbox
            *ngIf="!shouldDisableInput(data.category)"
            (click)="changeEnabledStatus(data)"
            [checked]="data.included"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th
          class="text-center"
          mat-header-cell
          *matHeaderCellDef
        >
          Amount
          <fa-icon
            class="mx-2"
            [icon]="question"
            matTooltip="The amount of progress you want to create for each category"
            matTooltipPosition="above"
          ></fa-icon>
        </th>
        <td
          mat-cell
          *matCellDef="let data"
          class="text-center"
        >
          <input
            [disabled]="shouldDisableInput(data.category)"
            [value]="data.progressAmount"
            class="amountInput text-center"
            matInput
            type="number"
            [max]="getProgressMaxAmount(data.category)"
            min="0"
            step="1"
            [(ngModel)]="data.progressAmount"
          />
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        class="example-element-row"
      ></tr>
    </table>
    <div class="text-center confirmBtn mt-3">
      <div
        class="d-flex justify-content-center align-items-center"
        *ngIf="savingProgress"
      >
        <fa-icon
          class="mx-2"
          [icon]="spinnerIcon"
          [spin]="true"
        >
        </fa-icon>

        <div>Please wait, the student progress is being saved.</div>
      </div>

      <div
        class="buttons"
        *ngIf="!savingProgress"
      >
        <app-simple-button
          class="mx-2"
          text="CONFIRM"
          [smaller]="true"
          (click)="confirm()"
        ></app-simple-button>

        <app-simple-button
          class="mx-2"
          text="CANCEL"
          [smaller]="true"
          (click)="cancel()"
        ></app-simple-button>
      </div>
    </div>
  </div>
</div>
