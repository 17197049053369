<ng-container *ngIf="isLoading">
  <div class="loading">
    <fa-icon
      [icon]="spinnerIcon"
      [spin]="true"
    ></fa-icon>
  </div>
</ng-container>

<ng-container *ngIf="!isLoading">
  <table
    mat-table
    [dataSource]="regions"
  >
    <ng-container matColumnDef="region">
      <th
        mat-header-cell
        [ngClass]="'name-col'"
        *matHeaderCellDef
      >
        Region
      </th>
      <td
        mat-cell
        class="pointer"
        [ngClass]="'name-col'"
        *matCellDef="let row"
      >
        {{ row.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="orgId">
      <th
        mat-header-cell
        [ngClass]="'orgId-col'"
        *matHeaderCellDef
        class="orgId-col"
      >
        Org Id
      </th>
      <td
        mat-cell
        [ngClass]="'orgId-col'"
        *matCellDef="let row"
        class="orgId-col"
      >
        {{ row.orgId }}
      </td>
    </ng-container>
    <ng-container matColumnDef="orgName">
      <th
        mat-header-cell
        [ngClass]="'orgName-col'"
        *matHeaderCellDef
        class="orgName-col"
      >
        Organization Name
      </th>
      <td
        mat-cell
        [ngClass]="'orgName-col'"
        *matCellDef="let row"
        class="orgName-col"
      >
        {{ row.orgName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th
        mat-header-cell
        [ngClass]="'orgName-col'"
        *matHeaderCellDef
        class="orgName-col"
      >
        Email
        <fa-icon
          class="mx-2"
          [icon]="question"
          matTooltipPosition="above"
          matTooltip="The region email field is used for demos and screening reports on lsworks.org"
        ></fa-icon>
      </th>
      <td
        mat-cell
        [ngClass]="'orgName-col'"
        *matCellDef="let row"
        class="orgName-col"
      >
        {{ getRegionEmail(row) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="iso2">
      <th
        mat-header-cell
        [ngClass]="'iso2-col'"
        *matHeaderCellDef
        class="iso2"
      >
        ISO 2
      </th>
      <td
        mat-cell
        [ngClass]="'status-col'"
        *matCellDef="let row"
        class="iso2"
      >
        {{ row.iso2 }}
      </td>
    </ng-container>

    <ng-container matColumnDef="iso3">
      <th
        mat-header-cell
        [ngClass]="'iso3-col'"
        *matHeaderCellDef
        class="iso3"
      >
        ISO 3
      </th>
      <td
        mat-cell
        [ngClass]="'iso3-col'"
        *matCellDef="let row"
        class="iso3"
      >
        {{ row.iso3 }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th
        mat-header-cell
        [ngClass]="'action-col'"
        *matHeaderCellDef
      >
        Actions
      </th>

      <td
        mat-cell
        class="text-center d-flex mt-2"
        [ngClass]="'action-col'"
        *matCellDef="let row"
      >
        <a
          class="mx-1"
          matTooltip="Delete Region"
          matTooltipPosition="above"
        >
          <fa-icon
            [icon]="trash"
            (click)="deleteRegion(row.id)"
          ></fa-icon>
        </a>

        <a
          class="mx-1"
          matTooltip="Edit Region"
          matTooltipPosition="above"
        >
          <fa-icon
            [icon]="editIcon"
            (click)="editRegion(row)"
          ></fa-icon>
        </a>
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="regionColumns"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: regionColumns"
    ></tr>
  </table>

  <div class="buttonWrapper">
    <button
      mat-raised-button
      (click)="createNewRegion()"
      class="my-2"
    >
      Add
    </button>
  </div>
</ng-container>
