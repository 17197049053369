export const english = {
  beforeYouStart: {
    title: 'You’re almost ready! Before you start, here are a few tips:',
    quietLocation: 'Work at a quiet location with no distractions.',
    wearConfortable: 'Wear a comfortable over-the-ear headset. Make sure left and right audio are working.',
    externalMouse: 'Use a plain external mouse with NO back button/s on the side.',
    keyboardHandy: 'Have a keyboard handy.',
    clickOnArrows: 'Always maximize your gameplays by clicking on the arrows in the bottom right corner.',
    userWideMonitor: 'Use a 21” to 35” monitor for best results.',
    pressP: 'Press the letter ‘P’ on your keypad to pause and unpause the program.',
    pressSpaceBar: 'Press the spacebar to repeat the audio.',
    takeDeepBreath: 'Take a deep breath, relax, and press the ‘Cognitive Therapy’ button when you are ready to start.',
    scoresRecorded:
      'When you are finished the session your scores will be recorded and will automatically display on the graph.',
    start: "LET'S START!",
    gotIt: 'GOT IT!',
  },
  program: {
    week: 'Week',
    day: 'Day',
    hours: 'hours',
    minutes: 'minutes',
    seconds: 'seconds',
    playNextSessionAllowed: 'You can play the next session.',
    completedCognitiveTherapy: 'Congratulation you have completed Cognitive Therapy',
    playNextSessionTimeTo: "You'll be able to play the next session (session ${session}) in ",
    triesLeft: 'You have ${tries} tries left.',
    description1: 'You are playing ${level} session ${session}',
    description2: 'You are completing Assessment ${session}',
    description3: 'You are playing ${level} Level ${currentWeek} Day ${day}',
    playFromBeginning: 'Play from beginning',
    cancel: 'Cancel',
    loadGame: 'Load Game',
    cognitiveTherapy: {
      title: 'Cognitive Therapy',
      subTitle: 'You are playing % Level % Day %',
    },
    speedReading: {
      title: 'Speed Reading',
      subTitle: '',
    },
    readingExercises: {
      title: 'Reading Exercises',
      subTitle: '',
    },
    assessment: {
      title: 'Assessment',
      subTitle: '',
    },
    brainGames: {
      title: 'Brain Games',
      subTitle: '',
    },
  },
  warnings: {
    unsupportedBrowser:
      'Your current browser does not support WebGL, which may result in reduced functionality and performance issues while using Neuralign. Please switch to a compatible browser before continuing. Visit get.webgl.org for a list of compatible browsers.',
  },
  header: {
    welcomeText: 'Welcome',
  },
};
