import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { RestAPIService } from '../../services/rest/rest-api.service';
import { UserSideMenuModule } from '../../shared/components/user-side-menu/user-side-menu.module';
import { MaterialComponentsModule } from 'src/app/shared/modules/material-components.module';
import { LayoutComponentsModule } from 'src/app/shared/components/layout/layout-components.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ProgramsComponent } from './programs.component';
import { LayoutsModule } from 'src/app/shared/layouts/layouts.module';

@NgModule({
  declarations: [ProgramsComponent],
  imports: [
    BrowserModule,
    RouterModule,
    HttpClientModule,
    UserSideMenuModule,
    MaterialComponentsModule,
    LayoutComponentsModule,
    FontAwesomeModule,
    LayoutsModule,
  ],
  providers: [RestAPIService],
})
export class ProgramsModule {}
