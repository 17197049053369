import { Component, Input } from '@angular/core';

type Tag = 'Neuralign' | '--';

@Component({
  selector: 'users-table-program-tag',
  templateUrl: './table-program-tag.component.html',
  styleUrls: ['./table-program-tag.component.scss'],
})
export class TableProgramTagComponent {
  @Input() tag: Tag;
}
