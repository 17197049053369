import { Component, Input, OnInit } from '@angular/core';
import { OrdersByProvince } from 'src/app/shared/interfaces/classwallet.interface';
import { get } from 'lodash';
import { ShopifyControllerGetOrders200ResponseCompletedOrdersInner } from 'src/app/core/openapi';
import moment from 'moment';

@Component({
  selector: 'app-cw-list-filter',
  templateUrl: './cw-list-filter.component.html',
  styleUrl: './cw-list-filter.component.scss',
})
export class CwListFilterComponent implements OnInit {
  constructor() {}

  @Input() listType: string = 'Status';
  @Input() listItems: string[] = [];
  @Input() status: string = '';
  @Input() activeList: OrdersByProvince[] = [];

  public filterIsActive: boolean = false;
  public removedContent: OrdersByProvince[] = [];

  ngOnInit(): void {}

  public shouldDisableBtn() {
    return this.status === undefined;
  }

  public applyFilter() {
    this.resetFilter();

    for (const item of this.activeList) {
      const dataSource = item.dataSource;

      const itemsToSearch = [...dataSource.data, ...this.getRemovedContent(item.province)];

      const filteredValue = itemsToSearch.filter((item) => {
        return get(item, 'orderStatus', '').toLowerCase() === this.status.toLowerCase();
      });

      this.addRemovedItems(dataSource.data, filteredValue, item.province);

      dataSource.data = filteredValue;
    }

    this.filterIsActive = true;
  }

  public addRemovedItems(
    orders: ShopifyControllerGetOrders200ResponseCompletedOrdersInner[],
    filteredValue: ShopifyControllerGetOrders200ResponseCompletedOrdersInner[],
    province: string,
  ) {
    const removedItems = orders.filter((item) => !filteredValue.includes(item));

    const newEntry: OrdersByProvince = {
      orders: removedItems,
      province: province,
      dataSource: null,
    };

    this.removedContent.push(newEntry);
  }

  public getRemovedContent(province: string) {
    const removedcontent = this.removedContent.find((c) => c.province === province);

    if (!removedcontent) {
      return [];
    }

    return removedcontent.orders;
  }

  public resetFilter() {
    for (const item of this.activeList) {
      const removedcontent = this.removedContent.find((c) => c.province === item.province);

      if (!removedcontent) {
        continue;
      }

      const dataSource = item.dataSource;
      const newData = [...dataSource.data, ...removedcontent.orders];
      newData.sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt)));

      dataSource.data = newData;
    }

    this.removedContent = [];
    this.filterIsActive = false;
  }
}
