export enum Tutorials {
  ADD_STUDENTS,
  ACTIVE_STUDENTS,
  STUDENT_CONTROLS,
  PROGRAMS,
  READLS,
  DEMO,
  TRAINING,
  DOCUMENTATION,
  ACTIVE_STUDENTS_CLIENT,
  READLS_CLIENT,
  USERS_ORG,
  ADD_USER_ORG,
  ADD_STUDENT_ORG,
  PURCHASING_CREDITS_ORG,
  NEURALIGN_ORG,
  ADD_CREDIT_ORG,
  ENTERING_STUDENT_PAGE_ORG,
  REMOTE_ACCESS_ORG,
  DEMO_ORG,
  TRAINING_ORG,
  STEP_BY_STEP_ORG,
  DOCUMENTS_ORG,
  READLS_2,
  ACTIVE_USERS,
  MOUSE_TRAINING,
  ACTIVE_USERS_CLIENT,
  BEFORE_YOU_START,
  ORG_TRAINING,
  CLIENT_TRAINING,
  B2C_TRAINING,
}
