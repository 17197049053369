import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { SimpleButtonComponent } from './simple-button/simple-button.component';
import { EmptyStateComponent } from './empty-state/empty-state.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TextTransformModule } from '../../pipes/text-config/text-transform.module';

@NgModule({
  declarations: [SimpleButtonComponent, EmptyStateComponent],
  imports: [BrowserModule, FontAwesomeModule, TextTransformModule],
  exports: [SimpleButtonComponent, EmptyStateComponent],
})
export class LayoutComponentsModule {}
