import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TutorialsComponent } from './tutorials.component';
import { TutorialModalModule } from 'src/app/shared/components/tutorial-modal/tutorial-modal.module';
import { BeforeYouStartModule } from 'src/app/shared/components/before-you-start/before-you-start.module';
import { LayoutsModule } from 'src/app/shared/layouts/layouts.module';

@NgModule({
  declarations: [TutorialsComponent],
  imports: [BrowserModule, FontAwesomeModule, TutorialModalModule, BeforeYouStartModule, LayoutsModule],
})
export class TutorialsModule {}
