import { Component, OnInit } from '@angular/core';

/**
 * @scope Lays out a grid for the course overview page.
 */
@Component({
  selector: 'course-overview-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
})
export class GridComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
