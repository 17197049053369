<div class="fiveDaysMenu">
  <div class="mainContent">
    <div
      class="days"
      *ngFor="let state of dayStateArray; let i = index"
      (click)="start(state)"
    >
      <img
        class="day {{ state }}"
        [src]="getDayImage(state, i + 1)"
        (error)="setDefaultImage($event, i + 1, state)"
      />
    </div>
  </div>
</div>
