import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { Student } from '../../interfaces/student.interface';

@Component({
  selector: 'app-archive-dialog',
  template: ` <div mat-dialog-content>
    <span
      class="close"
      (click)="resolve()"
    >
      X
    </span>
    <p>
      Archived Students<br />
      <span>Click on a student to retrieve it from the archive</span>
    </p>
    <table
      mat-table
      [dataSource]="students"
      class="mat-elevation-z8"
    >
      <ng-container matColumnDef="name">
        <th
          mat-header-cell
          *matHeaderCellDef
        >
          Name
        </th>
        <td
          mat-cell
          *matCellDef="let row"
        >
          {{ row.givenName }} {{ row.familyName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="nickname">
        <th
          mat-header-cell
          *matHeaderCellDef
        >
          Nickname
        </th>
        <td
          mat-cell
          *matCellDef="let row"
        >
          {{ row.nickname }}
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="['name', 'nickname']"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: ['name', 'nickname']"
        (click)="resolve(row)"
      ></tr>
    </table>
    <ng-container *ngIf="students?.length == 0">
      <br />
      <p>No students archived</p>
      <br />
    </ng-container>
  </div>`,
  styleUrls: ['./archive.dialog.scss'],
})
export class ArchiveDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ArchiveDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public students: Student[],
  ) {}

  public resolve(selectedStudent: Student = null): void {
    this.dialogRef.close(selectedStudent);
  }
}
