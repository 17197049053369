import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicMessageBannerComponent } from './public-message-banner.component';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PublicWarningMessageBannerComponent } from './banner/public-warning-message-banner.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [PublicMessageBannerComponent, PublicWarningMessageBannerComponent],
  imports: [CommonModule, MatInputModule, FormsModule, MatCheckboxModule, FontAwesomeModule, ReactiveFormsModule],
  exports: [PublicWarningMessageBannerComponent],
})
export class PublicMessageBannerModule {}
