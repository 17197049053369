import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Games } from '../utils/categories-utils';
import { environment } from 'src/environments/environment';
import { Category } from 'src/app/pages/configuration-pages/interfaces/global-config.interfaces';

declare let cloudinary: any;

interface DialogData {
  bonusPack: Category;
}

@Component({
  selector: 'app-bonus-pack-dialog',
  templateUrl: './bonus-pack-dialog.html',
  styleUrls: ['./bonus-pack-dialog.scss'],
})
export class BonusPackDialog implements OnInit {
  constructor(
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<BonusPackDialog>,
    public dialog: MatDialog,
  ) {}

  public gamesDataSource: MatTableDataSource<string>;
  public readonly gamesColums: string[] = ['games', 'enabled'];
  public gamesList = Games;
  public checked = true;
  public unchecked = false;

  ngOnInit() {
    this.gamesDataSource = new MatTableDataSource(Object.keys(Games));
  }

  public imageIsPlaceHolder(img: string): boolean {
    return !img;
  }

  public addCategoryImage(property: string) {
    const cloudinaryConfig = {
      cloud_name: environment.CLOUDINARY_CLOUD_NAME,
      upload_preset: environment.CLOUDINARY_UPLOAD_PRESET,
      secure: true,
    };

    cloudinary.openUploadWidget(cloudinaryConfig, (error, result) => {
      if (result) {
        const [imageResponse] = result;
        const { secure_url, url } = imageResponse;
        this.data.bonusPack[property] = secure_url || url;
      } else if (error && error.message !== 'User closed widget') {
        this.snackBar.open('There was an error while uploading the image, please try again later', 'OK', {
          duration: 5000,
        });
      }
    });
  }

  public saveBonusPack() {
    this.dialogRef.close(this.data.bonusPack);
  }

  public isGameEnabled(gameName: string) {
    const game = this.gamesList[gameName];
    const categoryGameList = this.data.bonusPack.games;

    return categoryGameList.find((g) => g.type === game.toLowerCase());
  }

  public getImagePlaceholder() {
    return 'assets/img/Image_Placeholder.jpg';
  }

  public addGameToList(gameName: string) {
    const game = this.gamesList[gameName];
    const categoryGameList = this.data.bonusPack.games;
    const selectedGame = categoryGameList.find((g) => g.type === game.toLowerCase());

    if (selectedGame) {
      const index = categoryGameList.indexOf(selectedGame);
      categoryGameList.splice(index, 1);
    } else {
      const newGameEntry = { type: game.toLowerCase() };
      categoryGameList.push(newGameEntry);
    }
  }
}
