import { Pipe, PipeTransform } from '@angular/core';
import { PortalConfig } from 'src/app/services/auth/auth-consts/auth-consts';
import { AuthService } from 'src/app/services/auth/auth.service';

enum OrganizationsLabels {
  ORGANIZATIONS = 'Organizations',
  ORGANIZATION = 'Organization',

  SCHOOLS = 'Schools',
  SCHOOL = 'School',
}

enum AdminsLabels {
  ADMINS = 'Admins',

  ADMIN = 'Admin',
}

enum ManagersLabels {
  MANAGERS = 'Managers',
  MANAGER = 'Manager',

  TEACHERS = 'Teachers',
  TEACHER = 'Teacher',
}

enum ClientsLabels {
  CLIENTS = 'Clients',
  CLIENT = 'Client',

  STUDENTS = 'Students',
  STUDENT = 'Student',
}

enum ProfessionLabels {
  PROFESSION = 'Profession',
  CONTACT_PERSON = 'Contact person',
}

enum ProfessionalAssociationLabels {
  PROFESSIONAL_ASSOCIATION = 'Professional Association',
  Title = 'Title',
}

@Pipe({
  name: 'textTransform',
})
export class TextTransformPipe implements PipeTransform {
  public configToUse: PortalConfig = PortalConfig.DEFAULT;
  public registeredTransformers = [];

  constructor(public authService: AuthService) {
    this.configToUse = this.authService.activeConfig;

    this.registeredTransformers = [
      this.transformClientsText.bind(this),
      this.transformManagersText.bind(this),
      this.transformAdminsText.bind(this),
      this.transformOutsiderText.bind(this),
      this.transformProfessionText.bind(this),
      this.transformProfessionalAssociationText.bind(this),
    ];
  }

  transform(value: string, transformation: PortalConfig): string {
    if (!value) {
      return '';
    }

    // Allow the pipe to force the text type
    if (transformation) {
      this.configToUse = transformation;
    }

    let text = value;

    for (const method of this.registeredTransformers) {
      text = method(text);
    }

    return text;
  }

  transformOutsiderText(value: string) {
    // eslint-disable-next-line no-useless-escape
    const regex = /\bOrganization(s)?\b/gi;

    switch (this.configToUse) {
      case PortalConfig.SCHOOL:
        return value.replace(regex, (match) =>
          match.toLowerCase() === 'organizations' ? OrganizationsLabels.SCHOOLS : OrganizationsLabels.SCHOOL,
        );

      default:
        return value.replace(regex, (match) =>
          match.toLowerCase() === 'organizations'
            ? OrganizationsLabels.ORGANIZATIONS
            : OrganizationsLabels.ORGANIZATION,
        );
    }
  }

  transformAdminsText(value: string) {
    // eslint-disable-next-line no-useless-escape
    const regex = /\Admin(s)?\b/gi;

    switch (this.configToUse) {
      default:
        return value.replace(regex, (match) =>
          match.toLowerCase() === 'admins' ? AdminsLabels.ADMINS : AdminsLabels.ADMIN,
        );
    }
  }

  transformManagersText(value: string) {
    // eslint-disable-next-line no-useless-escape
    const regex = /\bManager(s)?\b/gi;

    switch (this.configToUse) {
      case PortalConfig.SCHOOL:
        return value.replace(regex, (match) =>
          match.toLowerCase() === 'managers' ? ManagersLabels.TEACHERS : ManagersLabels.TEACHER,
        );

      default:
        return value.replace(regex, (match) =>
          match.toLowerCase() === 'managers' ? ManagersLabels.MANAGERS : ManagersLabels.MANAGER,
        );
    }
  }

  transformClientsText(value: string) {
    // eslint-disable-next-line no-useless-escape
    const regex = /\Client(s)?\b/gi;

    switch (this.configToUse) {
      case PortalConfig.SCHOOL:
        return value.replace(regex, (match) =>
          match.toLowerCase() === 'clients' ? ClientsLabels.STUDENTS : ClientsLabels.STUDENT,
        );

      default:
        return value.replace(regex, (match) =>
          match.toLowerCase() === 'clients' ? ClientsLabels.CLIENTS : ClientsLabels.CLIENT,
        );
    }
  }

  transformProfessionText(value: string) {
    // eslint-disable-next-line no-useless-escape
    const regex = /\Profession?\b/gi;

    switch (this.configToUse) {
      case PortalConfig.SCHOOL:
        return value.replace(regex, () => ProfessionLabels.CONTACT_PERSON);

      default:
        return value.replace(regex, () => ProfessionLabels.PROFESSION);
    }
  }

  transformProfessionalAssociationText(value: string) {
    // eslint-disable-next-line no-useless-escape
    const regex = /\Professional Association?\b/gi;

    switch (this.configToUse) {
      case PortalConfig.SCHOOL:
        return value.replace(regex, () => ProfessionalAssociationLabels.Title);

      default:
        return value.replace(regex, () => ProfessionalAssociationLabels.PROFESSIONAL_ASSOCIATION);
    }
  }
}
