import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-confirm-dialog',
  template: ` <div mat-dialog-content>
      <p [innerHTML]="question"></p>
    </div>
    <div mat-dialog-actions>
      <button
        mat-button
        class="no"
        (click)="resolve(false)"
      >
        No
      </button>
      <button
        mat-button
        class="yes"
        (click)="resolve(true)"
        cdkFocusInitial
      >
        Yes
      </button>
    </div>`,
  styleUrls: ['./confirm.dialog.scss'],
})
export class ConfirmDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public question: string,
  ) {}

  public resolve(answer: boolean): void {
    this.dialogRef.close(answer);
  }
}
