import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Program, Token } from 'src/app/shared/models';

interface Data {
  token: Token;
  programs: Program[];
}

@Component({
  selector: 'app-update-token',
  templateUrl: './update-token-dialog.html',
  styleUrls: ['./update-token-dialog.scss'],
})
export class UpdateTokenDialog implements OnInit {
  private user;
  public programs;
  public programId;
  public errorMessage;
  public allowCompleteAtHome = true;
  public allowProgressRerun = false;

  constructor(public dialogRef: MatDialogRef<UpdateTokenDialog>, @Inject(MAT_DIALOG_DATA) public data: Data) {}

  ngOnInit() {
    if (this.data.token.allowProgressRerun === undefined) {
      this.data.token.allowProgressRerun = false;
    }

    this.programs = this.data.programs;
    this.programId = this.data.programs[0].id;
    this.allowCompleteAtHome = this.data.token.allowCompleteAtHome;
    this.allowProgressRerun = this.data.token.allowProgressRerun;
  }

  public confirm() {
    if (!this.programId) {
      return (this.errorMessage = 'You must choose a program');
    }

    this.dialogRef.close({
      allowCompleteAtHome: this.allowCompleteAtHome,
      allowProgressRerun: this.allowProgressRerun,
    });
  }

  public cancel() {
    this.dialogRef.close(false);
  }
}
