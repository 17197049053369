import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { SideMenuComponent } from './side-menu.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DialogsModule } from '../../dialogs/dialogs.module';
import { MaterialComponentsModule } from '../../modules/material-components.module';
import { BeforeYouStartModule } from '../before-you-start/before-you-start.module';
import { SideMenuSectionComponent } from './side-menu-section/side-menu-section.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DebugHelperComponent } from '../header/components/debug-helper/debug-helper.component';

@NgModule({
  declarations: [SideMenuComponent, SideMenuSectionComponent, DebugHelperComponent],
  imports: [
    BrowserModule,
    RouterModule,
    FontAwesomeModule,
    DialogsModule,
    MaterialComponentsModule,
    BeforeYouStartModule,
    MatTooltipModule,
  ],
  exports: [SideMenuComponent],
  providers: [AuthService],
})
export class SideMenuModule {}
