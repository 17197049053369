import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { Student } from 'src/app/pages/students/interfaces/student.interface';

@Component({
  selector: 'app-select-student-dialog',
  templateUrl: 'select-student.dialog.html',
  styleUrls: ['./select-student.dialog.scss'],
})
export class SelectStudentDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<SelectStudentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public students: Student[],
  ) {}

  public resolve(selectedStudent: Student = null): void {
    this.dialogRef.close(selectedStudent);
  }

  close() {
    this.dialogRef.close();
  }
}
