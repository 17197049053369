import { NgModule } from '@angular/core';
import { ControlsModule } from './controls/controls.module';
import { DisplayModule } from './display/display.module';
import { MainViewComponent } from './main-view.component';
import { HeaderModule } from './header/header.module';
import { GridModule } from './grid/grid.module';

@NgModule({
  declarations: [MainViewComponent],
  imports: [ControlsModule, DisplayModule, GridModule, HeaderModule],
  exports: [MainViewComponent, ControlsModule, DisplayModule, GridModule, HeaderModule],
})
export class MainViewModule {}
