import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';
import { MatDialog } from '@angular/material/dialog';
import { CourseEditor } from './course-editor/course-editor.component';
import { CourseCreator } from './course-creator/course-creator.component';
import { Course } from '../../../interfaces/global-config.interfaces';

@Component({
  selector: 'app-learning-management-system',
  templateUrl: './learning-management-system.component.html',
  styleUrls: ['./learning-management-system.component.scss'],
})
export class LearningManagementSystemComponent implements OnInit {
  public courses = [];
  public loading = true;
  @Input() courseList: Course[];
  @Output() reset = new EventEmitter();

  constructor(
    public rest: RestAPIService,
    public snack: MatSnackBar,
    public confirm: ConfirmationService,
    private dialog: MatDialog,
  ) {}

  async ngOnInit() {}

  public getLessonsAmount(course: Course): number {
    if (!course.lessons) {
      return 0;
    }

    return course.lessons.length;
  }

  public async deleteCourse(course: Course) {
    this.confirm
      .createConfirmation(
        'Warning',
        'This action cannot be undone, are you sure you want to delete this course?',
        'Yes',
        'No',
      )
      .then(async () => {
        await this.rest.delete('delete/course/id/' + course.id);
        this.reset.emit();
        this.snack.open('Course deleted!', 'Close', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      });
  }

  public duplicateCourse(course: Course) {
    this.confirm
      .createConfirmation('Warning', 'Are you sure you want to duplicate this course?', 'Yes', 'No')
      .then(async () => {
        // Delete the course id before we send it to API so we can duplicate it
        // The duplication of the lessons and sections will be handled by the API
        delete course.id;

        await this.rest.post('courses', course);
        this.reset.emit();
        this.snack.open(`Course ${course.name} duplicated!`, 'Close', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          duration: 5000,
        });
      });
  }

  public openCourseEditor(course: Course) {
    if (course.enabledToAll === undefined) {
      course.enabledToAll = true;
    }

    if (course.useForGameCategory === undefined) {
      course.useForGameCategory = false;
    }

    this.dialog.open(CourseEditor, {
      width: '1400px',
      height: '900px',
      data: course,
      panelClass: 'modal-border',
    });
  }

  public openCourseCreator() {
    const dialog = this.dialog.open(CourseCreator, {
      width: '700px',
      height: '650px',
      panelClass: 'modal-border',
      disableClose: true,
    });

    dialog.afterClosed().subscribe(async (newCourse) => {
      if (newCourse) {
        await this.rest.post('courses', newCourse);
        this.reset.emit();
        this.snack.open('Course created!', 'Close', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          duration: 5000,
        });
      }
    });
  }

  public updateCourses() {
    this.reset.emit();
  }
}
