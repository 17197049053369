import { Component, isDevMode } from '@angular/core';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { SHOW_TEST_AREA } from 'src/app/app.constants';
import { LoggerService } from 'src/app/services/logger/logger.service';

@Component({
  selector: 'app-test-area',
  templateUrl: './test-area.component.html',
  styleUrls: ['./test-area.component.scss'],
})
export class TestAreaComponent {
  isEnabled: boolean = this.enable();

  constructor(private rest: RestAPIService, private logger: LoggerService) {}

  ngOnInit() {
    if (this.isEnabled) {
      // this.testLoggerService();
    }
  }

  testLoggerService() {
    this.logger.debug('This is a debug message', { hello: 'world' }, [1, 2, 3, 4]);
    this.logger.info('This is an info message');
    this.logger.warn('This is a warn message');
    this.logger.error('This is an error message');
  }

  /**
   * Enable the test area view
   */
  enable() {
    // Do not enable if inside a Unity game iframe
    if (window.frameElement) {
      return false;
    }
    // Enable if in dev mode
    if (isDevMode() && SHOW_TEST_AREA) {
      return true;
    }
  }

  // Javascript Errors

  localError() {
    throw Error('The application has thrown an error.');
  }

  throwTypeError() {
    throw TypeError('This is a test type error');
  }

  async badData() {
    const mock = await this.rest.get('test/empty-object', { msg: 'Could not perform mock action' });
    mock.propertyDoesNotExist.filter();
  }

  // HTTP Errors

  async getInvalidUrl() {
    await this.rest.get('path-to-nowhere', {
      msg: 'This action could not be completed.',
    });
  }

  getInvalidUrlToSnackbar() {
    this.rest.get('path-to-nowhere', {
      msg: 'This action could not be completed.',
    });
  }

  postWithBadData() {
    this.rest.post('test/fake-signup', { hello: 'fail' }, { msg: 'This action could not be completed.' });
  }

  postToSnackBar() {
    this.rest.post('test/snackbar', { hello: 'fail' }, { msg: 'This action could not be completed.' });
  }

  auto500() {
    this.rest.get('test/500', { msg: 'Could not delete 500.' });
  }

  hideError() {
    this.rest.get('test/hide', { msg: 'Could not delete 500.' });
  }

  test401() {
    this.rest.get('test/401', { msg: 'Could not test 401 error.' });
  }

  // Scratch Pad

  async generic() {
    const mock = await this.rest.get('test/mock', { msg: 'Could not perform mock action' });

    console.log(mock.lala);
  }
}
