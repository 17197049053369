'use strict';

exports.__esModule = true;
var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
  return typeof obj;
} : function (obj) {
  return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
};
exports.setField = setField;
exports.registerOptionField = registerOptionField;
exports.setOptionField = setOptionField;
exports.isFieldValid = isFieldValid;
exports.getFieldInvalidHint = getFieldInvalidHint;
exports.isFieldVisiblyInvalid = isFieldVisiblyInvalid;
exports.showInvalidField = showInvalidField;
exports.hideInvalidFields = hideInvalidFields;
exports.setFieldShowInvalid = setFieldShowInvalid;
exports.clearFields = clearFields;
exports.getField = getField;
exports.getFieldValue = getFieldValue;
exports.getFieldLabel = getFieldLabel;
exports.phoneNumber = phoneNumber;
exports.email = email;
exports.vcode = vcode;
exports.password = password;
exports.username = username;
exports.mfaCode = mfaCode;
exports.isSelecting = isSelecting;
exports.renderOptionSelection = renderOptionSelection;
var _react = require('react');
var _react2 = _interopRequireDefault(_react);
var _immutable = require('immutable');
var _trim = require('trim');
var _trim2 = _interopRequireDefault(_trim);
var _option_selection_pane = require('./option_selection_pane');
var _option_selection_pane2 = _interopRequireDefault(_option_selection_pane);
var _index = require('../core/index');
var l = _interopRequireWildcard(_index);
function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};
    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key];
      }
    }
    newObj.default = obj;
    return newObj;
  }
}
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var minMax = function minMax(value, min, max) {
  return value.length >= min && value.length <= max;
};
var getDefaultValidator = function getDefaultValidator(field) {
  switch (field) {
    case 'family_name':
    case 'given_name':
      return function (str) {
        return minMax((0, _trim2.default)(str), 1, 150);
      };
    case 'name':
      return function (str) {
        return minMax((0, _trim2.default)(str), 1, 300);
      };
    case 'nickname':
      return function (str) {
        return minMax((0, _trim2.default)(str), 1, 300);
      };
    default:
      return function (str) {
        return (0, _trim2.default)(str).length > 0;
      };
  }
};
function setField(m, field, value) {
  var validator = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : getDefaultValidator(field);
  var prevValue = m.getIn(['field', field, 'value']);
  var prevShowInvalid = m.getIn(['field', field, 'showInvalid'], false);
  for (var _len = arguments.length, args = Array(_len > 4 ? _len - 4 : 0), _key = 4; _key < _len; _key++) {
    args[_key - 4] = arguments[_key];
  }
  var validation = validate.apply(undefined, [validator, value].concat(args));
  return m.mergeIn(['field', field], validation.merge({
    value: value,
    showInvalid: prevShowInvalid && prevValue === value
  }));
}
function validate(validator, value) {
  if (typeof validator != 'function') return (0, _immutable.Map)({
    valid: true
  });
  for (var _len2 = arguments.length, args = Array(_len2 > 2 ? _len2 - 2 : 0), _key2 = 2; _key2 < _len2; _key2++) {
    args[_key2 - 2] = arguments[_key2];
  }
  var validation = validator.apply(undefined, [value].concat(args));
  return validation && (typeof validation === 'undefined' ? 'undefined' : _typeof(validation)) === 'object' ? (0, _immutable.Map)({
    valid: validation.valid,
    invalidHint: validation.hint
  }) : (0, _immutable.Map)({
    valid: !!validation
  });
}

// TODO: this should handle icons, and everything.
// TODO: also there should be a similar fn for regular fields.
function registerOptionField(m, field, options, initialValue) {
  var valid = true,
    hasInitial = !initialValue,
    initialOption = void 0;
  options.forEach(function (x) {
    valid = valid && x.get('label') && typeof x.get('label') === 'string' && x.get('value') && typeof x.get('value') === 'string';
    if (!hasInitial && x.get('value') === initialValue) {
      initialOption = x;
      hasInitial = true;
    }
  });
  if (!valid || !options.size) {
    var stopError = new Error('The options provided for the "' + field + '" field are invalid, they must have the following format: {label: "non-empty string", value: "non-empty string"} and there has to be at least one option.');
    stopError.code = 'invalid_select_field';
    // TODO: in the future we might want to return the result of the
    // operation along with the model insteand of stopping the
    // rendering, like [false, m] in the case of failure and [true, m]
    // in the case of success.
    return l.stop(m, stopError);
  }
  if (!initialOption) initialOption = (0, _immutable.Map)({});
  return m.mergeIn(['field', field], initialOption, (0, _immutable.Map)({
    options: options,
    showInvalid: false,
    valid: !initialOption.isEmpty()
  }));
}
function setOptionField(m, field, option) {
  return m.mergeIn(['field', field], option.merge((0, _immutable.Map)({
    valid: true,
    showInvalid: false
  })));
}
function isFieldValid(m, field) {
  return m.getIn(['field', field, 'valid']);
}
function getFieldInvalidHint(m, field) {
  return m.getIn(['field', field, 'invalidHint'], '');
}
function isFieldVisiblyInvalid(m, field) {
  return m.getIn(['field', field, 'showInvalid'], false) && !m.getIn(['field', field, 'valid']);
}
function showInvalidField(m, field) {
  return m.setIn(['field', field, 'showInvalid'], !isFieldValid(m, field));
}
function hideInvalidFields(m) {
  return m.update('field', function (fields) {
    return fields && fields.map(function (field) {
      return field.set('showInvalid', false);
    });
  });
}

// TODO: only used in passwordless, when we update it to use
// validateAndSubmit this won't be needed anymore.
function setFieldShowInvalid(m, field, value) {
  return m.setIn(['field', field, 'showInvalid'], value);
}
function clearFields(m, fields) {
  var keyPaths = void 0;
  if (!fields || fields.length === 0) {
    keyPaths = [['field']];
  } else {
    keyPaths = fields.map(function (x) {
      return ['field', x];
    });
  }
  return keyPaths.reduce(function (r, v) {
    return r.removeIn(v);
  }, m);
}
function getField(m, field) {
  var notFound = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : new _immutable.Map({});
  return m.getIn(['field', field], notFound);
}
function getFieldValue(m, field) {
  var notFound = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  return getField(m, field).get('value', notFound);
}
function getFieldLabel(m, field) {
  var notFound = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  return getField(m, field).get('label', notFound);
}

// phone number

function phoneNumber(lock) {
  return lock.getIn(['field', 'phoneNumber', 'value'], '');
}

// email

function email(m) {
  return getFieldValue(m, 'email');
}

// vcode

function vcode(m) {
  return getFieldValue(m, 'vcode');
}

// password

function password(m) {
  return getFieldValue(m, 'password');
}

// username

function username(m) {
  return getFieldValue(m, 'username');
}

// mfa_code

function mfaCode(m) {
  return getFieldValue(m, 'mfa_code');
}

// select field options

function isSelecting(m) {
  return !!m.getIn(['field', 'selecting']);
}
function renderOptionSelection(m) {
  var name = m.getIn(['field', 'selecting', 'name']);
  return isSelecting(m) ? _react2.default.createElement(_option_selection_pane2.default, {
    model: m,
    name: name,
    icon: m.getIn(['field', 'selecting', 'icon']),
    iconUrl: m.getIn(['field', 'selecting', 'iconUrl']),
    items: m.getIn(['field', name, 'options'])
  }) : null;
}