import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MaterialComponentsModule } from 'src/app/shared/modules/material-components.module';
import { LayoutComponentsModule } from 'src/app/shared/components/layout/layout-components.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AdminPricingComponent } from './admin-pricing.component';
import { AdminBundleCardComponent } from './components/admin-bundle-card/admin-bundle-card.component';
import { InputBundleCardComponent } from './components/input-bundle-card/input-bundle-card.component';
import { PortalMenusModule } from 'src/app/shared/modules/portal-menus.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { LayoutsModule } from 'src/app/shared/layouts/layouts.module';

@NgModule({
  declarations: [AdminPricingComponent, AdminBundleCardComponent, InputBundleCardComponent],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PortalMenusModule,
    MaterialComponentsModule,
    LayoutComponentsModule,
    FontAwesomeModule,
    MatDialogModule,
    MatExpansionModule,
    MatSnackBarModule,
    LayoutsModule,
  ],
})
export class AdminPricingModule {}
