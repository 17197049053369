import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';

@Component({
  selector: 'app-update-language',
  templateUrl: './update-language-dialog.html',
  styleUrls: ['./update-language-dialog.scss'],
})
export class UpdateLanguageDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _rest: RestAPIService,
    private dialogRef: MatDialogRef<UpdateLanguageDialog>,
    protected _snackBar: MatSnackBar,
  ) {}

  public closeDialog(shouldSaveLanguage: boolean) {
    this.dialogRef.close(shouldSaveLanguage);
  }
}
