import { Component } from '@angular/core';
import { biographies } from './data/biographies';

@Component({
  selector: 'lsw-biography',
  templateUrl: './biography.component.html',
  styleUrls: ['./biography.component.scss'],
})
export class BiographyComponent {
  biographyList = biographies;

  constructor() {}

  goTo() {
    window.history.back();
  }
}
