<div class="mainContainer">
  <div class="col-8 px-0 m-auto">
    <div class="header d-flex justify-content-center my-3">Courses</div>

    <div class="d-flex justify-content-center my-4">
      <app-simple-button
        [text]="'Add new course'"
        (click)="openCourseCreator()"
      ></app-simple-button>
    </div>

    <div class="courseList d-flex justify-content-center courseCard p-3">
      <mat-card
        class="cards mx-2"
        *ngFor="let course of courseList"
      >
        <mat-card-header class="d-flex justify-content-center">
          <mat-card-title class="pt-2"> {{ course.title }} </mat-card-title>
        </mat-card-header>

        <div class="d-flex justify-content-center my-2 lessonAmount">
          {{ getLessonsAmount(course) }} Lessons
        </div>

        <div class="d-flex justify-content-center mt-3 lessonAmount">
          <div
            class="col-4 px-0 text-center raisedBtn p-3"
            (click)="openCourseEditor(course)"
          >
            Edit course
          </div>

          <div
            class="col-4 px-0 text-center raisedBtn p-3"
            (click)="duplicateCourse(course)"
          >
            Duplicate course
          </div>

          <div
            class="col-4 px-0 text-center raisedBtn p-3"
            (click)="deleteCourse(course)"
          >
            Delete course
          </div>
        </div>

        <div class="d-flex justify-content-center">
          <img
            class="courseImage"
            [src]="course.image"
          />
        </div>
      </mat-card>
    </div>
  </div>
</div>
