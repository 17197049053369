import { Component, HostListener, Input } from '@angular/core';
import { LessonsService } from '../../../services/lessons/lessons.service';
import { CoursesService } from '../../../services/courses/courses.service';
import { Router } from '@angular/router';

/**
 * @scope Displays a lesson title with an icon.
 */
@Component({
  selector: 'course-overview-section-item',
  templateUrl: './section-item.component.html',
  styleUrls: ['./section-item.component.scss'],
})
export class SectionItemComponent {
  @Input() section;
  @Input() sequence;

  constructor(private _lessons: LessonsService, private _courses: CoursesService, private _router: Router) {}

  @HostListener('click')
  handleClick() {
    this._lessons.selectSectionById(this.section.id);

    this._router.navigate(['courses', 'learning', this._courses.getCurrentCourseId()], {
      queryParams: { section: this.section.id },
    });
  }
}
