<div class="mainContainer h-100">
  <div class="header">New section</div>

  <div class="inputs text-center m-auto d-flex align-items-center">
    <div class="text-center m-auto">
      <mat-form-field>
        <mat-label>Title</mat-label>
        <input
          [(ngModel)]="sectionTitle"
          matInput
          #first
        />
      </mat-form-field>

      <br />

      <mat-form-field>
        <mat-label>Section type</mat-label>
        <mat-select [(ngModel)]="sectionType">
          <mat-option
            *ngFor="let type of getSectionTypes()"
            [value]="type"
          >
            {{ type }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <br />

      <app-simple-button
        [disabled]="!shouldEnableButton()"
        [text]="'Create section'"
        (click)="saveSection()"
      ></app-simple-button>
    </div>
  </div>
</div>
