import { Component, OnInit } from '@angular/core';
import { TutorialInfo, TutorialPage, TutorialComponent } from 'src/app/shared/models/tutorial.model';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { Tutorials } from 'src/app/shared/components/tutorial-modal/tutorial-modal.constants';
import { getTutorialInfo } from 'src/app/shared/components/tutorial-modal/tutorial-modal.utils';

@Component({
  selector: 'app-tutorials-org',
  templateUrl: './tutorials-org.component.html',
  styleUrls: ['./tutorials-org.component.scss'],
})
export class TutorialsOrgComponent implements OnInit {
  public showBeforeYouStart = false;
  public showTutorial = false;
  public tutorial: TutorialComponent;
  public isSingle = true;
  public acc: any = { organization: {} };
  readonly tutorialTypes = Tutorials;

  constructor(private _rest: RestAPIService) {}

  async ngOnInit() {
    this.acc = await this._rest
      .get('organization/self', { msg: 'Could not get organization.' })
      .catch(() => (this.acc = null));
  }

  public openWizard(index: Tutorials): void {
    const tutorialInfo = this._setTutorialInfo(index);
    const tutorialPage: TutorialPage = {
      tutorialInfo,
    };
    this.tutorial = new TutorialComponent([tutorialPage]);
    this.showTutorial = true;
  }

  public openFullWizard(): void {
    const pageOne = {
      tutorialInfo: [
        getTutorialInfo(Tutorials.USERS_ORG),
        getTutorialInfo(Tutorials.ADD_USER_ORG),
        getTutorialInfo(Tutorials.ADD_STUDENT_ORG),
      ],
    };

    const pageTwo = {
      tutorialInfo: this.acc.organization.isOutsider
        ? [
            getTutorialInfo(Tutorials.NEURALIGN_ORG),
            getTutorialInfo(Tutorials.ADD_CREDIT_ORG),
            getTutorialInfo(Tutorials.ENTERING_STUDENT_PAGE_ORG),
            getTutorialInfo(Tutorials.REMOTE_ACCESS_ORG),
            getTutorialInfo(Tutorials.DEMO_ORG),
          ]
        : [
            getTutorialInfo(Tutorials.PURCHASING_CREDITS_ORG),
            getTutorialInfo(Tutorials.NEURALIGN_ORG),
            getTutorialInfo(Tutorials.ADD_CREDIT_ORG),
            getTutorialInfo(Tutorials.ENTERING_STUDENT_PAGE_ORG),
            getTutorialInfo(Tutorials.REMOTE_ACCESS_ORG),
            getTutorialInfo(Tutorials.DEMO_ORG),
          ],
    };

    const pageThree = {
      tutorialInfo: [getTutorialInfo(Tutorials.TRAINING_ORG), getTutorialInfo(Tutorials.DOCUMENTS_ORG)],
    };

    this.tutorial = new TutorialComponent([pageOne, pageTwo, pageThree]);

    this.isSingle = false;
    this.showTutorial = true;
  }

  private _setTutorialInfo(index: Tutorials): TutorialInfo[] {
    this.isSingle = true;
    return [getTutorialInfo(index)];
  }

  public onCompleteTutorial = () => {
    this.showTutorial = false;
    this.tutorial = null;
  };
}
