import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { TakeATourComponent } from './take-a-tour.component';
import { TakeATourTargetComponent } from './components/target/target.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TakeATourGameTargetComponent } from './components/game-target/game-target.component';
import { TakeATourHighlightComponent } from './components/highlight/highlight.component';
import { LayoutsModule } from 'src/app/shared/layouts/layouts.module';
import { CurtainModule } from 'src/app/shared/components/curtain/curtain.module';

@NgModule({
  declarations: [
    TakeATourComponent,
    TakeATourHighlightComponent,
    TakeATourTargetComponent,
    TakeATourGameTargetComponent,
  ],
  imports: [BrowserModule, LayoutsModule, FontAwesomeModule, CurtainModule],
})
export class TakeATourModule {}
