import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Theme } from 'src/app/pages/configuration-pages/content-configurations/components/themes/interfaces/themes.interface';
import { classicThemeLabel } from 'src/app/pages/program/program-game.const';
import { AuthService } from 'src/app/services/auth/auth.service';
import { User } from 'src/app/shared/models';

interface EnabledThemesInterface {
  themes: Theme[];
  b2cEnabledThemes: string[];
  user: User;
}

@Component({
  selector: 'app-select-enabled-themes',
  templateUrl: './select-enabled-themes.component.html',
  styleUrls: ['./select-enabled-themes.component.scss'],
})
export class SelectEnabledThemes implements OnInit {
  public displayedColumns: string[] = ['name', 'enabled'];
  public dataSource: MatTableDataSource<Theme>;

  public checked = true;
  public unchecked = false;

  public en_ca = 'en_ca';

  constructor(
    public dialogRef: MatDialogRef<SelectEnabledThemes>,
    @Inject(MAT_DIALOG_DATA) public data: EnabledThemesInterface,
    protected _snackBar: MatSnackBar,
    public auth: AuthService,
  ) {}

  async ngOnInit() {
    this.dataSource = new MatTableDataSource(this.data.themes);
  }

  public returnThemeLabel(theme: Theme) {
    if (!theme) {
      return;
    }

    const user = this.data.user;

    if (theme.label[user.organization.language]) {
      return theme.label[user.organization.language];
    } else {
      return theme.label[this.en_ca];
    }
  }

  public changeEnabledStatus(theme: Theme) {
    const enabledTheme = this.data.b2cEnabledThemes.find((v) => v === theme.id);

    if (!enabledTheme) {
      this.data.b2cEnabledThemes.push(theme.id);
    } else {
      const selectedTheme = this.data.b2cEnabledThemes.find((v) => v === theme.id);
      const index = this.data.b2cEnabledThemes.indexOf(selectedTheme);
      this.data.b2cEnabledThemes.splice(index, 1);
    }
  }

  public checkIfThemeIsEnabled(theme: Theme): boolean {
    const findById = this.data.b2cEnabledThemes.find((v) => v === theme.id);
    const findByVariableName = this.data.b2cEnabledThemes.find((v) => v === theme.variableName);

    // if the b2c provider still have the b2cEnabledThemes property with variableNames we change it to id
    if (findByVariableName) {
      const index = this.data.b2cEnabledThemes.indexOf(findByVariableName);
      this.data.b2cEnabledThemes[index] = theme.id;

      return true;
    }

    return findById ? true : false;
  }

  public confirm() {
    this.dialogRef.close(this.data.b2cEnabledThemes);
  }

  public cancel() {
    this.dialogRef.close();
  }

  public checkIfItsDefaultTheme(theme: Theme) {
    return theme.variableName === classicThemeLabel && theme.isDefault;
  }
}
