<ng-container *ngIf="currentSection$ | async as section">
  <ng-container *ngIf="section">
    <h1>{{ (currentLesson$ | async).title }}</h1>
    <div class="flex-container">
      <courses-shared-lesson-type-icon
        [type]="section?.type"
        [remSize]="1.4"
      ></courses-shared-lesson-type-icon>
      <h2>{{ section?.title }}</h2>
    </div>
  </ng-container>
</ng-container>
