import { Component } from '@angular/core';
import { MobileMenuService } from '../../services/mobile-menu/mobile-menu.service';
import { IconDefinition, faBars } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'header-public',
  templateUrl: './public-header.component.html',
  styleUrls: ['./public-header.component.scss'],
})
export class PublicHeaderComponent {
  mobileMenuIcon: IconDefinition = faBars;

  constructor(public mobile: MobileMenuService) {}

  toggle() {
    this.mobile.toggleMobileMenu();
  }
}
