<app-main-layout
  [breadcrumbs]="[
    { name: 'Home', url: '/' },
    { name: 'Payment manager', url: '/installment-payments-control-center' }
  ]"
>
  <div
    *ngIf="pageLoading"
    class="pageLoading"
  >
    <fa-icon
      class="regularIcon"
      [icon]="spinner"
      [spin]="true"
    ></fa-icon>
  </div>

  <div
    class="noPaymentsWarning"
    *ngIf="!pageLoading && paymentList.length === 0"
  >
    You dont have active installment payments
  </div>

  <div class="col-10 px-0 position-relative m-auto">
    <div
      class="cards col-12 px-0 m-auto"
      *ngIf="isB2cProvider && !pageLoading && paymentList.length > 0"
    >
      <div class="infoCards my-4 d-flex justify-content-start">
        <div class="mx-2 d-flex justify-content-center">
          <mat-card class="card mx-2">
            {{ listSize }} <br />
            <span class="cardFooter"> Active installment payments </span>
          </mat-card>
        </div>

        <div class="mx-2 d-flex justify-content-center">
          <mat-card class="card mx-2">
            <div>
              <div *ngIf="monthlyIncome">
                {{ incomeCurrency }} {{ monthlyIncome }}
              </div>

              <div *ngIf="!monthlyIncome">
                <fa-icon
                  class="regularIcon"
                  [icon]="spinner"
                  [spin]="true"
                ></fa-icon>
              </div>

              <div class="cardFooter">Monthly income</div>
            </div>
          </mat-card>
        </div>
      </div>
    </div>

    <div
      class="mat-elevation-z8 col-12 px-0 m-auto tableContainer"
      [ngClass]="{ 'd-none': paymentList.length === 0 }"
    >
      <div
        class="tableLoading"
        *ngIf="tableLoading"
      >
        <mat-spinner></mat-spinner>
      </div>

      <div
        [ngClass]="{ 'disabled': tableLoading }"
        class="refresh w-100 text-end"
        (click)="refreshTable()"
        *ngIf="isB2cProvider && !pageLoading"
      >
        <div>
          <fa-icon [icon]="refresh"> </fa-icon>

          Refresh table
        </div>
      </div>

      <table
        mat-table
        [dataSource]="dataSource"
        class="col-12 p-2 text-center mt-4 subscriptionInfo"
        *ngIf="isB2cClient"
      >
        <div class="refresh">Refresh table</div>
        <div class="table">
          <ng-container
            matColumnDef="cost"
            class="text-center"
          >
            <th
              mat-header-cell
              *matHeaderCellDef
              class="text-center"
            >
              Cost
            </th>
            <td
              mat-cell
              *matCellDef="let payment"
              class="text-center"
            >
              {{ payment.currency }} {{ payment.cost }}
            </td>
          </ng-container>

          <ng-container
            matColumnDef="next charge"
            class="text-center"
          >
            <th
              mat-header-cell
              *matHeaderCellDef
              class="text-center"
            >
              Next charge
            </th>
            <td
              mat-cell
              *matCellDef="let payment"
              class="text-center"
            >
              {{ paymentsCompleted(payment) || payment.isCanceled ? '---' :
              getDate(payment.currentPeriodEnd) }}
            </td>
          </ng-container>

          <ng-container
            matColumnDef="end date"
            class="text-center"
          >
            <th
              mat-header-cell
              *matHeaderCellDef
              class="text-center"
            >
              End date
            </th>
            <td
              mat-cell
              *matCellDef="let payment"
              class="text-center"
            >
              {{ getDate(payment.endDate) }}
            </td>
          </ng-container>

          <ng-container
            matColumnDef="completed payments"
            class="text-center"
          >
            <th
              mat-header-cell
              *matHeaderCellDef
              class="text-center"
            >
              Completed payments
            </th>
            <td
              mat-cell
              *matCellDef="let payment"
              class="text-center"
            >
              {{ payment.completedPayments }} / {{
              getInstallmentPaymentsAmount(payment, payment.interval) }}
            </td>
          </ng-container>

          <ng-container
            matColumnDef="card"
            class="text-center"
          >
            <th
              class="text-center"
              mat-header-cell
              *matHeaderCellDef
            >
              Card
            </th>
            <td
              mat-cell
              *matCellDef="let payment"
              class="text-center"
            >
              <div class="d-flex justify-content-center align-items-center">
                <fa-icon
                  *ngIf="returnCardIcon(payment)"
                  class="cardIcon"
                  [icon]="returnCardIcon(payment)"
                ></fa-icon>

                <div class="mx-2">{{ returnPaymentSource(payment) }}</div>
              </div>
            </td>
          </ng-container>

          <ng-container
            matColumnDef="status"
            class="text-center"
          >
            <th
              mat-header-cell
              *matHeaderCellDef
              class="text-center"
            >
              Last payment status
            </th>
            <td
              mat-cell
              *matCellDef="let payment"
              class="text-center"
            >
              <fa-icon
                class="mr-2 {{ getPaymentClass(payment) }}"
                [icon]="circle"
              ></fa-icon>
              {{ invoiceIsPaid(payment.lastInvoice) ? 'Paid' : 'Waiting for
              payment' }}
              <fa-icon
                (click)="retryPayment(payment)"
                *ngIf="shouldAllowRetry(payment.lastInvoice)"
                matTooltip="Retry payment"
                class="paymentRefresh"
                [icon]="refresh"
              ></fa-icon>
            </td>
          </ng-container>

          <ng-container
            matColumnDef="studentsIncluded"
            class="text-center"
          >
            <th
              mat-header-cell
              *matHeaderCellDef
              class="text-center"
            >
              Credits
            </th>
            <td
              mat-cell
              *matCellDef="let payment"
              class="text-center"
            >
              {{ payment.studentsIncluded }}
            </td>
          </ng-container>

          <ng-container
            matColumnDef="actions"
            class="text-center"
          >
            <th
              mat-header-cell
              *matHeaderCellDef
              class="text-center"
            >
              Actions
            </th>
            <td
              mat-cell
              *matCellDef="let payment"
              class="actionIcons"
              class="text-center"
            >
              <a
                matTooltip="Invoices"
                matTooltipPosition="above"
              >
                <fa-icon
                  class="mx-2 regularIcon"
                  [icon]="receipt"
                  (click)="findPaymentInvoices(payment)"
                >
                </fa-icon>
              </a>

              <a
                matTooltip="Change payment settings"
                matTooltipPosition="above"
                [ngClass]="{ disabled: payment.isCanceled }"
              >
                <fa-icon
                  (click)="adjustPaymentSettings(payment)"
                  [ngClass]="{ disabledIcon: payment.isCanceled }"
                  class="mx-2 regularIcon"
                  [icon]="card"
                >
                </fa-icon>
              </a>

              <a
                matTooltip="Renew payment plan"
                matTooltipPosition="above"
                [ngClass]="{ disabled: payment.isCanceled }"
                *ngIf="allowRenew(payment)"
              >
                <fa-icon
                  (click)="renewRecurringPayment(payment)"
                  class="mx-2 regularIcon"
                  [ngClass]="{ disabledIcon: payment.isCanceled }"
                  [icon]="renew"
                >
                </fa-icon>
              </a>

              <a
                matTooltip="Cancel payment plan"
                matTooltipPosition="above"
                *ngIf="allowCancel(payment) && !payment.isCanceled"
              >
                <fa-icon
                  (click)="changePaymentStatus(payment, true)"
                  class="mx-2 regularIcon"
                  [icon]="cancel"
                >
                </fa-icon>
              </a>
            </td>
          </ng-container>
        </div>

        <tr
          mat-header-row
          *matHeaderRowDef="b2cClientColumns"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: b2cClientColumns"
        ></tr>
      </table>

      <table
        mat-table
        [dataSource]="dataSource"
        class="col-12 p-2 text-center my-4 subscriptionInfo"
        *ngIf="isB2cProvider"
      >
        <div class="table">
          <ng-container
            matColumnDef="client"
            class="text-center"
          >
            <th
              mat-header-cell
              *matHeaderCellDef
              class="text-center"
            >
              Client
            </th>
            <td
              mat-cell
              *matCellDef="let payment"
              class="text-center"
            >
              {{ payment.user.patron.name }}
            </td>
          </ng-container>

          <ng-container
            matColumnDef="cost"
            class="text-center"
          >
            <th
              mat-header-cell
              *matHeaderCellDef
              class="text-center"
            >
              Cost
            </th>
            <td
              mat-cell
              *matCellDef="let payment"
              class="text-center"
            >
              {{ payment.currency }} {{ payment.cost }}
            </td>
          </ng-container>

          <ng-container
            matColumnDef="next charge"
            class="text-center"
          >
            <th
              mat-header-cell
              *matHeaderCellDef
              class="text-center"
            >
              Next charge
            </th>
            <td
              mat-cell
              *matCellDef="let payment"
              class="text-center"
            >
              {{ paymentsCompleted(payment) ? '---' :
              getDate(payment.currentPeriodEnd) }}
            </td>
          </ng-container>

          <ng-container
            matColumnDef="end date"
            class="text-center"
          >
            <th
              mat-header-cell
              *matHeaderCellDef
              class="text-center"
            >
              End date
            </th>
            <td
              mat-cell
              *matCellDef="let payment"
              class="text-center"
            >
              {{ getDate(payment.endDate) }}
            </td>
          </ng-container>

          <ng-container
            matColumnDef="completed payments"
            class="text-center"
          >
            <th
              mat-header-cell
              *matHeaderCellDef
              class="text-center"
            >
              Completed payments
            </th>
            <td
              mat-cell
              *matCellDef="let payment"
              class="text-center"
            >
              {{ payment.completedPayments }} / {{
              getInstallmentPaymentsAmount(payment, payment.interval) }}
            </td>
          </ng-container>

          <ng-container
            matColumnDef="studentsIncluded"
            class="text-center"
          >
            <th
              mat-header-cell
              *matHeaderCellDef
              class="text-center"
            >
              Credits
            </th>
            <td
              mat-cell
              *matCellDef="let payment"
              class="text-center"
            >
              {{ payment.studentsIncluded }}
            </td>
          </ng-container>

          <ng-container
            matColumnDef="payment interval"
            class="text-center"
          >
            <th
              mat-header-cell
              *matHeaderCellDef
              class="text-center"
            >
              Payment interval
            </th>
            <td
              mat-cell
              *matCellDef="let payment"
              class="text-center"
            >
              {{ payment.interval }}
            </td>
          </ng-container>

          <ng-container
            matColumnDef="status"
            class="text-center"
          >
            <th
              mat-header-cell
              *matHeaderCellDef
              class="text-center"
            >
              Last payment status
            </th>
            <td
              mat-cell
              *matCellDef="let payment"
              class="text-center"
            >
              <fa-icon
                class="mr-2 {{ getPaymentClass(payment) }}"
                [icon]="circle"
              ></fa-icon>
              {{ invoiceIsPaid(payment.lastInvoice) ? 'Paid' : 'Waiting for
              payment' }}
            </td>
          </ng-container>

          <ng-container
            matColumnDef="actions"
            class="text-center"
          >
            <th
              mat-header-cell
              *matHeaderCellDef
              class="text-center"
            >
              Actions
            </th>
            <td
              mat-cell
              *matCellDef="let payment"
              class="actionIcons text-center"
            >
              <a
                matTooltip="Invoices"
                matTooltipPosition="above"
              >
                <fa-icon
                  class="mx-2 regularIcon"
                  [icon]="receipt"
                  (click)="findPaymentInvoices(payment)"
                >
                </fa-icon>
              </a>
            </td>
          </ng-container>
        </div>

        <tr
          mat-header-row
          *matHeaderRowDef="b2cProviderColumns"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: b2cProviderColumns"
        ></tr>
      </table>

      <mat-paginator
        (page)="onPageChange($event)"
        [length]="listSize"
        [pageSizeOptions]="[5, 10]"
        aria-label="Select page"
      >
      </mat-paginator>
    </div>
  </div>
</app-main-layout>
