<div class="newLanguageContainer col-12 mx-0">
  <div class="header col-12 px-0 text-center">Update Language</div>

  <div class="p-3 text-center">
    <div>
      <mat-form-field
        class="w-100"
        appearance="fill"
      >
        <mat-label>Language name</mat-label>
        <input
          matInput
          [(ngModel)]="data.name"
          autocomplete="off"
          placeholder="ex: English ( Canada )"
        />
      </mat-form-field>
    </div>

    <div>
      <mat-form-field
        class="w-100"
        appearance="fill"
      >
        <mat-label>Language code</mat-label>
        <input
          matInput
          [(ngModel)]="data.languageCode"
          autocomplete="off"
          placeholder="ex: en_ca"
        />
      </mat-form-field>
    </div>

    <div>
      <mat-form-field
        class="w-100"
        appearance="fill"
      >
        <mat-label>Flag Code</mat-label>
        <input
          matInput
          [(ngModel)]="data.flagCode"
          autocomplete="off"
          placeholder="ex: US"
        />
      </mat-form-field>
    </div>

    <div class="buttonWrapper d-flex justify-content-center">
      <button
        mat-raised-button
        class="mx-3"
        (click)="closeDialog(true)"
      >
        Save
      </button>

      <button
        class="mx-3"
        mat-raised-button
        (click)="closeDialog(false)"
      >
        Cancel
      </button>
    </div>
  </div>
</div>
