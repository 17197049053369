import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { isEmpty } from 'lodash';
import { RoleService } from 'src/app/services/roles/role.service';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { MobileMenuService } from '../../services/mobile-menu/mobile-menu.service';
import { get } from 'lodash';

@Component({
  selector: 'header-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnInit {
  public logo: string;
  public org: any;

  constructor(private _roles: RoleService, private _rest: RestAPIService, private _mobile: MobileMenuService) {}

  ngOnInit(): void {
    this.getLogo();
  }

  public async getLogo() {
    const defaultLogo = environment.PORTAL_CONSTANTS.PORTAL_LOGO;

    // if there is no user, dont do anything
    if (isEmpty(this._roles.user)) {
      this.logo = this.enforceHttps(defaultLogo);
      return;
    }

    // get organization object
    const req = await this._rest.get('organization/self', { msg: 'Could not get organization.' });

    // set custom or default logo
    if (get(req, 'organization.logo')) {
      const organizationCustomLogo = req.organization.logo;
      this.logo = this.enforceHttps(organizationCustomLogo);
    } else {
      this.logo = this.enforceHttps(defaultLogo);
    }
  }

  // Needed for Cloudinary
  private enforceHttps(url: string): string {
    const pattern = 'http://';
    const newValue = 'https://';
    return url.replace(pattern, newValue);
  }

  public closeMobileMenu() {
    if (this._mobile.isMobileMenuOpen$.value === true) {
      this._mobile.toggleMobileMenu();
    }
  }
}
