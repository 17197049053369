import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { styleElement } from '../../helpers/styleElement.function';
import { Styles } from '../../styles/lsw.styles';

@Directive({
  selector: '[roseBg]',
})
export class RoseBgDirective {
  constructor(private el: ElementRef, private _renderer: Renderer2) {}

  ngAfterViewChecked() {
    // use ngAfterViewChecked to override styles set during ngAfterViewInit
    styleElement(this._renderer, this.el, {
      backgroundColor: Styles.Colours.Neuralign.ROSE,
    });
  }
}
