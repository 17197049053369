<ng-container *ngIf="(this.pmbService.publicMessage$ | async).enabled">
  <div class="wrapper">
    <div class="message">
      <fa-icon [icon]="icon"></fa-icon>
      <p>
        {{ (this.pmbService.publicMessage$ | async).text }}
      </p>
    </div>
  </div>
</ng-container>
