import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { faSearch, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Help } from './interfaces/help.interface';

const questions = [
  {
    question: `What is the Neuralign assessment (placement test)?`,
    answer: `The Neuralign assessment (placement test) takes 10 minutes to complete. Once the student completes the Neuralign assessment (placement test) the program will assign the student to one of the 5 levels - Jr, P1, P2, P3, or P4. The placement is determined by the student's age and reading ability.`,
  },
  {
    question: `How long are the Neuralign sessions?`,
    answer: `Cognitive Therapy (CT) is 15 x 1-hour sessions.  Each session only reopens after 8 hours from the time a session is completed.<br>
    The Speed Reading (SR) link activates after the 9th session of the Cognitive Therapy (CT) is completed. Speed Reading (SR) is completed for 10 weeks. Students read and track their one-minute reading session for 5 days per week for 10 weeks.  Each week (5 days) the same story is read.<br>
    The Reading Exercise (RE) only opens after the 15 sessions of the Cognitive Therapy (CT) is completed. Reading Exercise (RE) is completed for 10 weeks. 15 to 30 minutes of games, reading and reading comprehension questions per day, for 5 days a week.`,
  },
  {
    question: `How come Cognitive Therapy (CT) locks up after I have completed a session?`,
    answer: `Cognitive Therapy (CT) only reopens after 8 hours from the time a session is completed. It is important to allow your brain to rest in-between sessions.`,
  },
  {
    question: `Can we complete more than 1 Cognitive Therapy (CT) session per day?`,
    answer: `Another Cognitive Therapy (CT) session can be completed after the 8 hour wait period. So if 2 sessions are to be completed in a day you can do one in the morning and the second in the evening.  Example 9am and 5pm.`,
  },
  {
    question: `Can a student open the Reading Exercise (RE) program without completing Cognitive Therapy (CT)?`,
    answer: `The Reading Exercise (RE) only opens after the 15 sessions of the Cognitive Therapy (CT) is completed.`,
  },
  {
    question: `How are the different levels for the Cognitive Therapy (CT) chosen for the student?`,
    answer: `When the student completes the Neuralign assessment (placement test) the program will assign the student to one of the 5 levels - Jr, P1, P2, P3, or P4. This is determined by student's age and how many correct answers were completed on the Neuralign assessment.`,
  },
  {
    question: `When is the Speed Reading (SR) activated?`,
    answer: `The Speed Reading link opens up after the 9th session of the Cognitive Therapy (CT) is completed.`,
  },
  {
    question: `How is the student’s login information generated and how do they receive the information?`,
    answer: `Clients who have an email and who can register and pay for their student will create an account for their student. Once the paid program is linked to the student they click on the “Neuralign” on the menu bar or click on the “Up Arrow” to go into the students Neuralign program page.`,
  },
  {
    question: `How do I find my student's login and pw information?`,
    answer: `Students don’t have login and PW only clients who register will. If you are a client and a student, you will receive an email with your login and temporary PW and some other information, which you will be prompt to change when you sign in. PW can be changed anytime by clicking on the “Don’t remember your password” link. You will then be prompted to change your PW.`,
  },
  {
    question: `How does the student see an overview of the exercises before they start the program?`,
    answer: `On the menu tab, there is a link named “Demo” which your client can open to see how all the gameplays are completed.`,
  },
  {
    question: `How do I know if my student is on the correct Cognitive Therapy (CT) level?`,
    answer: `Often clients (parents) might think the CT level is not quick enough for their student (child). CT purposely starts slowly so that students are able to build the phonemic awareness of the words presented to them.  As students go up in sessions the skills will get more difficult and more demanding on their visual and auditory system.`,
  },
  {
    question: `What size screen should be used for the Neuralign program?`,
    answer: `For the Cognitive Therapy (CT) we recommend a 21" + screen should be used, but no larger than 35". Reading Exercise (RE) can be completed on any size computer screen/laptop.`,
  },
  {
    question: `When clients access their Cognitive Therapy (CT), Reading Exercise (RE) and Speed Reading (SR) at home, will it automatically go up to the level or day they are on?`,
    answer: `Yes the program will automatically go up a level every time a client completes a session.`,
  },
  {
    question: `Should the Reading Exercise (RE) and Speed Reading (SR) be read silently or aloud?`,
    answer: `This will depend on the skill and age of the student. Reading both aurally and silently are crucial skills to learn when reading so both should be completed.`,
  },
  {
    question: `As a client (parent) how can I support my student (child)?`,
    answer: `It is very important especially for the FIRST FEW sessions to be available in case your student (child) has questions.  Encourage them to get their points, help them understand how each gameplay works. Watch to see if they are able to do each game.  If not press the “p” button on the keyboard to “pause” the program and take the time to explain what they need to do.  Encouragement is critical. The program is set up for success, if the student starts feeling they can do it then they will want to do it.  Motivate and help as much as possible. If your student (child) is young sit next to them and help them when needed.`,
  },
  {
    question: `What are the requirements to complete Neuralign at home?`,
    answer: `Requirements to complete the Neuralign at Home. You will need:<br>
    Over the ear Headset
    External mouse
    21” to 35” Monitor or TV
    Keyboard
    Computer<br>
    Recommended specs for a PC desktop: i3 and above processor. 6GB or more RAM. Graphics card with 2GB internal memory.
    Recommended specs for Mac: Any mac0S.<br>
    To use WebGL Firefox browser is preferred.
    To test other browsers to see if they support WebGL go to get.webgl.org, if the square is turning then that Browser supports webgl.<br>
    For better results, if using a laptop with a smaller screen it can be connected to a larger screen.
    Set up your workstation in a quiet uninterrupted place.`,
  },
  {
    question: `What is the best time of the day to complete the program?`,
    answer: `Best time of the day is morning when students are fresh and not so tired. Right after school after having a snack is also a good time. Depending on the age of the student, it is better not to do it late in the evening.`,
  },
  {
    question: `Can I pause the program once we start?`,
    answer: `Yes press the p button on the computer keyboard to pause and unpause the program.`,
  },
  {
    question: `How can we hear the auditory again while on the program?`,
    answer: `Press the space bar on the computer keyboard to repeat the audio.`,
  },
];

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent implements OnInit {
  public questions: Help[] = [];
  public searchIcon: IconDefinition = faSearch;

  private _allQuestions: Help[] = [];
  private _searchSubject: Subject<string> = new Subject();

  ngOnInit() {
    this._setSearchSubscription();

    setTimeout(() => {
      this._allQuestions = questions;
      this.questions = questions;
    }, 250);
  }

  public updateSearch(searchTextValue: string) {
    this._searchSubject.next(searchTextValue);
  }

  private _setSearchSubscription() {
    this._searchSubject.pipe(debounceTime(400)).subscribe((searchValue: string) => {
      this.questions = this._filterQuestions(searchValue, this._allQuestions);
    });
  }

  private _filterQuestions(searchValue: string, faqs: Help[]): Help[] {
    return faqs.filter((faq) => {
      return this._containsSubstring(faq.question, searchValue) || this._containsSubstring(faq.answer, searchValue);
    });
  }

  private _containsSubstring(main: string, comparison: string): boolean {
    return main.toLowerCase().includes(comparison.trim().toLowerCase());
  }
}
