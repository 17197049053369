import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { clamp } from 'src/app/shared/helpers/clamp.function';
import { styleElement } from 'src/app/shared/helpers/styleElement.function';

@Directive({
  selector: '[smallSize]',
})
export class SmallSizeDirective {
  constructor(private el: ElementRef, private _renderer: Renderer2) {}

  ngOnInit() {
    styleElement(this._renderer, this.el, {
      fontSize: clamp(0.5, 1.4),
    });
  }
}
