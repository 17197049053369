import { Component, Input, OnInit } from '@angular/core';
import { IconDefinition, faCircle, faCircleCheck } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'completion-icon',
  templateUrl: './completion-icon.component.html',
  styleUrls: ['./completion-icon.component.scss'],
})
export class CompletionIconComponent implements OnInit {
  @Input() isCompleted = false;
  @Input() remSize = 1;

  public icon: IconDefinition;
  private readonly completedIcon: IconDefinition = faCircleCheck;
  private readonly incompletedIcon: IconDefinition = faCircle;

  public colour: string;

  public tooltip: string;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(): void {
    if (this.isCompleted) {
      this.icon = this.completedIcon;
      this.colour = '#3A3372';
    } else {
      this.icon = this.incompletedIcon;
      this.colour = '#B7B5C6';
    }

    this.tooltip = this.isCompleted ? 'Completed' : 'Not completed yet';
  }
}
