import { Component } from '@angular/core';
import { RoleService } from 'src/app/services/roles/role.service';

@Component({
  selector: 'header-auth',
  templateUrl: './auth-header.component.html',
  styleUrls: ['./auth-header.component.scss'],
})
export class AuthHeaderComponent {
  constructor(private _roles: RoleService) {}

  public isSchoolStudent: boolean = this._roles.isSchoolStudent();
}
