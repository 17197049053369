import { Component } from '@angular/core';
import { SlidesService } from 'src/app/pages/courses/services/slides/slides.service';

@Component({
  selector: 'display-slides-lesson',
  templateUrl: './slides-lesson.component.html',
  styleUrls: ['./slides-lesson.component.scss'],
})
export class SlidesLessonComponent {
  public currentSlide$ = this._slides.currentSlide$;

  constructor(private _slides: SlidesService) {}
}
