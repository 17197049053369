export enum AuthErrors {
  accessDenied = 'access_denied',
  invalidUserOrPassword = 'invalid_user_password',
  serverError = 'server_error',
}

export enum AuthErrorDescriptions {
  unableToConfigurePage = 'Unable to configure verification page.',
}

export enum PortalConfig {
  SCHOOL = 'school',
  DEFAULT = 'default',
}
