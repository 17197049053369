import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddButtonComponent } from './add-button.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [AddButtonComponent],
  imports: [CommonModule, FontAwesomeModule],
  exports: [AddButtonComponent],
})
export class AddButtonModule {}
