import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { UpdatesControllerService } from 'src/app/core/openapi';

@Injectable({
  providedIn: 'root',
})
export class UpdatesHelperService {
  private unreadUpdates = new BehaviorSubject(0);
  public $unreadUpdates = this.unreadUpdates.asObservable();

  constructor(private _updateService: UpdatesControllerService) {
    this.getUnreadUpdatesCount();
  }

  public async getUnreadUpdatesCount() {
    try {
      const { count } = await firstValueFrom(this._updateService.updatesControllerNewUpdates());

      if (count == null) {
        return;
      }

      this.unreadUpdates.next(count);
    } catch (err) {
      throw new Error('Could not get updates: ' + err.message);
    }
  }
}
