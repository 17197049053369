import { Component } from '@angular/core';
import { MobileMenuService } from '../../components/header/services/mobile-menu/mobile-menu.service';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-public-layout',
  templateUrl: './public-layout.component.html',
  styleUrls: ['./public-layout.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        // using status here for transition
        style({ opacity: 0, transform: 'translateY(20%)' }),
        animate(300, style({ opacity: 1, transform: 'translateY(0)' })),
      ]),
      transition('* => void', [animate(200, style({ opacity: 0 }))]),
    ]),
  ],
})
export class PublicLayoutComponent {
  showMobileMenu$ = this._mobile.isMobileMenuOpen$;

  constructor(private _mobile: MobileMenuService) {}

  toggleMobileMenu() {
    this._mobile.toggleMobileMenu();
  }
}
