import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RegisterComponent } from './register.component';
import { RegisterFormModule } from '../../shared/components/register/register.module';
import { HeaderModule } from 'src/app/shared/components/header/header.module';

@NgModule({
  declarations: [RegisterComponent],
  imports: [BrowserModule, RouterModule, RegisterFormModule, HeaderModule],
  providers: [],
})
export class RegisterModule {}
