export function isExcelDateSerial(value: any): boolean {
  if (typeof value !== 'number') {
    return false;
  }

  if (value < 1 || value > 2958465) {
    return false;
  }

  return true;
}
