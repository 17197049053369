import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { RestAPIService } from '../../services/rest/rest-api.service';
import { HomeComponent } from './home.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { LayoutsModule } from 'src/app/shared/layouts/layouts.module';
import { HeroComponent } from './sections/hero/hero.component';
import { QuotesComponent } from './sections/quotes/quotes.component';
import { ReportComponent } from './sections/report/report.component';
import { NeuralignDefComponent } from './sections/neuralign-def/neuralign-def.component';
import { DyslexiaDefComponent } from './sections/dyslexia-def/dyslexia-def.component';
import { AwardsComponent } from './sections/awards/awards.component';
import { NewsComponent } from './sections/news/news.component';
import { OrgsPortalHeroComponent } from './sections/hero/orgs-portal-hero/orgs-portal-hero.component';
import { DefaultPortalHeroComponent } from './sections/hero/default-portal-hero/default-portal-hero.component';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { CurtainModule } from 'src/app/shared/components/curtain/curtain.module';
import { DemoComponent } from './sections/demo/demo.component';

@NgModule({
  declarations: [
    HomeComponent,
    HeroComponent,
    QuotesComponent,
    ReportComponent,
    NeuralignDefComponent,
    DyslexiaDefComponent,
    AwardsComponent,
    NewsComponent,
    OrgsPortalHeroComponent,
    DefaultPortalHeroComponent,
    DemoComponent,
  ],
  imports: [BrowserModule, NgbModule, RouterModule, MatSnackBarModule, LayoutsModule, DirectivesModule, CurtainModule],
  providers: [RestAPIService],
})
export class HomeModule {}
