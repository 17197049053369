import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { Client } from '../../interfaces/Client.interface';

@Component({
  selector: 'app-select-client-dialog',
  templateUrl: './select-client.dialog.html',
  styleUrls: ['./select-client.dialog.scss'],
})
export class SelectClientDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<SelectClientDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public clients: Client[],
  ) {}

  public resolve(selected: Client = null): void {
    this.dialogRef.close(selected);
  }
}
