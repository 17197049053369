import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

interface Scores {
  [key: string]: number;
}

interface FullSessionData {
  colums: string[];
  scoresArray: Scores[];
}

@Component({
  selector: 'full-session-result-dialog',
  templateUrl: './full-session-result.html',
  styleUrls: ['./full-session-result.scss'],
})
export class FullSessionResultComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<FullSessionResultComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FullSessionData,
  ) {}

  public datasource: MatTableDataSource<Scores>;

  ngOnInit(): void {
    this.datasource = new MatTableDataSource(this.data.scoresArray);
  }

  public getColumValue(value: string) {
    return value != undefined ? value.toString().toUpperCase() : '---';
  }

  public removeExerciseIndex(exercise: string) {
    return exercise.replace(/\d/g, '');
  }

  public createDataSource() {
    return new MatTableDataSource(['1']);
  }
}
