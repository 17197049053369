import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderComponent } from './header.component';
import { AuthService } from '../../../services/auth/auth.service';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UpdatesModule } from '../updates/updates.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { LogoComponent } from './components/logo/logo.component';
import { DirectivesModule } from '../../directives/directives.module';
import { UnsupportedBrowserBannerComponent } from './components/unsupported-browser-banner/unsupported-browser-banner.component';
import { PublicHeaderComponent } from './components/public-header/public-header.component';
import { AuthHeaderComponent } from './components/auth-header/auth-header.component';
import { DesktopNavComponent } from './components/desktop-nav/desktop-nav.component';
import { WarningMessageBannerComponent } from './components/warning-message-banner/warning-message-banner.component';
import { LoginBtnComponent } from './components/login-btn/login-btn.component';
import { CogmanMenuComponent } from './components/cogman-menu/cogman-menu.component';
import { CreditsComponent } from './components/credits/credits.component';
import { WelcomeMessageComponent } from './components/welcome-message/welcome-message.component';
import { BottomBorderComponent } from './components/bottom-border/bottom-border.component';
import { MobileNavComponent } from './components/mobile-nav/mobile-nav.component';

@NgModule({
  declarations: [
    HeaderComponent,
    LogoComponent,
    HeaderComponent,
    WarningMessageBannerComponent,
    UnsupportedBrowserBannerComponent,
    PublicHeaderComponent,
    AuthHeaderComponent,
    DesktopNavComponent,
    LoginBtnComponent,
    CogmanMenuComponent,
    CreditsComponent,
    WelcomeMessageComponent,
    BottomBorderComponent,
    MobileNavComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FontAwesomeModule,
    UpdatesModule,
    MatTooltipModule,
    MatSnackBarModule,
    TranslateModule.forChild(),
    DirectivesModule,
  ],
  exports: [HeaderComponent, MobileNavComponent],
  providers: [AuthService],
})
export class HeaderModule {}
