<div
  class="icon"
  [matTooltip]="tooltip"
  [matTooltipPosition]="'above'"
  (click)="handleClick()"
>
  <fa-icon
    [ngClass]="{ disableIcon: disabled }"
    [icon]="iconToDisplay"
  ></fa-icon>
</div>
