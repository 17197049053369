import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SectionTypes } from '../utils/course-utils';
import { environment } from 'src/environments/environment';
import * as pdfjs from 'pdfjs-dist/build/pdf';
import { HttpClient } from '@angular/common/http';
import { IconDefinition, faSpinner } from '@fortawesome/free-solid-svg-icons';
import * as pdfWorker from 'pdfjs-dist/build/pdf.worker.js';
import { Section } from 'src/app/pages/configuration-pages/interfaces/global-config.interfaces';
import { CloudanaryResponse, CloudnaryConfig } from 'src/app/shared/interfaces/cloudnary.interface';

declare let cloudinary: any;

interface PresentationManagerData {
  section: Section;
  cloudinaryConfig: CloudnaryConfig;
}

@Component({
  selector: 'app-presentation-manager',
  templateUrl: './presentation-manager.html',
  styleUrls: ['./presentation-manager.scss'],
})
export class PresentationManager implements OnInit, AfterViewInit {
  @ViewChild('pdfCanvas', { static: true }) pdfCanvas: ElementRef;

  public courses = [];
  public loading = true;

  public sectionTypes = SectionTypes;
  public sectionTitle: string;
  public sectionType: string;

  public courseName: string;
  public courseTitle: string;
  public courseImage = 'assets/img/Image_Placeholder.jpg';
  public pdfData: string | ArrayBuffer; // To store the PDF data
  public uploadingPDF = false;

  public pdfTotalPages = 0;
  public currentUploadPage = 0;

  public readonly spinnerIcon: IconDefinition = faSpinner;

  @Output() reset = new EventEmitter();

  constructor(
    public rest: RestAPIService,
    public snack: MatSnackBar,
    public confirm: ConfirmationService,
    private dialogRef: MatDialogRef<PresentationManager>,
    @Inject(MAT_DIALOG_DATA) public editorData: PresentationManagerData,
    private http: HttpClient,
  ) {}

  async ngOnInit() {}

  ngAfterViewInit() {
    document.getElementById('file-upload-button').addEventListener('click', function () {
      document.getElementById('file-input').click();
    });
  }

  public addImage() {
    const cloudinaryConfig = this.editorData.cloudinaryConfig;

    cloudinary.openUploadWidget(cloudinaryConfig, (error, result) => {
      if (result) {
        result.forEach((imageResponse) => {
          const { secure_url, url } = imageResponse;
          this.editorData.section.content.slides.push(secure_url || url);

          this.dialogRef.close(true);
        });
      } else if (error && error.message !== 'User closed widget') {
        this.snack.open('There was an error while uploading the image, please try again later', 'OK', {
          duration: 5000,
        });

        this.dialogRef.close(false);
      }
    });
  }

  async onFileSelected(event: any) {
    pdfjs.GlobalWorkerOptions.workerSrc = pdfWorker;
    const pdfFile = event.target.files[0];

    // Use file reader to read the PDF file
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent) => {
      const target = e.target as FileReader;
      this.pdfData = target.result;
    };
    reader.readAsDataURL(pdfFile);

    // Load the PDF file using pdfjs-dist lib
    const pdf = await this.getPdfInfo(pdfFile);
    const pageNum = pdf.numPages;
    this.pdfTotalPages = pageNum;

    this.uploadingPDF = true;

    // Iterate through the pages to add all of them to our presentation
    for (let i = 1; i <= pageNum; i++) {
      // Get the PDF and render it to a canvas
      this.currentUploadPage = i;
      const page = await pdf.getPage(i);
      const canvas: HTMLCanvasElement = document.querySelectorAll('canvas')[0];
      const context = document.querySelectorAll('canvas')[0].getContext('2d');
      const viewport = page.getViewport({ scale: 1.5 });
      canvas.width = viewport.width;
      canvas.height = viewport.height;
      await page.render({ canvasContext: context, viewport }).promise;

      // Convert canvas to a data URL
      const dataURL = canvas.toDataURL('image/png');

      // Upload it to our cloudnary account
      await this.http
        .post('https://api.cloudinary.com/v1_1/' + environment.CLOUDINARY_CLOUD_NAME + '/image/upload', {
          file: dataURL,
          upload_preset: environment.CLOUDINARY_UPLOAD_PRESET,
        })
        .toPromise()
        .then(async (res: CloudanaryResponse) => {
          this.editorData.section.content.slides.push(res.secure_url || res.url);
        });
    }

    this.snack.open('Presentation updated!', 'Close', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });

    this.dialogRef.close(true);
  }

  public async getPdfInfo(file: File) {
    const pdf = await pdfjs.getDocument(URL.createObjectURL(file)).promise;

    return pdf;
  }
}
