<ng-container
  *ngIf="userIsBuyingForFirstTime; then firstPurchase; else regularPurchase"
></ng-container>

<ng-template #firstPurchase>
  <div class="margin centered">
    <pricing-heading
      text="Join the growing list of trusted Neuralign professionals"
    ></pricing-heading>
    <pricing-paragraph
      text="Our sign-on price includes a comprehensive online provider certification course"
    ></pricing-paragraph>
    <pricing-paragraph
      text="2 Neuralign credits to familiarize yourself with the program, and a Neuralign portal with a unique URL that can be branded with your centre's logo."
    ></pricing-paragraph>
  </div>

  <single-card-holder>
    <!-- <span class="badge">Special Welcome Offer!</span> -->
    <pricing-card>
      <card-heading> Provider Certification </card-heading>
      <card-price
        [currency]="firstPurchaseBundle?.currency?.toUpperCase()"
        [price]="firstPurchaseBundle?.price"
        [isSubscription]="firstPurchaseBundle?.subscription"
      ></card-price>
      <ul>
        <li>Provider certification course</li>
        <li>2 Neuralign credits</li>
        <li>Branded portal</li>
      </ul>
      <card-buy-btn [bundle]="firstPurchaseBundle"></card-buy-btn>
    </pricing-card>
  </single-card-holder>
</ng-template>

<ng-template #regularPurchase>
  <div class="margin centered">
    <pricing-heading
      text="Choose the plan that's right for you."
    ></pricing-heading>
    <pricing-paragraph
      text="The world's premium, cognitive, research based reading intervention, is now available as a subscription, making it more affordable than ever to offer it to your clients."
    ></pricing-paragraph>
  </div>

  <div class="tabs">
    <button
      (click)="handleTabSwitch(SubscriptionPeriod.yearly)"
      [ngClass]="{ bold: activeTab === SubscriptionPeriod.yearly }"
    >
      Billed Anually
    </button>
    |
    <button
      (click)="handleTabSwitch(SubscriptionPeriod.monthly)"
      [ngClass]="{ bold: activeTab === SubscriptionPeriod.monthly }"
    >
      Billed Monthly
    </button>
  </div>

  <br />

  <p>Recieve credits delivered to your account every month</p>

  <div *ngIf="!loading">
    <ng-container
      *ngIf="
        activeTab === SubscriptionPeriod.yearly;
        then yearlyTab;
        else monthlyTab
      "
    ></ng-container>

    <ng-template #yearlyTab>
      <br />

      <p>
        <span class="bold">Save 10%</span> when you pay annually, and recieve
        all your credits upfront.
      </p>

      <app-three-card-holder>
        <div
          left
          class="card"
        >
          <app-new-bundle-card
            [bundle]="subscriptionBundles[0]"
            [type]="SubscriptionPeriod.yearly"
          ></app-new-bundle-card>
        </div>

        <div
          middle
          class="card"
        >
          <app-new-bundle-card
            [bundle]="subscriptionBundles[1]"
            [type]="SubscriptionPeriod.yearly"
          ></app-new-bundle-card>
        </div>

        <div
          right
          class="card"
        >
          <app-new-bundle-card
            [bundle]="subscriptionBundles[2]"
            [type]="SubscriptionPeriod.yearly"
          ></app-new-bundle-card>
        </div>
      </app-three-card-holder>
    </ng-template>

    <ng-template #monthlyTab>
      <app-three-card-holder>
        <div
          left
          class="card"
        >
          <app-new-bundle-card
            [bundle]="subscriptionBundles[0]"
            [type]="SubscriptionPeriod.monthly"
          ></app-new-bundle-card>
        </div>

        <div
          middle
          class="card"
        >
          <!-- <span class="badge most-poular">Most Popular!</span> -->
          <app-new-bundle-card
            [bundle]="subscriptionBundles[1]"
            [type]="SubscriptionPeriod.monthly"
          ></app-new-bundle-card>
        </div>

        <div
          right
          class="card"
        >
          <!-- <span class="badge best-vaule">Best Value!</span> -->
          <app-new-bundle-card
            [bundle]="subscriptionBundles[2]"
            [type]="SubscriptionPeriod.monthly"
          ></app-new-bundle-card>
        </div>
      </app-three-card-holder>
    </ng-template>
  </div>

  <div
    class="no-subscription"
    *ngIf="singleBundle"
  >
    <p>Not ready to commit to a subscription?</p>
    <p>
      You can still buy a
      <span class="bold">single credit</span>
    </p>
    <div class="no-sub-price">
      <card-price
        [currency]="singleBundle?.currency"
        [price]="singleBundle?.price"
      >
      </card-price>
      <card-buy-btn [bundle]="singleBundle"></card-buy-btn>
    </div>
  </div>
</ng-template>

<br />
<br />

<div class="clarifications">
  <p>Each credit provides access to Neuralign for one student.</p>
  <p>All providers must complete the Neuralign provider certification.</p>
</div>

<br />
<br />
