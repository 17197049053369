import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { LoadingService } from 'src/app/services/utils/loading.service';
import { StripeRedirect } from 'src/app/shared/interfaces/Stripe.interfaces';

@Component({
  selector: 'users-organizations-menu',
  templateUrl: './organizations-menu.component.html',
  styleUrls: ['./organizations-menu.component.scss'],
})
export class OrganizationsMenuComponent implements OnInit {
  @Output() setLoading = new EventEmitter();
  constructor(
    private router: Router,
    private _rest: RestAPIService,
    private _snackBar: MatSnackBar,
    private loading: LoadingService,
  ) {}

  @Input() public userHasStripeAccount: boolean = true;
  @Input() public userHasStripeEnabled: boolean = true;

  ngOnInit(): void {}

  adjustDefaultPrice() {
    this.router.navigate(['admin-pricing']);
  }

  async generateStripeAccount() {
    this.loading.ActivateLoading();
    const res: StripeRedirect = await this._rest.post('account/linkStripe', {
      baseUrl: window.location.href,
    });

    const stripeURL = res.redirectUrl;
    window.open(stripeURL, '_self');
  }
}
