import { AfterViewInit, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { clamp } from 'src/app/shared/helpers/clamp.function';
import { styleElement } from 'src/app/shared/helpers/styleElement.function';

@Component({
  selector: 'home-quotes',
  templateUrl: './quotes.component.html',
  styleUrls: ['./quotes.component.scss'],
})
export class QuotesComponent implements AfterViewInit {
  @ViewChild('quotesBox') quotesBox: ElementRef;

  constructor(private _renderer: Renderer2) {}

  ngAfterViewInit() {
    this.setStyles();
  }

  setStyles(): void {
    styleElement(this._renderer, this.quotesBox, {
      position: 'absolute',
      color: 'white',
      display: 'flex',
      flexDirection: 'column',
      gap: clamp(1, 4),
      left: clamp(8, 80),
      height: '100%',
      justifyContent: 'center',
      paddingRight: clamp(0.5, 2),
    });
  }
}
