import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { RestAPIService } from '../../services/rest/rest-api.service';
import { UserSideMenuModule } from '../../shared/components/user-side-menu/user-side-menu.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProgramComponent, SafePipe } from './program.component';
import { MaterialComponentsModule } from 'src/app/shared/modules/material-components.module';
import { LayoutComponentsModule } from 'src/app/shared/components/layout/layout-components.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BeforeYouStartModule } from 'src/app/shared/components/before-you-start/before-you-start.module';
import { TranslateModule } from '@ngx-translate/core';
import { LayoutsModule } from 'src/app/shared/layouts/layouts.module';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { GameModule } from 'src/app/shared/components/game/game.module';
import { CommonModule } from '@angular/common';
import { StudentResultsDialog } from './student-results-dialog/student-results-dialog.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { LevelOverrideDialogComponent } from './level-override-dialog/level-override-dialog.component';
import { CategoryInfoDialog } from './category-info-dialog/category-info-dialog';
import { FiveDaysMenuComponent } from './five-days-menu/five-days-menu.component';
import { ProgramCompletionDialog } from './program-completion-dialog/program-completion-dialog';
import { VideoCategoryComponent } from './video-category/video-category.component';
import { DisplayModule } from '../courses/pages/learning-section/main-view/display/display.module';
import { SafeUrlModule } from 'src/app/shared/pipes/safe-url/safe-url.module';

@NgModule({
  declarations: [
    ProgramComponent,
    SafePipe,
    StudentResultsDialog,
    LevelOverrideDialogComponent,
    CategoryInfoDialog,
    FiveDaysMenuComponent,
    ProgramCompletionDialog,
    VideoCategoryComponent,
  ],
  imports: [
    SafeUrlModule,
    DisplayModule,
    BrowserModule,
    RouterModule,
    HttpClientModule,
    BeforeYouStartModule,
    UserSideMenuModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialComponentsModule,
    LayoutComponentsModule,
    FontAwesomeModule,
    TranslateModule.forChild(),
    LayoutsModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    GameModule,
    CommonModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
  ],
  providers: [RestAPIService],
})
export class ProgramModule {}
