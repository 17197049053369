import { Component, Input } from '@angular/core';

@Component({
  selector: 'users-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss'],
})
export class TableHeaderComponent {
  @Input() heading = '';
}
