import { Pipe, PipeTransform } from '@angular/core';
import { get } from 'lodash';

@Pipe({
  name: 'searchFilter',
})
export class SearchFilterPipe implements PipeTransform {
  transform(values: any, property: any, input?: any, fallbackProperty?: any): any {
    if (!values) {
      return null;
    }
    if (!property || !input) {
      return values;
    }

    input = input.toLowerCase();

    return values.filter((data) => {
      let result = false;
      result = get(data, property, '').toLowerCase().includes(input);

      if (!result) {
        result = get(data, fallbackProperty, '').toLowerCase().includes(input);
      }

      return result;
    });
  }
}
