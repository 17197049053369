import { Component, Input, OnInit } from '@angular/core';
import { IconDefinition, faShoppingCart, faCheck, faUserCheck } from '@fortawesome/free-solid-svg-icons';
import {
  yearlySubscriptionBundles,
  singlePurchasePrice,
} from '../../../pricing/default-readls-bundles/default-readls-bundles.constants';
import { PricingBundle } from 'src/app/pages/pricing/interfaces/pricing-bundle.interface';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';
import { SelectBundleService } from 'src/app/pages/programs-pricing/services/select-bundle.service';

@Component({
  selector: 'app-bundle-card-reseller',
  templateUrl: './bundle-card-reseller.component.html',
  styleUrls: ['./bundle-card-reseller.component.scss'],
})
export class BundleCardResellerComponent implements OnInit {
  @Input() bundle: PricingBundle;
  @Input() message: string;
  @Input() isB2C = false;
  @Input() isOutsider = false;

  public checkIcon: IconDefinition = faCheck;
  public cartIcon: IconDefinition = faShoppingCart;
  public verified: IconDefinition = faUserCheck;
  public amount: number;
  public subscriptions: any;
  public subscriptionInterval: string;
  public singlePurchasePrice: number;
  public yearlySubscriptionPlan: PricingBundle;

  constructor(private _router: Router, public dialog: MatDialog, public bundleService: SelectBundleService) {}

  ngOnInit() {
    this.findSinglePurchasePrice();
    this.findYearPlan();
  }

  public findSinglePurchasePrice() {
    // in order to be able to show the price difference between the monthly subscriptions and the single purchase bundle
    // we need to save the single purchase bundle price in a variable

    this.singlePurchasePrice = singlePurchasePrice;
  }

  public findYearPlan() {
    if (this.bundle.subscription && !this.bundle.isRegularBundle) {
      const yearlyPlan = yearlySubscriptionBundles.find((b) => b.id === this.bundle.id);
      this.yearlySubscriptionPlan = yearlyPlan;
    } else {
      const newBundle = cloneDeep(this.bundle);
      newBundle.price = newBundle.yearlyPrice;
      newBundle.studentsIncluded = newBundle.studentsIncluded * 12;
      newBundle.interval = 'year';
      this.yearlySubscriptionPlan = newBundle;
    }
  }

  public addToCart() {
    if (this.subscriptionInterval) {
      this.bundle.interval = this.subscriptionInterval;
    }

    let bundle = cloneDeep(this.bundle);

    if (bundle.interval === 'year') {
      // if the client choose the year plan we have to update the credits amount and the price
      bundle = this.yearlySubscriptionPlan || yearlySubscriptionBundles.find((s) => s.id === bundle.id);
    }

    this.bundleService.selectedBundle = bundle;
    this._router.navigate(['purchase-tokens/' + this.bundle.id], { queryParams: { ...bundle } });
  }

  public goto(path) {
    window.open(path, '_blank');
  }

  public getSavePercentage(price, reference, credits) {
    // we need to return the % of the discount that the client will have in subscriptions purchases comparing with the single purchase
    const result = 100 - (price / (reference * credits)) * 100;

    if (Number.isInteger(result)) {
      return result;
    } else {
      return result.toFixed(2);
    }
  }
}
