import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MobileMenuService {
  public isMobileMenuOpen$ = new BehaviorSubject(false);
  public innerWidth$ = new BehaviorSubject(window.innerWidth);
  public mobileMenuBreakpoint = 1080;

  constructor() {
    this.setupInnerWidthObservable();
  }

  private setupInnerWidthObservable() {
    fromEvent(window, 'resize')
      .pipe(tap((event: any) => this.innerWidth$.next(event.target.innerWidth)))
      .subscribe();
  }

  public toggleMobileMenu() {
    this.isMobileMenuOpen$.next(!this.isMobileMenuOpen$.value);

    if (document.body.classList.contains('no-scroll')) {
      document.body.classList.remove('no-scroll');
    } else {
      document.body.classList.add('no-scroll');
    }
  }
}
