<lsw-curtain></lsw-curtain>

<app-main-layout
  [breadcrumbs]="[
    { name: 'Home', url: '/' },
    { name: 'Courses', url: '/courses' }
  ]"
>
  <div
    class="loadingContainer"
    *ngIf="loading"
  >
    <fa-icon
      [icon]="spinner"
      [spin]="true"
    ></fa-icon>
  </div>

  <div *ngIf="!loading">
    <ng-container *ngIf="courses$ | async as courses">
      <div class="wrapper">
        <top-heading
          label="Courses"
          [hideBackButton]="true"
        ></top-heading>

        <courses-list-grid>
          <courses-card
            *ngFor="let course of courses"
            [course]="course"
          >
          </courses-card>
        </courses-list-grid>
      </div>
    </ng-container>
  </div>
</app-main-layout>
