<lsw-curtain></lsw-curtain>

<app-public-layout>
  <div class="container">
    <h1>Frequently Asked Questions</h1>

    <p class="subtitle">
      Didn't find what you were looking for? Please
      <a routerLink="/contact">contact us!</a>
    </p>

    <mat-form-field>
      <mat-label>Try searching!</mat-label>
      <input
        type="text"
        matInput
        required
        (keyup)="updateSearch($event.target.value)"
      />
    </mat-form-field>

    <mat-accordion>
      <mat-expansion-panel *ngFor="let faq of FAQs">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ faq.question }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div [innerHTML]="faq.answer"></div>
      </mat-expansion-panel>
    </mat-accordion>

    <div
      class="empty-state"
      *ngIf="FAQs?.length === 0"
    >
      <fa-icon [icon]="searchIcon"></fa-icon>
      <p>Oops! No results for the search.</p>
    </div>
  </div>
</app-public-layout>
