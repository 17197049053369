<div
  id="patients-add-files"
  class="container-fluid"
>
  <div
    class="close-btn"
    *ngIf="closeButtonRoute !== ''"
  >
    <fa-icon
      (click)="closeRoute()"
      [icon]="closeIcon"
    >
    </fa-icon>
  </div>

  <div class="row mx-0 bg-sec mb-3">
    <div class="col-12 mx-0 my-0 px-5 py-4 modalHeader">
      <h2>{{ titleInput }}</h2>
    </div>
  </div>
  <div class="col-12 mx-0 px-0 row">
    <div class="row mx-0 px-3 col-12 mb-3 confirmationContent">
      <p [innerHTML]="textInput"></p>
    </div>
    <div class="col-12 mx-0 px-3 d-flex justify-content-center mb-3 button-row">
      <button
        class="mx-1"
        (click)="confirm()"
        *ngIf="confirmButton"
      >
        {{ confirmButton }}
      </button>
      <button
        class="warn mx-1"
        (click)="cancel()"
        *ngIf="cancelButton"
      >
        {{ cancelButton }}
      </button>
    </div>
  </div>
</div>
