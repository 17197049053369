<ng-container *ngIf="showWarning">
  <section
    class="warning-box"
    roseBg
    white
    centerText
  >
    <div><!-- used for spacing --></div>

    <div class="message">
      <span>
        <fa-icon
          [icon]="warningIcon"
          class="large"
        ></fa-icon>
        &nbsp;{{ warningMessage?.text }}</span
      >
    </div>

    <button (click)="closeWarningMessage()">Close</button>
  </section>
</ng-container>
