import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-system-requirements-dialog',
  templateUrl: './system-requirements.dialog.html',
  styleUrls: ['./system-requirements.dialog.scss'],
})
export class SystemRequirementsDialogComponent {
  constructor(public dialogRef: MatDialogRef<SystemRequirementsDialogComponent>) {}

  closeDialog() {
    this.dialogRef.close();
  }
}
