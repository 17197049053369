<!-- Banners -->

<header-warning-message-banner></header-warning-message-banner>

<!-- Header -->

<ng-container
  *ngIf="isUserLoggedIn; then authHeader; else publicHeader"
></ng-container>

<ng-template #authHeader>
  <header-auth></header-auth>
</ng-template>

<ng-template #publicHeader>
  <header-public></header-public>
</ng-template>
