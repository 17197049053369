import { Component, Input } from '@angular/core';

@Component({
  selector: 'draft-badge',
  templateUrl: './draft-badge.component.html',
  styleUrls: ['./draft-badge.component.scss'],
})
export class DraftBadgeComponent {
  @Input() isDraft: boolean;
}
