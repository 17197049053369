import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { RoleService } from 'src/app/services/roles/role.service';

@Directive({
  selector: '[userCanSelectEnabledThemes]',
})
export class CanSelectEnabledThemesDirective implements OnInit {
  constructor(private _el: TemplateRef<unknown>, private _host: ViewContainerRef, private _roles: RoleService) {}

  ngOnInit(): void {
    if (this._isCapable()) {
      this._host.createEmbeddedView(this._el); // show
    } else {
      this._host.clear(); // hide
    }
  }

  private _isCapable() {
    return this._roles.isPortalOwner() || this._roles.isOrgOwner();
  }
}
