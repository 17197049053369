import { Component, OnInit, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';

@Component({
  selector: 'app-warning-message',
  templateUrl: './warning-message.component.html',
  styleUrls: ['./warning-message.component.scss'],
})
export class WarningMessageComponent implements OnInit {
  @Input() globalConfigurations: any;

  constructor(private _rest: RestAPIService, protected _snackBar: MatSnackBar) {}

  ngOnInit(): void {}

  public toggleWarningMessage(statement) {
    this.globalConfigurations.warningMessage.enabled = statement;
  }

  public async saveMessage() {
    try {
      this._rest
        .put('globalConfig/update', {
          id: this.globalConfigurations.id,
          warningMessage: this.globalConfigurations.warningMessage,
        })
        .then(
          () => {
            this._snackBar.open(`Warning message updated!`, 'Close', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
          },
          (err) => {
            this._snackBar.open(`${err.error.message}`, 'Close', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
          },
        );
    } catch (error) {
      this._snackBar.open(`${error.message}!`, 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }
  }
}
