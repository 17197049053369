import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { UserSideMenuComponent } from './user-side-menu.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [UserSideMenuComponent],
  imports: [BrowserModule, FontAwesomeModule],
  exports: [UserSideMenuComponent],
})
export class UserSideMenuModule {}
