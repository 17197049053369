<div
  class="bundle-card"
  [ngClass]="{ firstPurchase: bundle.isFirstPurchaseBundle }"
>
  <form
    [formGroup]="bundleFormGroup"
    [ngStyle]="{ width: '100%' }"
    class="text-center"
  >
    <div class="title">
      {{
        bundle.isFirstPurchaseBundle
          ? 'First purchase bundle'
          : 'Bundle ' + (index + 1)
      }}
    </div>

    <mat-form-field appearance="outline">
      <mat-label>Title</mat-label>
      <input
        matInput
        required
        placeholder="Title"
        formControlName="title"
      />
    </mat-form-field>

    <div class="d-flex justify-content-between mt-2 mb-3">
      <mat-checkbox
        (change)="disableControls($event)"
        class="ml-1 checkbox"
        formControlName="hiddenPrice"
        >Hide Info</mat-checkbox
      >
      <mat-checkbox
        class="mr-1 checkbox"
        formControlName="subscription"
        >Subscription</mat-checkbox
      >
    </div>

    <mat-form-field appearance="outline">
      <mat-label>Full price</mat-label>
      <input
        (input)="handleFullPriceChange()"
        matInput
        type="number"
        required
        placeholder="Full price"
        formControlName="fullPrice"
      />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Discount %</mat-label>
      <input
        (input)="handleDiscountChange($event)"
        min="0"
        max="100"
        matInput
        type="number"
        required
        placeholder="Discount"
        formControlName="discount"
      />

      <mat-error
        *ngIf="bundleFormGroup.get('discount').hasError('discountInvalid')"
      >
        Discount must be between 0 and 100
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Final price</mat-label>
      <input
        matInput
        type="number"
        required
        placeholder="Price"
        formControlName="price"
      />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Currency</mat-label>
      <mat-select formControlName="currency">
        <mat-option value="CAD">CAD</mat-option>
        <mat-option value="USD">USD</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Student Per Bundle</mat-label>
      <input
        matInput
        type="number"
        min="1"
        required
        placeholder="Students"
        formControlName="studentsIncluded"
      />
    </mat-form-field>
  </form>
  <div class="save d-flex justify-content-center">
    <!-- @TODO ADD FORM VERIFICATION  -->
    <app-simple-button
      class="mx-2"
      text="SAVE"
      (click)="save()"
      [disabled]="allowBundleSave()"
    ></app-simple-button>

    <app-simple-button
      [disabled]="false"
      *ngIf="!bundle.isDefault"
      class="mx-2"
      text="RESET"
      (click)="delete()"
    >
    </app-simple-button>

    <p *ngIf="false">
      Please check the form, there are invalid fields or required fields
      missing.
    </p>
  </div>
</div>
