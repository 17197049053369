import { Injectable, isDevMode } from '@angular/core';
import { environment } from 'src/environments/environment';
import { isEmpty } from 'lodash';

declare const gtag;
declare const lintrk;

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  public markPurchase() {
    // guard against dev not having references to analytics
    if (isEmpty(gtag) || isEmpty(lintrk)) {
      return;
    }

    // only fire event when in prod
    if (!isDevMode()) {
      // google
      gtag('event', 'purchase', {
        send_to: `${environment.GOOGLE.ADWORDS_CONVERSION_ID}/${environment.GOOGLE.ADWORDS_CONVERSION_LABEL}`,
      });

      // linkedin
      lintrk('track', {
        conversion_id: parseInt(environment.LINKEDIN.CONVERSION_ID),
      });
    }
  }
}
