import { Component, OnInit } from '@angular/core';
import { Student } from 'src/app/pages/students/interfaces/student.interface';
import { B2cStudentsListService } from './b2c-students-list.service';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';
import { isEmpty } from 'lodash';
import { applyFilter } from 'src/app/shared/helpers/applyFilter';

@Component({
  selector: 'users-b2c-students-list',
  templateUrl: './b2c-students-list.component.html',
  styleUrls: ['./b2c-students-list.component.scss'],
})
export class B2cStudentsListComponent implements OnInit {
  template: 'loaded' | 'unselected' | 'loading' | 'noStudents' = 'unselected';
  students: Student[];
  currentB2CClient: string;

  dataSource: any;

  constructor(
    private _b2cStudentsListService: B2cStudentsListService,
    private _router: Router,
    private _confirm: ConfirmationService,
  ) {}

  ngOnInit(): void {}

  async getStudents(id: string) {
    // Check if current client is not already selected
    if (this.currentB2CClient !== id) {
      // Set template
      this.template = 'loading';

      // Save current client id
      this.currentB2CClient = id;

      // Get students
      const students = await this._b2cStudentsListService.loadStudents(id, { refresh: true });

      // Check if client has students
      students.length === 0 ? (this.template = 'noStudents') : (this.template = 'loaded');

      // Update datasource
      this.dataSource = new MatTableDataSource(students);
    }
  }

  public applyFilter(event: KeyboardEvent): void {
    this.dataSource = applyFilter(event, this.dataSource);
  }

  public accessNeuralign(student: Student) {
    this._router.navigate(['/program/' + student.id + '/Neuralign']);
  }

  public deleteB2CStudent(student: Student) {
    const studentHasAssignedCredit = student.tokens?.length > 0;
    const studentHasProgress = student.progress.length > 0;

    if (studentHasAssignedCredit && !studentHasProgress) {
      this._confirm.createConfirmation(
        'Warning',
        'The assigned credit should be retrieved by clicking on the - sign before archiving the student.',
        'Close',
        undefined,
      );
    } else {
      this._confirm
        .createConfirmation(
          'Warning',
          `Are you sure you want to delete ${!isEmpty(student.fullname) ? student.fullname : 'this student'}?`,
          'Yes',
          'No',
          '350px',
        )
        .then(async () => {
          this.template = 'loading';
          await this._b2cStudentsListService.archiveStudent(student);

          const students = await this._b2cStudentsListService.loadStudents(this.currentB2CClient, { refresh: true });

          students.length === 0 ? (this.template = 'noStudents') : (this.template = 'loaded');

          this.dataSource = new MatTableDataSource(students);

          students.length === 0 ? (this.template = 'noStudents') : (this.template = 'loaded');
        });
    }
  }

  public canAccessNeuralign(student: Student): boolean {
    // a student should only be able to access Neuralign
    // if they have a credit spent on their enrollment
    return student.tokens?.length > 0;
  }
}
