<div class="graphContainer h-100 d-flex p-2 mt-5">
  <canvas
    class="d-flex"
    *ngFor="let chart of charts; let i = index"
    [ngClass]="{ pieChart: chart.type != 'bar' }"
    width="890"
    height="700"
    [id]="'chart' + i"
  >
  </canvas>
</div>
