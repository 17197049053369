import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessagesTableButtonComponent } from './messages-table-button.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [MessagesTableButtonComponent],
  imports: [CommonModule, FontAwesomeModule, MatTooltipModule],
  exports: [MessagesTableButtonComponent],
})
export class MessagesTableButtonModule {}
