import { MetadataDialogComponent } from './../../../shared/dialogs/metadata/metadata.dialog';
import { SubscriptionInfoComponent } from './subscription-info-dialog/subscription-info-dialog.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, throwError } from 'rxjs';
import { map, startWith, filter } from 'rxjs/operators';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faPlus,
  faMinus,
  faSpinner,
  faReceipt,
  faHandHoldingUsd,
  faExchangeAlt,
  faTimes,
  faSearch,
  faTrashAlt,
  faInfoCircle,
  faEye,
  faUserEdit,
  faExclamationTriangle,
  faRedo,
  faPenToSquare,
  faGamepad,
  faArrowUpFromBracket,
} from '@fortawesome/free-solid-svg-icons';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';
import { MatDialog } from '@angular/material/dialog';
import { AddTokenComponent } from '../add-token/add-token.component';
import { orderBy, indexOf, get, has, groupBy, isEmpty, isObject, sortBy, cloneDeep } from 'lodash';
import { HeaderComponent } from 'src/app/shared/components/header/header.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationClass } from 'src/app/shared/classes/notification.class';
import { PublicProfilePreviewComponent } from 'src/app/shared/dialogs/public-profile-preview/public-profile-preview.component';
import { PublicProfileEditDialogComponent } from './public-profile-edit-dialog/public-profile-edit-dialog.component';
import { Account, User } from 'src/app/shared/models/user.model';
import { Token } from 'src/app/shared/models/token.model';
import { PricingBundle } from '../../pricing/interfaces/pricing-bundle.interface';
import { HttpParams } from '@angular/common/http';
import { Student } from '../../students/interfaces/student.interface';
import { StudentProgramInfoHelperService } from '../../program/program-helpers/student-program-info-helper.service';
import { ASSESSMENT } from '../../program/consts/program-game-consts';
import { DeleteTokenComponent } from '../delete-token/delete-token.component';
import { GlobalConfigurationHelper } from 'src/app/services/utils/global-configuration-helper';
import { ProgressEditDialog } from './progress-edit-dialog/progress-edit-dialog';
import { Router } from '@angular/router';
import { Category } from '../../configuration-pages/interfaces/global-config.interfaces';
import { B2cPaymentTypesDialog } from './b2c-payment-types/b2c-payment-types.component';
import { B2cPaymentTypes } from '../../users/components/adjust-b2c-prices/constants/b2c-prices.constants';
import { ProgressCompletionDialog } from './progress-completion-dialog/progress-completion-dialog';
import { UpdateTokenDialog } from './update-token-dialog/update-token-dialog';
import { OutsiderImportDialog } from './outsider-import-dialog/outsider-import-dialog';
import { Organization, Progress } from 'src/app/core/openapi';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss'],
})
export class AdminDashboardComponent extends NotificationClass implements OnInit {
  public defaultResellerBundles: PricingBundle[] = [
    {
      title: 'Single',
      price: 750,
      studentsIncluded: 1,
      currency: 'CAD',
      order: 1,
    },
    {
      title: 'Basic subscription',
      price: 650,
      yearlyPrice: 6240,
      studentsIncluded: 1,
      subscription: true,
      interval: 'month',
      currency: 'CAD',
      order: 2,
    },
    {
      title: 'Pro subscription',
      price: 1800,
      yearlyPrice: 17280,
      studentsIncluded: 3,
      subscription: true,
      interval: 'month',
      currency: 'CAD',
      order: 3,
    },
    {
      title: 'Premium subscription',
      price: 2775,
      yearlyPrice: 26640,
      studentsIncluded: 5,
      subscription: true,
      interval: 'month',
      currency: 'CAD',
      order: 4,
    },
  ];

  @ViewChild(HeaderComponent) headerComponent: HeaderComponent;

  public readonly plusLine: IconDefinition = faPlus;
  public readonly minusLine: IconDefinition = faMinus;
  public readonly spinnerIcon: IconDefinition = faSpinner;
  public readonly receipt: IconDefinition = faReceipt;
  public readonly refundIcon: IconDefinition = faHandHoldingUsd;
  public readonly changeIcon: IconDefinition = faExchangeAlt;
  public readonly closeIcon: IconDefinition = faTimes;
  public readonly searchIcon: IconDefinition = faSearch;
  public readonly trashIcon: IconDefinition = faTrashAlt;
  public readonly infoIcon: IconDefinition = faInfoCircle;
  public readonly eyeIcon: IconDefinition = faEye;
  public readonly userEdit: IconDefinition = faUserEdit;
  public readonly warning: IconDefinition = faExclamationTriangle;
  public readonly reSend: IconDefinition = faRedo;
  public readonly edit: IconDefinition = faPenToSquare;
  public readonly game: IconDefinition = faGamepad;
  public readonly upgrade: IconDefinition = faArrowUpFromBracket;

  public orgPriceInput = false;
  public clients;
  public organizations;
  public students;
  public orgClients;
  public orgStudents;
  public orgOutsiders;
  public selectedUserType = 'Clients';
  public currentTokens;
  public currentCredits;
  public currentStudents;
  public selectedUser;
  public selectedUserReseller;
  public selectedStudent;
  private programs;
  public loading = false;
  public filterByName;
  public chargesList;
  public invoiceList;
  public filteredItens;
  public usersList;
  public reqUsers;
  public loadingCharges;
  public orderByList = ['createdAt', 'name', 'lastLogin'];
  public orderByDirection = ['asc', 'desc'];
  public selectedOrderBy = 'createdAt';
  public selectedOrderByDirection: 'asc' | 'desc' = 'desc'; // fix for typescript 3
  public organizationAutocomplete = new UntypedFormControl();
  public organizationAutocompleteFilteredOptions: Observable<string[]>;
  public organizationToAdd: User;
  public maxTokenDebit: number;
  public loadingDebits = true;
  public regularBundles: unknown;
  public customRegularBundles: Array<unknown>;
  public disableStripeWarning: boolean;
  public isPublicB2C: boolean;
  public outsiderLoading = true;
  public loadingStudents = false;
  public categoryList: Category[] = [];

  constructor(
    private _rest: RestAPIService,
    private router: Router,
    private confirm: ConfirmationService,
    private dialog: MatDialog,
    protected _snackBar: MatSnackBar,
    private studentHelper: StudentProgramInfoHelperService,
    private globalConfiguration: GlobalConfigurationHelper,
  ) {
    super(_snackBar);
  }

  async ngOnInit(): Promise<void> {
    this.prepareTable();
    this.organizationAutocompleteFilteredOptions = this.organizationAutocomplete.valueChanges.pipe(
      startWith({}),
      map((value) => this._organizationFilter(value)),
      filter((x) => !!x),
    );

    await this.getCategoryList();
  }

  private async getCategoryList() {
    const categoryList = await this.globalConfiguration.getCategories();

    this.categoryList = categoryList;
  }

  private async prepareTable(autoSelectUser?) {
    this.loading = true;
    let req = (this.reqUsers = await this._rest.get('admin/users', { msg: 'Could not get admin/users.' }));
    const studentsReq = await this._rest.get('admin/students', { msg: 'Could not get admin/students.' });
    this.students = studentsReq.studentList;
    req = await this._rest.get('programs', { msg: 'Could not get programs.' });
    this.updateOrder();
    this.programs = req.programs;
    this.loading = false;
    this.usersList = this.getCurrentUsers();
    if (autoSelectUser) {
      this.selectUser(this.selectedUser);
    }
  }

  public updateFilteredUsers(): void {
    this.usersList = this.getCurrentUsers();
  }

  public updateOrder(): void {
    this.clients = orderBy(
      this.reqUsers.users.filter((f) => !!f.patron),
      (c) => get(c, this.selectedOrderBy === 'name' ? 'patron.givenName' : this.selectedOrderBy, '').toLowerCase(),
      this.selectedOrderByDirection,
    );

    this.organizations = orderBy(
      this.reqUsers.users.filter((f) => !!f.organization || (!!f.organization && f.role === 'owner')),
      (o) => get(o, this.selectedOrderBy === 'name' ? 'organization.name' : this.selectedOrderBy, '').toLowerCase(),
      this.selectedOrderByDirection,
    );

    this.students = orderBy(this.students, (o) => get(o, 'fullname', '').toLowerCase(), this.selectedOrderByDirection);

    this.usersList = this.getCurrentUsers();
  }

  public onChangePage(pageOfItems: unknown[]): void {
    // update current page of items
    this.filteredItens = pageOfItems;
  }

  public getCurrentUsers(): unknown {
    let ret = [];
    if (this.selectedUserType === 'Clients') {
      ret = [].concat(this.clients.filter((c) => has(c, 'role') === false || get(c, 'role') === 'Client'));
    } else if (this.selectedUserType === 'Students') {
      ret = [].concat(this.students);
    } else {
      const roleGroup = groupBy(
        this.clients.filter((r) => has(r, 'role')),
        'orgId',
      );
      ret = [].concat(this.organizations);
      ret.forEach((org) => {
        org.users = roleGroup[org.organization.id];
      });
    }
    const f = this.filterByName ? this.filterByName.toLowerCase() : null;

    if (!f) {
      return ret;
    }
    return ret.filter((r) => {
      const email: string = r.patron
        ? get(r, 'patron.email', '').toLowerCase()
        : get(r, 'organization.email', '').toLowerCase();
      let name: string;

      if (this.selectedUserType === 'Students') {
        name = get(r, 'givenName', '').toLowerCase() + ' ' + get(r, 'familyName', '').toLowerCase();
        return name.indexOf(f) >= 0 || r.id.indexOf(f) === 0;
      }
      name = r.patron
        ? get(r, 'patron.givenName', '').toLowerCase() + ' ' + get(r, 'patron.familyName', '').toLowerCase()
        : get(r, 'organization.name', '').toLowerCase();

      return this.returnFilteredName(name, r, f) || this.returnFilteredEmails(email, r, f);
    });
  }

  public returnFilteredName(name: string, user: User, filter: string | null): boolean {
    return name.indexOf(filter) >= 0 || user.id.indexOf(filter) === 0;
  }

  public returnFilteredEmails(email: string, user: User, filter: string | null): boolean {
    return email.indexOf(filter) >= 0 || user.id.indexOf(filter) === 0;
  }

  public async deleteUser(id: string, tokenId: string): Promise<void> {
    const user = this.filteredItens.find((f) => f.id === id);

    if (!user.organization || user.organization.isOutsider === false) {
      this.loading = true;
      this.confirm
        .createConfirmation(
          'Warning',
          'This action cannot be undone, do you really want to delete this user?',
          'Yes',
          'No',
        )
        .then(
          async () => {
            if (user.patron) {
              const { id: patronId } = user.patron;
              await this._rest.put('patron/archive/' + patronId, {}, { msg: 'Could not put patron/archive.' });
            } else if (user.organization) {
              await this._rest.delete(`/organization/${user.organization.id}`);
            }

            await this._rest.delete(`user/${id}/${tokenId}`, { msg: 'Could not delete user.' });
            delete this.clients;
            this.prepareTable();

            this._snackBar.open('User deleted', 'Close', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
          },
          () => (this.loading = false),
        );
    } else {
      const warningMessage =
        'Are you sure you want to delete this organization? All students associated with this client will be deleted!';
      this.confirm.createConfirmation('Warning', warningMessage, 'Yes', 'No').then(async () => {
        try {
          // Once the delete on Auth0 is fixed it add the /:tokenId to the path
          await this._rest.delete(`organization/outsider/${id}/${tokenId}`, {
            msg: 'Could not delete organization.',
          });
          delete this.clients;
          this.prepareTable();
          // eslint-disable-next-line no-empty
        } catch {}
      });
    }
  }

  public selectBelongsTo(id: string, type = 'Organizations'): void {
    delete this.usersList;

    let acc;
    if (type === 'Organizations') {
      acc = this.organizations.find((o) => o.organization.id === id);
      this.filterByName = acc.organization.name;
    } else if (type === 'Students') {
      acc = this.students.find((o) => o.id === id);
      this.filterByName = acc.givenName + ' ' + acc.familyName;
    } else {
      acc = this.clients.find((o) => o.patron.id === id);
      this.filterByName = acc.patron.givenName + ' ' + acc.patron.familyName;
    }
    this.deselectUser();
    this.selectedUserType = type;
    this.updateFilteredUsers();
    this.selectUser(acc.id);
  }

  private _organizationFilter(value: string): string[] {
    let currentValue = value;

    if (isObject(value)) {
      currentValue = get(value, 'organization.name', '');
    }
    const filterValue = currentValue.toLowerCase();

    const filteredOrgs = this.organizations.filter((option) => {
      const name = get(option, 'organization.name', '');
      const id = get(option, 'id', '');
      return isEmpty(name) === false && name.toLowerCase().indexOf(filterValue) === 0 && this.selectedUser !== id;
    });

    return sortBy(filteredOrgs, ['organization.name']);
  }

  public async selectUser(id: string): Promise<void> {
    this.currentTokens = undefined;
    this.orgStudents = undefined;
    this.currentStudents = undefined;
    this.chargesList = undefined;
    this.organizationToAdd = undefined;
    this.orgOutsiders = [];
    this.selectedUser = id;
    this.loadingCharges = true;
    this.loadingDebits = true;
    this.maxTokenDebit = undefined;
    this.regularBundles = undefined;

    const user =
      this.clients.find((c) => c.id === this.selectedUser) ||
      this.organizations.find((o) => o.id === this.selectedUser) ||
      this.students.find((o) => o.id === this.selectedUser);
    if (user.users) {
      user.users = user.users.filter((item) => (item.patron !== null ? !item.patron.archived : true));
    }

    if (this.selectedUserType !== 'Students') {
      await this._rest
        .get('token/' + this.selectedUser, { msg: 'Could not get token/:selectedUser.' })
        .then((res) => {
          const { tokens } = res;
          this.currentTokens = tokens;
          this.currentCredits = tokens.filter((token: Token) => !token.studentId && token.paymentConfirmed).length;
          this.currentCredits -= tokens.filter((token: Token) => token.studentId && !token.paymentConfirmed).length;
        })
        .catch((error) => {
          throwError(() => new Error(error.message));
        });

      try {
        await this._rest
          .get('admin/charges/' + this.selectedUser, {
            msg: 'Could not get the user charges',
          })
          .then((res) => {
            const { charges, invoices } = res;
            this.chargesList = charges.length > 0 ? charges : [];
            this.invoiceList = invoices > 0 ? invoices : [];
            this.loadingCharges = false;
          });
      } catch (err) {
        if (err.status === 404) {
          this._snackBar.open(`User charges not found`, 'Close', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        } else {
          this._snackBar.open(`Could not find the user charges`, 'Close', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }
      }
      if (user.patron?.id) {
        this.loadingStudents = true;
        await this._rest
          .get('patron/' + user.patron.id + '/students', { msg: 'Could not get patron/:patronId/students.' })
          .then((res) => (this.currentStudents = res.students));
        this.loadingStudents = false;
      }

      if (user.organization?.isOutsider) {
        this.selectedUserReseller = this.organizations.find((o) => o.id === this.selectedUser);
      }

      if (user.organization?.isReseller) {
        if (user.organization.outsiders) {
          this.outsiderLoading = true;
          this.orgOutsiders = this.organizations.filter((o) => user.organization.outsiders.includes(o.id));

          this.orgOutsiders = await Promise.all(
            this.orgOutsiders.map(async (outsider) => {
              const { stripeSetup } = await this._rest.get('organization/stripe/checkOutsiderStripeSetup', {
                params: new HttpParams().set('outsider', outsider.id),
                msg: 'Could not get outsider Stripe setup',
              });

              return {
                ...outsider,
                stripeSetup,
              };
            }),
          );
          this.outsiderLoading = false;
        }
      }

      this.orgClients = this.clients.filter((c) => (c.belongsTo ? c.belongsTo?.id === user.organization?.id : false));
      this.orgStudents = this.students.filter((c) => this.orgClients.find((b) => b.patron?.id === c.patronId));
      if (this.selectedUserType === 'Organizations') {
        this.maxTokenDebit = user.organization?.maximumTokenDebit || 0;
        this.disableStripeWarning = user.organization?.disableStripeWarning || false;
        this.loadingDebits = false;
        this.isPublicB2C = user.organization?.isPublicB2C || false;
        await this.findOrgRegularBundles(user);
      }
    }
  }

  public deselectUser(): void {
    delete this.selectedUser;
  }

  public async fixStripeSetup(outsider: User): Promise<void> {
    this.confirm
      .createConfirmation('Warning', 'Would you like to fix the stripe setup for this outsider?', 'Yes', 'No')
      .then(async () => {
        try {
          await this._rest.put(
            'organization/stripe/fixStripeSetup',
            {
              outsider: outsider.id,
            },
            { msg: 'Could not put organization.' },
          );

          this._snackBar.open('The stripe setup is fixed!', 'Close', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });

          await this.selectUser(this.selectedUser);
        } catch (error) {
          this._snackBar.open('Could not fix this stripe setup!', 'Close', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }
      });
  }

  public async findOrgRegularBundles(user: User): Promise<unknown[]> {
    if (!user.organization) {
      return;
    }

    const defaultBaseBundles: PricingBundle[] = await this._rest.get('globalConfig/bundles/' + user.tokenId);

    this.regularBundles = defaultBaseBundles;
  }

  public async setRegularBundleToDefault(id: string) {
    const user: User = this.organizations.find((o) => o.id === this.selectedUser);

    await this._rest.delete(`bundle/bundleId/${id}/orgId/${user.organization.id}`, {
      msg: 'Could not delete bundle',
    });
    await this.findOrgRegularBundles(user);
  }

  public async changeResellerStatus(id: string, isReseller: boolean): Promise<void> {
    const user = this.organizations.find((o) => o.id === id);
    const index = indexOf(this.organizations, user);
    user.organization.isReseller = isReseller;

    this.organizations.splice(index, 1, user);

    await this._rest.put(
      'organization/reseller',
      {
        accId: id,
        isReseller,
      },
      { msg: 'Could not put reseller.' },
    );
  }

  public async setB2CProvider(id: string, isB2CProvider: boolean): Promise<void> {
    const user = this.organizations.find((o) => o.id === id);
    const index = indexOf(this.organizations, user);
    user.organization.isB2CProvider = isB2CProvider;

    this.organizations.splice(index, 1, user);

    await this._rest.put(
      'organization/B2CProvider',
      {
        accId: id,
        isB2CProvider,
      },
      { msg: 'Could not put provider.' },
    );
  }

  public async toggleCertifiedProviderState(id: string, certifiedProvider: boolean) {
    const user = this.organizations.find((o) => o.id === id);
    const index = indexOf(this.organizations, user);
    user.organization.certifiedProvider = certifiedProvider;

    this.organizations.splice(index, 1, user);

    await this._rest.put('organization/update', user.organization, { msg: 'Could not put provider.' });
  }

  public async updateOrg(id: string, isPrivateB2C: boolean): Promise<void> {
    const user = this.organizations.find((o) => o.id === id);
    const index = indexOf(this.organizations, user);
    user.organization.isPrivateB2C = isPrivateB2C;

    this.organizations.splice(index, 1, user);

    await this._rest.put('organization/update', user.organization, { msg: 'Could not put provider.' });
  }

  public async changeTrustedStatus(id: string, isTrusted: boolean): Promise<void> {
    const user = this.organizations.find((org) => org.id === id);
    const index = indexOf(this.organizations, user);
    user.organization.isTrusted = isTrusted;

    this.organizations.splice(index, 1, user);

    await this._rest.put(
      'organization/setTrustedState',
      {
        accId: id,
        isTrusted,
      },
      { msg: 'Could not put organization' },
    );
  }

  public async changeSubscriptionAuthorization(id: string, allowSubscription: boolean): Promise<void> {
    const user = this.organizations.find((org) => org.id === id);
    const index = indexOf(this.organizations, user);
    user.organization.allowSubscription = allowSubscription;

    this.organizations.splice(index, 1, user);
    await this._rest.put(
      'organization/allowSubscription',
      {
        accId: id,
        allowSubscription,
      },
      { msg: 'Could not put organization.' },
    );
  }

  public async changeStripeWarningStatus(id: string, disableStripeWarning: boolean): Promise<void> {
    const user = this.organizations.find((org) => org.id === id);
    const index = indexOf(this.organizations, user);
    user.organization.disableStripeWarning = disableStripeWarning;
    this.organizations.splice(index, 1, user);
    await this._rest.put(
      'organization/disableStripeWarning',
      {
        accId: id,
        disableStripeWarning,
      },
      { msg: 'Could not put organization.' },
    );
  }

  public async selectStudent(id: string): Promise<void> {
    this.selectedStudent = id;
  }

  public async refund(chargeId: string): Promise<void> {
    this.loading = true;
    this.confirm
      .createConfirmation(
        'Warning',
        'This action cannot be undone, do you really want to refund this payment?',
        'Yes',
        'No',
      )
      .then(
        async () => {
          await this._rest.post(
            'admin/refund',
            {
              chargeId,
            },
            { msg: 'Could not post refund' },
          );

          await this.selectUser(this.selectedUser);
          this.loading = false;
        },
        () => (this.loading = false),
      );
  }

  public openReceipt(url: string): void {
    window.open(url, '_blank');
  }

  public deselectStudent(): void {
    delete this.selectedStudent;
  }

  public getProgramById(id: string): Record<string, unknown> {
    if (!this.programs) {
      return {};
    }
    return this.programs.find((p) => p.id === id);
  }

  public async deleteToken(token: Token): Promise<void> {
    this.loading = true;
    this.confirm
      .createConfirmation('Warning', 'This action cannot be undone, do you confirm deleting this token?', 'Yes', 'No')
      .then(
        async () => {
          await this._rest.delete('token/' + token.id, { msg: 'Could not delete token.' });
          await this.selectUser(this.selectedUser);
          this.loading = false;
        },
        () => (this.loading = false),
      );
  }

  public async deleteProgress(progress: Progress): Promise<void> {
    this.loading = true;
    this.confirm
      .createConfirmation(
        'Warning',
        'This action cannot be undone, do you confirm deleting this progress?',
        'Yes',
        'No',
      )
      .then(async () => {
        await this._rest.delete('progress/' + progress.id, { msg: 'Could not delete progress.' });
        await this.selectUser(this.selectedUser);
        this.loading = false;
      });

    this.loading = false;
  }

  public openEditProgressDialog(progress: Progress) {
    if (this.isEmptyExerciseList(progress)) {
      return;
    }

    const dialog = this.dialog.open(ProgressEditDialog, {
      panelClass: 'modal-border',
      data: cloneDeep(progress),
      width: '600px',
    });

    dialog.afterClosed().subscribe(async (shouldUpdate: boolean) => {
      if (shouldUpdate) {
        this.loadingStudents = true;
        const user = this.clients.find((c) => c.id === this.selectedUser);
        await this._rest
          .get('patron/' + user.patron.id + '/students')
          .then((res) => (this.currentStudents = res.students));
        this.loadingStudents = false;
      }
    });
  }

  public isEmptyExerciseList(progress: Progress) {
    const exercises = get(progress, 'metadata.exercises');

    if (!exercises) {
      return false;
    }

    return exercises.length === 0;
  }

  public async addTokenTo(user: User): Promise<void> {
    this.loading = true;
    const dialog = this.dialog.open(AddTokenComponent, {
      width: '800px',
      data: {
        user,
        programs: this.programs,
      },
    });
    dialog.afterClosed().subscribe(async (response) => {
      if (!response) {
        return (this.loading = false);
      }
      await this._rest.post(
        'token',
        {
          programId: response.programId,
          allowCompleteAtHome: response.allowCompleteAtHome,
          accountId: user.id,
          quantity: response.quantity,
          paymentConfirmed: true,
          allowProgramRerun: response.allowProgramRerun,
        },
        { msg: 'Could not post token.' },
      );
      await this.selectUser(this.selectedUser);
      this.loading = false;
    });
  }

  public async openB2cPaymentOptionsDialog(user: Organization) {
    if (!user.b2cAllowedPaymentTypes) {
      user.b2cAllowedPaymentTypes = [B2cPaymentTypes.SinglePayment, B2cPaymentTypes.SplittedPayment];
    }

    this.dialog.open(B2cPaymentTypesDialog, {
      width: '400px',
      panelClass: 'custom-modalbox',
      data: user,
    });
  }

  public async deleteTokenList(user: User): Promise<void> {
    const avaliableTokens = this.currentTokens.filter((t) => !t.studentId && t.paymentConfirmed);

    const maxTokensToDelete = this.currentCredits;

    const dialog = this.dialog.open(DeleteTokenComponent, {
      width: '800px',
      data: {
        maxTokensToDelete,
        avaliableTokens,
      },
    });

    dialog.afterClosed().subscribe(async (amountToDelete) => {
      if (amountToDelete) {
        await this._rest.delete('/token/account/' + user.id + '/amount/' + amountToDelete);

        this._snackBar.open('Token list deleted', 'Close', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });

        await this.selectUser(this.selectedUser);
      }
    });
  }

  public accessStudentProgramPage(studentId: string) {
    this.router.navigate(['/program/' + studentId + '/Neuralign'], { queryParams: { debug: true } });
  }

  public formatDate(date: Date): string {
    const d = new Date(date);
    let ret = '';
    ret += d.getMonth() + 1 < 9 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1;
    ret += '/';
    ret += d.getDate() < 9 ? '0' + d.getDate() : d.getDate();
    ret += '/';
    ret += d.getFullYear();
    ret += ' ';
    ret += d.getHours();
    ret += ':';
    ret += d.getMinutes();
    return ret;
  }

  public numberOfExercisesWithEmptyLevel(progress: Progress[]): number {
    let savedProgressWithEmptyLevel = 0;

    progress.map((p) => {
      if (!p.tag.includes('assessment') && !p.metadata.exerciseList) {
        p.metadata.exercises.map((e) => {
          if (!e.level) {
            savedProgressWithEmptyLevel += 1;
          }
        });
      }

      return p;
    });

    return savedProgressWithEmptyLevel;
  }

  public studentHaveBrokenProgress(progress: Progress[]): boolean {
    if (!progress) {
      return false;
    }

    const brokenProgress = this.numberOfExercisesWithEmptyLevel(progress);

    return brokenProgress > 0;
  }

  public fixStudentProgress(student: Student) {
    const progress: Progress[] = student.progress;

    this.confirm
      .createConfirmation(
        'Warning',
        `This student have ${this.numberOfExercisesWithEmptyLevel(
          progress,
        )} exercises saved with empty level values. Do you want to fix this`,
        'Yes',
        'No',
      )
      .then(async () => {
        await Promise.all(
          progress.map(async (p) => {
            let shouldUpdateProgress = false;

            if (!p.tag.includes(ASSESSMENT) && !p.metadata.exerciseList) {
              p.metadata.exercises.map((e) => {
                if (!e.level) {
                  e.level = this.studentHelper.fixProgressWithNullLevel(student);
                  shouldUpdateProgress = true;
                }

                return e;
              });
            }

            if (shouldUpdateProgress) {
              await this._rest.put(
                'progress/' + p.id,
                {
                  progress: p,
                },
                { msg: 'Could not put progress' },
              );
            }

            return p;
          }),
        );
      });
  }

  public openMetadataInfo(progress: Progress, student: Student): void {
    let metadataInfo = [];

    if (progress.tag.includes('assessment')) {
      progress.metadata.name = '*-*-assessment-*';
      metadataInfo[0] = progress.metadata;
    } else if (progress.metadata.exercises) {
      metadataInfo = progress.metadata.exercises;
    } else {
      metadataInfo = progress.metadata.exerciseList;
    }

    this.dialog.open(MetadataDialogComponent, {
      width: 'auto',
      data: {
        metadata: metadataInfo,
        savedGame: progress.metadata.savedGame ? true : false,
        student,
        fullProgress: student.progress,
      },
    });
  }

  async updateOrganizationPrice(account: Account): Promise<void> {
    this.orgPriceInput = false;
    await this._rest.put(
      'organization/orgPrice',
      {
        accId: account.id,
        portalPrice: account.organization.portalPrice,
      },
      { msg: 'Could not put organization.' },
    );
  }

  public selectOutsiderToAdd(outsider: User): void {
    const name = get(outsider, 'organization.name');
    this.organizationAutocomplete.setValue(name);
    this.organizationToAdd = outsider;
  }

  public async addOutsider(): Promise<void> {
    if (!this.organizationToAdd) {
      return;
    }

    try {
      await this._rest.put(
        'organization/outsider/assign',
        {
          outsiderId: this.organizationToAdd.id,
          resellerId: this.selectedUser,
        },
        { msg: 'Could not put organization' },
      );

      this.prepareTable(this.selectedUser);
      this.notify('Outsider Added', 'OK', 3000);
    } catch (err) {
      this.notify(`Error: ${err.error.message}`, 'OK', 3000);
    }
  }

  public async removeOutsider(outsider: User): Promise<void> {
    this.loading = true;
    this.confirm.createConfirmation('Warning', 'Do you really want to delete this user?', 'Yes', 'No').then(
      async () => {
        try {
          await this._rest.put(
            'organization/outsider/remove',
            {
              outsiderId: outsider.id,
              resellerId: this.selectedUser,
            },
            { msg: 'Could not put organization.' },
          );

          this.notify('Outsider Removed', 'OK', 3000);

          this.prepareTable(this.selectedUser);
        } catch (err) {
          this.notify(`Error: ${err.error.message}`, 'OK', 3000);
        }
      },
      () => (this.loading = false),
    );
  }

  public async saveMaxDebit(id: string): Promise<void> {
    const user = this.organizations.find((org) => org.id === id);
    const index = indexOf(this.organizations, user);

    if (this.maxTokenDebit === null) {
      this._snackBar.open(`Please insert a valid token amount`, 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });

      return;
    }

    if (this.maxTokenDebit > 0) {
      this._snackBar.open(`The maximum org Debit must be negative`, 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });

      return;
    }

    user.organization.maximumTokenDebit = this.maxTokenDebit;

    this.organizations.splice(index, 1, user);

    this._rest
      .put(
        'organization/setMaxDebitAmount',
        {
          accId: id,
          maximumTokenDebit: this.maxTokenDebit,
        },
        { msg: 'Could not put organization.' },
      )
      .then(() => {
        this._snackBar.open(`Maximum Debit for ${user.organization.name} updated!`, 'Close', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      });
  }

  public checkIfIsSubscription(charge: { id: string }): boolean {
    if (!charge) {
      return false;
    }

    const findCharge = this.invoiceList.find((c) => c.charge === charge.id);

    return findCharge ? true : false;
  }

  public openSubscriptionDialog(charge: { id: string }): void {
    const invoice = this.invoiceList.find((i) => i.charge === charge.id);

    this.dialog.open(SubscriptionInfoComponent, {
      width: '600px',
      height: '315px',
      data: {
        user: this.selectedUser,
        invoice,
      },
    });
  }

  public async saveBundle(user: User): Promise<void> {
    this.findOrgRegularBundles(user);
  }

  public openPublicProfilePreview(user: User): void {
    if (!user.organization.publicProfile) {
      return;
    }

    this.dialog.open(PublicProfilePreviewComponent, {
      data: user.organization.publicProfile,
      width: '1250px',
    });
  }

  public async changePublicProfileState(id: string, enabled: boolean): Promise<void> {
    const user = this.organizations.find((org) => org.id === id);
    const index = indexOf(this.organizations, user);
    user.organization.publicProfile.enabled = enabled;
    this.organizations.splice(index, 1, user);
    await this._rest.put(
      'organization/changePublicProfileStatus',
      {
        accId: id,
        enabled,
      },
      { msg: 'Could not put organization.' },
    );
  }

  public editPublicProfile(user: User): Promise<void> {
    if (!user) {
      return;
    }

    const dialog = this.dialog.open(PublicProfileEditDialogComponent, {
      data: user,
      width: '1200px',
      height: '820px',
    });

    dialog.afterClosed().subscribe(async (shouldReload) => {
      if (shouldReload) {
        await this.ngOnInit();
      }
    });
  }

  public async resendEmail(account: Account) {
    let clientName = '';

    if (account.organization) {
      clientName = account.organization.name;
    } else if (account.patron) {
      clientName = account.patron.fullName ? account.patron.fullName : account.patron.name;
    }

    this._rest
      .get('account/email/verify/resend/' + account.id, {
        msg: 'Could not get account/email/resend/:clientAccountId.',
      })
      .then(() => {
        this._snackBar.open(`Verification email has been sent to ${clientName}`, 'Close', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      })
      .catch(() => {
        this._snackBar.open('E-mail not found', 'Close', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      });
  }

  public async changeOrgInformation(property: string, enabled: boolean, orgId: string) {
    const user = this.organizations.find((org) => org.id === orgId);
    const index = indexOf(this.organizations, user);
    user.organization[property] = enabled;
    this.organizations.splice(index, 1, user);

    await this._rest.put('/organization/update', user.organization);
  }

  public async openProgressCompletionDialog(student: Student) {
    const studentProgress = student.progress;
    const associatedTokens = student.tokens;

    if (associatedTokens.length === 0) {
      this._snackBar.open(
        'This student dont have an associated token, please assign one before complete the progress',
        'Close',
        {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        },
      );

      return;
    }

    const token = associatedTokens[0];

    const categoryList = await this.globalConfiguration.getCategories();

    const dialog = this.dialog.open(ProgressCompletionDialog, {
      data: {
        categoryList,
        studentProgress,
        student,
        run: token.progressRun || 0,
      },
      panelClass: 'custom-modalbox',
      width: '450px',
      disableClose: true,
    });

    dialog.afterClosed().subscribe(async (shouldReload) => {
      if (shouldReload) {
        this._snackBar.open('The student progress has been created', 'Close', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });

        await this.selectUser(this.selectedUser);
      }
    });
  }

  public openUpdateTokenDialog(token: Token) {
    const dialog = this.dialog.open(UpdateTokenDialog, {
      data: {
        token,
        programs: this.programs,
      },
      panelClass: 'modal-border',
      width: '650px',
      height: '620px',
    });

    dialog.afterClosed().subscribe(async (dataToSAve) => {
      if (dataToSAve) {
        this.loading = true;
        await this._rest.put('token/' + token.id, {
          token: dataToSAve,
        });

        await this.selectUser(this.selectedUser);
        this.loading = false;
      }
    });
  }

  public openOutsiderTransferDialog(org: Organization) {
    this.dialog.open(OutsiderImportDialog, {
      data: {
        userList: this.organizations,
        organization: org,
      },
      width: '500px',
      height: '340px',
    });
  }
}
