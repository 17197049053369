<div class="close">
  <button (click)="closeModal()"><fa-icon [icon]="closeIcon"></fa-icon></button>
</div>

<header>
  <img
    src="assets/img/NeurAlign text logo.png"
    width="80%"
  />
</header>

<div class="video-container">
  <div class="iframe-container">
    <iframe
      width="100%"
      [src]="data | safeUrl"
      allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    >
    </iframe>
  </div>
</div>
