import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { styleElement } from '../../helpers/styleElement.function';

@Directive({
  selector: '[montserrat]',
})
export class MontserratDirective {
  constructor(private el: ElementRef, private _renderer: Renderer2) {}

  ngOnInit() {
    styleElement(this._renderer, this.el, {
      fontFamily: 'Montserrat, sans-serif',
    });
  }
}
