import { AfterViewInit, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { clamp } from 'src/app/shared/helpers/clamp.function';
import { styleElement } from 'src/app/shared/helpers/styleElement.function';
import { Styles } from 'src/app/shared/styles/lsw.styles';

@Component({
  selector: 'home-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class ReportComponent implements AfterViewInit {
  @ViewChild('bg') bg: ElementRef;
  @ViewChild('box') box: ElementRef;

  constructor(private _renderer: Renderer2) {}

  ngAfterViewInit() {
    styleElement(this._renderer, this.box, {
      position: 'absolute',
      left: clamp(1, 12),
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      gap: clamp(0.3, 3),
    });

    styleElement(this._renderer, this.bg, { backgroundColor: Styles.Colours.SECONDARY_COLOUR });
  }

  public goToReport() {
    window.open('https://drive.google.com/file/d/1ouuhJp6ZOF_zEqbb2ogRTiUtXcatvM0b/view', '_blank');
  }
}
