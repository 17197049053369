import { Component, EventEmitter, Output } from '@angular/core';
import { get } from 'lodash';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RoleService } from 'src/app/services/roles/role.service';
import { Menu } from '../../users.component';
import { PortalConfig } from 'src/app/services/auth/auth-consts/auth-consts';

@Component({
  selector: 'users-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent {
  activeTab = 'clients';

  showB2CTab: boolean = this.setShowB2CTab();
  showOrganizationsTab: boolean = this.setShowOrganizationsTab();
  showManagersAndAdminsTab: boolean = this.setShowManagersAndAdminsTab();

  @Output() menuToDisplay = new EventEmitter<string>();

  constructor(private _auth: AuthService, private _roles: RoleService) {}

  public switchMenu(menu: Menu) {
    const hasUserClickedOnActiveTab = this.activeTab === menu;

    if (hasUserClickedOnActiveTab) {
      this.activeTab = 'privacy';
      this.menuToDisplay.emit('privacy');
    } else {
      this.activeTab = menu;
      this.menuToDisplay.emit(menu);
    }
  }

  public isSchoolConfig() {
    const config = this._auth.activeConfig;

    return config === PortalConfig.SCHOOL;
  }

  private setShowB2CTab(): boolean {
    // An organization has to be a B2C provider in order to see the B2C tab
    const isB2CProvider = get(this._auth.orgAcc, 'organization.isB2CProvider', false);

    // hide the B2C tab from Managers
    const isNotManager = !this._roles.isOrgManager();

    // Calcualte if tab should show
    const shouldShowB2CTab = isB2CProvider && isNotManager;

    // Set tab visiblity
    return shouldShowB2CTab;
  }

  private setShowOrganizationsTab(): boolean {
    // An org has to be a reseller in order to see the organizations tab
    const isReseller = get(this._auth.orgAcc, 'organization.isReseller', false);

    // hide the organizations tab from mangagers
    const isNotManager = !this._roles.isOrgManager();

    // Calcualte if tab should show
    const shouldShowOrganizationsTab = isReseller && isNotManager;

    // Set tab visiblity
    return shouldShowOrganizationsTab;
  }

  private setShowManagersAndAdminsTab(): boolean {
    // hide the managers and admins tab from Managers
    const isNotManager = !this._roles.isOrgManager();

    // Calcualte if tab should show
    const shouldShowManagersAndAdminsTab = isNotManager;

    // Set tab visiblity
    return shouldShowManagersAndAdminsTab;
  }
}
