import { animate, style, transition, trigger } from '@angular/animations';

export const fadeAnimation = [
  trigger('fade', [
    transition(':enter', [
      style({ opacity: 0, display: 'none' }),
      animate('600ms cubic-bezier(0.900, -0.410, 0.750, 0.750)', style({ opacity: 1, display: 'block' })),
    ]),
    transition(':leave', [
      style({ opacity: 1, display: 'block' }),
      animate('300ms cubic-bezier(0.900, -0.410, 0.750, 0.750)', style({ opacity: 0, display: 'none' })),
    ]),
  ]),
];
