import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'pricing-paragraph',
  templateUrl: './paragraph.component.html',
  styleUrls: ['./paragraph.component.scss'],
})
export class ParagraphComponent implements OnInit {
  @Input() text = '%% PLACEHOLDER %%';
  @Input() isBold = false;

  constructor() {}

  ngOnInit(): void {}
}
