<div class="mainContainer p-4">
  <users-table-header heading="Students"></users-table-header>

  <div class="search-and-button">
    <users-table-search-input
      class="mx-2"
      placeholder="Search by name..."
      (userKeyUp)="applyFilter($event)"
    ></users-table-search-input>

    <users-table-search-input
      class="mx-2"
      placeholder="Search by school..."
      (userKeyUp)="applySchoolFilter($event)"
    ></users-table-search-input>

    <div class="button-container">
      <button
        class="button-primary"
        type="button"
        (click)="createStudent()"
      >
        Add New +
      </button>
    </div>
  </div>

  <div
    class="table p-3"
    *ngIf="dataSource.data.length > 0 && !loading"
  >
    <mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
        <mat-cell *matCellDef="let student"> {{ student.fullname }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="age">
        <mat-header-cell *matHeaderCellDef> Age </mat-header-cell>
        <mat-cell *matCellDef="let student">
          <span>{{ getStudentAge(student) }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="grade">
        <mat-header-cell *matHeaderCellDef> Grade </mat-header-cell>
        <mat-cell *matCellDef="let student">
          <span> {{ student.grade || 0 }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="school">
        <mat-header-cell *matHeaderCellDef> School </mat-header-cell>
        <mat-cell *matCellDef="let student">
          <span> {{ student.school }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="program">
        <mat-header-cell *matHeaderCellDef> Program </mat-header-cell>
        <mat-cell *matCellDef="let student">
          <ng-container
            *ngIf="
                      student.tokens?.length > 0;
                      then neuralignBadge;
                      else noProgramBadge
                    "
          ></ng-container>
          <ng-template #neuralignBadge>
            <users-neuralign-badge></users-neuralign-badge>
          </ng-template>
          <ng-template #noProgramBadge>
            <users-no-program-badge></users-no-program-badge>
          </ng-template>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell
          *matHeaderCellDef
          class="d-flex justify-content-center"
        >
          Actions
        </mat-header-cell>
        <mat-cell
          *matCellDef="let student"
          class="d-flex justify-content-center"
        >
          <div class="icons d-flex align-items-center">
            <users-table-add-program-button
              *ngIf="allowActions(student)"
              class="mx-2"
              [student]="student"
            ></users-table-add-program-button>
            <users-table-action-icon
              class="mx-2"
              [icon]="'edit'"
              [tooltip]="'Edit user'"
              (click)="editClientStudent(student)"
            ></users-table-action-icon>
            <users-table-action-icon
              class="mx-2"
              *ngIf="canAccessNeuralign(student) && allowActions(student)"
              [icon]="'game'"
              [tooltip]="'Access Neuralign'"
              (click)="accessNeuralign(student)"
            ></users-table-action-icon>
            <users-table-action-icon
              class="mx-2"
              *ngIf="!canAccessNeuralign(student) && allowActions(student)"
              [icon]="'game'"
              [tooltip]="
                    'Please assign a credit to this student to access Neuralign'
                  "
              [disabled]="true"
            ></users-table-action-icon>
            <users-table-action-icon
              *ngIf="allowActions(student)"
              class="mx-2"
              [icon]="'trash'"
              [tooltip]="'Delete user'"
              (click)="deleteStudent(student)"
            ></users-table-action-icon>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="
              ['name', 'age', 'grade', 'school', 'program', 'actions'];
              sticky: true
            "
      ></mat-header-row>
      <mat-row
        *matRowDef="
              let row;
              columns: ['name', 'age', 'grade', 'school', 'program', 'actions'];
            "
      ></mat-row>
    </mat-table>
  </div>

  <div *ngIf="loading">
    <app-loading-indicator></app-loading-indicator>
  </div>

  <ng-container *ngIf="dataSource.data.length === 0 && !loading">
    <users-empty-state
      template="noStudents"
      text="This Organization does not have any students"
      (onAddStudent)="createStudent()"
    ></users-empty-state>
  </ng-container>
</div>
