import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { QuizService } from 'src/app/pages/courses/services/quiz/quiz.service';
import { fadeAnimation } from 'src/app/pages/courses/utils/fade.animation';

@Component({
  selector: 'quiz-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
  animations: fadeAnimation,
})
export class FeedbackComponent implements OnInit {
  isQuestionComplete = false;
  isQuestionCompleteSub: Subscription;

  feedback: string;

  currentQuizQuestion$ = this._quiz.currentQuizQuestion$;

  constructor(private _quiz: QuizService) {}

  ngOnInit() {
    this.feedback = this.currentQuizQuestion$.value.feedback;

    this.isQuestionCompleteSub = this._quiz.currentQuizQuestionCompleteEvent.subscribe(
      (bool) => (this.isQuestionComplete = bool),
    );
  }
  ngOnDestroy() {
    this.isQuestionCompleteSub.unsubscribe();
  }
}
