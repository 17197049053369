<div class="px-0 mainContainer">
  <div class="col-12 px-0 d-flex justify-content-center">
    <form
      [formGroup]="profileFormGroup"
      class="p-1 d-flex col-12 justify-content-around"
    >
      <div class="col-5 mx-2 px-0 firstContainer">
        <div>
          <h3 class="pl-5">Organization logo</h3>
          <div class="orgLogo col p-4">
            <img [src]="profileFormGroup.value.logo" />
          </div>

          <div class="col-12 px-0 text-center">
            <app-simple-button
              text="UPLOAD IMAGE"
              (click)="uploadToCloudinary()"
            ></app-simple-button>
          </div>
        </div>

        <div
          class="col-12 px-0 mt-5"
          *ngIf="checkVideoUrl(profileFormGroup.value.videoUrl)"
        >
          <h3 class="pl-5">Organization video</h3>

          <div class="centerVideo text-center">
            <iframe
              width="510"
              height="345"
              [src]="returnVideoUrl(profileFormGroup.value.videoUrl) | safeUrl"
            >
            </iframe>
          </div>
        </div>
      </div>

      <div class="col-5 mx-2 px-0 secondContainer">
        <h3>Organization info</h3>
        <div class="col-12 px-0">
          <mat-form-field appearance="outline">
            <mat-label>Organization name</mat-label>
            <input
              matInput
              required
              placeholder="Organization name"
              formControlName="name"
            />
          </mat-form-field>

          <br />

          <mat-form-field appearance="outline">
            <mat-label>Phone number</mat-label>
            <input
              matInput
              placeholder="Phone number"
              formControlName="phoneNumber"
            />
          </mat-form-field>

          <br />

          <div class="two-columns">
            <mat-form-field appearance="outline">
              <mat-label>E-mail</mat-label>
              <input
                matInput
                placeholder="E-mail"
                formControlName="email"
              />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Website</mat-label>
              <input
                matInput
                placeholder="Website"
                formControlName="website"
              />
            </mat-form-field>
          </div>

          <div class="two-columns">
            <mat-form-field appearance="outline">
              <mat-label>Address</mat-label>
              <input
                matInput
                placeholder="Address"
                formControlName="address"
              />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Postal code</mat-label>
              <input
                matInput
                placeholder="Postal code"
                formControlName="postalCode"
              />
            </mat-form-field>
          </div>

          <div class="two-columns">
            <mat-form-field appearance="outline">
              <mat-label>Country</mat-label>
              <input
                required
                matInput
                placeholder="Country"
                formControlName="country"
              />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Province</mat-label>
              <input
                required
                matInput
                placeholder="Province"
                formControlName="province"
              />
            </mat-form-field>
          </div>

          <mat-form-field appearance="outline">
            <mat-label>Video url</mat-label>
            <input
              matInput
              placeholder="Video url"
              formControlName="videoUrl"
            />
          </mat-form-field>
          <mat-accordion multi>
            <mat-expansion-panel class="my-1">
              <mat-expansion-panel-header>
                <mat-panel-title> Videos with subtitles </mat-panel-title>
                <mat-panel-description>
                  Expand to see the options
                </mat-panel-description>
              </mat-expansion-panel-header>

              <div class="subtitles">
                <div class="pb-3 pt-2 px-1">
                  <table
                    *ngIf="
                      profileFormGroup.value.videosWithSubtitles.length > 0
                    "
                    mat-table
                    [dataSource]="dataSource"
                    class="col-12 px-0"
                    multiTemplateDataRows
                  >
                    <ng-container matColumnDef="code">
                      <th
                        mat-header-cell
                        *matHeaderCellDef
                        class="text-center"
                      >
                        Country code
                      </th>
                      <td
                        mat-cell
                        *matCellDef="let video"
                        class="text-center"
                      >
                        {{ video.code }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="url">
                      <th
                        mat-header-cell
                        *matHeaderCellDef
                        class="text-center"
                      >
                        URL
                      </th>
                      <td
                        mat-cell
                        *matCellDef="let video"
                        class="text-center pointer"
                        (click)="goToUrl(video.url)"
                      >
                        {{ video.url }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                      <th
                        mat-header-cell
                        *matHeaderCellDef
                        class="text-center"
                      >
                        Actions
                      </th>
                      <td
                        mat-cell
                        *matCellDef="let video"
                        class="text-center"
                      >
                        <fa-icon
                          class="pointer"
                          [icon]="deleteIcon"
                          (click)="deleteVideo(video)"
                        ></fa-icon>
                      </td>
                    </ng-container>

                    <tr
                      mat-header-row
                      *matHeaderRowDef="displayedColumns"
                    ></tr>
                    <tr
                      mat-row
                      *matRowDef="let row; columns: displayedColumns"
                      class="example-element-row"
                    ></tr>
                  </table>
                </div>
              </div>
              <form [formGroup]="videosFormGroup">
                <div class="inputs two-columns">
                  <mat-form-field appearance="outline">
                    <mat-label> Country code </mat-label>
                    <input
                      matInput
                      placeholder="Country code"
                      formControlName="code"
                    />
                  </mat-form-field>

                  <mat-form-field appearance="outline">
                    <mat-label>Video url</mat-label>
                    <input
                      matInput
                      placeholder="Video url"
                      formControlName="url"
                    />
                  </mat-form-field>
                </div>
              </form>
              <div class="buttons d-flex justify-content-center">
                <app-simple-button
                  class="mx-1"
                  [text]="'Add new'"
                  (click)="addSubtitledVideo()"
                  [disabled]="videosFormGroup.status === 'INVALID'"
                >
                </app-simple-button>
              </div>
            </mat-expansion-panel>
          </mat-accordion>

          <div class="orgDescription px-0 mt-4">
            <textarea
              required
              formControlName="description"
              draggable="false"
              placeholder="Add a description of your center here"
            ></textarea>
          </div>
        </div>

        <div class="buttons col-12 px-0 d-flex justify-content-center mt-2">
          <app-simple-button
            class="mx-1"
            [text]="'SAVE'"
            [disabled]="profileFormGroup.status === 'INVALID'"
            (click)="save()"
          >
          </app-simple-button>

          <app-simple-button
            class="mx-1"
            [text]="'CANCEL'"
            (click)="close()"
          >
          </app-simple-button>
        </div>
      </div>
    </form>
  </div>
</div>
