<section class="what-youll-need">
  <h1>What you'll need</h1>

  <h2>Equipment List:</h2>
  <p>
    <span class="dot">• </span>Over-ear headset<span class="dot"> • </span
    >Keyboard<span class="dot"> • </span>External mouse<span class="dot">
      • </span
    >Computer<span class="dot"> • </span>21” to 35” Monitor or TV. <br />* We
    recommend connecting laptops that are smaller than 15” to a larger screen
  </p>

  <h2>System requirements:</h2>
  <p>
    Specs for PC: i3 or above processor, 6GB or more RAM, graphics card with 2GB
    internal memory. Specs for Mac: Any mac0S.
  </p>
</section>
