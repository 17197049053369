import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { PrivacyPolicyComponent } from './privacy-policy.component';
import { LayoutsModule } from 'src/app/shared/layouts/layouts.module';

@NgModule({
  declarations: [PrivacyPolicyComponent],
  imports: [BrowserModule, LayoutsModule],
})
export class PrivacyPolicyModule {}
