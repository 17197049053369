import { Component, OnInit, Inject } from '@angular/core';
import { IconDefinition, faShoppingCart, faCheck } from '@fortawesome/free-solid-svg-icons';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { get } from 'lodash';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { LoggerService } from 'src/app/services/logger/logger.service';

@Component({
  selector: 'app-update-bundle-dialog',
  templateUrl: './update-bundle-dialog.component.html',
  styleUrls: ['./update-bundle-dialog.component.scss'],
})
export class UpdateBundleDialogComponent implements OnInit {
  public checkIcon: IconDefinition = faCheck;
  public bundleFormGroup: UntypedFormGroup;
  public cartIcon: IconDefinition = faShoppingCart;
  public amount: number;
  public id: string;
  public isGlobalBundle = false;
  public index: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _rest: RestAPIService,
    protected _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<UpdateBundleDialogComponent>,
    private logger: LoggerService,
  ) {}

  ngOnInit() {
    this.id = this.data.bundle.id;
    this.isGlobalBundle = this.data.bundle.globalConfigId ? true : false;
    this.bundleFormGroup = new UntypedFormGroup({
      title: new UntypedFormControl('', [Validators.required]),
      price: new UntypedFormControl('', [Validators.required]),
      yearlyPrice: new UntypedFormControl('', [Validators.required]),
      currency: new UntypedFormControl('CAD', [Validators.required]),
      studentsIncluded: new UntypedFormControl('', [Validators.required]),
      subscription: new UntypedFormControl('', [Validators.required]),
      isFirstPurchaseBundle: new UntypedFormControl('', [Validators.required]),
    });
    this.bundleFormGroup.setValue({
      title: get(this.data.bundle, 'title', ''),
      price: get(this.data.bundle, 'price', 0),
      yearlyPrice: get(this.data.bundle, 'yearlyPrice', 0),
      currency: get(this.data.bundle, 'currency', 'CAD'),
      studentsIncluded: get(this.data.bundle, 'studentsIncluded', 0),
      subscription: get(this.data.bundle, 'subscription', false),
      isFirstPurchaseBundle: get(this.data.bundle, 'isFirstPurchaseBundle', false),
    });
  }

  public async save() {
    const bundleToSave = this.bundleFormGroup.value;

    if (this.id && !this.isGlobalBundle) {
      bundleToSave.id = this.id;
    }

    bundleToSave.order = this.data.order;
    bundleToSave.isRegularBundle = true;

    if (get(bundleToSave, 'id', false)) {
      try {
        await this._rest.put(
          `bundle/${bundleToSave.id}`,
          {
            bundle: bundleToSave,
          },
          { msg: 'Could not put bundle.' },
        );

        this._snackBar.open(`Default bundle for ${this.data.user.organization.name} changed!`, 'Close', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });

        this.dialogRef.close(true);
      } catch (error) {
        this._snackBar.open(`Could not update this bundle!`, 'Close', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
        this.logger.error(error);
        this.dialogRef.close(false);
      }
    } else {
      const resellerId = get(this.data.user, 'organization.id', '');

      try {
        await this._rest.post(
          `bundle`,
          {
            bundle: bundleToSave,
            resellerOrganizationId: resellerId,
          },
          { msg: 'Could not post bundle.' },
        );

        this._snackBar.open(`Default bundle for ${this.data.user.organization.name} changed!`, 'Close', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });

        this.dialogRef.close(true);
      } catch (error) {
        this._snackBar.open(`Could not update this bundle!`, 'Close', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
        this.logger.error(error);
        this.dialogRef.close(false);
      }
    }
  }
}
