import { EventEmitter, Injectable } from '@angular/core';
import { QuizQuestion } from '../courses/courses.service.models';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class QuizService {
  public currentQuiz$ = new BehaviorSubject<any>({});
  public currentQuizQuestion$ = new BehaviorSubject<QuizQuestion>({} as QuizQuestion);

  public currentChosenAnswers$ = new BehaviorSubject<any[]>([]);
  public currentCorrectMultiAnswers$ = new BehaviorSubject<any[]>([]);

  public currentQuizQuestionCompleteEvent = new EventEmitter<boolean>();
  public currentQuizCompleteEvent = new EventEmitter<boolean>();

  public userAnswers: { [questionId: string]: any } = {};

  constructor() {}

  public loadQuiz(lesson) {
    const quiz = lesson.content.quizes.map((quiz, index) => {
      return {
        question: quiz.question,
        type: getQuizType(quiz.type),
        typeLabel: getQuizTypeLabel(quiz.type),
        choices: quiz.choices,
        feedback: quiz.feedBack,
        isOptional: quiz.isOptional,
        isFirstQuestion: index === 0,
        isLastQuestion: index === lesson.content.quizes.length - 1,
        index: index,
      } as QuizQuestion;
    });

    function getQuizType(type) {
      if (type === 'One correct answer') {
        return 'single';
      }

      if (type === 'One or more correct answers') {
        return 'multi';
      }
    }

    function getQuizTypeLabel(type) {
      if (type === 'One correct answer') {
        return 'Choose only ONE best answer.';
      }

      if (type === 'One or more correct answers') {
        return 'Choose ALL answers that apply.';
      }
    }

    this.currentQuiz$.next(quiz);
    this.currentQuizQuestion$.next(quiz[0]);
  }

  public nextQuizQuestion() {
    if (!this.currentQuizQuestion$.value.isLastQuestion) {
      const correctAnswer = this.currentQuizQuestion$.value.choices.find((choice) => choice.correctChoice === true);
      this.saveAnswer(this.currentQuizQuestion$.value.index, correctAnswer);

      const nextQuestion = this.currentQuiz$.value[this.currentQuizQuestion$.value.index + 1];
      this.currentQuizQuestion$.next(nextQuestion);
      this.currentQuizQuestionCompleteEvent.emit(false);
    }
  }

  public prevQuizQuestion() {
    if (!this.currentQuizQuestion$.value.isFirstQuestion) {
      const prevQuestionIndex = this.currentQuizQuestion$.value.index - 1;
      const prevQuestion = this.currentQuiz$.value[prevQuestionIndex];
      const userAnswer = this.getAnswer(this.currentQuizQuestion$.value[this.currentQuizQuestion$.value.index - 1]);

      this.currentQuizQuestion$.next(prevQuestion);
      this.currentChosenAnswers$.next(userAnswer);
      this.currentQuizQuestionCompleteEvent.emit(false);
    }
  }

  public saveAnswer(questionId: number, answer: any) {
    this.userAnswers[questionId] = answer;
  }

  public getAnswer(questionId: number) {
    return this.userAnswers[questionId];
  }
}
