import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms-of-service-box',
  templateUrl: './terms-of-service-box.component.html',
  styleUrls: ['./terms-of-service-box.component.scss'],
})
export class TermsOfServiceBoxComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
