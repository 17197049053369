import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'users-table-website-item',
  templateUrl: './table-website-item.component.html',
  styleUrls: ['./table-website-item.component.scss'],
})
export class TableWebsiteItemComponent implements OnInit {
  @Input() website: string;
  subdomain;

  ngOnInit(): void {
    this.subdomain = 'https://' + this.website + '.ls.works';
  }

  goToSubdomain() {
    window.open(this.subdomain, '_blank');
  }
}
