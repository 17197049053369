import { Injectable } from '@angular/core';
import { HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse, HttpInterceptor } from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorHandlerService } from '../errors/services/error-handler.service';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { get } from 'lodash';
import { AuthService } from 'src/app/services/auth/auth.service';
import { captureException } from '@sentry/angular-ivy';

type ErrorDialogType = 'hidden' | 'show' | 'none' | 'snack';

/**
 * Intercepts every http request and handles errors
 */
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  private response: any;

  // Add routes that we shouldnt retry the request

  public blockedRetryRoutes: RegExp[] = [new RegExp('/subscription/([^/]+)/retryPayment')];

  constructor(
    private errorDialogService: ErrorHandlerService,
    private snackbar: MatSnackBar,
    private logger: LoggerService,
    private auth: AuthService,
  ) {}

  /**
   * Pipe the request through `retry(2)` so that failed requests retry twice before catching.
   * Then pipes to `catchError` and decides where to send the error.
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const shouldRetry: boolean = !this.blockedRetryRoutes.some((pattern) => pattern.test(request.url));
    const retryAmount = shouldRetry ? 2 : 0;

    return next.handle(request).pipe(
      retry(retryAmount),
      catchError((error: HttpErrorResponse) => {
        // Sentry
        captureException(new Error(error.message), {
          data: {
            method: request.method,
            url: request.url,
            body: request.body,
            code: error.status,
          },
        });

        // Clarity
        (window as any)?.clarity?.('event', 'http-error');

        // Add additional properties to error
        // Using this.response, we get the error object with additional properties.
        this.response = Object.assign({}, error, {
          method: request.method,
          url: request.url,
          body: request.body,
        });

        const errorDialog: ErrorDialogType = get(error, 'error.dialog') ?? 'snack';

        // Decide where to route the error
        if (errorDialog === 'show') {
          this.sendErrorToDialog();
        } else if (errorDialog === 'hidden') {
          this.sendErrorToConsole();
        } else if (errorDialog !== 'none') {
          this.sendErrorToSnackbar(request, error);
        }

        if (get(error, 'error.error.message') === 'jwt expired') {
          this.auth.logout();
        }

        return throwError(() => new Error(error.message));
      }),
    ) as Observable<HttpEvent<any>>;
  }

  /**
   * Helpers
   */
  sendErrorToSnackbar(request, response) {
    const message = get(response, 'error.message', get(response, 'error.error.message', ''));
    const statusCode = get(response, 'error.statusCode', get(response, 'error.error.statusCode', ''));

    // Send error to clarity
    (window as any)?.clarity?.('event', 'error');

    this.snackbar.open(`Error ${statusCode}: ${message} ${get(request, 'body.msg', '')}`, 'Close', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: 5000,
    });

    return this.sendErrorToConsole();
  }

  sendErrorToConsole() {
    this.logger.error(this.response);
    return EMPTY;
  }

  sendErrorToDialog() {
    this.errorDialogService.openDialog(this.response);
    return throwError(this.response);
  }
}
