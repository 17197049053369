import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { IconDefinition, faEye, faBan, faUsers } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'users-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss'],
})
export class EmptyStateComponent implements OnInit {
  @Input() text = '';
  @Input() template: 'unselected' | 'noData' | 'noStudents' | undefined;
  @Input() isAddButtonEnabled = true;

  @Output() onAddStudent = new EventEmitter();

  eyeIcon: IconDefinition = faEye;
  noDataIcon: IconDefinition = faBan;
  emptySetIcon: IconDefinition = faUsers;

  ngOnInit(): void {}

  handleAddStudent() {
    this.onAddStudent.emit();
  }
}
