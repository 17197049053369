import { Renderer2, QueryList, ElementRef } from '@angular/core';
import { CSSObject } from './CSSObject.type';
import { styleElement } from './styleElement.function';

/**
 * This function is the same as syleElement() but is intended for ViewChildren containers.
 */
export function styleElements(renderer: Renderer2, elements: QueryList<ElementRef>, values: CSSObject): void {
  elements.forEach((element) => {
    styleElement(renderer, element, values);
  });
}
