<ng-container *ngIf="canShowDebugHelper">
  <fa-icon
    [icon]="bug"
    (click)="openDebugHelper()"
    class="icon"
  ></fa-icon>
  <table *ngIf="isDebugHelperOpen">
    <tr>
      <th>Property</th>
      <th>Value</th>
    </tr>
    <tr>
      <td>ID</td>
      <td>{{ user.id }} <button (click)="copy(user.id)">copy</button></td>
    </tr>
    <tr>
      <td>Email</td>
      <td>{{ user.email }} <button (click)="copy(user.email)">copy</button></td>
    </tr>
    <tr>
      <td>Type</td>
      <td>{{ user.patron }}</td>
    </tr>
    <tr>
      <td>Role</td>
      <td>{{ user.role }}</td>
    </tr>
    <tr>
      <td>B2C Provider</td>
      <td>{{ user.isB2cProvider }}</td>
    </tr>
    <tr>
      <td>Reseller</td>
      <td>{{ user.isReseller }}</td>
    </tr>
    <tr>
      <td>Test Error</td>
      <td><button (click)="throwTestError()">Throw test error</button></td>
    </tr>
    <tr>
      <td>Test HTTP Error</td>
      <td>
        <button (click)="throwTestHttpError()">Throw test http error</button>
      </td>
    </tr>
  </table>
</ng-container>
