import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TextHighlightComponent } from './highlight.component';

@NgModule({
  declarations: [TextHighlightComponent],
  imports: [BrowserModule],
  exports: [TextHighlightComponent],
})
export class TextHighlightModule {}
