import { Component, Input } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faBan, faSpinner, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';

@Component({
  selector: 'app-empty-state',
  template: ` <div class="empty-state">
    <fa-icon
      *ngIf="!loading && !addPath"
      [icon]="icon"
    ></fa-icon>

    <fa-icon
      *ngIf="loading"
      [icon]="spinnerIcon"
      [spin]="true"
    ></fa-icon>

    <a
      *ngIf="addPath && !loading"
      (click)="navigate(addPath)"
    >
      <fa-icon
        class="active"
        [icon]="plusIcon"
      ></fa-icon>
    </a>

    <div *ngIf="!loading">
      {{ text }}
    </div>
  </div>`,
  styleUrls: ['./empty-state.component.scss'],
})
export class EmptyStateComponent {
  @Input() readonly text: string = 'Nothinig to see here!';
  @Input() readonly icon: IconDefinition = faBan;
  @Input() readonly loading: boolean = false;
  @Input() readonly addPath: string;

  public spinnerIcon: IconDefinition = faSpinner;
  public plusIcon: IconDefinition = faPlus;

  constructor(private router: Router) {}

  protected navigate(path) {
    this.router.navigate([path]);
  }
}
