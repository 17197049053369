import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { QuizQuestion, QuizQuestionType } from 'src/app/pages/courses/services/courses/courses.service.models';
import { QuizService } from 'src/app/pages/courses/services/quiz/quiz.service';

@Component({
  selector: 'quiz-choice-list-item',
  templateUrl: './choice-list-item.component.html',
  styleUrls: ['./choice-list-item.component.scss'],
})
export class ChoiceListItemComponent {
  @Input() choice;

  @Output() multiAnswerCheck = new EventEmitter();

  currentQuizQuestion$: BehaviorSubject<QuizQuestion> = this._quiz.currentQuizQuestion$;

  isMarked = false;
  color = 'initial';
  backgroundColor = 'white';

  isQuestionComplete = false;
  isQuestionCompleteSub: Subscription;

  constructor(private _quiz: QuizService) {}

  ngOnInit() {
    this.isQuestionCompleteSub = this._quiz.currentQuizQuestionCompleteEvent.subscribe(
      (bool) => (this.isQuestionComplete = bool),
    );
  }
  ngOnDestroy() {
    this.isQuestionCompleteSub.unsubscribe();
  }

  public handleClick(choice) {
    if (this.currentQuizQuestion$.value.type === QuizQuestionType.SingleAnswer) {
      this.handleSingleAnswerSelection(choice);
    }
    if (this.currentQuizQuestion$.value.type === QuizQuestionType.MulitAnswer) {
      this.handleMultiAnswerSelection(choice);
    }
  }

  private handleSingleAnswerSelection(choice) {
    this.markChoice(choice);

    if (choice.correctChoice) {
      this._quiz.currentQuizQuestionCompleteEvent.emit(true);
    }
  }

  private handleMultiAnswerSelection(choice) {
    this.markChoice(choice);
    this.multiAnswerCheck.emit(choice);
  }

  private markChoice(choice) {
    if (!choice.correctChoice) {
      this.isMarked = true;
      this.color = 'red';
      this.backgroundColor = 'hsl(343, 100%, 89%)';
    }

    if (choice.correctChoice) {
      this.isMarked = true;
      this.color = 'green';
      this.backgroundColor = 'hsl(143, 100%, 89%)';
    }
  }
}
