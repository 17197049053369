'use strict';

exports.__esModule = true;
var _core = require('./core');
var _core2 = _interopRequireDefault(_core);
var _passwordless = require('./engine/passwordless');
var _passwordless2 = _interopRequireDefault(_passwordless);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}
function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }
  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}
function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}
var Auth0LockPasswordless = function (_Core) {
  _inherits(Auth0LockPasswordless, _Core);
  function Auth0LockPasswordless(clientID, domain, options) {
    _classCallCheck(this, Auth0LockPasswordless);
    var _this = _possibleConstructorReturn(this, _Core.call(this, clientID, domain, options, _passwordless2.default));
    (0, _core.injectStyles)();
    (0, _core.setWindowHeightStyle)();
    window.addEventListener('resize', function () {
      (0, _core.setWindowHeightStyle)();
    });
    return _this;
  }
  return Auth0LockPasswordless;
}(_core2.default);
exports.default = Auth0LockPasswordless;
Auth0LockPasswordless.version = '11.33.3';