<app-main-layout
  [breadcrumbs]="[
    { name: 'Home', url: '/' },
    { name: 'Students', url: '/students' },
    { name: programName, url: '/students' },
    { name: studentName }
  ]"
>
  <div class="student-results">
    <div
      class="program-items"
      *ngIf="programInfo"
    >
      <img
        class="program-img"
        [src]="programInfo.image"
      />
      <div
        class="program-item hide-sm"
        *ngFor="let lesson of programInfo?.categories"
        (click)="selectLesson(lesson.id)"
      >
        <img [src]="lesson.imageUrl" />
        <p>{{ lesson.name }}</p>
      </div>
    </div>

    <div
      class="selected-item"
      *ngFor="let lesson of programInfo?.categories; let index = index"
      [hidden]="checkScreenWidth() && selectedLesson !== lesson"
    >
      <h2>{{ lesson.name }}</h2>
      <br /><br />
      <canvas id="Lesson{{ index }}Chart"></canvas>
    </div>
  </div>
</app-main-layout>
