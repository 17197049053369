<ng-container>
  <div class="credits">
    <div
      class="badge"
      [ngClass]="{ 'negative-tokens': credits < 0 }"
    >
      <div *ngIf="credits !== null && !isOrgClient">
        <span
          class="credits-amount"
          *ngIf="credits !== undefined"
          data-cy="creditsAmount"
        >
          {{ credits }}
        </span>
        <fa-icon
          class="credits-amount"
          [icon]="loading"
          [spin]="true"
          *ngIf="credits === undefined"
        ></fa-icon>
        Credits
      </div>
    </div>
    <div class="refresh">
      <div
        matTooltip="Refresh credits"
        matTooltipPosition="below"
        *ngIf="!isOrgClient"
      >
        <fa-icon
          data-cy="refreshButton"
          [icon]="refresh"
          (click)="handleRefreshCredits()"
          [ngClass]="{ 'disabled-icon': credits === undefined }"
        ></fa-icon>
      </div>
    </div>
  </div>
</ng-container>
