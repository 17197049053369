import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TwoTablesComponent } from './two-tables/two-tables.component';

@NgModule({
  declarations: [TwoTablesComponent],
  imports: [CommonModule],
  exports: [TwoTablesComponent],
})
export class UsersLayoutsModule {}
