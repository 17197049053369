<link
  rel="stylesheet"
  href="/assets/styles/webgl.css"
/>
<div class="game-container">
  <!-- Mobile warning message -->
  <div
    *ngIf="isMobileDevice"
    class="mobile-warning"
  >
    <div class="warning-content">
      <h2>UNSUPPORTED DEVICE</h2>
      <p>
        For a better experience, please access through a computer (PC or MAC).
      </p>
      <p class="sub-text">This content is not optimized for mobile devices.</p>
      <button
        mat-button
        color="primary"
        class="learn-more-btn"
        (click)="openSystemRequirements()"
      >
        Learn More
      </button>
    </div>
  </div>

  <!-- Existing game content - only show if not mobile -->
  <ng-container *ngIf="!isMobileDevice">
    <div
      class="webgl-content m-auto"
      [ngClass]="{ hide: loading }"
    >
      <div id="gameContainer">
        <canvas
          id="gameCanvas"
          style="width: 960px; height: 540px"
        ></canvas>
      </div>
      <div class="footer">
        <span>Click the arrows to enlarge your screen</span>
        <div
          class="fullscreen"
          (click)="setFullscreen()"
        ></div>
      </div>
    </div>

    <div
      class="loading"
      [ngClass]="{ hide: !loading }"
    >
      <div class="col-2 px-0">
        <div class="col-12 px-0 loadingText">
          <img
            [ngStyle]="{ width: '160px', padding: '10px' }"
            src="../../../../assets/img/NeurAlign text logo.png"
            alt="Neuralign logo"
          />
        </div>
        <div class="col-8 px-0 m-auto">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
      </div>
    </div>
  </ng-container>
</div>
