import { Component, Input } from '@angular/core';
import { MenuItem } from './breadcrumb.interface';
import { faHome, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';

// breadcrumb.component
@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  @Input() readonly menuItems: MenuItem[];
  @Input() reload: boolean;

  public homeIcon: IconDefinition = faHome;

  constructor(private _router: Router) {}

  public goto(path) {
    if (!this.reload) {
      this._router.navigate([path]);
    } else {
      window.open(path, '_self');
    }
  }
}
