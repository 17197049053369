import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CourseFEM } from '../../../services/courses/courses.service.models';

/**
 * @scope Uses Router and CoursesService to navigate to the currently selected course.
 */
@Component({
  selector: 'goto-course-button',
  templateUrl: './goto-course-button.component.html',
  styleUrls: ['./goto-course-button.component.scss'],
})
export class GotoCourseButtonComponent {
  @Input() course: CourseFEM;

  constructor(private _router: Router) {}

  public goToCourse(): void {
    this._router.navigate(['courses', 'learning', this.course?.id]);
  }
}
