import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { get } from 'lodash';
import { faSpinner, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-org-finder-dialog',
  templateUrl: './org-finder.dialog.component.html',
  styleUrls: ['./org-finder.dialog.component.scss'],
})
export class OrgFinderComponent implements OnInit {
  public orgName: string;
  public organization: any;
  public loading = false;
  public searchComplete = false;

  public readonly loadingIcon: IconDefinition = faSpinner;

  constructor(
    public dialogRef: MatDialogRef<OrgFinderComponent>,
    private confirm: ConfirmationService,
    private _rest: RestAPIService,
    private _snack: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {}

  public async findOrganization() {
    this.loading = true;

    if (!this.orgName) {
      this._snack.open(`The organization name field is empty`, 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });

      return;
    }

    const response = await this._rest.get('organization/' + this.orgName.replace(' ', '').toLowerCase(), {
      params: new HttpParams().set('errorType', 'hidden'),
      msg: '',
    });

    this.loading = false;
    this.searchComplete = true;

    if (get(response, 'organization')) {
      this.organization = response.organization;
    }
  }

  public redirectToUrl(subdomain: string) {
    const url = `https://${subdomain}.ls.works/`;

    window.open(url, '_self');
  }

  public redirectToLsWorks() {
    const url = `https://ls.works/`;

    window.open(url, '_self');
  }

  public resetOrganization() {
    this.organization = undefined;
    this.searchComplete = false;
  }
}
