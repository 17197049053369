import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { RestAPIService } from '../../services/rest/rest-api.service';
import { TutorialModalModule } from '../../shared/components/tutorial-modal/tutorial-modal.module';
import { UserSideMenuModule } from '../../shared/components/user-side-menu/user-side-menu.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StudentsComponent } from './students.component';
import { StudentProfileComponent } from './student-profile/student-profile.component';
import { MaterialComponentsModule } from 'src/app/shared/modules/material-components.module';
import { LayoutComponentsModule } from 'src/app/shared/components/layout/layout-components.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { StudentResultsComponent } from './student-results/student-results.component';
import { StudentProgramsComponent } from './student-programs/student-programs.component';
import { DialogsModule } from 'src/app/shared/dialogs/dialogs.module';
import { ArchiveDialogComponent } from './student-profile/archive-dialog/archive.dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { CalendarDialogComponent } from './student-profile/calendar-dialog/calendar.dialog';
import { DateChangerDialogComponent } from './student-profile/calendar-dialog/date-changer-dialog/date-changer-dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { DateAdapter, CalendarModule } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { StudentOverrideDialogComponent } from './student-programs/student-override-dialog/student-override-dialog.component';
import { LayoutsModule } from 'src/app/shared/layouts/layouts.module';

@NgModule({
  declarations: [
    StudentsComponent,
    StudentProfileComponent,
    StudentProgramsComponent,
    StudentResultsComponent,
    ArchiveDialogComponent,
    CalendarDialogComponent,
    DateChangerDialogComponent,
    StudentOverrideDialogComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    HttpClientModule,
    TutorialModalModule,
    UserSideMenuModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialComponentsModule,
    LayoutComponentsModule,
    FontAwesomeModule,
    DialogsModule,
    MatDatepickerModule,
    MatRadioModule,
    MatButtonModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatTooltipModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    LayoutsModule,
  ],
  providers: [RestAPIService],
})
export class StudentsModule {}
