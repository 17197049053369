<div *ngFor="let info of data.scoresArray; let i = index">
  <table
    mat-table
    [dataSource]="createDataSource()"
    class="mat-elevation-z8"
  >
    <ng-container
      *ngFor="let column of info.colums; let i = index"
      [matColumnDef]="column"
    >
      <th
        class="text-center {{ column }}"
        mat-header-cell
        *matHeaderCellDef
      >
        {{ removeExerciseIndex(column) | titlecase }}
      </th>

      <td
        class="text-center {{ column }}"
        mat-cell
        *matCellDef="let element"
      >
        {{ getColumValue( info.exerciseScores[column] )}}
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="info.colums"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: info.colums;"
    ></tr>
  </table>
</div>
