import { B2cPaymentTypes } from '../../users/components/adjust-b2c-prices/constants/b2c-prices.constants';
import { PricingBundle } from '../interfaces/pricing-bundle.interface';

export const defaultResellerBundles: PricingBundle[] = [
  {
    id: '2',
    title: 'Neuralign certification',
    price: 250,
    studentsIncluded: 0,
    certification: true,
    order: 0,
  },
  {
    id: '3',
    title: 'Single',
    price: 750,
    studentsIncluded: 1,
    currency: 'CAD',
    order: 1,
  },
  {
    id: '4',
    title: 'Basic subscription',
    price: 650,
    yearlyPrice: 6240,
    studentsIncluded: 1,
    subscription: true,
    interval: 'month',
    currency: 'CAD',
    order: 2,
  },
  {
    id: '5',
    title: 'Pro subscription',
    price: 1800,
    yearlyPrice: 17280,
    studentsIncluded: 3,
    subscription: true,
    interval: 'month',
    currency: 'CAD',
    order: 3,
  },
  {
    id: '6',
    title: 'Premium subscription',
    price: 2775,
    yearlyPrice: 26640,
    studentsIncluded: 5,
    subscription: true,
    interval: 'month',
    currency: 'CAD',
    order: 4,
  },
];

export const defaultB2CBundles: PricingBundle = {
  id: '1',
  title: 'One Time',
  price: 350,
  studentsIncluded: 1,
  currency: 'CAD',
};

export const b2cMonthlyBundle: PricingBundle = {
  id: '2',
  title: 'Monthly',
  price: defaultB2CBundles.price / 3,
  studentsIncluded: 1,
  currency: 'CAD',
  interval: 'month',
  subscription: true,
  cancelOnEndDate: true,
  paymentType: B2cPaymentTypes.SplittedPayment,
};

export const b2cWeeklyBundle: PricingBundle = {
  id: '3',
  title: 'Weekly',
  price: defaultB2CBundles.price / 12,
  studentsIncluded: 1,
  currency: 'CAD',
  interval: 'week',
  subscription: true,
  cancelOnEndDate: true,
  paymentType: B2cPaymentTypes.SplittedPayment,
};

export const yearlySubscriptionBundles: PricingBundle[] = [
  {
    id: '4',
    title: 'Basic subscription',
    price: 6240,
    studentsIncluded: 12,
    subscription: true,
    interval: 'year',
    currency: 'CAD',
  },
  {
    id: '5',
    title: 'Pro subscription',
    price: 17280,
    studentsIncluded: 36,
    subscription: true,
    interval: 'year',
    currency: 'CAD',
  },
  {
    id: '6',
    title: 'Premium subscription',
    price: 26640,
    studentsIncluded: 60,
    subscription: true,
    interval: 'year',
    currency: 'CAD',
  },
];

export const singlePurchasePrice = 750;
