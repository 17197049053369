import { Component, Input } from '@angular/core';

@Component({
  selector: 'top-heading',
  templateUrl: './top-heading.component.html',
  styleUrls: ['./top-heading.component.scss'],
})
export class TopHeadingComponent {
  @Input() label: string;
  @Input() hideBackButton: boolean = false;
}
