import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface EventModel {
  title: 'string';
  start: Date;
}

@Component({
  selector: 'app-date-changer-dialog',
  templateUrl: './date-changer-dialog.html',
  styleUrls: ['./date-changer-dialog.scss'],
})
export class DateChangerDialogComponent implements OnInit {
  public programDate: any;
  public today = new Date();

  constructor(
    public dialogRef: MatDialogRef<DateChangerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EventModel,
  ) {}

  ngOnInit(): void {
    this.programDate = this.data.start;
  }

  public submit() {
    this.dialogRef.close(this.programDate);
  }
}
