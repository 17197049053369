export class TutorialComponent {
  tutorialPages: TutorialPage[];
  constructor(_tutorialPages: TutorialPage[]) {
    this.tutorialPages = _tutorialPages;
  }
}

export interface TutorialPage {
  tutorialInfo: TutorialInfo[];
}

export interface TutorialInfo {
  title: string;
  information: string;
  image?: string;
  videoId?: string;
  videoUrl?: string;

  expanded?: boolean;
}
