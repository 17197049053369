import { get } from 'lodash';
import { Component, OnInit } from '@angular/core';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCheck, faPlus, faMinus, faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons';
import { StripeCurrency, StripeDecimalMultiplier } from 'src/app/shared/interfaces/Stripe.interfaces';

@Component({
  selector: 'app-coupon-user',
  templateUrl: './coupon-user.component.html',
  styleUrls: ['./coupon-user.component.scss'],
})
export class CouponUserComponent implements OnInit {
  public readonly plusLine: IconDefinition = faPlus;
  public readonly minusLine: IconDefinition = faMinus;
  public readonly spinnerIcon: IconDefinition = faSpinner;
  public readonly checkIcon: IconDefinition = faCheck;
  public readonly trashIcon: IconDefinition = faTrash;

  public showCouponCodeOnly = true;
  public selectedUserType = 'Clients';
  public clients: any[] = [];
  public organizations: any[] = [];
  public suggestions: any[] = [];
  public usersToCoupon: any[] = [];
  public discountAmount = null;
  public discountPercentage = null;
  public selectedPractice = '';
  public couponCode = '';
  public limitUsage;
  public selectedUser;
  public nameFilter = '';
  public deletingCoupon = '';
  public expiry = '';
  public globalCoupons = [];
  public isFocused: any;

  constructor(private _rest: RestAPIService) {}

  async ngOnInit() {
    let req = await this._rest.get('admin/users', { msg: 'Could not get admin/users.' });
    this.clients = req.users.filter((f) => !!f.patron);
    this.organizations = req.users.filter((f) => !!f.organization);
    req = await this._rest.get('admin/globalCoupons', { msg: 'Could not get admin/globalCoupons.' });
    this.globalCoupons = req.coupons;
    this.deletingCoupon = '';
    this.getCouponsList();
  }

  public async deleteCoupon(coupon) {
    this.deletingCoupon = coupon.id;
    await this._rest.delete('admin/coupon/' + coupon.id, { msg: 'Could not delete coupon.' });
    this.ngOnInit();
  }

  public getCouponsList() {
    let userCouponList = [];
    if (this.selectedUserType === 'Clients') {
      userCouponList = [].concat(this.clients);
    } else {
      userCouponList = [].concat(this.organizations);
    }
    if (this.showCouponCodeOnly) {
      return userCouponList.filter((u) => u.coupons).filter((u) => u.coupons.length > 0);
    } else {
      return userCouponList;
    }
  }

  public updateSuggestions(value) {
    if (value.length === 0) {
      return (this.suggestions = []);
    }
    value = value.toLowerCase();
    this.suggestions = [].concat(
      this.clients.filter(
        (v) =>
          (get(v, 'patron.givenName') && get(v, 'patron.givenName').toLowerCase().indexOf(value) >= 0) ||
          (get(v, 'patron.familyName') && get(v, 'patron.familyName').toLowerCase().indexOf(value) >= 0),
      ),
      this.organizations.filter(
        (v) => get(v, 'organization.name') && get(v, 'organization.name').toLowerCase().indexOf(value) >= 0,
      ),
    );
    this.suggestions.forEach((sug, index) => {
      if (this.usersToCoupon.some((u) => u === sug)) {
        this.suggestions.splice(index, 1);
      }
    });
  }

  public addUserCoupon(user) {
    if (this.usersToCoupon.some((u) => u === user)) {
      return;
    }
    this.usersToCoupon.push(user);
  }

  public removeUserCoupon(user) {
    this.usersToCoupon.splice(this.usersToCoupon.indexOf(user), 1);
  }

  public addAllClientsCoupon() {
    this.clients.forEach((c) => {
      if (this.usersToCoupon.some((u) => u === c)) {
        return;
      }
      this.usersToCoupon.push(c);
    });
  }

  public addAllOrganizationsCoupon() {
    this.organizations.forEach((o) => {
      if (this.usersToCoupon.some((u) => u === o)) {
        return;
      }
      this.usersToCoupon.push(o);
    });
  }

  public clearUsersCoupom() {
    this.selectedPractice = '';
    this.expiry = '';
    delete this.limitUsage;
    this.couponCode = '';
    this.discountPercentage = null;
    this.discountAmount = null;
    this.usersToCoupon = [];
  }

  public isValidCoupon() {
    if (this.selectedPractice !== 'amount' && this.selectedPractice !== 'percentage') {
      return false;
    }
    if (!this.discountAmount && !this.discountPercentage) {
      return false;
    }
    if (this.couponCode.length < 3) {
      return false;
    }
    if (typeof this.limitUsage !== 'number' || this.limitUsage < 0) {
      return false;
    }
    return true;
  }

  public generateCouponCode() {
    this.couponCode = '';
    while (this.couponCode.length < 8) {
      this.couponCode += Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '')
        .substr(0, 8);
    }
    this.couponCode = this.couponCode.substr(0, 8).toUpperCase();
  }

  public async createCoupon() {
    await this._rest.post(
      'admin/coupon',
      {
        coupon: {
          code: this.couponCode,
          discountAmount: this.selectedPractice === 'amount' ? this.discountAmount : 0,
          discountPercentage: this.selectedPractice === 'percentage' ? this.discountPercentage : 0,
          limit: this.limitUsage,
          expiration: this.expiry ? new Date(this.expiry).toString() : undefined,
        },
        users: this.usersToCoupon,
      },
      { msg: 'Could not post coupon' },
    );

    //Insert the new coupon into the stripe coupons list
    const userIds = this.usersToCoupon.map((user) => user.id);
    const organizationId = userIds.length > 0 ? userIds : [];

    await this._rest.post('stripe/coupon', {
      id: this.couponCode,
      ...(this.selectedPractice === 'amount'
        ? {
            amount_off: this.discountAmount * StripeDecimalMultiplier.Hundred,
            currency: StripeCurrency.CAD,
          }
        : {}),
      ...(this.selectedPractice === 'percentage' ? { percent_off: this.discountPercentage } : {}),
      ...(this.limitUsage ? { max_redemptions: this.limitUsage } : {}),
      ...(this.expiry ? { redeem_by: this.expiry } : {}),
      ...(organizationId.length > 0 ? { metadata: { organizationId: organizationId } } : {}),
    });
    this.clearUsersCoupom();
    this.ngOnInit();
  }

  public formatPrice(price) {
    let p = (price || 0).toString();
    if (p.indexOf('.') < 0) {
      p += '.';
    }
    while (p.split('.')[1].length < 2) {
      p += '0';
    }
    return p;
  }
}
