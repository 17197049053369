import { Component } from '@angular/core';

/**
 * @scope Lays out a responsive grid that holds course cards for the courses list page.
 */
@Component({
  selector: 'courses-list-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
})
export class GridComponent {}
