import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '../../shared/components/confirmation-modal/confimation-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationService {
  constructor(private dialog: MatDialog) {}

  public registerConfirmation(title: string, text: string, closeLink = '') {
    return this.createConfirmation(title, text, undefined, undefined, '400px', true, closeLink);
  }

  public createConfirmation(
    title: string,
    text: string,
    confirm: string,
    cancel: string,
    width = '400px',
    disableClose = false,
    closeLink = '',
    panelClass = '',
  ): Promise<boolean> {
    const confirmationDialog = this.dialog.open(ConfirmationModalComponent, {
      width,
      disableClose,
      panelClass,
      data: {
        titleInput: title,
        textInput: text,
        confirmInput: confirm,
        cancelInput: cancel,
        closeButtonRoute: closeLink,
      },
    });
    return new Promise((resolve, reject) => {
      confirmationDialog.afterClosed().subscribe((hasConfirmed) => {
        if (hasConfirmed) {
          resolve(true);
        } else {
          reject();
        }
      });
    });
  }
}
