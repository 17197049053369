import { includes } from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SafeResourceUrl } from '@angular/platform-browser';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Component, EventEmitter, Inject, OnInit, Output, AfterViewInit, ViewChild, ElementRef } from '@angular/core';

import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { allowedOrgsTypes, MessageButton, Messages, userTypes } from 'src/app/shared/interfaces/Message.interface';
import { AllowedUsersDialog } from '../../configuration-pages/course-configurations/components/learning-management-system/allowed-users-dialog/allowed-users-dialog.component';
import { MessagesService } from '../messages.service';
import { UserResponse } from 'src/app/shared/models';

declare let cloudinary: any;

@Component({
  selector: 'app-messages-modal',
  templateUrl: './messages-modal.component.html',
  styleUrls: ['./messages-modal.component.scss'],
})
export class MessagesModalComponent implements OnInit, AfterViewInit {
  // Component properties and decorators
  @Output() newMessage = new EventEmitter<Messages>();
  @ViewChild('selectedOrgsPaginator') selectedOrgsPaginator!: MatPaginator;
  @ViewChild('usersReadPaginator') usersReadPaginator!: MatPaginator;
  @ViewChild('selectedOrgsSearchInput') selectedOrgsSearchInput: ElementRef;
  @ViewChild('usersReadSearchInput') usersReadSearchInput: ElementRef;

  // Configuration properties
  loading: boolean;
  displayedColumns: string[] = ['id', 'name', 'messageReadDate'];
  selectedOrgsDataSource: MatTableDataSource<any>;
  usersReadDataSource: MatTableDataSource<any>;
  readonly PAGE_SIZE = 3;
  readonly PAGE_SIZE_OPTIONS = [3, 6, 10];
  modalType: string;

  // Form and data properties
  messageForm: Messages = {
    text: '',
    image: '',
    allowedUsers: [],
    mediaType: 'image',
    video: '',
    isRead: false,
    createdAt: new Date(),
    organizationId: '',
  };

  allowedOrgs = [];
  allowedReadOrgs = [];
  usersRead: UserResponse[] = [];
  safeMessageVideoUrl: SafeResourceUrl;
  selectedHowToSend: string;
  organizations: UserResponse[];

  // Readonly properties
  readonly userTypes = userTypes;
  readonly allowedOrgsTypes = allowedOrgsTypes;
  readonly cloudinaryConfig = {
    cloud_name: environment.CLOUDINARY_CLOUD_NAME,
    upload_preset: environment.CLOUDINARY_UPLOAD_PRESET,
    secure: true,
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Messages,
    private dialogRef: MatDialogRef<MessagesModalComponent>,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private restService: RestAPIService,
    private messagesService: MessagesService,
  ) {
    this.selectedOrgsDataSource = new MatTableDataSource([]);
    this.usersReadDataSource = new MatTableDataSource([]);
  }

  // Lifecycle hooks
  async ngOnInit() {
    await this.loadData();
  }

  ngAfterViewInit() {
    if (!this.loading) {
      this.initializePaginators();
      this.selectedOrgsDataSource.filterPredicate = this.createFilter();
      this.usersReadDataSource.filterPredicate = this.createFilter();
    }
  }

  // Initialization methods
  private initializePaginators() {
    if (this.selectedOrgsPaginator) {
      this.selectedOrgsDataSource.paginator = this.selectedOrgsPaginator;
      this.selectedOrgsPaginator.pageSize = this.PAGE_SIZE;
      this.selectedOrgsPaginator.pageSizeOptions = this.PAGE_SIZE_OPTIONS;
    }

    if (this.usersReadPaginator) {
      this.usersReadDataSource.paginator = this.usersReadPaginator;
      this.usersReadPaginator.pageSize = this.PAGE_SIZE;
      this.usersReadPaginator.pageSizeOptions = this.PAGE_SIZE_OPTIONS;
    }
  }

  async loadData() {
    try {
      this.loading = true;
      const { type } = this.data as MessageButton;
      this.modalType = type;
      await this.getOrganizations();
      if (this.data?.data) {
        await this.initializeData();
      }
      this.initializePaginators();
    } finally {
      this.loading = false;
    }
  }

  private async getOrganizations() {
    const organizations = await this.restService.get('organization/all');
    this.organizations = organizations;
  }

  private async initializeData(): Promise<void> {
    const { data } = this.data;
    this.messageForm = {
      ...this.messageForm,
      text: data.text,
      allowedUsers: data.allowedUsers,
      mediaType: data.mediaType || 'image',
      image: data.mediaType === 'image' ? data.image : '',
      video: data.mediaType === 'video' ? data.video : '',
      organizationId: data.organizationId,
    };

    if (this.messageForm.video) {
      this.updateSafeUrl();
    }
    this.allowedReadOrgs = data.organizationId ? this.prepareOrgToDisplay(data.organizationId) : [];
    this.usersRead = await this.getReadMessages(data.id);
  }

  // Data preparation methods
  private prepareOrgToDisplay(orgsId: string) {
    const orgsStringToArray = orgsId.includes(',') ? orgsId.split(',') : [orgsId];
    const orgs = this.organizations;
    const matchedOrgs = orgs.filter((org) => orgsStringToArray.includes(org.organization.id));
    this.selectedOrgsDataSource.data = matchedOrgs;
    return matchedOrgs;
  }

  private async getReadMessages(messageId: string): Promise<UserResponse[]> {
    try {
      const users = await this.restService.get(`message/read/id/${messageId}`);
      const userIds = users.map((user) => user.accountId);
      const organizations = this.organizations;
      const filteredOrgs = organizations
        .filter((org) => userIds.includes(org.id))
        .map((org) => {
          const user = users.find((user) => user.accountId === org.id);
          return {
            ...org,
            messageReadDate: user ? user.createdAt : null,
          };
        });
      this.usersReadDataSource.data = filteredOrgs;
      return filteredOrgs;
    } catch (error) {
      this.showError('Error fetching users who read the message');
      return [];
    }
  }

  // Button Functions
  async save() {
    const { type } = this.data as MessageButton;

    if (type === 'PUT') {
      await this.update();
    } else {
      await this.create();
    }
  }

  async create() {
    try {
      const draftMessage = {
        ...this.messageForm,
        organizationId: this.allowedOrgs ? this.prepareOrgToSend(this.allowedOrgs) : '',
      };
      const createdMessage: Messages = await this.restService.post('message', draftMessage);
      this.data = createdMessage;
      this.newMessage.emit(this.data);
      this.close();
    } catch (error) {
      this.showError('Could not save the message');
    }
  }

  async update() {
    const messageId = this.data?.data?.id;
    const draftMessage = {
      ...this.messageForm,
      organizationId: this.allowedOrgs ? this.prepareOrgToSend(this.allowedOrgs) : '',
    };

    try {
      if (messageId) {
        await this.restService.put(`message/id/${messageId}`, draftMessage);
        this.data.data = { ...draftMessage, id: messageId };
        this.close();
      }
    } catch (error) {
      this.showError('Could not update the message');
    }
  }

  public close() {
    this.dialogRef.close();
  }

  // Image upload function
  addMessageImage(): void {
    cloudinary.openUploadWidget(this.cloudinaryConfig, (error, result) => {
      if (result) {
        const [imageResponse] = result;
        this.messageForm.image = imageResponse.secure_url || imageResponse.url;
      } else if (error?.message !== 'User closed widget') {
        this.showError('Error uploading the image');
      }
    });
  }

  // Dialog functions
  async openAllowedOrgsDialog(): Promise<void> {
    const dialogRef = this.dialog.open(AllowedUsersDialog, {
      width: '600px',
      height: '650px',
      disableClose: true,
      data: this.data,
      panelClass: 'modal-border',
    });

    dialogRef.afterClosed().subscribe(async (shouldSave: boolean) => {
      if (shouldSave) {
        await this.updateAllowedOrganizations(dialogRef.componentInstance.data.allowedOrganizations);
      }
    });
  }

  private async updateAllowedOrganizations(selectedOrgs: string[]): Promise<void> {
    try {
      const organizations = this.organizations;
      const filteredOrgs = organizations.filter((org) => selectedOrgs.includes(org.organization.id));
      this.allowedOrgs = filteredOrgs;
      this.selectedOrgsDataSource.data = filteredOrgs;
    } catch (error) {
      this.showError('Error updating allowed organizations');
    }
  }

  // Utility functions
  public prepareOrgToSend(orgs: UserResponse[]) {
    const orgsId = orgs.map((org) => org.organization.id);
    const matchedOrgs = this.organizations.filter((org) => orgsId.includes(org.organization.id));
    const filteredOrgsId = matchedOrgs.map((org) => org.organization.id);
    return filteredOrgsId.join(',');
  }

  public updateSafeUrl(): void {
    const videoUrl = this.messageForm.video;
    const result = this.messagesService.updateSafeUrl(videoUrl);
    this.safeMessageVideoUrl = result;
  }

  private showError(message: string): void {
    this.snackBar.open(message, 'OK', { duration: 5000 });
  }

  // Getter and checker functions
  getUserTypes(): string[] {
    return Object.keys(this.userTypes);
  }

  getAllowedOrgsTypes(): string[] {
    return Object.keys(this.allowedOrgsTypes);
  }

  checkUserType(user: string): boolean {
    return includes(this.messageForm.allowedUsers, user);
  }

  // User and organization management functions
  addAllowedUser(user: string): void {
    const index = this.messageForm.allowedUsers.indexOf(user);
    if (index !== -1) {
      this.messageForm.allowedUsers.splice(index, 1);
    } else {
      this.messageForm.allowedUsers.push(user);
    }
  }

  isAllowedOrgs(userType: string): boolean {
    return this.allowedOrgs.includes(userType);
  }

  async addAllowedOrgs(user: string): Promise<void> {
    if (this.allowedOrgs.includes(user)) {
      this.allowedOrgs = this.allowedOrgs.filter((u) => u !== user);
    } else {
      this.allowedOrgs = [user];
      if (user === 'manually') {
        this.selectedHowToSend = 'manually';
        await this.openAllowedOrgsDialog();
      }
    }
  }

  //SearchBar
  public createFilter(): (data: UserResponse[], filter: string) => boolean {
    const filterFunction = function (data, filter): boolean {
      return (
        data?.organization?.name.toLowerCase().indexOf(filter) !== -1 ||
        data?.organization?.id.toLowerCase().indexOf(filter) !== -1
      );
    };
    return filterFunction;
  }

  public applyFilterSelectedOrgs(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.selectedOrgsDataSource.filter = filterValue.trim().toLowerCase();

    if (this.selectedOrgsDataSource?.paginator) {
      this.selectedOrgsDataSource.paginator.firstPage();
    }
  }

  public applyFilterUsersRead(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.usersReadDataSource.filter = filterValue.trim().toLowerCase();

    if (this.usersReadDataSource?.paginator) {
      this.usersReadDataSource.paginator.firstPage();
    }
  }
}
