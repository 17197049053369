<br />

<h3>{{ sequence }}. {{ lesson.title }}</h3>

<ng-container *ngFor="let section of lesson.sections; index as i">
  <course-overview-section-item
    [section]="section"
    [sequence]="i + 1"
  ></course-overview-section-item>
</ng-container>
