<ng-container *ngTemplateOutlet="getTemplate()"></ng-container>

<ng-template #active>
  <span class="active">Active</span>
</ng-template>

<ng-template #notActive>
  <span class="not-active">Not Active</span>
</ng-template>

<ng-template #error>
  <span
    class="error"
    [matTooltip]="errorTooltip"
    [matTooltipPosition]="'above'"
    >Error</span
  ><fa-icon
    class="icon"
    [icon]="questionMarkIcon"
  ></fa-icon>
</ng-template>
