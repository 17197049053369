export const data = {
  intro: {
    title: 'LSWorks is a not for profit educational software company.',
    mission:
      'Our mission is to provide high tech, research based learning interventions for individuals with learning disabilities or difficulties in a way that is broadly accessible both logistically and financially.',
  },

  foundedBy: {
    paragraphs: [
      'LSWorks was founded by a team of dedicated individuals wanting to provide a viable and cost-effective solution for overcoming the challenges of learning to read. Understanding and personally experiencing reading disabilities made the team very aware that learning differences have a real impact on both school and lifetime success.',
      'LSWorks’ introductory program, Neuralign, is a high-end cognitive therapy program which targets the neural pathways required for reading acquisition.',
    ],
  },

  ingridsStory: {
    title: 'The Personal Story of Neuralign Co-Founder Ingrid Poupart',
    paragraphs: [
      '“The first key to teaching dyslexic learners to read is hope, ” says Ingrid Poupart, the co-founder and heart and soul of LSWorks. As a dyslexic learner herself, Ingrid understands firsthand the everyday obstacles and challenges struggling readers face. Like so many other students with disabilities, Ingrid was never diagnosed nor treated. Instead, she was left to fend for herself in getting through the academic hurdles presented each day at school. “Homework that would take others a half hour to do took me hours, ” she says. Unlike many young students with dyslexia, Ingrid was lucky to realize that she possessed other skills and abilities. She recognized that her dyslexia did not equate to a lack of intelligence.',
      'The seeds of today’s Neuralign were sown when Ingrid’s youngest child was diagnosed with dyslexia. Ingrid then started investigating the causes of reading deficiencies in the brain, and learnt that fluent readers used efficient pathways while struggling readers were using inefficient pathways to do the same task. 25 years, and several certifications later, the Neuralign program was born. Ingrid knew that by building fun and engaging games, children would be less reluctant and more inspired to complete the program, which would work on higher-order thinking skills. She focused on a research based model to help teach the skill of reading to dyslexic and struggling readers, like herself.',
    ],
  },
};
