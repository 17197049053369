<div class="container-bg">
  <div
    class="beforeYouStartHolder d-flex justify-content-center"
    [ngClass]="{ isPopup: isPopup }"
  >
    <div>
      <div class="title col-12">
        {{ pages[pageIndex].title | translate }}
      </div>

      <p
        class="close"
        (click)="start()"
      >
        X
      </p>

      <div class="lines col-12">
        <div
          class="line row"
          *ngFor="let line of pages[pageIndex].lines"
        >
          <div class="icon col-3">
            <fa-icon
              [icon]="line.icon"
              *ngIf="line.icon"
            ></fa-icon>
            <img
              src="assets/img/fullscreen.png"
              *ngIf="line.picture && line.picture === 1"
            />
            <div
              class="picture1"
              *ngIf="line.picture && line.picture === 2"
            ></div>
            <div
              class="picture2"
              *ngIf="line.picture && line.picture === 3"
            >
              <span>P</span>
            </div>
          </div>
          <div class="col-9">{{ line.title | translate }}</div>
        </div>
      </div>
      <div class="pageIndexing col-12 mt-auto">
        <div
          class="indexBall"
          *ngFor="let page of pages; let index = index"
          [ngClass]="{ current: pageIndex === index }"
          (click)="pageIndex = index"
        ></div>
      </div>
      <div class="button col-12 mt-auto">
        <button
          *ngIf="pageIndex < pages.length - 1"
          (click)="foward()"
        >
          {{ 'beforeYouStart.gotIt' | translate }}
        </button>
        <button
          *ngIf="pageIndex === pages.length - 1"
          (click)="start()"
        >
          {{ 'beforeYouStart.start' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
