import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Error Handlers
import { GlobalErrorHandler } from './handlers/global-error.handler';
import { ErrorHandlerService } from './services/error-handler.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [{ provide: ErrorHandler, useClass: GlobalErrorHandler }, ErrorHandlerService],
})
export class ErrorHandlerModule {}
