<div class="mainContainer col-12 px-0">
  <div class="header text-center col-12">Categories</div>

  <div class="col-12 px-0 mt-5 dataContainer">
    <div class="title">Bonus pack Icons</div>

    <div class="categoryIcon d-flex justify-content-center align-items-end">
      <div class="mx-2 text-center">
        <img
          [ngClass]="{ placeHolder: imageIsPlaceHolder(data.bonusPack.img) }"
          [src]="data.bonusPack.img || getImagePlaceholder()"
        />

        <div class="my-2 text-center">
          <app-simple-button
            [text]="'Add avaliable icon'"
            (click)="addCategoryImage('img')"
          >
          </app-simple-button>
        </div>
      </div>

      <div class="mx-2 text-center">
        <img
          [ngClass]="{ placeHolder: imageIsPlaceHolder(data.bonusPack.lockedImg) }"
          [src]="data.bonusPack.lockedImg || getImagePlaceholder()"
        />

        <div class="my-2 text-center">
          <app-simple-button
            [text]="'Add locked icon'"
            (click)="addCategoryImage('lockedImg')"
          >
          </app-simple-button>
        </div>
      </div>
    </div>

    <div class="title mt-3">Bonus pack Info</div>

    <div class="info my-3 mx-auto">
      <div class="keyInput m-2">
        <div class="col-4 px-0 d-flex align-items-center">
          <span> Bonus sessions </span>
        </div>

        <div class="mx-2">
          <input
            name="sessions"
            class="text-center basicInput mx-2"
            type="number"
            [(ngModel)]="data.bonusPack.maxSessions"
            autocomplete="off"
          />
        </div>
      </div>

      <div class="keyInput m-2">
        <div class="col-4 px-0 d-flex align-items-center">
          <span> Sessions interval </span>
        </div>

        <div class="mx-2">
          <input
            name="sessionIntervals"
            class="text-center basicInput mx-2"
            type="number"
            [(ngModel)]="data.bonusPack.sessionInterval"
            autocomplete="off"
          />
        </div>
      </div>

      <div class="keyInput m-2">
        <div class="col-4 px-0 d-flex align-items-center">
          <span> Starting session </span>
        </div>

        <div class="mx-2">
          <input
            name="startingSession"
            class="text-center basicInput mx-2"
            type="number"
            [(ngModel)]="data.bonusPack.startingSession"
            autocomplete="off"
          />
        </div>
      </div>
    </div>

    <div class="title my-2">Bonus pack game List</div>

    <table
      mat-table
      [dataSource]="gamesDataSource"
      matSort
      class="col-8 px-0 m-auto"
      multiTemplateDataRows
    >
      <ng-container matColumnDef="games">
        <th
          mat-header-cell
          *matHeaderCellDef
        >
          Game
        </th>
        <td
          mat-cell
          *matCellDef="let game"
        >
          {{ gamesList[game] }}
        </td>
      </ng-container>

      <ng-container matColumnDef="enabled">
        <th
          class="text-center"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Enabled
        </th>
        <td
          class="text-center"
          mat-cell
          *matCellDef="let game"
        >
          <mat-checkbox
            (click)="addGameToList(game)"
            [checked]="isGameEnabled(game)"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="gamesColums"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: gamesColums"
        class="example-element-row"
      ></tr>
    </table>

    <div class="button col-12 px-0 d-flex justify-content-center my-4">
      <app-simple-button
        [text]="'Save bonus pack'"
        (click)="saveBonusPack()"
      >
      </app-simple-button>
    </div>
  </div>
</div>
