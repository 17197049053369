<h2 mat-dialog-title>We use cookies</h2>

<mat-dialog-content>
  <p>
    We use cookies to monitor the performance and analytics of this website. By
    using this website you agree to our use of cookies. For more information you
    can <a routerLink="/privacy-policy">read our privacy policy</a>.
  </p>
</mat-dialog-content>

<mat-dialog-actions>
  <button
    class="autofocus"
    mat-raised-button
    mat-dialog-close
    color="primary"
    (click)="handleManageCookies()"
  >
    Manage
  </button>
  <button
    class="autofocus"
    mat-raised-button
    mat-dialog-close
    color="primary"
    (click)="handleAcceptAll()"
  >
    Accept All
  </button>
</mat-dialog-actions>
