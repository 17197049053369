<div>
  <a
    *ngIf="selected && canGoBack"
    (click)="goBack()"
    class="fa-pull-left pointer"
  >
    <fa-icon [icon]="arrowBack"></fa-icon>
  </a>

  <p
    align="center"
    *ngIf="!selected"
  >
    <strong>Latest Changes</strong>
  </p>
  <p
    align="center"
    *ngIf="selected"
  >
    <strong class="title">{{ selected.title }}</strong>
    <span class="badge badge-{{ getClass(selected.type) }}">
      {{ selected.type }}
    </span>
  </p>
</div>

<div
  *ngIf="!updatesList.length && !selected"
  class="upToDate"
>
  You are up to date!
</div>

<div *ngIf="updatesList.length && !selected">
  <div
    *ngFor="let item of updatesList"
    (click)="clickRow(item)"
    class="row-update"
  >
    <span class="badge badge-{{ getClass(item.type) }}">{{ item.type }}</span>
    <strong class="title">{{ item.title }}</strong>
    <span>{{ limitText(item.description) }}</span>
  </div>
</div>

<div
  align="center"
  *ngIf="!selected"
>
  <br /><br />
  <div class="button-container">
    <button
      (click)="goToLink()"
      mat-raised-button
      color="primary"
      class="p-2"
    >
      Check {{ updatesList.length ? 'all' : 'older' }} updates
    </button>
    <button
      mat-stroked-button
      (click)="markAllRead()"
    >
      Mark All Read
    </button>
  </div>
</div>

<div
  *ngIf="selected"
  class="details"
>
  <div [innerHTML]="selected.description"></div>
</div>
