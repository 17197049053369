<app-main-layout>
  <div class="margin-4 inner-content p-2">
    <h3 align="center">Updates</h3>
    <div
      class="col-12 m-1"
      align="right"
      *ngIf="isPortalAdmin"
    >
      <button
        mat-raised-button
        color="primary"
        class="p-2"
        [routerLink]="'/admin/updates/0'"
      >
        Add new
      </button>
    </div>

    <div
      *ngIf="!updatesList.length"
      class="col-12"
      align="center"
    >
      Please create a new Update!
    </div>

    <table
      class="table col-12"
      *ngIf="updatesList.length"
    >
      <thead>
        <tr>
          <th width="15%">Type</th>
          <th width="15%">Date</th>
          <th [width]="isPortalAdmin ? '55%' : '70%'">Title</th>
          <th
            width="15%"
            *ngIf="isPortalAdmin"
          ></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let update of updatesList">
          <td>
            <span class="badge badge-{{ getClass(update.type) }}">{{
              update.type
            }}</span>
          </td>
          <td>
            {{ update.createdAt | date: 'MM/dd/yyyy hh:mm' }}
          </td>
          <td>
            {{ update.title }}
          </td>
          <td align="center">
            <a
              (click)="goToLink(update)"
              class="click"
            >
              <fa-icon
                *ngIf="isPortalAdmin"
                [icon]="edit"
              ></fa-icon>
              <fa-icon
                *ngIf="!isPortalAdmin"
                [icon]="eye"
              ></fa-icon>
            </a>
            <a
              *ngIf="isPortalAdmin"
              (click)="confirmDelete(update)"
              class="click"
            >
              <fa-icon [icon]="trash"></fa-icon>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</app-main-layout>
