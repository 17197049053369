export enum EditTypes {
  Client = 'Client',
  Admin = 'Organization Admin',
  Manager = 'Organization Manager',
  Organization = 'Organization',
  Students = 'Students',
}

export enum SectionTypes {
  Clients = 'Clients',
  Admins = 'Admins',
  Managers = 'Managers',
  Outsiders = 'Outsiders',
  Students = 'Students',
}

export const returnEditType = (editType) => {
  switch (editType) {
    case EditTypes.Client:
      return SectionTypes.Clients;
    case EditTypes.Admin:
      return SectionTypes.Admins;
    case EditTypes.Manager:
      return SectionTypes.Managers;
    case EditTypes.Organization:
      return SectionTypes.Outsiders;
    case EditTypes.Students:
      return SectionTypes.Students;
  }
};
