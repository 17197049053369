import { Component, OnInit, isDevMode } from '@angular/core';
import { IconDefinition, faBug } from '@fortawesome/free-solid-svg-icons';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { RoleService } from 'src/app/services/roles/role.service';

@Component({
  selector: 'debug-helper',
  templateUrl: './debug-helper.component.html',
  styleUrls: ['./debug-helper.component.scss'],
})
export class DebugHelperComponent implements OnInit {
  private enable = true; // Devs, use this flag to enable/disable the helper

  public user: Record<string, string | boolean> = {};

  public canShowDebugHelper = false;
  public isDebugHelperOpen = false;

  public bug: IconDefinition = faBug;

  constructor(private _roles: RoleService, private _rest: RestAPIService) {}

  ngOnInit(): void {
    if (this.enable) {
      this.getUserInfo();
      this.showDebugHelper();
    }
  }

  private getUserInfo(): void {
    const user = this._roles.user;

    this.user = {
      id: user?.id,
      email: user?.email,
      patron: user?.patron ? 'patron' : 'organization',
      role: user?.role ? user?.role : 'no role assigned',
      isB2cProvider: user?.patron ? false : user?.organization?.isB2CProvider,
      isReseller: user?.patron ? false : user?.organization?.isReseller,
    };
  }

  private showDebugHelper(): void {
    const isDevEnvironment =
      window.location.href.includes('dev') || window.location.href.includes('localhost') || isDevMode();

    this.canShowDebugHelper = isDevEnvironment && this._roles.isLogged();
  }

  // Click Handlers --

  public openDebugHelper(): void {
    this.isDebugHelperOpen = !this.isDebugHelperOpen;
  }

  public copy(value): void {
    navigator.clipboard.writeText(value);
  }

  public throwTestError() {
    throw Error('A test error had been thrown.');
  }

  public throwTestHttpError() {
    return this._rest.get('www.notarealwebsite.com/this-should-error');
  }
}
