import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Token } from 'src/app/shared/models';

interface TokenDeleteData {
  avaliableTokens: Token[];
  maxTokensToDelete: number;
}

@Component({
  selector: 'app-delete-token',
  templateUrl: './delete-token.component.html',
  styleUrls: ['./delete-token.component.scss'],
})
export class DeleteTokenComponent implements OnInit {
  public quantity = 1;

  constructor(
    public dialogRef: MatDialogRef<DeleteTokenComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TokenDeleteData,
    private _snackBar: MatSnackBar,
  ) {}

  ngOnInit() {}

  public confirm() {
    if (this.quantity > this.data.maxTokensToDelete) {
      this._snackBar.open('The amount of deleted tokens must be less than the current organization tokens', 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });

      return;
    }

    this.dialogRef.close(this.quantity);
  }

  public cancel() {
    this.dialogRef.close(false);
  }
}
