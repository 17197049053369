import { get } from 'lodash';
import { Subscription } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { IconDefinition, faSpinner, faSyncAlt } from '@fortawesome/free-solid-svg-icons';

import { Token } from 'src/app/shared/models';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RoleService } from 'src/app/services/roles/role.service';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { StudentsListService } from 'src/app/pages/users/menus/clients-menu/students-list/students-list.service';
import { OrganizationsListService } from 'src/app/pages/users/menus/organizations-menu/organizations-list/organizations-list.service';
import { TokenService } from 'src/app/services/token/token.service';

@Component({
  selector: 'header-credits',
  templateUrl: './credits.component.html',
  styleUrls: ['./credits.component.scss'],
})
export class CreditsComponent implements OnInit {
  public credits: number;

  public isOrgClient: boolean = this._roles.isOrgClient();
  public isB2CPortal: boolean = this._roles.isB2CPortal();

  public loading: IconDefinition = faSpinner;
  public refresh: IconDefinition = faSyncAlt;

  private subscription: Subscription;
  private refreshSubscription: Subscription;

  constructor(
    private _roles: RoleService,
    private _rest: RestAPIService,
    private _snackBar: MatSnackBar,
    private _logger: LoggerService,
    private _studentsListService: StudentsListService,
    private _organizationListService: OrganizationsListService,
    private tokenService: TokenService,
  ) {}

  // Lifecycle --

  async ngOnInit(): Promise<void> {
    await this.getCredits();

    this.subscribeEvents();
  }

  public subscribeEvents() {
    this.subscription = this._studentsListService.studentAssociated.subscribe(async () => {
      await this.getCredits();
    });

    this.refreshSubscription = this._organizationListService.refreshCredits.subscribe(async () => {
      await this.getCredits();
    });

    // TODO use only this method to refresh credits anywhere on the project
    this.tokenService.refreshCredits.subscribe(async () => {
      await this.getCredits();
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }

  // Handlers --

  public async handleRefreshCredits(): Promise<void> {
    await this.getCredits();

    // Notify the user that credits have been updated
    this._snackBar.open(`Credits updated succesfully!`, 'Close', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: 2000,
    });
  }

  // Credits --

  public async getCredits(): Promise<void> {
    // reset credits
    this.credits = undefined;

    // check for updated amount of credits
    await this.checkTokensToRecieve();

    // get tokens
    const tokens = await this._requestTokens();
    this.tokenService.setTokens(tokens);

    // filter out spent tokens
    if (tokens) {
      this.credits = this._filterSpentTokens(tokens);
    } else {
      this.credits = 0;
    }
  }

  private async _requestTokens(): Promise<Token[]> {
    const response = await this._rest.get('token/self', { msg: 'Could not get token.' });
    const tokens = get(response, 'tokens', []);
    return tokens;
  }

  private _filterSpentTokens(tokens: Token[]): number {
    const spentTokens = tokens.filter((t) => !t.studentId && t.paymentConfirmed).length;
    const unspentTokens = tokens.filter((t) => t.studentId && !t.paymentConfirmed).length;
    return spentTokens - unspentTokens;
  }

  public async checkTokensToRecieve(): Promise<void> {
    // B2C Portals and Clients don't have subscriptions so we don't need to check for tokens
    if (this.isB2CPortal || this.isOrgClient) {
      return;
    }

    try {
      await this._rest.get('subscriptions/checkTokensToReceive', {
        msg: 'Could not get subscriptions/checkTokensToReceive.',
      });
    } catch (error) {
      this._logger.error(error);
    }
  }
}
