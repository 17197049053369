<div class="header-container">
  <header>
    <img
      [src]="logo"
      width="100%"
    />
  </header>

  <back-to-portal-button
    (navagateToPortal)="handleNavagateToPortal()"
  ></back-to-portal-button>
</div>
