import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { HeaderModule } from '../components/header/header.module';
import { SideMenuModule } from '../components/side-menu/side-menu.module';
import { BreadcrumbModule } from '../components/breadcrumb/breadcrumb.module';
import { PublicLayoutComponent } from './public-layout/public-layout.component';
import { FooterModule } from '../components/footer/footer.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PublicMessageBannerModule } from 'src/app/pages/public-message-banner/public-message-banner.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [MainLayoutComponent, PublicLayoutComponent],
  imports: [
    CommonModule,
    HeaderModule,
    FooterModule,
    SideMenuModule,
    BreadcrumbModule,
    FontAwesomeModule,
    PublicMessageBannerModule,
    BrowserAnimationsModule,
  ],
  exports: [MainLayoutComponent, PublicLayoutComponent],
})
export class LayoutsModule {}
