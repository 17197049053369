import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { MaterialComponentsModule } from 'src/app/shared/modules/material-components.module';
import { TutorialModalComponent } from './tutorial-modal.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { SafeUrlModule } from '../../pipes/safe-url/safe-url.module';
import { LayoutComponentsModule } from '../layout/layout-components.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [TutorialModalComponent],
  imports: [
    BrowserModule,
    MaterialComponentsModule,
    ClickOutsideModule,
    SafeUrlModule,
    LayoutComponentsModule,
    FontAwesomeModule,
  ],
  exports: [TutorialModalComponent],
})
export class TutorialModalModule {}
