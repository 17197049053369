<ng-container
  *ngIf="roles.isLogged(); then showLogoutMessage; else showForm"
></ng-container>

<ng-template #showLogoutMessage>
  <div class="margin">
    <p>Please log out in order to use this feature.</p>
    <button (click)="logout()">Logout</button>
  </div>
</ng-template>

<ng-template #showForm>
  <app-public-warning-message-banner></app-public-warning-message-banner>

  <form
    [formGroup]="form"
    (ngSubmit)="submit()"
    class="margin"
  >
    <div class="control">
      <mat-checkbox formControlName="toggle">
        Toggle public message banner
      </mat-checkbox>
    </div>

    <div class="control">
      <label for="message-input">Message:</label>
      <textarea
        id="message-input"
        class="textarea"
        matInput
        rows="8"
        cols="60"
        formControlName="text"
      ></textarea>
    </div>

    <div class="control">
      <label for="password-input">Password:</label>
      <input
        matInput
        id="password-input"
        type="password"
        formControlName="password"
      />
    </div>

    <button
      type="submit"
      [disabled]="!form.dirty"
    >
      Save
    </button>
  </form>
</ng-template>
