import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { IconDefinition, faShoppingCart, faCheck } from '@fortawesome/free-solid-svg-icons';
import { PricingBundle } from 'src/app/pages/pricing/interfaces/pricing-bundle.interface';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { get } from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-input-bundle-card',
  templateUrl: './input-bundle-card.component.html',
  styleUrls: ['./input-bundle-card.component.scss'],
})
export class InputBundleCardComponent implements OnInit {
  @Output() saveEvent = new EventEmitter<any>();
  @Output() deleteEvent = new EventEmitter<any>();
  @Input() bundle: PricingBundle;
  @Input() index: number;
  @Input() outsiderId: string;
  @Input() subscriptionList: any;
  @Input() allowSubscription: boolean;

  public allowedFieldsWithHiddenPrice = ['title', 'hiddenPrice', 'price'];
  public checkIcon: IconDefinition = faCheck;
  public bundleFormGroup: UntypedFormGroup;
  public cartIcon: IconDefinition = faShoppingCart;
  public amount: number;
  public id: string;
  public allowSave = false;

  constructor(public dialog: MatDialog, protected _snackBar: MatSnackBar) {}

  ngOnInit() {
    this.setBundleDefaultProperties();

    this.id = this.bundle.id;
    this.bundleFormGroup = new UntypedFormGroup({
      title: new UntypedFormControl('', [Validators.required]),
      fullPrice: new UntypedFormControl('', [Validators.required]),
      price: new UntypedFormControl({ value: '', disabled: true }),
      discount: new UntypedFormControl('', [this.discountValidator()]),
      currency: new UntypedFormControl('CAD'),
      studentsIncluded: new UntypedFormControl(''),
      hiddenPrice: new UntypedFormControl({ value: false, disabled: this.bundle.isFirstPurchaseBundle }),
      subscription: new UntypedFormControl({
        value: false,
        disabled: !this.allowSubscription || this.bundle.isFirstPurchaseBundle,
      }),
      isFirstPurchaseBundle: new UntypedFormControl(false),
    });
    this.bundleFormGroup.setValue({
      title: get(this.bundle, 'title', ''),
      fullPrice: get(this.bundle, 'fullPrice', this.bundle.price),
      price: get(this.bundle, 'price', 0),
      discount: get(this.bundle, 'discount', 0),
      currency: get(this.bundle, 'currency', 'CAD'),
      studentsIncluded: get(this.bundle, 'studentsIncluded', 0),
      hiddenPrice: get(this.bundle, 'hiddenPrice', false),
      subscription: get(this.bundle, 'subscription', false),
      isFirstPurchaseBundle: get(this.bundle, 'isFirstPurchaseBundle', false),
    });
  }

  discountValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (value < 0 || value > 100) {
        return { discountInvalid: true };
      }
      return null;
    };
  }

  public setBundleDefaultProperties() {
    if (this.bundle.discount === undefined) {
      this.bundle.discount = 0;
    }

    if (this.bundle.fullPrice === undefined) {
      this.bundle.fullPrice = this.bundle.price;
    }

    if (this.bundle.isFirstPurchaseBundle === undefined) {
      this.bundle.isFirstPurchaseBundle = false;
    }
  }

  save() {
    const itemToSave = this.bundleFormGroup.value;
    const discount = this.bundleFormGroup.value.discount;

    if (discount < 0 || discount > 100) {
      this._snackBar.open('The discount amount cant be lower than 0% or higher than 100%!', 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });

      return;
    }

    itemToSave.order = this.index;
    itemToSave.belongsTo = this.outsiderId;
    if (this.id) {
      itemToSave.id = this.id;
    }

    if (!itemToSave.price) {
      itemToSave.price = this.bundleFormGroup.get('price').value;
    }

    this.saveEvent.emit(itemToSave);
  }

  handleDiscountChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const bundlePrice =
      this.bundleFormGroup.get('fullPrice').value -
      this.bundleFormGroup.get('fullPrice').value * (Number(inputElement.value) / 100);
    this.bundleFormGroup.get('price').setValue(bundlePrice.toFixed(0));
  }

  public handleFullPriceChange() {
    const discount = this.bundleFormGroup.get('discount').value;
    const bundlePrice =
      this.bundleFormGroup.get('fullPrice').value - this.bundleFormGroup.get('fullPrice').value * (discount / 100);
    this.bundleFormGroup.get('price').setValue(bundlePrice.toFixed(0));
  }

  delete() {
    this.deleteEvent.emit(this.id);
  }

  public allowBundleSave(): boolean {
    const bundle = {
      title: this.bundle.title,
      currency: this.bundle.currency,
      price: this.bundle.price,
      discount: this.bundle.discount,
      studentsIncluded: this.bundle.studentsIncluded,
      subscription: this.bundle.subscription,
      hiddenPrice: this.bundle.hiddenPrice,
      isFirstPurchaseBundle: this.bundle.isFirstPurchaseBundle,
    };

    for (const keys of Object.keys(bundle)) {
      if (bundle[keys] !== this.bundleFormGroup.get(keys).value) {
        return false;
      }
    }

    return true;
  }

  disableControls(hiddenPriceValue: boolean) {
    Object.keys(this.bundleFormGroup.controls).forEach((controlName) => {
      if (this.allowedFieldsWithHiddenPrice.includes(controlName)) {
        return;
      }

      const control = this.bundleFormGroup.get(controlName);

      control.disable();

      if (get(hiddenPriceValue, 'checked')) {
        control.disable();
      } else {
        control.enable();
      }
    });
  }
}
