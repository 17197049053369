import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  private logEvents = true;

  // EVENTS --

  // Next Lesson
  private isReadyForNextLessonEvent = new BehaviorSubject<boolean>(false);
  public isReadyForNextLessonEvent$ = this.isReadyForNextLessonEvent.asObservable();
  public emitIsReadyForNextLesson(bool: boolean) {
    this.isReadyForNextLessonEvent.next(bool);
  }
}
