import { UserTypeEnum } from './user-type.enum';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserType {
  messages = [
    {
      type: UserTypeEnum.OrganizationAdmin,
      title: 'WAIT! You are about to create an Organization Admin.',
      description:
        'Organization Admins can purchase tokens, add and edit organization managers, and add and edit students.<br />Would you like to proceed?',
      confirmation: 'Yes, Create an Admin',
    },
    {
      type: UserTypeEnum.OrganizationManager,
      title: 'WAIT! You are about to create an Organization Manager.',
      description: 'Organization Managers can add and edit students.<br />Would you like to proceed?',
      confirmation: 'Yes, Create a Manager',
    },
  ];

  getMessage(type: string) {
    return this.messages.find((item) => item.type === type);
  }
}
