export function loadDevShortcuts(): void {
  document.addEventListener('keydown', (event: KeyboardEvent) => {
    // if (event.key === 'k') clearCookiesAndRefresh(); // disabled
  });
}

function clearCookiesAndRefresh(): void {
  console.log('clearing cookies and refreshing...');
  localStorage.clear();
  window.location.reload();
}
