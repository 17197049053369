<section #box>
  <p
    montserrat
    extraLargeSize
    zeroMargin
    primaryColour
    style="line-height: 1"
  >
    Free Demo
  </p>
  <p
    montserrat
    mediumSize
    zeroMargin
    primaryColour
  >
    See what Neuralign is all about
  </p>

  <button
    primaryBtn
    smallSize
    (click)="goToDemo()"
  >
    Try Now
  </button>
</section>

<div #bg>
  <img
    src="assets/img/slides/desktop-mac-yellow-wall.webp"
    alt=""
    width="100%"
  />
</div>
