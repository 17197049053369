import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment';
import { ShopifyControllerGetOrders200ResponseCompletedOrdersInner } from 'src/app/core/openapi';
import { OrdersByProvince } from 'src/app/shared/interfaces/classwallet.interface';

@Component({
  selector: 'app-cw-boolean-filter',
  templateUrl: './cw-boolean-filter.component.html',
  styleUrl: './cw-boolean-filter.component.scss',
})
export class CwBooleanFilterComponent implements OnInit {
  @Input() propertyType: string = '';
  @Input() propertyValue: boolean = true;
  @Input() activeList: OrdersByProvince[] = [];

  public filterIsActive: boolean = false;
  public removedContent: OrdersByProvince[] = [];

  public options = [
    {
      label: 'Yes',
      value: true,
    },
    {
      label: 'No',
      value: false,
    },
  ];

  public labels = [
    {
      label: 'accountId',
      value: 'Account created',
    },
    {
      label: 'tokenDelivered',
      value: 'Token Delivered',
    },
  ];

  ngOnInit(): void {}

  public getLabel() {
    const labelToUse = this.labels.find((l) => l.label === this.propertyType);

    return labelToUse ? labelToUse.value : '';
  }

  public applyFilter() {
    this.resetFilter();

    for (const item of this.activeList) {
      const dataSource = item.dataSource;

      const itemsToSearch = [...dataSource.data, ...this.getRemovedContent(item.province)];

      const filteredValue = itemsToSearch.filter((item) => {
        const value = !item[this.propertyType] ? false : true;
        return value === this.propertyValue;
      });

      this.addRemovedItems(dataSource.data, filteredValue, item.province);

      dataSource.data = filteredValue;
    }

    this.filterIsActive = true;
  }

  public resetFilter() {
    for (const item of this.activeList) {
      const removedcontent = this.removedContent.find((c) => c.province === item.province);

      if (!removedcontent) {
        continue;
      }

      const dataSource = item.dataSource;
      const newData = [...dataSource.data, ...removedcontent.orders];
      newData.sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt)));

      dataSource.data = newData;
    }

    this.removedContent = [];
    this.filterIsActive = false;
  }

  public addRemovedItems(
    orders: ShopifyControllerGetOrders200ResponseCompletedOrdersInner[],
    filteredValue: ShopifyControllerGetOrders200ResponseCompletedOrdersInner[],
    province: string,
  ) {
    const removedItems = orders.filter((item) => !filteredValue.includes(item));

    const newEntry: OrdersByProvince = {
      orders: removedItems,
      province: province,
      dataSource: null,
    };

    this.removedContent.push(newEntry);
  }

  public getRemovedContent(province: string) {
    const removedcontent = this.removedContent.find((c) => c.province === province);

    if (!removedcontent) {
      return [];
    }

    return removedcontent.orders;
  }

  public shouldDisableBtn() {
    return this.propertyValue === undefined;
  }
}
