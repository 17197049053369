import { saveAs } from 'file-saver';
import { Component } from '@angular/core';
import { omit, chain, get, flatten } from 'lodash';
import { WorkBook, WorkSheet, utils, write, read } from 'xlsx';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faFileDownload, faFileUpload, faFileLines, faFile } from '@fortawesome/free-solid-svg-icons';

import { Patron, User } from 'src/app/shared/models/user.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Student } from 'src/app/pages/students/interfaces/student.interface';
import { EXCEL_EXTENSION, EXCEL_TYPE } from 'src/app/services/excel/excel.constants';

import { MatDialog } from '@angular/material/dialog';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { B2cListService } from '../../menus/b2c-menu/b2c-list/b2c-list.service';
import { AlertDialogComponent } from 'src/app/shared/dialogs/alert/alert.dialog';
import { ClientsListService } from '../../menus/clients-menu/clients-list/clients-list.service';
import { AdminsListService } from '../../menus/managers-and-admins-menu/admins-list/admins-list.service';
import { B2cStudentsListService } from '../../menus/b2c-menu/b2c-students-list/b2c-students-list.service';
import { ManagersListService } from '../../menus/managers-and-admins-menu/managers-list/managers-list.service';
import { OrganizationsListService } from '../../menus/organizations-menu/organizations-list/organizations-list.service';
import { Organization } from 'src/app/core/openapi';

@Component({
  selector: 'app-import-export',
  templateUrl: './import-export.component.html',
  styleUrls: ['./import-export.component.scss'],
})
export class ImportExportComponent {
  isLoading = false;

  public readonly importIcon: IconDefinition = faFileUpload;
  public readonly exportIcon: IconDefinition = faFileDownload;
  public readonly fileLinesIcon: IconDefinition = faFileLines;
  public readonly fileLIcon: IconDefinition = faFile;

  constructor(
    private _rest: RestAPIService,
    private _dialog: MatDialog,
    private _clientsListService: ClientsListService,
    private _studentsListService: B2cStudentsListService,
    private _managersListService: ManagersListService,
    private _adminsListService: AdminsListService,
    private _organizationsListService: OrganizationsListService,
    private _b2cListService: B2cListService,
    private _authService: AuthService,
  ) {}

  public async exportData() {
    this.isLoading = true;
    const user: User = await this._authService.getUser();
    const org = user.organization;

    const clientSheet = await this.getClientSheet();
    const studentSheet = await this.getStudentSheet();
    const managerSheet = await this.getManagerSheet();
    const adminSheet = await this.getAdminSheet();
    const outsiderSheet = get(org, 'isReseller', false) ? await this.getOutsidersSheet() : [];
    const b2cSheet = get(org, 'isB2CProvider', false) ? await this.getB2cSheet() : [];

    const workbook: WorkBook = {
      Sheets: {
        clients: clientSheet,
        students: studentSheet,
        managers: managerSheet,
        admins: adminSheet,
        ...(outsiderSheet.length > 0 ? { outsiders: outsiderSheet } : {}),
        ...(b2cSheet.length > 0 ? { b2cs: b2cSheet } : {}),
      },
      SheetNames: [
        'clients',
        'students',
        'managers',
        'admins',
        ...(outsiderSheet.length > 0 ? ['outsiders'] : []),
        ...(b2cSheet.length > 0 ? ['b2cs'] : []),
      ],
    };

    const excelBuffer: any = write(workbook, { bookType: 'xlsx', type: 'array' });

    const blob = new Blob([excelBuffer], {
      type: EXCEL_TYPE,
    });

    saveAs(blob, `exportedUsers-${new Date().toISOString()}${EXCEL_EXTENSION}`);

    this.isLoading = false;
  }

  private async getClientSheet() {
    const clients = await this._clientsListService.getClients({ refresh: true });

    const clientData = clients.map((client) => omit(client, ['id', 'image', 'accountId', 'type']));

    const clientSheet: WorkSheet = utils.json_to_sheet(clientData);
    clientSheet['!cols'] = [{ wch: 50 }, { wch: 20 }, { wch: 20 }, { wch: 15 }];

    return clientSheet;
  }

  private async getStudentSheet() {
    const clients = await this._clientsListService.getClients({ refresh: true });
    const students = await Promise.all(
      clients.map(async (client) => await this._studentsListService.loadStudents(client.id)),
    );

    const studentData = flatten(students).map((student: Student) => {
      const clientName = clients.find((client: Patron) => client.id === student.patronId)?.familyName;

      return chain(student)
        .merge({ client: clientName })
        .omit(['id', 'image', 'createdBy', 'patronId', 'programs', 'progress', 'tokens'])
        .value();
    });

    const studentSheet: WorkSheet = utils.json_to_sheet(studentData);
    studentSheet['!cols'] = [
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 30 },
    ];

    return studentSheet;
  }

  private async getManagerSheet() {
    const managers = await this._managersListService.getManagers({ refresh: true });

    const managerData = managers.map((manager) => omit(manager, ['id', 'accountId', 'type']));

    const managerSheet: WorkSheet = utils.json_to_sheet(managerData);
    managerSheet['!cols'] = [{ wch: 50 }, { wch: 20 }, { wch: 30 }, { wch: 20 }];

    return managerSheet;
  }

  private async getAdminSheet() {
    const admins = await this._adminsListService.getAdmins({ refresh: true });

    const adminData = admins.map((admin) => omit(admin, ['id', 'accountId', 'type']));

    const adminSheet: WorkSheet = utils.json_to_sheet(adminData);
    adminSheet['!cols'] = [{ wch: 50 }, { wch: 20 }, { wch: 20 }, { wch: 10 }];

    return adminSheet;
  }

  private async getOutsidersSheet() {
    const outsiders = await this._organizationsListService.getOrganizations();

    const outsidersData = outsiders.map((outsider: Organization) =>
      chain(outsider)
        .merge(get(outsider, 'organization'), get(outsider, 'organization.address'))
        .omit([
          'id',
          'organization',
          'tokenId',
          'type',
          'logo',
          'position',
          'isTrusted',
          'isOutsider',
          'allowSignup',
          'price',
          'b2cTokenPrice',
          'b2cCurrency',
          'maximumTokenDebit',
          'portalPrice',
          'allowSubscription',
          'accountId',
          'acceptedTerms',
          'professionalAssociation',
          'referredBy',
          'isReseller',
        ])
        .value(),
    );

    const outsiderSheet: WorkSheet = utils.json_to_sheet(outsidersData);
    outsiderSheet['!cols'] = [
      { wch: 30 },
      { wch: 30 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 40 },
      { wch: 20 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
    ];

    return outsiderSheet;
  }

  private async getB2cSheet() {
    const b2cs = await this._b2cListService.getB2C({ refresh: true });

    const b2cData = b2cs.map((b2c: Patron) =>
      omit(b2c, ['id', 'image', 'accountId', 'availableTokens', 'org', 'orgId']),
    );

    const b2cSheet: WorkSheet = utils.json_to_sheet(b2cData);
    b2cSheet['!cols'] = [
      { wch: 20 },
      { wch: 20 },
      { wch: 25 },
      { wch: 25 },
      { wch: 20 },
      { wch: 20 },
      { wch: 25 },
      { wch: 25 },
      { wch: 20 },
      { wch: 20 },
      { wch: 25 },
      { wch: 25 },
      { wch: 20 },
      { wch: 20 },
      { wch: 25 },
      { wch: 25 },
      { wch: 25 },
      { wch: 25 },
    ];

    return b2cSheet;
  }

  public async importFile(e: any) {
    this.isLoading = true;
    if (!e.target.files.length) {
      this.isLoading = false;
      return;
    }

    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = async (event: any) => {
      const clients = [];
      const fileContent = event.target.result;
      const workbook: WorkBook = read(fileContent, { type: 'binary' });
      const sheetNames = workbook.SheetNames;

      sheetNames.map((sheetName) => {
        const sheet: WorkSheet = workbook.Sheets[sheetName];
        const sheetData = utils.sheet_to_json(sheet);
        if (sheetName === 'clients') {
          for (const data of sheetData) {
            clients.push(data);
          }
        }
      });
      const promiseList = [];
      const user: User = await this._authService.getUser();
      const org = user.organization;

      for (const client of clients) {
        const newClient = {
          fullname: client.fullname,
          nickname: client.nickname,
          email: client.email,
          verifyType: 'Client',
          type: 'Client',
          org: org.name,
          subdomain: org.subdomain,
          lang: org.language,
        };
        promiseList.push(this._rest.post('account', newClient));
      }

      Promise.all(promiseList)
        .then(() => {
          this.isLoading = false;
          this._dialog.open(AlertDialogComponent, {
            width: '400px',
            data: 'All users have been created',
          });
        })
        .catch((error) => {
          this.isLoading = false;
          this._dialog.open(AlertDialogComponent, {
            width: '400px',
            data: error.message,
          });
        });
    };

    this.isLoading = false;
    reader.readAsBinaryString(file);
  }

  public downloadClientsFile() {
    const workbook: WorkBook = { SheetNames: [], Sheets: {} };

    const clients = [
      {
        fullname: '',
        nickname: '',
        email: '',
      },
    ];

    workbook.SheetNames.push('clients');
    workbook.Sheets['clients'] = utils.json_to_sheet(clients);

    const excelBuffer: any = write(workbook, { bookType: 'xlsx', type: 'array' });

    const blob = new Blob([excelBuffer], {
      type: EXCEL_TYPE,
    });

    saveAs(blob, `importClientsFileExample-${new Date().toISOString()}${EXCEL_EXTENSION}`);
  }

  public downloadStudentFile(): void {
    this._clientsListService.downloadStudentFile();
  }
}
