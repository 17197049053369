<form
  *ngIf="form"
  [formGroup]="form"
>
  <h1>Public Profile</h1>

  <div class="two-column">
    <!-- Name control -->

    <div class="name control">
      <mat-form-field appearance="outline">
        <mat-label>Organization Name</mat-label>
        <input
          formControlName="name"
          matInput
          required
        />
      </mat-form-field>
      <div class="errors">
        <ng-container
          *ngIf="name.invalid && (name.touched || name.dirty)"
          class="alert alert-danger"
        >
          <div
            class="error"
            *ngIf="name.errors?.['required']"
            [@validate]
          >
            Name is required.
          </div>
          <div
            class="error"
            *ngIf="name.errors?.['maxlength']"
            [@validate]
          >
            Name cannot exceed 255 characters.
          </div>
        </ng-container>
      </div>
    </div>

    <!-- Email control -->

    <div class="email control">
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input
          formControlName="email"
          matInput
        />
      </mat-form-field>
      <div class="errors">
        <ng-container
          *ngIf="email.invalid && (email.touched || email.dirty)"
          class="alert alert-danger"
        >
          <div
            class="error"
            *ngIf="email.errors?.['required']"
            [@validate]
          >
            Email is required.
          </div>
          <div
            class="error"
            *ngIf="email.errors?.['email']"
            [@validate]
          >
            Please enter a standard email, like yourname&#64;address.com
          </div>
          <div
            class="error"
            *ngIf="email.errors?.['pattern']"
            [@validate]
          >
            Your email cannot be yourname&#64;address.com.
          </div>
        </ng-container>
      </div>
    </div>

    <!-- Logo control -->

    <div class="logo control">
      <div *ngIf="logo.value">
        <img [src]="logo.value" />
      </div>

      <div class="logo-footer">
        <h6>Organization logo</h6>
        <app-simple-button
          text="Upload Image"
          (click)="uploadToCloudinary()"
          [smaller]="true"
        ></app-simple-button>
      </div>
    </div>

    <!-- Phone number control -->

    <div class="phone control">
      <mat-form-field appearance="outline">
        <mat-label>Phone Number</mat-label>
        <input
          phoneMask
          formControlName="phoneNumber"
          matInput
        />
      </mat-form-field>
      <div class="errors">
        <ng-container
          *ngIf="phoneNumber.invalid"
          class="alert alert-danger"
        >
          <div
            class="error"
            *ngIf="phoneNumber.errors?.['pattern']"
            [@validate]
          >
            Please enter a standard phone number, ex: (555) 123-4567.
          </div>
        </ng-container>
      </div>
    </div>

    <!-- Website control -->

    <div class="website control">
      <mat-form-field appearance="outline">
        <mat-label>Website</mat-label>
        <input
          formControlName="website"
          matInput
        />
      </mat-form-field>
    </div>

    <!-- Address control -->

    <div class="address control">
      <mat-form-field appearance="outline">
        <mat-label>Address</mat-label>
        <input
          formControlName="address"
          matInput
        />
      </mat-form-field>
      <div class="errors">
        <ng-container
          *ngIf="address.invalid && (address.touched || address.dirty)"
          class="alert alert-danger"
        >
          <div
            class="error"
            *ngIf="address.errors?.['required']"
            [@validate]
          >
            Address is required.
          </div>
        </ng-container>
      </div>
    </div>

    <!-- Postal Code control -->

    <div class="postalCode control">
      <mat-form-field appearance="outline">
        <mat-label>Postal Code / Zip Code</mat-label>
        <input
          formControlName="postalCode"
          matInput
        />
      </mat-form-field>
    </div>

    <!-- Province control -->

    <div class="province control">
      <mat-form-field appearance="outline">
        <mat-label>Province / State</mat-label>
        <input
          formControlName="province"
          matInput
        />
      </mat-form-field>
      <div class="errors">
        <ng-container
          *ngIf="province.invalid && (province.touched || province.dirty)"
          class="alert alert-danger"
        >
          <div
            class="error"
            *ngIf="province.errors?.['required']"
            [@validate]
          >
            Province is required.
          </div>
        </ng-container>
      </div>
    </div>

    <!-- Country control -->

    <div class="country control">
      <mat-form-field appearance="outline">
        <mat-label>Country</mat-label>
        <input
          formControlName="country"
          matInput
        />
      </mat-form-field>
      <div class="errors">
        <ng-container
          *ngIf="country.invalid && (country.touched || country.dirty)"
          class="alert alert-danger"
        >
          <div
            class="error"
            *ngIf="country.errors?.['required']"
            [@validate]
          >
            Postal code is required.
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <!-- Video URL control -->

  <div class="videoUrl control">
    <mat-form-field appearance="outline">
      <mat-label>Video URL</mat-label>
      <input
        formControlName="videoUrl"
        matInput
      />
    </mat-form-field>
    <div class="errors">
      <ng-container
        *ngIf="videoUrl.invalid"
        class="alert alert-danger"
      >
        <div
          class="error"
          *ngIf="videoUrl.errors?.['pattern']"
          [@validate]
        >
          Please provide a valid youtube video url such as
          https://www.youtube.com/embed/YOUR-VIDEO-ID.
        </div>
      </ng-container>
    </div>
  </div>

  <!-- Description control -->

  <div class="description control">
    <mat-form-field appearance="outline">
      <mat-label>Description</mat-label>
      <textarea
        formControlName="description"
        matInput
        rows="12"
        placeholder="Please tell us about your organization."
        maxlength="1000"
        (ngModelChange)="countCharacters($event)"
      ></textarea>
      <div class="character-count">{{ descriptionCharacterCount }} / 1000</div>
    </mat-form-field>
    <div class="errors">
      <ng-container
        *ngIf="
          description.invalid && (description.touched || description.dirty)
        "
        class="alert alert-danger"
      >
        <div
          class="error"
          *ngIf="description.errors?.['required']"
          [@validate]
        >
          A description of your organization is required.
        </div>
        <div
          class="error"
          *ngIf="description.errors?.['maxlength']"
          [@validate]
        >
          A description can be a maximum of 1000 characters.
        </div>
      </ng-container>
    </div>
  </div>

  <!-- Language control -->

  <div class="language control">
    <h6 class="language-heading">Choose Languages Provided:</h6>
    <div class="languages-list">
      <ng-container *ngIf="form.get('languagesProvided')">
        <div
          *ngFor="let lang of form.get('languagesProvided').value | keyvalue"
          [formGroup]="languagesProvided"
          class="language-control"
          [ngClass]="{ on: lang.value }"
        >
          <mat-label>{{ lang.key }}</mat-label>
          <mat-checkbox
            [checked]="lang.value"
            [formControlName]="lang.key"
          ></mat-checkbox>
        </div>
      </ng-container>
    </div>
  </div>

  <!-- Buttons -->

  <div class="bottom buttons">
    <app-simple-button
      [text]="'Save'"
      (click)="save()"
      [disabled]="areRequiredFieldsValid()"
    >
    </app-simple-button>

    <app-simple-button
      themeStyle="outline"
      [text]="'Cancel'"
      (click)="cancel()"
    >
    </app-simple-button>

    <app-simple-button
      themeStyle="outline"
      [text]="'Preview'"
      (click)="preview()"
    >
    </app-simple-button>
  </div>
  <div class="errors">
    <ng-container
      *ngIf="areRequiredFieldsValid()"
      class="alert alert-danger"
    >
      <div
        class="error"
        [@validate]
      >
        Please check for errors in the form before saving.
      </div>
    </ng-container>
  </div>
</form>
