<ng-container *ngIf="languagesList && !addingNewLang">
  <table
    mat-table
    [dataSource]="languagesList"
    *ngIf="languagesList.length > 0"
    class="col-5 px-0 mx-0"
  >
    <ng-container matColumnDef="name">
      <th
        mat-header-cell
        [ngClass]="'name-col'"
        *matHeaderCellDef
      >
        Name
      </th>
      <td
        mat-cell
        class="pointer"
        [ngClass]="'name-col'"
        *matCellDef="let row"
      >
        {{ row.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="languageCode">
      <th
        mat-header-cell
        *matHeaderCellDef
      >
        Code
      </th>
      <td
        mat-cell
        *matCellDef="let row"
      >
        {{ row.languageCode }}
      </td>
    </ng-container>

    <ng-container matColumnDef="flagCode">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="text-center"
      >
        Flag Code
      </th>
      <td
        mat-cell
        *matCellDef="let row"
        class="text-center"
      >
        {{ row.flagCode || '---' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="text-center"
      ></th>
      <td
        mat-cell
        *matCellDef="let row"
        class="text-center"
      >
        <a
          matTooltip="Delete Language"
          matTooltipPosition="above"
          class="mx-2"
        >
          <fa-icon
            [icon]="trash"
            (click)="deleteLanguage(row.id)"
          ></fa-icon>
        </a>

        <a
          matTooltip="Update Language"
          matTooltipPosition="above"
          class="mx-2"
        >
          <fa-icon
            [icon]="update"
            (click)="openUpdateLanguageDialog(row)"
          >
          </fa-icon>
        </a>
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="languagesColumns"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: languagesColumns"
    ></tr>
  </table>
</ng-container>

<div
  class="buttonWrapper mt-4"
  *ngIf="!addingNewLang"
>
  <button
    mat-raised-button
    (click)="addingNewLang = true"
  >
    Add language
  </button>
</div>

<div
  class="newLanguageContainer col-3 mx-0"
  *ngIf="addingNewLang"
>
  <div>
    <mat-form-field
      class="w-100"
      appearance="fill"
    >
      <mat-label>Language name</mat-label>
      <input
        matInput
        [(ngModel)]="name"
        autocomplete="off"
        placeholder="ex: English ( Canada )"
      />
    </mat-form-field>
  </div>

  <div>
    <mat-form-field
      class="w-100"
      appearance="fill"
    >
      <mat-label>Language code</mat-label>
      <input
        matInput
        [(ngModel)]="languageCode"
        autocomplete="off"
        placeholder="ex: en_ca"
      />
    </mat-form-field>
  </div>

  <div>
    <mat-form-field
      class="w-100"
      appearance="fill"
    >
      <mat-label>Flag Code</mat-label>
      <input
        matInput
        [(ngModel)]="flagCode"
        autocomplete="off"
        placeholder="ex: US"
      />
    </mat-form-field>
  </div>

  <div class="buttonWrapper d-flex justify-content-between">
    <button
      mat-raised-button
      (click)="saveNewLanguage()"
    >
      Save
    </button>

    <button
      mat-raised-button
      (click)="addingNewLang = false; resetLanguageForm()"
    >
      Cancel
    </button>
  </div>
</div>
