import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'users-managers-and-admins-menu',
  templateUrl: './managers-and-admins-menu.component.html',
  styleUrls: ['./managers-and-admins-menu.component.scss'],
})
export class ManagersAndAdminsMenuComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
