<div
  id="patients-add-files"
  class="container-fluid"
>
  <div class="col-12 mx-0 px-0 row">
    <div class="text-center mx-0 px-3 col-12 mb-3 confirmationContent">
      <h4>{{ warningMessage }}</h4>
      <mat-form-field>
        <mat-label>Amount</mat-label>
        <input
          type="number"
          matInput
          [(ngModel)]="data.amount"
        />
      </mat-form-field>
    </div>
    <div class="col-12 mx-0 px-3 d-flex mb-3 text-center">
      <button
        class="col-4"
        (click)="onConfirm()"
      >
        Confirm
      </button>
      <button
        class="offset-4 col-4 warn"
        (click)="onCancel()"
      >
        Cancel
      </button>
    </div>
  </div>
</div>
