import { Subscription, tap } from 'rxjs';
import { QuizQuestionType } from 'src/app/pages/courses/services/courses/courses.service.models';
import { EventService } from 'src/app/pages/courses/services/event/event.service';
import { QuizService } from 'src/app/pages/courses/services/quiz/quiz.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'quiz-choice-list',
  templateUrl: './choice-list.component.html',
  styleUrls: ['./choice-list.component.scss'],
})
export class ChoiceListComponent implements OnInit {
  @Input() choices: any[];
  @Input() selectedAnswer: any;
  @Output() choiceSelected = new EventEmitter<any>();

  multiQuestionSub: Subscription;
  correctMultiChoiceAnswers = [];
  chosenMultiChoiceAnswers = [];

  multiChoiceCompleteEvent = new EventEmitter();

  constructor(private _quiz: QuizService, private _events: EventService) {}

  ngOnInit(): void {
    this.multiQuestionSub = this._quiz.currentQuizQuestion$
      .pipe(
        tap((question) => {
          if (question.type === QuizQuestionType.MulitAnswer) {
            this.correctMultiChoiceAnswers = question.choices.filter((choice) => choice.correctChoice === true);
          }
        }),
      )
      .subscribe();
  }
  ngOnDestroy() {
    this.multiQuestionSub.unsubscribe();
  }

  checkMultiAnswerChoice(choice) {
    this.choiceSelected.emit(choice);
    if (choice.correctChoice) {
      if (!this.chosenMultiChoiceAnswers.includes(choice)) {
        this.chosenMultiChoiceAnswers.push(choice);
      }

      if (this._isMultiAnswerComplete()) {
        if (this._quiz.currentQuizQuestion$.value.isLastQuestion) {
          this._quiz.currentQuizQuestionCompleteEvent.emit(true);
          this._quiz.currentQuizCompleteEvent.emit(true);
          this._events.emitIsReadyForNextLesson(true);
        } else {
          this._quiz.nextQuizQuestion();
        }
      }
    } else {
      return; // no-op
    }
  }

  private _isMultiAnswerComplete() {
    return this.correctMultiChoiceAnswers.length === this.chosenMultiChoiceAnswers.length;
  }

  onChoiceSelected(choice, questionId) {
    this.selectedAnswer = choice;
    this._quiz.saveAnswer(questionId, choice);
  }
}
