import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { DocumentsComponent } from './documents.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { LayoutsModule } from 'src/app/shared/layouts/layouts.module';

@NgModule({
  declarations: [DocumentsComponent],
  imports: [BrowserModule, MatCardModule, MatButtonToggleModule, MatFormFieldModule, MatSelectModule, LayoutsModule],
})
export class DocumentsModule {}
