import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';

/**
 * This interceptor adds the Authorization token to every request, assuming it can
 * find the user's id token from the AuthService.
 */
@Injectable()
export class HeadersInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.auth.idToken) {
      if (request.url.includes('cloudinary')) {
        return next.handle(request);
      }

      const addHeadersToRequest = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + this.auth.idToken),
      });
      return next.handle(addHeadersToRequest);
    } else {
      return next.handle(request);
    }
  }
}
