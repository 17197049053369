import { Component, OnInit, ViewChild } from '@angular/core';
import { isEmpty } from 'lodash';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';
import { StudentsListService } from '../clients-menu/students-list/students-list.service';
import { SchoolStudent } from 'src/app/core/openapi';
import { Subscription } from 'rxjs';
import { Student } from 'src/app/pages/students/interfaces/student.interface';
import { MatDialog } from '@angular/material/dialog';
import { StudentCreateModalComponent } from '../../modals/student-create-modal/student-create-modal.component';
import { ManagerActions } from 'src/app/shared/interfaces/Manager.interface';
import { UsersHelperService } from '../../users-helper.service';
import { get } from 'lodash';
import { AuthService } from 'src/app/services/auth/auth.service';
import { User } from 'src/app/shared/models';

interface Event {
  target: {
    value: string;
  };
}

@Component({
  selector: 'students-menu',
  templateUrl: './students-menu-component.html',
  styleUrls: ['./students-menu-component.scss'],
})
export class StudentsMenuComponent implements OnInit {
  @ViewChild(MatTable) table: MatTable<SchoolStudent>;

  public dataSource: MatTableDataSource<SchoolStudent> = new MatTableDataSource([]);

  private refreshSubscription: Subscription;

  public loading = true;
  public user: User;

  constructor(
    private _confirm: ConfirmationService,
    private studentListService: StudentsListService,
    private dialog: MatDialog,
    private _usersHelperService: UsersHelperService,
    private authService: AuthService,
  ) {}

  async ngOnInit() {
    this.subscribeEvents();
    this.user = await this.authService.getUser();

    await this.getStudents();
  }

  ngOnDestroy(): void {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }

  public editClientStudent(student: Student): void {
    this.dialog.open(StudentCreateModalComponent, {
      data: {
        type: ManagerActions.UPDATE,
        student: student,
      },
    });
  }

  public createStudent(): void {
    this.dialog.open(StudentCreateModalComponent, {
      data: {
        type: ManagerActions.CREATE,
      },
    });
  }

  public async getStudents(refresh: boolean = false) {
    const students = await this.studentListService.showSchoolStudents(refresh);
    this.dataSource.data = students;

    if (this.table) {
      this.table.renderRows();
    }

    this.loading = false;
  }

  public subscribeEvents() {
    this.refreshSubscription = this.studentListService.refreshSchoolList.subscribe(async () => {
      this.loading = true;
      await this.getStudents(true);
    });
  }

  public canAccessNeuralign(student: Student): boolean {
    return student.tokens?.length > 0;
  }

  public accessNeuralign(student: Student): void {
    this._usersHelperService.goToStudentProgramPage(student);
  }

  public async deleteStudent(student: Student): Promise<void> {
    const studentHasAssignedCredit = student.tokens?.length > 0;
    const studentHasProgress = student.progress.length > 0;

    if (studentHasAssignedCredit && !studentHasProgress) {
      this._confirm.createConfirmation(
        'Warning',
        'The assigned credit should be retrieved by clicking on the - sign before archiving the student.',
        'Close',
        undefined,
      );
    } else {
      this._confirm
        .createConfirmation(
          'Warning',
          `Are you sure you want to delete ${!isEmpty(student.fullname) ? student.fullname : 'this student'}?`,
          'Yes',
          'No',
          '350px',
        )
        .then(async () => {
          this.loading = true;

          await this.studentListService.archiveStudent(student);

          await this.getStudents(true);
        });
    }
  }

  public getStudentAge(student: Student): string {
    if (!student.birthdate) {
      return '--';
    }

    const ageDifMs = Date.now() - new Date(student.birthdate).getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970).toString();
  }

  public applyFilter(event: Event): void {
    this.dataSource.filterPredicate = (data: SchoolStudent, filter: string) => {
      return data.fullname?.trim().toLowerCase().includes(filter);
    };

    let { value } = event.target;
    value = value.trim();
    value = value.toLowerCase();
    this.dataSource.filter = value;
  }

  public applySchoolFilter(event: Event): void {
    this.dataSource.filterPredicate = (data: SchoolStudent, filter: string) => {
      return data.school?.trim().toLowerCase().includes(filter);
    };

    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
    this.dataSource.filter = filterValue;
  }

  public allowActions(student: Student): boolean {
    if (!this.user) {
      return false;
    }

    const organization = this.user.organization;
    const isSchoolBoard: boolean = get(organization, 'isSchool', false) && get(organization, 'isReseller', false);

    if (!isSchoolBoard) {
      return true;
    }

    return get(organization, 'id', '') === get(student, 'organizationId', '');
  }
}
