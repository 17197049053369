import { Component, Input } from '@angular/core';

@Component({
  selector: 'quiz-question-title',
  templateUrl: './question-title.component.html',
  styleUrls: ['./question-title.component.scss'],
})
export class QuestionTitleComponent {
  @Input() question: string;
}
