<app-main-layout [breadcrumbs]="[{ name: 'Home', url: '/' }]">
  <div
    class="inner-content"
    *ngIf="logs"
  >
    <div class="content">
      <div class="overview">
        <h4 class="title">OVERVIEW</h4>
        <div class="info">
          <p>
            {{ getActiveUsers() }}<br />
            <small> Active Users </small>
          </p>
          <p>
            {{
              this.processLogs(this.logs, 'program progress', 0).count -
                this.processLogs(this.logs, 'program completion', 0).count
            }}<br />
            <small> In Progress </small>
          </p>
        </div>

        <h4 class="title">TODAY</h4>
        <div class="info">
          <p>
            {{
              this.processLogs(this.logs, 'cognitive therapy progress', 1)
                .count
            }}<br />
            <small> Cognitive Therapy Login </small>
          </p>
          <p>
            {{
              this.processLogs(this.logs, 'reading exercises progress', 1)
                .count
            }}<br />
            <small> Reading Exercises Login </small>
          </p>
        </div>

        <h4 class="title">WEEK</h4>
        <div class="info">
          <p>
            {{
              this.processLogs(this.logs, 'cognitive therapy progress', 7)
                .count
            }}<br />
            <small> Cognitive Therapy Login </small>
          </p>
          <p>
            {{
              this.processLogs(this.logs, 'reading exercises progress', 7)
                .count
            }}<br />
            <small> Reading Exercises Login </small>
          </p>
        </div>
      </div>
      <div class="graph">
        <canvas id="DashboardResults"></canvas>
      </div>
    </div>
  </div>
</app-main-layout>
