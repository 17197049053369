import { Component, OnInit } from '@angular/core';
import { CoursesService } from '../../services/courses/courses.service';
import { IconDefinition, faSpinner } from '@fortawesome/free-solid-svg-icons';

/**
 * @scope Holds all the components needed for the courses list page.
 */
@Component({
  selector: 'courses-course-list',
  templateUrl: './courses-list.component.html',
  styleUrls: ['./courses-list.component.scss'],
})
export class CoursesListPageComponent implements OnInit {
  courses$ = this._coursesService.allCourses$;

  public loading = true;
  public spinner: IconDefinition = faSpinner;

  constructor(private _coursesService: CoursesService) {}

  async ngOnInit() {
    await this._coursesService.loadCourses();

    this.loading = false;
  }
}
