import { Component, OnInit, Inject } from '@angular/core';
import {
  IconDefinition,
  faShoppingCart,
  faCheck,
  faUserCheck,
  faSpinner,
  faExchangeAlt,
} from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-subscription-info-dialog.component',
  templateUrl: './subscription-info-dialog.component.html',
  styleUrls: ['./subscription-info-dialog.component.scss'],
})
export class SubscriptionInfoComponent implements OnInit {
  public checkIcon: IconDefinition = faCheck;
  public cartIcon: IconDefinition = faShoppingCart;
  public verified: IconDefinition = faUserCheck;
  public change: IconDefinition = faExchangeAlt;
  public loading: IconDefinition = faSpinner;

  public amount: number;
  public subscription: any;
  public intervalChangeLoading = false;

  public readonly spinnerIcon: IconDefinition = faSpinner;

  constructor(
    private _router: Router,
    private _rest: RestAPIService,
    private confirm: ConfirmationService,
    public dialogRef: MatDialogRef<SubscriptionInfoComponent>,
    private snack: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  async ngOnInit() {
    await this.findSubscriptionInfo();
  }

  public getDate(date) {
    const correctDate = Number(date + '000');
    const dateFormat = new Date(correctDate);
    return ` ${dateFormat.getDate()} / ${dateFormat.getMonth() + 1} / ${dateFormat.getFullYear()} `;
  }

  public async findSubscriptionInfo() {
    const findSubscription = await this._rest.get('subscriptions/getSubscriptionStatus/' + this.data.user, {
      params: new HttpParams().set('invoice', this.data.invoice.subscription),
      msg: 'Could not get subscriptions/getSubscriptionStatus/:user',
    });

    this.subscription = findSubscription.subscription;
  }

  public async deleteSubscription() {
    this.confirm
      .createConfirmation(
        'Warning',
        'Are you sure you want delete this subscription ? This action cant be undone',
        'Yes',
        'No',
      )
      .then(async () => {
        try {
          await this._rest.delete('admin/subscriptions/deleteSubscription/' + this.data.user, {
            params: new HttpParams().set('id', this.subscription.id),
            msg: 'Could not delete subscription',
          });

          this.snack.open('Subscription deleted!', 'Close', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });

          this.dialogRef.close();
        } catch (error) {
          this.snack.open(`Could not delete this subscription; ${error.error}`, 'Close', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }
      });
  }

  public async changeSubscriptionPaymentInterval(subscription) {
    this.confirm
      .createConfirmation(
        'Warning',
        'Are you sure you want to change the interval of this subscription billing ?',
        'Yes',
        'No',
      )
      .then(async () => {
        try {
          this.intervalChangeLoading = true;
          await this._rest.put(
            'admin/subscriptions/changePaymentInterval/' + this.data.user,
            {
              subscription,
              interval: subscription.plan.interval === 'day' ? 'month' : 'day',
            },
            { msg: 'Could not put subscription.' },
          );

          this.snack.open('Subscription interval updated!', 'Close', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
          this.intervalChangeLoading = false;
          this.dialogRef.close();
        } catch (error) {
          this.snack.open(`Could not update this subscription interval; ${error.error}`, 'Close', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }
      });
  }
}
