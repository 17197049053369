<app-main-layout
  [breadcrumbs]="[
    { name: 'Home', url: '/' },
    { name: 'Tutorials', url: '/tutorials-org' }
  ]"
>
  <app-before-you-start
    *ngIf="showBeforeYouStart"
    [isPopup]="true"
    [allowStart]="false"
    (onStart)="listStudents(); showBeforeYouStart = false"
    (close)="showBeforeYouStart = false"
  ></app-before-you-start>

  <app-tutorial-modal
    *ngIf="showTutorial"
    [callback]="onCompleteTutorial"
    [tutorial]="tutorial"
    [isSingle]="isSingle"
  >
  </app-tutorial-modal>

  <div class="inner-content">
    <h2>Tutorials</h2>

    <p>Click on a specific part of the Tutorial to open help for that page!</p>

    <ul>
      <li (click)="openWizard(tutorialTypes.USERS_ORG)">USERS</li>
      <li
        (click)="openWizard(tutorialTypes.ADD_USER_ORG)"
        class="tabbed"
      >
        Add User
      </li>
      <li
        (click)="openWizard(tutorialTypes.ADD_STUDENT_ORG)"
        class="tabbed"
      >
        Add Student
      </li>
      <li class="unclickable">PROGRAMS</li>
      <li
        (click)="openWizard(tutorialTypes.PURCHASING_CREDITS_ORG)"
        *ngIf="acc.organization.isOutsider === false"
        class="tabbed"
      >
        Purchasing Credits
      </li>
      <li
        (click)="openWizard(tutorialTypes.NEURALIGN_ORG)"
        class="tabbed"
      >
        Neuralign
      </li>
      <li
        (click)="openWizard(tutorialTypes.ADD_CREDIT_ORG)"
        class="tabbed"
      >
        Add credit to your student
      </li>
      <li
        (click)="openWizard(tutorialTypes.ENTERING_STUDENT_PAGE_ORG)"
        class="tabbed"
      >
        Entering the student's program page
      </li>
      <li
        (click)="openWizard(tutorialTypes.REMOTE_ACCESS_ORG)"
        class="tabbed"
      >
        Remote access for home use
      </li>
      <li
        (click)="openWizard(tutorialTypes.DEMO_ORG)"
        class="tabbed"
      >
        Demo
      </li>
      <li (click)="openWizard(tutorialTypes.TRAINING_ORG)">TRAINING</li>
      <li
        (click)="showBeforeYouStart = true"
        class="tabbed"
      >
        Before you start Neuralign
      </li>
      <li
        (click)="openWizard(tutorialTypes.DOCUMENTS_ORG)"
        class="tabbed"
      >
        Documents
      </li>
      <li (click)="openFullWizard()">FULL TUTORIAL</li>
    </ul>
  </div>
</app-main-layout>
