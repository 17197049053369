import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { styleElement } from '../../helpers/styleElement.function';
import { Styles } from '../../styles/lsw.styles';

@Directive({
  selector: '[primaryColour]',
})
export class PrimaryColourDirective {
  constructor(private el: ElementRef, private _renderer: Renderer2) {}

  ngOnInit() {
    styleElement(this._renderer, this.el, {
      color: Styles.Colours.PRIMARY_COLOUR,
    });
  }
}
