import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { PortalTypeService } from '../../services/portal/portal-type.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public content;
  public isOrgsPortal = false;
  public orgName: string;

  constructor(
    public auth: AuthService,
    private _cookieService: CookieService,
    private portalType: PortalTypeService,
    private _snackBar: MatSnackBar,
  ) {}

  ngOnInit() {
    this.isOrgsPortal = this.portalType.chekIfIsOrgPortal;

    if (this.isOrgsPortal) {
      this.orgName = this.portalType.getOrgName;
    }

    if (this.auth.accessToken) {
      this.auth.redirectUser();
    } else if (this.getAuth0Error()) {
      this._snackBar.open('Authentication failed!', 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    } else {
      const cookie = this._cookieService.get('authResult');
      const profile = this._cookieService.get('profile');
      if (cookie && cookie !== '' && profile && profile !== '') {
        const authResult = JSON.parse(cookie);
        const parsedProfile = JSON.parse(cookie);
        this.auth.fillLoggedInfo(authResult, parsedProfile);
      }
    }

    // TODO: getHomePageContent
    this.content = {
      testimonial: {
        name: 'Anette McCloud',
        text: 'My son has been behind in his reading since grade one, and has fallen further behind every year.  School was causing him so much anxiety. We were amazed at how easy it was to get him to do Neuralign. The program set him up for success and kept him engaged. But the most amazing thing is how much his reading has improved! His teachers have all asked for the name of the program so they can recommend it to other parents. Thank you so much for this opportunity!',
      },
    };
  }

  public getWindowAuth() {
    return window.location.href.includes('auth=true');
  }

  public getB2cSignUp() {
    return window.location.href.includes('b2csignup=true');
  }

  public getAuth0Error() {
    return window.location.href.includes('#error');
  }

  public logout() {
    this.auth.logout();
  }

  public isOnlineSignUpAllowed(): boolean {
    const provider = this.portalType.getPortalOwner;

    if (provider.disableStripeWarning) {
      return true;
    } else if (!provider.disableStripeWarning && !provider.allowSignup) {
      return false;
    } else {
      return true;
    }
  }
}
