import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-take-a-tour-highlight',
  template: ` <div class="highlight">
    <div class="container">
      <p class="title">
        {{ title }}
      </p>
      <p class="subtitle">
        {{ subtitle }}
      </p>
    </div>
  </div>`,
  styleUrls: ['./highlight.component.scss'],
})
export class TakeATourHighlightComponent {
  @Input() title: string;
  @Input() subtitle: string;
}
