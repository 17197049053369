<div
  [ngClass]="{ 'd-none': !dataSource }"
  *ngIf="resellerSubscriptions"
  class="mainContainer col-12 px-0 resellerControlCenter"
>
  <div class="col-10 px-0 m-auto">
    <div class="infoCards col-12 px-0 d-flex">
      <div>
        <mat-card class="card mx-2">
          {{ resellerSubscriptions.length }} <br />
          <span class="cardFooter"> Active subscriptions </span>
        </mat-card>
      </div>

      <div class="mt-2">
        <mat-card class="card mx-2">
          <fa-icon
            *ngIf="loadingBalance"
            class="regularIcon"
            [icon]="loadingIcon"
            [spin]="true"
          ></fa-icon>
          <span
            *ngIf="!loadingBalance"
            [ngClass]="{ negativeBalance: balance < 0 }"
            >{{ balance }}</span
          >
          <br />
          <span class="cardFooter"> Next month token balance </span>
        </mat-card>

        <div
          class="outOfTokensWarning col-12"
          *ngIf="outOfTokensCheck()"
        >
          You wont have enough credits to deliver next month
        </div>
      </div>
    </div>
  </div>

  <div class="mat-elevation-z8 col-10 px-0 m-auto">
    <table
      mat-table
      [dataSource]="dataSource"
      class="col-12 px-0 text-center mt-4 subscriptionInfo"
      matSort
    >
      <div class="table">
        <ng-container
          matColumnDef="client"
          class="text-center"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            Client
          </th>
          <td
            mat-cell
            *matCellDef="let subscription"
            class="text-center"
          >
            {{ subscription.client }}
          </td>
        </ng-container>

        <ng-container
          matColumnDef="cost"
          class="text-center"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
          >
            Cost
          </th>
          <td
            mat-cell
            *matCellDef="let subscription"
            class="text-center"
          >
            {{ subscription.currency }} {{ subscription.cost }}
          </td>
        </ng-container>

        <ng-container
          matColumnDef="next charge"
          class="text-center"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
          >
            Next charge
          </th>
          <td
            mat-cell
            *matCellDef="let subscription"
            class="text-center"
          >
            {{ getDate(subscription.currentPeriodEnd) }}
          </td>
        </ng-container>

        <ng-container
          matColumnDef="status"
          class="text-center"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
          >
            Status
          </th>
          <td
            mat-cell
            *matCellDef="let subscription"
            class="text-center"
          >
            <span>
              <fa-icon
                class="mr-2 {{ getSubscriptionClass(subscription) }}"
                [icon]="circle"
              >
              </fa-icon>
              {{ getSubscriptionStatus(subscription) }}
            </span>
          </td>
        </ng-container>

        <ng-container
          matColumnDef="tokens"
          class="text-center"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
          >
            Tokens
          </th>
          <td
            mat-cell
            *matCellDef="let subscription"
            class="text-center"
          >
            {{ subscription.tokens }}
          </td>
        </ng-container>

        <ng-container
          matColumnDef="actions"
          class="text-center"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
          >
            Actions
          </th>
          <td
            mat-cell
            *matCellDef="let subscription"
            class="actionIcons text-center"
          >
            <a
              *ngIf="subscription.status !== 'canceled'"
              matTooltip="Adjust subscription"
              matTooltipPosition="above"
            >
              <fa-icon
                class="mx-2 regularIcon"
                [icon]="changePrice"
                (click)="adjustSubscriptionInfo(subscription)"
              >
              </fa-icon>
            </a>

            <a
              matTooltip="Check last payments"
              matTooltipPosition="above"
            >
              <fa-icon
                class="mx-2 regularIcon"
                [icon]="receipt"
                (click)="findResellerSubscriptionInvoices(subscription)"
              >
              </fa-icon>
            </a>

            <a
              *ngIf="subscription.status !== 'canceled'"
              matTooltip="Pause subscription"
              matTooltipPosition="above"
            >
              <fa-icon
                class="mx-2 regularIcon"
                [icon]="stopIcon"
                (click)="changeSubscriptionPaymentStatus(subscription, 'pause')"
                [ngClass]="{ disabledIcon: subscription.pausedSubscription }"
              >
              </fa-icon>
            </a>

            <a
              *ngIf="subscription.status !== 'canceled'"
              matTooltip="Resume subscription"
              matTooltipPosition="above"
            >
              <fa-icon
                class="mx-2 regularIcon"
                [icon]="reactivate"
                (click)="
                  changeSubscriptionPaymentStatus(subscription, 'unpause')
                "
                [ngClass]="{ disabledIcon: !subscription.pausedSubscription }"
              >
              </fa-icon>
            </a>
          </td>
        </ng-container>
      </div>

      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
      ></tr>
    </table>

    <mat-paginator
      class="col-12 row px-0 mx-0"
      [pageSize]="5"
      aria-label="Select page"
    ></mat-paginator>
  </div>
</div>
