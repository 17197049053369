import { Component, Input } from '@angular/core';

/**
 * @scope Styles a description string for the course overview page.
 */
@Component({
  selector: 'course-overview-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss'],
})
export class DescriptionComponent {
  @Input() description: string;
}
