import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { MaterialComponentsModule } from 'src/app/shared/modules/material-components.module';
import { GlobalConfigurationsComponent } from './global-configurations.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDividerModule } from '@angular/material/divider';
import { LayoutComponentsModule } from 'src/app/shared/components/layout/layout-components.module';
import { LayoutsModule } from 'src/app/shared/layouts/layouts.module';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { SafeUrlModule } from 'src/app/shared/pipes/safe-url/safe-url.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { WarningMessageComponent } from './components/warning-message/warning-message.component';
import { ExportsComponent } from './components/exports/exports.component';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';

@NgModule({
  declarations: [GlobalConfigurationsComponent, WarningMessageComponent, ExportsComponent],
  imports: [
    BrowserModule,
    RouterModule,
    HttpClientModule,
    MaterialComponentsModule,
    FontAwesomeModule,
    MatTooltipModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatTableModule,
    MatCheckboxModule,
    MatButtonModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatExpansionModule,
    LayoutComponentsModule,
    LayoutsModule,
    AngularEditorModule,
    SafeUrlModule,
    DragDropModule,
  ],
  providers: [RestAPIService],
})
export class GlobalConfigurationsModule {}
