import { NgModule } from '@angular/core';
import { ControlsComponent } from './controls.component';
import { ControlComponent } from './control/control.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [ControlsComponent, ControlComponent],
  imports: [CommonModule, FontAwesomeModule, MatTooltipModule],
  exports: [ControlsComponent],
})
export class ControlsModule {}
