import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { Messages } from 'src/app/shared/interfaces/Message.interface';
import { CoursesService } from 'src/app/pages/courses/services/courses/courses.service';
import { Course } from 'src/app/pages/configuration-pages/interfaces/global-config.interfaces';
import { MessageControllerService } from 'src/app/core/openapi';
import { firstValueFrom } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { MessageDialogComponent } from 'src/app/shared/dialogs/message/message.dialog';

@Injectable({
  providedIn: 'root',
})
export class MessagesService {
  constructor(
    private _courses: CoursesService,
    private sanitizer: DomSanitizer,
    public _auth: AuthService,
    private _dialog: MatDialog,
    private _logger: LoggerService,
    private _messageController: MessageControllerService,
  ) {}

  public rows: Messages[] = [];

  public async getMessages() {
    const messages = await firstValueFrom(this._messageController.messageControllerGetAllMessages());
    const courses = await this.getCourses();
    return (this.rows = this.formatedColumnsMessage(messages, courses));
  }

  public formatedColumnsMessage(messages: Messages[], courses?: Course[]) {
    return messages.map((message: Messages) => {
      const course = courses?.find((course: Course) => course.id === message.courseId);
      return {
        ...message,
        createdAt: new Date(message.createdAt).toLocaleDateString(),
        courseName: course ? course.title : 'System Message',
      };
    });
  }

  public async getCourses() {
    await this._courses.initCourses();
    const courseList = this._courses.getRawCourseData();
    return courseList;
  }

  public updateSafeUrl(url: string) {
    if (this.isYouTubeUrl(url)) {
      const videoId = this.extractYouTubeVideoId(url);
      return this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${videoId}`) || null;
    }

    if (this.isSynthesiaUrl(url)) {
      const videoId = this.extractSynthesiaVideoId(url);
      return (
        this.sanitizer.bypassSecurityTrustResourceUrl(`https://share.synthesia.io/embeds/videos/${videoId}`) || null
      );
    }

    return null;
  }

  private extractYouTubeVideoId(url: string): string | null {
    const match = url.match(
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/,
    );
    return match ? match[1] : null;
  }

  private extractSynthesiaVideoId(url: string): string | null {
    const match = url.match(/https:\/\/share\.synthesia\.io\/embeds\/videos\/([a-zA-Z0-9-]+)/);
    return match ? match[1] : null;
  }

  private isYouTubeUrl(url: string): boolean {
    const youtubeRegex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    return youtubeRegex.test(url);
  }

  private isSynthesiaUrl(url: string): boolean {
    const synthesiaRegex = /(?:https?:\/\/)?(?:www\.)?(?:share\.synthesia\.io\/)([a-zA-Z0-9_-]+)/;
    return synthesiaRegex.test(url);
  }

  public async initUserMessage() {
    try {
      const user = await this._auth.getUser();
      const isSessionActive = this._auth.checkIsSessionActive();
      const messages = await firstValueFrom(this._messageController.messageControllerGetMessagesByLogin(user.id));
      const filteredMessages = [];

      if (messages?.length > 0) {
        await this._courses.initCourses();
        const courseList = this._courses.getRawCourseData();
        messages.filter((message) => {
          if (message?.courseId) {
            const course = courseList.find((c) => c.id === message.courseId);
            if (course && message.createdAt > user.createdAt) {
              filteredMessages.push(message);
            }
          } else {
            if (message.createdAt > user.createdAt) {
              filteredMessages.push(message);
            }
          }
        });
      }

      if (filteredMessages?.length > 0 && isSessionActive) {
        this._dialog.open(MessageDialogComponent, {
          data: filteredMessages,
          panelClass: 'modal-border',
          width: '800px',
        });
      }
    } catch (error) {
      this._logger.error(error);
    }
  }
}
