type Testimonial = {
  name: string;
  quote: string;
};

export const testimonials: Testimonial[] = [
  {
    name: 'Jenny’s Mom',
    quote:
      'I came home today to find Jenny sitting in our nook reading a book. As a mother who watched her daughter struggle for so many years I just started crying!',
  },
  {
    name: 'Ann-Maire’s Mom',
    quote:
      'The difference in her reading skills changed dramatically, within 3 weeks my daughter was reading without struggles.',
  },
  {
    name: 'Julie',
    quote:
      'It helped both my boys aged 8 and 10 improve greatly in their reading skills, but also in their organization skills and in their self-esteem.',
  },
];
