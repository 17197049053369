<div class="wrapper">
  <ng-container *ngIf="currentQuizQuestion$ | async as question">
    <quiz-question-title [question]="question.question"></quiz-question-title>

    <quiz-question-answer-type
      [answerType]="question.typeLabel"
    ></quiz-question-answer-type>

    <quiz-choice-list
      [choices]="question.choices"
      [selectedAnswer]="selectedAnswer"
      (choiceSelected)="onChoiceSelected($event, question.index)"
    >
    </quiz-choice-list>

    <div class="feedback-container">
      <quiz-feedback class="text-center"></quiz-feedback>
    </div>
  </ng-container>
</div>
