import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Messages } from 'src/app/shared/interfaces/Message.interface';

@Component({
  selector: 'app-message-header-dashboard',
  templateUrl: './messages-header-dashboard.component.html',
  styleUrls: ['./messages-header-dashboard.component.scss'],
})
export class MessagesHeaderDashboardComponent {
  @Input() public rows: Messages[];
  @Output() public filterRows = new EventEmitter<any>();
  @Output() public newMessage = new EventEmitter<any>();

  public searchTerm: string;

  public search() {
    this.handleSearchMessages(this.rows, this.searchTerm);
  }

  public handleSearchMessages(items: Messages[], searchTerm: string): any[] {
    if (!items) return [];
    if (!searchTerm) {
      this.filterRows.emit(items);
      return;
    }

    searchTerm = searchTerm.toLowerCase();
    const filteredRows = items.filter((it) => {
      return (
        (it.courseName ? it.courseName.toLowerCase().includes(searchTerm) : false) ||
        (it.text ? it.text.toLowerCase().includes(searchTerm) : false) ||
        (it.createdAt ? it.createdAt.toString().includes(searchTerm) : false)
      );
    });
    this.filterRows.emit(filteredRows);
  }

  public sendNewMessageToDashboard(message) {
    this.newMessage.emit(message);
  }
}
