<div class="slides-container">
  <ng-container *ngIf="currentSlide$ | async as slide">
    <div class="sequence">
      <p class="text-center">
        Slide {{ slide.sequence }}/{{ slide.numberOfSlides }}
      </p>
    </div>

    <div class="slide">
      <img
        [src]="slide.url"
        width="100%"
      />
    </div>
  </ng-container>
</div>
