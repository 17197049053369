import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { RestAPIService } from '../../services/rest/rest-api.service';
import { MaterialComponentsModule } from '../../shared/modules/material-components.module';
import { AdminComponent } from './admin.component';
import { UploadGameFilesComponent } from './upload-game-files/upload-game-files.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { BreadcrumbModule } from 'src/app/shared/components/breadcrumb/breadcrumb.module';
import { ConfirmationModalComponent } from 'src/app/shared/components/confirmation-modal/confimation-modal.component';
import { SubscriptionInfoComponent } from './admin-dashboard/subscription-info-dialog/subscription-info-dialog.component';
import { PublicProfileEditDialogComponent } from './admin-dashboard/public-profile-edit-dialog/public-profile-edit-dialog.component';
import { AddTokenComponent } from './add-token/add-token.component';
import { PaginationComponent } from 'src/app/shared/components/pagination/pagination.component';
import { UpdatesModule } from '../updates/updates.module';
import { DefaultBundleCardComponent } from './admin-dashboard/admin-bundle-card/default-bundle-card.component';
import { UpdateBundleDialogComponent } from './admin-dashboard/admin-bundle-card/update-bundle-dialog/update-bundle-dialog.component';
import { LayoutComponentsModule } from 'src/app/shared/components/layout/layout-components.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SafeUrlModule } from 'src/app/shared/pipes/safe-url/safe-url.module';
import { LayoutsModule } from 'src/app/shared/layouts/layouts.module';
import { DeleteTokenComponent } from './delete-token/delete-token.component';
import { ProgressEditDialog } from './admin-dashboard/progress-edit-dialog/progress-edit-dialog';
import { CommonModule } from '@angular/common';
import { B2cPaymentTypesDialog } from './admin-dashboard/b2c-payment-types/b2c-payment-types.component';
import { ProgressCompletionDialog } from './admin-dashboard/progress-completion-dialog/progress-completion-dialog';
import { UpdateTokenDialog } from './admin-dashboard/update-token-dialog/update-token-dialog';
import { OutsiderImportDialog } from './admin-dashboard/outsider-import-dialog/outsider-import-dialog';

@NgModule({
  declarations: [
    AdminComponent,
    UploadGameFilesComponent,
    AdminDashboardComponent,
    ConfirmationModalComponent,
    AddTokenComponent,
    DeleteTokenComponent,
    PaginationComponent,
    SubscriptionInfoComponent,
    DefaultBundleCardComponent,
    UpdateBundleDialogComponent,
    PublicProfileEditDialogComponent,
    ProgressEditDialog,
    B2cPaymentTypesDialog,
    ProgressCompletionDialog,
    UpdateTokenDialog,
    OutsiderImportDialog,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FontAwesomeModule,
    MatAutocompleteModule,
    MaterialComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    BreadcrumbModule,
    UpdatesModule,
    LayoutComponentsModule,
    MatTooltipModule,
    SafeUrlModule,
    LayoutsModule,
    CommonModule,
  ],
  providers: [RestAPIService],
  exports: [ConfirmationModalComponent, AddTokenComponent, DeleteTokenComponent],
})
export class AdminModule {}
