import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'quiz-question-answer-type',
  templateUrl: './question-answer-type.component.html',
  styleUrls: ['./question-answer-type.component.scss'],
})
export class QuestionAnswerTypeComponent implements OnInit {
  @Input() answerType: string;

  constructor() {}

  ngOnInit(): void {}
}
