<p>
  <span class="text">{{ currency }}$</span>
  <span class="price">{{ price }}</span>

  <span
    class="price fullPrice"
    *ngIf="discount && discount > 0 && fullPrice"
    >{{ '*Full price ' + fullPrice }}</span
  >
  <span
    class="text"
    *ngIf="isSubscription"
    >/month</span
  >
</p>
