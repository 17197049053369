import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';

import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faYoutube,
  faLinkedin,
  IconDefinition,
} from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  public facebookIcon: IconDefinition = faFacebookF;
  public twitterIcon: IconDefinition = faTwitter;
  public instagramIcon: IconDefinition = faInstagram;
  public youtubeIcon: IconDefinition = faYoutube;
  public linkedinIcon: IconDefinition = faLinkedin;

  email = new FormControl('');

  constructor() {}

  public goToTwitter(): void {
    window.open('https://twitter.com/ls_works', '_blank');
  }

  public goToFacebook(): void {
    window.open('https://www.facebook.com/LSWorks.Edu/', '_blank');
  }

  public goToInstagram(): void {
    window.open('https://www.instagram.com/ls.works/', '_blank');
  }

  public goToLinkedIn(): void {
    window.open('https://www.linkedin.com/company/ls-works/', '_blank');
  }

  public goToYoutube(): void {
    window.open('https://www.youtube.com/channel/UCOBxNLmdqHf7zJB92GXpRKA', '_blank');
  }

  public cleanSubscribeInput(event): void {
    const element = document.querySelector('#mc_embed_signup_scroll input#email') as HTMLInputElement;

    if (element.value.trim() === '') {
      event.preventDefault();
    } else {
      setTimeout(() => (element.value = ''));
    }
  }

  public connectEmail() {
    // TODO: Setup newsletter connection
    this.email.setValue('');
  }
}
