<ng-container *ngIf="messages.length < 2">
  <ng-container *ngFor="let message of messages; let i = index">
    <div class="text-center">
      <div class="header">Neuralign</div>
      <div *ngIf="message.mediaType == 'image' || !message.mediaType">
        <img
          class="img"
          [src]="message.image"
          alt="Image"
        />
      </div>
      <div
        *ngIf="message.mediaType == 'video'"
        class="video"
      >
        <iframe
          [src]="safeMessageVideoUrl"
          frameborder="0"
          allowfullscreen
          class="responsive-iframe"
        ></iframe>
      </div>
      <div class="content">{{message.text}}</div>
      <div class="group-button">
        <button
          *ngIf="message?.courseId"
          (click)="goToCourse(message)"
        >
          Go to course
        </button>
        <button
          class="button-close"
          (click)="close(message)"
        >
          Close
        </button>
      </div>
    </div>
  </ng-container>
</ng-container>

<!-- Multiple messages -->
<ng-container *ngIf="messages.length > 1">
  <div class="carousel">
    <ng-container *ngFor="let message of messages; let i = index">
      <div
        *ngIf="i === currentSlide"
        class="text-center"
      >
        <div class="header">Neuralign</div>
        <div class="container">
          <button
            *ngIf="currentSlide > 0"
            class="control prev"
            (click)="onPreviousClick(message)"
          >
            <span class="arrow left"></span>
          </button>
          <div *ngIf="message.mediaType == 'image' || !message.mediaType">
            <img
              class="img"
              [src]="message.image"
              alt="Image"
            />
          </div>
          <div
            *ngIf="message.mediaType == 'video'"
            class="video"
          >
            <iframe
              [src]="safeMessageVideoUrl"
              frameborder="0"
              allowfullscreen
              class="responsive-iframe"
            ></iframe>
          </div>
          <button
            *ngIf="currentSlide < messages.length - 1"
            class="control next"
            (click)="onNextClick(message)"
          >
            <span class="arrow right"></span>
          </button>
        </div>
        <div class="content">{{message.text}}</div>

        <div class="group-button">
          <button
            *ngIf="message?.courseId"
            (click)="goToCourse(message)"
          >
            Go to course
          </button>
          <button
            class="button-close"
            (click)="close(message)"
          >
            Close
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
