import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StripeTableActionIconComponent } from './stripe-table-action-icon.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [StripeTableActionIconComponent],
  imports: [CommonModule, FontAwesomeModule, MatTooltipModule],
  exports: [StripeTableActionIconComponent],
})
export class StripeTableActionIconModule {}
