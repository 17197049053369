import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PricingBundle } from 'src/app/pages/pricing/interfaces/pricing-bundle.interface';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { NewBundleDialog } from './new-bundle-dialog/new-bundle-dialog.component';

@Component({
  selector: 'app-global-config-bundles',
  templateUrl: './global-config-bundles.component.html',
  styleUrls: ['./global-config-bundles.component.scss'],
})
export class GlobalConfigBundlesComponent implements OnInit {
  @Input() bundles: PricingBundle[];

  @Output() refreshBundles = new EventEmitter();

  constructor(
    private _rest: RestAPIService,
    protected _snackBar: MatSnackBar,
    private confirmation: ConfirmationService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {}

  public deleteBundle(id: string) {
    this.confirmation
      .createConfirmation(
        'Warning',
        'This action cannot be undone, are you sure you want to delete this bundle?',
        'Yes',
        'No',
      )
      .then(async () => {
        this._rest.delete('globalConfig/bundles/delete/id/' + id).then(
          () => {
            this._snackBar.open('Bundle delete!', 'Close', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
          },
          (error) => {
            this._snackBar.open(error, 'Close', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
          },
        );
      });
  }

  public saveBundle(bundle: PricingBundle) {
    this._rest.put(`bundle/${bundle.id}`, { bundle }).then(
      () => {
        this._snackBar.open('Bundle updated!', 'Close', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      },
      (error) => {
        this._snackBar.open(error, 'Close', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      },
    );
  }

  public openBundleCreator() {
    const dialog = this.dialog.open(NewBundleDialog, {
      width: '450px',
      height: '530px',
      panelClass: 'modal-border',
    });

    dialog.afterClosed().subscribe((shouldRefresh) => {
      if (shouldRefresh) {
        this.refreshBundles.emit();
      }
    });
  }
}
