import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[formControlName][phoneMask]',
})
export class PhoneMaskDirective {
  constructor(public ngControl: NgControl) {}

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    const newValue = event.replace(/[^1234567890()\-+\s]/g, '');
    this.ngControl.valueAccessor.writeValue(newValue);
  }
}
