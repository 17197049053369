import { Component, OnDestroy } from '@angular/core';
import { faArrowCircleLeft, faArrowCircleRight, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import {
  LessonFEM,
  QuizQuestion,
  SectionFEM,
  SlideFEM,
} from 'src/app/pages/courses/services/courses/courses.service.models';
import { LessonsService } from 'src/app/pages/courses/services/lessons/lessons.service';
import { QuizService } from 'src/app/pages/courses/services/quiz/quiz.service';
import { SlidesService } from 'src/app/pages/courses/services/slides/slides.service';

type LMSEvent = 'next-lesson' | 'prev-lesson' | 'next-slide' | 'prev-slide' | 'next-question' | 'prev-question';

@Component({
  selector: 'main-view-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.scss'],
})
export class ControlsComponent implements OnDestroy {
  private _subs: Subscription[] = [];

  // Template vars for current lesson
  public lesson: LessonFEM;
  public section: SectionFEM;
  public slide: SlideFEM;
  public quiz: QuizQuestion;

  // Buttons
  public nextLessonButtonLabel: string;

  // Disables
  public isPrevLessonDisabled: boolean = false;
  public isPrevQuestionDisabled: boolean = false;
  public isNextQuestionDisabled: boolean = true;

  public icons = {
    nextLesson: faArrowCircleRight,
    prevLesson: faArrowCircleLeft,
    nextItem: faArrowRight,
    prevItem: faArrowLeft,
  };

  constructor(private _lessons: LessonsService, private _slides: SlidesService, private _quiz: QuizService) {}

  ngOnInit() {
    // Subscribe to all services this component needs reactive data from and store the subscription refs in a list
    this._subs.push(
      this._lessons.currentLesson$.subscribe((lesson) => (this.lesson = lesson)),
      this._lessons.currentSection$.subscribe((section) => (this.section = section)),
      this._slides.currentSlide$.subscribe((slide) => (this.slide = slide)),
      this._quiz.currentQuizQuestion$.subscribe((quiz) => (this.quiz = quiz)),
      this._quiz.currentQuizQuestionCompleteEvent.subscribe((bool) => (this.isNextQuestionDisabled = !bool)),
    );
  }

  ngAfterViewChecked() {
    // set next button label
    {
      if (this.lesson.isLastInCourse) {
        this.nextLessonButtonLabel = 'Finish Course';
      } else if (this.section.isCompleted) {
        this.nextLessonButtonLabel = 'Next Lesson';
      } else {
        this.nextLessonButtonLabel = 'Complete & Continue';
      }
    }

    {
      if (this.lesson.isFirstInCourse && this.section.isFirstInLesson) {
        this.isPrevLessonDisabled = true;
      } else {
        this.isPrevLessonDisabled = false;
      }
    }

    // set quiz controls
    {
      if (this.quiz.isFirstQuestion) {
        this.isPrevQuestionDisabled = true;
      }
    }

    // disable next quiz button if on last question
    {
      if (this.quiz.isLastQuestion) {
        this.isNextQuestionDisabled = true;
      }
    }
  }

  ngOnDestroy() {
    // Clean up all subscriptions
    this._subs.forEach((sub) => sub.unsubscribe());
    this._subs = [];
  }

  /**
   * Hook up events to service methods that do the processing.
   */
  public handle(command: LMSEvent) {
    switch (command) {
      case 'prev-lesson':
        return this._lessons.selectPrevLesson();
      case 'next-lesson':
        return this._lessons.selectNext();
      case 'prev-slide':
        return this._slides.selectPrevSlide();
      case 'next-slide':
        return this._slides.selectNextSlide();
      case 'prev-question':
        return this._quiz.prevQuizQuestion();
      case 'next-question':
        return this._quiz.nextQuizQuestion();
      default:
        console.warn(`Unhandled command: ${command}`);
        return;
    }
  }
}
