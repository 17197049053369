import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { defaultSlide } from '../courses/courses.service.models';

@Injectable({
  providedIn: 'root',
})
export class SlidesService {
  public currentSlideDeck$ = new BehaviorSubject([]);
  public currentSlide$ = new BehaviorSubject(defaultSlide);

  public loadSlides(lesson) {
    const slides = lesson.content.slides.map((slide, index) => {
      return {
        url: slide,
        isFirstSlide: index === 0,
        isLastSlide: index === lesson.content.slides.length - 1,
        sequence: index + 1,
        numberOfSlides: lesson.content.slides.length,
      };
    });

    this.currentSlideDeck$.next(slides);
    this.currentSlide$.next(slides[0]);
  }

  public selectNextSlide() {
    const currentIndex = this.currentSlideDeck$.value.indexOf(this.currentSlide$.value);
    const isLastSlide = currentIndex >= this.currentSlideDeck$.value.length - 1;

    if (isLastSlide) {
      return; // do nothing
    } else {
      // select next slide
      const nextSlide = this.currentSlideDeck$.value[currentIndex + 1];
      this.currentSlide$.next(nextSlide);
    }
  }

  public selectPrevSlide() {
    const currentIndex = this.currentSlideDeck$.value.indexOf(this.currentSlide$.value);
    const isFirstSlide = currentIndex === 0;

    if (isFirstSlide) {
      return; // do nothing
    } else {
      // select prev slide
      const nextSlide = this.currentSlideDeck$.value[currentIndex - 1];
      this.currentSlide$.next(nextSlide);
    }
  }
}
