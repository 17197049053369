import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IconDefinition, faPhone, faEnvelope, faGlobe } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-public-profile-preview',
  templateUrl: './public-profile-preview.component.html',
  styleUrls: ['./public-profile-preview.component.scss'],
})
export class PublicProfilePreviewComponent implements OnInit {
  public phoneIcon: IconDefinition = faPhone;
  public emailIcon: IconDefinition = faEnvelope;
  public siteIcon: IconDefinition = faGlobe;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private _dialogRef: MatDialogRef<any>) {}

  ngOnInit() {}

  public playVideo(url) {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    const id = match && match[7].length === 11 ? match[7] : false;

    return `https://www.youtube.com/embed/${id}?feature=oembed`;
  }

  // Handlers --

  public formatDescription() {
    const description = this.data?.description;

    // we need to replace return carriges with <br />
    // eslint-disable-next-line
    const result = description.replace(new RegExp('\n', 'g'), '<br />');

    return result;
  }

  public getProvidedLanguages() {
    return Object.entries(this.data.languagesProvided).filter((lang) => lang[1] === true);
  }

  public closePreview() {
    this._dialogRef.close();
  }
}
