import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'pricing-heading',
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.scss'],
})
export class HeadingComponent implements OnInit {
  @Input() text = '%% PLACEHOLDER %%';

  constructor() {}

  ngOnInit(): void {}
}
