import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorHandlerModule } from './errors/errors.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { HeadersInterceptor } from './interceptors/headers.interceptor';

@NgModule({
  declarations: [],
  imports: [CommonModule, ErrorHandlerModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HeadersInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
  ],
})
export class CoreModule {}
