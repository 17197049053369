<div class="bundle-card">
  <h2>{{ bundle.title }}</h2>
  <p *ngIf="!bundle.hiddenPrice">
    &#36;{{ bundle.price }} {{ bundle.currency || 'CAD' }}
    {{ bundle.subscription ? '/Month' : '' }}<br />
    <small *ngIf="bundle.studentsIncluded">
      {{ bundle.studentsIncluded }} student bundle
    </small>
  </p>
  <div class="col-12 px-0 d-flex justify-content-center edit">
    <div>
      <div
        [ngStyle]="{ cursor: 'pointer' }"
        (click)="editBundle(bundle)"
      >
        <fa-icon [icon]="editIcon"></fa-icon> Edit bundle
      </div>

      <div
        *ngIf="bundle.isRegularBundle && !bundle.globalConfigId"
        [ngStyle]="{ cursor: 'pointer' }"
        (click)="setRegularBundleToDefault(bundle.id)"
      >
        <fa-icon [icon]="deleteIcon"></fa-icon> Set to default
      </div>
    </div>
  </div>
</div>
