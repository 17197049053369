<div class="buttons d-flex justify-content-center my-2">
  <button
    class="mx-2 defaultButton"
    mat-raised-button
    (click)="openBundleCreator()"
  >
    Create new bundle
  </button>
</div>

<div class="mainContainer">
  <div
    *ngFor="let bundle of bundles"
    class="bundle-card mx-2 my-2"
  >
    <div>
      <div class="d-flex justify-content-between my-4">
        <mat-checkbox
          [(ngModel)]="bundle.subscription"
          class="mx-2"
          [value]="bundle.subscription"
          [checked]="bundle.subscription"
        >
          Subscription
        </mat-checkbox>

        <mat-checkbox
          [(ngModel)]="bundle.isFirstPurchaseBundle"
          class="mx-2"
          [value]="bundle.isFirstPurchaseBundle"
          [checked]="bundle.isFirstPurchaseBundle"
        >
          First Purchase
        </mat-checkbox>
      </div>

      <div class="text-center month">
        <mat-form-field appearance="outline">
          <mat-label>Title</mat-label>
          <input
            matInput
            placeholder="Title"
            [value]="bundle.title"
            [(ngModel)]="bundle.title"
          />
        </mat-form-field>
      </div>

      <div class="text-center month">
        <mat-form-field appearance="outline">
          <mat-label>Price</mat-label>
          <input
            matInput
            type="number"
            required
            placeholder="Price"
            [value]="bundle.price"
            [(ngModel)]="bundle.price"
          />
        </mat-form-field>
      </div>

      <div class="text-center month">
        <mat-form-field appearance="outline">
          <mat-label>Yearly price</mat-label>
          <input
            [(ngModel)]="bundle.yearlyPrice"
            [disabled]="!bundle.subscription"
            matInput
            type="number"
            required
            placeholder="Price"
            [value]="bundle.yearlyPrice || 0"
          />
        </mat-form-field>
      </div>

      <div class="text-center month">
        <mat-form-field appearance="outline">
          <mat-label>Students included</mat-label>
          <input
            [(ngModel)]="bundle.studentsIncluded"
            matInput
            type="number"
            required
            placeholder="Students included"
            [value]="bundle.studentsIncluded"
          />
        </mat-form-field>
      </div>

      <div>
        <button
          (click)="saveBundle(bundle)"
          class="mx-2"
          mat-raised-button
        >
          Save
        </button>

        <button
          (click)="deleteBundle(bundle.id)"
          class="mx-2"
          mat-raised-button
        >
          Delete
        </button>
      </div>
    </div>
  </div>
</div>
