import { Injectable, ErrorHandler, NgZone } from '@angular/core';
import { ErrorHandlerService } from '../services/error-handler.service';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { captureException } from '@sentry/angular-ivy';

/**
 * This class provides a central place to deal with exceptional javascript errors.
 */
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private zone: NgZone, private errorDialogService: ErrorHandlerService, private logger: LoggerService) {}

  handleError(error: Error) {
    // Log error
    this.logger.error(error);

    // Send error to clarity
    (window as any)?.clarity?.('event', 'error');

    // Send error to Sentry
    const sentryErrorId = captureException(error);

    // Send sentry error id to clarity
    (window as any)?.clarity?.('set', 'sentryErrorId', sentryErrorId);

    // Disable error dialog from showing inside Unity game
    if (window.frameElement) {
      return;
    }

    // If the error comes from an request error, ignore, it should be handled by the http interceptor
    if (error?.message?.includes('Http failure response')) {
      return;
    }

    // Send error to dialog
    this.sendError(error);
  }

  /**
   * Helpers
   */
  getErrorType(error) {
    return error.stack.split(':')[0];
  }

  sendError(error) {
    if (!error) {
      return;
    }

    this.zone.run(() =>
      this.errorDialogService.openDialog({
        message: error.message,
        type: this.getErrorType(error),
        stack: error.stack,
      }),
    );
  }
}
