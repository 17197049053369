import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { RestAPIService } from '../../services/rest/rest-api.service';
import { MaterialComponentsModule } from 'src/app/shared/modules/material-components.module';
import { LayoutsModule } from 'src/app/shared/layouts/layouts.module';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SafeUrlModule } from 'src/app/shared/pipes/safe-url/safe-url.module';
import { LayoutComponentsModule } from 'src/app/shared/components/layout/layout-components.module';

import { MatDialogModule } from '@angular/material/dialog';

import { CoursesListModule } from './pages/courses-list/courses-list.module';
import { CourseOverviewPageModule } from './pages/course-overview/course-overview.module';
import { SideMenuModule } from 'src/app/shared/components/side-menu/side-menu.module';

import { CoursesService } from './services/courses/courses.service';
import { LearningSectionModule } from './pages/learning-section/learning-section.module';
import { CourseCompletionDialog } from './utils/course-completion-dialog/course-completion-dialog.component';
import { LessonsService } from './services/lessons/lessons.service';

@NgModule({
  declarations: [CourseCompletionDialog],
  imports: [
    BrowserModule,
    RouterModule,
    HttpClientModule,
    MaterialComponentsModule,
    LayoutsModule,
    MatCardModule,
    MatExpansionModule,
    FontAwesomeModule,
    SafeUrlModule,
    LayoutComponentsModule,
    MatDialogModule,
    SideMenuModule,
    CoursesListModule,
    CourseOverviewPageModule,
    LearningSectionModule,
  ],
  providers: [RestAPIService, CoursesService, LessonsService],
})
export class CoursesModule {}
