<app-public-layout>
  <div
    id="NotFound"
    class="container"
  >
    <img src="./assets/img/NotFound.png" />

    <h1>Oops! Something went wrong :(</h1>
    <h3>
      The page you're looking for doesn't exist.<br />
      We have lots of other nice pages to check out though, so have a look
      around!
    </h3>
  </div>
</app-public-layout>
