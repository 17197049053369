import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'users-add-student-button',
  templateUrl: './add-student-button.component.html',
  styleUrls: ['./add-student-button.component.scss'],
})
export class AddStudentButtonComponent {
  @Output() onAddStudent = new EventEmitter();

  public addStudent() {
    this.onAddStudent.emit();
  }
}
