import { Component } from '@angular/core';
import { RoleService } from 'src/app/services/roles/role.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  public isUserLoggedIn = this._roles.isLogged();
  constructor(private _roles: RoleService) {}
}
