import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import {
  Category,
  Course,
  CourseLesson,
  CourseProgress,
  Section,
} from '../../configuration-pages/interfaces/global-config.interfaces';
import { get } from 'lodash';
import { VideoLessonContentFEM } from '../../courses/services/courses/courses.service.models';
import { IconDefinition, faSpinner, faLock, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { Student } from '../../students/interfaces/student.interface';
import { VideoCategoryService } from '../video-category-service/video-category.service';

interface DialogData {
  category: Category;
  student: Student;
}

interface StudentProgressData {
  course: Course;
  progress: CourseProgress;
}

interface CourseCategoryContent {
  contentList: VideoLessonContentFEM[];
  completed: boolean;
  title: string;
  id: string;
  isUnlocked?: boolean;
}

@Component({
  selector: 'app-video-category',
  templateUrl: './video-category.component.html',
  styleUrl: './video-category.component.scss',
})
export class VideoCategoryComponent implements OnInit {
  @ViewChild('containerWrapper') containerWrapper: ElementRef<HTMLDivElement>;

  public courseList: Course[] = [];
  public categoryContent: CourseCategoryContent[] = [];
  public loading = true;
  public selectedContent: CourseCategoryContent = null;
  public progressData: CourseProgress = null;
  public courseData: Course = null;
  public readonly loadingIcon: IconDefinition = faSpinner;
  public readonly lock: IconDefinition = faLock;
  public readonly check: IconDefinition = faCircleCheck;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<VideoCategoryComponent>,
    private rest: RestAPIService,
    private _snackBar: MatSnackBar,
    private videoCategoryService: VideoCategoryService,
  ) {}

  async ngOnInit() {
    if (this.isImportedFromCourse(this.data.category)) {
      await this.getCourseData();
    }

    // TODO add logic to use video categories that wasnt imported from courses

    this.loading = false;

    if (this.selectedContent) {
      setTimeout(() => {
        const scroll: HTMLElement = document.querySelector('.containerWrapper');
        const index = this.categoryContent.indexOf(this.selectedContent);

        if (scroll) {
          scroll.scrollTop = 50 * index;
        }
      }, 100);
    }
  }

  async getCourseData() {
    try {
      const data: StudentProgressData = await this.videoCategoryService.getVideoCategoryProgress(
        this.data.category.courseId,
        this.data.student.id,
      );

      if (!data) {
        this.dialogRef.close();
      }

      this.progressData = get(data, 'progress', null);
      this.courseData = get(data, 'course', null);

      if (!this.courseData) {
        throw new Error('Course not found');
      }

      const lessons = data.course.lessons.filter((l) => !l.draft);

      let contentOpened = false;

      for (const lesson of lessons) {
        const sections = lesson.sections.filter((s) => !s.draft && s.type === 'video');

        const content: CourseCategoryContent = {
          contentList: sections.map((s) => {
            return {
              url: s.content.url,
              text: s.content.text,
              title: s.title,
              completed: this.isSectionCompleted(s, lesson),
              id: s._id,
            };
          }),
          completed: this.isLessonCompleted(lesson),
          title: lesson.title,
          id: lesson._id,
        };

        this.categoryContent.push(content);

        if (!content.completed && !contentOpened) {
          this.selectedContent = content;
          contentOpened = true;
        }
      }

      this.checkLockedContent();
    } catch (error) {
      this._snackBar.open('Data not found, please try again! If the problem persist contact our support', 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });

      this.dialogRef.close();
    }
  }

  public checkLockedContent() {
    this.categoryContent.map((content) => {
      content.isUnlocked = this.isLessonUnlocked(content);
    });
  }

  public isLessonUnlocked(content: CourseCategoryContent) {
    if (!this.progressData) {
      return false;
    }

    const relatedLesson = this.progressData.lessons.find((l) => l.lessonId === content.id);

    if (!relatedLesson) {
      return false;
    }

    const index = this.progressData.lessons.indexOf(relatedLesson);

    if (index === 0) {
      return true;
    }

    const previousLesson = this.progressData.lessons[index - 1];

    return previousLesson.completed;
  }

  public isSectionCompleted(section: Section, lesson: CourseLesson): boolean {
    if (!this.progressData) {
      return false;
    }

    const progressLesson = this.progressData.lessons.find((l) => l.lessonId === lesson._id);

    if (!progressLesson) {
      return false;
    }

    const progressSection = progressLesson.sections.find((s) => s.sectionId === section._id);

    return get(progressSection, 'completed', false);
  }

  public isLessonCompleted(lesson: CourseLesson): boolean {
    if (!this.progressData) {
      return false;
    }

    const progressLesson = this.progressData.lessons.find((l) => l.lessonId === lesson._id);

    return get(progressLesson, 'completed', false);
  }

  public async playVideo(videoSection: VideoLessonContentFEM, content: CourseCategoryContent) {
    const relatedLesson = this.progressData.lessons.find((l) => l.lessonId === content.id);

    if (relatedLesson) {
      const section = relatedLesson.sections.find((s) => s.sectionId === videoSection.id);
      const index = relatedLesson.sections.indexOf(section);
      videoSection.isLast = index >= relatedLesson.sections.length - 1;

      await this.videoCategoryService.saveVideoCategoryProgress(this.progressData, content, section.sectionId);
    }

    const progressData = this.progressData;

    const videoContent = {
      progressData,
      content,
      videoSection,
    };

    this.dialogRef.close(videoContent);
  }

  public isImportedFromCourse(category: Category) {
    return get(category, 'importedFromCourse', false) === true;
  }

  public close() {
    this.dialogRef.close();
  }
}
