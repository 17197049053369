import { Component, OnInit } from '@angular/core';
import { PublicMessageBannerService } from './public-message-banner.service';
import { Subscription } from 'rxjs';
import { RoleService } from 'src/app/services/roles/role.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-public-message-banner',
  templateUrl: './public-message-banner.component.html',
  styleUrls: ['./public-message-banner.component.scss'],
})
export class PublicMessageBannerComponent implements OnInit {
  form = new FormGroup({
    toggle: new FormControl(false),
    text: new FormControl(''),
    password: new FormControl(''),
  });

  sub: Subscription;
  toggleSub: Subscription;

  showInccorrectPasswordMessage = false;

  constructor(public pmbService: PublicMessageBannerService, public roles: RoleService, private _auth: AuthService) {}

  ngOnInit(): void {
    this.sub = this.pmbService.publicMessage$.subscribe((result) => {
      this.form.controls.text.setValue(result.text);
      this.form.controls.toggle.setValue(result.enabled);
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  submit() {
    const password = this.form.controls.password.value;
    const text = this.form.controls.text.value;
    const enabled = this.form.controls.toggle.value;

    this.pmbService.update({ password, text, enabled });

    this.form.markAsPristine();
    this.form.controls.password.setValue('');
  }

  logout() {
    this._auth.logout();
  }
}
