export enum subscriptionMenuOptions {
  outsiderSubscriptions = 'outsiderSubscriptions',
  mySubscriptions = 'mySubscriptions',
}

export enum subscriptionsStatus {
  canceled = 'canceled',
  active = 'active',
  underCancelation = 'underCancelation',
  paused = 'paused',
  past_due = 'past_due',
  unpaid = 'unpaid',
  waiting_for_payment = 'waitingForPayment',
  incomplete = 'incomplete',
}

export enum subscriptionStatusMessage {
  active = 'Active',
  canceled = 'Canceled',
  paused = 'Paused',
  waiting_for_payment = 'Waiting for payment',
  underCancelation = 'Cancel at',
}

export const isCanceled = (subscription): boolean => {
  return subscription.status === subscriptionsStatus.canceled;
};

export const isActive = (subscription): boolean => {
  return (
    subscription.status === subscriptionsStatus.active &&
    !subscription.underCancelation &&
    !subscription.pausedSubscription
  );
};

export const isUnderCancelation = (subscription): boolean => {
  return subscription.underCancelation && subscription.status !== subscriptionsStatus.canceled;
};

export const isPaused = (subscription): boolean => {
  return (
    subscription.pausedSubscription &&
    !subscription.underCancelation &&
    subscription.status !== subscriptionsStatus.canceled
  );
};

export const isWaitingForPayment = (subscription): boolean => {
  return (
    (subscription.status === subscriptionsStatus.past_due ||
      subscription.status === subscriptionsStatus.unpaid ||
      subscription.status === subscriptionsStatus.incomplete) &&
    !subscription.underCancelation
  );
};

export const getDate = (date) => {
  const correctDate = Number(date + '000');
  const dateFormat = new Date(correctDate);
  return ` ${dateFormat.getDate()} / ${dateFormat.getMonth() + 1} / ${dateFormat.getFullYear()} `;
};

export const getSubscriptionStatusClass = (subscription) => {
  if (isCanceled(subscription)) {
    return subscriptionsStatus.canceled;
  } else if (isActive(subscription)) {
    return subscriptionsStatus.active;
  } else if (isPaused(subscription)) {
    return subscriptionsStatus.paused;
  } else if (isUnderCancelation(subscription)) {
    return subscriptionsStatus.underCancelation;
  } else if (isWaitingForPayment(subscription)) {
    return subscriptionsStatus.waiting_for_payment;
  }
};

export const getSubscriptionStatus = (subscription) => {
  if (isCanceled(subscription)) {
    return subscriptionStatusMessage.canceled;
  } else if (isActive(subscription)) {
    return subscriptionStatusMessage.active;
  } else if (isPaused(subscription)) {
    return subscriptionStatusMessage.paused;
  } else if (isUnderCancelation(subscription)) {
    return subscriptionStatusMessage.underCancelation + ` ${getDate(subscription.currentPeriodEnd)}`;
  } else if (isWaitingForPayment(subscription)) {
    return subscriptionStatusMessage.waiting_for_payment;
  }
};
