import { Component, Input } from '@angular/core';
import { faCheck, IconDefinition } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-pricing-card',
  template: `
    <div
      class="pricing-card"
      [ngClass]="{ 'pro-bundle': isProBundle }"
    >
      <h2>{{ title }}{{ isProBundle ? '*' : '' }}</h2>
      <p>
        &#36;{{ price }} CAD<br />
        <small>{{ studentsIncluded ? studentsIncluded + ' student bundle' : '' }}</small>
      </p>

      <ul>
        <li *ngFor="let feature of includedFeatures"><fa-icon [icon]="checkIcon"></fa-icon> {{ feature }}</li>
      </ul>
    </div>
  `,
  styleUrls: ['./pricing-card.component.scss'],
})
export class PricingCardComponent {
  @Input() isProBundle = false;
  @Input() title: string;
  @Input() price: number;
  @Input() studentsIncluded: number;
  @Input() includedFeatures: string[] = [];

  public checkIcon: IconDefinition = faCheck;
}
