import { progressTypes } from 'src/app/shared/consts/global-constants';

export enum StudentLevel {
  JR = 0,
  P1 = 1,
  P2 = 2,
  P3 = 3,
  P4 = 4,
}

export enum GameTag {
  CognitiveTherapy = 'cognitive therapy',
  CognitiveTherapy30Min = 'cognitive therapy 30 min',
  ReadingExercises = 'reading exercises',
  Assessment = 'assessment',
  SpeedReading = 'speed reading',
  Gumball = 'gumball',
  BrainGames = 'Brain Games',
}

export const VIDEO = 'video';

export const EXCLUDED_EXERCISES_TYPE = [VIDEO];

// TODO find a way to add the menu images dinamically
export const fiveDaysMenu = (session: number, lang: string, day: number, theme: string) => {
  return {
    default: `assets/img/fiveDaysMenu/day${session}${theme}_${lang}.png`,
    locked: `assets/img/fiveDaysMenu/day${day}locked${theme}_${lang}.png`,
    checked: `assets/img/fiveDaysMenu/day${day}checked${theme}_${lang}.png`,
  };
};

export const ASSESSMENT = 'assessment';

export const programName = 'Neuralign';

export const defaultDescriptions = {
  [progressTypes.ASSESSMENT]: {
    Unlocks:
      '3 unlocks. First unlock is at the beginning of program to establish a baseline. Second unlock is a check-in after the Cognitive Therapy path is completed. Final unlock is after the full program has been completed for a full assessment of the treatment.',
    Sessions: 'About 10 minutes each.',
  },
  [progressTypes.COGNITIVE_THERAPY]: {
    Unlocks:
      'The first Cognitive Therapy will unlock after the first assessment is completed, or if a provider overrides the assessment.  Each session after will unlock after 6 hours are up.',
    Sessions: 'Cognitive Therapy has 15 X 1 hour sessions.',
  },
  [progressTypes.BRAIN_GAMES]: {
    Unlocks: 'No locks',
    Sessions: '3 to 5 minutes per day / 5 x week / 10 weeks.',
  },
  [progressTypes.SPEED_READING]: {
    Unlocks: 'After the session 9th Cognitive Therapy is completed.',
    Sessions: '1 minute per day / 5 x week / 10 weeks.',
  },
  [progressTypes.READING_EXERCISES]: {
    Unlocks: 'After the Cognitive Therapy is completed.',
    Sessions: '10 to 20 minutes per day / 5 x week / 10 weeks.',
  },
};

export const clientBlockedAccessMessage =
  'You dont have access to this category. Check if your provider activated the remote access';

export const clientFailedPaymentBlockedAccessMessage =
  'We couldnt verify your last payment, please check your card information';
