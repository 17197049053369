<menu>
  <button (click)="closeMobileMenu()">
    <fa-icon
      [icon]="closeIcon"
      class="icon"
    ></fa-icon>
  </button>
  <header-logo></header-logo>
  <button
    *ngFor="let link of nav"
    (click)="navigate(link)"
  >
    {{ link.title }}
  </button>
  <header-login-btn></header-login-btn>
</menu>
