<app-main-layout
  [breadcrumbs]="[
    { name: 'Home', url: '/' },
    { name: 'Programs', url: '/programs' }
  ]"
>
  <div
    class="inner-content"
    [ngClass]="{ empty: !programs || programs.length === 0 }"
  >
    <ng-container *ngIf="programs && programs.length > 0">
      <h2>My Programs</h2>

      <table
        mat-table
        [dataSource]="programs"
      >
        <ng-container matColumnDef="name">
          <th
            mat-header-cell
            *matHeaderCellDef
          >
            Name
          </th>
          <td
            mat-cell
            *matCellDef="let row"
          >
            {{ row.name }}
          </td>

          <td></td
        ></ng-container>

        <ng-container matColumnDef="actions">
          <th
            mat-header-cell
            *matHeaderCellDef
          >
            Actions
          </th>
          <td
            mat-cell
            *matCellDef="let row"
          >
            --
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
        ></tr>
      </table>
    </ng-container>

    <ng-container *ngIf="!programs || programs.length === 0">
      <app-empty-state
        text="You haven't acquired any programs yet"
        [icon]="gamepadIcon"
      ></app-empty-state>
      <p class="purchase">
        Would you like to
        <a routerLink="/purchase-tokens/0"> buy tokens now? </a>
      </p>
    </ng-container>
  </div>
</app-main-layout>
