import { Injectable } from '@angular/core';
import { UserTypes } from 'src/app/pages/configuration-pages/course-configurations/components/learning-management-system/utils/course-utils';
import { tutorialVideos } from 'src/app/shared/consts/global-constants';
import { RoleService } from '../roles/role.service';
import { MatDialog } from '@angular/material/dialog';
import { TutorialModalComponent } from 'src/app/shared/components/tutorial-modal/tutorial-modal.component';
import { isEmpty } from 'lodash';
import { RestAPIService } from '../rest/rest-api.service';
import { User } from 'src/app/shared/models';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class TutorialsService {
  public tutorialVideos = tutorialVideos;
  public tutorialUrl = this.tutorialVideos[UserTypes.ORGANIZATIONS];

  constructor(
    private roleService: RoleService,
    private dialog: MatDialog,
    private rest: RestAPIService,
    private auth: AuthService,
  ) {}

  public async setupTutorial(user: User) {
    const { lastLogin } = user;

    if (this.roleService.isB2CPortal()) {
      this.tutorialUrl = this.tutorialVideos[UserTypes.B2C];
    } else if (this.roleService.isOrgClient()) {
      this.tutorialUrl = this.tutorialVideos[UserTypes.CLIENTS];
    }

    const dialog = this.dialog.open(TutorialModalComponent, {
      width: '806px',
      panelClass: 'modal-border',
      data: this.tutorialUrl,
      disableClose: true,
      height: '500px',
    });

    dialog.afterClosed().subscribe(async () => {
      // check if the user already have the last login property to dont have it more than once
      if (isEmpty(lastLogin) && user) {
        user.lastLogin = new Date().toJSON();
        await this.rest.put('user/self', { user: user }, { msg: 'Could not put user.' });
        await this.auth.getUser(true);
      }
    });
  }
}
