import { Component, Inject, ViewChild, AfterViewInit, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-subscription-payments-dialog',
  templateUrl: './subscription-payments-dialog.component.html',
  styleUrls: ['./subscription-payments-dialog.component.scss'],
})
export class SubscriptionPaymentsDialogComponent implements AfterViewInit {
  dataSource: MatTableDataSource<any>;
  public price: number;
  public studentsIncluded: number;
  public loading = false;
  public downloadIcon: IconDefinition = faDownload;
  displayedColumns: string[] = ['date', 'value', 'status', 'download'];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  refreshDialog = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<SubscriptionPaymentsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.dataSource = new MatTableDataSource(this.data);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  public getDate(date) {
    const correctDate = Number(date + '000');
    const dateFormat = new Date(correctDate);
    return ` ${dateFormat.getDate()} / ${dateFormat.getMonth() + 1} / ${dateFormat.getFullYear()} `;
  }

  public closeDialog() {
    this.dialogRef.close();
  }
}
