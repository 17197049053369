import { Component, Input } from '@angular/core';

/**
 * @scope Displays section title with a sequence number starting at 1.
 */
@Component({
  selector: 'course-overview-lesson-item',
  templateUrl: './lesson-item.component.html',
  styleUrls: ['./lesson-item.component.scss'],
})
export class LessonItemComponent {
  @Input() lesson;
  @Input() sequence;
}
