<ng-container
  *ngIf="isOrgsPortal; then orgsPortalHero; else defaultPortalHero"
></ng-container>

<ng-template #orgsPortalHero>
  <home-orgs-portal-hero></home-orgs-portal-hero>
</ng-template>

<ng-template #defaultPortalHero>
  <home-default-portal-hero></home-default-portal-hero>
</ng-template>
