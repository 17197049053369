<ng-container *ngIf="!isLoading">
  <button
    (click)="downloadClientsFile()"
    matTooltip="Import clients file example"
    matTooltipPosition="above"
  >
    <fa-icon [icon]="fileLIcon"></fa-icon>
  </button>
</ng-container>

<ng-container *ngIf="!isLoading">
  <button
    (click)="downloadStudentFile()"
    matTooltip="Import students file example"
    matTooltipPosition="above"
  >
    <fa-icon [icon]="fileLinesIcon"></fa-icon>
  </button>
</ng-container>

<ng-container *ngIf="!isLoading">
  <button
    (click)="exportData()"
    matTooltip="Export"
    matTooltipPosition="above"
  >
    <fa-icon [icon]="exportIcon"></fa-icon>
  </button>
</ng-container>

<ng-container *ngIf="!isLoading">
  <button
    matTooltip="Import clients from a file"
    matTooltipPosition="above"
  >
    <input
      type="file"
      (change)="importFile($event)"
      style="display: none"
      #fileInput
    />
    <fa-icon
      [icon]="importIcon"
      (click)="fileInput.click()"
    ></fa-icon>
  </button>
</ng-container>

<ng-container *ngIf="isLoading">
  <mat-spinner [diameter]="25"></mat-spinner>
</ng-container>
