import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationClass } from '../../shared/classes/notification.class';
import { Component } from '@angular/core';
import { RestAPIService } from '../../services/rest/rest-api.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faMapMarkerAlt, faPhone } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent extends NotificationClass {
  public formGroup: UntypedFormGroup;
  public mapIcon: IconDefinition = faMapMarkerAlt;
  public phoneIcon: IconDefinition = faPhone;

  constructor(protected _snackBar: MatSnackBar, private _restService: RestAPIService) {
    super(_snackBar);
    this.formGroup = this._createContactForm();
  }

  public async sendMail() {
    try {
      const formData = this.formGroup.getRawValue();
      await this._restService.post(
        'contact',
        {
          email: formData,
        },
        { msg: 'Could not post contact.' },
      );
      this.formGroup = this._createContactForm();
      this.notify('Email sent successfully!');
      // eslint-disable-next-line no-empty
    } catch {}
  }

  private _createContactForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required]),
      country: new UntypedFormControl('', [Validators.required]),
      email: new UntypedFormControl('', [Validators.required]),
      subject: new UntypedFormControl('', [Validators.required]),
      body: new UntypedFormControl('', [Validators.required]),
    });
  }
}
