<ng-container *ngIf="template === 'add-program'">
  <users-table-action-icon
    [icon]="'plus'"
    [tooltip]="'Add program'"
    (click)="handleClick('add')"
  ></users-table-action-icon>
</ng-container>

<ng-container *ngIf="template === 'remove-program'">
  <users-table-action-icon
    [icon]="'minus'"
    [tooltip]="'Remove program'"
    (click)="handleClick('remove')"
  ></users-table-action-icon>
</ng-container>

<ng-container *ngIf="template === 'checkmark'">
  <users-table-action-icon
    [icon]="'checkmark'"
    [tooltip]="'Success!'"
  ></users-table-action-icon>
</ng-container>

<ng-container *ngIf="template === 'locked'">
  <users-table-action-icon
    [icon]="'learning'"
    [tooltip]="'Student is in progress'"
    [disabled]="true"
  ></users-table-action-icon>
</ng-container>

<ng-container *ngIf="template === 'loading'">
  <mat-spinner [diameter]="25"></mat-spinner>
</ng-container>
