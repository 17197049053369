import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Patron } from 'src/app/core/openapi';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';

interface Admin {
  name: string;
  emailVerified?: string;
  id?: string;
  patron?: Patron;
}

@Injectable({
  providedIn: 'root',
})
export class AdminsListService {
  private adminSource = new BehaviorSubject<Admin>(null);
  public currentAdmin = this.adminSource.asObservable();
  admins: Admin[];

  constructor(
    private _logger: LoggerService,
    private _rest: RestAPIService,
    private _router: Router,
    private _snackBar: MatSnackBar,
  ) {}

  public changeAdmin(admin: Admin): void {
    this.adminSource.next(admin);
  }

  public async getAdmins(options?: { refresh: boolean }): Promise<Admin[]> {
    try {
      if (options?.refresh || !this.admins) {
        const response = await this._rest.get('organization/self/managers');
        this.admins = response.managers
          .filter((user) => user.role === 'admin')
          .filter((user) => user.patron)
          .map((admin) => ({
            ...admin,
            name: admin.patron.givenName,
            status: transformAdminStatus(admin),
            type: 'Admin',
          }));
      }
      return this.admins;
    } catch (err) {
      this._logger.error(err);
    }
  }

  public editAdmin(admin: Admin): void {
    localStorage.setItem('LS_OrgEditingUser', JSON.stringify(admin));
    this._router.navigate(['/users/manage/' + admin.patron.id]);
  }

  public resendAdminVerificationEmail(admin: any): void {
    const adminName = admin.fullname ? admin.fullname : admin.name;

    this._rest
      .get('account/email/verify/resend/' + admin.accountId, {
        msg: 'Could not get account/email/resend/:adminAccountId.',
      })
      .then(() => {
        this._snackBar.open(`Verification email has been sent to ${adminName}`, 'Close', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      })
      .catch(() => {
        this._snackBar.open('E-mail not found', 'Close', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      });
  }
}

function transformAdminStatus(manager: Admin) {
  if (manager.emailVerified) {
    return 'active';
  } else if (!manager.emailVerified) {
    return 'not-active';
  } else if (manager.emailVerified === undefined) {
    return 'error';
  }
}
