import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { faTrash, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';

@Component({
  selector: 'app-tax-rates',
  templateUrl: './tax-rates.component.html',
  styleUrls: ['./tax-rates.component.scss'],
})
export class TaxRatesComponent implements OnInit {
  constructor(private _rest: RestAPIService, protected snackbar: MatSnackBar, private _confirm: ConfirmationService) {}

  public readonly trash: IconDefinition = faTrash;

  public taxes: any[];

  public addingNewTax = false;

  public readonly taxesColums: string[] = ['state', 'description', 'percentage', 'actions'];

  async ngOnInit() {
    await this.getTaxes();
  }

  public async getTaxes() {
    const taxes = await this._rest.get('admin/getTaxes', { msg: 'Could not get stripe taxes' });

    if (taxes) {
      this.taxes = taxes;
    } else {
      this.taxes = [];
    }
  }

  public async archiveTax(id: string) {
    this._confirm
      .createConfirmation('Warning', 'Are you sure you want to archive this tax?', 'Yes', 'No')
      .then(async () => {
        try {
          await this._rest.put(
            'admin/taxes/archive/' + id,
            { active: false },
            { msg: 'Could not archive the selected tax' },
          );
          await this.ngOnInit();
        } catch (error) {
          this.snackbar.open(`Could not archive this tax`, 'Close', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }
      });
  }
}
