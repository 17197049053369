<div class="card-holder">
  <div class="left card">
    <ng-content select="[left]"></ng-content>
  </div>

  <div class="middle card">
    <ng-content select="[middle]"></ng-content>
  </div>

  <div class="right card">
    <ng-content select="[right]"></ng-content>
  </div>
</div>
