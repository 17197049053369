<section #callToActionBox>
  <h1
    montserrat
    primaryColour
    extraLargeSize
  >
    The most accessible, <br />
    high-tech learning <br />
    intervention.
  </h1>

  <p
    montserrat
    primaryColour
    mediumSize
  >
    Because everyone deserves the <br />
    chance to love learning.
  </p>

  <div #buttonsBox>
    <button
      primaryBtn
      mediumSize
      (click)="tour()"
    >
      Take a Tour
    </button>
    <button
      outlineBtn
      mediumSize
      (click)="signup()"
    >
      Become a Provider
    </button>
  </div>
</section>

<div>
  <img
    src="assets\img\slides\homepage-header.webp"
    width="100%"
  />
</div>
