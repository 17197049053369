const MIN_VIEWPORT_WIDTH = 320;
const MAX_VIEWPORT_WIDTH = 2560;

/**
 * This function takes in a minimum and maximum size (in rem units) for text or box elements
 * and returns a CSS clamp function that perfectly scales the size between the smallest
 * and largest screen sizes.
 */
export function clamp(min: number, max: number): string {
  const minInPx = min * 16;
  const maxInPx = max * 16;

  const viewportValue = (100 * (maxInPx - minInPx)) / (MAX_VIEWPORT_WIDTH - MIN_VIEWPORT_WIDTH);

  const relativeValue =
    (MIN_VIEWPORT_WIDTH * maxInPx - MAX_VIEWPORT_WIDTH * minInPx) / (MIN_VIEWPORT_WIDTH - MAX_VIEWPORT_WIDTH) / 16;

  return `clamp(${min}rem, ${viewportValue}vw + ${relativeValue}rem, ${max}rem)`;
}
