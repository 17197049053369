import { Injectable } from '@angular/core';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';

@Injectable({
  providedIn: 'root',
})
export class B2cListService {
  b2c: any[];
  constructor(private _logger: LoggerService, private _rest: RestAPIService) {}

  public async getB2C(options?: { refresh: boolean }): Promise<any> {
    try {
      if (options?.refresh || !this.b2c) {
        const response = await this._rest.get(`organization/self/b2c`);
        this.b2c = response.filteredB2C.map((b2c) => {
          return {
            ...b2c,
            credits: b2c.availableTokens.length,
          };
        });
      }
      return this.b2c;
    } catch (err) {
      this._logger.error(err);
    }
  }
}
