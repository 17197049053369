import { MatDialog } from '@angular/material/dialog';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  faTrashAlt,
  faExclamationTriangle,
  faPlus,
  faMinus,
  faGamepad,
  faEdit,
  faRedo,
  faTag,
  faCheck,
  faSchool,
  faBagShopping,
  faCoins,
  faFileUpload,
  faFileLines,
  faSearch,
  faFilter,
  faEye,
} from '@fortawesome/free-solid-svg-icons';

import { MessagesModalComponent } from '../messages-modal/messages-modal.component';
import { MessageButton } from 'src/app/shared/interfaces/Message.interface';
@Component({
  selector: 'messages-table-button',
  templateUrl: './messages-table-button.component.html',
  styleUrls: ['./messages-table-button.component.scss'],
})
export class MessagesTableButtonComponent implements OnInit {
  @Input() icon:
    | 'default'
    | 'trash'
    | 'plus'
    | 'minus'
    | 'edit'
    | 'redo'
    | 'game'
    | 'tag'
    | 'checkmark'
    | 'learning'
    | 'shop'
    | 'coins'
    | 'upload'
    | 'file'
    | 'search'
    | 'filter'
    | 'eye';
  @Input() tooltip = '';
  @Input() action;
  @Input() disabled = false;
  @Input() data: MessageButton;
  @Input() disableHandleClick: boolean;
  @Input() type: string;
  @Output() updatedMessage = new EventEmitter<any>();

  public iconToDisplay: any = faExclamationTriangle;
  private readonly MODAL_WIDTH = '1000px';

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {
    this.initIcon();
  }

  private initIcon() {
    switch (this.icon) {
      case 'trash':
        this.iconToDisplay = faTrashAlt;
        break;
      case 'plus':
        this.iconToDisplay = faPlus;
        break;
      case 'minus':
        this.iconToDisplay = faMinus;
        break;
      case 'edit':
        this.iconToDisplay = faEdit;
        break;
      case 'game':
        this.iconToDisplay = faGamepad;
        break;
      case 'redo':
        this.iconToDisplay = faRedo;
        break;
      case 'tag':
        this.iconToDisplay = faTag;
        break;
      case 'checkmark':
        this.iconToDisplay = faCheck;
        break;
      case 'learning':
        this.iconToDisplay = faSchool;
        break;
      case 'shop':
        this.iconToDisplay = faBagShopping;
        break;
      case 'coins':
        this.iconToDisplay = faCoins;
        break;
      case 'upload':
        this.iconToDisplay = faFileUpload;
        break;
      case 'file':
        this.iconToDisplay = faFileLines;
        break;
      case 'search':
        this.iconToDisplay = faSearch;
        break;
      case 'filter':
        this.iconToDisplay = faFilter;
        break;
      case 'eye':
        this.iconToDisplay = faEye;
        break;
      default:
        this.iconToDisplay = faExclamationTriangle;
    }
  }

  public handleClick() {
    if (this.disableHandleClick) {
      return;
    }

    const dialog = this.dialog.open(MessagesModalComponent, {
      width: this.MODAL_WIDTH,
      disableClose: true,
      data: {
        type: this.type,
        data: this.data,
      },
    });
    dialog.afterClosed().subscribe(() => {
      if (dialog?.componentRef?.instance.data) {
        this.updatedMessage.emit(dialog?.componentRef?.instance.data);
      }
    });
  }
}
