import { NgModule } from '@angular/core';
import { BackToPortalButtonComponent } from './back-to-portal-button/back-to-portal-button.component';
import { CompletionIconComponent } from './completion-icon/completion-icon.component';
import { LessonTypeIconComponent } from './lesson-type-icon/lesson-type-icon.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { TopHeadingComponent } from './top-heading/top-heading.component';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatTooltipModule } from '@angular/material/tooltip';

/**
 * @scope Provides shared components for the courses module.
 */
@NgModule({
  declarations: [
    BackToPortalButtonComponent,
    CompletionIconComponent,
    LessonTypeIconComponent,
    ProgressBarComponent,
    TopHeadingComponent,
  ],
  imports: [CommonModule, FontAwesomeModule, MatTooltipModule],
  exports: [
    BackToPortalButtonComponent,
    CompletionIconComponent,
    LessonTypeIconComponent,
    ProgressBarComponent,
    TopHeadingComponent,
  ],
})
export class CoursesSharedModule {}
