import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-what-you-need',
  templateUrl: './what-you-need.component.html',
  styleUrls: ['./what-you-need.component.scss'],
})
export class WhatYouNeedComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
