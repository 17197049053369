<div class="main">
  <section class="container">
    <div class="title">
      <h2>Message Registration</h2>
    </div>

    <div
      *ngIf="loading"
      class="loading-overlay"
    >
      <div class="spinner-container">
        <mat-spinner diameter="50"></mat-spinner>
        <span class="loading-text">Loading...</span>
      </div>
    </div>

    <form
      class="form"
      [class.loading]="loading"
    >
      <div class="two-column-layout">
        <div class="left-column">
          <div class="input-group">
            <div class="input-block">
              <label for="messageText">Text *</label>
              <textarea
                type="text"
                [(ngModel)]="messageForm.text"
                name="messageText"
                placeholder="Insert the message Text"
                [disabled]="modalType === 'VIEW'"
              ></textarea>
            </div>
          </div>

          <div class="input-block-select">
            <label for="mediaType">Select the media</label>
            <select
              [(ngModel)]="messageForm.mediaType"
              name="mediaType"
              [disabled]="modalType === 'VIEW'"
            >
              <option value="image">Image</option>
              <option value="video">Video</option>
            </select>
          </div>

          <div
            *ngIf="messageForm.mediaType === 'image'"
            class="image"
          >
            <div
              *ngIf="messageForm.image"
              class="image-preview"
            >
              <h2 class="title">Image Preview</h2>
              <img
                [src]="messageForm.image"
                alt="Image preview"
                class="preview-media"
              />
            </div>
            <button
              type="button"
              class="btn-image"
              [disabled]="modalType === 'VIEW'"
              (click)="addMessageImage()"
            >
              Add message image
            </button>
          </div>

          <div
            *ngIf="messageForm.mediaType === 'video'"
            class="video"
          >
            <div
              *ngIf="safeMessageVideoUrl"
              class="video-preview"
            >
              <h2 class="title">Video Preview</h2>
              <iframe
                [src]="safeMessageVideoUrl"
                frameborder="0"
                allowfullscreen
                class="preview-media"
              ></iframe>
            </div>
            <input
              class="input-block"
              type="text"
              [(ngModel)]="messageForm.video"
              name="videoUrl"
              placeholder="Enter YouTube video URL"
              [disabled]="modalType === 'VIEW'"
              (ngModelChange)="updateSafeUrl()"
            />
          </div>

          <div class="my-4">
            <div class="inputHeader text-center">Send to users type</div>
            <div class="d-flex justify-content-center">
              <div
                *ngFor="let userType of getUserTypes()"
                class="mx-2"
              >
                <mat-checkbox
                  [disabled]="
                    modalType === 'VIEW' || this.allowedOrgs.length > 0
                  "
                  (change)="addAllowedUser(userTypes[userType])"
                  [checked]="checkUserType(userTypes[userType])"
                >
                  {{ userTypes[userType] | titlecase }}
                </mat-checkbox>
              </div>
            </div>
          </div>

          <div class="my-4">
            <div class="inputHeader text-center">
              Send to allowed organizations
            </div>
            <div class="d-flex justify-content-center">
              <div
                *ngFor="let orgType of getAllowedOrgsTypes()"
                class="mx-2"
              >
                <mat-checkbox
                  [disabled]="
                    modalType === 'VIEW' ||
                    this.messageForm.allowedUsers.length > 0
                  "
                  (change)="addAllowedOrgs(allowedOrgsTypes[orgType])"
                  [checked]="isAllowedOrgs(allowedOrgsTypes[orgType])"
                >
                  {{ allowedOrgsTypes[orgType] | titlecase }}
                </mat-checkbox>
              </div>
            </div>
          </div>
        </div>

        <div class="right-column">
          <div
            *ngIf="
              selectedHowToSend === 'manually' ||
              selectedOrgsDataSource.data.length > 0
            "
            class="allowed-users-list my-4"
          >
            <div class="inputHeader text-center">Selected Organizations</div>
            <div class="table-container mat-elevation-z8">
              <div class="table-header">
                <input
                  class="search-input"
                  type="text"
                  placeholder="Search organization..."
                  (input)="applyFilterSelectedOrgs($event)"
                  #selectedOrgsSearchInput
                />
              </div>
              <table
                mat-table
                [dataSource]="selectedOrgsDataSource"
              >
                <ng-container matColumnDef="id">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                  >
                    ID
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let org"
                  >
                    {{ org?.organization?.id }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="name">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                  >
                    Organization Name
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let org"
                  >
                    {{ org?.organization?.name }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="messageReadDate">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                  >
                    Date
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let org"
                  >
                    {{ org?.createdAt | date: 'dd/MM/yyyy HH:mm:ss' }}
                  </td>
                </ng-container>
                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedColumns"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns"
                ></tr>
              </table>
              <mat-paginator
                #selectedOrgsPaginator
                [pageSize]="PAGE_SIZE"
                [pageSizeOptions]="PAGE_SIZE_OPTIONS"
                showFirstLastButtons
              >
              </mat-paginator>
            </div>
          </div>

          <div
            *ngIf="usersReadDataSource.data.length > 0"
            class="allowed-users-list my-4"
          >
            <div class="inputHeader text-center">
              Users who read the message
            </div>
            <div class="table-container mat-elevation-z8">
              <div class="table-header">
                <input
                  class="search-input"
                  type="text"
                  placeholder="Search organization..."
                  (input)="applyFilterUsersRead($event)"
                  #usersReadSearchInput
                />
              </div>
              <table
                mat-table
                [dataSource]="usersReadDataSource"
              >
                <ng-container matColumnDef="id">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                  >
                    ID
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let user"
                  >
                    {{ user.organization.id }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="name">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                  >
                    Organization Name
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let user"
                  >
                    {{ user.organization.name | titlecase }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="messageReadDate">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                  >
                    Date
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let user"
                  >
                    {{ user?.messageReadDate | date: 'dd/MM/yyyy HH:mm:ss' }}
                  </td>
                </ng-container>
                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedColumns"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns"
                ></tr>
              </table>
              <mat-paginator
                #usersReadPaginator
                [pageSize]="PAGE_SIZE"
                [pageSizeOptions]="PAGE_SIZE_OPTIONS"
                showFirstLastButtons
              >
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>

      <div class="button-group">
        <button
          type="button"
          class="btn-secondary"
          (click)="close()"
        >
          Close
        </button>
        <button
          *ngIf="modalType !== 'VIEW'"
          type="button"
          class="btn-primary"
          (click)="save()"
        >
          Save
        </button>
      </div>
    </form>
  </section>
</div>
