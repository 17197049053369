import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { IconDefinition, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Category } from 'src/app/pages/configuration-pages/interfaces/global-config.interfaces';
import { Student } from 'src/app/pages/students/interfaces/student.interface';
import { B2cPaymentTypes } from 'src/app/pages/users/components/adjust-b2c-prices/constants/b2c-prices.constants';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { Progress } from 'src/app/shared/models';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';

interface DialogData {
  categoryList: Category[];
  studentProgress: Progress[];
  student: Student;
  run: number;
}

interface CategoryData {
  category: Category;
  progressAmount: number;
  included: boolean;
}

@Component({
  selector: 'app-progress-completion-dialog',
  templateUrl: './progress-completion-dialog.html',
  styleUrls: ['./progress-completion-dialog.scss'],
})
export class ProgressCompletionDialog implements OnInit {
  public displayedColumns: string[] = ['name', 'included', 'amount'];
  public dataSource: MatTableDataSource<CategoryData>;

  public checked = true;
  public unchecked = false;
  public savingProgress = false;

  public paymentTypes = B2cPaymentTypes;
  public includedCategories: Category[] = [];

  public readonly spinnerIcon: IconDefinition = faSpinner;
  public readonly question: IconDefinition = faCircleQuestion;

  constructor(
    public dialogRef: MatDialogRef<ProgressCompletionDialog>,
    public rest: RestAPIService,
    protected _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  async ngOnInit() {
    const dataToLoad = this.data.categoryList.map((category) => {
      return {
        category,
        progressAmount: 0,
        included: false,
      };
    });

    this.dataSource = new MatTableDataSource(dataToLoad);
  }

  public changeEnabledStatus(data: CategoryData) {
    if (this.shouldDisableInput(data.category)) {
      return;
    }

    data.included = !data.included;
  }

  public checkIfCategoryIsIncluded(category: Category): boolean {
    return this.includedCategories.includes(category);
  }

  public getProgressMaxAmount(category: Category) {
    const categoryProgress = this.data.studentProgress.filter(
      (p) => p.tag === category.name.toLowerCase() && this.shouldIncludeProgress(p),
    );

    return category.maxSessions - categoryProgress.length;
  }

  public shouldIncludeProgress(progress: Progress) {
    const progressCategory = this.data.categoryList.find((c) => c.name.toLowerCase() === progress.tag.toLowerCase());

    if (!progressCategory) {
      return false;
    }

    const progressRun = progress.rerun ?? 0;

    return progressCategory.mandatory ? progressRun === this.data.run : true;
  }

  public shouldDisableInput(category: Category) {
    const categoryProgress = this.data.studentProgress.filter(
      (p) => p.tag === category.name.toLowerCase() && this.getProgressRun(p) === this.data.run,
    );

    return category.maxSessions === categoryProgress.length;
  }

  public getProgressRun(progress: Progress) {
    return progress.rerun ? progress.rerun : 0;
  }

  public async confirm() {
    this.savingProgress = true;
    const studentId = this.data.student.id;
    const dataToSave = this.dataSource.data.filter((d) => d.included && d.progressAmount > 0);

    if (dataToSave.length === 0) {
      this._snackBar.open(`You need to choose at least one category and a an progress amount higher than 0`, 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
      this.savingProgress = false;
      return;
    }

    if (this.isValidData(dataToSave)) {
      for (const data of dataToSave) {
        await this.rest.post(
          'progress/create/amount/' + data.progressAmount + '/category/' + data.category.id + '/student/' + studentId,
          {},
        );
      }

      this.savingProgress = false;
      this.dialogRef.close(true);
    } else {
      for (const data of dataToSave) {
        if (data.progressAmount > data.category.maxSessions) {
          this._snackBar.open(
            `The maximum amount of progress allowed for ${data.category.name} is ${data.category.maxSessions}`,
            'Close',
            {
              horizontalPosition: 'center',
              verticalPosition: 'top',
            },
          );
        } else if (data.progressAmount + this.getCategoryProgress(data.category) > data.category.maxSessions) {
          this._snackBar.open(
            `This student already have ${this.getCategoryProgress(data.category)} created progress for ${
              data.category.name
            }, 
          the chosen amount exceeds this category max sessions ${data.category.maxSessions}`,
            'Close',
            {
              horizontalPosition: 'center',
              verticalPosition: 'top',
            },
          );
        }
      }

      this.savingProgress = false;
    }
  }

  public isValidData(data: CategoryData[]) {
    return data.every((e) => e.progressAmount + this.getCategoryProgress(e.category) <= e.category.maxSessions);
  }

  public getCategoryProgress(category: Category) {
    const categoryProgress = this.data.studentProgress.filter(
      (p) => p.tag === category.name.toLowerCase() && this.getProgressRun(p) === this.data.run,
    );

    return categoryProgress ? categoryProgress.length : 0;
  }

  public cancel() {
    this.dialogRef.close();
  }
}
