import { Injectable } from '@angular/core';

import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root',
})
export class CallbackGuard {
  constructor(private _authService: AuthService) {}

  canActivate() {
    // if the user is already authenticated, redirect it to their page
    if (this._authService.accessToken) {
      this._authService.redirectUser();
      return false;
    }

    return true;
  }
}
