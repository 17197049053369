import { Injectable } from '@angular/core';
import { RestAPIService } from '../rest/rest-api.service';
import { Client } from 'src/app/shared/interfaces';

@Injectable({
  providedIn: 'root',
})
export class PatronListSaver {
  constructor(private _rest: RestAPIService) {}

  public orgPatronList: Client[];

  public async getOrganizationPatronList() {
    if (this.orgPatronList) {
      return this.orgPatronList;
    } else {
      const response = await this._rest.get('organization/self/patrons', {
        msg: 'Could not get organization/self/patrons.',
      });

      response.patrons = response.patrons.filter((p) => !p.archived);

      return response.patrons;
    }
  }
}
