import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { faQuestionCircle, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { progressTypes } from 'src/app/shared/consts/global-constants';
import { Program, Token, User } from 'src/app/shared/models';
import { Student } from '../../students/interfaces/student.interface';
import { get, includes } from 'lodash';
import { BonusPack, Category } from '../../configuration-pages/interfaces/global-config.interfaces';
import { Progress } from 'src/app/core/openapi';

interface StudentLevel {
  level: string;
  assessmentLevel: number;
  enabled: boolean;
}

interface StudentLevelData {
  levels: StudentLevel[];
  assessmentProgresses: Progress[];
  studentLevel: string;
  student: Student;
  program: Program;
  categories: Category[];
  bonusPackList: BonusPack[];
  asssociatedToken: Token;
  user: User;
}

@Component({
  selector: 'app-student-level-override',
  templateUrl: './level-override-dialog.component.html',
  styleUrls: ['./level-override-dialog.component.scss'],
})
export class LevelOverrideDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: StudentLevelData,
    private rest: RestAPIService,
    protected _snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<LevelOverrideDialogComponent>,
  ) {}

  public studentAssessmentLevel: number;
  public readonly overrideColums: string[] = ['program', 'level', 'resetLevels'];
  public readonly categoryColums: string[] = ['category', 'level', 'actions'];
  public programDataSource: MatTableDataSource<Program>;
  public categoriesDatasource: MatTableDataSource<Category>;
  public readonly helpIcon: IconDefinition = faQuestionCircle;

  public categoryLevels;
  public loadingInfo = true;
  public nameInput = 'name';

  public checked = true;
  public unchecked = false;

  ngOnInit() {
    this.saveDataSources();
    this.saveStudentLevel();
    this.buildCategoriesLevelArray();
  }

  public saveStudentLevel() {
    const levelObject = this.data.levels.find((l) => l.level === this.data.studentLevel);
    this.studentAssessmentLevel = levelObject.assessmentLevel;
  }

  public saveDataSources() {
    this.programDataSource = new MatTableDataSource([this.data.program]);
    this.categoriesDatasource = new MatTableDataSource(this.data.categories.filter((c) => c.mandatory));
  }

  public buildCategoriesLevelArray() {
    const lastIndex =
      this.data.assessmentProgresses.length === 0
        ? this.data.assessmentProgresses.length
        : this.data.assessmentProgresses.length - 1;
    const assessment = this.data.assessmentProgresses[lastIndex];

    const categoryLevelsArray = get(assessment, 'metadata.categoryLevels', []);
    const newLevelObject = {};

    for (const category of this.data.categories) {
      const categoryLevel = categoryLevelsArray.find(
        (l) => l.categoryId === category.id || l.categoryName === category.name,
      );

      for (const key of Object.keys(category)) {
        if (key === this.nameInput) {
          newLevelObject[category[key]] = {
            assessmentLevel: categoryLevel ? categoryLevel.assessmentLevel : this.studentAssessmentLevel,
            categoryId: category.id,
            categoryName: category.name,
          };
        }
      }
    }
    this.categoryLevels = newLevelObject;

    this.loadingInfo = false;
  }

  public async saveCategoryLevel(category: Category) {
    const lastIndex =
      this.data.assessmentProgresses.length === 0
        ? this.data.assessmentProgresses.length
        : this.data.assessmentProgresses.length - 1;
    const assessment = this.data.assessmentProgresses[lastIndex];
    const studentLevel = this.data.levels.find((l) => l.level === this.data.studentLevel);
    const levelObject = this.categoryLevels[category.name];

    if (!assessment) {
      const progress = {
        progress: {
          session: 0,
          metadata: {
            level: studentLevel.assessmentLevel,
            score: 0,
            categoryLevels: [levelObject],
          },
          studentId: this.data.student.id,
          programId: this.data.program.id,
          tag: progressTypes.ASSESSMENT,
        },
      };

      await this.rest.post('progress', progress, { msg: 'Could not post progress' });
    } else {
      const categoryLevelList = assessment.metadata.categoryLevels || [];
      const categoryLevel = categoryLevelList.find(
        (l) => l.categoryId === category.id || l.categoryName === category.name,
      );

      if (categoryLevel) {
        categoryLevel.assessmentLevel = this.categoryLevels[category.name].assessmentLevel;
      } else {
        const newCategoryLevelEntry = {
          assessmentLevel: this.categoryLevels[category.name].assessmentLevel,
          categoryId: category.id,
          categoryName: category.name,
        };

        categoryLevelList.push(newCategoryLevelEntry);
        assessment.metadata.categoryLevels = categoryLevelList;
      }

      await this.rest.put(
        'progress/' + assessment.id,
        {
          progress: assessment,
        },
        { msg: 'Could not put progress' },
      );
    }

    this._snackBar.open(`Level updated!`, 'Close', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  public resetCategoryLevels() {
    const assessments = this.data.assessmentProgresses;

    assessments.forEach(async (assessment) => {
      if (assessment.metadata.categoryLevels) {
        delete assessment.metadata.categoryLevels;
      }

      return this.rest.put(
        'progress/' + assessment.id,
        {
          progress: assessment,
        },
        { msg: 'Could not put progress' },
      );
    });

    this.ngOnInit();
  }

  public async saveAssessmentLevel(): Promise<void> {
    const assessments = this.data.assessmentProgresses;

    if (assessments.length === 0) {
      const progress = {
        progress: {
          session: 0,
          metadata: {
            level: this.studentAssessmentLevel,
            score: 0,
            levelOverwriten: true,
            overwritenBy: this.data.user.id,
          },
          studentId: this.data.student.id,
          programId: this.data.program.id,
          tag: progressTypes.ASSESSMENT,
        },
      };

      await this.rest.post('progress', progress, { msg: 'Could not post progress' });
    } else {
      assessments.forEach(async (assessment) => {
        assessment.metadata.level = this.studentAssessmentLevel;
        assessment.metadata.levelOverwriten = true;
        assessment.metadata.overwritenBy = this.data.user.id;

        await this.rest.put(
          'progress/' + assessment.id,
          {
            progress: assessment,
          },
          { msg: 'Could not put progress' },
        );
      });
    }

    this._snackBar.open(`Level updated!`, 'Close', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });

    this.dialogRef.close(true);
  }

  public isBonusPackAvaliable(category: Category): boolean {
    return this.data.bonusPackList.find((c) => c.id === category.id) !== undefined;
  }

  public isBonusPackActivated(category: Category): boolean {
    return includes(this.data.asssociatedToken.enabledBonus, category.id);
  }

  public async enableBonusPack(category: Category) {
    const tokenId = this.data.asssociatedToken.id;
    const token = this.data.asssociatedToken;

    if (!token.enabledBonus) {
      token.enabledBonus = [];
    }

    const enabledBonus = includes(token.enabledBonus, category.id);

    if (enabledBonus) {
      const index = token.enabledBonus.indexOf(category.id);
      token.enabledBonus.splice(index, 1);
    } else {
      token.enabledBonus.push(category.id);
    }

    await this.rest.put('token/' + tokenId, { token }, { msg: 'Could not put token.' });

    this._snackBar.open(`Bonus pack ${enabledBonus ? 'disabled' : 'enabled'}!`, 'Close', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  close() {
    this.dialogRef.close();
  }
}
