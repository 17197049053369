<div class="col-12 px-0">
  <div class="header">Filter by {{ getLabel() }}</div>

  <div>
    <mat-form-field class="mx-1">
      <mat-select [(value)]="propertyValue">
        <mat-option
          *ngFor="let option of options"
          [value]="option.value"
          >{{ option.label }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>

  <div class="button d-flex justify-content-center">
    <app-simple-button
      (click)="applyFilter()"
      [disabled]="shouldDisableBtn()"
      [fullWidth]="true"
      class="mx-auto"
      [text]="'Apply'"
    >
    </app-simple-button>

    <app-simple-button
      (click)="resetFilter()"
      [fullWidth]="true"
      [disabled]="!filterIsActive"
      class="mx-auto"
      [text]="'Clear'"
    >
    </app-simple-button>
  </div>
</div>
