import { AfterViewInit, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { clamp } from 'src/app/shared/helpers/clamp.function';
import { styleElement } from 'src/app/shared/helpers/styleElement.function';

@Component({
  selector: 'home-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent implements AfterViewInit {
  @ViewChild('availableInFrench') availableInFrench: ElementRef;
  @ViewChild('frenchText') frenchText: ElementRef;
  @ViewChild('neuralignLogo') neuralignLogo: ElementRef;

  @ViewChild('carleton') carleton: ElementRef;
  @ViewChild('carletonText') carletonText: ElementRef;
  @ViewChild('carletonLogo') carletonLogo: ElementRef;

  @ViewChild('brain') brain: ElementRef;

  constructor(private _renderer: Renderer2) {}

  ngAfterViewInit() {
    this.setStyles();
  }

  private setStyles() {
    styleElement(this._renderer, this.availableInFrench, {
      position: 'relative',
    });

    styleElement(this._renderer, this.frenchText, {
      position: 'absolute',
      top: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      gap: clamp(0.5, 5),
      height: '100%',
      left: clamp(1, 30),
    });

    styleElement(this._renderer, this.neuralignLogo, {
      width: clamp(12, 60),
    });

    styleElement(this._renderer, this.carleton, {
      position: 'relative',
    });

    styleElement(this._renderer, this.carletonText, {
      position: 'absolute',
      right: clamp(0.75, 22),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      gap: clamp(0.5, 3),
      alignItems: 'flex-start',
      height: '100%',
    });

    styleElement(this._renderer, this.carletonLogo, {
      width: clamp(9, 60),
    });

    styleElement(this._renderer, this.brain, {
      position: 'absolute',
      top: clamp(6, 16),
      width: clamp(7, 80),
    });
  }

  // Click Handlers --

  public goToStudy() {
    window.open('https://lsworks.org/blog/neuralign-essa-level-1-certification', '_blank');
  }
}
