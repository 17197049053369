<div
  class="orgList"
  *ngIf="!loading"
>
  <div class="header p-4 text-center">Organizations</div>
  <mat-form-field
    appearance="outline"
    class="col-10 p-2 m-auto d-flex justify-content-center"
  >
    <mat-label>Filter</mat-label>
    <input
      matInput
      (keyup)="applyFilter($event)"
      #input
    />
  </mat-form-field>

  <div class="buttons d-flex justify-content-center mt-4">
    <div class="mx-3 text-center mb-3">
      <button
        (click)="selectAllUsers()"
        mat-raised-button
      >
        Select all
      </button>
    </div>

    <div class="mx-3 text-center mb-3">
      <button
        (click)="reverseSelection()"
        mat-raised-button
      >
        Reverse selection
      </button>
    </div>
  </div>

  <div class="d-flex justify-content-center my-2">
    <mat-checkbox
      (click)="showAllowedUsers()"
      class="m-auto d-flex"
      [(ngModel)]="allowedUsers"
    >
      Show only allowed users
    </mat-checkbox>
  </div>

  <div class="wrapper">
    <table
      *ngIf="dataSource"
      mat-table
      [dataSource]="dataSource"
      class="mat-elevation-z8 col-12 px-1"
    >
      <ng-container matColumnDef="name">
        <th
          mat-header-cell
          *matHeaderCellDef
        >
          Name
        </th>
        <td
          mat-cell
          *matCellDef="let org"
        >
          {{ org.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="enabled">
        <th
          mat-header-cell
          *matHeaderCellDef
        >
          Enabled
        </th>
        <td
          mat-cell
          *matCellDef="let acc"
        >
          <mat-checkbox
            class="mx-3"
            (click)="addEnabledOrg(acc)"
            [checked]="checkEnabledOrgs(acc)"
          >
          </mat-checkbox>
        </td>
      </ng-container>
      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
      ></tr>

      <tr
        class="mat-row"
        *matNoDataRow
      >
        <td
          class="mat-cell"
          colspan="4"
        >
          No data matching the filter "{{ input.value }}"
        </td>
      </tr>
    </table>
  </div>

  <div class="buttons d-flex justify-content-center mt-3">
    <app-simple-button
      class="mx-2"
      [text]="'Save'"
      (click)="save()"
    >
    </app-simple-button>

    <app-simple-button
      class="mx-2"
      [text]="'Close'"
      (click)="close()"
    >
    </app-simple-button>
  </div>
</div>

<div
  class="loadingContainer"
  *ngIf="loading"
>
  <fa-icon
    [icon]="spinner"
    [spin]="true"
  ></fa-icon>
</div>
