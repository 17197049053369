import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface ModalData {
  message: string;
  timeToExpire: number;
}

@Component({
  selector: 'app-renew-token-dialog',
  templateUrl: './renew-token-dialog.component.html',
  styleUrls: ['./renew-token-dialog.component.scss'],
})
export class RenewTokenDialog implements OnInit {
  public interval: ReturnType<typeof setInterval>;
  tokenExp: number;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ModalData, private dialogRef: MatDialogRef<RenewTokenDialog>) {}

  ngOnInit(): void {
    const expirationString = localStorage.getItem('expire_at');

    if (!expirationString) {
      this.dialogRef.close(false);
      return;
    }

    this.tokenExp = parseInt(expirationString);

    this.interval = setInterval(() => {
      this.data.timeToExpire -= 1000;

      if (this.data.timeToExpire <= 0 || this.tokenExp < Date.now()) {
        this.resetInterval();
        this.dialogRef.close(false);
      }
    }, 1000);
  }

  close(shouldRenewToken: boolean) {
    this.resetInterval();
    this.dialogRef.close(shouldRenewToken);
  }

  getTimeToExpire() {
    const minutes = Math.floor(this.data.timeToExpire / 60000);
    const seconds = Math.floor((this.data.timeToExpire % 60000) / 1000);

    let formattedTime = `${minutes} minute`;

    if (minutes !== 1) {
      formattedTime += 's';
    }

    if (minutes <= 0) {
      formattedTime = `${seconds} seconds`;
    }

    return formattedTime;
  }

  resetInterval() {
    clearInterval(this.interval);
    this.interval = null;
  }
}
