<div class="first-column">
  <div class="organization">
    <div
      *ngIf="data.logo"
      class="logo"
    >
      <img
        [src]="data.logo"
        width="100%"
      />
    </div>

    <div
      *ngIf="data.name"
      class="name"
    >
      {{ data.name }}
    </div>
  </div>

  <div class="details">
    <div *ngIf="data.address">
      {{ data.address }}, <br />
      {{ data.province }}, {{ data.postalCode }}
    </div>

    <br />

    <div *ngIf="data.phoneNumber">
      <fa-icon [icon]="phoneIcon"></fa-icon> {{ data.phoneNumber }}
    </div>

    <div *ngIf="data.email">
      <fa-icon [icon]="emailIcon"></fa-icon> {{ data.email }}
    </div>

    <div *ngIf="data.website">
      <fa-icon [icon]="siteIcon"></fa-icon> {{ data.website }}
    </div>
  </div>

  <div class="close">
    <app-simple-button
      text="Close"
      (click)="closePreview()"
    ></app-simple-button>
  </div>
</div>

<div class="second-column">
  <div *ngIf="data.videoUrl">
    <iframe
      width="510"
      height="345"
      [src]="playVideo(data.videoUrl) | safeUrl"
    >
    </iframe>
  </div>

  <div
    *ngIf="data.description"
    class="description"
    [innerHtml]="formatDescription()"
  ></div>

  <div
    class="languages-provided"
    *ngIf="data.languagesProvided"
  >
    <h6>Offers Neuralign in these languages:</h6>
    <div class="languages">
      <div
        class="language"
        *ngFor="let lang of getProvidedLanguages()"
      >
        <span *ngIf="lang[1] === true">{{ lang[0] }}</span>
      </div>
    </div>
  </div>
</div>
