import { Component, OnInit } from '@angular/core';

import { Chart } from 'chart.js';
import { ProgramInfo } from '../interfaces/program-info.interface';
import { ProgramResults } from '../interfaces/program-results.interface';
import { Lesson } from '../interfaces/lesson.interface';
import { ChartJSOptions } from '../../../shared/consts/chartjs-options';
import { RestAPIService } from '../../../services/rest/rest-api.service';
import { ActivatedRoute } from '@angular/router';
import { GlobalConfigurationHelper } from 'src/app/services/utils/global-configuration-helper';

@Component({
  selector: 'app-student-results',
  templateUrl: './student-results.component.html',
  styleUrls: ['./student-results.component.scss'],
})
export class StudentResultsComponent implements OnInit {
  public programName = '';
  public studentName = '';
  public studentId = '';
  public programInfo: ProgramInfo;
  public studentResults: ProgramResults;
  public selectedLesson: Lesson;
  public assessment: any;

  public canvas = [];
  public ctx: HTMLCanvasElement;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _rest: RestAPIService,
    private globalConfigHelper: GlobalConfigurationHelper,
  ) {}

  async ngOnInit() {
    this.getRouteParams();
    await this._getStudentAssessment(this.studentId);
    await this._getStudentResults();
  }

  getRouteParams() {
    this.programName = this._activatedRoute.snapshot.paramMap.get('programName');
    this.studentName = this._activatedRoute.snapshot.paramMap.get('name');
    this.studentId = this._activatedRoute.snapshot.paramMap.get('studentId');
  }

  public selectLesson(lessonId: number | string): void {
    if ((lessonId as number) > 1 || !this.assessment) {
      return;
    }
    this.selectedLesson = this.programInfo.categories.find((lesson) => lesson.id === lessonId);
  }

  public checkScreenWidth(): boolean {
    return window.innerWidth > 960;
  }

  private async _setProgramInfo(): Promise<void> {
    const categories = await this.globalConfigHelper.getCategories();

    const programInfo = {
      id: 1,
      name: 'Neuralign',
      image: './assets/img/programs/NeurAlign text logo.png',
      categories: categories,
    };

    this.programInfo = programInfo;
    this.selectedLesson = this.programInfo.categories[0];
  }

  private async _getStudentAssessment(studentId: string): Promise<void> {
    try {
      const response = await this._rest.get('student/progress/' + studentId + '/assessment', {
        msg: 'Could not get student/progress/:studentId/assessment.',
      });

      if (response.progress && response.progress.length > 0) {
        this.assessment = response.progress[0];
      }
      // eslint-disable-next-line no-empty
    } catch {}
  }

  private async _getStudentResults(): Promise<void> {
    try {
      await this._setProgramInfo();
      this.programInfo.categories.forEach(() => this.canvas.push({}));

      setTimeout(() => {
        if (this.assessment) {
          this._generateGraphs();
        }
      });
      // eslint-disable-next-line no-empty
    } catch {}
  }

  private _generateGraphs() {
    for (let index = 0; index < this.canvas.length; index++) {
      const type = index > 0 ? 'pie' : 'bar';

      this.canvas[index] = document.getElementById('Lesson' + index + 'Chart');
      this.ctx = this.canvas[index].getContext('2d');

      const options = ChartJSOptions;
      (options.scales as any).yAxes = [
        {
          gridLines: { display: false },
          ticks: { min: 0, max: 3000 },
        },
      ];

      new Chart(this.ctx, {
        type,
        data: {
          labels: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15'],
          datasets: [
            {
              label: 'Session',
              data: [500, 600, 750, 800, 950, 1000, 1120, 1200, 1350, 1400, 1600, 1750, 2000, 2250, 2320],
              backgroundColor: '#fdbd4e',
              borderWidth: 1,
            },
          ],
        },
        options: ChartJSOptions,
      });
    }
  }
}
