import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { RestAPIService } from '../../services/rest/rest-api.service';
import { MaterialComponentsModule } from 'src/app/shared/modules/material-components.module';
import { LayoutsModule } from 'src/app/shared/layouts/layouts.module';
import { AddButtonModule } from 'src/app/shared/components/buttons/add-button/add-button.module';
import { StripeTableActionIconModule } from 'src/app/pages/stripe/stripe-table-action-icon/stripe-table-action-icon.module';

import { FormsModule } from '@angular/forms';
import { TaxStripeComponent } from './tax-stripe/tax-stripe.component';
import { StripeTableDashboardComponent } from './stripe-table-dashboard/stripe-table-dashboard.component';
import { StripeObservableService } from './stripe-observable.service';
import { TaxStripeModalComponent } from './modals/tax-stripe-modal/tax-stripe-modal.component';

@NgModule({
  declarations: [TaxStripeComponent, TaxStripeModalComponent, StripeTableDashboardComponent],
  imports: [
    BrowserModule,
    RouterModule,
    HttpClientModule,
    MaterialComponentsModule,
    LayoutsModule,
    AddButtonModule,
    StripeTableActionIconModule,
    FormsModule,
  ],
  providers: [RestAPIService, StripeObservableService],
})
export class StripeModule {}
