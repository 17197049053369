import { AfterContentChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CoursesService } from '../../services/courses/courses.service';
import { Observable } from 'rxjs';
import { LessonsService } from '../../services/lessons/lessons.service';
import { LessonFEM, SectionFEM } from '../../services/courses/courses.service.models';

@Component({
  selector: 'courses-learning-section',
  templateUrl: './learning-section.component.html',
  styleUrls: ['./learning-section.component.scss'],
})
export class LearningSectionPageComponent implements OnInit, AfterContentChecked {
  currentSection$: Observable<SectionFEM> = this._lessons.currentSection$;
  currentLesson$: Observable<LessonFEM> = this._lessons.currentLesson$;

  constructor(
    private _courses: CoursesService,
    private _lessons: LessonsService,
    private _route: ActivatedRoute,
    private _cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this._courses.loadCourseByActivatedRoute(this._route);
  }

  ngAfterContentChecked(): void {
    this._cdr.detectChanges();
  }
}
