import { AfterViewInit, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { clamp } from 'src/app/shared/helpers/clamp.function';
import { styleElement } from 'src/app/shared/helpers/styleElement.function';

@Component({
  selector: 'home-awards',
  templateUrl: './awards.component.html',
  styleUrls: ['./awards.component.scss'],
})
export class AwardsComponent implements AfterViewInit {
  @ViewChild('wrapper') wrapper: ElementRef;
  @ViewChild('title') title: ElementRef;
  @ViewChild('graph') graph: ElementRef;
  @ViewChild('awards') awards: ElementRef;

  constructor(private _renderer: Renderer2) {}

  ngAfterViewInit() {
    this.setStyles();
  }

  setStyles() {
    styleElement(this._renderer, this.wrapper, {
      display: 'flex',
      flexDirection: 'column',
      gap: clamp(1, 7),
      alignItems: 'center',
      padding: clamp(1, 6),
    });

    styleElement(this._renderer, this.graph, {
      width: clamp(16, 80),
    });

    styleElement(this._renderer, this.awards, {
      display: 'flex',
      gap: clamp(1, 10),
      alignItems: 'center',
      margin: `0 ${clamp(1, 6)}`,
    });
  }
}
