import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DaysMenu, ImageDaysStates } from '../utils/program-utils';
import { Category } from '../../configuration-pages/interfaces/global-config.interfaces';

@Component({
  selector: 'app-five-days-menu',
  templateUrl: './five-days-menu.component.html',
  styleUrls: ['./five-days-menu.component.scss'],
})
export class FiveDaysMenuComponent implements OnInit {
  @Input() category: Category;
  @Input() dayStateArray: string[];
  @Input() fiveDaysMenu: DaysMenu;
  @Input() sessionDay: string;
  @Output() startGame = new EventEmitter<any>();

  ngOnInit(): void {}

  public getDayImage(state: string, day: string) {
    // Change the day we have saved by default on our constant
    return this.fiveDaysMenu[state].replace(this.sessionDay, day);
  }

  public start(state: string) {
    if (state !== ImageDaysStates.DEFAULT) {
      return;
    }

    this.startGame.emit();
  }

  setDefaultImage(event: any, day: string, state: string) {
    const defaultImages = {
      default: `assets/img/fiveDaysMenu/day${day}classic_en_ca.png`,
      locked: `assets/img/fiveDaysMenu/day${day}lockedclassic_en_ca.png`,
      checked: `assets/img/fiveDaysMenu/day${day}checkedclassic_en_ca.png`,
    };

    event.target.src = defaultImages[state];
  }
}
