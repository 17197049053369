import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-adjust-subscription-info-dialog',
  templateUrl: './adjust-subscription-info.component.html',
  styleUrls: ['./adjust-subscription-info.component.scss'],
})
export class AdjustSubscriptionInfoComponent implements OnInit {
  public price: number;
  public studentsIncluded: number;

  constructor(
    public dialogRef: MatDialogRef<AdjustSubscriptionInfoComponent>,
    private confirm: ConfirmationService,
    private _rest: RestAPIService,
    private _snack: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    if (this.data) {
      (this.price = this.data.cost), (this.studentsIncluded = this.data.tokens);
    }
  }

  public checkIfCanSubmit(): boolean {
    return this.price && this.studentsIncluded ? true : false;
  }

  public save() {
    this.data.cost = this.price;
    this.data.tokens = this.studentsIncluded;

    this.confirm
      .createConfirmation('Warning', 'Are you sure you want to change this subscription ?', 'Yes', 'No')
      .then(async () => {
        try {
          await this._rest.put(
            'subscriptions/updateSubscription',
            {
              ...this.data,
            },
            { msg: 'Could not put updateSubscription' },
          );

          this._snack.open('Subscription updated!', 'Close', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });

          this.dialogRef.close(true);
        } catch (error) {
          this._snack.open('Could not update this subscriptions', 'Close', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
          this.dialogRef.close(false);
        }
      });
  }
}
