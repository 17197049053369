<div
  id=""
  class="container-fluid"
>
  <div class="row mx-0 px-3 mb-3">
    <div class="col-12 mx-0 px-0 d-flex justify-content-center">
      <h4>Metadata</h4>
      <span
        class="ml-2"
        *ngIf="data.savedGame"
      >
        ( Saved Game )
      </span>
    </div>
  </div>
  <div class="row mx-0 px-3 mb-3">
    <div class="col-12 mx-0 px-0">
      <table>
        <tr>
          <th>Game</th>
          <th>Index</th>
          <th>Lang</th>
          <th>Game session</th>
          <th>Level</th>
          <th>Score</th>
        </tr>
        <tr *ngFor="let info of metadata;">
          <td>{{info.name}}</td>
          <td>{{info.index}}</td>
          <td>{{info.lang}}</td>
          <td>{{info.session}}</td>
          <td>{{info.level}}</td>
          <td>{{info.score}}</td>
        </tr>
        <tr class="totalRow">
          <td colspan="5">Total</td>
          <td>{{totalScore}}</td>
        </tr>
      </table>
    </div>
  </div>
  <div class="row mx-0 px-3 mb-3">
    <div class="col-12 mx-0 px-0">
      <button
        class="offset-4 col-4 warn"
        (click)="onCancel()"
      >
        Close
      </button>
    </div>
  </div>
</div>
