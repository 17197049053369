<div class="mainContainer col-12 px-0">
  <div class="header col-12 p-3">Invoices</div>

  <table
    mat-table
    [dataSource]="dataSource"
    class="mat-elevation-z8 col-12 text-center"
    *ngIf="!loading"
  >
    <div class="table">
      <ng-container matColumnDef="date">
        <th
          mat-header-cell
          *matHeaderCellDef
        >
          Date
        </th>
        <td
          mat-cell
          *matCellDef="let invoice"
        >
          {{ getDate(invoice.period_end) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="value">
        <th
          mat-header-cell
          *matHeaderCellDef
        >
          Value
        </th>
        <td
          mat-cell
          *matCellDef="let invoice"
          class="text-center"
        >
          {{ invoice.currency | uppercase }} {{ invoice.total / 100 }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="status"
        class="text-center"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
        >
          Status
        </th>
        <td
          mat-cell
          *matCellDef="let invoice"
          class="text-center"
        >
          <span
            *ngIf="invoice.status === 'paid'"
            class="paid"
            >Paid</span
          >
          <span
            *ngIf="invoice.status === 'draft' || invoice.status === 'open'"
            class="waitingPayment"
            >Waiting payment</span
          >
          <span
            *ngIf="invoice.status_transitions.marked_uncollectible_at"
            class="paymentFailed"
            >Payment failed</span
          >
        </td>
      </ng-container>

      <ng-container matColumnDef="download">
        <th
          mat-header-cell
          *matHeaderCellDef
        >
          Download
        </th>
        <td
          mat-cell
          *matCellDef="let invoice"
          class="text-center"
        >
          <a
            href="{{ invoice.invoice_pdf }}"
            rel="noopener"
            ><fa-icon
              [icon]="downloadIcon"
              matTooltipPosition="above"
              matTooltip="Download invoice pdf"
            ></fa-icon
          ></a>
        </td>
      </ng-container>
    </div>

    <tr
      mat-header-row
      *matHeaderRowDef="displayedColumns"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
    ></tr>
  </table>

  <mat-paginator
    class="col-12 row px-0 mx-0"
    [pageSizeOptions]="[5]"
    aria-label="Select page"
  ></mat-paginator>

  <div class="d-flex justify-content-center align-items-center">
    <app-simple-button
      [text]="'Close'"
      (click)="closeDialog()"
      class="p-3"
    ></app-simple-button>
  </div>
</div>
