import { Component, OnInit } from '@angular/core';
import { GlobalConfigurationHelper } from '../../../../../services/utils/global-configuration-helper';
import { IconDefinition, faWarning } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../../../../services/auth/auth.service';
import { CloseWarningService } from '../../services/closeWarningService/close-warning.service';
import { LoggerService } from 'src/app/services/logger/logger.service';

@Component({
  selector: 'header-warning-message-banner',
  templateUrl: './warning-message-banner.component.html',
  styleUrls: ['./warning-message-banner.component.scss'],
})
export class WarningMessageBannerComponent implements OnInit {
  public showWarning = false;
  public warningMessage: { enabled: boolean; text: string };
  public warningIcon: IconDefinition = faWarning;

  constructor(
    private _globalConfigurationHelper: GlobalConfigurationHelper,
    private _closeWarningService: CloseWarningService,
    private _auth: AuthService,
    private _logger: LoggerService,
  ) {}

  // Lifecycle

  async ngOnInit(): Promise<void> {
    await this.loadWarningMessage();
  }

  private async loadWarningMessage() {
    if (this._auth.isAuthenticated()) {
      try {
        const globalConfig = await this._globalConfigurationHelper.getGlobalConfiguration();
        this.warningMessage = globalConfig.warningMessage;
      } catch (err) {
        this._logger.error('Could not load global config.', err);
        this.warningMessage = {
          text: '',
          enabled: false,
        };
      }

      this.shouldShowWarningMessage();
    }
  }

  private shouldShowWarningMessage() {
    if (this.warningMessage.enabled && this._closeWarningService.enabled) {
      this.showWarning = true;
    } else {
      this.showWarning = false;
    }
  }

  // Click Handler --

  public closeWarningMessage() {
    this._closeWarningService.enabled = false;
    this.showWarning = false;
  }
}
