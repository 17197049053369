import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { startCase } from 'lodash';
import { gameNamesCDH } from '../../consts/global-constants';

@Component({
  selector: 'metadata-dialog',
  templateUrl: './metadata.dialog.html',
  styleUrls: ['./metadata.dialog.scss'],
})
export class MetadataDialogComponent implements OnInit {
  public metadata;
  public totalScore;

  constructor(
    public dialogRef: MatDialogRef<MetadataDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private ngZone: NgZone,
  ) {}

  ngOnInit() {
    this.prepareMetadata();
  }

  prepareMetadata() {
    this.totalScore = 0;
    this.metadata = this.data.metadata.map((exercise, i) => {
      const { score, session, lang, level } = exercise;
      const index = i + 1;

      let { name } = exercise;

      if (name.includes('cdh')) {
        name = this.swapNamesCDH(name);
      }

      this.totalScore += score;

      return {
        name: startCase(name),
        index,
        score,
        level,
        lang,
        session,
      };
    });
  }

  swapNamesCDH(name) {
    return gameNamesCDH[name];
  }

  onCancel(): void {
    this.ngZone.run(() => {
      this.dialogRef.close();
    });
  }
}
