<div
  class="bundle-card"
  [ngClass]="{ certificationBundle: bundle.certification }"
>
  <div class="mainContainer">
    <div
      class="text-center month"
      *ngIf="bundle.subscription"
    >
      <div class="col-12 px-0 text-center header">Monthly</div>
      <div class="text-center bundleDetails">
        <div class="price">
          &#36;{{ bundle.price }} {{ bundle.currency || 'CAD' }}
        </div>

        <div class="font500">
          {{ bundle.studentsIncluded }} credit{{
            bundle.studentsIncluded > 1 ? 's' : ''
          }}
          {{ bundle.subscription ? '/month' : '' }}
        </div>

        <div class="font500">
          Save
          {{
            getSavePercentage(
              bundle.price,
              singlePurchasePrice,
              bundle.studentsIncluded
            )
          }}%
        </div>

        <small>off single credit purchase</small>
      </div>
    </div>

    <div
      class="text-center single"
      *ngIf="!bundle.subscription"
    >
      <div class="text-center bundleDetails">
        <div class="price">
          &#36;{{ bundle.price }} {{ bundle.currency || 'CAD' }}
        </div>

        <div
          class="font500 m-auto"
          *ngIf="bundle.certification"
          [ngStyle]="{ width: '160px' }"
        >
          Become a certified Neuralign provider!
        </div>

        <div
          class="font500"
          *ngIf="!bundle.certification"
        >
          {{ bundle.studentsIncluded }} credit{{
            bundle.studentsIncluded > 1 ? 's' : ''
          }}
        </div>
      </div>
    </div>

    <div
      class="text-center year mt-2"
      *ngIf="bundle.subscription"
    >
      <div class="col-12 px-0 text-center header">Yearly</div>
      <div class="text-center bundleDetails">
        <div class="price">
          &#36;{{ bundle.yearlyPrice }}
          {{ bundle.currency || 'CAD' }}
        </div>

        <div class="font500">{{ bundle.studentsIncluded }} credits upfront</div>

        <div class="font500">
          Save &#36;{{ bundle.price * 12 - bundle.yearlyPrice }}
        </div>

        <small>off monthly plan</small>
      </div>
    </div>

    <div class="text-center mt-4 font500 bundleInfo pb-5">
      <app-bundle-details-template
        [bundle]="bundle"
      ></app-bundle-details-template>
    </div>

    <div
      class="col-12 px-0 text-center"
      *ngIf="bundle.subscription"
    >
      <mat-radio-group
        aria-labelledby="example-radio-group-label"
        class="example-radio-group"
        [(ngModel)]="subscriptionInterval"
      >
        <mat-radio-button
          value="month"
          class="example-radio-button subscriptionRadioButton"
          [ngStyle]="{ 'margin-right': '36px' }"
        >
          Monthly
          <span class="details"> (&#36;{{ bundle.price }}/month) </span>
        </mat-radio-button>
        <br />
        <mat-radio-button
          value="year"
          class="example-radio-button subscriptionRadioButton"
        >
          Yearly
          <span class="details">
            (&#36;{{ bundle.yearlyPrice }} - Save &#36;{{
              bundle.price * 12 - bundle.yearlyPrice
            }})
          </span>
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <div class="cart">
    <span
      [ngClass]="{ disabled: bundle.subscription && !subscriptionInterval }"
      class="add"
      (click)="addToCart()"
      *ngIf="!bundle.certification"
    >
      <fa-icon [icon]="cartIcon"></fa-icon> &nbsp; Purchase
    </span>

    <span
      class="add"
      (click)="goto('https://lsworks.thinkific.com/collections')"
      *ngIf="bundle.certification"
    >
      <fa-icon [icon]="cartIcon"></fa-icon> &nbsp; Purchase
    </span>
  </div>
</div>
