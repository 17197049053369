import { Component, OnInit } from '@angular/core';
import { PricingBundle } from '../pricing/interfaces/pricing-bundle.interface';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/services/auth/auth.service';
import { get, sortBy } from 'lodash';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { User } from 'src/app/shared/models/user.model';
import { Router } from '@angular/router';
import { isEmpty, cloneDeep } from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoggerService } from 'src/app/services/logger/logger.service';

@Component({
  selector: 'app-admin-pricing',
  templateUrl: './admin-pricing.component.html',
  styleUrls: ['./admin-pricing.component.scss'],
})
export class AdminPricingComponent implements OnInit {
  public baseBundles: PricingBundle[] = [
    {
      title: 'Single.',
      price: 1275,
      discount: 0,
      fullPrice: 1275,
      studentsIncluded: 1,
      hiddenPrice: false,
      currency: 'USD',
      order: 0,
      isDefault: true,
      isFirstPurchaseBundle: false,
    },
    {
      title: 'Trio.',
      price: 3300,
      discount: 0,
      fullPrice: 3300,
      studentsIncluded: 3,
      hiddenPrice: false,
      currency: 'USD',
      order: 1,
      isDefault: true,
      isFirstPurchaseBundle: false,
    },
    {
      title: 'Pro.',
      price: 0,
      fullPrice: 0,
      discount: 0,
      hiddenPrice: true,
      message:
        '<span>Please email us at <a href="mailto:aspiremg.ls@gmail.com">aspiremg.ls@gmail.com</a> for special pricing and discounts for educators and mentors.</span>',
      currency: 'USD',
      order: 2,
      isDefault: true,
      isFirstPurchaseBundle: false,
    },
    {
      title: 'First purchase',
      price: 250,
      fullPrice: 250,
      discount: 0,
      currency: 'USD',
      studentsIncluded: 2,
      isFirstPurchaseBundle: true,
      isDefault: true,
      order: 3,
    },
  ];
  public displayBundles: PricingBundle[];

  public outsiderName: string;
  public outsiderId: string;
  public user: User;
  public organization;
  public showInfo = false;
  private replaceRegex = /##replace##/g;
  public message =
    '<span>Please email us at <a href="mailto:##replace##">##replace##</a> for special pricing and discounts for educators and mentors.</span>';
  public checkIcon: IconDefinition = faCheck;
  public subscriptionList: any;
  public allowSubscription: boolean;

  constructor(
    public _authService: AuthService,
    public _rest: RestAPIService,
    public _router: Router,
    protected _snackBar: MatSnackBar,
    private logger: LoggerService,
  ) {}

  async ngOnInit() {
    const queryParams = this._router['currentUrlTree']['queryParams'];

    if (isEmpty(queryParams) === false) {
      this.outsiderName = queryParams.name;
      this.outsiderId = queryParams.id;
    }

    this.user = await this._authService.getUser();
    const { organization } = await this._rest.get('organization/self', {
      msg: 'Could not get organization/self.',
    });
    this.organization = organization;
    this.message = this.message.replace(this.replaceRegex, get(this.user, 'email', ''));

    await this.getBundles();
    await this.checkIfSubscriptionIsAllowed();
    this.showInfo = true;
  }

  async getBundles() {
    const resellerId = this.user.organization
      ? get(this.user, 'organization.id', '')
      : get(this.organization, 'id', '');
    const bundlesResponse = await this._rest.get(`bundle/${resellerId}`, {
      msg: 'Could not get bundle/:resellerId.',
    });

    const bundleList = cloneDeep(this.baseBundles);

    const { bundles } = bundlesResponse;

    let defaultBundles = bundles.filter((b) => isEmpty(b.belongsTo));
    const baseBundles = bundleList;

    defaultBundles.forEach((e) => {
      baseBundles.splice(e.order, 1, e);
    });
    defaultBundles = baseBundles;

    defaultBundles = sortBy(defaultBundles, 'order');

    if (isEmpty(this.outsiderId) === false) {
      const outsiderBundles = bundles.filter((b) => b.belongsTo === this.outsiderId);

      if (outsiderBundles.length > 0) {
        outsiderBundles.forEach((e) => {
          defaultBundles.splice(e.order, 1, e);
        });
      }
    }

    this.displayBundles = defaultBundles;
  }

  async save($bundleToSave: PricingBundle) {
    if (get($bundleToSave, 'id', false)) {
      await this._rest.put(
        `bundle/${$bundleToSave.id}`,
        {
          bundle: $bundleToSave,
        },
        { msg: 'Could not put bundle.' },
      );
    } else {
      const resellerId = this.user.organization
        ? get(this.user, 'organization.id', '')
        : get(this.organization, 'id', '');
      await this._rest.post(
        `bundle`,
        {
          bundle: $bundleToSave,
          resellerOrganizationId: resellerId,
        },
        { msg: 'Could not post bundle' },
      );
    }

    this.getBundles();

    this._snackBar.open('Bundle saved!', 'Close', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  async delete(bundleId: string) {
    const orgId = this.user.organization ? this.user.organization.id : this.organization.id;

    try {
      const response = await this._rest.delete(`/bundle/bundleId/${bundleId}/orgId/${orgId}`, {
        msg: 'Bundle reset failed, please try again!',
      });

      if (!response) {
        throw new Error('Bundle reset failed, please try again!');
      }

      this._snackBar.open('Bundle reseted to default!', 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });

      this.getBundles();
    } catch (error) {
      this.logger.error(error);
    }
  }

  public async checkIfSubscriptionIsAllowed() {
    const user: User = this.user;

    if (!user.organization && !this.organization) {
      return;
    }

    const id = user.organization ? user.organization.id : this.organization.id;

    const findOrg = await this._rest.get('organization/findById/' + id, {
      msg: 'Coudn not get organization/findById.',
    });

    if (findOrg.organization.allowSubscription) {
      this.allowSubscription = true;
    } else {
      this.allowSubscription = false;
    }
  }
}
