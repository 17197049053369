<h1 #title>
  {{ orgName }}
</h1>

<div
  #box
  class="mobile-col"
>
  <section
    #panel
    class="panel"
  >
    <div #logo>
      <img
        src="assets/img/neuralign-home.png"
        width="100%"
      />
    </div>

    <p #paragraph>I want to do Neuralign on my own, at home.</p>

    <button
      outlineBtn
      #button
      (click)="b2cSignup()"
    >
      Sign Up to Buy
    </button>

    <p #paragraph>
      Create an account, use our secure checkout to purchase Neuralign, then
      complete it independently
    </p>
  </section>

  <section
    #panel
    class="panel"
  >
    <div #logo>
      <img
        src="assets/img/neuralign-provider.webp"
        width="100%"
      />
    </div>

    <p #paragraph>
      I want to do Neuralign at home, with the support of a Neuralign clinician.
    </p>

    <button
      primaryBtn
      #button
      (click)="login()"
    >
      Log In to my Account
    </button>

    <p #paragraph>
      Activate the account your Neuralign provider has created for you, and
      complete it with their support.
    </p>
  </section>
</div>
