import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from 'src/app/shared/dialogs/error-dialog/error-dialog.component';

@Injectable()
export class ErrorHandlerService {
  private opened = false;

  constructor(private dialog: MatDialog) {}

  openDialog(data: any): void {
    if (!this.opened) {
      // Send error to clarity
      (window as any)?.clarity?.('event', 'error');

      // open dialog
      this.opened = true;
      // configure dialog
      const dialogRef = this.dialog.open(ErrorDialogComponent, {
        data,
        maxHeight: '100%',
        width: '540px',
        maxWidth: '100%',
        disableClose: true,
        hasBackdrop: true,
      });

      // clean up dialog on close
      dialogRef.afterClosed().subscribe(() => {
        this.opened = false;
      });
    }
  }
}
