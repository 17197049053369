<div class="mainContainer">
  <div class="header col-12 px-0">New course</div>

  <div class="mt-4">
    <div>
      <div class="inputHeader">Course name</div>

      <input
        class="basicInput"
        [(ngModel)]="courseName"
      />
    </div>

    <div>
      <div class="inputHeader">Course title</div>

      <input
        class="basicInput"
        [(ngModel)]="courseTitle"
      />
    </div>

    <div class="w-100 my-3">
      <div class="inputHeader">Course image</div>

      <div>
        <img
          class="w-50"
          [src]="courseImage"
        />
      </div>

      <div class="my-2">
        <app-simple-button
          [text]="'Add course image'"
          (click)="addCourseImage()"
        ></app-simple-button>
      </div>
    </div>

    <div class="saveButton mt-5">
      <app-simple-button
        class="mx-2"
        [text]="'Create course'"
        (click)="saveCourse()"
      ></app-simple-button>

      <app-simple-button
        class="mx-2"
        [text]="'Cancel'"
        (click)="closeDialog()"
      ></app-simple-button>
    </div>
  </div>
</div>
