import { Biography } from './biography.interface';

export const biographies: Biography[] = [
  {
    name: 'Ingrid Poupart - Co-founder',
    id: 'ingrid',
    jobTitle: 'Chairperson',
    imageUrl: 'Ingrid.jpg',
    body: [
      'As a dyslexic, Ingrid approached her career in education with an insider’s perspective into learning challenges. With over 30 years of experience in the field of education, Ingrid’s passion for reading remediation was fueled by her own dyslexic child’s struggles, which allows her to connect with students in a truly unique way. Ingrid has founded two successful learning centres that specialize in reading disorders and has implemented school-wide dyslexia-friendly reading programs in several Elementary schools.',
      'Ingrid is also an international speaker and has spoken about how it feels to be dyslexic, in Kolkata India at the Department of Occupational Therapy, the National Institute of Disabilities, the Department of Psychology at the University of Calcutta, the Press Club of India in New Deli, and the Mahatma Gandhi University of Medical sciences and technology, she has also presented at professional development conferences in Philadelphia, Ontario, and Montreal. Ingrid is also certified in multiple research-based learning interventions.',
    ],
  },
  {
    name: 'Erika Poupart - Co-founder',
    id: 'erika',
    jobTitle: 'Chief Creative Officer (CCO)',
    imageUrl: 'Erika_Poupart.png',
    body: [
      'A graduate of the International Academy of Design in Montreal, Quebec, Erika brings 20 years of experience in the visual design and layout of communication materials to the LSWorks team. Her notable experience includes work for Admark Communication and Design, Autasco Inc., and COMMETTA, as well as over a decade of freelance graphic design work.',
      'Together with Ingrid, she is the co-creator of the Neuralign program, having developed its striking graphic displays. She is responsible for ongoing brand creation and management as well as program concept development and social media management.',
      'In addition to her professional credits in design, Erika is a Director of Stepping Stones Learning Solutions and a passionate advocate for project-based education. She has explored these approaches in-depth while home-schooling her two children.',
    ],
  },
  {
    name: 'Chris Poupart - Co-founder',
    id: 'chris',
    jobTitle: 'Chief Technology Officer (CTO)',
    imageUrl: 'Chris.jpg',
    body: [
      'Chris brings his 20 years of experience in the field of information technology to the LSWorks team. A graduate of Herzing College in Montreal, QC, he specializes in the areas of Linux server management at scale, Kubernetes, cloud computing, automation, and DevOps. In his role as CTO he is responsible for managing the developer teams and providing innovative technical solutions to drive the company’s mission.',
      'As the father of a dyslexic child, he saw first-hand how the Neuralign program helped his son transition from a reluctant reader to an avid one. Chris saw the value of harnessing his technological expertise to continue to develop the program and make it accessible to other families.',
    ],
  },
  {
    name: 'Mike Andrews',
    id: 'mike',
    jobTitle: 'Business Development/Legal',
    imageUrl: 'Mike_Andrews.png',
    body: [
      'Michael is a founding and Senior Partner of Andrews Robichaud. He is an Intellectual Property and Business lawyer and a Registered Trade-mark Agent and advises on regular and complex trade-mark, copyright, patent, and industrial design matters, including the searching, filing, prosecution, registration, litigation, and enforcement of trade-marks, copyrights, patents and industrial designs in Canada and internationally through a world-wide network of qualified associates. His clients range from small business owners to Fortune 100 companies and include industry sectors spanning business, high technology, biotechnology, consumer products, entertainment, sports, and the arts. Mike is routinely retained to act on behalf of well-known and famous brands and personalities.',
      'Applying his business and legal background, Mike assists clients in protecting and expanding their domestic and international knowledge-based businesses using licensing, syndication, financing, and tax strategies. Mike has developed and implemented intellectual property, business, branding, and financial strategies and initiatives for state-of-the-art biometric identification systems, biotechnology developers, international syndication, prime time television, news media, feature film, Internet and web-enabled application service providers, leading fashion and consumer product businesses and emerging fin-tech and edu-tech companies.',
      'Mike is a frequent speaker and author on Canadian and international business, litigation, and tax strategies as they relate to trade-mark, copyright, patent, and intellectual property licensing and related issues. He is equally comfortable addressing the technical issues of concern to intellectual property professionals, as well as the interests of knowledge-based business development, branding, and licensing audiences.',
    ],
  },
  {
    name: 'Indrani Pal-Chaudhuri',
    id: 'indrani',
    jobTitle: 'Chief Visionary Officer/Chief Marketing Officer',
    imageUrl: 'Indrani_Pal-Chaudhuri.png',
    body: [
      "Disruptive Innovation Fellow Indrani is a multimedia artist, marketing expert, Princeton University visiting lecturer, and social justice advocate. A United Nations Women’s Entrepreneurship Distinguished Fellow, and a Max Mark-Cranbrook Global Peacemaker, she was Co-Host of the Global People’s Summit during the General Assembly at the United Nations, and Speaker at Live Med Aid with Doctors Without Borders. A protege of David Bowie, she helped launch the solo careers of Beyonce and Lady Gaga, creating the image of their brands and over 300 others, including Nike, Pepsi, L'Oreal, and Epson. Featured in The New York Times, Vogue, Vanity Fair, on CNN, BBC, HBO, at the Lincoln Center, Center Pompidou, and the Smithsonian Institute, her work has won 34 awards including Best Picture at the CNN Expose Awards, the Tribeca Film Festival Disruptive Innovator Award, and two Gold Lions at Cannes Festival of Creativity.",
      'Serving on many non-profit and impact-driven boards, she is Co-founder and Director of Shakti Empowerment Education Foundation serving the most socio-economically disadvantaged women and children of India. At Princeton, her lectures include: "Mobilizing Millions with Art & Film, for Human Rights & Social Justice," and "Digital Storytelling & Counter-Colonizing the Heteropatriarchal Gaze." She organizes and hosts "Representation, Symbolization, & Indigeneity," at Slought, University of Pennsylvania, the "AI Humanities Symposium" at  New York Live Arts, and was Keynote Speaker at Harvard University\'s "Pride & Progress Symposium." ',
    ],
  },
  {
    name: 'Scott Runte',
    id: 'scott',
    jobTitle: 'CEO Ontario Launch Lab/Virtual CFO',
    imageUrl: 'Scott_Runte.png',
    body: [
      'Scott is a strategic, entrepreneurial leader who is passionate about helping clients, companies, and entrepreneurs realize their potential by focusing on building a strong operational and strategic base that drives success and fuels growth. Scott has comprehensive international experience across multiple sectors. As the General Manager and CFO of an export-driven manufacturing company, he was a key member of the executive team that guided the company through a rapid 5-year growth plan that included raising venture capital, managing mergers, and acquisitions and establishing and maintaining international operations.',
      'Scott is the founder and CEO of Warren Capital Corp. The company works with the leaders of small and medium-sized businesses to help them drive growth and realize success by providing achievable strategic and operational advice. Scott is also the CEO and an Entrepreneur in Residence at Launch Lab, a Regional Innovation Centre providing advisory services to innovative growth companies in Eastern Ontario. Scott’s volunteer experience includes being a six-year Board member with the United Way including holding the position of Board Chair for two consecutive years. Scott earned an MBA from McMaster University in 1991 and a BSc with Honours from Queen’s University in 1988.',
    ],
  },
  {
    name: 'Christian Boutin',
    id: 'christian',
    jobTitle: 'Senior Software Developer',
    imageUrl: 'Christian_Boutin.png',
    body: [
      'Lead engineer with more than 20 years of experience shipping video games, board games, and gaming-related applications, as well as leading and managing teams of engineers. Designer and programmer, running a one-man freelance/game studio that has released 6 games and experiences since 2011 as well as working with several international clients.',
      'Worked for multiple major gaming companies. Shipped 25+ titles from MS-DOS shareware to DirectX-based CD-ROM to Flash and Unity games, as well as mobile applications and games for iOS and Android.',
    ],
  },
];
