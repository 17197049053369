<div
  class="warningDiv title mb-4"
  *ngIf="globalConfigurations"
>
  <span class="mx-2">Warning message</span>
  <div class="enableWarningMessage my-4">
    <mat-radio-group
      name="warningEnable"
      [value]="globalConfigurations.warningMessage.enabled"
    >
      <mat-radio-button
        class="mx-2"
        [value]="globalConfigurations.warningMessage.enabled"
        [checked]="globalConfigurations.warningMessage.enabled"
        [disabled]="globalConfigurations.warningMessage.enabled"
        (click)="toggleWarningMessage(true)"
        [ngClass]="{ disabled: globalConfigurations.warningMessage.enabled }"
        >Enable</mat-radio-button
      >

      <mat-radio-button
        class="mx-2"
        [value]="!globalConfigurations.warningMessage.enabled"
        [checked]="!globalConfigurations.warningMessage.enabled"
        [disabled]="!globalConfigurations.warningMessage.enabled"
        (click)="toggleWarningMessage(false)"
        [ngClass]="{ disabled: !globalConfigurations.warningMessage.enabled }"
        >Disable</mat-radio-button
      >
    </mat-radio-group>
  </div>

  <div class="warningMessageText">
    <textarea
      class="warningTextArea"
      cols="40"
      rows="5"
      resizeble="false"
      [(ngModel)]="globalConfigurations.warningMessage.text"
      [disabled]="!globalConfigurations.warningMessage.enabled"
    ></textarea>
  </div>

  <div class="buttonsWrapper">
    <button
      mat-raised-button
      (click)="saveMessage()"
    >
      Save
    </button>
  </div>
</div>
