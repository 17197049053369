import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { validationAnimation } from '../../helpers/animations';
import { emailRegex, videoRegex } from '../../helpers/regex';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { get } from 'lodash';
import { RoleService } from 'src/app/services/roles/role.service';
import { PublicProfilePreviewComponent } from 'src/app/shared/dialogs/public-profile-preview/public-profile-preview.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

declare let cloudinary: any;

@Component({
  selector: 'public-profile-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  animations: validationAnimation,
})
export class FormComponent implements OnInit {
  public form: FormGroup;
  public organization = this._roles.orgAcc?.organization;
  public descriptionCharacterCount = 0;

  // Lifecycle ---

  constructor(
    private _rest: RestAPIService,
    private _snackBar: MatSnackBar,
    private _roles: RoleService,
    private _dialog: MatDialog,
    private _router: Router,
  ) {}

  async ngOnInit() {
    await this._initForm();
    await this.appendLanguageProvidedControls();
  }

  // Setup --

  private async _initForm(): Promise<void> {
    this.form = new FormGroup<PublicProfileForm>({
      name: new FormControl(this._findValue('publicProfile.name', 'name', ''), [
        Validators.required,
        Validators.maxLength(255),
      ]),

      email: new FormControl(this._findValue('publicProfile.email', 'email', ''), [
        Validators.required,
        Validators.email,
        Validators.pattern(emailRegex),
      ]),

      phoneNumber: new FormControl(this._findValue('publicProfile.phoneNumber', 'phoneNumber', '')),

      website: new FormControl(get(this.organization, 'publicProfile.website', '')),

      address: new FormControl(this._findValue('publicProfile.address', 'address.address', ''), Validators.required),

      postalCode: new FormControl(this._findValue('publicProfile.postalCode', 'address.postalCode', '')),

      province: new FormControl(this._findValue('publicProfile.province', 'address.province', ''), Validators.required),

      country: new FormControl(this._findValue('publicProfile.country', 'address.country', ''), Validators.required),

      logo: new FormControl(this._getFormLogoPath()),

      description: new FormControl(this._getDescription(), [Validators.required, Validators.maxLength(1000)]),

      videoUrl: new FormControl(get(this.organization, 'publicProfile.videoUrl', ''), Validators.pattern(videoRegex)),
    });
  }

  public async appendLanguageProvidedControls(): Promise<void> {
    // get list of all language names from the api
    const allLanguages = (await this._rest.get('/languages')) as { name: string }[];

    // get organizations current list of provided languages
    const currentLanguagesProvided = get(this.organization, 'publicProfile.languagesProvided', []) as {
      name: string;
      value: boolean;
    }[];

    // loop thru all languages and find ones that are already set for the organization
    // provide a default of false for new languages
    const listOfProvidedLanguages = allLanguages.map((lang) => {
      const current = currentLanguagesProvided.find((current) => current.name === lang.name);

      if (current) {
        return {
          name: current.name,
          value: current.value,
        };
      } else {
        return {
          name: lang.name,
          value: false,
        };
      }
    });

    // create a key value pair to hold form controls
    const controls = {};

    // add key values to controls
    listOfProvidedLanguages.map((lang) => (controls[lang.name] = new FormControl(lang.value)));

    // add new form group to form passing in the controls
    this.form.addControl('languagesProvided', new FormGroup(controls));
  }

  // Initalizers --

  // When a user has a new account they don't have a public profile property yet,
  // so we need to get intial values from the organization object.
  private _findValue(publicProfile: string, orgObject: string, defaultValue: unknown): unknown {
    const fromPublicProfile = get(this.organization, publicProfile, '');
    const fromOrgObject = get(this.organization, orgObject, '');

    if (fromPublicProfile) {
      return fromPublicProfile;
    } else if (fromOrgObject) {
      return fromOrgObject;
    } else {
      return defaultValue;
    }
  }

  private _getFormLogoPath(): string {
    const path = get(this.organization, 'publicProfile.logo', '');
    if (path === '') {
      return 'assets/img/profile-placeholder.jpg';
    } else {
      return path;
    }
  }

  private _getDescription(): string {
    const description = get(this.organization, 'publicProfile.description', '');
    this.descriptionCharacterCount = description.length;
    return description;
  }

  // Handlers ---

  public save(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.setupFormForSaving();
    this.putForm();
  }

  public preview(): void {
    this._dialog.open(PublicProfilePreviewComponent, {
      data: this.form.value,
      width: '1000px',
      panelClass: 'public-profile-preview',
    });
  }

  public cancel(): void {
    this._router.navigate(['']);
  }

  public uploadToCloudinary(): void {
    const cloudinaryConfig = {
      cloud_name: environment.CLOUDINARY_CLOUD_NAME,
      upload_preset: environment.CLOUDINARY_UPLOAD_PRESET,
      secure: true,
    };

    cloudinary.openUploadWidget(cloudinaryConfig, (error, result) => {
      if (result) {
        const [imageResponse] = result;
        const { secure_url, url } = imageResponse;
        this.form.get('logo').setValue(secure_url || url);
        this.form.markAsDirty();
      } else if (error && error.message !== 'User closed widget') {
        this._snackBar.open('There was an error while uploading the image, please try again later', 'OK', {
          duration: 5000,
        });
      }
    });
  }

  public countCharacters(description: string): void {
    this.descriptionCharacterCount = description.length;
  }

  public areRequiredFieldsValid() {
    const boolean =
      (this.name.invalid && (this.name.touched || this.name.dirty)) ||
      (this.email.invalid && (this.email.touched || this.email.dirty)) ||
      this.phoneNumber.invalid ||
      (this.province.invalid && (this.province.touched || this.province.dirty)) ||
      (this.country.invalid && (this.country.touched || this.country.dirty)) ||
      (this.description.invalid && (this.description.touched || this.description.dirty));

    return boolean;
  }

  // Helpers --

  public setupFormForSaving(): void {
    // In order for the form to be able to register the checkboxes
    // it has to be able to use them as individual FormControls in a FormGroup,
    // but the API saves them as an array
    const languagesProvidedConvertedToArray = Object.entries(this.form.value.languagesProvided).map((lang) => {
      return {
        name: lang[0],
        value: lang[1],
      };
    });

    this.form.value['languagesProvided'] = languagesProvidedConvertedToArray;
  }

  public async putForm(): Promise<void> {
    await this._rest
      .put(
        'organization/setOrganizationPublicProfile',
        {
          publicProfile: this.form.value,
        },
        { msg: 'Could not put organization profile.' },
      )
      .then(() => {
        this._snackBar.open('Public profile sucessfully updated!', 'Close', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          duration: 2000,
        });
        this.form.markAsPristine();
      });
  }

  // Getters ---

  get name() {
    return this.form.get('name');
  }
  get phoneNumber() {
    return this.form.get('phoneNumber');
  }
  get email() {
    return this.form.get('email');
  }
  get website() {
    return this.form.get('website');
  }
  get address() {
    return this.form.get('address');
  }
  get postalCode() {
    return this.form.get('postalCode');
  }
  get province() {
    return this.form.get('province');
  }
  get country() {
    return this.form.get('country');
  }
  get logo() {
    return this.form.get('logo');
  }
  get description() {
    return this.form.get('description');
  }
  get videoUrl() {
    return this.form.get('videoUrl');
  }
  get languagesProvided() {
    return this.form.get('languagesProvided');
  }
}

interface PublicProfileForm {
  name: FormControl;
  phoneNumber: FormControl;
  email: FormControl;
  website: FormControl;
  address: FormControl;
  postalCode: FormControl;
  province: FormControl;
  country: FormControl;
  logo: FormControl;
  description: FormControl;
  videoUrl: FormControl;
  languagesProvided?: FormGroup;
}
