import { Injectable } from '@angular/core';
import { LogLevel } from 'src/app/shared/consts/loglevel';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  level: LogLevel = environment.LOG_LEVEL;

  public debug(...args: any[]) {
    this.log(LogLevel.DEBUG, ...args);
  }

  public info(...args: any[]) {
    this.log(LogLevel.INFO, ...args);
  }

  public warn(...args: any[]) {
    this.log(LogLevel.WARN, ...args);
  }

  public error(...args: any[]) {
    this.log(LogLevel.ERROR, ...args);
  }

  /**
   * Write logs but guard against production and log level
   */
  private log(level: LogLevel, ...args: any[]) {
    // Guard log level
    if (this.shouldLog(level)) {
      const emoji = this.chooseEmoji(level);
      const timestamp = new Date().toLocaleTimeString('en-US', { hour12: false }).split(' ')[0];

      const input = [emoji, timestamp, ...args];

      switch (level) {
        case LogLevel.DEBUG:
        case LogLevel.INFO:
          return console.log(...input);
        case LogLevel.WARN:
          return console.warn(...input);
        case LogLevel.ERROR:
          return console.error(...input);
        default:
          console.info('no matching log level found.');
      }
    }
  }

  /**
   * Sets log visibility in a cascading manner DEBUG > INFO > WARN > ERROR > NONE
   */
  private shouldLog(level: LogLevel): boolean {
    return (level >= this.level && level !== LogLevel.NONE) || this.level === LogLevel.DEBUG;
  }

  /**
   * The coolest thing about making our own logger is adding emoji!
   */
  private chooseEmoji(level: LogLevel) {
    switch (level) {
      case LogLevel.DEBUG:
        return '🐛';
      case LogLevel.INFO:
        return '🏳️';
      case LogLevel.WARN:
        return '🏴‍☠️';
      case LogLevel.ERROR:
        return '💥';
      default:
        return '';
    }
  }
}
