<div
  class="wrapper"
  [ngStyle]="{ backgroundColor: isSelected ? '#DFDDEE' : 'transparent' }"
>
  <completion-icon [isCompleted]="section.isCompleted"></completion-icon>
  <courses-shared-lesson-type-icon
    [type]="section.type"
  ></courses-shared-lesson-type-icon>
  <p>
    {{ section.title }}
  </p>
</div>
