<div class="bundle-card">
  <form [formGroup]="bundleFormGroup">
    <h3 class="text-center">Bundle {{ data.bundle.title }}</h3>

    <mat-form-field
      appearance="outline"
      class="w-100"
    >
      <mat-label>Title</mat-label>
      <input
        matInput
        required
        placeholder="Title"
        formControlName="title"
      />
    </mat-form-field>

    <mat-checkbox formControlName="subscription"> Subscription </mat-checkbox>
    <mat-checkbox
      [disabled]
      formControlName="isFirstPurchaseBundle"
    >
      First Purchase
    </mat-checkbox>
    <mat-form-field
      appearance="outline"
      class="w-100"
    >
      <mat-label>Price</mat-label>
      <input
        matInput
        type="number"
        required
        placeholder="Price"
        formControlName="price"
      />
    </mat-form-field>

    <mat-form-field
      appearance="outline"
      *ngIf="bundleFormGroup.value.subscription"
      class="w-100"
    >
      <mat-label>Yearly price ( only for subscriptions )</mat-label>
      <input
        matInput
        type="number"
        min="0"
        required
        placeholder="Yearly Price"
        formControlName="yearlyPrice"
      />
    </mat-form-field>

    <mat-form-field
      appearance="outline"
      class="w-100"
    >
      <mat-label>Currency</mat-label>
      <mat-select formControlName="currency">
        <mat-option value="CAD">CAD</mat-option>
        <mat-option value="USD">USD</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      appearance="outline"
      class="w-100"
    >
      <mat-label>Student Per Bundle</mat-label>
      <input
        matInput
        type="number"
        min="1"
        required
        placeholder="Students"
        formControlName="studentsIncluded"
      />
    </mat-form-field>
  </form>
  <div class="save">
    <app-simple-button
      class="mx-1"
      [text]="'SAVE'"
      (click)="save()"
      [disabled]="bundleFormGroup.status === 'INVALID'"
    >
    </app-simple-button>
  </div>
</div>
