'use strict';

exports.__esModule = true;
var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];
    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }
  return target;
};
var _react = require('react');
var _react2 = _interopRequireDefault(_react);
var _input_wrap = require('./input_wrap');
var _input_wrap2 = _interopRequireDefault(_input_wrap);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
function _objectWithoutProperties(obj, keys) {
  var target = {};
  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }
  return target;
}
function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}
function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }
  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}
function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}
var IconSvg = _react2.default.createElement('svg', {
  'aria-hidden': 'true',
  focusable: 'false',
  width: '13px',
  height: '14px',
  viewBox: '0 0 15 16',
  version: '1.1',
  xmlns: 'http://www.w3.org/2000/svg',
  xmlnsXlink: 'http://www.w3.org/1999/xlink',
  className: 'auth0-lock-icon auth0-lock-icon-box'
}, _react2.default.createElement('g', {
  stroke: 'none',
  strokeWidth: '1',
  fill: 'none',
  fillRule: 'evenodd'
}, _react2.default.createElement('g', {
  transform: 'translate(-11.000000, -1471.000000)',
  fill: '#888888'
}, _react2.default.createElement('path', {
  d: 'M25.552,1486.998 L11.449,1486.998 C10.667,1485.799 10.984,1483.399 11.766,1482.6 C12.139,1482.219 14.931,1481.5 16.267,1481.172 C14.856,1480.076 13.995,1478.042 13.995,1476.103 C13.995,1473.284 14.813,1470.999 18.498,1470.999 C22.182,1470.999 23,1473.284 23,1476.103 C23,1478.037 22.145,1480.065 20.74,1481.163 C22.046,1481.489 24.88,1482.228 25.241,1482.601 C26.019,1483.399 26.328,1485.799 25.552,1486.998 L25.552,1486.998 Z M24.6,1483.443 C24.087,1483.169 21.881,1482.548 20,1482.097 L20,1480.513 C21.254,1479.659 21.997,1477.806 21.997,1476.12 C21.997,1473.841 21.414,1471.993 18.499,1471.993 C15.583,1471.993 15,1473.841 15,1476.12 C15,1477.807 15.744,1479.662 17,1480.515 L17,1482.112 C15.109,1482.556 12.914,1483.166 12.409,1483.442 C12.082,1483.854 11.797,1485.173 12,1486 L25,1486 C25.201,1485.174 24.922,1483.858 24.6,1483.443 L24.6,1483.443 Z'
}))));
var UsernameInput = function (_React$Component) {
  _inherits(UsernameInput, _React$Component);
  function UsernameInput(props) {
    _classCallCheck(this, UsernameInput);
    var _this = _possibleConstructorReturn(this, _React$Component.call(this, props));
    _this.state = {};
    return _this;
  }
  UsernameInput.prototype.shouldComponentUpdate = function shouldComponentUpdate(nextProps, nextState) {
    var _props = this.props,
      invalidHint = _props.invalidHint,
      isValid = _props.isValid,
      value = _props.value,
      disabled = _props.disabled,
      onChange = _props.onChange;
    var focused = this.state.focused;
    return invalidHint != nextProps.invalidHint || isValid != nextProps.isValid || value != nextProps.value || focused != nextState.focused || disabled != nextProps.disabled;
  };
  UsernameInput.prototype.render = function render() {
    var _props2 = this.props,
      invalidHint = _props2.invalidHint,
      isValid = _props2.isValid,
      onChange = _props2.onChange,
      autoComplete = _props2.autoComplete,
      props = _objectWithoutProperties(_props2, ['invalidHint', 'isValid', 'onChange', 'autoComplete']);
    var focused = this.state.focused;
    return _react2.default.createElement(_input_wrap2.default, {
      focused: focused,
      invalidHint: invalidHint,
      isValid: isValid,
      name: 'username',
      icon: IconSvg
    }, _react2.default.createElement('input', _extends({
      ref: 'input',
      type: 'text',
      name: 'username',
      className: 'auth0-lock-input',
      placeholder: 'username',
      autoComplete: autoComplete ? 'on' : 'off',
      autoCapitalize: 'off',
      spellCheck: 'off',
      autoCorrect: 'off',
      onChange: this.handleOnChange.bind(this),
      onFocus: this.handleFocus.bind(this),
      onBlur: this.handleBlur.bind(this),
      'aria-label': 'User name',
      'aria-invalid': !isValid,
      'aria-describedby': !isValid && invalidHint ? 'auth0-lock-error-msg-username' : undefined
    }, props)));
  };
  UsernameInput.prototype.handleOnChange = function handleOnChange(e) {
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  };
  UsernameInput.prototype.handleFocus = function handleFocus() {
    this.setState({
      focused: true
    });
  };
  UsernameInput.prototype.handleBlur = function handleBlur() {
    this.setState({
      focused: false
    });
  };
  return UsernameInput;
}(_react2.default.Component);

// TODO: specify propTypes

exports.default = UsernameInput;