import { Injectable } from '@angular/core';
import FileSaver from 'file-saver';
import { WorkSheet, WorkBook, write, utils, writeFile } from 'xlsx';
import { EXCEL_EXTENSION, EXCEL_TYPE } from './excel.constants';

@Injectable()
export class ExcelService {
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const myworksheet: WorkSheet = utils.json_to_sheet(json);
    const myworkbook: WorkBook = { Sheets: { data: myworksheet }, SheetNames: ['data'] };
    const excelBuffer: any = write(myworkbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(data, fileName + '_exported' + EXCEL_EXTENSION);
  }

  public exportClasswalletStatement(formattedHeaders: string[], rows) {
    const worksheet = utils.aoa_to_sheet([formattedHeaders, ...rows]);

    const borderStyle = {
      top: { style: 'thin', color: { rgb: '000000' } },
      left: { style: 'thin', color: { rgb: '000000' } },
      bottom: { style: 'thin', color: { rgb: '000000' } },
      right: { style: 'thin', color: { rgb: '000000' } },
    };

    const range = utils.decode_range(worksheet['!ref']!);
    for (let row = range.s.r; row <= range.e.r; row++) {
      for (let col = range.s.c; col <= range.e.c; col++) {
        const cell = worksheet[utils.encode_cell({ r: row, c: col })];

        if (cell) {
          cell.border = borderStyle;
          cell.alignment = { horizontal: 'center', vertical: 'center' };
          const colors = ['#FFFFF0', '#D3D3D3'];
          const colorIndex = row % 2;
          cell.fill = {
            fgColor: { rgb: colors[colorIndex].replace('#', '') },
          };

          if (row === 0) {
            cell.font = { bold: true };
          }
        }
      }
    }
    worksheet['!cols'] = [{ wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 15 }, { wch: 18 }, { wch: 18 }];

    const workbook = { Sheets: { Statement: worksheet }, SheetNames: ['Statement'] };

    writeFile(workbook, 'statement.xlsx');
  }
}
