import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { get } from 'lodash';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

declare let cloudinary: any;

@Component({
  selector: 'app-public-profile-edit',
  templateUrl: './public-profile-edit-dialog.component.html',
  styleUrls: ['./public-profile-edit-dialog.component.scss'],
})
export class PublicProfileEditDialogComponent implements OnInit {
  public profileFormGroup: UntypedFormGroup;
  public videosFormGroup: UntypedFormGroup;
  public organization: any;
  public user: any;
  public loading = true;
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatTable) table: MatTable<any>;

  displayedColumns: string[] = ['code', 'url', 'actions'];

  public readonly deleteIcon: IconDefinition = faTrash;

  public exampleVideo = [
    { code: 'KW', url: 'test/video' },
    { code: 'US', url: 'test/video/' },
  ];

  constructor(
    private _rest: RestAPIService,
    private _auth: AuthService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PublicProfileEditDialogComponent>,
  ) {}

  async ngOnInit() {
    this.profileFormGroup = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      phoneNumber: new UntypedFormControl(''),
      email: new UntypedFormControl(''),
      website: new UntypedFormControl(''),
      address: new UntypedFormControl(''),
      postalCode: new UntypedFormControl(''),
      province: new UntypedFormControl('', Validators.required),
      country: new UntypedFormControl('', Validators.required),
      logo: new UntypedFormControl(''),
      description: new UntypedFormControl('', Validators.required),
      videoUrl: new UntypedFormControl(''),
      enabled: new UntypedFormControl(''),
      videosWithSubtitles: new UntypedFormControl([]),
    });

    this.videosFormGroup = new UntypedFormGroup({
      code: new UntypedFormControl('', Validators.required),
      url: new UntypedFormControl('', Validators.required),
    });

    await this.findUserOrganization();
    this.dataSource = new MatTableDataSource(this.profileFormGroup.get('videosWithSubtitles').value);
  }

  public async findUserOrganization() {
    this.organization = this.data.organization;
    this.user = this.data;
    this.fillOrgInformation();
  }

  public fillOrgInformation() {
    if (!this.organization.publicProfile) {
      this.profileFormGroup.setValue({
        name: get(this.organization, 'name', ''),
        phoneNumber: get(this.organization, 'phoneNumber', ''),
        email: get(this.user, 'email', ''),
        website: '',
        address: get(this.organization, 'address.address', ''),
        postalCode: get(this.organization, 'address.postalCode', ''),
        province: get(this.organization, 'address.province', ''),
        country: get(this.organization, 'address.country', ''),
        logo: get(this.organization, 'logo', ''),
        description: '',
        videoUrl: '',
        enabled: false,
        videosWithSubtitles: [],
      });
    } else {
      this.profileFormGroup.setValue({
        name: get(this.organization, 'publicProfile.name', ''),
        phoneNumber: get(this.organization, 'publicProfile.phoneNumber', ''),
        email: get(this.organization, 'publicProfile.email', ''),
        website: get(this.organization, 'publicProfile.website', ''),
        address: get(this.organization, 'publicProfile.address', ''),
        postalCode: get(this.organization, 'publicProfile.postalCode', ''),
        province: get(this.organization, 'publicProfile.province', ''),
        country: get(this.organization, 'publicProfile.country', ''),
        logo: get(this.organization, 'publicProfile.logo', ''),
        description: get(this.organization, 'publicProfile.description', ''),
        videoUrl: get(this.organization, 'publicProfile.videoUrl', ''),
        enabled: get(this.organization, 'publicProfile.enabled', false),
        videosWithSubtitles: get(this.organization, 'publicProfile.videosWithSubtitles', []),
      });
    }

    this.loading = false;
  }

  public uploadToCloudinary() {
    const cloudinaryConfig = {
      cloud_name: environment.CLOUDINARY_CLOUD_NAME,
      upload_preset: environment.CLOUDINARY_UPLOAD_PRESET,
      secure: true,
    };

    cloudinary.openUploadWidget(cloudinaryConfig, (error, result) => {
      if (result) {
        const [imageResponse] = result;
        const { secure_url, url } = imageResponse;
        this.profileFormGroup.get('logo').setValue(secure_url || url);
      } else if (error && error.message !== 'User closed widget') {
        this._snackBar.open('There was an error while uploading the image, please try again later', 'OK', {
          duration: 5000,
        });
      }
    });
  }

  public save() {
    this._rest
      .put(
        'organization/setOrganizationPublicProfile',
        {
          publicProfile: this.profileFormGroup.value,
          organizationId: this.organization.id,
        },
        { msg: 'Could not put organization.' },
      )
      .then(() => {
        this._snackBar.open('Public profile sucessfully updated!', 'Close', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });

        this.dialogRef.close(true);
      });
  }

  public close() {
    this.dialogRef.close();
  }

  public addSubtitledVideo() {
    const videoArray: any[] = this.profileFormGroup.get('videosWithSubtitles').value;
    const newVideo = { code: this.videosFormGroup.get('code').value, url: this.videosFormGroup.get('url').value };

    videoArray.push(newVideo);

    if (this.table) {
      this.table.renderRows();
    }

    this.videosFormGroup.reset();
  }

  public deleteVideo(video) {
    const videoArray: any[] = this.profileFormGroup.get('videosWithSubtitles').value;
    const index = videoArray.indexOf(video);
    videoArray.splice(index, 1);

    if (this.table) {
      this.table.renderRows();
    }
  }

  public goToUrl(url) {
    window.open(url, '_blank');
  }

  public checkVideoUrl(url) {
    if (!url) {
      return false;
    }

    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    const id = match && match[7].length === 11 ? match[7] : false;

    if (!id) {
      return false;
    } else {
      return true;
    }
  }

  public returnVideoUrl(url) {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    const id = match && match[7].length === 11 ? match[7] : false;

    return `https://www.youtube.com/embed/${id}?feature=oembed`;
  }
}
