export type ResponsiveView = 'mobile' | 'desktop' | 'hidden';

export interface SideMenuPreferences {
  /**
   * Is the side menu open or not. Note that, we don't want to save this value if the user is on mobile.
   */
  isOpen: boolean;
  /**
   * Each section has a key to identify the category, and a boolean to indicate if that section is open or closed
   */
  sections: Record<SideMenuSection, boolean>;
}

// Default state is used when intializing service and loading outdated preference objects.
export const DEFAULT_SIDE_MENU_PREF: SideMenuPreferences = {
  isOpen: false,
  sections: {
    none: false,
    users: false,
    admin: false,
    configuration: false,
    programs: false,
    demo: false,
    tutorials: false,
    documents: false,
    stripe: false,
  },
};

// Common breakpoint for mobile devices.
export const MOBILE_BREAKPOINT: number = 768;

// Section names
export type SideMenuSection =
  | 'none'
  | 'users'
  | 'admin'
  | 'configuration'
  | 'programs'
  | 'demo'
  | 'tutorials'
  | 'documents'
  | 'stripe';
