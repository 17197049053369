<lsw-curtain></lsw-curtain>

<app-main-layout
  [breadcrumbs]="[
    { name: 'Home', url: '/' },
    { name: 'Courses', url: '/courses' },
    { name: 'Overview', url: '/overview' }
  ]"
>
  <div
    class="wrapper"
    *ngIf="course$ | async as course"
  >
    <top-heading label="Course Overview"></top-heading>

    <goto-course-button [course]="course"></goto-course-button>

    <br />
    <br />

    <course-overview-title [title]="course?.title"></course-overview-title>

    <course-overview-description
      [description]="course?.description"
    ></course-overview-description>

    <course-overview-grid>
      <course-overview-lesson-item
        *ngFor="let lesson of course?.lessons; index as i"
        [lesson]="lesson"
        [sequence]="i + 1"
      ></course-overview-lesson-item>
    </course-overview-grid>
  </div>
</app-main-layout>
