import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { clamp } from '../../../../../shared/helpers/clamp.function';
import { styleElement } from '../../../../../shared/helpers/styleElement.function';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserType } from 'src/app/shared/interfaces/UserType.enum';

@Component({
  selector: 'home-default-portal-hero',
  templateUrl: './default-portal-hero.component.html',
  styleUrls: ['./default-portal-hero.component.scss'],
})
export class DefaultPortalHeroComponent {
  @ViewChild('callToActionBox') callToActionBox: ElementRef;
  @ViewChild('buttonsBox') buttonsBox: ElementRef;

  // Lifecyle

  constructor(private _renderer: Renderer2, private _router: Router, private _auth: AuthService) {}

  ngAfterViewInit() {
    this.setStyles();
  }

  // Styles --

  private setStyles() {
    styleElement(this._renderer, this.callToActionBox, {
      position: 'absolute',
      left: clamp(9, 72),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      gap: clamp(0, 2),
      height: '100%',
    });

    styleElement(this._renderer, this.buttonsBox, {
      display: 'flex',
      gap: clamp(0.1, 6),
    });
  }

  // Click Handlers --

  public signup(): void {
    this._auth.showSignUpLock(UserType.Organization);
  }

  public tour(): void {
    this._router.navigate(['take-a-tour']);
  }
}
