'use strict';

exports.__esModule = true;
var _react = require('react');
var _react2 = _interopRequireDefault(_react);
var _propTypes = require('prop-types');
var _propTypes2 = _interopRequireDefault(_propTypes);
var _captcha_input = require('../../ui/input/captcha_input');
var _captcha_input2 = _interopRequireDefault(_captcha_input);
var _index = require('../../core/index');
var l = _interopRequireWildcard(_index);
var _index2 = require('../../store/index');
var _captcha = require('../captcha');
var captchaField = _interopRequireWildcard(_captcha);
var _index3 = require('../index');
var _recaptcha = require('./recaptcha');
function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};
    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key];
      }
    }
    newObj.default = obj;
    return newObj;
  }
}
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}
function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }
  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}
function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
} /* eslint-disable no-nested-ternary */

var CaptchaPane = function (_React$Component) {
  _inherits(CaptchaPane, _React$Component);
  function CaptchaPane() {
    _classCallCheck(this, CaptchaPane);
    return _possibleConstructorReturn(this, _React$Component.apply(this, arguments));
  }
  CaptchaPane.prototype.render = function render() {
    var _props = this.props,
      i18n = _props.i18n,
      lock = _props.lock,
      onReload = _props.onReload;
    var lockId = l.id(lock);
    var captcha = l.captcha(lock);
    var value = (0, _index3.getFieldValue)(lock, 'captcha');
    var isValid = !(0, _index3.isFieldVisiblyInvalid)(lock, 'captcha');
    var provider = captcha.get('provider');
    if ((0, _recaptcha.isRecaptcha)(provider)) {
      var _handleChange = function _handleChange(value) {
        (0, _index2.swap)(_index2.updateEntity, 'lock', lockId, captchaField.set, value);
      };
      var reset = function reset() {
        _handleChange();
      };
      return _react2.default.createElement(_recaptcha.ReCAPTCHA, {
        provider: provider,
        sitekey: captcha.get('siteKey'),
        onChange: _handleChange,
        onExpired: reset,
        hl: l.ui.language(lock),
        isValid: isValid,
        value: value
      });
    }
    function handleChange(e) {
      (0, _index2.swap)(_index2.updateEntity, 'lock', lockId, captchaField.set, e.target.value);
    }
    var placeholder = captcha.get('type') === 'code' ? i18n.str('captchaCodeInputPlaceholder') : i18n.str('captchaMathInputPlaceholder');

    // TODO: blankErrorHint is deprecated.
    // It is kept for backwards compatibiliy in the code for the customers overwriting
    // it with languageDictionary. It can be removed in the next major release.
    return _react2.default.createElement(_captcha_input2.default, {
      lockId: lockId,
      image: captcha.get('image'),
      placeholder: placeholder,
      isValid: isValid,
      onChange: handleChange,
      onReload: onReload,
      value: value,
      invalidHint: i18n.str('blankErrorHint') || i18n.str('blankCaptchaErrorHint')
    });
  };
  return CaptchaPane;
}(_react2.default.Component);
exports.default = CaptchaPane;
CaptchaPane.propTypes = {
  i18n: _propTypes2.default.object.isRequired,
  lock: _propTypes2.default.object.isRequired,
  error: _propTypes2.default.bool,
  onReload: _propTypes2.default.func.isRequired
};
CaptchaPane.defaultProps = {
  error: false
};