import { Organization } from 'src/app/core/openapi';
import { SignupFields } from './sign-up-interfaces';
import { environment } from 'src/environments/environment';

export enum AuthenticationTypes {
  LOGIN = 'login',
  SIGNUP = 'signup',
}

export const LOCALHOST = 'localhost';

export const domainUrl = '/authCallback';

// The authentication is made on auth0 window, use this method to make changes on auth0 widget

const auth0WidgetCSS = (signupFields: SignupFields[] = []) => {
  const fieldsToShow = signupFields.filter((f) => !f.hidden);
  const fieldsToHide = signupFields.filter((f) => f.hidden);

  let css = '.auth0-lock.auth0-lock .auth0-lock-header-logo { width: 80%!important }';
  css += ' .auth0-lock.auth0-lock .auth0-lock-widget { width: 400px !important }';
  css += ' .auth0-lock.auth0-lock .auth0-lock-header-logo { height: 100% !important }';
  css += ' .auth0-lock.auth0-lock .auth0-lock-header { height: auto !important }';
  css += '.auth0-lock.auth0-lock .auth0-lock-header-bg { height: 0px !important }';
  css += ' .auth0-lock-name { color: #3a3372 }';
  css +=
    '.auth0-lock-center { background: url(`https://asset.cloudinary.com/dtk628odh/2b3eacfd392c8fbb2fb7dcbb46651de7`) no-repeat center center fixed!important }';
  css += 'auth0-lock-password-strength { display: absolute!important }';
  css += '.auth0-lock.auth0-lock { z-index: 0!important }';
  css += ' .auth0-lock.auth0-lock .auth0-lock-header { padding: 30px 11px 11px 11px!important }';

  fieldsToHide.map((f) => {
    css += '.auth0-lock-input-' + f.name + ' ' + '{ display: none!important }';
  });

  if (fieldsToShow.length > 5) {
    css += '.auth0-lock-content { overflow-y: auto !important }';
    css += '.auth0-lock-content { height: 500px !important }';
  }

  return css;
};

export const b2cSignUpFields = (portalOwner): SignupFields[] => {
  return [
    {
      name: 'name',
      placeholder: 'Full Name',
      required: true,
    },
    {
      name: 'email',
      placeholder: 'E-mail',
      required: true,
    },
    {
      name: 'country',
      placeholder: 'Country',
      required: true,
    },
    {
      name: 'postal_code',
      placeholder: 'Postal / Zip Code',
      required: true,
    },
    {
      hidden: true,
      type: 'text',
      name: 'lang',
      value: portalOwner.language || 'en',
      placeholder: 'lang',
      prefill: portalOwner.language || 'en',
    },
    {
      name: 'verify_type',
      value: 'b2c',
      hidden: true,
      placeholder: 'verify_type',
      type: 'text',
      prefill: 'b2c',
    },
    {
      name: 'org_email',
      value: portalOwner.email,
      hidden: true,
      placeholder: 'org_email',
      type: 'text',
      prefill: portalOwner.email,
    },
    {
      name: 'subdomain',
      value: portalOwner.subdomain,
      hidden: true,
      placeholder: 'subdomain',
      type: 'text',
      prefill: portalOwner.subdomain,
    },
  ];
};

export const organizationSignUpFields = (): SignupFields[] => {
  return [
    {
      name: 'contact_person_name',
      placeholder: 'Contact Persons Name',
      required: true,
    },
    {
      name: 'email',
      placeholder: 'E-mail',
      required: true,
    },
    {
      name: 'verify_type',
      value: 'org',
      hidden: true,
      placeholder: 'verify_type',
      type: 'text',
      prefill: 'org',
    },
  ];
};

export const b2cSignUp = (portalOwner: Organization, email: string) => {
  const options = {
    authorizationParams: {
      screen_hint: AuthenticationTypes.SIGNUP,
      portalOwnerName: portalOwner.name,
      portalOwnerEmail: portalOwner.email,
      portalOwnerSubdomain: portalOwner.subdomain,
      portalOwnerLanguage: portalOwner.language,
      verify_type: 'b2c',
      additionalSignUpFields: JSON.stringify(b2cSignUpFields(portalOwner)),
      css: auth0WidgetCSS(b2cSignUpFields(portalOwner)),
      redirectUrl: window.location.protocol + '//' + environment.ROOT_DOMAIN_URL + domainUrl + '?verify_type=b2c',
      prefillEmail: email,
      logo: portalOwner.logo.includes('Image_Placeholder') ? null : portalOwner.logo,
      title: 'Sign Up',
    },
    appState: { target: '/' + domainUrl + '?verify_type=b2c&type=signup' },
  };

  return options;
};

export const organizationSignUp = () => {
  const options = {
    authorizationParams: {
      verify_type: 'org',
      screen_hint: AuthenticationTypes.SIGNUP,
      additionalSignUpFields: JSON.stringify(organizationSignUpFields()),
      css: auth0WidgetCSS(organizationSignUpFields()),
      redirectUrl: window.location.protocol + '//' + environment.ROOT_DOMAIN_URL + domainUrl + '?verify_type=org',
      title: 'Provider Sign Up',
    },
    appState: { target: '/' + domainUrl + '?verify_type=org&type=signup' },
  };

  return options;
};

export const loginOptions = () => {
  const options = {
    authorizationParams: {
      screen_hint: AuthenticationTypes.LOGIN,
      css: auth0WidgetCSS(),
      redirectUrl: window.location.protocol + '//' + environment.ROOT_DOMAIN_URL + domainUrl + '?state=login',
    },
    appState: { target: '/' + domainUrl + '?type=login' },
  };

  return options;
};
