import { AfterViewInit, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { clamp } from 'src/app/shared/helpers/clamp.function';
import { styleElement } from 'src/app/shared/helpers/styleElement.function';

@Component({
  selector: 'home-neuralign-def',
  templateUrl: './neuralign-def.component.html',
  styleUrls: ['./neuralign-def.component.scss'],
})
export class NeuralignDefComponent implements AfterViewInit {
  @ViewChild('box') box: ElementRef;
  @ViewChild('neuralign') neuralign: ElementRef;
  @ViewChild('list') list: ElementRef;

  constructor(private _renderer: Renderer2) {}

  ngAfterViewInit() {
    this.setStyles();
  }

  private setStyles() {
    styleElement(this._renderer, this.box, {
      position: 'absolute',
      top: clamp(1, 10),
      left: clamp(2, 16),
    });

    styleElement(this._renderer, this.neuralign, {
      marginBottom: clamp(0.25, 3),
    });

    styleElement(this._renderer, this.list, {
      display: 'flex',
      flexDirection: 'column',
      gap: clamp(0.25, 3),
      listStyleType: 'disc',
    });
  }
}
