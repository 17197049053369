import { Component, OnInit } from '@angular/core';
import { RestAPIService } from '../../services/rest/rest-api.service';
import { FAQ } from './interfaces/faq.interface';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { faSearch, IconDefinition } from '@fortawesome/free-solid-svg-icons';

const FAQ = [
  {
    question: `Who are we?`,
    answer: `“Learning Solutions Works” (ls.works) is a not-for-profit organization with a passion to bring high quality cognitive training programs to everyone.`,
  },
  {
    question: `Does Learning Solutions Works have a mission statement?`,
    answer: `Our mission statement is: The purpose of “Learning Solutions Works” is to provide high tech, research based learning interventions for individuals with learning disabilities or difficulties in a way that is broadly accessible both logistically and financially.`,
  },
  {
    question: `Who can benefit from Neuralign`,
    answer: `Neuralign has been designed for students 5 years up to adulthood who suffer from:
    • Eye movement control difficulty,
    • Auditory processing difficulty,
    • Good reading skills but cannot recall what was read,
    • Poor working memory,
    • Dyslexia,
    • Weak reading skills,
    • Low self- esteem when reading`,
  },
  {
    question: `How quickly can we expect results?`,
    answer: `It depends on the individual’s profile, also if anything else is being completed along with Neuralign to accomplish reading goals. The brain needs a certain amount of repeated stimulation in order to develop new connections, also to reorganize itself and stabilize improvement. Some people see change within a few days, others after completion of the forty-hour program. The brain is too complicated to know in advance the exact amount of time it will take.`,
  },
  {
    question: `Can a successful outcome be predicted?`,
    answer: `No one can predict with certainty that a multi-sensory training program will be successful in achieving specific goals. However, very good results have been achieved by many students especially when reading is continued diligently and used constantly throughout the program. Often, results are even seen in areas that were not initially identified for change, such as spelling and handwriting.`,
  },
  {
    question: `Will the results last?`,
    answer: `Neuralign is designed to educate and re-educate the brain to better process information targeting reading. The most measurable effects usually occur shortly after completion of the program. However, depending on the severity of the students reading difficulty, completing the program a second time can be very beneficial to see continued reading growth.`,
  },
  {
    question: `What is the protocol to complete the Neuralign program, and are there any specific recommendations to get the best results?`,
    answer: `Neuralign is a 40 hour program completed within 4 months (can be completed quicker). There are 3 categories:
    • Cognitive Training is 15 x 1 hour session to be completed within 5 weeks.(3 X a week for 5 weeks, or 4 X a week for 3.5 weeks, or 5 X a week for 3 weeks or 15 days in a row.)
    • Speed Reading is completed 1 minute X 5 days a week for 10 weeks, which will become available after session 9 of the Cognitive Training session has ended.
    • Additional Reading Exercises and games is approximately (depending on with level) 20 - 30 minutes X 5 days a week for 10 weeks.
    • For best results, there should never be more than a 3- day break between sessions.`,
  },
  {
    question: `Can I redo the Neuralign program once I have finished the full program?`,
    answer: `Yes, but you first need to take a 4-week break. The Neuralign Program consists in stimulating the visual, auditory, and vestibular systems. This can be taxing for the brain. After a long period of stimulation, it needs time to integrate all the new changes.`,
  },
  {
    question: `Are there any side effects?`,
    answer: `Neuralign is a safe program. It stimulates the brain in a natural manner with modified acoustics and eye movement exercises. Some students have needed to rest their eyes by taking small breaks while completing the Cognitive Therapy program. Neuralign provides brain stimulation through a multi-sensory workout, so it is important to rest the brain as much as possible throughout the 15 sessions.`,
  },
  {
    question: `Is the Neuralign Program complementary with other approaches and treatments?`,
    answer: `It is perfectly fine to use Neuralign with traditional treatments and interventions. We also strongly recommend integrating Forbrain® with the additional Reading Exercises. This will help strengthen the process, and will also reinforce the reading task for the sessions. (<a href="http://www.forbrain.com/?utm_id=2A0801121" target="_blank">Forbrain can be ordered through this link to receive a 10% discount</a>).`,
  },
  {
    question: `Are there any precautions or limitations when using Neuralign?`,
    answer: `Neuralign is designed for anyone. It is specifically adapted to help people with:
    • Reading delays
    • Dyslexia
    • Autism spectrum and developmental issues
    • ADD and ADHD
    We don’t recommend the use of Neuralign for people who suffer with Brain seizure.

    If you have any doubts, we recommend that you begin your Neuralign program under the guidance of a therapist. If for any reason, you don’t feel good during or following a session, we ask that you stop the program until you feel well.`,
  },
  {
    question: `What are the benefits of using the Neuralign program?`,
    answer: `Neuralign is easy to use, safe and efficient. It is a home or centre-based multi-sensory training program which combines sound with visual to stimulate the vestibular and auditory and visual systems. Considering its price and protocol of use, Neuralign is much more user-friendly than any other cognitive training reading programs and integrates some of the most advanced technology.`,
  },
  {
    question: `Can I cancel or change my plan anytime?`,
    answer: `Organization can purchase student bundles there are no subscriptions. No contract. No obligation. You can stop using the product anytime.`,
  },
  {
    question: `Will unused student purchases rollover?`,
    answer: `Absolutely. Any unused student purchase never expires and can be used anytime.`,
  },
  {
    question: `Which is the best Browser for Neuralign?`,
    answer: `The recommended browser is Firefox.`,
  },
  {
    question: `Can we test other browsers to see if Neuralign will work on them?`,
    answer: `Yes test your computer browser to see if it supports WebGL at <a href="http://get.webgl.org/" target="_blank">get.webgl.org</a>, if the square is turning then Neuralign will work on that browser.`,
  },
  {
    question: `What type of computer do I need?`,
    answer: `Recommendation for Mac:
    Any mac0S.
    Recommended specs for a PC desktop:
    i3 and above processor. 6GB or more RAM. Graphics card with 2GB internal memory.

    Must use Firefox browser.

    23” to 31” monitor and TV screens can be used.
    Laptops with a larger screen can be used or if your laptop is powerful enough then it can be hooked up to a larger screen, but for best results a desktop computer is preferred.`,
  },
  {
    question: `What is the pricing model?`,
    answer: `It's really simple. For organizations we offer 3 different plans to purchases student tokens, so depending on what your needs are will depend on the cost. The Home version will usually always have a discounted rate available. Browse our Pricing Page to learn more about your options. Our ultimate goal is to make the program accessible to all. For individual clients who would like to complete the program at home click on the “Home” pricing button.`,
  },
  {
    question: `Do you offer non-profit rates?`,
    answer: `Special pricing for non- profit organizations and special causes will certainly be made available. Please contact our support team so they can get more details and get your custom plan started.`,
  },
  {
    question: `Do you save credit card details?`,
    answer: `No. Absolutely not. Your credit card details are not stored on our servers. The information goes directly from your computer to our payment service provider.`,
  },
  {
    question: `Is this a secure site for purchases?`,
    answer: `Absolutely! We work with Stripe.com which guarantees your safety and security. All site communication and data transfer are secured and encrypted.`,
  },
  {
    question: `How does Neuralign work for organizations?`,
    answer: `Organization users will have full access to the Neuralign cloud platform and all of its cognitive and reading therapy modules. Users can access the training modules as often as needed. Organizational users will be able to access new updated sessions as they are getting added. New content is added to our library regularly, with a goal of meeting the needs of each and every student.`,
  },
  {
    question: `Can I promote Neuralign on Social Media?`,
    answer: `Yes, you can advertise Neuralign on your social media business pages, we encourage you to do so.`,
  },
  {
    question: `How Can I reach your Customer Support?`,
    answer: `To reach our customer support team please email <a href="mailto:support@lsworks.org">support@lsworks.org</a>`,
  },
];

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FAQComponent implements OnInit {
  public FAQs: FAQ[] = [];
  public searchIcon: IconDefinition = faSearch;

  private _allFAQs: FAQ[] = [];
  private _searchSubject: Subject<string> = new Subject();

  constructor(private _restService: RestAPIService) {}

  ngOnInit() {
    this._setSearchSubscription();

    setTimeout(() => {
      this._allFAQs = FAQ;
      this.FAQs = FAQ;
    }, 250);
  }

  public updateSearch(searchTextValue: string) {
    this._searchSubject.next(searchTextValue);
  }

  private _setSearchSubscription() {
    this._searchSubject.pipe(debounceTime(400)).subscribe((searchValue: string) => {
      this.FAQs = this._filterQuestions(searchValue, this._allFAQs);
    });
  }

  private _filterQuestions(searchValue: string, faqs: FAQ[]): FAQ[] {
    return faqs.filter((faq) => {
      return this._containsSubstring(faq.question, searchValue) || this._containsSubstring(faq.answer, searchValue);
    });
  }

  private _containsSubstring(main: string, comparison: string): boolean {
    return main.toLowerCase().includes(comparison.trim().toLowerCase());
  }
}
