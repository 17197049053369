import { Component } from '@angular/core';
import { PortalTypeService } from 'src/app/services/portal/portal-type.service';

@Component({
  selector: 'home-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss'],
})
export class HeroComponent {
  public isOrgsPortal = this._portalType.chekIfIsOrgPortal;

  constructor(private _portalType: PortalTypeService) {}
}
