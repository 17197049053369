import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'single-card-holder',
  templateUrl: './single-card-holder.component.html',
  styleUrls: ['./single-card-holder.component.scss'],
})
export class SingleCardHolderComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
