import { clamp } from '../helpers/clamp.function';

export const Styles = {
  Colours: {
    PRIMARY_COLOUR: '#3a3372',
    SECONDARY_COLOUR: '#f3c062',
    Neuralign: {
      GOLDEN: '#fdbd4e',
      ROSE: '#ee456c',
      MAUVE: '#7869aa',
      WINDSOR: '#2a2766',
    },
    WHITE: '#FFF',
    BLACK: '#000',
    Gradients: {
      PRIMARY_GRADIENT: 'linear-gradient(to right, #fdbd4e, #ee456c 40%, #7869aa 56%, #2a2766)',
    },
  },

  Fonts: {
    PRIMARY_FONT: 'Montserrat, sans-serif',
  },

  Constants: {
    MIN_VIEWPORT_WIDTH: 320,
    MAX_VIEWPORT_WIDTH: 2560,
  },

  Buttons: {
    DEFAULT_BORDER_RADIUS: clamp(0.1, 0.5),
    DEFAULT_PADDING: `${clamp(0.25, 1)} ${clamp(0.5, 3)}`,
    DEFAULT_VERTICAL_PADDING: clamp(0.25, 1),
    DEFAULT_HORIZONTAL_PADDING: clamp(0.5, 3),
    DEFAULT_FONT_SIZE: clamp(0.7, 3),
    DEFAULT_BORDER_WIDTH: clamp(0.1, 0.2),
    DEFAULT_BORDER: '1px solid #3a3372',
  },

  Transitions: {
    FADE: 'background 0.3s ease',
  },
};
