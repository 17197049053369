import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { faYoutube, IconDefinition } from '@fortawesome/free-brands-svg-icons';

import { NotificationClass } from '../../../shared/classes/notification.class';
import { UpdatesHelperService } from 'src/app/services/utils/updates-helper.service';
import { UpdatesControllerService } from 'src/app/core/openapi';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-manage-update',
  templateUrl: './manage-update.component.html',
  styleUrls: ['./manage-update.component.scss'],
})
export class ManageUpdateComponent extends NotificationClass implements OnInit {
  formGroup: UntypedFormGroup;
  isUpdate = false;
  title = 'New Update';
  typeList = ['New Feature', 'Improvement', 'Bugfix'];
  youtubeIcon: IconDefinition = faYoutube;

  constructor(
    private _snackBar: MatSnackBar,
    private formBuilder: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _updatesHelper: UpdatesHelperService,
    private _updateService: UpdatesControllerService,
  ) {
    super(_snackBar);
  }

  async ngOnInit() {
    this.createForm();
    await this.loadData();
  }

  private createForm() {
    this.formGroup = this.formBuilder.group({
      type: new UntypedFormControl('', [Validators.required]),
      title: new UntypedFormControl('', [Validators.required]),
      description: new UntypedFormControl(''),
    });
  }

  async loadData() {
    const id = this.getRouterId();
    console.log('id', id);
    if (id !== '0') {
      try {
        const update = await firstValueFrom(this._updateService.updatesControllerGetUpdateById(id));

        this.isUpdate = true;
        this.title = update.title;

        this.formGroup.patchValue(update);
      } catch (err: unknown) {
        throw new Error('Error while fetching update data: ' + (err as Error).message);
      }
    }
  }

  getRouterId() {
    const { id } = this.activatedRoute.snapshot.params;
    return id;
  }

  addYoutube() {
    const videoUrl = this.getVideoUrl();
    if (videoUrl && videoUrl !== '') {
      const description = this.formGroup.get('description');
      description.setValue(`${description.value || ''}\n\n[youtube]${videoUrl}[/youtube]`);
    }
  }

  getVideoUrl() {
    return prompt('Paste the Youtube Url below:', 'eg.: https://www.youtube.com/watch?v=gBrmnB5aOSI');
  }

  async saveForm(update) {
    const { id } = this.activatedRoute.snapshot.params;

    if (!this.isUpdate) {
      await firstValueFrom(
        this._updateService.updatesControllerCreate({
          update: update,
        }),
      );

      this.notify('Update created successfully!');
    } else {
      await firstValueFrom(
        this._updateService.updatesControllerUpdate(id, {
          update: update,
        }),
      );

      this.notify('Update saved successfully!');
    }

    this._updatesHelper.getUnreadUpdatesCount();

    await this.router.navigate(['/admin/updates']);
  }
}
