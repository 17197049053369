<div
  class="mainContainer"
  *ngIf="data"
>
  <div class="header">
    {{ data.category.name }}
  </div>

  <div
    class="containerWrapper list"
    *ngIf="!loading"
  >
    <div
      class="lessonList col-12 px-0 mx-auto position-relative"
      *ngFor="let content of categoryContent; let l = index"
    >
      <mat-accordion>
        <mat-expansion-panel
          [disabled]="!content.isUnlocked"
          class="my-1 expansionPanel"
          [expanded]="selectedContent === content"
          id="{{ 'content' + l }}"
        >
          <mat-expansion-panel-header (click)="selectedContent = content">
            <div class="contentTitle">
              <span class="ml-2">
                {{ content.title }}
                <fa-icon
                  *ngIf="content.completed"
                  [icon]="check"
                >
                </fa-icon>
              </span>

              <span
                matTooltipPosition="above"
                matTooltip="Complete the previous exercises to unlock"
                class="lockIcon"
                *ngIf="!content.isUnlocked"
              >
                <fa-icon [icon]="lock"> </fa-icon>
              </span>
            </div>
          </mat-expansion-panel-header>

          <div class="sections">
            <div class="list">
              <div
                *ngFor="let section of content.contentList; let i = index"
                class="d-flex my-2 sectionInfo"
              >
                <div class="sectionTitle">
                  {{ section.title }}

                  <fa-icon
                    *ngIf="section.completed"
                    [icon]="check"
                  >
                  </fa-icon>
                </div>

                <app-simple-button
                  class="mx-1"
                  [text]="'Start'"
                  (click)="playVideo(section, content)"
                >
                </app-simple-button>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>

  <div
    *ngIf="loading; &quot;m-auto&quot;"
    class="loadingContainer"
  >
    <fa-icon
      [icon]="loadingIcon"
      [spin]="true"
    >
    </fa-icon>
  </div>

  <div class="buttons mt-auto">
    <app-simple-button
      class="mx-1"
      [text]="'Close'"
      (click)="close()"
    >
    </app-simple-button>
  </div>
</div>
