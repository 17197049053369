import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RestAPIService } from '../../services/rest/rest-api.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FAQComponent } from './faq.component';
import { MaterialComponentsModule } from '../../shared/modules/material-components.module';
import { LayoutsModule } from 'src/app/shared/layouts/layouts.module';
import { CurtainModule } from 'src/app/shared/components/curtain/curtain.module';

@NgModule({
  declarations: [FAQComponent],
  imports: [BrowserModule, RouterModule, FontAwesomeModule, MaterialComponentsModule, LayoutsModule, CurtainModule],
  providers: [RestAPIService],
})
export class FAQModule {}
