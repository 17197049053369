import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimaryBgDirective } from './bg/primary-bg.directive';
import { PrimaryBtnDirective } from './buttons/primary-btn.directive';
import { SmallTextDirective } from './typeography/small-text.directive';
import { WhiteTextDirective } from './typeography/white-text.directive';
import { CenterTextDirective } from './typeography/center-text.directive';
import { MontserratDirective } from './typeography/montserrat.directive';
import { OutlineBtnDirective } from './buttons/outline-btn.directive';
import { ParagraphDirective } from './typeography/paragraph.directive';
import { ThinDirective } from './typeography/thin.directive';
import { PrimaryColourDirective } from './colours/primary-colour.directive';
import { ExtraLargeDirective } from './typeography/sizes/extra-large.directive';
import { MediumSizeDirective } from './typeography/sizes/medium-size.directive';
import { LargeSizeDirective } from './typeography/sizes/large-size.directive';
import { SmallSizeDirective } from './typeography/sizes/small-size.directive';
import { ZeroMarginDirective } from './spacing/zero-margin.directive';
import { LargePaddingDirective } from './spacing/large-padding.directive';
import { SmallPaddingDirective } from './spacing/small-padding.directive';
import { RoseColourDirective } from './colours/rose-colour.directive';
import { RoseBgDirective } from './bg/rose-bg.directive';

@NgModule({
  declarations: [
    PrimaryBgDirective,
    PrimaryBtnDirective,
    SmallPaddingDirective,
    SmallTextDirective,
    WhiteTextDirective,
    CenterTextDirective,
    MontserratDirective,
    OutlineBtnDirective,
    ParagraphDirective,
    ThinDirective,
    PrimaryColourDirective,
    ExtraLargeDirective,
    MediumSizeDirective,
    LargeSizeDirective,
    SmallSizeDirective,
    ZeroMarginDirective,
    LargePaddingDirective,
    RoseColourDirective,
    RoseBgDirective,
  ],
  imports: [CommonModule],
  exports: [
    PrimaryBgDirective,
    PrimaryBtnDirective,
    SmallPaddingDirective,
    SmallTextDirective,
    WhiteTextDirective,
    CenterTextDirective,
    MontserratDirective,
    OutlineBtnDirective,
    ParagraphDirective,
    ThinDirective,
    PrimaryColourDirective,
    ExtraLargeDirective,
    MediumSizeDirective,
    LargeSizeDirective,
    SmallSizeDirective,
    ZeroMarginDirective,
    LargePaddingDirective,
    RoseColourDirective,
    RoseBgDirective,
  ],
})
export class DirectivesModule {}
