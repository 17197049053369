<app-main-layout
  [breadcrumbs]="[
    { name: 'Home', url: '/' },
    { name: 'Course configs', url: '/course-configurations' }
  ]"
>
  <div
    *ngIf="loading"
    class="loading"
  >
    <mat-spinner></mat-spinner>
  </div>

  <div class="mainContainer border">
    <div class="header col-8 mx-auto px-0 d-flex">
      <div
        class="py-2 col-2 text-center"
        (click)="selectedOption = 'courses'"
        [ngClass]="{ selectedOption: selectedOption === 'courses' }"
      >
        <fa-icon [icon]="learningManagementSystemIcon"></fa-icon> <br />
        <span>Courses</span>
      </div>
    </div>

    <div
      class="col-8 mx-auto px-0 mt-5"
      *ngIf="selectedOption === 'courses'"
    >
      <app-learning-management-system
        [courseList]="courseList"
        (reset)="reset()"
      >
      </app-learning-management-system>
    </div>
  </div>
</app-main-layout>
