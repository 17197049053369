<section #box>
  <h2
    #neuralign
    montserrat
    thin
    white
    largeSize
  >
    Neur.a.lign &nbsp; /ˈnʊərəˈlaɪn/
  </h2>
  <ul
    #list
    montserrat
    mediumSize
    white
  >
    <li>
      A game-changing evidence-based gamified <br />
      cognitive development program.
    </li>
    <li>
      Created by a neurodiverse team to empower the <br />
      neurodiverse community.
    </li>
    <li>
      Based on the most advanced principles of <br />
      neuroscience and neural plasticity, with years of <br />
      research and fieldwork.
    </li>
  </ul>
</section>

<div>
  <img
    src="assets/img/slides/children-blue-wall-nodes.webp"
    alt=""
    width="100%"
  />
</div>
