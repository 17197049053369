<app-main-layout
  [breadcrumbs]="[
    { name: 'Home', url: '/' },
    { name: 'Students', url: '/students' }
  ]"
>
  <div
    id="studentContainer"
    class="inner-content"
    [ngClass]="{ empty: !students || students.data.length === 0 }"
  >
    <ng-container *ngIf="students && students.data.length > 0">
      <div class="top-row">
        <h2>My Students</h2>
        <div class="search-input">
          <input
            (keyup)="applyFilter($event.target.value)"
            placeholder="Search"
          />
          <fa-icon
            class="icon"
            [icon]="searchIcon"
          ></fa-icon>
        </div>
      </div>

      <mat-table
        [dataSource]="students"
        class="p-3"
      >
        <ng-container matColumnDef="name">
          <mat-header-cell
            *matHeaderCellDef
            mat-header-cell
            [ngClass]="'name-col'"
          >
            Name
          </mat-header-cell>
          <mat-cell
            [ngClass]="'name-col'"
            *matCellDef="let row"
          >
            {{ row.givenName }} {{ row.familyName }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="nickname">
          <mat-header-cell
            mat-header-cell
            [ngClass]="'nickname-col'"
            *matHeaderCellDef
          >
            Nickname
          </mat-header-cell>
          <mat-cell
            mat-cell
            [ngClass]="'nickname-col'"
            *matCellDef="let row"
          >
            {{ row.nickname || '--' }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="age">
          <mat-header-cell
            mat-header-cell
            [ngClass]="'age-col'"
            *matHeaderCellDef
          >
            Age
          </mat-header-cell>
          <mat-cell
            mat-cell
            [ngClass]="'age-col'"
            *matCellDef="let row"
          >
            {{ getAgeFromBirthday(row.birthdate) }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="tag">
          <mat-header-cell
            mat-header-cell
            [ngClass]="'tag-col'"
            *matHeaderCellDef
            class="text-center"
          >
            Program
          </mat-header-cell>
          <mat-cell
            mat-cell
            [ngClass]="'tag-col'"
            *matCellDef="let row"
          >
            <div
              class="ReadLSTag"
              *ngIf="row.programs && row.programs.length > 0"
            >
              Neuralign
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell
            mat-header-cell
            [ngClass]="'action-col'"
            *matHeaderCellDef
            class="actionHeader"
          >
            Actions
          </mat-header-cell>
          <mat-cell
            mat-cell
            [ngClass]="'action-col'"
            *matCellDef="let row"
          >
            <a
              (click)="goToStudentProfile(row)"
              *ngIf="!isOrgClient()"
              matTooltip="Edit student"
              matTooltipPosition="above"
            >
              <fa-icon [icon]="editIcon"></fa-icon>
            </a>
            <a
              class="mx-1"
              matTooltip="Student's calendar"
              matTooltipPosition="above"
            >
              <fa-icon
                [ngClass]="{ disabledIcon: !row.agenda }"
                [icon]="calendarIcon"
                (click)="openAgenda(row)"
              ></fa-icon>
            </a>
            <a
              *ngIf="
                row.programs && row.programs.length > 0;
                else disabledProgram
              "
              [routerLink]="'/program/' + row.id + '/Neuralign'"
              matTooltip="Program Page"
              matTooltipPosition="above"
            >
              <fa-icon [icon]="gamepadIcon"></fa-icon>
            </a>

            <ng-template #disabledProgram>
              <a
                matTooltip="Access Neuralign"
                matTooltipPosition="above"
              >
                <fa-icon
                  [icon]="gamepadIcon"
                  class="disabledIcon"
                ></fa-icon>
              </a>
            </ng-template>

            <a
              *ngIf="
                (!row.programs || row.programs.length === 0) && !isOrgClient()
              "
              (click)="associateStudent(row)"
              matTooltip="Add program"
              matTooltipPosition="above"
            >
              <fa-icon [icon]="plusIcon"></fa-icon>
            </a>
            <a
              *ngIf="
                !isOrgClient() && canDisassociateStudent(row);
                else disabledRemoveProgram
              "
              (click)="disassociateStudent(row)"
              matTooltip="Remove program"
              matTooltipPosition="above"
            >
              <fa-icon [icon]="minusIcon"></fa-icon>
            </a>

            <ng-template #disabledRemoveProgram>
              <a
                *ngIf="
                  row.programs && row.programs.length > 0 && !isOrgClient()
                "
                matTooltip="Remove Program"
                matTooltipPosition="above"
              >
                <fa-icon
                  [icon]="minusIcon"
                  class="disabledIcon"
                ></fa-icon>
              </a>
            </ng-template>
            <a
              (click)="presentArchiveStudent(row)"
              *ngIf="!isOrgClient()"
              matTooltip="Delete student"
              matTooltipPosition="above"
            >
              <fa-icon [icon]="trash"></fa-icon>
            </a>
          </mat-cell>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
        ></tr>
      </mat-table>
    </ng-container>

    <app-empty-state
      *ngIf="!students"
      text="Loading students..."
      [loading]="true"
    ></app-empty-state>
    <app-empty-state
      *ngIf="students && students.data.length === 0"
      text="No students registered yet"
      [icon]="usersIcon"
    ></app-empty-state>
  </div>
</app-main-layout>
