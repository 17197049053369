import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';

export interface TokenAmountModalData {
  amount: number;
  organizationName: string;
  warningMessage: string;
}

@Component({
  selector: 'app-token-amount-data',
  templateUrl: `./add-token-amount-dialog.html`,
  styleUrls: ['./add-token-amount.scss'],
})
export class TokenAmountModalComponent implements OnInit {
  public warningMessage: string;

  constructor(
    public dialogRef: MatDialogRef<TokenAmountModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TokenAmountModalData,
  ) {}

  ngOnInit(): void {
    this.warningMessage = this.data.warningMessage;
  }

  onCancel(): void {
    this.dialogRef.close();
  }
  onConfirm(): void {
    this.dialogRef.close(this.data);
  }
}
