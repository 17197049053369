import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-token',
  templateUrl: './add-token.component.html',
  styleUrls: ['./add-token.component.scss'],
})
export class AddTokenComponent implements OnInit {
  private user;
  public programs;
  public programId;
  public errorMessage;
  public allowCompleteAtHome = 'true';
  public quantity = 1;

  constructor(public dialogRef: MatDialogRef<AddTokenComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.user = this.data.user;
    this.programs = this.data.programs;
    this.programId = this.data.programs[0].id;
  }

  public confirm() {
    if (!this.programId) {
      return (this.errorMessage = 'You must choose a program');
    }
    this.dialogRef.close({
      programId: this.programId,
      allowCompleteAtHome: this.allowCompleteAtHome === 'true',
      quantity: this.quantity,
    });
  }

  public cancel() {
    this.dialogRef.close(false);
  }
}
