<app-main-layout
  [breadcrumbs]="[
    { name: 'Home', url: '/' },
    { name: 'Users', url: '/users' },
    { name: userId ? 'Edit User' : 'Add New' }
  ]"
>
  <div class="inner-content">
    <h2>
      {{
        formGroup.value.type === 'Organization'
          ? 'Organization'
          : (userId ? 'Edit' : 'New') + ' User'
      }}
    </h2>
    <form [formGroup]="formGroup">
      <div class="col-12">
        <div class="user-type row">
          <div class="col-6">
            <span *ngIf="!!userId === false">
              <mat-form-field appearance="outline">
                <mat-label>User Type</mat-label>
                <mat-select
                  placeholder="User Type"
                  formControlName="type"
                  (selectionChange)="resetForm($event)"
                >
                  <mat-option value="Client"> Client </mat-option>
                  <mat-option
                    value="Organization"
                    *ngIf="isReseller"
                  >
                    Organization
                  </mat-option>
                  <mat-option
                    value="Organization Admin"
                    *ngIf="isPortalAdmin || isPortalOwner"
                  >
                    Organization Admin
                  </mat-option>
                  <mat-option
                    value="Organization Manager"
                    *ngIf="isOrgAdmin || isPortalAdmin || isPortalOwner"
                  >
                    Organization Manager
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </span>
          </div>
          <div class="col-6">
            <p>
              Organization Admins can purchase tokens, add and edit organization
              managers, and add and edit students.
            </p>
            <p>Organization Managers can add and edit students.</p>
          </div>
        </div>
      </div>
      <br />
      <div class="col-12">
        <div class="form-inputs row">
          <div
            class="user-photo col-6"
            *ngIf="formGroup.value.type !== 'Organization'"
          >
            <img [src]="formGroup.get('image').value" />
            <p
              class="upload"
              (click)="uploadToCloudinary()"
            >
              Upload Photo
            </p>
          </div>
          <div
            class="col-6"
            *ngIf="formGroup.value.type !== 'Organization'"
          >
            <mat-form-field appearance="outline">
              <mat-label>Full Name</mat-label>
              <input
                matInput
                required
                placeholder="Full Name"
                formControlName="fullname"
              />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Nickname</mat-label>
              <input
                matInput
                placeholder="Nickname"
                formControlName="nickname"
              />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>E-mail</mat-label>
              <input
                matInput
                required
                placeholder="E-mail"
                formControlName="email"
              />
            </mat-form-field>
            <p
              class="form-error"
              *ngIf="checkFormDisabled()"
            >
              Please check the form, there are invalid fields or required fields
              missing.
            </p>
            <div class="actions">
              <div
                class="w-100 d-flex justify-content-center mb-4"
                *ngIf="
                  formGroup.value.type === 'Organization Manager' ||
                  formGroup.value.type === 'Manager'
                "
              >
                <div class="text-center">
                  <div class="d-flex align-items-center my-2">
                    <mat-checkbox formControlName="allowFullAccess">
                    </mat-checkbox>
                    <span class="mx-2"> Allow full access to client list </span>
                  </div>

                  <app-simple-button
                    (click)="openClientList()"
                    text="Open allowed client list"
                    [disabled]="formGroup.value.allowFullAccess === true"
                  >
                  </app-simple-button>
                </div>
              </div>

              <app-simple-button
                class="mx-1"
                text="SAVE"
                [disabled]="
                  !formGroup.valid ||
                  saving ||
                  (formGroup.value.allowFullAccess === false &&
                    formGroup.value.allowedClients.length === 0)
                "
                (click)="save()"
              >
              </app-simple-button>
              <app-simple-button
                class="mx-1"
                *ngIf="!!userId"
                text="NEW USER"
                (click)="createRedirectToSame()"
              >
              </app-simple-button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-12"
        *ngIf="formGroup.value.type === 'Organization' && resellerId"
      >
        <app-register-form
          [reseller]="resellerId"
          [outsiderOrgId]="outsiderOrgId"
        ></app-register-form>
      </div>
    </form>
  </div>
  <br />
  <br />
  <br />
</app-main-layout>
