import { Chart } from 'chart.js';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-graph-preview.',
  templateUrl: './graph-preview.component.html',
  styleUrls: ['./graph-preview.component.scss'],
})
export class GraphPreview implements OnInit, AfterViewInit {
  constructor(@Inject(MAT_DIALOG_DATA) public graphType: string) {}

  public charts = [];

  public graphStyle = {
    // Default graph type for Cognitive Therapy
    bar: () => {
      const graph = {
        data: {
          labels: Array.from({ length: 6 }, (e, i: number) => `Session ${i + 1}`),
          datasets: [
            {
              label: 'My Test Chart',
              data: Array.from({ length: 6 }, () => Math.floor(Math.random() * 100) + 1),
              backgroundColor: '#fdbd4e',
              borderWidth: 1,
            },
          ],
        },
        type: 'bar',
        width: 890,
        height: 700,
      };

      this.charts.push(graph);
    },

    // Default graph type for Speed Reading
    doughnut: () => {
      const graph = {
        type: 'doughnut',
        data: {
          labels: ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5'],
          datasets: [
            {
              label: 'My test chart',
              data: Array.from({ length: 5 }, () => Math.floor(Math.random() * 200) + 1),
              backgroundColor: ['#009ee5', '#ff4778', '#00c3c1', '#ffcd67', '#ff9947'],
              hoverOffset: 4,
            },
          ],
        },
        width: 130,
        height: 270,
      };

      this.charts.push(graph);
    },

    // Default graph type for Reading Exercises
    pie: () => {
      const graph = {
        type: 'pie',
        data: {
          labels: ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5'],
          datasets: [
            {
              label: 'My test chart',
              data: Array.from({ length: 5 }, () => Math.floor(Math.random() * 200) + 1),
              backgroundColor: ['#009ee5', '#ff4778', '#00c3c1', '#ffcd67', '#ff9947'],
              hoverOffset: 4,
            },
          ],
        },
        width: 130,
        height: 270,
      };

      this.charts.push(graph);
    },
  };

  ngOnInit() {
    const buildGraphsCanvas = this.graphStyle[this.graphType];
    buildGraphsCanvas();
  }

  ngAfterViewInit(): void {
    this.buildResultsGraphs();
  }

  public buildResultsGraphs() {
    for (const chart of this.charts) {
      const index = this.charts.indexOf(chart);
      const wCanvas = document.getElementById('chart' + index) as HTMLCanvasElement;
      const wCtx = wCanvas.getContext('2d');

      new Chart(wCtx, {
        type: chart.type,
        data: chart.data,
      });
    }
  }
}
