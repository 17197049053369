<app-main-layout
  [breadcrumbs]="[
    { name: 'Home', url: '/' },
    {
      name: !isPatron ? 'Users' : 'Students',
      url: !isPatron ? '/users' : '/students'
    },
    {
      name: programName,
      url: '/program/' + '/' + studentId + '/' + 'Neuralign'
    },
    { name: studentName, url: '/' }
  ]"
>
  <div
    class="gamePage h-100 mx-auto"
    *ngIf="showGame"
  >
    <div
      class="loading w-100"
      *ngIf="gameLoading"
    >
      <div class="col-6 px-0">
        <div class="col-12 px-0 loadingText">
          <img
            [ngStyle]="{ width: '160px', padding: '10px' }"
            src="../../../../assets/img/NeurAlign text logo.png"
          />
        </div>
        <div class="col-3 px-0 m-auto">
          <mat-progress-bar
            class="readLsColors"
            mode="indeterminate"
          >
          </mat-progress-bar>
        </div>
      </div>
    </div>

    <div
      class="mx-auto d-flex justify-content-start back"
      *ngIf="showGame && !gameLoading"
    >
      <fa-icon
        [ngClass]="{ disabledIcon: !allowUnityClose }"
        (click)="endSession()"
        class="mr-3"
        [icon]="back"
        matTooltip="Return to main page"
        matTooltipPosition="above"
      >
      </fa-icon>
    </div>

    <app-five-days-menu
      *ngIf="showFiveDaysMenu && !gameLoading"
      [dayStateArray]="dayStateArray"
      [fiveDaysMenu]="fiveDaysMenu"
      [category]="selectedLesson"
      [sessionDay]="getSessionDay(selectedLesson)"
      (startGame)="forceStartGame()"
    >
    </app-five-days-menu>

    <app-game
      *ngIf="showGame && !gameLoading && !showFiveDaysMenu"
      [queryData]="queryData"
      [student]="gameStudentDTO"
      [savedGameProgress]="savedGameProgress"
      [studentProgress]="progress"
      [studentThemeId]="studentTheme"
      [selectedLesson]="selectedLesson"
      [isDebugMode]="isDebugMode"
      (sessionComplete)="sessionComplete($event)"
      (saveProgress)="saveProgress($event)"
      (endUnitySession)="endSession()"
      (handleUnityClose)="handleUnityClose()"
    >
    </app-game>
  </div>

  <div
    class="video-bg-container"
    *ngIf="!showGame && showVideo"
  >
    <div class="video-container">
      <div class="back-button">
        <fa-icon
          (click)="endSession()"
          class="mr-3 basicIcon"
          [icon]="back"
          matTooltip="Return to main page"
          matTooltipPosition="above"
        >
        </fa-icon>
      </div>
      <div class="video-wrapper">
        <iframe
          id="gameIframe"
          *ngIf="videoSection.url"
          [src]="videoSection.url | safeUrl"
          allow="accelerometer; encrypted-media; gyroscope; picture-in-picture; autoplay"
          allowfullscreen
        >
        </iframe>
      </div>
      <div class="d-flex justify-content-center">
        <app-simple-button
          class="mx-1"
          [text]="videoSection.isLast ? 'Finish lesson' : 'Next exercise'"
          (click)="nextVideoExercise()"
        >
        </app-simple-button>
      </div>
    </div>
  </div>

  <div
    class="programPage h-100"
    *ngIf="!showGame && !showVideo"
  >
    <div
      class="d-flex justify-content-center align-items-center col-12 px-0 spinner m-auto"
      *ngIf="loadingInfo"
    >
      <div>
        <mat-spinner
          color="accent"
          class="m-auto"
        >
        </mat-spinner>
        <br />
        Loading the student information
      </div>
    </div>

    <div
      class="mainGamePage col-11 p-2 mx-auto"
      *ngIf="!loadingInfo && !showGame"
    >
      <div class="header text-center mt-3 mb-2">
        <img
          class="program-img"
          [src]="programInfo.image"
          (click)="byPass()"
        />
      </div>

      <div class="d-flex">
        <div class="col-8 px-0 categoryContainer">
          <div class="programCategories col-12 px-0 mx-auto">
            <div class="categoryHeader col-12 px-0">
              <div>Neuralign Core Program</div>
            </div>
            <div
              class="card"
              *ngFor="let category of getCoreProgram()"
            >
              <div class="header col-12 px-0 d-flex">
                <div class="p-3 mx-2">
                  <img
                    class="cardImg"
                    [src]="
                      isLessonEnabled(category)
                        ? category.img
                        : category.lockedImg
                    "
                  />
                </div>
                <div class="mx-2 p-4">
                  <span class="name">
                    {{ category.name }}
                  </span>
                </div>

                <fa-icon
                  (click)="openCategoryInfo(category)"
                  matTooltip="Category details"
                  matTooltipPosition="above"
                  [icon]="moreInfo"
                  class="info"
                >
                </fa-icon>
              </div>

              <div
                class="categoryInfo col-12 px-3 pb-3 pt-0"
                *ngIf="progress"
              >
                <div class="status my-1">
                  Status:
                  <span class="description">
                    {{ isLessonEnabled(category) ? 'Avaliable' : 'Locked' }}
                    <span>
                      <fa-icon
                        *ngIf="!isLessonEnabled(category)"
                        [matTooltip]="getConditionToUnlockMessage(category)"
                        matTooltipPosition="right"
                        class="mx-1"
                        [icon]="lock"
                      >
                      </fa-icon>
                    </span>
                  </span>
                </div>
                <div class="session my-1">
                  Current Session:
                  <span class="description">
                    {{ getCurrentSession(category) }}
                  </span>
                </div>
                <div class="lastScore my-1">
                  Last Score:
                  <span
                    class="description"
                    *ngIf="category.hasProgress === false"
                  >
                    This category dont have scores
                  </span>

                  <span
                    class="description"
                    *ngIf="category.hasProgress !== false"
                  >
                    {{ getCategoryLastScore(category) || '---' }}
                  </span>
                </div>
                <div class="lastAccess my-1">
                  Last Acesss:
                  <span
                    class="description"
                    *ngIf="category.hasProgress !== false"
                  >
                    {{ getCategoryLastAccess(category) || 'Never' }}
                  </span>

                  <span
                    class="description"
                    *ngIf="category.hasProgress === false"
                  >
                    {{ '---' }}
                  </span>
                </div>
              </div>

              <div class="actionButtons d-flex justify-content-around mt-auto">
                <button
                  [ngClass]="{
                    disabledButton: shouldUnlockResults(category)
                  }"
                  class="actionButton text-center p-2"
                  (click)="openResultsDialog(category)"
                >
                  Results
                </button>

                <button
                  (click)="startGame(category)"
                  class="actionButton text-center p-2"
                  [disabled]="!isLessonEnabled(category)"
                  [ngClass]="{ disabledButton: !isLessonEnabled(category) }"
                >
                  Play
                </button>
              </div>
            </div>
          </div>

          <div
            class="programCategories col-12 px-0 mx-auto"
            *ngIf="getExtras().length > 0"
          >
            <div class="categoryHeader col-12 px-0">
              <div>Neuralign Extras</div>
            </div>
            <div
              class="card"
              *ngFor="let category of getExtras()"
            >
              <div class="header col-12 px-0 d-flex">
                <div class="p-3 mx-2">
                  <img
                    class="cardImg"
                    [src]="
                      isLessonEnabled(category)
                        ? category.img
                        : category.lockedImg
                    "
                  />
                </div>
                <div class="mx-2 p-4">
                  <span class="name">
                    {{ category.name }}
                  </span>
                </div>

                <fa-icon
                  (click)="openCategoryInfo(category)"
                  matTooltip="Category details"
                  matTooltipPosition="above"
                  [icon]="moreInfo"
                  class="info"
                >
                </fa-icon>
              </div>

              <div
                class="categoryInfo col-12 px-3 pb-3 pt-0"
                *ngIf="progress"
              >
                <div class="status my-1">
                  Status:
                  <span class="description">
                    {{ isLessonEnabled(category) ? 'Avaliable' : 'Locked' }}
                    <span>
                      <fa-icon
                        *ngIf="!isLessonEnabled(category)"
                        [matTooltip]="getConditionToUnlockMessage(category)"
                        matTooltipPosition="right"
                        class="mx-1"
                        [icon]="lock"
                      >
                      </fa-icon>
                    </span>
                  </span>
                </div>
                <div class="session my-1">
                  Current Session:
                  <span class="description">
                    {{ getCurrentSession(category) }}
                  </span>
                </div>
                <div class="lastScore my-1">
                  Last Score:
                  <span
                    class="description"
                    *ngIf="category.hasProgress !== false"
                  >
                    {{ getCategoryLastScore(category) || '---' }}
                  </span>

                  <span
                    class="description"
                    *ngIf="category.hasProgress === false"
                  >
                    This category dont have scores
                  </span>
                </div>
                <div class="lastAccess my-1">
                  Last Acesss:
                  <span
                    class="description"
                    *ngIf="category.hasProgress !== false"
                  >
                    {{ getCategoryLastAccess(category) || 'Never' }}
                  </span>

                  <span
                    class="description"
                    *ngIf="category.hasProgress === false"
                  >
                    {{ '---' }}
                  </span>
                </div>
              </div>

              <div class="actionButtons d-flex justify-content-around mt-auto">
                <button
                  [ngClass]="{
                    disabledButton: shouldUnlockResults(category)
                  }"
                  class="actionButton text-center p-2"
                  (click)="openResultsDialog(category)"
                >
                  Results
                </button>

                <button
                  (click)="startGame(category)"
                  class="actionButton text-center p-2"
                  [disabled]="!isLessonEnabled(category)"
                  [ngClass]="{ disabledButton: !isLessonEnabled(category) }"
                >
                  Play
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="studentContainer col-4 mx-auto px-0">
          <div class="header">
            Student Information

            <fa-icon
              *ngIf="!isPatron && !isSchoolStudent"
              class="mx-2"
              [icon]="edit"
              (click)="editStudent()"
            >
            </fa-icon>
          </div>

          <div
            class="col-12 mx-auto mb-3 px-0 justify-content-center studentInfo text-center"
          >
            <div class="studentData d-flex px-0 align-items-center mx-auto">
              <div class="m-auto data">
                <div class="name my-1">
                  Name: <span class="description"> {{ studentName }} </span>
                </div>

                <div class="nickName my-1">
                  Nickname:
                  <span class="description"> {{ student.nickname }} </span>
                </div>

                <div class="nickName my-1">
                  Age:
                  <span class="description">
                    {{ getAgeFromBirthday(student.birthdate) }}
                  </span>
                </div>

                <div class="nickName my-1">
                  Gender:
                  <span class="description">
                    {{ student.gender || '---' }}
                  </span>
                </div>

                <div class="level my-1">
                  Level:
                  <span class="description"> {{ getStudentLevel() }} </span>
                  <fa-icon
                    [ngClass]="{ disabledIcon: isDebugMode }"
                    *ngIf="!isPatron && !isB2C && !isSchoolStudent"
                    [icon]="edit"
                    (click)="openOverrideDialog()"
                  ></fa-icon>
                </div>

                <div class="theme my-1">
                  Theme:
                  <span class="description">
                    {{
                      themeInfo.label[student.language] ||
                        themeInfo.label['en_ca']
                    }}
                  </span>
                </div>

                <div class="language my-1">
                  Language:
                  <span class="description">
                    {{ getStudentLanguage() }}
                  </span>
                </div>

                <div
                  class="progressRun my-1"
                  *ngIf="rerunOptions.length > 1"
                >
                  Progress run:

                  <select
                    [(ngModel)]="selectedRerun"
                    (ngModelChange)="changeSelectedRun()"
                  >
                    <option
                      *ngFor="let option of rerunOptions"
                      value="{{ option }}"
                    >
                      {{ option + 1 }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="image px-0 text-center mx-2 mb-2">
                <img [src]="student.image" />

                <mat-checkbox
                  [ngClass]="{ disabled: isPatron }"
                  *ngIf="!isB2C && !isDebugMode && !isSchoolStudent"
                  [disabled]="isPatron"
                  (click)="allowRemoteAccess(allowCompleteAtHome)"
                  class="my-1"
                  [(ngModel)]="allowCompleteAtHome"
                >
                  Remote Access
                </mat-checkbox>
              </div>
            </div>
          </div>

          <div class="studentCalendar">
            <div
              class="calendarHeader d-flex mx-0 px-0 justify-content-center align-items-center"
            >
              <fa-icon
                class="calendarIcon mx-2"
                [icon]="arrowLeft"
                mwlCalendarPreviousView
                [view]="view"
                [(viewDate)]="viewDate"
              >
              </fa-icon>
              <div>{{ viewDate | calendarDate: view + 'ViewTitle':'en' }}</div>
              <fa-icon
                class="calendarIcon mx-2"
                [icon]="arrowRight"
                mwlCalendarNextView
                [view]="view"
                [(viewDate)]="viewDate"
              >
              </fa-icon>
            </div>
            <div [ngSwitch]="view">
              <mwl-calendar-month-view
                *ngSwitchCase="'month'"
                [viewDate]="viewDate"
                [events]="events"
                [activeDayIsOpen]="activeDayIsOpen"
              >
              </mwl-calendar-month-view>
            </div>
          </div>

          <div
            class="calendarLabels text-center"
            *ngIf="calendarLabels.length > 0"
          >
            <span
              *ngFor="let label of calendarLabels"
              class="mx-2 col-4 px-0"
            >
              <fa-icon
                class="mx-1"
                [icon]="circle"
                [style]="getLabelColor(label.color)"
              ></fa-icon>
              {{ label.title }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-main-layout>
