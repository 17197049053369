import { Component, OnInit } from '@angular/core';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';

@Component({
  selector: 'app-upload-game-files',
  templateUrl: './upload-game-files.component.html',
  styleUrls: ['./upload-game-files.component.scss'],
})
export class UploadGameFilesComponent implements OnInit {
  public gameFiles: any[] = [];
  public filesToUpload: any[] = [];

  constructor(private rest: RestAPIService, private logger: LoggerService) {}

  ngOnInit() {
    this.loadFiles();
  }

  private loadFiles() {
    this.gameFiles = [];
    this.rest.get('gameFiles', { msg: 'Could not get game files.' }).then((res) => (this.gameFiles = res.gameFiles));
  }

  private notUploadThisOne(one) {
    this.filesToUpload.splice(one, 1);
  }

  private async deleteGameFile(file) {
    await this.rest.delete('gameFile/' + file.id, { msg: 'Could not delete game file.' });
    this.loadFiles();
  }

  public async sendFiles(gameFiles) {
    for (let i = 0; i < gameFiles.length; i += 10) {
      await this.rest
        .post(
          'gameFiles',
          {
            gameFiles: gameFiles.slice(i, i + 10),
          },
          { msg: 'Could not post game files' },
        )
        .then(
          (res) => this.logger.info(res),
          (err) => this.logger.error(err),
        );
      this.logger.info(Math.round((i * 100) / gameFiles.length) + '%');
    }
    this.logger.info('100%');
    this.loadFiles();
  }

  public updateFiles(e) {
    const gameFiles = [];
    let fileNumber = 0;
    for (const file of e.target.files) {
      const reader = new FileReader();
      reader.onload = () => {
        const gameFile = {};
        gameFile[file.name] = JSON.parse(reader.result.toString());
        gameFiles.push(gameFile);
        fileNumber += 1;
        if (fileNumber / e.target.files.length === 1) {
          this.filesToUpload.push(...gameFiles);
        }
      };
      reader.readAsText(file);
    }
  }

  private formatFile(file) {
    return JSON.stringify(file, undefined, 2);
  }

  private formatFileToUpload(file) {
    return Object.keys(file)[0];
  }
}
