<div class="main">
  <section class="container">
    <div class="title">
      <h2>Coupon Registration</h2>
    </div>

    <form class="form">
      <div class="input-group">
        <div class="input-block">
          <label for="couponForm.code">Insert the name</label>
          <input
            name="couponForm.code"
            [(ngModel)]="couponForm.code"
            type="text"
            placeholder="Insert the coupon name"
          />
        </div>
        <button
          class="btn-primary generate"
          (click)="generateCouponCode()"
        >
          Generate
        </button>
      </div>

      <div class="input-group">
        <div class="input-block-select">
          <label for="couponType">Coupon Type</label>
          <select
            [(ngModel)]="couponType"
            name="couponType"
          >
            <option
              value=""
              disabled
              selected
            >
              Select the type
            </option>
            <option value="amount">Discount</option>
            <option value="percentage">Percentage</option>
          </select>
        </div>
        <div
          class="input-block"
          *ngIf="couponType === 'amount'"
        >
          <label for="couponForm.discountAmount">Insert the discount</label>
          <input
            type="number"
            name="couponForm.discountAmount"
            [(ngModel)]="couponForm.discountAmount"
            placeholder="Insert the discount"
          />
        </div>
        <div
          class="input-block"
          *ngIf="couponType === 'percentage'"
        >
          <label for="couponForm.discountPercentage"
            >Insert the Percentage</label
          >
          <input
            type="number"
            [(ngModel)]="couponForm.discountPercentage"
            name="couponForm.discountPercentage"
            placeholder="Insert the percentage"
          />
        </div>
      </div>

      <div class="input-group">
        <div class="input-block">
          <label for="couponForm.limit">Limit</label>
          <input
            type="number"
            name="couponForm.limit"
            [(ngModel)]="couponForm.limit"
            placeholder="Insert the limit"
          />
        </div>
        <div class="input-block">
          <label for="couponForm.expiration">Expiration date</label>
          <input
            type="date"
            name="couponForm.expiration"
            placeholder="Insert the expiration date"
            [(ngModel)]="couponForm.expiration"
          />
        </div>
      </div>
      <div class="search-section">
        <div class="input-block">
          <input
            #suggestionInput
            type="text"
            placeholder="Type a Client or Organization name"
            (input)="updateSuggestions($event.target.value)"
          />
          <div class="suggestions-list">
            <div
              class="suggestion-row"
              [ngClass]="{
                'client-type': suggest.patron,
                'org-type': suggest.organization
              }"
              (click)="
                addUserCoupon(suggest);
                suggestionInput.value = '';
                suggestions = []
              "
              *ngFor="let suggest of suggestions"
            >
              {{ suggest.patron?.givenName || suggest.organization?.name }}
              {{ suggest.patron?.familyName }}
            </div>
          </div>
        </div>
        <div class="users-list">
          <div
            class="user-row"
            *ngFor="let user of usersToCoupon"
            [ngClass]="{
              'client-type': user.patron,
              'org-type': user.organization
            }"
          >
            <span class="user-name">
              {{ user.patron?.givenName || user.organization.name }}
              {{ user.patron?.familyName }}
            </span>
            <button
              class="remove-button"
              (click)="removeUserCoupon(user)"
            >
              <fa-icon [icon]="minusLine"></fa-icon>
            </button>
          </div>
        </div>
        <div
          class="warning-message"
          *ngIf="usersToCoupon.length === 0"
        >
          <div class="warning-title">WARNING!</div>
          <p>This coupon will be available to all users!</p>
          <p>Because you did not select any client or organization.</p>
        </div>
      </div>
      <div class="button-group">
        <button
          class="btn-secondary"
          (click)="close()"
        >
          Close
        </button>
        <button
          class="btn-primary"
          (click)="save()"
        >
          Save
        </button>
      </div>
    </form>
  </section>
</div>
