<div class="col-12 row px-0 mx-0">
  <div
    class="title col-12 px-0 mx-0 py-3 text-center justify-content-center modalHeader"
  >
    <h2>Delete Token</h2>
  </div>

  <div class="input col-12 row px-3 mx-0 my-2">
    <h3 class="label">Quantity</h3>
    <input
      class="col-12 py-2"
      matInput
      type="number"
      min="1"
      [max]="data.maxTokensToDelete"
      step="1"
      [(ngModel)]="quantity"
    />
  </div>

  <div class="buttons col-12 px-3 my-3 mx-0">
    <button
      class="col-4 py-3"
      (click)="confirm()"
    >
      Delete
    </button>
    <button
      class="offset-4 col-4 py-3 warn"
      (click)="cancel()"
    >
      Cancel
    </button>
  </div>
</div>
