import { Component, OnInit } from '@angular/core';
import { documentList } from './documents-list.const';
import { Languages } from './documents-languages.const';
import { toArray } from 'lodash';
import { RoleService } from 'src/app/services/roles/role.service';
import { Document } from './document.model';
import { get } from 'lodash';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnInit {
  public languageList = toArray(Languages);

  public documentList = documentList;

  public filteredDocuments: Document[] = [];

  public languageSelected = 'En';

  constructor(private _roles: RoleService) {}

  async ngOnInit() {
    this.selectLanguage({ value: Languages.English });
    this.selectDocuments();
  }

  public goTo(url: string) {
    window.open(url, '_blank');
  }

  public selectLanguage(e) {
    this.filteredDocuments = this.documentList.filter((d) => d.lang === e.value);
  }

  public selectDocuments() {
    const roles = get(this._roles, 'roles', []);

    this.filteredDocuments = this.filteredDocuments.filter((doc) => doc.roles.some((role) => roles.includes(role)));
  }
}
