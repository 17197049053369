import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Link, navLinks } from '../../constants/nav-links.constants';

@Component({
  selector: 'header-desktop-nav',
  templateUrl: './desktop-nav.component.html',
  styleUrls: ['./desktop-nav.component.scss'],
})
export class DesktopNavComponent {
  constructor(private _router: Router) {}

  public nav = navLinks;

  public navigate(link: Link) {
    if (link.outsideLink) {
      window.open(link.outsideLink, '_blank');
    } else {
      this._router.navigate([link.href]);
    }
  }
}
