<users-layout-two-tables>
  <div leftTable>
    <users-clients-list
      (onGetStudents)="handleGetStudents($event)"
    ></users-clients-list>
  </div>
  <div rightTable>
    <users-students-list></users-students-list>
  </div>
</users-layout-two-tables>
