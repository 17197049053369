import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  faCircle,
  faReceipt,
  faMoneyCheckAlt,
  faTimes,
  faCheckCircle,
  faSpinner,
  faStopCircle,
} from '@fortawesome/free-solid-svg-icons';
import { faCcVisa, faCcMastercard, faCcAmex } from '@fortawesome/free-brands-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { AdjustSubscriptionInfoComponent } from '../adjust-subscription-info-dialog/adjust-subscription-info.component';
import { SubscriptionPaymentsDialogComponent } from '../subscription-payments.dialog/subscription-payments-dialog.component';
import { ConfirmationService } from '../../../services/confirmation/confirmation.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {
  subscriptionMenuOptions,
  getSubscriptionStatusClass,
  getSubscriptionStatus,
} from '../subscription-consts/subscription.consts';
import { RoleService } from 'src/app/services/roles/role.service';
import { get, isEmpty } from 'lodash';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-reseller-subscriptions',
  templateUrl: './reseller-subscriptions.component.html',
  styleUrls: ['./reseller-subscriptions.component.scss'],
})
export class ResellerSubscriptionComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Output() startLoading = new EventEmitter<boolean>();
  @Input() resellerSubscriptions = [];

  public readonly circle: IconDefinition = faCircle;
  public readonly receipt: IconDefinition = faReceipt;
  public readonly changePrice: IconDefinition = faMoneyCheckAlt;
  public readonly cancel: IconDefinition = faTimes;
  public readonly reactivate: IconDefinition = faCheckCircle;
  public readonly loadingIcon: IconDefinition = faSpinner;
  public readonly stopIcon: IconDefinition = faStopCircle;
  public readonly visaIcon: IconDefinition = faCcVisa;
  public readonly masterIcon: IconDefinition = faCcMastercard;
  public readonly amexIcon: IconDefinition = faCcAmex;

  public readonly subscriptionMenuOptions = subscriptionMenuOptions;

  displayedColumns: string[] = ['client', 'cost', 'next charge', 'status', 'tokens', 'actions'];

  dataSource: MatTableDataSource<any>;

  public organization;
  public resellerSubscriptionForDisplay = [];
  public subscriptionForDisplay = [];
  public balance: number;
  public loadingBalance = true;
  public isReseller = false;
  public isOutsider = false;
  public getSubscriptionClass = getSubscriptionStatusClass;
  public getSubscriptionStatus = getSubscriptionStatus;

  constructor(
    private _rest: RestAPIService,
    private _dialog: MatDialog,
    private confirm: ConfirmationService,
    private _snack: MatSnackBar,
    private roleService: RoleService,
    private authService: AuthService,
  ) {}

  async ngOnInit() {
    this.organization = get(this.roleService, 'user.organization') || get(this.authService.getOrgAcc(), 'organization');
    await this.tokenBalance();
    this.findUserSubscriptions();
  }

  public findUserSubscriptions() {
    if (this.resellerSubscriptions.length === 0) {
      return;
    }

    this.resellerSubscriptionForDisplay = this.resellerSubscriptions.map((subscription) => {
      return {
        cost: subscription.plan.amount / 100,
        currency: subscription.plan.currency.toUpperCase(),
        tokens: subscription.metadata.tokens,
        status: subscription.status,
        underCancelation: subscription.cancel_at_period_end,
        client: subscription.user.organization.name,
        id: subscription.id,
        subscriptionItemId: subscription.items.data[0].id,
        currentPeriodEnd: subscription.current_period_end,
        pausedSubscription: subscription.pause_collection ? true : false,
      };
    });

    this.dataSource = new MatTableDataSource(this.resellerSubscriptionForDisplay);
    this.dataSource.paginator = this.paginator;
  }

  public returnSubscriptionSource(subscription) {
    if (!this.isActiveSubscription(subscription)) {
      return '---';
    } else {
      return subscription.paymentCard.last4;
    }
  }

  public isActiveSubscription(subscription): boolean {
    return !isEmpty(subscription.paymentCard);
  }

  public async tokenBalance() {
    let tokensToDeduct = 0;

    try {
      const tokenResult = await this._rest.get('token/self', { msg: 'Could not get token.' });

      let tokensAmount = tokenResult.tokens.filter((t) => !t.studentId && t.paymentConfirmed).length;
      tokensAmount -= tokenResult.tokens.filter((t) => t.studentId && !t.paymentConfirmed).length;

      this.resellerSubscriptions.map((t) => {
        if (t.cancel_at_period_end || t.pause_collection) {
          return;
        }

        tokensToDeduct = Number(tokensToDeduct) + Number(t.metadata.tokens);
      });

      this.balance = tokensAmount - tokensToDeduct;
      this.loadingBalance = false;
      // eslint-disable-next-line no-empty
    } catch {}
  }

  public adjustSubscriptionInfo(subscription) {
    const dialog = this._dialog.open(AdjustSubscriptionInfoComponent, {
      data: subscription,
      width: '400px',
      panelClass: 'custom-modalbox',
    });

    dialog.afterClosed().subscribe(async (shouldRealod: boolean) => {
      if (shouldRealod) {
        await this.refreshSubscriptions();
      }
    });
  }

  public getDate(date) {
    const correctDate = Number(date + '000');
    const dateFormat = new Date(correctDate);
    return ` ${dateFormat.getDate()} / ${dateFormat.getMonth() + 1} / ${dateFormat.getFullYear()} `;
  }

  public async findResellerSubscriptionInvoices(subscription) {
    this.startLoading.emit(true);

    const invoices = await this._rest.get('reseller/subscriptions/' + subscription.id + '/invoices', {
      msg: 'Could not get this subscription invoices',
    });

    this._dialog.open(SubscriptionPaymentsDialogComponent, {
      data: invoices,
      panelClass: 'custom-modalbox',
      width: '410px',
    });

    this.startLoading.emit(false);
  }

  public outOfTokensCheck(): boolean {
    const maxDebit = this.organization.maximumTokenDebit;

    return this.balance < maxDebit ? true : false;
  }

  public async changeSubscriptionPaymentStatus(subscription, status) {
    if (!subscription) {
      return;
    }

    const warningMessage =
      status === 'pause'
        ? 'Are you sure you want to pause this subscription ?'
        : 'Are you sure you want to resume this subscription ?';
    const shouldPause = status === 'pause' ? { behavior: 'void' } : '';

    this.confirm.createConfirmation('Warning', warningMessage, 'Yes', 'No').then(async () => {
      // in order to pause the subscription payment we have to pass the status as { behavior 'void' } to update the pause_collection property on stripe
      // in order to resume the subscription payment we have to pass the status as an empty string to update the pause_collection property on stripe

      try {
        await this._rest.put(
          'subscriptions/changePaymentStatus',
          {
            subscriptionId: subscription.id,
            status: shouldPause,
          },
          { msg: 'Could not put subscriptions' },
        );

        const message = status === 'pause' ? 'paused' : 'resumed';

        this._snack.open(`Done! This subscription has been ${message}`, 'Close', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
        await this.refreshSubscriptions();
      } catch (error) {
        this._snack.open('Could not update this subscription', 'Close', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }
    });
  }

  public async refreshResellerSubscriptions() {
    this.findUserSubscriptions();
  }

  public async refreshSubscriptions() {
    this.startLoading.emit(true);
    const subscriptions = await this._rest.get('subscriptions/getActiveSubscriptions', {
      msg: 'Could not get subscriptions/getActiveSubscriptions.',
    });

    this.resellerSubscriptions = this.resellerSubscriptions =
      subscriptions.resellerSubscriptions.filter((s) => s.user.organization) || [];
    this.findUserSubscriptions();
    this.startLoading.emit(false);
  }
}
