<div class="dialog">
  <div class="checkbox-form">
    <header>Select the allowed payment types:</header>
    <form [formGroup]="form">
      <div class="checkboxes">
        <div
          class="checkbox"
          [matTooltip]="'Single payments are always available and active.'"
          [matTooltipPosition]="'above'"
        >
          <label for="single">Single Payment</label>
          <mat-checkbox
            id="single"
            [formControlName]="'single'"
          ></mat-checkbox>
        </div>
        <div class="checkbox">
          <label for="split">Split Payment</label>
          <mat-checkbox
            id="split"
            [formControlName]="'split'"
          ></mat-checkbox>
        </div>
        <div class="checkbox">
          <label for="monthly">Monthly Payment</label>
          <mat-checkbox
            id="monthly"
            [formControlName]="'monthly'"
          ></mat-checkbox>
        </div>
      </div>
    </form>

    <div class="buttons">
      <app-simple-button
        class="mx-2"
        text="CONFIRM"
        [smaller]="true"
        (click)="confirm()"
      ></app-simple-button>

      <app-simple-button
        class="mx-2"
        text="CANCEL"
        [smaller]="true"
        (click)="cancel()"
      ></app-simple-button>
    </div>
  </div>
</div>
