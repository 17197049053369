<div id="App">
  <div
    class="globalLoading"
    *ngIf="loading"
  >
    <div class="background"></div>
    <fa-icon
      [icon]="spinner"
      [spin]="true"
    ></fa-icon>
  </div>
  <app-test-area></app-test-area>
  <router-outlet></router-outlet>
</div>
