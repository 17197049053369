import { Component, Input, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { faHeadset, faKeyboard, faMousePointer, faTv, faChartBar } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-before-you-start',
  templateUrl: './before-you-start.component.html',
  styleUrls: ['./before-you-start.component.scss'],
})
export class BeforeYouStartComponent implements OnInit {
  @Input() isPopup: boolean;

  @Output() handleStart: EventEmitter<any>;
  @Output() close = new EventEmitter();

  public pages;
  public pageIndex;

  constructor(
    public translate: TranslateService,
    private changeDetector: ChangeDetectorRef,
    public dialogRef: MatDialogRef<BeforeYouStartComponent>,
  ) {
    this.handleStart = new EventEmitter();
  }

  ngOnInit() {
    this.pageIndex = 0;
    this.pages = [
      {
        title: 'beforeYouStart.title',
        lines: [
          { title: 'beforeYouStart.quietLocation' },
          { title: 'beforeYouStart.wearConfortable', icon: faHeadset },
          { title: 'beforeYouStart.externalMouse', icon: faMousePointer },
          { title: 'beforeYouStart.keyboardHandy', icon: faKeyboard },
          { title: 'beforeYouStart.clickOnArrows', picture: 1 },
          { title: 'beforeYouStart.userWideMonitor', icon: faTv },
        ],
      },
      {
        title: 'beforeYouStart.title',
        lines: [
          { title: 'beforeYouStart.pressP', picture: 3 },
          { title: 'beforeYouStart.pressSpaceBar', picture: 2 },
          { title: 'beforeYouStart.takeDeepBreath' },
          { title: 'beforeYouStart.scoresRecorded', icon: faChartBar },
        ],
      },
    ];
  }

  public foward() {
    this.pageIndex += 1;
    this.changeDetector.detectChanges();
  }

  public start() {
    this.dialogRef.close();
  }
}
