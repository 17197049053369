<app-main-layout
  [breadcrumbs]="[
    { name: 'Home', url: '/' },
    { name: 'Programs', url: '/programs' }
  ]"
>
  <div class="inner-content">
    <ng-container *ngIf="showInfo">
      <div class="logo">
        <img src="./assets/img/NeurAlign text logo.png" />
      </div>

      <div class="belongs-to-info">
        <h2 *ngIf="outsiderName">
          Adjusting price for <span class="org-name"> {{ outsiderName }} </span>
        </h2>
        <h2 *ngIf="outsiderName === undefined">Adjusting default price</h2>
      </div>

      <div class="bundles">
        <app-input-bundle-card
          class="mx-1"
          (saveEvent)="save($event)"
          (deleteEvent)="delete($event)"
          *ngFor="let bundle of displayBundles; let i = index"
          [index]="i"
          [bundle]="bundle"
          [outsiderId]="outsiderId"
          [subscriptionList]="subscriptionList"
          [allowSubscription]="allowSubscription"
        >
        </app-input-bundle-card>
      </div>
    </ng-container>
  </div>
</app-main-layout>
