import { Injectable } from '@angular/core';
import { LoggerService } from '../../services/logger/logger.service';
import { Router } from '@angular/router';
import { Student } from '../students/interfaces/student.interface';
import { Client } from 'src/app/shared/interfaces';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { get } from 'lodash';
import { Organization } from 'src/app/core/openapi';

@Injectable({
  providedIn: 'root',
})
export class UsersHelperService {
  constructor(private _logger: LoggerService, private _router: Router, private _rest: RestAPIService) {}

  public getUserStatus(isEmailVerified: boolean): 'active' | 'not-active' | 'error' {
    if (isEmailVerified) {
      return 'active';
    } else if (!isEmailVerified) {
      return 'not-active';
    } else if (isEmailVerified === undefined) {
      return 'error';
    }
  }

  public getEmailVerifiedTooltip(isEmailVerified: boolean | undefined): string {
    switch (isEmailVerified) {
      case true:
        return 'Email already verified';
      case false:
        return 'Send verification email';
      case undefined:
        return 'E-mail not found';
      default:
        this._logger.error('Could not set user email verified tooltip.');
    }
  }

  public transformUserName(user: Client): string {
    if (user.fullname) {
      return user.fullname;
    } else {
      return `${user.givenName} ${user.familyName}`;
    }
  }

  public goToStudentProgramPage(student: Student): void {
    this._router.navigate(['/program/' + student.id + '/Neuralign']);
  }

  public async getCurrentOrganization() {
    const response = await this._rest.get('organization/self', {
      msg: 'Could not get organization.',
    });
    const organization = response.organization as Organization;
    return organization;
  }

  public async confirmFirstPurchase() {
    const response = await this._rest.get(`/user/confirmFirstPurchase`);

    const isFirstPurchaseDone = get(response, 'tokenPurchased', false);

    return isFirstPurchaseDone;
  }
}
