<section #availableInFrench>
  <div #frenchText>
    <div #neuralignLogo>
      <img
        src="assets/img/neuralign-text-logo-white.webp"
        width="100%"
      />
    </div>

    <p
      montserrat
      thin
      largeSize
      white
      zeroMargin
    >
      Est aussi disponible en <br />
      français!
    </p>

    <p
      montserrat
      thin
      largeSize
      white
      zeroMargin
    >
      Is also available in French!
    </p>
  </div>
  <div>
    <img
      src="/assets/img/slides/man-purple-bg-reversed.webp"
      alt=""
      width="100%"
    />
  </div>
</section>

<section #carleton>
  <div #carletonText>
    <div #carletonLogo>
      <h3 montserrat>Results supported by research</h3>

      <p
        class="carelton-text"
        montserrat
      >
        The Centre for Applied Cognitive Research at Carleton University
        conducted a quasi-randomized controlled trial of Neuralign.
      </p>

      <div class="images">
        <div>
          <img
            src="/assets/img/carleton-logo-black.webp"
            width="100%"
          />
        </div>

        <div>
          <img
            src="/assets/img/badges/ESSA-badge.png"
            width="100%"
          />
        </div>
      </div>
    </div>

    <button
      primaryBtn
      smallSize
      (click)="goToStudy()"
    >
      Read the Report
    </button>
  </div>
  <div>
    <img
      src="/assets/img/slides/girl-holding-books-orange-bg.webp"
      width="100%"
    />
  </div>
</section>

<div #brain>
  <img
    src="/assets/img/brains/white-brain.webp"
    alt=""
    width="100%"
  />
</div>
