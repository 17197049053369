import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { english } from '../locale/en';
import { french } from '../locale/fr';
import { CookiesService } from './services/cookies/cookies.service';
import { MatDialog } from '@angular/material/dialog';
import { IconDefinition, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { CookiesConsentDialog } from './shared/dialogs/cookies-consent/cookies-consent.dialog';
import { LoadingService } from './services/utils/loading.service';
import { Subscription } from 'rxjs';
import { LOCALHOST } from './services/auth/auth-constants';

enum ProtocolTypes {
  SAFE = 'https:',
  UNSAFE = 'http:',
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Learning Solutions Works';

  cookiesSub: Subscription;

  public readonly spinner: IconDefinition = faSpinner;
  public loading = false;
  private eventSubscription: Subscription;

  constructor(
    translate: TranslateService,
    private _dialog: MatDialog,
    private _loading: LoadingService,
    private _cookies: CookiesService,
  ) {
    translate.setTranslation('en', english);
    translate.setTranslation('fr', french);

    this.handleUserProtocol();
  }

  ngOnInit(): void {
    this.cookiesSub = this._cookies.showCookiesDialog$.subscribe((show) => {
      if (show) this._showCookiesDialog();
    });

    this.eventSubscription = this._loading.loadingEvent.subscribe((state: boolean) => {
      this.changeLoadingState(state);
    });
  }

  ngOnDestroy(): void {
    this.cookiesSub.unsubscribe();

    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
  }

  public handleUserProtocol() {
    if (this.isUnsafeProtocol()) {
      window.location.href = window.location.href.replace(ProtocolTypes.UNSAFE, ProtocolTypes.SAFE);
    }
  }

  public isUnsafeProtocol(): boolean {
    return window.location.protocol === ProtocolTypes.UNSAFE && window.location.hostname !== LOCALHOST;
  }

  private changeLoadingState(state: boolean) {
    this.loading = state;
  }

  private _showCookiesDialog() {
    this._dialog.open(CookiesConsentDialog, {
      hasBackdrop: false,
      width: '480px',
      autoFocus: 'autofocus',
      position: {
        bottom: '24px',
        left: '24px',
      },
    });
  }
}
