<div class="mainContainer h-100">
  <div class="col-12 px-0 header text-center">Neuralign</div>

  <div class="textContainer">
    <div
      class="containerInfo p-4"
      *ngIf="data.level < 4"
    >
      Congratulations on finishing the Neuralign program! Would you like to move
      up a level?
    </div>

    <div
      class="containerInfo p-2"
      *ngIf="data.level === 4"
    >
      <div class="my-2">
        You have completed the highest level, you can complete this level again
        or would you like to pick another level?
      </div>

      <div class="my=2">
        Select the level :

        <select
          class="levelSelect"
          [(ngModel)]="selectedLevel"
          *ngIf="data.level === 4"
        >
          <option
            class="p-1"
            *ngFor="let studentLevel of data.allowedLevels"
            value="{{ studentLevel.assessmentLevel }}"
          >
            {{ studentLevel.level }}
          </option>
        </select>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-center align-items-center">
    <app-simple-button
      text="Yes"
      (click)="closeDialog(true)"
      class="mx-2"
    ></app-simple-button>
    <app-simple-button
      text="No"
      (click)="closeDialog(false)"
      class="mx-2"
    ></app-simple-button>
  </div>
</div>
