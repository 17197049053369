import { NgModule } from '@angular/core';
import { HomeModule } from './home/home.module';
import { PricingModule } from './pricing/pricing.module';
import { RegisterModule } from './register/register.module';
import { FAQModule } from './faq/faq.module';
import { ContactModule } from './contact/contact.module';
import { TermsOfServiceModule } from './terms-of-service/terms.module';
import { DocumentsModule } from './documents/documents.module';
import { StudentsModule } from './students/students.module';
import { ProgramsModule } from './programs/programs.module';
import { PurchaseModule } from './purchase/purchase.module';
import { TakeATourModule } from './take-a-tour/take-a-tour.module';
import { ProgramsPricingModule } from './programs-pricing/programs-pricing.module';
import { AdminPricingModule } from './admin-pricing/admin-pricing.module';
import { UsersModule } from './users/users.module';
import { TutorialsModule } from './tutorials/tutorials.module';
import { HelpModule } from './help/help.module';
import { PrivacyPolicyModule } from './privacy-policy/privacy-policy.module';
import { NotFoundModule } from './404/404.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { AboutUsModule } from './about-us/about-us.module';
import { TutorialsOrgModule } from './tutorials-org/tutorials-org.module';
import { DemoModule } from './demo/demo.module';
import { BiographyModule } from './biography/biography.module';
import { SubscriptionControlCenterModule } from './subscription-control-center/subscription-control-center.module';
import { PublicProfileModule } from './public-profile/public-profile.module';
import { CoursesModule } from './courses/courses.module';
import { ContentConfigurationsModule } from './configuration-pages/content-configurations/content-configurations.module';
import { RegionConfigurationsModule } from './configuration-pages/region-configurations/region-configurations.module';
import { CourseConfigurationsModule } from './configuration-pages/course-configurations/course-configurations.module';
import { GlobalConfigurationsModule } from './configuration-pages/global-configurations/global-configurations.module';
import { HttpClientModule } from '@angular/common/http';
import { AuthCallBackModule } from '../shared/components/call-back/call-back.component.module';
import { InstallmentPaymentsControlCenterModule } from './installment-payments-control-center/installment-payments-control-center.module';
import { PublicMessageBannerModule } from './public-message-banner/public-message-banner.module';
import { OrganizationUpdateModule } from './org-signup-completion/org-signup-completion.module';
import { StripeModule } from './stripe/stripe.module';
import { MessagesDashboardModule } from './messages/messages-dashboard.module';
import { ClassWalletDashboardModule } from './region-dashboard/classwallet/cw-dashboard.component.module';

@NgModule({
  imports: [
    HomeModule,
    NotFoundModule,
    PricingModule,
    RegisterModule,
    FAQModule,
    ContactModule,
    TermsOfServiceModule,
    DocumentsModule,
    StudentsModule,
    RegionConfigurationsModule,
    ContentConfigurationsModule,
    GlobalConfigurationsModule,
    CourseConfigurationsModule,
    UsersModule,
    ProgramsModule,
    PurchaseModule,
    TakeATourModule,
    ProgramsPricingModule,
    AdminPricingModule,
    TutorialsModule,
    TutorialsOrgModule,
    HelpModule,
    PrivacyPolicyModule,
    DashboardModule,
    DemoModule,
    AboutUsModule,
    BiographyModule,
    SubscriptionControlCenterModule,
    InstallmentPaymentsControlCenterModule,
    ClassWalletDashboardModule,
    PublicProfileModule,
    CoursesModule,
    HttpClientModule,
    AuthCallBackModule,
    PublicMessageBannerModule,
    OrganizationUpdateModule,
    StripeModule,
    MessagesDashboardModule,
  ],
})
export class PagesModule {}
