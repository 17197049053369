<app-main-layout
  [breadcrumbs]="[
    { name: 'Home', url: '/' },
    { name: 'Region configs', url: '/region-configurations' }
  ]"
>
  <div
    *ngIf="loading"
    class="loading"
  >
    <mat-spinner></mat-spinner>
  </div>

  <div class="mainContainer border">
    <div class="header col-8 mx-auto px-0 d-flex">
      <div
        class="py-2 col-2 text-center"
        (click)="selectedOption = 'reservedRegions'"
        [ngClass]="{ selectedOption: selectedOption === 'reservedRegions' }"
      >
        <fa-icon [icon]="reservedRegions"></fa-icon> <br />
        <span>Reserved Regions</span>
      </div>

      <div
        class="py-2 col-2 text-center"
        (click)="selectedOption = 'taxes'"
        [ngClass]="{ selectedOption: selectedOption === 'taxes' }"
      >
        <fa-icon [icon]="taxesIcon"></fa-icon> <br />
        <span>Taxes</span>
      </div>

      <div
        class="py-2 col-2 text-center"
        (click)="selectedOption = 'bundles'"
        [ngClass]="{ selectedOption: selectedOption === 'bundles' }"
      >
        <fa-icon [icon]="bundlesIcon"></fa-icon> <br />
        <span>Bundles</span>
      </div>
    </div>

    <div
      class="col-8 mx-auto px-0 mt-5"
      *ngIf="selectedOption === 'reservedRegions'"
    >
      <app-reserved-regions> </app-reserved-regions>
    </div>

    <div
      class="col-8 mx-auto px-0 mt-5"
      *ngIf="selectedOption === 'taxes'"
    >
      <app-tax-rates> </app-tax-rates>
    </div>

    <div
      class="col-8 mx-auto px-0 mt-5"
      *ngIf="selectedOption === 'bundles'"
      (refreshBundles)="reset()"
    >
      <app-global-config-bundles [bundles]="bundleList">
      </app-global-config-bundles>
    </div>
  </div>
</app-main-layout>
