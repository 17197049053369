<div class="mainContainer">
  <div class="header text-center p-4">
    <img
      class="my-3"
      src="assets/img/NeurAlign text logo.png"
    />
  </div>

  <div class="body px-2">
    <img [src]="data.bodyImage" />
  </div>

  <div class="footer">
    <div class="footerTitle my-2">
      {{ data.studentsIncluded }} Credits / ${{ data.promotionalPrice }}
    </div>
    <div class="footerBody">
      Help your students create some magical summer moments with our lowest
      prices of the season! For the month of June save
      {{ calculateDiscount() }} on our 3 credit bundle.
    </div>
    <div class="buttons d-flex justify-content-center mt-4 mb-4">
      <button
        mat-raised-button
        class="basicButton mx-2"
        (click)="goToPurchasePage()"
      >
        Buy now!
      </button>
      <button
        mat-raised-button
        class="basicButton mx-2"
        (click)="close()"
      >
        Close
      </button>
    </div>
  </div>
</div>
