import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { UsersCreateModalComponent } from '../../modals/users-create-modal/users-create-modal.component';
import { OrganizationsCreateModalComponent } from '../../modals/organizations-create-modal/organizations-create-modal.component';
import { OrgAdminAndManagerCreateModalComponent } from '../../modals/org-admin-manager-create-modal/org-admin-manager-create-modal.component';
import { ManagerActions, UsersType } from 'src/app/shared/interfaces/Manager.interface';
import { StudentCreateModalComponent } from '../../modals/student-create-modal/student-create-modal.component';

@Component({
  selector: 'users-add-new-button',
  templateUrl: './add-new-button.component.html',
  styleUrls: ['./add-new-button.component.scss'],
})
export class AddNewButtonComponent {
  public faPlus = faPlus;

  @Input() usersType: UsersType;
  @Output() onAddNewUser = new EventEmitter();

  //Modal Config
  MODAL_WIDTH = '500px';
  ORGANIZATION_MODAL_WIDTH = '1200px';

  constructor(private _router: Router, private dialog: MatDialog) {}

  public goto(path: string, org: any) {
    if (org) {
      this._router.navigate([path], { queryParams: { id: org.id, name: org.organization.name } });
    } else {
      this._router.navigate([path]);
    }
  }

  public handleClick() {
    const modalComponent = this.getModalComponent();
    if (modalComponent) {
      this.dialog.open(modalComponent as any, {
        width: this.usersType !== UsersType.ORGANIZATION ? this.MODAL_WIDTH : this.ORGANIZATION_MODAL_WIDTH,
        disableClose: true,
        data: {
          type: ManagerActions.CREATE,
        },
      });
    }
  }

  private getModalComponent() {
    switch (this.usersType) {
      case UsersType.CLIENT:
        return UsersCreateModalComponent;
      case UsersType.ORGANIZATION:
        return OrganizationsCreateModalComponent;
      case UsersType.MANAGERS_AND_ADMINS:
        return OrgAdminAndManagerCreateModalComponent;
      case UsersType.STUDENT:
        return StudentCreateModalComponent;
      default:
        return null;
    }
  }
}
