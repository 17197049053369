import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { faPlus, faTrash, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { CouponModalComponent } from './coupons-modal/coupons-modal.component';
import moment from 'moment';
import { Templates } from 'src/app/shared/interfaces/Manager.interface';
import { get } from 'lodash';
import { AlertDialogComponent } from 'src/app/shared/dialogs/alert/alert.dialog';
import { Client } from 'src/app/shared/interfaces';
import { Organization } from 'src/app/core/openapi';
import { Coupon } from 'src/app/shared/interfaces/Coupon.interface';

enum Sort {
  ASC = 'asc',
  DESC = 'desc',
}

enum MenuToggle {
  CLIENTS = 'clients',
  ORGANIZATIONS = 'organizations',
  GLOBAL = 'global',
}

interface OrganizationWithCoupons {
  coupons: Coupon[];
  organization: Organization;
}

@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.scss'],
})
export class CouponComponent implements OnInit {
  public coupons: Coupon[];
  public clientsCoupons: Coupon[];
  public organizationsCoupons: Coupon[];
  public originalClientsCoupons: Coupon[];
  public originalOrganizationsCoupons: Coupon[];
  public originalGlobalCoupons: Coupon[];
  public clients: Client[];
  public organizations: OrganizationWithCoupons[];
  public globalCoupons: Coupon[];
  public deletingCoupon: string;
  public selectedUserType = 'Clients';
  public showCouponCodeOnly = true;
  public userCouponList = [];

  // Header Config
  public searchTerm: string;
  public readonly faPlus: IconDefinition = faPlus;

  // Table Config
  public template: Templates;
  public sortField = null;
  public showCoupons: MenuToggle = MenuToggle.CLIENTS;
  public sortDirection = Sort.ASC;
  public readonly faTrash: IconDefinition = faTrash;
  public columns = ['code', 'discountAmount', 'discountPercentage', 'expiration', 'limit', 'used'];
  public columnOrgIncludes: boolean = false;

  constructor(private _rest: RestAPIService, private dialog: MatDialog) {}

  async ngOnInit() {
    this.template = Templates.LOADING;
    await this.initializeUsers();
    await this.initializeGlobalCoupons();
    this.initializeOrganizationCoupons();
    this.initializeUserCoupons();
    this.sortCouponsByExpiration(this.coupons);
    this.template = Templates.TABLE;
  }

  // Initialization Functions
  private async initializeUsers() {
    try {
      const { users } = await this._rest.get('admin/users');
      this.clients = users.filter((user) => !!user.patron);
      this.organizations = users.filter((user) => !!user.organization);
    } catch (error) {
      this.dialog.open(AlertDialogComponent, {
        width: '400px',
        data: 'Error getting users' + error.message,
      });
    }
  }

  private async initializeGlobalCoupons() {
    try {
      const { coupons } = await this._rest.get('admin/globalCoupons');
      this.globalCoupons = coupons;
      this.originalGlobalCoupons = [...coupons];
      this.deletingCoupon = '';
    } catch (error) {
      this.dialog.open(AlertDialogComponent, {
        width: '400px',
        data: 'Error getting global coupons' + error.message,
      });
    }
  }

  private initializeUserCoupons() {
    this.userCouponList = this.getCouponsList();
    this.getCoupons(this.userCouponList);
    this.originalClientsCoupons = [...this.clientsCoupons];
  }

  private initializeOrganizationCoupons(): void {
    const orgCoupons = [];
    this.organizations.forEach((org) => {
      if (org?.coupons?.length > 0) {
        org.coupons.forEach((coupon) => {
          orgCoupons.push({
            organization: get(org, 'organization.name', ''),
            ...coupon,
          });
        });
      }
    });
    this.organizations = orgCoupons;
    this.originalOrganizationsCoupons = [...orgCoupons];
  }

  // Main Functions
  public getCouponsList() {
    let userCouponList = [];
    if (this.selectedUserType === 'Clients') {
      userCouponList = [].concat(this.clients);
    } else {
      userCouponList = [].concat(this.organizations);
    }
    if (this.showCouponCodeOnly) {
      return userCouponList.filter((u) => u.coupons).filter((u) => u.coupons.length > 0);
    } else {
      return userCouponList;
    }
  }

  public getCoupons(users) {
    const coupons = [];
    users.forEach((user) => {
      user.coupons.forEach((coupon) => coupons.push(coupon));
    });

    this.coupons = coupons;
    this.clientsCoupons = coupons;
  }

  public sortCouponsByExpiration(coupons: Coupon[]) {
    this.coupons = coupons.sort((a, b) => {
      const dateA = moment(a.expiration);
      const dateB = moment(b.expiration);

      if (!dateA.isValid() && !dateB.isValid()) return 0;
      if (!dateA.isValid()) return 1;
      if (!dateB.isValid()) return -1;

      if (dateA.isBefore(dateB)) return 1;
      if (dateA.isAfter(dateB)) return -1;
      return 0;
    });
  }

  public async deleteCoupon(coupon) {
    try {
      this.deletingCoupon = coupon.id;
      await this._rest.delete('admin/coupon/' + coupon.id);
      this.coupons = this.coupons.filter((c) => c.id !== coupon.id);
    } catch (error) {
      this.dialog.open(AlertDialogComponent, {
        width: '400px',
        data: 'Error deleting coupon' + error.message,
      });
    }
  }

  // Header functions
  search() {
    switch (this.showCoupons) {
      case MenuToggle.CLIENTS:
        this.coupons = this.handleCouponsSearch(this.originalClientsCoupons, this.searchTerm);
        break;
      case MenuToggle.ORGANIZATIONS:
        this.coupons = this.handleCouponsSearch(this.originalOrganizationsCoupons, this.searchTerm);
        break;
      case MenuToggle.GLOBAL:
        this.coupons = this.handleCouponsSearch(this.originalGlobalCoupons, this.searchTerm);
        break;
    }
  }

  public handleCouponsSearch(items: Coupon[], searchTerm: string) {
    if (!items) return [];
    if (!searchTerm) return items;

    const term = searchTerm.toLowerCase().trim();

    return items.filter((item) => {
      const matchCode = item?.code?.toLowerCase().includes(term);
      const matchOrg = item?.organization?.toLowerCase().includes(term);

      return matchCode || matchOrg;
    });
  }

  public async handleClick() {
    const dialog = this.dialog.open(CouponModalComponent, {
      width: '550px',
      disableClose: true,
      data: {
        clients: this.clients,
        organizations: this.organizations,
      },
    });

    dialog.afterClosed().subscribe(async (result) => {
      if (result) {
        await this.ngOnInit();
        this.showCoupons = MenuToggle.CLIENTS;
        this.sortCouponsByExpiration(this.coupons);
      }
    });
  }

  // Table config
  public toggleCoupons(type: MenuToggle) {
    switch (type) {
      case MenuToggle.CLIENTS:
        this.columns.includes('organization') ? this.columns.shift() : this.columns;
        this.columnOrgIncludes = false;
        this.showCoupons = MenuToggle.CLIENTS;
        this.sortCouponsByExpiration(this.clientsCoupons);
        this.coupons = this.clientsCoupons;
        break;
      case MenuToggle.ORGANIZATIONS:
        this.showCoupons = MenuToggle.ORGANIZATIONS;
        this.sortCouponsByExpiration(this.originalOrganizationsCoupons);
        this.coupons = this.originalOrganizationsCoupons;
        if (!this.columnOrgIncludes) {
          this.columns.unshift('organization');
          this.columnOrgIncludes = true;
        }
        break;
      case MenuToggle.GLOBAL:
        this.columns.includes('organization') ? this.columns.shift() : this.columns;
        this.columnOrgIncludes = false;
        this.showCoupons = MenuToggle.GLOBAL;
        this.sortCouponsByExpiration(this.globalCoupons);
        this.coupons = this.globalCoupons;
        break;
    }
    this.searchTerm = '';
  }

  public sortTable(field: string) {
    if (this.sortField === field) {
      this.sortDirection = this.sortDirection === Sort.ASC ? Sort.DESC : Sort.ASC;
    } else {
      this.sortField = field;
      this.sortDirection = Sort.ASC;
    }

    this.coupons.sort((a, b) => {
      if (field === 'expiration') {
        const dateA = moment(a[field]);
        const dateB = moment(b[field]);

        if (!dateA.isValid() && !dateB.isValid()) return 0;
        if (!dateA.isValid()) return 1;
        if (!dateB.isValid()) return -1;

        if (dateA.isBefore(dateB)) {
          return this.sortDirection === Sort.ASC ? -1 : 1;
        }
        if (dateA.isAfter(dateB)) {
          return this.sortDirection === Sort.ASC ? 1 : -1;
        }
        return 0;
      }

      if (a[this.sortField] < b[this.sortField]) {
        return this.sortDirection === Sort.ASC ? -1 : 1;
      }
      if (a[this.sortField] > b[this.sortField]) {
        return this.sortDirection === Sort.ASC ? 1 : -1;
      }
      return 0;
    });
  }

  getColumnDisplayName(column: string): string {
    switch (column) {
      case 'discountAmount':
        return 'amount';
      case 'discountPercentage':
        return 'percentage';
      default:
        return column;
    }
  }

  getColumnValue(coupon: Coupon, column: string) {
    switch (column) {
      case 'expiration':
        return coupon[column] ? this.handleTimestampToDate(coupon[column]) : 'No expiration';
      case 'discountPercentage':
        return coupon[column] === coupon[column] ? `${coupon[column]}%` : '';
      case 'used':
        return coupon[column] === 0 ? 'Not used' : `${coupon[column]} time${coupon[column] === 1 ? '' : 's'}`;
      case 'limit':
        return coupon[column] === 0 ? 'Unlimited' : coupon[column];
      case 'organization':
        return coupon[column] ? this.toTitleCase(coupon[column]) : '';
      default:
        return typeof coupon[column] === 'string' ? coupon[column].toUpperCase() : coupon[column];
    }
  }

  private toTitleCase(text: string) {
    return text
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  private handleTimestampToDate(date: Date) {
    const parsedDate = moment(date);

    if (parsedDate.isValid()) {
      return parsedDate.format('MM-DD-YYYY');
    } else {
      return '--';
    }
  }
}
