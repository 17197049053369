import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RestAPIService } from '../../services/rest/rest-api.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MaterialComponentsModule } from '../../shared/modules/material-components.module';
import { HelpComponent } from './help.component';
import { LayoutsModule } from 'src/app/shared/layouts/layouts.module';

@NgModule({
  declarations: [HelpComponent],
  imports: [BrowserModule, RouterModule, FontAwesomeModule, MaterialComponentsModule, LayoutsModule],
  providers: [RestAPIService],
})
export class HelpModule {}
