import { Component, Input, OnInit } from '@angular/core';
import { QuizLessonContentFEM } from 'src/app/pages/courses/services/courses/courses.service.models';
import { EventService } from 'src/app/pages/courses/services/event/event.service';
import { QuizService } from 'src/app/pages/courses/services/quiz/quiz.service';

@Component({
  selector: 'display-quiz-lesson',
  templateUrl: './quiz-lesson.component.html',
  styleUrls: ['./quiz-lesson.component.scss'],
})
export class QuizLessonComponent implements OnInit {
  currentQuiz$ = this._quiz.currentQuiz$;
  currentQuizQuestion$ = this._quiz.currentQuizQuestion$;

  @Input() content: QuizLessonContentFEM;
  selectedAnswer: any;

  constructor(private _quiz: QuizService, private _events: EventService) {}

  ngOnInit() {
    this._events.emitIsReadyForNextLesson(false);
  }

  onChoiceSelected(choice: any, questionId: any) {
    this.selectedAnswer = choice;
    this._quiz.saveAnswer(questionId, choice);
  }
}
