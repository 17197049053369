import { Component } from '@angular/core';
import { MobileMenuService } from '../../services/mobile-menu/mobile-menu.service';
import { IconDefinition, faClose } from '@fortawesome/free-solid-svg-icons';
import { Link, navLinks } from '../../constants/nav-links.constants';
import { Router } from '@angular/router';

@Component({
  selector: 'header-mobile-nav',
  templateUrl: './mobile-nav.component.html',
  styleUrls: ['./mobile-nav.component.scss'],
})
export class MobileNavComponent {
  closeIcon: IconDefinition = faClose;
  public nav = navLinks;

  constructor(public mobile: MobileMenuService, private _router: Router) {}

  public closeMobileMenu() {
    this.mobile.toggleMobileMenu();
  }

  public navigate(link: Link) {
    if (link.outsideLink) {
      window.open(link.outsideLink, '_blank');
    } else {
      this._router.navigate([link.href]);
    }

    this.mobile.toggleMobileMenu();
  }
}
