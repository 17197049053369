import { trigger, transition, style, animate } from '@angular/animations';
import { AfterViewInit, Component, OnInit } from '@angular/core';

@Component({
  selector: 'lsw-curtain',
  templateUrl: './curtain.component.html',
  styleUrls: ['./curtain.component.scss'],
  animations: [
    trigger('fade', [
      transition(':leave', [
        style({ opacity: 1 }),
        animate('500ms cubic-bezier(0.900, -0.410, 0.750, 0.750)', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class CurtainComponent implements OnInit, AfterViewInit {
  isLoading = true;

  ngOnInit(): void {
    document.body.style.overflow = 'hidden'; // at initialization, set overflow to hidden so scrollbars don't show
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isLoading = false;
      document.body.style.overflow = 'initial';
    }, 250);
  }
}
