import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import {
  Course,
  CourseLesson,
  CourseProgress,
  ProgressLessons,
  ProgressSections,
  Section,
} from 'src/app/pages/configuration-pages/interfaces/global-config.interfaces';

export class ProgressManagerHelper {
  private _rest: RestAPIService;

  constructor(restService: RestAPIService) {
    this._rest = restService;
  }

  public async updateUserProgress(progress: CourseProgress, selectedCourse: Course) {
    const userProgress = { ...progress };

    // Check if the current course have any new lessons or sections that the user dont have saved
    selectedCourse.lessons.forEach((courseLesson) => {
      const savedLesson = userProgress.lessons.find((lesson) => courseLesson._id === lesson.lessonId);

      if (!savedLesson) {
        this.addLesson(courseLesson, userProgress);
      } else {
        this.updateSections(courseLesson, savedLesson);
      }
    });

    // Check if the progress have any lessons or sections that were deleted
    userProgress.lessons = userProgress.lessons.filter((progressLesson) => {
      const lesson = selectedCourse.lessons.find((courseLesson) => courseLesson._id === progressLesson.lessonId);

      if (!lesson) {
        return false;
      } else {
        progressLesson.sections = this.updateProgressSections(progressLesson, lesson);
        return true;
      }
    });

    await this._rest.put('courseProgress/update', userProgress);
  }

  public createNewProgress(courseId: string, lessonList: CourseLesson[]) {
    const newProgress = {
      courseId,
      creationTime: new Date().toDateString(),
      lessons: this.getProgressLessons(lessonList),
    };

    return newProgress;
  }

  public getProgressLessons(lessonList: CourseLesson[]): ProgressLessons[] {
    const lessons = lessonList.map((l) => {
      return {
        lessonId: l._id,
        completed: false,
        sections: this.getProgressSections(l.sections),
      };
    });

    return lessons;
  }

  public getProgressSections(sectionList: Section[]): ProgressSections[] {
    const sections = sectionList.map((s) => {
      return {
        sectionId: s._id,
        completed: false,
      };
    });

    return sections;
  }

  public addSection(section: Section, lesson: ProgressLessons) {
    const newSection: ProgressSections = {
      sectionId: section._id,
      completed: false,
    };

    lesson.sections.push(newSection);
  }

  public addLesson(lesson: CourseLesson, progress: CourseProgress) {
    const newLesson = {
      lessonId: lesson._id,
      completed: false,
      sections: this.getProgressSections(lesson.sections),
    };

    progress.lessons.push(newLesson);
  }

  public updateSections(courseLesson: CourseLesson, savedLesson: ProgressLessons) {
    courseLesson.sections.forEach((courseSection) => {
      const savedSection = savedLesson.sections.find((section) => section.sectionId === courseSection._id);

      if (!savedSection) {
        this.addSection(courseSection, savedLesson);
      }
    });
  }

  public updateProgressSections(progressLesson: ProgressLessons, lesson: CourseLesson) {
    return progressLesson.sections.filter((progressSection) => {
      const section = lesson.sections.find((courseSection) => courseSection._id === progressSection.sectionId);
      return !!section;
    });
  }
}
