import { Component, OnInit } from '@angular/core';
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Course } from '../interfaces/global-config.interfaces';

@Component({
  selector: 'app-course-configurations',
  templateUrl: './course-configurations.component.html',
  styleUrls: ['../configurations.style.scss'],
})
export class CourseConfigurationsComponent implements OnInit {
  public readonly learningManagementSystemIcon: IconDefinition = faGraduationCap;

  public loading: boolean;
  public courseList: Course[] = [];

  public selectedOption = 'courses';

  constructor(private _rest: RestAPIService, protected _snackBar: MatSnackBar) {}

  async ngOnInit() {
    this.loading = true;
    try {
      await this.getCourseList();
    } catch (error) {
      // @TODO add warning
      console.log(error);
    } finally {
      this.loading = false;
    }
  }
  public async getCourseList() {
    this.courseList = await this._rest.get('courses');
  }

  public reset() {
    this.ngOnInit();
  }

  public setLoading = (loading: boolean) => {
    this.loading = loading;
  };
}
