<app-main-layout>
  <div class="container">
    <h1>Help</h1>

    <mat-form-field>
      <mat-label>Try searching!</mat-label>
      <input
        type="text"
        matInput
        (keyup)="updateSearch($event.target.value)"
      />
    </mat-form-field>

    <mat-accordion>
      <mat-expansion-panel *ngFor="let question of questions">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ question.question }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div [innerHTML]="question.answer"></div>
      </mat-expansion-panel>
    </mat-accordion>

    <div
      class="empty-state"
      *ngIf="questions?.length === 0"
    >
      <fa-icon [icon]="searchIcon"></fa-icon>
      <p>Oops! No results for the search.</p>
    </div>
  </div>
</app-main-layout>
