import { Component, Input } from '@angular/core';

@Component({
  selector: 'courses-list-card-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent {
  default = 'assets/img/slides/desktop-mac-yellow-wall-small.jpg';
  @Input() image: string = this.default;

  showPlaceholder(): void {
    this.image = this.default;
  }
}
