import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { B2cListService } from './b2c-list.service';
import { B2C, Token } from 'src/app/shared/interfaces';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { TokenAmountModalComponent } from 'src/app/shared/dialogs/add-token-amount/add-token-amount.dialog';
import { MatDialog } from '@angular/material/dialog';
import { UsersHelperService } from '../../../users-helper.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { applyFilter } from 'src/app/shared/helpers/applyFilter';

const B2C_SORT_ORDER_KEY = 'b2cSortOrder';

@Component({
  selector: 'users-b2c-list',
  templateUrl: './b2c-list.component.html',
  styleUrls: ['./b2c-list.component.scss'],
})
export class B2cListComponent implements OnInit {
  template: 'loading' | 'table' = 'table';
  dataSource;
  sortOrderLabel = this._getSortOrderLabel();

  @Output() onGetStudents = new EventEmitter();

  constructor(
    private _b2cListService: B2cListService,
    private _confirm: ConfirmationService,
    private _rest: RestAPIService,
    private _logger: LoggerService,
    private _dialog: MatDialog,
    private _usersHelperService: UsersHelperService,
    private _auth: AuthService,
    private _snackBar: MatSnackBar,
  ) {}

  async ngOnInit(): Promise<void> {
    this.template = 'loading';
    await this._loadB2CData();
    this.template = 'table';
  }

  public applyFilter(event: KeyboardEvent): void {
    this.dataSource = applyFilter(event, this.dataSource);
  }

  private async _loadB2CData() {
    const data = await this._b2cListService.getB2C({ refresh: true });
    if (this._getB2CSortOrder() === 'asc') {
      this.dataSource = new MatTableDataSource(data);
    } else {
      this.dataSource = new MatTableDataSource(data.reverse());
    }
  }

  public getStudents(id: string): void {
    this.onGetStudents.emit(id);
  }

  public addCredits(b2c: B2C) {
    // Dialogs --------------------------------------
    const showNotEnoughCreditsDialog = () => {
      this._confirm.createConfirmation('Warning', 'Not enough credits available.', 'Ok', undefined);
      return;
    };
    const showAddAtLeastOneCreditDialog = () => {
      this._confirm.createConfirmation('Warning', 'Please add at least one credit.', 'Ok', undefined);
      return;
    };

    const dialogData = {
      width: '350px',
      data: {
        amount: 1,
        organizationName: b2c.name,
      },
    };

    // Process ---------------------------------------

    // Open token dialog
    const dialog = this._dialog.open(TokenAmountModalComponent, dialogData);

    // Process add credits request
    dialog.afterClosed().subscribe(async (data: { amount: number; organizationName: string }) => {
      // Guard against dialog being closed with no data
      if (data === undefined) {
        return;
      }

      // Guard against user spending less than one credit
      if (data !== undefined && data.amount < 1) {
        return showAddAtLeastOneCreditDialog();
      }

      // Get tokens
      const tokenResponse = await this._rest.get('token/self', { msg: 'Could not get token.' });
      const tokens = tokenResponse.tokens as Token[];

      // Get available tokens by filtering out unusable tokens
      const avaliableTokens = tokens.filter(removeTokensInUse).filter(removeUnconfirmedPaymentTokens);

      // Guard not having enough tokens
      if (avaliableTokens.length < data.amount) {
        return showNotEnoughCreditsDialog();
      }

      // Construct dialog message
      const warningMessage = `Are you sure you want to grant ${data.amount} ${creditPlural(data.amount)} to ${
        b2c.name
      }?`;

      // Create main confirmation
      this._confirm.createConfirmation('Warning', warningMessage, 'Yes', 'No').then(async () => {
        try {
          // Get organization and user
          const organization = await this._usersHelperService.getCurrentOrganization();
          const user = this._auth.user;

          // Transfer tokens
          await this._rest.put(
            'token/transfer',
            {
              outsiderAccId: { accountId: b2c.accountId },
              amount: data.amount,
              accountId: user.organization ? user.id : organization.accountId,
              tokenTransfer: true,
            },
            { msg: 'Could not put token' },
          );

          // Notify success
          this._snackBar.open('Credits granted!', 'Close', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });

          // Refresh table
          const refreshB2C = await this._b2cListService.getB2C({ refresh: true });

          this.dataSource = new MatTableDataSource(refreshB2C);

          // Handle errors
        } catch (err) {
          this._logger.error(err);
        }
      });

      // Helpers --------------------------

      function removeTokensInUse(token: Token) {
        return !token.studentId;
      }

      function removeUnconfirmedPaymentTokens(token: Token) {
        return !token.paymentConfirmend;
      }

      function creditPlural(credits: number) {
        return credits === 1 ? 'credit' : 'credits';
      }
    });
  }

  public async deleteB2C(b2c: B2C) {
    this._confirm
      .createConfirmation('Warning', 'Are you sure you want do delete this client?', 'Yes', 'No')
      .then(async () => {
        this.template = 'loading';
        try {
          await this._rest.put('patron/archive/B2C/' + b2c.id, {}, { msg: 'Could not put patron/archive/B2C/:id.' });
        } catch (err) {
          this._logger.error(err);
        }
      })
      .then(async () => {
        await this._loadB2CData();
        this.template = 'table';
      });
  }

  public isDataSourceEmpty(): boolean {
    return this.dataSource.data.length === 0;
  }

  private _getB2CSortOrder(): string {
    return window.localStorage.getItem(B2C_SORT_ORDER_KEY);
  }

  private _getSortOrderLabel() {
    const currentSortOrder = this._getB2CSortOrder();
    if (currentSortOrder === 'asc') {
      return 'Sorting oldest first';
    } else {
      return 'Sorting newest first';
    }
  }

  public async reverseB2CListOrder() {
    this._toggleB2CListSortOrder();
    await this._loadB2CData();
    this.sortOrderLabel = this._getSortOrderLabel();
  }

  private _toggleB2CListSortOrder(): void {
    if (this._getB2CSortOrder() === 'asc') {
      window.localStorage.setItem(B2C_SORT_ORDER_KEY, 'desc');
    } else {
      window.localStorage.setItem(B2C_SORT_ORDER_KEY, 'asc');
    }
  }
}
