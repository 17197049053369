'use strict';

exports.__esModule = true;
exports.showMissingCaptcha = showMissingCaptcha;
exports.setCaptchaParams = setCaptchaParams;
exports.swapCaptcha = swapCaptcha;
var _index = require('../core/index');
var l = _interopRequireWildcard(_index);
var _index2 = require('../field/index');
var c = _interopRequireWildcard(_index2);
var _i18n = require('../i18n');
var i18n = _interopRequireWildcard(_i18n);
var _index3 = require('../store/index');
var _web_api = require('../core/web_api');
var _web_api2 = _interopRequireDefault(_web_api);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};
    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key];
      }
    }
    newObj.default = obj;
    return newObj;
  }
}

/**
 * Display the error message of missing captcha in the header of lock.
 *
 * @param {Object} m model
 * @param {Number} id
 */
function showMissingCaptcha(m, id) {
  var captchaConfig = l.captcha(m);
  var captchaError = captchaConfig.get('provider') === 'recaptcha_v2' ? 'invalid_recaptcha' : 'invalid_captcha';
  var errorMessage = i18n.html(m, ['error', 'login', captchaError]);
  (0, _index3.swap)(_index3.updateEntity, 'lock', id, function (m) {
    m = l.setSubmitting(m, false, errorMessage);
    return c.showInvalidField(m, 'captcha');
  });
  return m;
}

/**
 * Set the captcha value in the fields object before sending the request.
 *
 * @param {Object} m model
 * @param {Object} params
 * @param {Object} fields
 *
 * @returns {Boolean} returns true if is required and missing the response from the user
 */
function setCaptchaParams(m, params, fields) {
  var captchaConfig = l.captcha(m);
  var isCaptchaRequired = captchaConfig && l.captcha(m).get('required');
  if (!isCaptchaRequired) {
    return true;
  }
  var captcha = c.getFieldValue(m, 'captcha');
  //captcha required and missing
  if (!captcha) {
    return false;
  }
  params['captcha'] = captcha;
  fields.push('captcha');
  return true;
}

/**
 * Get a new challenge and display the new captcha image.
 *
 * @param {number} id The id of the Lock instance.
 * @param {boolean} wasInvalid A boolean indicating if the previous captcha was invalid.
 * @param {Function} [next] A callback.
 */
function swapCaptcha(id, wasInvalid, next) {
  return _web_api2.default.getChallenge(id, function (err, newCaptcha) {
    if (!err && newCaptcha) {
      (0, _index3.swap)(_index3.updateEntity, 'lock', id, l.setCaptcha, newCaptcha, wasInvalid);
    }
    if (next) {
      next();
    }
  });
}