import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

import { UpdatesComponent } from './updates.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [CommonModule, BrowserModule, MatDialogModule, FontAwesomeModule, RouterModule, MatButtonModule],
  declarations: [UpdatesComponent],
  exports: [UpdatesComponent],
})
export class UpdatesModule {}
