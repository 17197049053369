import { Component, OnInit } from '@angular/core';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { User } from 'src/app/shared/models/user.model';
import { Chart } from 'chart.js';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';
import { uniqBy } from 'lodash';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  public canvas;
  public ctx: HTMLCanvasElement;
  public logs: any[] = [];
  private user: User;

  constructor(public _rest: RestAPIService, private _auth: AuthService, private confirm: ConfirmationService) {}

  ngOnInit() {
    this._auth.getUser().then(async (user: User) => {
      this.user = user;
      this.setupPage();
    });
  }

  /**
   * acceptTerms
   */
  public acceptTerms() {
    this.confirm
      .createConfirmation(
        'Terms of Service',
        'You must agree with our &nbsp; <a target="_blank" href="/terms-of-service"> Terms of Service </a>  to continue with access to your LSWorks portal',
        'I agree',
        undefined,
        '400px',
        true,
      )
      .then(async () => {
        try {
          this.user.organization.acceptedTerms = true;
          await this._rest.put(
            'organization/self',
            {
              organization: this.user.organization,
            },
            { msg: 'Could not put organization' },
          );
          this.setupPage();
          // eslint-disable-next-line no-empty
        } catch {}
      });
  }

  public setupStripe(stripeURL) {
    this.confirm
      .createConfirmation(
        'Payment Account',
        'In order to receive your payment it is necessary to create a linked account with our partner Stripe',
        'Create Account',
        undefined,
        '400px',
        true,
      )
      .then(async () => {
        window.open(stripeURL, '_self');
      });
  }

  public async setupPage() {
    const { organization: { acceptedTerms = true, isReseller = false, disableStripeWarning = false } = {} } = this.user;

    if (acceptedTerms && isReseller === false) {
      this._loadDashboardData();
    } else if (acceptedTerms) {
      const { hasChargesEnabled } = await this._rest.get('account/checkStripeSetup', {
        msg: 'Could not get account setup.',
      });
      if (hasChargesEnabled) {
        this._loadDashboardData();
      } else {
        if (disableStripeWarning === false) {
          const res: any = await this._rest.post(
            'account/linkStripe',
            {
              baseUrl: window.location.href,
            },
            { msg: 'Could not post account' },
          );
          const stripeURL = res.redirectUrl;
          this.setupStripe(stripeURL);
        }
      }
    } else {
      this.acceptTerms();
    }
  }

  public async _loadDashboardData(): Promise<void> {
    try {
      let ct = this.processLogs(this.logs, 'cognitive therapy progress', 7).data;
      let re = this.processLogs(this.logs, 'reading exercises progress', 7).data;
      ct.forEach((c, i) => {
        ct[i] += re[i];
      });
      this._generateGraph(ct);

      const logs = await this._rest.get('organization/self/logs', {
        msg: 'Could not get organization logs.',
      });

      this.logs = logs;

      ct = this.processLogs(this.logs, 'cognitive therapy progress', 7).data;
      re = this.processLogs(this.logs, 'reading exercises progress', 7).data;
      ct.forEach((c, i) => {
        ct[i] += re[i];
      });
      this._generateGraph(ct);
      // eslint-disable-next-line no-empty
    } catch {}
  }

  public getUniqueLogs(logs, days) {
    const users = logs.map((l) => l.accountId);
    const students = logs.map((l) => l.studentId);
    const day = new Date(Date.now() - 1000 * 60 * 60 * 24 * days);
    return logs.filter((l, index) => {
      const lDate = new Date(l.date);
      return (users.indexOf(l.accountId) === index || students.indexOf(l.studentId) === index) && lDate > day;
    }).length;
  }

  public processLogs(logs, tag, days) {
    const data = [];
    if (days > 0) {
      for (let i = 0; i < days; i += 1) {
        const day = new Date(Date.now() - 1000 * 60 * 60 * 24 * i);
        const lDays = logs.filter((l) => {
          const lDate = new Date(l.date);
          return lDate.getDate() === day.getDate() && lDate.getMonth() === day.getMonth();
        });

        data.push(
          lDays.filter((l) => {
            return l.tag === tag;
          }).length,
        );
      }
    } else {
      const users = logs.map((l) => l.accountId);
      const students = logs.map((l) => l.studentId);
      data.push(
        logs.filter((l, index) => {
          return (users.indexOf(l.accountId) === index || students.indexOf(l.studentId) === index) && l.tag === tag;
        }).length,
      );
    }
    data.reverse();
    const count = data.reduce((prev, d) => prev + d);
    return {
      data,
      count,
    };
  }

  public getActiveUsers = () => {
    const latestLogs = this.logs.filter((l) => {
      return this._hasMinutesPassed(l.date);
    });
    return uniqBy(latestLogs, 'studentId').length;
  };

  private _hasMinutesPassed(time) {
    const date = new Date();
    const date1 = new Date(time);

    const minutes = (date1.getTime() - date.getTime()) / (60 * 1000);

    return minutes > 45 || (minutes < 0 && minutes > -1395);
  }

  public _generateGraph(data): void {
    this.canvas = document.getElementById('DashboardResults');
    this.ctx = this.canvas.getContext('2d');
    const labels = [];
    for (let i = 0; i < data.length; i += 1) {
      const day = new Date(Date.now() - 1000 * 60 * 60 * 24 * i);
      labels.push(day.getMonth() + 1 + '/' + day.getDate() + '/' + day.getFullYear());
    }
    labels.reverse();

    new Chart(this.ctx, {
      type: 'line',
      data: {
        labels, // ["01/01/2019", "01/02/2019", "01/03/2019", "01/04/2019", "01/05/2019", "01/06/2019", "01/07/2019"],
        datasets: [
          {
            data, // [ 2, 3, 12, 6, 11, 11, 7 ],
            backgroundColor: 'transparent',
            borderColor: '#712B91',
            borderWidth: 1,
            lineTension: 0,
          },
        ],
      },
      options: {
        responsive: false,
        legend: { display: false },
        scales: {
          xAxes: [
            {
              gridLines: { display: false },
              ticks: {
                fontColor: '#3A3372',
                fontSize: 10,
              },
            },
          ],
          yAxes: [
            {
              gridLines: { display: false },
              ticks: {
                min: 0,
                max: 20,
                stepSize: 5,
                fontColor: '#3A3372',
                fontSize: 10,
              },
            },
          ],
        },
        animation: {
          duration: 0,
        },
      },
    });
  }
}
