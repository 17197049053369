import { Injectable } from '@angular/core';
import { classic } from 'src/app/shared/consts/themeLabels';
import { RestAPIService } from '../rest/rest-api.service';
import { RoleService } from '../roles/role.service';
import { Theme } from 'src/app/pages/configuration-pages/content-configurations/components/themes/interfaces/themes.interface';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private enabledThemes: Theme[];
  private classicTheme: Theme;

  constructor(private roles: RoleService, private _rest: RestAPIService) {}

  public async getEnabledThemes() {
    if (this.enabledThemes) {
      return this.enabledThemes;
    } else {
      const url = this.roles.isB2C() ? 'themes/getB2cAllowedThemes' : 'themes/findAllowedThemes';

      const themes = await this._rest.get(url, { msg: 'Could not get the enabled themes' });

      if (themes) {
        this.enabledThemes = themes.filter((t: Theme) => t.isEnabled);
        this.classicTheme = themes.find((t: Theme) => t.isDefault && t.variableName === classic);
        return this.enabledThemes;
      } else {
        return [];
      }
    }
  }

  public getClassicTheme() {
    if (!this.classicTheme) {
      this.classicTheme = this.enabledThemes.find((t: Theme) => t.isDefault && t.variableName === classic);
    } else {
      return this.classicTheme;
    }
  }
}
