import { dateToJSDate } from './dateToJSDate.function';
import { isExcelDateSerial } from './isExcelDateSerial.function';
import { Student } from 'src/app/pages/students/interfaces/student.interface';

export function validateStudentImportData(data: Student[]) {
  for (let rowIndex = 0; rowIndex < data.length; rowIndex++) {
    const student = data[rowIndex];

    const giveNameError = validateGivenName(student.givenName, rowIndex);
    if (giveNameError) return giveNameError;

    const birthdateError = validateBirthdate(student.birthdate, rowIndex);
    if (birthdateError) return birthdateError;

    const genderError = validateGender(student.gender, rowIndex);
    if (genderError) return genderError;

    const languageError = validateLanguage(student.language, rowIndex);
    if (languageError) return languageError;
  }

  return null;
}

function validateGivenName(givenName: string | undefined, rowIndex: number) {
  if (!givenName) {
    return { error: `Given name is required at row ${rowIndex + 1}` };
  }
  return null;
}

function validateBirthdate(birthdate: Date | undefined, rowIndex: number) {
  if (!birthdate) {
    return { error: `Birthdate is required at row ${rowIndex + 1}.` };
  }

  const checkIfDateSerial = isExcelDateSerial(birthdate);

  if (checkIfDateSerial) {
    const excelBirthdate = dateToJSDate(Number(birthdate));
    const parts = excelBirthdate.split('/');
    const month = parseInt(parts[0], 10);
    const day = parseInt(parts[1], 10);

    if (month < 1 || month > 12 || day < 1 || day > 31) {
      return { error: `Invalid month, please use MM/DD/YYYY format at row ${rowIndex + 1}.` };
    }

    return null;
  }

  const month = birthdate.getMonth() + 1;
  const day = birthdate.getDate();

  if (month < 1 || month > 12 || day < 1 || day > 31) {
    return { error: `Invalid month, please use MM/DD/YYYY format at row ${rowIndex + 1}.` };
  }

  return null;
}

function validateGender(gender: string | undefined, rowIndex: number) {
  const validGenders = ['Male', 'Female', 'Other', 'M', 'F', 'O'];

  if (gender && !validGenders.includes(gender)) {
    return {
      error: `The gender is invalid, please update for Male, Female or Other at row ${
        rowIndex + 1
      }. Received: ${gender}`,
    };
  }

  return null;
}

function validateLanguage(language: string | undefined, rowIndex: number) {
  if (language && language !== 'en_ca' && language !== 'fr_ca') {
    return {
      error: `The language is invalid, please update for en_ca or fr_ca at row ${rowIndex + 1}. Received: ${language}`,
    };
  }
  return null;
}
