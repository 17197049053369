import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  faCircle,
  faReceipt,
  faMoneyCheckAlt,
  faTimes,
  faCheckCircle,
  faSpinner,
  faStopCircle,
  faCreditCard,
} from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { SubscriptionPaymentsDialogComponent } from '../subscription-payments.dialog/subscription-payments-dialog.component';
import { ConfirmationService } from '../../../services/confirmation/confirmation.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {
  subscriptionMenuOptions,
  getSubscriptionStatusClass,
  getSubscriptionStatus,
} from '../subscription-consts/subscription.consts';
import { RoleService } from 'src/app/services/roles/role.service';
import { get, isEmpty } from 'lodash';
import { faCcAmex, faCcMastercard, faCcVisa } from '@fortawesome/free-brands-svg-icons';
import { AdjustPaymentSettingsComponent } from '../adjust-payment-settings-dialog/adjust-payment-settings-dialog';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-non-reseller-subscriptions',
  templateUrl: './non-reseller-subscription-container.component.html',
  styleUrls: ['./non-reseller-subscription-container.component.scss'],
})
export class RegularSubscriptionComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Output() startLoading = new EventEmitter<boolean>();
  @Input() regularSubscriptions = [];

  public readonly circle: IconDefinition = faCircle;
  public readonly receipt: IconDefinition = faReceipt;
  public readonly changePrice: IconDefinition = faMoneyCheckAlt;
  public readonly cancel: IconDefinition = faTimes;
  public readonly reactivate: IconDefinition = faCheckCircle;
  public readonly loadingIcon: IconDefinition = faSpinner;
  public readonly stopIcon: IconDefinition = faStopCircle;
  public readonly visaIcon: IconDefinition = faCcVisa;
  public readonly masterIcon: IconDefinition = faCcMastercard;
  public readonly amexIcon: IconDefinition = faCcAmex;
  public readonly card: IconDefinition = faCreditCard;

  public readonly subscriptionMenuOptions = subscriptionMenuOptions;

  public cardBrands = [
    { brand: 'visa', icon: this.visaIcon },
    { brand: 'mastercard', icon: this.masterIcon },
    { brand: 'amex', icon: this.amexIcon },
  ];

  displayedColumns: string[] = ['cost', 'tokens', 'status', 'next charge', 'card', 'actions'];

  dataSource: MatTableDataSource<any>;

  public organization;
  public subscriptionForDisplay = [];
  public isOutsider = false;
  public getSubscriptionClass = getSubscriptionStatusClass;
  public getSubscriptionStatus = getSubscriptionStatus;

  constructor(
    private _rest: RestAPIService,
    private _dialog: MatDialog,
    private confirm: ConfirmationService,
    private _snack: MatSnackBar,
    private roleService: RoleService,
    private authService: AuthService,
  ) {}

  async ngOnInit() {
    this.organization = get(this.roleService, 'user.organization') || get(this.authService.getOrgAcc(), 'organization');
    this.isOutsider = this.organization.isOutsider;
    this.findUserSubscriptions();
  }

  public findUserSubscriptions() {
    if (this.regularSubscriptions.length === 0) {
      return;
    }

    this.subscriptionForDisplay = this.regularSubscriptions.map((subscription) => {
      return {
        cost: subscription.plan.amount / 100,
        currency: subscription.plan.currency.toUpperCase(),
        tokens: subscription.metadata.tokens,
        status: subscription.status,
        underCancelation: subscription.cancel_at_period_end,
        id: subscription.id,
        subscriptionItemId: subscription.items.data[0].id,
        currentPeriodEnd: subscription.current_period_end,
        pausedSubscription: subscription.pause_collection ? true : false,
        paymentCard: subscription.paymentCard,
      };
    });

    this.dataSource = new MatTableDataSource(this.subscriptionForDisplay);
    this.dataSource.paginator = this.paginator;
  }

  public async cancelSubscription(subscription) {
    this.confirm
      .createConfirmation('Warning', 'Are you sure you want to cancel your subscription ?', 'Yes', 'No')
      .then(async () => {
        try {
          await this._rest.put('subscriptions/changeSubscriptionStatus', {
            subscription,
            cancel: true,
          });

          this._snack.open('Done! Your subscription has been canceled', 'Close', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
          await this.refreshSubscriptions();
        } catch (error) {
          this._snack.open('Could not cancel this subscription, please contact our support', 'Close', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }
      });
  }

  public async activateSubscription(subscription) {
    this.confirm
      .createConfirmation('Warning', 'Are you sure you want to reactivate your subscription ?', 'Yes', 'No')
      .then(async () => {
        try {
          await this._rest.put(
            'subscriptions/changeSubscriptionStatus',
            {
              subscription,
              cancel: false,
            },
            { msg: 'Could not put subscriptions' },
          );

          this._snack.open('Done! Your subscription has been reactivated', 'Close', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
          await this.refreshSubscriptions();
        } catch (error) {
          this._snack.open('Could not reactivate this subscription', 'Close', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }
      });
  }

  public getDate(date) {
    const correctDate = Number(date + '000');
    const dateFormat = new Date(correctDate);
    return ` ${dateFormat.getDate()} / ${dateFormat.getMonth() + 1} / ${dateFormat.getFullYear()} `;
  }

  public async findSubscriptionInvoices(subscription) {
    if (this.isOutsider) {
      this.startLoading.emit(true);

      const invoices = await this._rest.get('reseller/subscriptions/' + subscription.id + '/invoices', {
        msg: 'Could not get this subscription invoices',
      });

      this._dialog.open(SubscriptionPaymentsDialogComponent, {
        data: invoices,
        panelClass: 'custom-modalbox',
        width: '410px',
      });

      this.startLoading.emit(false);
    } else {
      this.startLoading.emit(true);

      const invoices = await this._rest.get('subscriptions/' + subscription.id + '/invoices', {
        msg: 'Could not get this subscription invoices',
      });

      this._dialog.open(SubscriptionPaymentsDialogComponent, {
        data: invoices,
        panelClass: 'custom-modalbox',
        width: '410px',
      });

      this.startLoading.emit(false);
    }
  }

  public returnSubscriptionSource(subscription) {
    if (!this.isActiveSubscription(subscription)) {
      return '---';
    } else {
      return subscription.paymentCard.last4;
    }
  }

  public isActiveSubscription(subscription): boolean {
    return subscription.status === 'active' && !subscription.pausedSubscription && !isEmpty(subscription.paymentCard);
  }

  public returnCardIcon(subscription) {
    if (!this.isActiveSubscription(subscription)) {
      return;
    } else {
      const brand = subscription.paymentCard.brand;
      const cardBrand = this.cardBrands.find((b) => b.brand.toLowerCase() === brand.toLowerCase());

      return cardBrand.icon;
    }
  }

  public adjustPaymentSettings(subscription) {
    const dialog = this._dialog.open(AdjustPaymentSettingsComponent, {
      width: '600px',
      data: subscription.id,
      panelClass: 'modal-border',
    });

    dialog.afterClosed().subscribe((updatedSubscription) => {
      if (updatedSubscription) {
        this.refreshSubscriptions();
      }
    });
  }

  public async refreshSubscriptions() {
    this.startLoading.emit(true);
    const subscriptions = await this._rest.get('subscriptions/getActiveSubscriptions', {
      msg: 'Could not get subscriptions/getActiveSubscriptions.',
    });

    this.regularSubscriptions = subscriptions.regularSubscriptions || [];
    this.findUserSubscriptions();
    this.startLoading.emit(false);
  }
}
