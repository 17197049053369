import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'users-table-search-input',
  templateUrl: './table-search-input.component.html',
  styleUrls: ['./table-search-input.component.scss'],
})
export class TableSearchInputComponent {
  @Input() placeholder = '';
  @Output() userKeyUp = new EventEmitter();

  public handleKeyUp(event: any) {
    this.userKeyUp.emit(event);
  }
}
