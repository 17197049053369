import { defaultLanguage } from 'src/app/pages/configuration-pages/content-configurations/components/categories/utils/categories-utils';
import { Category, CategoryType } from 'src/app/pages/configuration-pages/interfaces/global-config.interfaces';
import { Course } from 'src/app/pages/configuration-pages/interfaces/global-config.interfaces';

const youtube = 'youtube';

export enum CourseContainerOptions {
  courseInformation = 'Course information',
  lessonInformation = 'Lessons information',
}

export enum UserTypes {
  CLIENTS = 'clients',
  B2C = 'b2c',
  ORGANIZATIONS = 'organizations',
}

export enum QuizTypes {
  oneCorrectAnswer = 'One correct answer',
  oneOrMoreCorrectAnswers = 'One or more correct answers',
}

export enum SurveyTypes {
  oneAnswer = 'One answer',
  oneOrMoreAnswers = 'One or more answers',
}

export enum SectionTypes {
  video = 'video',
  text = 'text',
  quiz = 'quiz',
  slides = 'slides',
}

export const urlHandler = (url: string) => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  const id = match && match[7].length === 11 ? match[7] : false;

  return url.includes(youtube) ? `https://www.youtube.com/embed/${id}?feature=oembed` : url;
};

export const getCourseAsGameCategory = (course: Course) => {
  const gameCategory: Partial<Category> = {
    games: [],
    avaliableLanguages: [defaultLanguage],
    unlockCondition: [],
    img: 'assets/img/Image_Placeholder.jpg',
    lockedImg: 'assets/img/Image_Placeholder.jpg',
    name: course.name,
    description: '',
    title: course.title,
    maxSessions: course.lessons.length,
    mandatory: false,
    courseId: course.id,
    importedFromCourse: true,
    type: CategoryType.VIDEO,
  };

  return gameCategory;
};
