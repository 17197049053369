import { Component } from '@angular/core';
import { IconDefinition, faFlag } from '@fortawesome/free-solid-svg-icons';
import { PublicMessageBannerService } from '../public-message-banner.service';

@Component({
  selector: 'app-public-warning-message-banner',
  templateUrl: './public-warning-message-banner.component.html',
  styleUrls: ['./public-warning-message-banner.component.scss'],
})
export class PublicWarningMessageBannerComponent {
  public text: string;

  public icon: IconDefinition = faFlag;

  constructor(public pmbService: PublicMessageBannerService) {}
}
