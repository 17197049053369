<header>
  <header-logo></header-logo>

  <header-desktop-nav
    *ngIf="(mobile.innerWidth$ | async) > mobile.mobileMenuBreakpoint"
  ></header-desktop-nav>

  <button
    *ngIf="(mobile.innerWidth$ | async) < mobile.mobileMenuBreakpoint"
    (click)="toggle()"
  >
    <fa-icon [icon]="mobileMenuIcon"></fa-icon>
  </button>
</header>
<header-bottom-border></header-bottom-border>
