import { Injectable } from '@angular/core';
import { LoggerService } from '../logger/logger.service';
import { BehaviorSubject } from 'rxjs';
import { initializeGoogleAnalytics } from 'src/scripts/gtag';
import { initializeSentry } from 'src/scripts/sentry';
import { initializeClarity } from 'src/scripts/clarity';
import { initializeLinkedInAnalytics } from 'src/scripts/linkedin';

export const ACCEPTED_COOKIES_PREFERENCE_KEY = 'apGDtzqZkie'; // jumbled name to obscure data from client

export class CookiesConfig {
  hasChosenCookiePreference: boolean;
  load: {
    analytics: boolean;
    clarity: boolean;
    sentry: boolean;
  };
}

@Injectable({
  providedIn: 'root',
})
export class CookiesService {
  // Event Sub
  private show: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public showCookiesDialog$ = this.show.asObservable();

  constructor(private _logger: LoggerService) {
    // Find and load config
    this._findCookiePreference();
  }

  public save(config: CookiesConfig) {
    // convert config obj to string
    const string = JSON.stringify(config);
    // encode string to hide details from user
    const encoded = window.btoa(string);
    // save config in local storage
    localStorage.setItem(ACCEPTED_COOKIES_PREFERENCE_KEY, encoded);
    // load scripts
    this._runScripts(config);
  }

  private _load(config: string) {
    // decode config string
    const decoded = window.atob(config);
    // parse to json
    const preference = this._parseJSON(decoded);
    // load scripts
    this._runScripts(preference);
  }

  private _runScripts(config: CookiesConfig) {
    if (config.hasChosenCookiePreference) {
      if (config.load.analytics) {
        initializeGoogleAnalytics();
        initializeLinkedInAnalytics();
      }
      if (config.load.sentry) {
        initializeSentry();
      }
      if (config.load.clarity) {
        initializeClarity();
      }
    }
  }

  private _findCookiePreference(): void {
    const pref = localStorage.getItem(ACCEPTED_COOKIES_PREFERENCE_KEY);

    if (pref === null) {
      this.show.next(true);
    } else {
      this._load(pref);
    }
  }

  private _parseJSON(pref: string): CookiesConfig | null {
    try {
      return JSON.parse(pref);
    } catch (err) {
      this._logger.error('Could not parse cookies config.', err);
      return null;
    }
  }
}
