export interface Messages {
  data?: Messages;
  id?: string;
  text?: string;
  image?: string;
  courseId?: string;
  isRead?: boolean;
  organizationId?: string;
  allowedOrganizations?: string[];
  allowedUsers?: string[];
  video?: string;
  mediaType?: string;
  createdAt?: string | Date;
  courseName?: string;
}

export interface MessageButton {
  data: Messages;
  type: string;
}

export enum userTypes {
  CLIENTS = 'clients',
  B2C = 'b2c',
  ORGANIZATIONS = 'organizations',
}

export enum allowedOrgsTypes {
  all = 'all',
  manually = 'manually',
}
