<app-main-layout
  [breadcrumbs]="[
    { name: 'Home', url: '/' },
    { name: 'Content configs', url: '/content-configurations' }
  ]"
>
  <div
    *ngIf="loading"
    class="d-flex justify-content-center m-auto align-items-center h-100"
  >
    <mat-spinner></mat-spinner>
  </div>

  <div
    class="mainContainer border"
    *ngIf="!loading"
  >
    <div class="header col-8 mx-auto px-0 d-flex">
      <div
        class="py-2 col-2 text-center"
        (click)="selectedOption = 'themes'"
        [ngClass]="{ selectedOption: selectedOption === 'themes' }"
      >
        <fa-icon [icon]="themes"></fa-icon> <br />
        <span>Themes</span>
      </div>

      <div
        class="py-2 col-2 text-center"
        (click)="selectedOption = 'languages'"
        [ngClass]="{ selectedOption: selectedOption === 'languages' }"
      >
        <fa-icon [icon]="languageIcon"></fa-icon> <br />
        <span>Languages</span>
      </div>

      <div
        class="py-2 col-2 text-center"
        (click)="selectedOption = 'categories'"
        [ngClass]="{ selectedOption: selectedOption === 'categories' }"
      >
        <fa-icon [icon]="categoriesIcon"></fa-icon> <br />
        <span>Game Categories</span>
      </div>
    </div>

    <div
      class="col-9 mx-auto px-0 mt-5"
      *ngIf="selectedOption === 'themes'"
    >
      <app-themes
        [globalThemes]="globalThemes"
        [globalConfigurations]="globalConfigurations"
        [organizationList]="organizationList"
        [languagesList]="languagesList"
        [categoriesList]="categoriesList"
        (reset)="reset()"
      >
      </app-themes>
    </div>

    <div
      class="col-8 mx-auto px-0 mt-5"
      *ngIf="selectedOption === 'languages'"
    >
      <app-languages
        [languagesList]="languagesList"
        (reset)="reset()"
      >
      </app-languages>
    </div>

    <div
      class="col-12 px-0 mt-5"
      *ngIf="selectedOption === 'categories'"
    >
      <app-categories
        [categoryToSelect]="categoryToSelect"
        [categories]="categoriesList"
        [languages]="languagesList"
        (reset)="reset()"
      >
      </app-categories>
    </div>
  </div>
</app-main-layout>
