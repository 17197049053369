import { Component, OnInit, ViewChild } from '@angular/core';
import { StudentsListService } from '../users/menus/clients-menu/students-list/students-list.service';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import {
  Categories,
  ExerciseData,
  Log,
  OrganizationControllerService,
  Progress,
  SchoolStudent,
} from 'src/app/core/openapi';
import moment from 'moment';
import { EXCLUDED_EXERCISES_TYPE, GameTag } from '../program/consts/program-game-consts';
import { get, trim, includes } from 'lodash';
import { GlobalConfigurationHelper } from 'src/app/services/utils/global-configuration-helper';
import { MatDialog } from '@angular/material/dialog';
import { FullSessionResultComponent } from 'src/app/shared/dialogs/full-session-result/full-session-result';
import { uniqBy, orderBy } from 'lodash';
import { Chart } from 'chart.js';
import { faXmark, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firstValueFrom } from 'rxjs';

enum ProgressTypes {
  CT = 'cognitive therapy progress',
  RE = 'reading exercises progress',
}

@Component({
  selector: 'app-school-dashboard',
  templateUrl: './school-dashboard.component.html',
  styleUrl: './school-dashboard.component.scss',
})
export class SchoolDashboardComponent implements OnInit {
  @ViewChild(MatTable) table: MatTable<SchoolStudent>;
  public dataSource: MatTableDataSource<SchoolStudent> = new MatTableDataSource([]);
  public loading = true;
  public categoryArray: Categories[] = [];
  public logs: Log[] = [];
  public canvas;
  public ctx: HTMLCanvasElement;

  public selectedGrade: number;
  public studentData: SchoolStudent[] = [];

  public schoolFilter: string = '';
  public fullnameFilter: string = '';
  public gradeFilter: number;

  public weekDays = 7;

  public readonly clear: IconDefinition = faXmark;

  constructor(
    private studentListService: StudentsListService,
    private globalConfigHelper: GlobalConfigurationHelper,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private organizationController: OrganizationControllerService,
  ) {}

  async ngOnInit() {
    await this._loadDashboardData();
    this.categoryArray = await this.globalConfigHelper.getCategories();
    await this.getStudents();
  }

  public async getStudents(refresh: boolean = false) {
    const students = await this.studentListService.showSchoolStudents(refresh);
    this.studentData = students;
    this.dataSource.data = students;
    this.dataSource.filterPredicate = this.createFilter();

    if (this.table) {
      this.table.renderRows();
    }

    this.loading = false;
    await this.loadGraph();
  }

  public clearGradeFilter() {
    if (!this.gradeFilter) {
      return;
    }
    this.gradeFilter = undefined;
    this.applyFilter();
  }

  public getGrades() {
    const gradeArray = uniqBy(get(this.dataSource, 'data', []).map((s: SchoolStudent) => s.grade));
    return orderBy(gradeArray);
  }

  public getLastSession(student: SchoolStudent): Progress | null {
    const studentProgress = this.getStudentProgress(student);

    if (studentProgress.length === 0) {
      return null;
    }

    const latestSession = studentProgress.reduce((latest: Progress, current: Progress) => {
      return !latest || current.creationTime > latest.creationTime ? current : latest;
    }, null);

    if (!latestSession) {
      return null;
    }

    return latestSession;
  }

  public getLastSessionDate(student: SchoolStudent) {
    const lastSession = this.getLastSession(student);

    if (!lastSession) {
      return '---';
    }

    const creationTime = lastSession.creationTime;

    return get(lastSession, 'session', '') + ' - ' + moment(creationTime).format('YYYY/MM/DD');
  }

  public getLastSessionPoints(student: SchoolStudent) {
    const studentProgress = this.getStudentProgress(student);

    if (studentProgress.length === 0) {
      return '---';
    }

    const latestSession = studentProgress
      .filter((p) => this.isCtProgress(p))
      .reduce((latest: Progress, current: Progress) => {
        return !latest || current.creationTime > latest.creationTime ? current : latest;
      }, null);

    if (!latestSession) {
      return '---';
    }

    const points = this.getTotalScore(latestSession);

    return points;
  }

  public isCtProgress(progress: Progress): boolean {
    return progress.tag === GameTag.CognitiveTherapy30Min || progress.tag === GameTag.CognitiveTherapy;
  }

  public getTotalScore(progress: Progress): string {
    const metadata = progress.metadata;

    if (typeof metadata.score != 'undefined') {
      return metadata.score.toString();
    } else if (metadata.exercises && !metadata.savedGame) {
      const totalScore = metadata.exercises
        .map((e) => e.score)
        .reduce((accumulatedScore: number, exerciseScore: number) => {
          return accumulatedScore + exerciseScore;
        }, 0);

      return totalScore.toString();
    }
  }

  public getStudentProgress(student: SchoolStudent) {
    return get(student, 'progress', []);
  }

  public getScoreChange(student: SchoolStudent) {
    const studentProgress = this.getStudentProgress(student);

    if (studentProgress.length === 0) {
      return '---';
    }

    const ctProgress = studentProgress.filter((p) => this.isCtProgress(p));

    if (ctProgress.length === 1) {
      return '+' + ' ' + this.getTotalScore(ctProgress[0]);
    }

    const lastestCtProgresses = ctProgress.sort(
      (a: Progress, b: Progress) => new Date(b.creationTime).getTime() - new Date(a.creationTime).getTime(),
    );

    const [latestProgress, secondLatestProgress] = lastestCtProgresses;

    const latestScore = Number(this.getTotalScore(latestProgress));
    const secondLatestScore = Number(this.getTotalScore(secondLatestProgress));
    const change = latestScore - secondLatestScore;
    const changeText = change <= 0 ? '' : '+';

    return changeText + ' ' + change;
  }

  public checkScoreChange(score: string): number {
    return Number(trim(score).replace('+', ''));
  }

  public openFullResultChart(student: SchoolStudent) {
    const progress = this.getStudentProgress(student);
    const scoresArray = this.getFullResultsArray(progress);

    this.dialog.open(FullSessionResultComponent, {
      width: '1100px',
      maxHeight: '650px',
      data: {
        scoresArray,
      },
      panelClass: 'modal-border',
    });
  }

  public getFullResultsArray(progress: Progress[]) {
    const fullProgressChart = progress
      .filter((p) => this.isCtProgress(p))
      .map((p) => {
        const filteredExercises = this.getFilteredExercises(p.metadata.exercises);
        const colums = filteredExercises.map(
          (e, index) =>
            this.getExerciseName(e.name, p.tag) +
            (this.exerciseHasDuplicate(e.name, filteredExercises) === true ? index : ''),
        );
        const completeColums = ['session', 'level', ...colums, 'total'];

        const exerciseScores = filteredExercises
          .map((e, index) => {
            const name =
              this.getExerciseName(e.name, p.tag) +
              (this.exerciseHasDuplicate(e.name, filteredExercises) === true ? index : '');
            return {
              [name]: e.score,
              session: p.session,
              total: p.metadata.exercises.reduce((a, b) => a + b.score, 0),
              level: e.level,
            };
          })
          .reduce((a, b) => Object.assign(a, b));

        return {
          colums: completeColums,
          exerciseScores,
        };
      });

    return fullProgressChart;
  }

  public getFilteredExercises(exercises: ExerciseData[]) {
    return exercises.filter((exercise) => includes(EXCLUDED_EXERCISES_TYPE, exercise.name) === false && exercise.name);
  }

  public getExerciseName(name: string, tag: string): string {
    const categories = this.categoryArray;
    const category = categories.find((c) => c.name.toLowerCase() === tag);

    if (!category) {
      return name;
    }

    const gameList = category.games;
    const game = gameList.find((g) => name.includes(g.type));

    return game ? game.type : name;
  }

  public exerciseHasDuplicate(name: string, filteredExercises: ExerciseData[]): boolean {
    const exercise = filteredExercises.filter((e) => e.name === name) || [];

    return exercise.length > 1;
  }

  public processLogs(logs: Log[], tag: string, days: number) {
    const data = [];
    if (days > 0) {
      for (let i = 0; i < days; i += 1) {
        const day = new Date(Date.now() - 1000 * 60 * 60 * 24 * i);
        const lDays = logs.filter((l) => {
          const lDate = new Date(l.date);
          return lDate.getDate() === day.getDate() && lDate.getMonth() === day.getMonth();
        });

        data.push(
          lDays.filter((l) => {
            return l.tag === tag;
          }).length,
        );
      }
    } else {
      const users = logs.map((l) => l.accountId);
      const students = logs.map((l) => l.studentId);
      data.push(
        logs.filter((l, index) => {
          return (users.indexOf(l.accountId) === index || students.indexOf(l.studentId) === index) && l.tag === tag;
        }).length,
      );
    }
    data.reverse();
    const count = data.reduce((prev, d) => prev + d);
    return {
      data,
      count,
    };
  }

  public getActiveUsers = () => {
    const latestLogs = this.logs.filter((l) => {
      return this._hasMinutesPassed(l.date);
    });
    return uniqBy(latestLogs, 'studentId').length;
  };

  private _hasMinutesPassed(time) {
    const date = new Date();
    const date1 = new Date(time);

    const minutes = (date1.getTime() - date.getTime()) / (60 * 1000);

    return minutes > 45 || (minutes < 0 && minutes > -1395);
  }

  public async _loadDashboardData(): Promise<void> {
    try {
      const logs = await firstValueFrom(this.organizationController.organizationControllerReadLogs());

      if (!logs) {
        throw Error('Could not load the dashboard logs, please refresh the page');
      }

      this.logs = logs;
    } catch (error) {
      this.snackbar.open(error.message, 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }
  }

  public async loadGraph() {
    const ct = this.processLogs(this.logs, ProgressTypes.CT, this.weekDays).data;
    const re = this.processLogs(this.logs, ProgressTypes.RE, this.weekDays).data;
    ct.forEach((c, i) => {
      ct[i] += re[i];
    });

    await this._generateGraph(ct);
  }

  public waitForElement(selector: string) {
    return new Promise((resolve, reject) => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelector(selector));
      }

      const observer = new MutationObserver((mutations) => {
        if (document.querySelector(selector)) {
          observer.disconnect();
          resolve(document.querySelector(selector));
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });

      setTimeout(() => {
        observer.disconnect();
        reject();
      }, 5000);
    });
  }

  createFilter() {
    return (data: SchoolStudent, filter: string): boolean => {
      const matchesSchool = this.schoolFilter
        ? data.school.toLowerCase().includes(this.schoolFilter.toLowerCase())
        : true;

      const matchesFullname = this.fullnameFilter
        ? data.fullname.toLowerCase().includes(this.fullnameFilter.toLowerCase())
        : true;

      const matchesGrade = this.gradeFilter ? data.grade?.toString().includes(this.gradeFilter?.toString()) : true;

      return matchesSchool && matchesFullname && matchesGrade;
    };
  }

  applyFilter() {
    this.dataSource.filter = `${this.schoolFilter}${this.fullnameFilter}${this.gradeFilter}`;
  }

  public getChartConfig(data: number[]) {
    const labels = [];
    for (let i = 0; i < data.length; i += 1) {
      const day = new Date(Date.now() - 1000 * 60 * 60 * 24 * i);
      labels.push(day.getMonth() + 1 + '/' + day.getDate() + '/' + day.getFullYear());
    }
    labels.reverse();

    const config = {
      type: 'line',
      data: {
        labels,
        datasets: [
          {
            data,
            backgroundColor: 'transparent',
            borderColor: '#712B91',
            borderWidth: 1,
            lineTension: 0,
          },
        ],
      },
      options: {
        responsive: false,
        legend: { display: false },
        scales: {
          xAxes: [
            {
              gridLines: { display: false },
              ticks: {
                fontColor: '#3A3372',
                fontSize: 10,
              },
            },
          ],
          yAxes: [
            {
              gridLines: { display: false },
              ticks: {
                min: 0,
                max: 20,
                stepSize: 5,
                fontColor: '#3A3372',
                fontSize: 10,
              },
            },
          ],
        },
        animation: {
          duration: 0,
        },
      },
    };

    return config;
  }

  public async _generateGraph(data: number[]) {
    const canvas = await this.waitForElement('#DashboardResults');
    this.canvas = canvas;
    this.ctx = this.canvas.getContext('2d');
    new Chart(this.ctx, this.getChartConfig(data));
  }
}
