import { TeamMember } from '../interfaces/team-member-interface';

export const teamMembers: TeamMember[] = [
  /* Executive */
  {
    image: './assets/img/team/Ingrid.jpg',
    name: 'Ingrid Poupart - Co-founder',
    title: 'Chairperson - Chief Executive Officer',
    id: 'ingrid',
    hasBio: true,
  },
  {
    image: './assets/img/team/Erika_Poupart.png',
    name: 'Erika Poupart - Co-founder',
    title: 'Chief Creative Officer',
    id: 'erika',
    hasBio: true,
  },
  {
    image: './assets/img/team/Chris.jpg',
    name: 'Chris Poupart - Co-founder',
    title: 'Chief Technology Officer',
    id: 'chris',
    hasBio: true,
  },
  {
    image: './assets/img/team/Mike_Andrews.png',
    name: 'Mike Andrews',
    title: 'Business Development/Legal',
    id: 'mike',
    hasBio: true,
  },
  {
    image: './assets/img/team/Indrani_Pal-Chaudhuri.png',
    name: 'Indrani Pal-Chaudhuri',
    title: 'Chief Visionary Officer/Marketing Officer',
    id: 'indrani',
    hasBio: true,
  },
  {
    image: './assets/img/team/Scott_Runte.png',
    name: 'Scott Runte',
    title: 'CEO Ontario Launch Lab/Virtual CFO',
    id: 'scott',
    hasBio: true,
  },

  /* Development */
  {
    image: './assets/img/team/Felipe_Simoes.png',
    name: 'Felipe Simões',
    title: 'Lead Software Developer',
    id: 'felipe',
    hasBio: false,
  },
  {
    image: './assets/img/team/Christian_Boutin.png',
    name: 'Christian Boutin',
    title: 'Senior Software Developer',
    id: 'christian',
    hasBio: true,
  },
  {
    image: './assets/img/team/Mostafa_Itani.png',
    name: 'Mostafa Itani',
    title: 'Software Developer',
    id: 'mostafa',
    hasBio: false,
  },
  {
    image: './assets/img/team/Raphael_Vicarone.png',
    name: 'Raphael Vicarone',
    title: 'Software Developer',
    id: 'raphael',
    hasBio: false,
  },
  {
    image: './assets/img/team/Marlon_Lima.png',
    name: 'Marlon Lima',
    title: 'Software Developer',
    id: 'marlon',
    hasBio: false,
  },
  {
    image: './assets/img/team/Kevin_Andrews.png',
    name: 'Kevin Andrews',
    title: 'Software Developer',
    id: 'kevin',
    hasBio: false,
  },

  /* Admin */
  {
    image: './assets/img/team/Milena.jpg',
    name: 'Milena Cardinal',
    title: 'Lawyer',
    id: 'milena',
    hasBio: false,
  },
  {
    image: './assets/img/team/Patricia_Czarnowske.png',
    name: 'Patricia Czarnowske',
    title: 'Executive Administrator',
    id: 'patricia',
    hasBio: false,
  },
];
