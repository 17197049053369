export const getDefaultSection = (type: string) => {
  const defaultSections = {
    video: {
      content: {
        url: '',
      },
    },
    slides: {
      content: {
        slides: [],
      },
    },
    quiz: {
      content: {
        quizes: [],
      },
    },
    text: {
      content: {
        text: '',
      },
    },
  };

  return defaultSections[type];
};

export const getDefaultLesson = () => {
  const defaultLesson = {
    title: 'New Lesson title',
    sections: [],
  };

  return defaultLesson;
};

export enum CertificateTypes {
  Parent = 'parent',
  Provider = 'provider',
  None = 'none',
}
