<p class="credits-per-month">{{ bundle?.studentsIncluded }} Credit/month</p>

<p class="price">
  <ng-container *ngIf="type === SubscriptionPeriod.yearly">
    {{ bundle?.currency }}$
    <span class="amount">{{ ceil(bundle?.yearlyPrice / 12) }}</span
    >/credit
  </ng-container>
  <ng-container *ngIf="type === SubscriptionPeriod.monthly">
    {{ bundle?.currency }}$ <span class="amount">{{ bundle?.price }}</span
    >/month</ng-container
  >
</p>

<p class="total-credits">{{ bundle?.studentsIncluded * 12 }} credits</p>

<p class="total">
  <ng-container *ngIf="type === SubscriptionPeriod.yearly">
    Billed annually at {{ bundle?.currency }}${{ bundle?.yearlyPrice }}
  </ng-container>
  <ng-container *ngIf="type === SubscriptionPeriod.monthly">
    {{ bundle?.studentsIncluded }} Credit/month for 12 months
  </ng-container>
</p>

<button
  class="buy-now"
  (click)="addToCart(bundle)"
>
  Buy Now
</button>
