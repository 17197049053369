import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StripeTax, StripeCoupon } from 'src/app/shared/interfaces/Stripe.interfaces';

@Injectable({
  providedIn: 'root',
})
export class StripeObservableService {
  private taxSource = new BehaviorSubject<StripeTax>(null);
  currentTax = this.taxSource.asObservable();

  private couponSource = new BehaviorSubject<StripeCoupon>(null);
  currentCoupon = this.couponSource.asObservable();

  constructor() {}

  changeTax(tax: StripeTax) {
    this.taxSource.next(tax);
  }

  changeCoupon(coupon: StripeCoupon) {
    this.couponSource.next(coupon);
  }

  public buildTaxRateData(row: StripeTax) {
    return {
      id: row.id,
      taxType: row.display_name,
      taxCountry: row.country,
      taxState: row.state,
      taxPercentage: row.percentage,
      taxInclusive: row.inclusive,
      taxActive: row.active,
    };
  }

  public buildCouponData(row: StripeCoupon) {
    return {
      id: row.id,
      couponName: row.name,
      couponDuration: row.duration,
      couponPercentageOff: row.percent_off,
      couponAmountOff: row.amount_off,
      couponCurrency: row.currency,
      couponValid: row.valid,
      couponRedeemBy: row.redeem_by,
      couponMaxRedemptions: row.max_redemptions,
      couponMetadata: row.metadata,
    };
  }
}
