export const getCanadianStates = [
  { name: 'All states', acronym: '' },
  { name: 'Alberta', acronym: 'AB' },
  { name: 'British Columbia', acronym: 'BC' },
  { name: 'Manitoba', acronym: 'MB' },
  { name: 'New Brunswick', acronym: 'NB' },
  { name: 'Newfoundland and Labrador', acronym: 'NL' },
  { name: 'Nova Scotia', acronym: 'NS' },
  { name: 'Northwest Territories', acronym: 'NT' },
  { name: 'Nunavut', acronym: 'NU' },
  { name: 'Ontario', acronym: 'ON' },
  { name: 'Prince Edward Island', acronym: 'PE' },
  { name: 'Quebec', acronym: 'QC' },
  { name: 'Saskatchewan', acronym: 'SK' },
  { name: 'Yukon Territory', acronym: 'YT' },
];
