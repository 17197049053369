import { Component, OnInit } from '@angular/core';
import { PricingBundle } from 'src/app/pages/pricing/interfaces/pricing-bundle.interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { GlobalConfigurationHelper } from 'src/app/services/utils/global-configuration-helper';
import { get, isEmpty, sortBy } from 'lodash';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { IconDefinition, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { SubscriptionPeriod } from 'src/app/shared/interfaces/SubscriptionPeriod.enum';

@Component({
  selector: 'pricing-outsider',
  templateUrl: './outsider-pricing.component.html',
  styleUrls: ['./outsider-pricing.component.scss'],
})
export class OutsiderPricingComponent implements OnInit {
  public baseBundles: PricingBundle[] = [
    {
      title: 'Single.',
      price: 1275,
      discount: 0,
      fullPrice: 1275,
      studentsIncluded: 1,
      hiddenPrice: false,
      currency: 'USD',
      order: 0,
      isDefault: true,
      isFirstPurchaseBundle: false,
    },
    {
      title: 'Trio.',
      price: 3300,
      discount: 0,
      fullPrice: 3300,
      studentsIncluded: 3,
      hiddenPrice: false,
      currency: 'USD',
      order: 1,
      isDefault: true,
      isFirstPurchaseBundle: false,
    },
    {
      title: 'Pro.',
      price: 0,
      fullPrice: 0,
      discount: 0,
      hiddenPrice: true,
      message:
        '<span>Please email us at <a href="mailto:aspiremg.ls@gmail.com">aspiremg.ls@gmail.com</a> for special pricing and discounts for educators and mentors.</span>',
      currency: 'USD',
      order: 2,
      isDefault: true,
      isFirstPurchaseBundle: false,
    },
    {
      title: 'First purchase',
      price: 250,
      fullPrice: 250,
      discount: 0,
      currency: 'USD',
      studentsIncluded: 2,
      isFirstPurchaseBundle: true,
      isDefault: true,
      order: 3,
    },
  ];

  activeTab: SubscriptionPeriod = SubscriptionPeriod.monthly;

  bundles: PricingBundle[];

  singleBundle: PricingBundle;

  subscriptionBundles: PricingBundle[];

  basicBundle: PricingBundle;
  mostPopularBundle: PricingBundle;
  bestValueBundle: PricingBundle;

  userIsBuyingForFirstTime = !this._auth.user.firstTokenPurchased;
  firstPurchaseBundle: PricingBundle | null = null;

  public loading = true;
  public readonly spinnerIcon: IconDefinition = faSpinner;

  constructor(
    private _globalConfig: GlobalConfigurationHelper,
    private _auth: AuthService,
    private rest: RestAPIService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadAllBundles();
    this.loadSingleBundle();
    this.loadSubscriptionBundles();
    this.loadfirstPurchaseBundle();
    this.loading = false;
  }

  private async loadAllBundles() {
    this.bundles = await this.getOutsiderBundles();
  }

  private loadSingleBundle() {
    // filter to get the option that is not subscription, even if you receive more than one option , use the first
    this.singleBundle = this.bundles.filter((b) => !b.subscription)[0];
  }

  private loadSubscriptionBundles() {
    // filter to get the options that are subscriptions, and only show three of those options
    this.subscriptionBundles = this.bundles.filter((b) => b.subscription).slice(0, 3);
  }

  private loadfirstPurchaseBundle() {
    // if the user hasn't completed the first purchase, show only the first time purchase bundle
    if (this.userIsBuyingForFirstTime) {
      this.firstPurchaseBundle = this.bundles.filter((b) => b.isFirstPurchaseBundle)[0];

      // if the outsider didnt created a first purchase option use their regular options to prevent a possible wrong first purchase
      if (!this.firstPurchaseBundle) {
        this.userIsBuyingForFirstTime = false;
      }
    }
  }

  handleTabSwitch(tab: string) {
    if (tab === SubscriptionPeriod.yearly) this.activeTab = SubscriptionPeriod.yearly;
    if (tab === SubscriptionPeriod.monthly) this.activeTab = SubscriptionPeriod.monthly;
  }

  async getOutsiderBundles() {
    const regularBundles = await this._globalConfig.getProgramBundles();
    const user = await this._auth.getUser();

    const resellerId: string = get(user, 'belongsTo.id', get(this._auth.getOrgAcc(), 'belongsTo.id'));
    const bundlesResponse = await this.rest.get(`bundle/${resellerId}`);

    if (!bundlesResponse) {
      return regularBundles;
    }

    const { bundles } = bundlesResponse;
    const defaultBundles = bundles.filter((b) => isEmpty(b.belongsTo));
    const outsiderBundles = bundles.filter((b) => b.belongsTo === user.id);

    defaultBundles.forEach((e) => {
      this.baseBundles.splice(e.order, 1, e);
    });

    this.baseBundles = sortBy(this.baseBundles, 'order');

    if (outsiderBundles.length > 0) {
      outsiderBundles.forEach((e) => {
        this.baseBundles.splice(e.order, 1, e);
      });
    }

    this.baseBundles = this.baseBundles.filter((b: PricingBundle) =>
      user.firstTokenPurchased ? !b.isFirstPurchaseBundle : b.isFirstPurchaseBundle,
    );

    return this.baseBundles.filter((b) => !b.hiddenPrice);
  }
}
