<div class="mat-simple-snackbar d-flex errorSnackbar">
  <span class="mat-simple-snack-bar-content">
    <p
      data-cy="simple-snackbar-message"
      *ngFor="let error of errors"
      class="text-center"
    >
      {{ error }}
    </p>
  </span>
  <div class="mat-simple-snackbar-action d-flex ml-2">
    <button
      mat-button=""
      class="mat-focus-indicator mat-button mat-button-base"
      (click)="closeSnackBar()"
    >
      <span
        class="mat-button-wrapper"
        data-cy="simple-snackbar-close"
        >Close</span
      >
      <span
        matripple=""
        class="mat-ripple mat-button-ripple"
        ng-reflect-disabled="false"
        ng-reflect-centered="false"
      ></span>
      <span class="mat-button-focus-overlay"></span>
    </button>
  </div>
</div>
