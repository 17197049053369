import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CanSelectEnabledThemesDirective } from './can-select-enabled-themes.directive';

@NgModule({
  declarations: [CanSelectEnabledThemesDirective],
  imports: [CommonModule],
  exports: [CanSelectEnabledThemesDirective],
})
export class CapabilitiesModule {}
