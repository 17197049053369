<app-main-layout
  [breadcrumbs]="[
    { name: 'Home', url: '/' },
    { name: 'Users', url: '/users' },
    { name: 'Coupon' }
  ]"
>
  <div class="inner-content">
    <h2>Add New Coupon</h2>

    <div class="addCoupon col-12">
      <div class="addClient d-flex p-2">
        <div class="searchUser col-4">
          <input
            #suggestionInput
            type="text"
            placeholder="Type a Client or a Organization name"
            (input)="updateSuggestions($event.target.value)"
          />
          <div class="suggestList col-12">
            <div
              class="suggestRow col-12"
              [ngClass]="{ client: suggest.patron, org: suggest.organization }"
              (click)="
                addUserCoupon(suggest);
                suggestionInput.value = '';
                suggestions = []
              "
              *ngFor="let suggest of suggestions"
            >
              {{ suggest.patron?.givenName || suggest.organization?.name }}
              {{ suggest.patron?.familyName }}
            </div>
          </div>
        </div>
        <div
          class="warning offset-1 col-4"
          *ngIf="usersToCoupon.length === 0"
        >
          <div class="title">WARNING!</div>
          <p>This coupon will be available to all users.</p>
        </div>
      </div>

      <div class="userList">
        <div
          class="userRow d-flex"
          *ngFor="let user of usersToCoupon"
          [ngClass]="{ client: user.patron, org: user.organization }"
        >
          <div class="col-5">
            {{ user.patron?.givenName || user.organization.name }}
            {{ user.patron?.familyName }}
          </div>
          <div
            class="openClose offset-6 col-1"
            (click)="removeUserCoupon(user)"
          >
            <fa-icon [icon]="minusLine"></fa-icon>
          </div>
        </div>
      </div>

      <div class="discount d-flex">
        <div
          class="practice amount col-4"
          (click)="selectedPractice = 'amount'"
          [ngClass]="{ selected: discountAmount }"
        >
          <input
            class="mb-3"
            type="number"
            [(ngModel)]="discountAmount"
            step="0.1"
            min="0"
            (click)="discountPercentage = null"
          />
          <span class="title col-12"
            >Amount ${{ formatPrice(discountAmount) }}</span
          >
        </div>
        <div
          class="practice percentage offset-1 col-4"
          (click)="selectedPractice = 'percentage'"
          [ngClass]="{ selected: discountPercentage }"
        >
          <input
            class="mb-3"
            type="number"
            [(ngModel)]="discountPercentage"
            step="0.1"
            max="100"
            min="0"
            (click)="discountAmount = null"
          />
          <span class="title col-12"
            >Percentage {{ formatPrice(discountPercentage) }}%</span
          >
        </div>
      </div>

      <div class="code row">
        <div class="col-12 d-flex p-0 mt-4 justify-content-center">
          <input
            class="highlight col-4"
            type="text"
            [(ngModel)]="couponCode"
            placeholder="Coupon code"
          />
          <button
            class="offset-1 col-4"
            matTooltip="Click here to generate a new code"
            matTooltipPosition="above"
            (click)="generateCouponCode()"
          >
            Generate
          </button>
        </div>
        <div class="col-12 d-flex mt-4 justify-content-center">
          <div class="col-4 mx-5">
            <input
              class="col-12"
              type="number"
              step="1"
              min="0"
              [(ngModel)]="limitUsage"
              placeholder="Limit usage (0 for infinite)"
              matTooltip="Set the maximum number of uses"
            />
          </div>

          <div class="col-4 d-flex mx-5">
            <input
              class="col-12"
              (click)="expiryDatePicker.open()"
              [matDatepicker]="expiryDatePicker"
              placeholder="Expiry date (blank for no expiry)"
              [(ngModel)]="expiry"
              matTooltip="Set the expiration date"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="expiryDatePicker"
            >
            </mat-datepicker-toggle>
            <mat-datepicker #expiryDatePicker></mat-datepicker>
          </div>
        </div>
      </div>

      <div class="submit mt-5 d-flex">
        <button
          class="bgPrimary mx-5 col-4"
          [disabled]="!isValidCoupon()"
          (click)="createCoupon()"
        >
          Create
        </button>
        <button
          class="bgGrey mx-5 col-4"
          (click)="clearUsersCoupom()"
        >
          Clear
        </button>
      </div>
    </div>

    <h2>Coupons</h2>

    <div class="filterBar col-12">
      <div class="col-12 p-3 d-flex align-items-center justify-content-between">
        <div class="col-8 mx-2">
          <input
            type="text"
            placeholder="Type a name"
            [(ngModel)]="nameFilter"
          />
        </div>
        <div class="col-3 mx-2">
          <mat-checkbox [(ngModel)]="showCouponCodeOnly"
            >Only shows users with coupons</mat-checkbox
          >
        </div>
      </div>

      <div class="userType col-12 p-3 d-flex justify-content-between">
        <button
          (click)="selectedUserType = 'Clients'"
          [ngClass]="{ selected: selectedUserType === 'Clients' }"
          class="type clients col-3"
        >
          Clients
        </button>
        <button
          (click)="selectedUserType = 'Organizations'"
          [ngClass]="{ selected: selectedUserType === 'Organizations' }"
          class="type orgs col-3"
        >
          Organizations
        </button>
        <button
          (click)="selectedUserType = 'Global'"
          [ngClass]="{ selected: selectedUserType === 'Global' }"
          class="type global col-3"
        >
          Global
        </button>
      </div>
    </div>

    <div
      class="userList col-12"
      *ngIf="
        selectedUserType === 'Clients' || selectedUserType === 'Organizations'
      "
    >
      <div
        class="userRow col-12 p-2"
        *ngFor="
          let user of getCouponsList()
            | searchFilter: 'patron.givenName':nameFilter:'organization.name'
        "
        [ngClass]="{ client: user.patron, org: user.organization }"
      >
        <div class="col-12 p-2 d-flex">
          <div class="col-4">
            {{ user.patron?.givenName || user.organization.name }}
            {{ user.patron?.familyName }}
          </div>
          <div class="col-4">
            {{ user?.coupons?.length || 0 }} coupon<span
              *ngIf="user?.coupons?.length > 1"
              >s</span
            >
          </div>
          <div class="openClose offset-3 col-1">
            <fa-icon
              *ngIf="selectedUser !== user.id && user?.coupons?.length > 0"
              (click)="selectedUser = user.id"
              [icon]="plusLine"
              matTooltip="Expand row"
              matTooltipPosition="above"
            ></fa-icon>
            <fa-icon
              *ngIf="selectedUser === user.id"
              (click)="selectedUser = ''"
              [icon]="minusLine"
              matTooltip="Reduce row"
              matTooltipPosition="above"
            ></fa-icon>
          </div>
        </div>

        <div
          class="couponList aCenter col-12"
          *ngIf="selectedUser === user.id"
        >
          <div class="listHeader col-12 d-flex">
            <div class="col-2 p-2">CODE</div>
            <div class="col-2 p-2">DISCOUNT</div>
            <div class="col-2 p-2">USED</div>
            <div class="col-2 p-2">EXPIRATION</div>
            <div class="col-2 p-2">LIMIT</div>
            <div class="col-2 p-2">DELETE</div>
          </div>
          <div
            class="listRow col-12 d-flex"
            *ngFor="let coupon of user.coupons"
          >
            <div class="col-2 p-2">
              {{ coupon.code }}
            </div>
            <div class="col-2 p-2">
              <span *ngIf="coupon.discountAmount"
                >${{ formatPrice(coupon.discountAmount) }}</span
              >
              <span *ngIf="coupon.discountPercentage"
                >{{ formatPrice(coupon.discountPercentage) }}%</span
              >
            </div>
            <div class="col-2 p-2">
              <span *ngIf="coupon.used === 0">Not used</span>
              <span *ngIf="coupon.used > 0"
                >{{ coupon.used }} time<span *ngIf="coupon.used > 1"
                  >s</span
                ></span
              >
            </div>
            <div class="col-2 p-2">
              <span *ngIf="!coupon.expiration">No expiration</span>
              <span *ngIf="coupon.expiration">
                {{ coupon.expiration | date: 'dd/MM/yyyy' }}
              </span>
            </div>
            <div class="col-2 p-2">
              <span *ngIf="coupon.limit === 0">No limit</span>
              <span *ngIf="coupon.limit > 0"
                >{{ coupon.limit }} time<span *ngIf="coupon.limit > 1"
                  >s</span
                ></span
              >
            </div>
            <div class="col-2 p-2">
              <fa-icon
                (click)="deleteCoupon(coupon)"
                [icon]="deletingCoupon === coupon.id ? spinnerIcon : trashIcon"
                [spin]="deletingCoupon === coupon.id"
                matTooltip="Delete coupon"
                matTooltipPosition="above"
              ></fa-icon>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="userList col-12"
      *ngIf="selectedUserType === 'Global'"
    >
      <div
        class="userRow global col-12"
        *ngFor="let coupon of globalCoupons | searchFilter: 'code':nameFilter"
      >
        <div class="col-12 p-2 d-flex">
          <div class="col-4">{{ coupon.code }}</div>
          <div class="col-4">
            used {{ coupon?.usedUsers?.length || 0 }} time<span
              *ngIf="coupon?.usedUsers?.length > 1"
              >s</span
            >
          </div>
          <div class="openClose offset-3 col-1">
            <fa-icon
              *ngIf="selectedUser !== coupon.id"
              (click)="selectedUser = coupon.id"
              [icon]="plusLine"
              matTooltip="Expand row"
              matTooltipPosition="above"
            ></fa-icon>
            <fa-icon
              *ngIf="selectedUser === coupon.id"
              (click)="selectedUser = ''"
              [icon]="minusLine"
              matTooltip="Reduce row"
              matTooltipPosition="above"
            ></fa-icon>
          </div>
        </div>

        <div
          class="couponList aCenter col-12"
          *ngIf="selectedUser === coupon.id"
        >
          <div class="listHeader col-12 d-flex">
            <div class="col-2 p-2">CODE</div>
            <div class="col-2 p-2">DISCOUNT</div>
            <div class="col-2 p-2">USED</div>
            <div class="col-2 p-2">EXPIRATION</div>
            <div class="col-2 p-2">LIMIT</div>
            <div class="col-2 p-2">DELETE</div>
          </div>
          <div class="listRow col-12 d-flex">
            <div class="col-2 p-2">
              {{ coupon.code }}
            </div>
            <div class="col-2 p-2">
              <span *ngIf="coupon.discountAmount"
                >${{ formatPrice(coupon.discountAmount) }}</span
              >
              <span *ngIf="coupon.discountPercentage"
                >{{ formatPrice(coupon.discountPercentage) }}%</span
              >
            </div>
            <div class="col-2 p-2">
              <span>
                {{ coupon?.usedUsers?.length || 0 }} time<span
                  *ngIf="coupon?.usedUsers?.length > 1"
                  >s</span
                ></span
              >
            </div>
            <div class="col-2 p-2">
              <span *ngIf="!coupon.expiration">No expiration</span>
              <span *ngIf="coupon.expiration">
                {{ coupon.expiration | date: 'dd/MM/yyyy' }}
              </span>
            </div>
            <div class="col-2 p-2">
              <span *ngIf="coupon.limit === 0">No limit</span>
              <span *ngIf="coupon.limit > 0"
                >{{ coupon.limit }} time<span *ngIf="coupon.limit > 1"
                  >s</span
                ></span
              >
            </div>
            <div class="col-2 p-2">
              <fa-icon
                (click)="deleteCoupon(coupon)"
                [icon]="deletingCoupon === coupon.id ? spinnerIcon : trashIcon"
                [spin]="deletingCoupon === coupon.id"
                matTooltip="Delete coupon"
                matTooltipPosition="above"
              ></fa-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-main-layout>
