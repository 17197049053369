<div class="table_responsive">
  <span *ngIf="rows && rows.length"
    >Total of {{ rows.length }} {{ rows[0]?.object }} messages</span
  >
  <table>
    <thead>
      <tr>
        <th
          *ngFor="let column of columns"
          (click)="sortTable(column)"
        >
          {{ column | uppercase }}
          <span *ngIf="sortField === column && sortDirection === 'asc'"
            >&#9650;</span
          >
          <span *ngIf="sortField === column && sortDirection === 'desc'"
            >&#9660;</span
          >
        </th>
        <th>ACTIONS</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of rows">
        <ng-container *ngIf="rows.length > 0">
          <td *ngFor="let column of columns">
            {{ getColumnValue(row, column) }}
          </td>
        </ng-container>
        <td>
          <div class="action_btn">
            <messages-table-button
              [icon]="'eye'"
              [tooltip]="'View'"
              [data]="row"
              [type]="'VIEW'"
            ></messages-table-button>
            <messages-table-button
              [icon]="'edit'"
              [tooltip]="'Edit item'"
              [data]="row"
              [type]="'PUT'"
              (updatedMessage)="updateMessage($event)"
            ></messages-table-button>
            <messages-table-button
              [disableHandleClick]="true"
              (click)="deleteMessage(row.id)"
              [icon]="'trash'"
              [tooltip]="'Delete item'"
            ></messages-table-button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
