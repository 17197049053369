import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faMinus, faPlus, faSquare } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { SideMenuService } from '../side-menu.service';

@Component({
  selector: 'app-side-menu-section',
  templateUrl: './side-menu-section.component.html',
  styleUrls: ['./side-menu-section.component.scss'],
})
export class SideMenuSectionComponent {
  @Input() title = '';
  @Input() icon = faSquare;
  @Input() plusIcon = faPlus;
  @Input() minusIcon = faMinus;
  @Input() hidePlusIcon = false;
  @Input() tooltip = '';
  @Input() sectionKey = '';

  @Output() onOpenSection = new EventEmitter();

  isSideMenuOpen = false;
  isDropdownOpen = false;

  private _subs: Subscription[] = [];

  constructor(private _sideMenu: SideMenuService) {}

  ngOnInit() {
    this._subs.push(
      this._sideMenu.preferences$.subscribe((data) => {
        // side menu section needs to know if the side menu is open so it can show full name or just icon
        this.isSideMenuOpen = data.isOpen;

        // toggle section open or closed to maintain user preference
        if (data.sections[this.sectionKey]) {
          this.isDropdownOpen = false;
        } else {
          this.isDropdownOpen = true;
        }
      }),
    );
  }

  ngOnDestroy() {
    this._subs.forEach((sub) => sub.unsubscribe());
    this._subs = [];
  }

  public toggleSection() {
    this._sideMenu.toggleSection(this.title);
  }

  public openSection() {
    this._sideMenu.open();
    this._sideMenu.toggleSection(this.title);
  }
}
