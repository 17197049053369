import { Roles } from 'src/app/shared/consts/roles-const';
import { Document } from './document.model';
import { Languages } from './documents-languages.const';

export const documentList: Document[] = [
  {
    title: "Student's Practice Book (A)",
    thumbnail: './assets/img/documents/neuralign-practice-book.jpg',
    url: 'https://drive.google.com/file/d/1izY2z2-DSME2H6MyHey94jkgY56RdaaV/view',
    lang: Languages.English,
    roles: [Roles.Organization, Roles.Client, Roles.B2C],
  },
  {
    title: 'Neuralign Home: Information/Guidelines for Cognitive Therapy Program',
    thumbnail: './assets/img/documents/ReadLS_Guideline_Instructions_thumb.jpg',
    url: './assets/pdf/Neuralign Home CT, SR and RE, Guideline Instructions.pdf',
    lang: Languages.English,
    roles: [Roles.Client, Roles.B2C],
  },
  {
    title: 'Neuralign Marketing and Branding',
    thumbnail: './assets/img/NeurAlign main logo.png',
    url: 'https://drive.google.com/drive/folders/1XFdI7Oc_uGgD6ZsKt3iVJLCtRu7N6NTS',
    lang: Languages.English,
    roles: [Roles.Organization],
  },
  {
    title: 'Student Practice Book (B)',
    thumbnail: './assets/img/documents/ReadLS_Practice_Book-2-thumb.jpg',
    url: 'https://drive.google.com/file/d/1lKxOocDLgIy7LaSm01y_Qe8j4SIkGw3-/view?usp=drivesdk',
    lang: Languages.English,
    roles: [Roles.Organization],
  },
  {
    title: 'Neuralign Marketing and Branding',
    thumbnail: './assets/img/NeurAlign main logo.png',
    url: 'https://drive.google.com/drive/folders/1XFdI7Oc_uGgD6ZsKt3iVJLCtRu7N6NTS',
    lang: Languages.French,
    roles: [Roles.Organization],
  },
  {
    title: 'Livre D´Exercices',
    thumbnail: './assets/img/documents/Livre_d_exercices_ReadLS-thumb.jpg',
    url: 'https://drive.google.com/file/d/18u_OrSf-6Vu1GgT4m3EBxMHJLSPAt0Ws/view',
    lang: Languages.French,
    roles: [Roles.Organization],
  },

  {
    title: 'Neuralign Marketing and Branding',
    thumbnail: './assets/img/NeurAlign main logo.png',
    url: 'https://drive.google.com/drive/folders/1XFdI7Oc_uGgD6ZsKt3iVJLCtRu7N6NTS',
    lang: Languages.Arabic,
    roles: [Roles.Organization],
  },
];
