<div class="mainContainer h-100">
  <div class="header p-4">Neuralign</div>

  <div class="content">
    Congratulations! <br />
    <br />
    You just completed {{ data.courseName }}!
  </div>

  <div class="buttons d-flex justify-content-center">
    <app-simple-button
      class="mx-1"
      [text]="'Ok'"
      (click)="closeDialog()"
    >
    </app-simple-button>
  </div>
</div>
