<ng-container *ngIf="template === 'unselected'">
  <fa-icon [icon]="eyeIcon"></fa-icon>
  <h3>{{ text | textTransform }}</h3>
</ng-container>

<ng-container *ngIf="template === 'noStudents'">
  <fa-icon [icon]="noDataIcon"></fa-icon>
  <h3>{{ text | textTransform }}</h3>
  <users-add-student-button
    *ngIf="isAddButtonEnabled"
    (onAddStudent)="handleAddStudent()"
  ></users-add-student-button>
</ng-container>

<ng-container *ngIf="template === 'noData'">
  <fa-icon [icon]="emptySetIcon"></fa-icon>
  <h3>{{ text | textTransform }}</h3>
</ng-container>

<ng-container *ngIf="template === undefined">
  %% Select a template for users-empty-state component %%
</ng-container>
