<div>
  <div class="p-4">
    <p>
      Students<br />
      <span>Click on a student to go to the program page</span>
    </p>
  </div>

  <table
    mat-table
    [dataSource]="students"
  >
    <ng-container matColumnDef="name">
      <mat-header-cell
        mat-header-cell
        *matHeaderCellDef
      >
        Name
      </mat-header-cell>
      <mat-cell
        mat-cell
        *matCellDef="let row"
        data-test="student-row"
        class="clickable"
      >
        {{ row.givenName }} {{ row.familyName }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="nickname">
      <mat-header-cell
        mat-header-cell
        *matHeaderCellDef
      >
        Nickname
      </mat-header-cell>
      <mat-cell
        mat-cell
        *matCellDef="let row"
        class="clickable"
      >
        {{ row.nickname }}
      </mat-cell>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="['name', 'nickname']"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: ['name', 'nickname']"
      (click)="resolve(row)"
    ></tr>
  </table>

  <div class="d-flex justify-content-center my-3">
    <app-simple-button
      class="mx-auto"
      [text]="'Close'"
      (click)="close()"
    >
    </app-simple-button>
  </div>
</div>
