import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { get, isEmpty } from 'lodash';
import { IconDefinition, faCamera, faCalendar, faArchive, faSpinner } from '@fortawesome/free-solid-svg-icons';

import { RoleService } from 'src/app/services/roles/role.service';
import { User } from 'src/app/shared/models/user.model';
import { SAVE_MODE } from 'src/app/pages/students/student-profile/calendar-dialog/consts/save-mode';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { Organization, Student } from 'src/app/core/openapi';
import { Languages } from 'src/app/pages/documents/documents-languages.const';
import { Theme } from 'src/app/pages/configuration-pages/content-configurations/components/themes/interfaces/themes.interface';
import { ThemeService } from 'src/app/services/themes/themes.service';
import { CalendarDialogComponent } from 'src/app/pages/students/student-profile/calendar-dialog/calendar.dialog';
import { StudentsListService } from '../../menus/clients-menu/students-list/students-list.service';
import { DataManager, ManagerActions } from 'src/app/shared/interfaces/Manager.interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PortalConfig } from 'src/app/services/auth/auth-consts/auth-consts';

declare let cloudinary: any;

@Component({
  selector: 'student-create-modal',
  templateUrl: './student-create-modal.component.html',
  styleUrls: ['./student-create-modal.component.scss'],
})
export class StudentCreateModalComponent implements OnInit {
  public readonly camera: IconDefinition = faCamera;
  public readonly calendar: IconDefinition = faCalendar;
  public readonly archive: IconDefinition = faArchive;
  public readonly spinnerIcon: IconDefinition = faSpinner;

  public orgAcc: User;
  public org: Organization;

  public archivedStudents: Student[];

  public clientId: string;

  public studentHasTokens = false;
  public student;
  public studentAgenda: any;

  //Form and data properties
  studentForm: Student = {
    fullname: '',
    nickname: '',
    givenName: '',
    theme: '',
    language: '',
    birthdate: '',
    alertOnSessionEnd: false,
    enableHomeAccess: false,
    image: '',
    grade: 1,
  };

  public studentEmail: string = '';
  public languages: Languages[];
  public themes: Theme[] = [];

  public isSchoolSetup = false;
  public loading = false;
  public imagePlaceholder = './assets/img/StudentImagePlaceholder.png';

  readonly cloudinaryConfig = {
    cloud_name: environment.CLOUDINARY_CLOUD_NAME,
    upload_preset: environment.CLOUDINARY_UPLOAD_PRESET,
    secure: true,
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DataManager,
    private _roles: RoleService,
    private _rest: RestAPIService,
    protected _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<StudentCreateModalComponent>,
    private _dialog: MatDialog,
    private themeService: ThemeService,
    private _studentsListService: StudentsListService,
    private authService: AuthService,
  ) {
    this.clientId = data?.clientId;
    if (data?.student) {
      this.student = data.student;
      this.studentEmail = data.student.email;
      const birthdate = data.student.birthdate ? new Date(data.student.birthdate).toISOString().split('T')[0] : '';
      this.studentForm = {
        ...data.student,
        birthdate: birthdate,
      };
    }
  }

  async ngOnInit() {
    this.isSchoolSetup = this.authService.activeConfig === PortalConfig.SCHOOL;
    await this.whoAmI();
    await this.loadThemes();
    await this.loadLanguages();
    if (this.student) {
      await this.findStudentAgenda();
    }
  }

  private async whoAmI() {
    this.orgAcc = this._roles.orgAcc;
    this.org = (await this._rest.get('organization/self', { msg: 'Could not get organization.' })).organization;
  }

  private async loadThemes() {
    this.themes = await this.themeService.getEnabledThemes();
  }

  private async loadLanguages() {
    this.languages = await this._rest.get('languages', {
      msg: 'Could not get languages.',
    });
  }

  async save() {
    switch (this.data?.type) {
      case ManagerActions.CREATE:
        await this.create();
        break;

      case ManagerActions.UPDATE:
        await this.update();
        break;

      default:
        break;
    }
  }

  public async create() {
    try {
      this.loading = true;
      // Ensure we have the user loaded
      await this.authService.getUser();
      const activeConfig = this.authService.activeConfig;
      const currentOrgAcc = this.authService.getOrgAcc();
      const currentOrg = currentOrgAcc.organization;

      const fullNameSplit = this.studentForm.fullname.split(' ');
      const givenName = fullNameSplit.shift();
      const familyName = fullNameSplit.join(' ');

      const student = {
        ...this.studentForm,
        givenName,
        familyName,
      };

      student.birthdate = new Date(student.birthdate).toJSON();

      if (isEmpty(student.image)) {
        student.image = this.imagePlaceholder;
      }

      const creationUrl =
        activeConfig === PortalConfig.SCHOOL
          ? this.getSchoolStudentCreationUrl(currentOrg.id)
          : this.getDefaultStudentCreationUrl();

      const response = await this._rest.post(creationUrl, student);

      if (!response) {
        throw new Error('Student creation failed. Please try again');
      }
      this.handleStudentsRefresh();
      this.close();
      this.openSnackbar('Student created succefully!');
      this.loading = false;
    } catch (error) {
      this.loading = false;
      this.openSnackbar(`${error.message}`);
    }
  }

  public handleStudentsRefresh() {
    if (this.authService.activeConfig === PortalConfig.SCHOOL) {
      this._studentsListService.refreshSchoolList.emit();
    } else {
      this._studentsListService.studentAssociated.emit();
    }
  }

  public shouldDisableSubmitBtn(): boolean {
    if (this.isSchoolSetup) {
      const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      const isValidEmailPattern = this.studentEmail.match(validRegex);

      return !isValidEmailPattern;
    }

    return false;
  }

  public getSchoolStudentCreationUrl(orgId: string) {
    return '/school/id/' + orgId + '/student/' + this.studentEmail + '/create';
  }

  public getDefaultStudentCreationUrl() {
    return 'student/' + (this.clientId || 'self');
  }

  public async update() {
    const studentId = get(this.data, 'student.id', '');

    if (!studentId) {
      this.openSnackbar('Student not found');
      return;
    }

    try {
      const [givenName, familyName = ''] = this.studentForm.fullname.split(' ');
      this.studentForm.givenName = givenName;
      this.studentForm.familyName = familyName;

      const response = await this._rest.put('student/' + studentId, { student: this.studentForm });
      if (!response) {
        throw new Error('Student updated failed');
      }

      this.handleStudentsRefresh();
      this.close();
      this.openSnackbar('Student updated successfully!');
    } catch (error) {
      this.openSnackbar(`Student update failed ${error.message}`);
    }
  }

  public close() {
    this.dialogRef.close(true);
  }

  private openSnackbar(message) {
    this._snackBar.open(message, 'Close', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  public addStudentImage(): void {
    cloudinary.openUploadWidget(this.cloudinaryConfig, (error, result) => {
      if (result) {
        const [imageResponse] = result;
        this.studentForm.image = imageResponse.secure_url || imageResponse.url;
      } else if (error?.message !== 'User closed widget') {
        this.openSnackbar('Error uploading the image');
      }
    });
  }

  public async findStudentAgenda() {
    if (this.student.id) {
      const response = await this._rest.get('student/' + this.student.id, {
        msg: 'Could not get student.',
      });
      if (response) {
        this.studentAgenda = response.student.agenda;
      }
    }
  }

  public async openOptionsDialog() {
    await Promise.resolve();
    const calendarDialog = this._dialog.open(CalendarDialogComponent, {
      data: {
        agenda: this.studentAgenda,
        saveMode: SAVE_MODE.SAVE,
      },
      panelClass: 'modal-border',
    });

    calendarDialog.afterClosed().subscribe((agenda) => {
      if (!agenda) {
        return;
      }

      this.studentAgenda = agenda;
    });
  }

  public checkIfHasSavedAgenda() {
    if (!this.student) {
      return false;
    }

    return this.studentAgenda || this.student.agenda ? true : false;
  }

  public openStudentAgenda() {
    const calendarDialog = this._dialog.open(CalendarDialogComponent, {
      data: {
        agenda: this.studentAgenda,
        saveMode: SAVE_MODE.SAVE,
      },
      panelClass: 'modal-border',
    });

    calendarDialog.afterClosed().subscribe((agenda) => {
      if (!agenda) {
        return;
      }

      this.studentAgenda = agenda;
    });
  }
}
