<lsw-curtain></lsw-curtain>

<app-public-layout>
  <div
    id="Contact"
    class="container py-5 my-5"
  >
    <div class="contact-info">
      <h1>CONTACT US</h1>
      <p class="desc">
        Don't hesitate to send us a message.<br />
        We will be happy to answer your questions.
      </p>
      <div class="info">
        <fa-icon
          class="map"
          [icon]="mapIcon"
        ></fa-icon>
        <p class="info">
          5254 Nine Mile Road<br />
          Martintown ON, Canada<br />
          K0C 2J0
        </p>
      </div>
    </div>

    <div class="contact-form">
      <h2>
        <small>Have any questions?</small><br />
        Send us an email!
      </h2>
      <form [formGroup]="formGroup">
        <mat-form-field>
          <mat-label>Your Name</mat-label>
          <input
            type="text"
            matInput
            required
            formControlName="name"
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Your E-mail</mat-label>
          <input
            type="text"
            matInput
            required
            formControlName="email"
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Your Country</mat-label>
          <input
            type="text"
            matInput
            required
            formControlName="country"
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Subject</mat-label>
          <input
            type="text"
            matInput
            required
            formControlName="subject"
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Message</mat-label>
          <textarea
            matInput
            required
            formControlName="body"
          ></textarea>
        </mat-form-field>

        <button
          type="button"
          (click)="sendMail()"
          [disabled]="!formGroup.valid"
        >
          SUBMIT
        </button>
      </form>
    </div>
  </div>
</app-public-layout>
