import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { get } from 'lodash';
import { AuthService } from '../../auth/auth.service';
import { RegionControllerService } from 'src/app/core/openapi';
import { firstValueFrom } from 'rxjs';
import { RoleService } from '../../roles/role.service';

@Injectable()
export class UsaGuardService {
  constructor(
    private _auth: AuthService,
    private regionService: RegionControllerService,
    private _router: Router,
    private _roles: RoleService,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const user = await this._auth.getUser();
    const org = get(user, 'organization', undefined);
    const usaRegion = await firstValueFrom(this.regionService.regionControllerFindByName('USA'));

    if (!usaRegion || !user || !org) {
      this._router.navigate(['']);
      return false;
    }

    const isUsaOwner: boolean = usaRegion.orgId === org.id;

    // Check if the user is our Portal Admin account
    const isPortalAdmin = this._roles.isPortalOwner();

    if (isPortalAdmin) {
      return true;
    }

    return isUsaOwner;
  }
}
