<section class="awards">
  <br />

  <h1 class="our-technology">Our technology is award winning</h1>

  <div class="award-imgs">
    <div>
      <img
        src="../../../../../assets/img/awards/flourish-prize-finalists.webp"
        width="100%"
      />
    </div>
    <div>
      <img
        src="../../../../../assets/img/awards/mindshare.webp"
        width="100%"
      />
    </div>
    <div>
      <img
        src="../../../../../assets/img/awards/lead-to-win.webp"
        width="100%"
      />
    </div>
    <div>
      <img
        src="../../../../../assets/img/awards/codie-awards.webp"
        width="100%"
      />
    </div>
    <div>
      <img
        src="../../../../../assets/img/awards/ges-awards-finalist.webp"
        width="100%"
      />
    </div>
    <div>
      <img
        src="../../../../../assets/img/awards/tech-edvocate-awards.webp"
        width="100%"
      />
    </div>
  </div>
</section>
