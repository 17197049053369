<div class="bundle-card">
  <form [formGroup]="bundleFormGroup">
    <mat-form-field appearance="outline">
      <mat-label>Title</mat-label>
      <input
        matInput
        required
        placeholder="Title"
        formControlName="title"
      />
    </mat-form-field>

    <div class="d-flex justify-content-between my-2">
      <mat-checkbox
        class="ml-2"
        formControlName="subscription"
      >
        Subscription
      </mat-checkbox>

      <mat-checkbox
        class="ml-2"
        formControlName="isFirstPurchaseBundle"
      >
        First purchase
      </mat-checkbox>
    </div>

    <mat-form-field appearance="outline">
      <mat-label>Price</mat-label>
      <input
        matInput
        type="number"
        required
        placeholder="Price"
        formControlName="price"
      />
    </mat-form-field>

    <mat-form-field
      appearance="outline"
      *ngIf="bundleFormGroup.value.subscription"
    >
      <mat-label>Yearly price ( only for subscriptions )</mat-label>
      <input
        matInput
        type="number"
        min="0"
        required
        placeholder="Yearly Price"
        formControlName="yearlyPrice"
      />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Currency</mat-label>
      <mat-select formControlName="currency">
        <mat-option value="CAD">CAD</mat-option>
        <mat-option value="USD">USD</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Student Per Bundle</mat-label>
      <input
        matInput
        type="number"
        min="1"
        required
        placeholder="Students"
        formControlName="studentsIncluded"
      />
    </mat-form-field>
  </form>
  <div class="save">
    <app-simple-button
      class="mx-1"
      [text]="'SAVE'"
      (click)="save()"
      [disabled]="bundleFormGroup.status === 'INVALID'"
    >
    </app-simple-button>

    <app-simple-button
      class="mx-1"
      [text]="'CANCEL'"
      (click)="closeDialog()"
    >
    </app-simple-button>
  </div>
</div>
