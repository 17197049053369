import { Component, OnInit } from '@angular/core';
import { Target } from './interfaces/target.interface';
import { GameTarget } from './interfaces/game-target.interface';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-take-a-tour',
  templateUrl: './take-a-tour.component.html',
  styleUrls: ['./take-a-tour.component.scss'],
})
export class TakeATourComponent implements OnInit {
  public tourHeaderImage;
  public tourTargetImage;

  public targets: Target[] = [
    {
      iconType: 'circle',
      color: '#8711de',
      title: 'Auditory Processing',
      description: `Auditory processing refers to the way the
      brain recognizes and interprets sounds,
      especially speech. Issues with auditory
      processing can cause problems
      recognizing slight differences between
      sounds in words, even when the sounds
      are loud and clear enough to be heard.
      (1). People with auditory processing
      issues may find it hard to isolate the
      sounds in words, match sounds to letters
      or blend sounds into words. (2).`,
    },
    {
      iconType: 'circle',
      color: '#1160de',
      title: 'Visual Processing',
      description: `Visual processing and visual attention
      refers to the number of distinct visual
      elements (such as letters) that can be
      simultaneously processed in a multi-element array
      (such as on a page of
      text). (3) Studies show that visual
      attention span capacities account for a
      substantial amount of unique variance in
      reading. (4)`,
    },
    {
      iconType: 'circle',
      color: '#11cede',
      title: 'Ocular Motor Control',
      description: `The ability of both eyes to work smoothly
      and efficiently when tracking a moving
      object or looking from place to place. (5)
      The motor neurons controlling the
      muscles have to coordinate to produce
      desired eye movements.(6) The inability
      to do this effectively can lead to
      difficulties with reading, such as loss of
      place, skipping lines, skipping words and
      lack of fluency and speed. (5)`,
    },
    {
      iconType: 'circle',
      color: '#50de11',
      title: 'Visual Closure',
      description: `The ability to identify a whole form or
      object from an incomplete outline or
      representation, or the ability to use
      visual clues in order to identify or
      recognise a symbol or object, when
      it is not visible in its entirety. It helps
      us to quickly process the information
      we receive from our surroundings,
      owing to the fact that our visual
      processing system does not need to
      analyse every single detail in order to
      be able to recognise what we see. (11)`,
    },
    {
      iconType: 'circle',
      color: '#ded611',
      title: 'Executive Function',
      description: `Executive function (EF) is an umbrella
      term that encompasses the set of higher-
      order processes (such as inhibitory
      control, working memory, and attentional
      flexibility) that govern goal-directed action
      and adaptive responses to novel,
      complex, or ambiguous situations. (10)
      Executive function is responsible for:
      paying attention, organizing, planning
      and prioritizing, starting and completing
      tasks, understanding different points of
      view, regulating emotions and self-
      monitoring. (9)`,
    },
    {
      iconType: 'circle',
      color: '#de9f11',
      title: 'Working Memory',
      description: `Also referred to as short-term memory, it
      is comprised of 3 key parts: the Central
      Executive (sends data to the
      subsystems), the Visuospatial Sketchpad
      or inner eye (stores and processes
      information in a visual or spatial form),
      and the Phonological Loop (deals with
      spoken and written material). (12)
      Working memory is a system for
      temporarily storing and managing the
      information required to carry out complex
      cognitive tasks such as learning,
      reasoning, and comprehension. (13)`,
    },
    {
      iconType: 'circle',
      color: '#de1111',
      title: 'Decoding and Encoding',
      description: `“Children are wired for sound,
      but the print is an optional accessory that must
      be painstakingly bolted on.” Steven Pinker, (Wolf, 2008, p 19).
      Literacy is, at its base, the encoding, decoding and
      understanding of language. (14). Decoding of phonemes
      is the root of reading, and encoding of phonemes is
      the root of writing. (24).`,
    },
    {
      iconType: 'circle',
      color: '#de119f',
      title: 'Phonological Awareness',
      description: `There is strong scientific consensus that
      emphasizing print-to-sound relationships
      is critical when learning to read
      alphabetic languages. (22). Phonological
      awareness allows us to attend to,
      discriminate, remember, and manipulate
      sounds at the sentence, word, syllable,
      and phoneme (sound) level. (15)
      Phonological awareness is important as a
      precursor to decoding. (16)(17)(18)`,
    },
    {
      iconType: 'bullseye',
      color: '#8f21df',
      title: 'Morphology | Word construction',
      description: `Morphological awareness is explicitly
      thinking about the smallest units of
      meaning in language, which are called
      morphemes. These units include root
      words that can stand alone as words,
      prefixes, suffixes, and bound roots, which
      are roots that must have a prefix or suffix
      added to become a word. (25)(26)`,
    },
    {
      iconType: 'bullseye',
      color: '#1362de',
      title: 'Timing',
      description: `With timing, We are able to coordinate
      our “looking” and “listening” in order to
      pay attention with both systems
      simultaneously, while also being able to
      use the timing between these sensory
      systems to develop an adequate working
      memory span for the comprehension of
      reading. Inner timing between the senses
      is required in order to truly read with
      fluidity, rhythm, and automaticity. (20)
      (28).`,
    },
    {
      iconType: 'bullseye',
      color: '#17cfde',
      title: 'Visual Sequential Memory',
      description: `The ability to recall and reproduce a
      series of visual stimuli, including
      letters, shapes, numbers, symbols and
      objects, in a specific sequence. Visual
      sequential memory is necessary for
      identifying the ordering of letters in
      words, and words in sentences. It is
      part of the visual decoding process in
      reading and the encoding process in
      spelling. “The eyes look and the brain
      sees.” (7)`,
    },
    {
      iconType: 'bullseye',
      color: '#56df19',
      title: 'Multi-Sensory',
      description: `Multi-sensory activities provide needed
      scaffolding to beginning and struggling
      readers and include visual, auditory,
      kinesthetic, and tactile activities to
      enhance learning and memory. (29)`,
    },
  ];

  public games: GameTarget[] = [
    {
      imageUrl: './assets/img/tour/Bees.png',
      targets: [this.targets[1], this.targets[2], this.targets[4], this.targets[5], this.targets[10]],
    },
    {
      imageUrl: './assets/img/tour/Mosaics.png',
      targets: [this.targets[1], this.targets[2], this.targets[3], this.targets[4], this.targets[5], this.targets[10]],
    },
    {
      imageUrl: './assets/img/tour/Pinball.png',
      targets: [
        this.targets[0],
        this.targets[1],
        this.targets[2],
        this.targets[3],
        this.targets[4],
        this.targets[5],
        this.targets[7],
        this.targets[9],
        this.targets[10],
        this.targets[11],
      ],
    },
    {
      imageUrl: './assets/img/tour/Egypt.png',
      targets: [this.targets[1], this.targets[2], this.targets[3], this.targets[4], this.targets[9], this.targets[10]],
    },
    {
      imageUrl: './assets/img/tour/Scotland spell.png',
      targets: [
        this.targets[0],
        this.targets[1],
        this.targets[2],
        this.targets[3],
        this.targets[4],
        this.targets[5],
        this.targets[6],
        this.targets[7],
        this.targets[9],
        this.targets[10],
        this.targets[11],
      ],
    },
    {
      imageUrl: './assets/img/tour/Sunny Scotland.png',
      targets: [
        this.targets[0],
        this.targets[1],
        this.targets[2],
        this.targets[3],
        this.targets[4],
        this.targets[5],
        this.targets[6],
        this.targets[7],
        this.targets[9],
        this.targets[10],
        this.targets[11],
      ],
    },
    {
      imageUrl: './assets/img/tour/Australia.png',
      targets: [
        this.targets[0],
        this.targets[1],
        this.targets[2],
        this.targets[3],
        this.targets[4],
        this.targets[5],
        this.targets[6],
        this.targets[10],
        this.targets[11],
      ],
    },
    {
      imageUrl: './assets/img/tour/China.png',
      targets: [
        this.targets[0],
        this.targets[1],
        this.targets[2],
        this.targets[3],
        this.targets[4],
        this.targets[5],
        this.targets[7],
        this.targets[10],
        this.targets[11],
      ],
    },
    {
      imageUrl: './assets/img/tour/Holland.png',
      targets: [
        this.targets[1],
        this.targets[2],
        this.targets[3],
        this.targets[4],
        this.targets[5],
        this.targets[6],
        this.targets[7],
        this.targets[8],
        this.targets[9],
        this.targets[10],
        this.targets[11],
      ],
    },
    {
      imageUrl: './assets/img/tour/Pathfinder.png',
      targets: [this.targets[4], this.targets[5], this.targets[6], this.targets[10], this.targets[11]],
    },
    {
      imageUrl: './assets/img/tour/Silly Machine.png',
      targets: [this.targets[4], this.targets[5], this.targets[6], this.targets[10], this.targets[11]],
    },
    {
      imageUrl: './assets/img/tour/Colour Hopper.png',
      targets: [
        this.targets[0],
        this.targets[1],
        this.targets[4],
        this.targets[5],
        this.targets[9],
        this.targets[10],
        this.targets[11],
      ],
    },
    {
      imageUrl: './assets/img/tour/Memory cards.png',
      targets: [this.targets[4], this.targets[5], this.targets[10], this.targets[11]],
    },
    {
      imageUrl: './assets/img/tour/Word Builder.png',
      targets: [
        this.targets[1],
        this.targets[2],
        this.targets[3],
        this.targets[4],
        this.targets[5],
        this.targets[6],
        this.targets[7],
        this.targets[8],
        this.targets[9],
        this.targets[10],
        this.targets[11],
      ],
    },
    {
      imageUrl: './assets/img/tour/Speed Reading.png',
      targets: [
        this.targets[1],
        this.targets[2],
        this.targets[4],
        this.targets[5],
        this.targets[6],
        this.targets[7],
        this.targets[9],
        this.targets[10],
        this.targets[11],
      ],
    },
  ];

  ngOnInit() {
    const assetUrl = 'https://s3.amazonaws.com/press-play-v2/player/';

    window['ppVideos'] = [];

    window['ppVideos'].push({
      videoId: '2127144',
      css: '.pp-video-container { position: relative; padding-bottom: 56.25%; padding-top: 0; height: 0; overflow: hidden; } .pp-video-container iframe { position: absolute; top:0; left: 0; width: 100%; height: 100%;}',
      userId: '2114725',
      playerFrame: null,
      movableVideo: null,
      originalBottom: 0,
      awsurl: 'https://s3.amazonaws.com/press-play-v2/',
      showOverlay: '0',
    });

    this.ppAutoLoad(assetUrl + 'common.js');

    this.tourHeaderImage = environment.PORTAL_CONSTANTS.TAKE_A_TOUR_HEADER_IMAGE;
    this.tourTargetImage = environment.PORTAL_CONSTANTS.TAKE_A_TOUR_TARGET_IMAGE;
  }

  public ppAutoLoad(url) {
    const script = document.createElement('script') as any;
    script.src = url;

    const head = document.getElementsByTagName('head')[0];
    let done = false;

    script.onload = script.onreadystatechange = function () {
      if (!done && (!this.readyState || this.readyState === 'loaded' || this.readyState === 'complete')) {
        done = true;
        script.onload = script.onreadystatechange = null;
        head.removeChild(script);
      }
    };

    head.appendChild(script);
  }
}
