<div mat-dialog-content>
  <span
    class="close"
    (click)="resolve()"
  >
    X
  </span>
  <p>
    Clients<br />
    <span>Click on a client to load its students</span>
  </p>
  <table
    mat-table
    [dataSource]="clients"
  >
    <ng-container matColumnDef="name">
      <th
        mat-header-cell
        *matHeaderCellDef
      >
        Name
      </th>
      <td
        mat-cell
        *matCellDef="let row"
        data-test="client-row"
      >
        {{ row.givenName }} {{ row.familyName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="nickname">
      <th
        mat-header-cell
        *matHeaderCellDef
      >
        Nickname
      </th>
      <td
        mat-cell
        *matCellDef="let row"
      >
        {{ row.nickname }}
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="['name', 'nickname']"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: ['name', 'nickname']"
      (click)="resolve(row)"
    ></tr>
  </table>
</div>
