import { Component, HostListener, Input } from '@angular/core';
import { SectionFEM } from '../../../../services/courses/courses.service.models';
import { LessonsService } from '../../../../services/lessons/lessons.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'section-item',
  templateUrl: './section-item.component.html',
  styleUrls: ['./section-item.component.scss'],
})
export class SectionItemComponent {
  @Input() section: SectionFEM;

  isSelected = false;

  constructor(private _lessons: LessonsService, private _route: ActivatedRoute) {}

  ngOnChanges() {
    this._route.queryParams.subscribe((params) => {
      if (params.section === this.section.id) {
        this.isSelected = true;
      } else {
        this.isSelected = false;
      }
    });
  }

  @HostListener('click')
  handleClick() {
    this._lessons.selectSectionById(this.section.id);
  }
}
