import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CourseProgress } from 'src/app/pages/configuration-pages/interfaces/global-config.interfaces';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';

@Injectable({
  providedIn: 'root',
})
export class ProgressService {
  private userProgressSubject = new BehaviorSubject<CourseProgress[]>({} as CourseProgress[]);

  constructor(private _auth: AuthService, private _rest: RestAPIService) {}

  public loadProgresses(progresses: CourseProgress[]) {
    this.userProgressSubject.next(progresses);
  }

  public getProgresses() {
    return this.userProgressSubject.value;
  }

  public async saveUserProgress(progress: any) {
    try {
      const result = await this._rest.put('courseProgress/update', progress);
      console.log('Progress saved', result);
      return result;
    } catch (error) {
      console.error('Error saving progress', error);
    }
  }

  public async markLessonComplete(lesson: any) {
    const progress = await this.getProgress();

    if (progress) {
      progress.forEach((l) => {
        if (l.id === lesson.id) {
          lesson.completed = true;
        }
      });
    }
  }

  private async getProgress(): Promise<any[]> {
    const user = await this._auth.getUser();
    return user.courseProgresses;
  }
}
