<div class="uploadGameFiles">
  <div
    class="filesToAdd"
    *ngFor="let file of filesToUpload; let index = index"
  >
    {{ formatFileToUpload(file) }}
    <button (click)="notUploadThisOne(index)">DELETE</button>
  </div>
  <input
    #uploadFiles
    type="file"
    (change)="updateFiles($event)"
    multiple
    hidden
  />
  <button (click)="uploadFiles.click()">ADD FILES</button>
  <button (click)="sendFiles(filesToUpload)">SEND</button>
</div>

<div class="readGameFiles">
  <div
    class="gameFilesRow"
    *ngFor="let gameFile of gameFiles"
  >
    <h2>
      {{ gameFile.tag }}
      <span
        *ngIf="!gameFile.show"
        (click)="gameFile.show = true"
        >+</span
      >
      <span
        *ngIf="gameFile.show"
        (click)="gameFile.show = false"
        >-</span
      >
      <button (click)="deleteGameFile(gameFile)">DELTE</button>
    </h2>
    <pre
      *ngIf="gameFile.show"
      [innerHTML]="formatFile(gameFile.file)"
    ></pre>
  </div>
</div>
