<div class="actions">
  <app-simple-button
    *ngIf="!userHasStripeAccount && userHasStripeEnabled"
    text="Adjust Default Price"
    (click)="adjustDefaultPrice()"
  ></app-simple-button>

  <app-simple-button
    *ngIf="!userHasStripeAccount && userHasStripeEnabled"
    text="Create Stripe Account"
    (click)="generateStripeAccount()"
  ></app-simple-button>
</div>

<users-organizations-list></users-organizations-list>
