import { Component } from '@angular/core';
import { TutorialInfo, TutorialPage, TutorialComponent } from 'src/app/shared/models/tutorial.model';
import { Account, User } from 'src/app/shared/models/user.model';
import { Tutorials } from 'src/app/shared/components/tutorial-modal/tutorial-modal.constants';
import { getTutorialInfo } from 'src/app/shared/components/tutorial-modal/tutorial-modal.utils';
import { RoleService } from 'src/app/services/roles/role.service';
import { tutorialList } from './tutorials.utils';
import { Roles } from 'src/app/shared/consts/roles-const';
import { MatDialog } from '@angular/material/dialog';
import { BeforeYouStartComponent } from 'src/app/shared/components/before-you-start/before-you-start.component';

@Component({
  selector: 'app-tutorials',
  templateUrl: './tutorials.component.html',
  styleUrls: ['./tutorials.component.scss'],
})
export class TutorialsComponent {
  public showBeforeYouStart = false;
  public showTutorial = false;
  public tutorial: TutorialComponent;
  public isSingle = true;
  readonly tutorialTypes = Tutorials;
  public user: User;
  public access: Account;

  constructor(private _roles: RoleService, private dialog: MatDialog) {}

  public getTutorialList() {
    return tutorialList[this.getUserType()];
  }

  public getTutorialName(tutorial) {
    const tutorialInfo = getTutorialInfo(tutorial);

    return tutorialInfo.title;
  }

  public getUserType() {
    if (this._roles.isOrgClient()) {
      return Roles.Client;
    } else if (this._roles.isB2C()) {
      return Roles.B2C;
    } else {
      return Roles.OrgOwner;
    }
  }

  public isOrgClient(): boolean {
    return this._roles.isOrgClient();
  }

  public isB2C(): boolean {
    return this._roles.isB2C();
  }

  public isPatron(): boolean {
    return !this.isB2C() && this._roles.isPatron();
  }

  public openWizard(index: Tutorials): void {
    const tutorialInfo = this._setTutorialInfo(index);
    const tutorialPage: TutorialPage = {
      tutorialInfo,
    };
    this.tutorial = new TutorialComponent([tutorialPage]);
    this.showTutorial = true;
  }

  public openFullWizard(): void {
    const pageOne = this.isB2C()
      ? {
          tutorialInfo: [getTutorialInfo(Tutorials.ACTIVE_STUDENTS), getTutorialInfo(Tutorials.ADD_STUDENTS)],
        }
      : {
          tutorialInfo: [getTutorialInfo(Tutorials.ACTIVE_STUDENTS_CLIENT)],
        };
    const pageTwo = this.isB2C()
      ? {
          tutorialInfo: [getTutorialInfo(Tutorials.PROGRAMS), getTutorialInfo(Tutorials.READLS)],
        }
      : {
          tutorialInfo: [getTutorialInfo(Tutorials.READLS_CLIENT)],
        };

    const pageThree = {
      tutorialInfo: [getTutorialInfo(Tutorials.DEMO)],
    };

    const pageFour = {
      tutorialInfo: [getTutorialInfo(Tutorials.MOUSE_TRAINING)],
    };

    const pageFive = this.isB2C()
      ? {
          tutorialInfo: [getTutorialInfo(Tutorials.B2C_TRAINING)],
        }
      : {
          tutorialInfo: [getTutorialInfo(Tutorials.CLIENT_TRAINING)],
        };
    this.tutorial = new TutorialComponent([pageOne, pageTwo, pageThree, pageFour, pageFive]);

    this.isSingle = false;
    this.showTutorial = true;
  }

  private _setTutorialInfo(index: Tutorials): TutorialInfo[] {
    this.isSingle = true;
    return [getTutorialInfo(index)];
  }

  public onCompleteTutorial = () => {
    this.showTutorial = false;
    this.tutorial = null;
  };

  public openBeforeYouStart() {
    this.dialog.open(BeforeYouStartComponent, {
      width: '800px',
      panelClass: 'beforeYouStart',
    });
  }
}
