<div class="col-12 px-0">
  <div class="header">Filter by date</div>

  <div>
    <mat-form-field>
      <mat-select
        [(value)]="selectedOption"
        class="position-relative"
        (selectionChange)="onSelectionChange()"
      >
        <mat-option
          *ngFor="let option of options"
          [value]="option"
          >{{ option }}</mat-option
        >
      </mat-select>
    </mat-form-field>

    <fa-icon
      class="setupArrow"
      [icon]="arrowDown"
    ></fa-icon>

    <div
      class="d-flex justify-content-end daysContainer"
      *ngIf="selectedOption === 'In the last'"
    >
      <mat-form-field class="mx-1">
        <input
          type="number"
          matInput
          [(ngModel)]="timeSelectInput"
        />
      </mat-form-field>

      <mat-form-field class="mx-1">
        <mat-select [(value)]="daysSelectedOption">
          <mat-option
            *ngFor="let option of momentTimeOptions"
            [value]="option"
            >{{ option }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>

    <div
      class="d-flex justify-content-end daysContainer"
      *ngIf="selectedOption === 'Is between'"
    >
      <mat-form-field>
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input
          [formGroup]="datePickerRange"
          [rangePicker]="picker"
        >
          <input
            matStartDate
            formControlName="start"
            placeholder="Start date"
          />
          <input
            matEndDate
            formControlName="end"
            placeholder="End date"
          />
        </mat-date-range-input>
        <mat-hint class="hint">MM/DD/YYYY – MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>

        @if (datePickerRange.controls.start.hasError('matStartDateInvalid')) {
        <mat-error>Invalid start date</mat-error>
        } @if (datePickerRange.controls.end.hasError('matEndDateInvalid')) {
        <mat-error>Invalid end date</mat-error>
        }
      </mat-form-field>
    </div>

    <!-- Filter types Is equal to, Is after and Is before uses a single datepicker -->
    <div
      class="d-flex justify-content-end daysContainer"
      *ngIf="
        selectedOption !== 'Is between' && selectedOption !== 'In the last'
      "
    >
      <mat-form-field>
        <mat-label>Choose a date</mat-label>
        <input
          [(ngModel)]="exactDate"
          matInput
          [matDatepicker]="datepicker"
        />
        <mat-hint class="hint">MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle
          matIconSuffix
          [for]="datepicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #datepicker> </mat-datepicker>
      </mat-form-field>
    </div>

    <div class="button d-flex justify-content-center">
      <app-simple-button
        [disabled]="shouldDisableBtn()"
        (click)="applyFilter()"
        [fullWidth]="true"
        class="mx-auto"
        [text]="'Apply'"
      >
      </app-simple-button>

      <app-simple-button
        [disabled]="!filterIsActive"
        (click)="resetFilter()"
        [fullWidth]="true"
        class="mx-auto"
        [text]="'Clear'"
      >
      </app-simple-button>
    </div>
  </div>
</div>
