import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

interface PromotionalPriceData {
  bodyImage: string;
  basicPrice: number;
  studentsIncluded: number;
  promotionalPrice: number;
}

@Component({
  selector: 'app-promotional-price-dialog',
  templateUrl: './promotional-price.component.html',
  styleUrls: ['./promotional-price.component.scss'],
})
export class PromotionalPriceComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PromotionalPriceData,
    private dialogRef: MatDialogRef<PromotionalPriceComponent>,
    private router: Router,
  ) {}

  ngOnInit() {}

  public calculateDiscount() {
    const threeCreditBasicPrice = this.data.basicPrice * 3;
    const discount = threeCreditBasicPrice - this.data.promotionalPrice;

    const discountPercentage = ((discount / threeCreditBasicPrice) * 100).toFixed(0);

    return discountPercentage + '%';
  }

  public close() {
    this.dialogRef.close();
  }

  public goToPurchasePage() {
    this.router.navigate(['/programs-pricing']);
    this.dialogRef.close();
  }
}
