import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CoursesService } from '../../services/courses/courses.service';
import { LessonsService } from '../../services/lessons/lessons.service';

/**
 * @scope Holds all the components needed for the course overview page.
 */
@Component({
  selector: 'courses-course-overview',
  templateUrl: './course-overview.component.html',
  styleUrls: ['./course-overview.component.scss'],
})
export class CourseOverviewPageComponent implements OnInit {
  course$ = this._courses.currentCourse$;
  lessons$ = this._lessons.lessons$;

  constructor(private _route: ActivatedRoute, private _courses: CoursesService, private _lessons: LessonsService) {}

  ngOnInit(): void {
    this._courses.loadCourseByActivatedRoute(this._route);
  }

  ngAfterContentChecked() {
    // remove section query param for overview page
    const query = window.location.search;
    if (query) {
      window.history.replaceState(null, '', window.location.pathname);
    }
  }
}
