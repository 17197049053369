<ng-container *ngIf="taxes && !addingNewTax">
  <table
    mat-table
    [dataSource]="taxes"
    *ngIf="taxes.length > 0"
    class="col-7 px-0 mx-0"
  >
    <ng-container matColumnDef="state">
      <th
        mat-header-cell
        *matHeaderCellDef
      >
        Province code
      </th>
      <td
        mat-cell
        *matCellDef="let row"
      >
        {{ row.state || '-' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th
        mat-header-cell
        *matHeaderCellDef
      >
        Description
      </th>
      <td
        mat-cell
        *matCellDef="let row"
      >
        {{ row.description }}
      </td>
    </ng-container>

    <ng-container matColumnDef="percentage">
      <th
        mat-header-cell
        *matHeaderCellDef
      >
        %
      </th>
      <td
        mat-cell
        *matCellDef="let row"
      >
        {{ row.percentage }}
      </td>
    </ng-container>

    <ng-container
      matColumnDef="actions"
      class="text-center"
    >
      <th
        mat-header-cell
        *matHeaderCellDef
      ></th>
      <td
        class="text-center"
        mat-cell
        *matCellDef="let row"
      >
        <a
          class="pl-5"
          matTooltip="Archive tax"
          matTooltipPosition="above"
        >
          <fa-icon
            class=""
            [icon]="trash"
            (click)="archiveTax(row.id)"
          ></fa-icon>
        </a>
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="taxesColums"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: taxesColums"
    ></tr>
  </table>
</ng-container>
