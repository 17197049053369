import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { styleElement } from '../../helpers/styleElement.function';

@Directive({
  selector: '[thin]',
})
export class ThinDirective {
  constructor(private el: ElementRef, private _renderer: Renderer2) {}

  ngOnInit() {
    styleElement(this._renderer, this.el, {
      fontWeight: '300',
    });
  }
}
