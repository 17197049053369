<div class="topnav">
  <span>Messages List</span>
  <div class="actions">
    <input
      type="text"
      placeholder="Search message..."
      [(ngModel)]="searchTerm"
      (input)="search()"
    />

    <messages-add-button
      [enable]="'true'"
      (newMessage)="sendNewMessageToDashboard($event)"
    >
    </messages-add-button>
  </div>
</div>
