'use strict';

exports.__esModule = true;
var _propTypes = require('prop-types');
var _propTypes2 = _interopRequireDefault(_propTypes);
var _react = require('react');
var _react2 = _interopRequireDefault(_react);
var _email_pane = require('../../field/email/email_pane');
var _email_pane2 = _interopRequireDefault(_email_pane);
var _username_pane = require('../../field/username/username_pane');
var _username_pane2 = _interopRequireDefault(_username_pane);
var _password_pane = require('../../field/password/password_pane');
var _password_pane2 = _interopRequireDefault(_password_pane);
var _actions = require('./actions');
var _captcha = require('../captcha');
var _index = require('./index');
var _index2 = require('../../core/index');
var l = _interopRequireWildcard(_index2);
var _captcha_pane = require('../../field/captcha/captcha_pane');
var _captcha_pane2 = _interopRequireDefault(_captcha_pane);
var _classic = require('../../engine/classic');
var _enterprise = require('../enterprise');
var _database = require('../database');
function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};
    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key];
      }
    }
    newObj.default = obj;
    return newObj;
  }
}
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}
function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }
  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}
function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}
var LoginPane = function (_React$Component) {
  _inherits(LoginPane, _React$Component);
  function LoginPane() {
    var _temp, _this, _ret;
    _classCallCheck(this, LoginPane);
    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _React$Component.call.apply(_React$Component, [this].concat(args))), _this), _this.handleDontRememberPasswordClick = function (e) {
      e.preventDefault();
      (0, _actions.showResetPasswordActivity)(l.id(_this.props.lock));
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }
  LoginPane.prototype.render = function render() {
    var _props = this.props,
      emailInputPlaceholder = _props.emailInputPlaceholder,
      forgotPasswordAction = _props.forgotPasswordAction,
      i18n = _props.i18n,
      instructions = _props.instructions,
      lock = _props.lock,
      passwordInputPlaceholder = _props.passwordInputPlaceholder,
      showForgotPasswordLink = _props.showForgotPasswordLink,
      showPassword = _props.showPassword,
      usernameInputPlaceholder = _props.usernameInputPlaceholder,
      usernameStyle = _props.usernameStyle;
    var headerText = instructions || null;
    var header = headerText && _react2.default.createElement('p', null, headerText);
    var resolver = l.connectionResolver(lock);
    var sso = (0, _classic.isSSOEnabled)(lock);

    // Should never validate format on login because of custom db connection and import mode.
    // If a custom resolver is in use, always use UsernamePane without validating format,
    // as the target connection (and this validation rules) could change by time the user hits 'submit'.
    var fieldPane = usernameStyle === 'email' && resolver === undefined ? _react2.default.createElement(_email_pane2.default, {
      i18n: i18n,
      lock: lock,
      forceInvalidVisibility: !showPassword,
      placeholder: emailInputPlaceholder,
      strictValidation: false
    }) : _react2.default.createElement(_username_pane2.default, {
      i18n: i18n,
      lock: lock,
      placeholder: usernameInputPlaceholder,
      usernameStyle: usernameStyle,
      validateFormat: false,
      strictValidation: false
    });
    var captchaPane = l.captcha(lock) && l.captcha(lock).get('required') && ((0, _enterprise.isHRDDomain)(lock, (0, _database.databaseUsernameValue)(lock)) || !sso) ? _react2.default.createElement(_captcha_pane2.default, {
      i18n: i18n,
      lock: lock,
      onReload: function onReload() {
        return (0, _captcha.swapCaptcha)(l.id(lock), false);
      }
    }) : null;
    var dontRememberPassword = showForgotPasswordLink && (0, _index.hasScreen)(lock, 'forgotPassword') ? _react2.default.createElement('p', {
      className: 'auth0-lock-alternative'
    }, _react2.default.createElement('a', {
      className: 'auth0-lock-alternative-link',
      href: (0, _index.forgotPasswordLink)(lock, '#'),
      onClick: (0, _index.forgotPasswordLink)(lock) ? undefined : this.handleDontRememberPasswordClick
    }, forgotPasswordAction)) : null;
    return _react2.default.createElement('div', null, header, fieldPane, _react2.default.createElement(_password_pane2.default, {
      i18n: i18n,
      lock: lock,
      placeholder: passwordInputPlaceholder,
      hidden: !showPassword
    }), captchaPane, dontRememberPassword);
  };
  return LoginPane;
}(_react2.default.Component);
exports.default = LoginPane;
LoginPane.propTypes = {
  emailInputPlaceholder: _propTypes2.default.string.isRequired,
  forgotPasswordAction: _propTypes2.default.string.isRequired,
  i18n: _propTypes2.default.object.isRequired,
  instructions: _propTypes2.default.any,
  lock: _propTypes2.default.object.isRequired,
  passwordInputPlaceholder: _propTypes2.default.string.isRequired,
  showForgotPasswordLink: _propTypes2.default.bool.isRequired,
  showPassword: _propTypes2.default.bool.isRequired,
  usernameInputPlaceholder: _propTypes2.default.string.isRequired,
  usernameStyle: _propTypes2.default.oneOf(['any', 'email', 'username'])
};