import { Component } from '@angular/core';
import { Router, Scroll } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { filter } from 'rxjs/operators';

import { data } from './data/data';
import { teamMembers } from './data/team-member';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
})
export class AboutUsComponent {
  data = data;
  teamMembers = teamMembers;

  constructor(private router: Router, private viewportScroller: ViewportScroller) {
    this.router.events.pipe(filter((e) => e instanceof Scroll)).subscribe((e: any) => {
      // Sync this code with the next render
      setTimeout(() => {
        if (e.position) {
          this.viewportScroller.scrollToPosition(e.position);
        } else if (e.anchor) {
          this.viewportScroller.scrollToAnchor(e.anchor);
        } else {
          this.viewportScroller.scrollToPosition([0, 0]);
        }
      });
    });
  }
}
