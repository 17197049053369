<div
  class="col-12 px-0 mainContainer"
  *ngIf="subscription"
>
  <div class="header col-12 px-0 d-flex justify-content-start">
    Subscription
  </div>

  <div class="secondaryHeader mt-3 col-12 px-0 border-bottom">User plan</div>

  <div
    class="subscriptionInfoContainer col-12 px-0 mt-4 d-flex justify-content-between"
  >
    <div>
      <div class="name">
        {{ subscription.plan.metadata.name || 'Subscription' }}
      </div>
      <div class="cost">
        {{ subscription.plan.currency | uppercase }}
        {{ subscription.plan.amount / 100 }} /
        {{ subscription.plan.interval | titlecase }}
      </div>
      <div class="billingPeriod">
        Billing period : {{ subscription.plan.interval | titlecase }}
        <fa-icon
          [icon]="change"
          *ngIf="subscription.status !== 'canceled' && !intervalChangeLoading"
          [matTooltip]="
            subscription.plan.interval === 'day'
              ? 'Change payment to month interval'
              : 'Change payment to day interval'
          "
          matTooltipPosition="above"
          (click)="changeSubscriptionPaymentInterval(subscription)"
        >
        </fa-icon>

        <fa-icon
          [icon]="loading"
          [spin]="true"
          *ngIf="intervalChangeLoading"
        >
        </fa-icon>
      </div>
      <div class="status">
        Status :
        <span
          class="canceled"
          *ngIf="subscription.status === 'canceled'"
        >
          Canceled
        </span>
        <span
          class="underCancelation"
          *ngIf="
            subscription.status !== 'canceled' &&
            subscription.cancel_at_period_end
          "
        >
          Under cancelation
        </span>
        <span
          class="active"
          *ngIf="
            subscription.status !== 'canceled' &&
            !subscription.cancel_at_period_end &&
            !subscription.pause_collection
          "
        >
          Active
        </span>
        <span
          class="paused"
          *ngIf="
            subscription.status !== 'canceled' &&
            !subscription.cancel_at_period_end &&
            subscription.pause_collection
          "
        >
          Paused
        </span>
      </div>
      <div
        class="date"
        *ngIf="
          !subscription.cancel_at_period_end &&
          subscription.status !== 'canceled' &&
          !subscription.pause_collection
        "
      >
        This subscription will renew on
        {{ getDate(subscription.current_period_end) }}
      </div>
      <div
        class="date"
        *ngIf="
          subscription.cancel_at_period_end &&
          subscription.status !== 'canceled' &&
          !subscription.pause_collection
        "
      >
        This subscription will end on
        {{ getDate(subscription.current_period_end) }}
      </div>
      <div
        class="date"
        *ngIf="
          subscription.status === 'canceled' && !subscription.pause_collection
        "
      >
        This subscription was canceled in
        {{ getDate(subscription.canceled_at) }}
      </div>
      <div
        class="date"
        *ngIf="
          subscription.status !== 'canceled' &&
          !subscription.cancel_at_period_end &&
          subscription.pause_collection
        "
      >
        This subscription is paused
      </div>
    </div>
    <div class="d-flex align-items-end justify-content-end">
      <button
        mat-raised-button
        color="warn"
        class="my-2"
        *ngIf="subscription.status !== 'canceled'"
        (click)="deleteSubscription()"
      >
        Delete this subscription
      </button>
    </div>
  </div>
</div>

<div
  class="loading col-12 h-100 px-0 d-flex justify-content-center align-items-center"
  *ngIf="!subscription"
>
  <fa-icon
    [icon]="spinnerIcon"
    [spin]="true"
  ></fa-icon>
</div>
