<div class="system-requirements-dialog">
  <div class="dialog-header">
    <h2>System Requirements</h2>
  </div>

  <div class="dialog-content">
    <div class="requirements-section">
      <h3>Mac Requirements</h3>
      <div class="requirement-item">
        <span class="icon">✔</span>
        <span>Any macOS</span>
      </div>
    </div>

    <div class="requirements-section">
      <h3>PC Desktop Requirements</h3>
      <div class="requirement-item">
        <span class="icon">✔</span>
        <span>i3 and above processor</span>
      </div>
      <div class="requirement-item">
        <span class="icon">✔</span>
        <span>6GB or more RAM</span>
      </div>
      <div class="requirement-item">
        <span class="icon">✔</span>
        <span>Graphics card with 2GB internal memory</span>
      </div>
      <div class="requirement-item">
        <span class="icon">✔</span>
        <span>Must use Firefox browser</span>
      </div>
      <div class="requirement-item">
        <span class="icon">✔</span>
        <span>23" to 31" monitor and TV screens can be used</span>
      </div>
    </div>

    <div class="requirements-section">
      <h3>Additional Information</h3>
      <p class="additional-info">
        Laptops with a larger screen can be used or if your laptop is powerful
        enough then it can be hooked up to a larger screen, but for best results
        a desktop computer is preferred.
      </p>
    </div>
  </div>

  <div
    class="dialog-actions"
    style="text-align: end"
  >
    <button (click)="closeDialog()">Close</button>
  </div>
</div>
