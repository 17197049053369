import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { get } from 'lodash';
import { User } from 'src/app/shared/models/user.model';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IconDefinition, faCamera, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Event } from 'src/app/shared/interfaces/portal.interface';
import { environment } from 'src/environments/environment';
import { animate, style, transition, trigger } from '@angular/animations';
import { PortalConfig } from 'src/app/services/auth/auth-consts/auth-consts';
import { Organization } from 'src/app/core/openapi';
declare let cloudinary: any;

@Component({
  selector: 'app-org-signup-completion.component',
  templateUrl: './org-signup-completion.component.html',
  styleUrls: ['./org-signup-completion.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(0.95)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition(':leave', [animate('300ms ease-in', style({ opacity: 0, transform: 'scale(0.95)' }))]),
    ]),
  ],
})
export class OrganizationSignupComplete implements OnInit {
  //Config for the view form
  public readonly PortalConfig = PortalConfig;
  public isSchool: boolean;
  public showOrganizationTypeContainer = false;
  public showContentContainer = false;
  public isInitialRender = true;

  public loading = true;
  public user: User;
  public updatingOrg = false;
  public readonly spinnerIcon: IconDefinition = faSpinner;
  public readonly camera: IconDefinition = faCamera;
  public allowTermsAgree = false;

  public organizationForm: Organization = {
    name: '',
    phoneNumber: '',
    position: '',
    professionalAssociation: '',
    language: '',
    referredBy: '',
    address: {
      country: '',
      address: '',
      province: '',
      postalCode: '',
    },
    subdomain: '',
    logo: '',
    acceptedTerms: false,
    allowSignup: false,
  };

  readonly cloudinaryConfig = {
    cloud_name: environment.CLOUDINARY_CLOUD_NAME,
    upload_preset: environment.CLOUDINARY_UPLOAD_PRESET,
    secure: true,
  };

  constructor(
    private auth: AuthService,
    private rest: RestAPIService,
    private _snackBar: MatSnackBar,
    private _router: Router,
    private snackbar: MatSnackBar,
  ) {}

  async ngOnInit() {
    this.user = await this.auth.getUser();
    this.loading = false;
    this.showOrganizationTypeContainer = true;
    this.isInitialRender = false;
  }

  public onOrganizationTypeSelected(value: boolean) {
    this.isSchool = value;
    this.showOrganizationTypeContainer = false;

    setTimeout(() => {
      this.showContentContainer = true;
    }, 300);
  }

  public async updateOrganization() {
    try {
      const organization = { ...this.organizationForm, ...(this.isSchool === true ? { isSchool: true } : {}) };
      this.updatingOrg = true;
      this.rest.put('/organization/completeRegistration', organization).then(async (result) => {
        if (!result) {
          this.updatingOrg = false;
          return;
        }

        await this.auth.getUser(true);
        this._router.navigate(['programs-pricing']);
      });
    } catch (error) {
      this.updatingOrg = false;
      this.snackbar.open(error.message, 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }
  }

  onScroll(event: Event) {
    const element = event.target;
    const offSet = 100;
    const height = element.clientHeight;
    const heightToCheck = height + offSet;

    if (element.scrollHeight - element.scrollTop <= heightToCheck) {
      this.allowTermsAgree = true;
    }
  }

  /**
   * Handle Google Autocomplete by populating other address
   * fields when user changes address
   *
   * @param place An object provided by Google that has all the details of a place the user selects.
   */
  handleAddressChange(place) {
    const location = this.setLocation(place);

    this.organizationForm.address.address = `${location.streetNumber} ${location.route}, ${location.locality}`;
    this.organizationForm.address.postalCode = location.postalCode;
    this.organizationForm.address.country = location.country;
    this.organizationForm.address.province = location.province;
  }

  private setLocation(place) {
    return {
      streetNumber: this.getAddressComponent(place, ['street_number']),
      route: this.getAddressComponent(place, ['route']),
      locality: this.getAddressComponent(place, ['locality', 'sublocality', 'neighborhood']),
      postalCode: this.getAddressComponent(place, ['postal_code']),
      country: this.getAddressComponent(place, ['country']),
      province: this.getAddressComponent(place, ['administrative_area_level_1']),
    };
  }

  private getAddressComponent(place, types: string[]) {
    const { address_components } = place;

    for (let i = 0; i < types.length; i++) {
      const result = address_components.find((item) => get(item, 'types').includes(types[i]));
      if (result) {
        return result.long_name;
      }
    }
    return '';
  }

  public fillSubdomain(): void {
    const companyName = this.organizationForm?.name;
    if (companyName && companyName.trim() !== '') {
      this.organizationForm.subdomain = this._getCompanyNameSubdomain(companyName);
    }
  }

  private _getCompanyNameSubdomain(companyName: string): string {
    return companyName
      .toLowerCase()
      .split(' ')
      .join('')
      .replace(/[^a-zA-Z0-9]/g, '');
  }

  public addOrganizationImage(): void {
    cloudinary.openUploadWidget(this.cloudinaryConfig, (error, result) => {
      if (result) {
        const [imageResponse] = result;
        this.organizationForm.logo = imageResponse.secure_url || imageResponse.url;
      } else if (error?.message !== 'User closed widget') {
        this.openSnackbar('Error uploading the image');
      }
    });
  }

  private openSnackbar(message) {
    this._snackBar.open(message, 'Close', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }
}
