import { trigger, transition, style, animate } from '@angular/animations';

// Animation
const VISIBLE = {
  opacity: 1,
  transform: 'translateX(0)',
};
const INVISIBLE = {
  opacity: 0,
  transform: 'translateX(-10px)',
};
const ANIMATION_TIME = '0.3s ease-in-out';

export const validationAnimation = [
  trigger('validate', [
    transition(':enter', [style(INVISIBLE), animate(ANIMATION_TIME, style(VISIBLE))]),
    transition(':leave', [style(VISIBLE), animate(ANIMATION_TIME, style(INVISIBLE))]),
  ]),
];
