import { Component, OnInit } from '@angular/core';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faSignal, faBook, faCheck, faQuestionCircle, faGamepad } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NotificationClass } from 'src/app/shared/classes/notification.class';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { StudentOverrideDialogComponent } from './student-override-dialog/student-override-dialog.component';
import { readLsLevels } from '../../configuration-pages/content-configurations/components/themes/consts/theme.constants';

@Component({
  selector: 'app-student-programs',
  templateUrl: './student-programs.component.html',
  styleUrls: ['./student-programs.component.scss'],
})
export class StudentProgramsComponent extends NotificationClass implements OnInit {
  public isPatron = true;
  public readonly displayedColumns: string[] = ['name', 'actions', 'assessmentLevel', 'allowCompleteAtHome'];
  public readonly graphIcon: IconDefinition = faSignal;
  public readonly bookIcon: IconDefinition = faBook;
  public readonly checkIcon: IconDefinition = faCheck;
  public readonly helpIcon: IconDefinition = faQuestionCircle;
  public readonly gamepadIcon: IconDefinition = faGamepad;
  public programs: any[] = [];
  public progress: any[] = [];
  public tokens: any[] = [];
  public student: any;
  public studentName: string;
  public studentId: string;
  public studentAssessmentLevel = 1;
  public allowCompleteAtHome = false;
  public loading = false;
  public themes: any;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _rest: RestAPIService,
    private _authService: AuthService,
    protected _snackBar: MatSnackBar,
    public dialog: MatDialog,
  ) {
    super(_snackBar);
  }

  async ngOnInit() {
    this.studentName = this._activatedRoute.snapshot.paramMap.get('name');
    this.studentId = this._activatedRoute.snapshot.paramMap.get('studentId');
    await this._loadPrograms(this.studentId);
    await this._loadProgress(this.studentId);
    await this._loadStudent(this.studentId);
    await this.loadThemes();
    this.isPatron = await this._authService.isPatron();
  }

  public getSecondMenuItem() {
    return {
      name: this.isPatron ? 'Students' : 'Users',
      url: this.isPatron ? '/students' : '/users',
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private async _loadPrograms(studentId: string): Promise<void> {
    this.loading = true;

    // const response = await this._rest.get('programs/student/' + studentId, null);
    // TODO: CHANGE TEMPORARY ENDPOINT
    const response = await this._rest.get('programs', { msg: 'Could not get programs.' });
    this.programs = response.programs;

    this.loading = false;
  }

  private async _loadStudent(studentId: string): Promise<void> {
    this.loading = true;
    try {
      // const response = await this._rest.get('programs/student/' + studentId, null);
      // TODO: CHANGE TEMPORARY ENDPOINT
      const response = await this._rest.get('student/' + studentId, {
        msg: 'Could not get student/:studentId.',
      });
      this.student = response.student;
      this.tokens = response.tokens;
      this.tokens
        .filter((t) => t.programs.indexOf(this.programs.find((p) => p.name === 'Neuralign').id) >= 0)
        .forEach((t) => {
          this.allowCompleteAtHome = t.allowCompleteAtHome;
        });
      // eslint-disable-next-line no-empty
    } catch {}
    this.loading = false;
  }

  private async _loadProgress(studentId: string): Promise<void> {
    this.loading = true;
    try {
      const response = await this._rest.get('progress/student/' + studentId, {
        msg: 'Could not get progress/student/:studentId.',
      });
      this.progress = response.progress;

      const assessmentProgress = this.progress.filter((p) => p.tag === 'assessment');

      if (assessmentProgress.length > 1) {
        this.studentAssessmentLevel = assessmentProgress[assessmentProgress.length - 1].metadata.level;
      } else {
        const assessment = this.progress.find((elem) => elem.tag === 'assessment');
        if (assessment) {
          this.studentAssessmentLevel = assessment.metadata.level;
        }
      }
      // eslint-disable-next-line no-empty
    } catch {}
    this.loading = false;
  }

  public async saveAssessmentLevel(): Promise<void> {
    this.loading = true;
    const assessments = this.progress.filter((elem) => elem.tag === 'assessment');

    if (assessments.length === 0) {
      const progress = {
        progress: {
          session: 0,
          metadata: {
            level: this.studentAssessmentLevel,
            score: 0,
          },
          studentId: this.studentId,
          programId: this.programs.find((p) => p.name === 'Neuralign').id,
          tag: 'assessment',
        },
      };

      await this._rest.post('progress', progress, { msg: 'Could not post progress' });
    } else {
      await assessments.forEach(async (assessment) => {
        assessment.metadata.level = this.studentAssessmentLevel;
        return this._rest.put(
          'progress/' + assessment.id,
          {
            progress: assessment,
          },
          { msg: 'Could not put progress' },
        );
      });
    }

    this.loading = false;

    this._snackBar.open(`Level updated!`, 'Close', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  public async saveAllowCompleteAtHome(): Promise<void> {
    this.loading = true;
    const tokens = this.tokens.filter(
      (t) => t.programs.indexOf(this.programs.find((p) => p.name === 'Neuralign').id) >= 0,
    );
    for (const token of tokens) {
      token.allowCompleteAtHome = this.allowCompleteAtHome;
      await this._rest.put('token/' + token.id, { token }, { msg: 'Could not put token.' });
    }
    this.loading = false;
    this._loadStudent(this.studentId);
  }

  public allowedStudentsLevels() {
    const studentTheme = this.themes.find((t) => t.id === this.student.theme);
    const language = this.student.language;

    if (!studentTheme.languages) {
      const level = studentTheme.levels[language].filter((l) => l.enabled);

      return level;
    } else {
      const enabledLanguage = studentTheme.languages.find((l) => l.languageCode === language);
      const enabledLevels = enabledLanguage.enabledLevels.filter((l) => l.enabled);

      return enabledLanguage ? enabledLevels : readLsLevels;
    }
  }

  public async loadThemes() {
    const themes = await this._rest.get('themes/readThemes', { msg: 'Could not get themes/readThemes.' });
    this.themes = themes.filter((t) => t.isEnabled);
  }

  public openDialog() {
    const dialogRef = this.dialog.open(StudentOverrideDialogComponent, {
      width: '400px',
    });
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm === 'true') {
        this.saveAssessmentLevel();
      }
    });
  }
}
