import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HeaderModule } from 'src/app/shared/components/header/header.module';
import { AuthCallbackComponent } from './call-back.component';
import { LayoutComponentsModule } from '../layout/layout-components.module';
import { LayoutsModule } from '../../layouts/layouts.module';
import { CurtainModule } from '../curtain/curtain.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [AuthCallbackComponent],
  imports: [
    BrowserModule,
    RouterModule,
    HeaderModule,
    LayoutComponentsModule,
    LayoutsModule,
    CurtainModule,
    MatProgressSpinnerModule,
  ],
  providers: [],
})
export class AuthCallBackModule {}
