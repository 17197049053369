import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { B2cStudentsListComponent } from './b2c-students-list/b2c-students-list.component';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { AdjustB2CPricesComponent } from '../../components/adjust-b2c-prices/adjust-b2c-prices.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ThemeService } from 'src/app/services/themes/themes.service';
import { SelectEnabledThemes } from '../../components/select-enabled-themes/select-enabled-themes.component';
import { RoleService } from 'src/app/services/roles/role.service';
import { Theme } from 'src/app/pages/configuration-pages/content-configurations/components/themes/interfaces/themes.interface';
import { LoadingService } from 'src/app/services/utils/loading.service';
import { ExcelService } from 'src/app/services/excel/excel.service';
import { get } from 'lodash';

@Component({
  selector: 'users-b2c-menu',
  templateUrl: './b2c-menu.component.html',
  styleUrls: ['./b2c-menu.component.scss'],
})
export class B2cMenuComponent implements OnInit {
  @Input() b2cActivationStatus = false;
  @Input() userHasStripeAccount = false;
  @Input() isLoadingActivationStatusChange = false;

  @Output() toggleB2CSignup = new EventEmitter();
  @Output() setLoading = new EventEmitter();

  public isLoadingExport: boolean = false;

  constructor(
    private _rest: RestAPIService,
    private _auth: AuthService,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _themeService: ThemeService,
    private _roles: RoleService,
    private loading: LoadingService,
    private excel: ExcelService,
  ) {}

  @ViewChild(B2cStudentsListComponent) b2cStudentsComponent: B2cStudentsListComponent;

  ngOnInit(): void {}

  async handleGetStudents(id: string) {
    this.b2cStudentsComponent.getStudents(id);
  }

  async generateStripeAccount() {
    const res: any = await this._rest.post(
      'account/linkStripe',
      {
        baseUrl: window.location.href,
      },
      { msg: 'Could not post account' },
    );
    const stripeURL = res.redirectUrl;
    window.open(stripeURL, '_self');
  }

  async adjustB2CPrice() {
    const user = await this._auth.getUser();
    const dialog = this._dialog.open(AdjustB2CPricesComponent, {
      width: '400px',
      data: {
        price: 1,
        currency: 'USD',
        organization: user.organization,
      },
      panelClass: 'custom-modalbox',
    });

    dialog.afterClosed().subscribe(async (b2cCampaign) => {
      if (b2cCampaign) {
        await this._rest
          .put(
            'organization/self',
            {
              organization: { b2cTokenPrice: b2cCampaign.price, b2cCurrency: b2cCampaign.currency },
            },
            { msg: 'Could not put organization' },
          )
          .then(() =>
            this._snackBar.open('Campaign price updated', 'Close', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
            }),
          );
        this._auth.getUser(true);
      }
    });
  }

  async selectEnabledThemes() {
    const user = this._roles.user;

    // Get all possible themes
    const themes = await this._themeService.getEnabledThemes();

    // Guard against an empty theme object
    if (!themes) return;

    // Get default themes
    const defaultThemes = themes
      .map((theme: Theme) => (theme.isDefault === true ? theme : undefined))
      .filter((theme: Theme) => theme !== undefined);

    // Init theme list
    let b2cEnabledThemes = [];

    // Decide to use default themes, or an organization's custom enabled themes
    if (!user.organization.b2cEnabledThemes) {
      b2cEnabledThemes = defaultThemes;
    } else {
      b2cEnabledThemes = user.organization.b2cEnabledThemes;
    }

    // Open dialog
    const dialog = this._dialog.open(SelectEnabledThemes, {
      width: '450px',
      data: {
        themes,
        b2cEnabledThemes,
        user,
      },
      panelClass: 'custom-modalbox',
    });

    // Handle updating the organization's B2C enabled themes list
    dialog.afterClosed().subscribe(async (b2cEnabledThemes) => {
      if (b2cEnabledThemes) {
        user.organization.b2cEnabledThemes = b2cEnabledThemes;

        await this._rest.put('/organization/update', user.organization);

        this._snackBar.open('Enabled themes updated', 'Close', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }
    });
  }

  public toggleB2C() {
    this.toggleB2CSignup.emit();
  }

  async exportB2C() {
    this.isLoadingExport = true;
    const response = await this._rest.get('organization/self/b2c');
    const b2c = response?.filteredB2C ?? [];

    if (b2c.length === 0) {
      this.setLoading.emit(false);
      this._snackBar.open(`There are no b2c to be exported`, 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
      return;
    }

    const b2cName = b2c.map((b2c) => {
      const b2cName = get('name', '');
      let [name, lastname] = b2c.name.split(' ');
      name = name || b2cName;
      lastname = lastname || '';
      return { name, lastname };
    });

    const formattedB2c = b2c.map((userB2c, index) => ({
      Name: b2cName[index].name,
      'Last Name': b2cName[index].lastname,
      Email: get(userB2c, 'email', ''),
      'Completed the first purchase': userB2c.availableTokens > 1 ? 'Yes' : 'No',
      'Postal / Zip Code': get(userB2c, 'postalCode', ''),
    }));

    const reportName = `B2C-${new Date().toLocaleDateString()}`;
    this.excel.exportAsExcelFile(formattedB2c, reportName);
    this.isLoadingExport = false;
  }
}
