import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CookiesConfig, CookiesService } from 'src/app/services/cookies/cookies.service';

@Component({
  selector: 'app-manage-cookies',
  templateUrl: './manage-cookies.dialog.html',
  styleUrls: ['./manage-cookies.dialog.scss'],
})
export class ManageCookiesDialog implements OnInit {
  form: FormGroup<{ necessaryCookies: FormControl; performanceCookies: FormControl }>;

  constructor(private _cookies: CookiesService) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      necessaryCookies: new FormControl<boolean>(true),
      performanceCookies: new FormControl<boolean>(true),
    });
  }

  public accept() {
    this.save({
      hasChosenCookiePreference: true,
      load: {
        sentry: true,
        clarity: this.form.controls.performanceCookies.value,
        analytics: true,
      },
    });
  }

  public reject() {
    this.save({
      hasChosenCookiePreference: true,
      load: {
        analytics: true,
        clarity: false,
        sentry: true,
      },
    });
  }

  private save(consent: CookiesConfig) {
    this._cookies.save(consent);
  }
}
