<div class="col-12 px-0 mainContainer">
  <div class="header col-12 p-4">Neuralign</div>

  <div class="message">
    Your session will expire in {{ getTimeToExpire() }}. Do you want to stay
    logged in?
  </div>

  <div class="col-12 px-0 d-flex justify-content-around">
    <app-simple-button
      class="mx-1"
      [text]="'Yes'"
      (click)="close(true)"
    >
    </app-simple-button>
    <app-simple-button
      class="mx-1"
      [text]="'Logout'"
      (click)="close(false)"
    >
    </app-simple-button>
  </div>
</div>
