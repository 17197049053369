import { Injectable } from '@angular/core';

/**
 * This service determines whether or not a 'privacy screen' should be displayed on the users page, so that sensitive student and client data are not visible.
 */
@Injectable({
  providedIn: 'root',
})
export class PrivacyScreenService {
  public showPrivacyScreen = true;

  public turnOff(): void {
    this.showPrivacyScreen = false;
  }

  public turnOn(): void {
    this.showPrivacyScreen = true;
  }
}
