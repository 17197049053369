import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IconDefinition, faCircleXmark } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-tutorial-modal',
  templateUrl: './tutorial-modal.component.html',
  styleUrls: ['./tutorial-modal.component.scss'],
})
export class TutorialModalComponent implements OnInit {
  public readonly closeIcon: IconDefinition = faCircleXmark;

  constructor(@Inject(MAT_DIALOG_DATA) public data: string, public dialogRef: MatDialogRef<TutorialModalComponent>) {}

  ngOnInit(): void {
    this.dialogRef.updateSize('auto', 'auto'); // get the dialog container to automatically size to it's child content
  }

  public closeModal() {
    this.dialogRef.close();
  }
}
