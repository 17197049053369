import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { RestAPIService } from '../../services/rest/rest-api.service';
import { MaterialComponentsModule } from 'src/app/shared/modules/material-components.module';
import { DashboardComponent } from './dashboard.component';
import { LayoutsModule } from 'src/app/shared/layouts/layouts.module';

@NgModule({
  declarations: [DashboardComponent],
  imports: [BrowserModule, RouterModule, HttpClientModule, MaterialComponentsModule, LayoutsModule],
  providers: [RestAPIService],
})
export class DashboardModule {}
