import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'card-price',
  templateUrl: './card-price.component.html',
  styleUrls: ['./card-price.component.scss'],
})
export class CardPriceComponent implements OnInit {
  @Input() currency: string;
  @Input() price: number;
  @Input() fullPrice: number;
  @Input() discount: number;
  @Input() isSubscription: boolean;

  constructor() {}

  ngOnInit(): void {}
}
