<header>
  <!-- Icon -->
  <div class="icon">
    <fa-icon
      [icon]="icon"
      (click)="openSection()"
      [matTooltip]="!isSideMenuOpen ? tooltip : ''"
      [matTooltipPosition]="'right'"
    ></fa-icon>
  </div>

  <!-- Title -->
  <span
    class="title"
    (click)="openSection()"
    *ngIf="isSideMenuOpen"
    >{{ title }}</span
  >

  <!-- Toggle Section -->
  <div *ngIf="!hidePlusIcon">
    <div *ngIf="isSideMenuOpen">
      <fa-icon
        *ngIf="!isDropdownOpen"
        [icon]="plusIcon"
        class="toggle"
        (click)="toggleSection()"
      ></fa-icon>
      <fa-icon
        *ngIf="isDropdownOpen"
        [icon]="minusIcon"
        class="toggle"
        (click)="toggleSection()"
      ></fa-icon>
    </div>
  </div>
</header>

<!-- Link Container -->
<div
  class="links"
  *ngIf="isSideMenuOpen && isDropdownOpen"
>
  <ng-content *ngIf="isDropdownOpen"></ng-content>
</div>
