<section class="learning-pyramid">
  <h2
    montserrat
    thin
    largeSize
    centerText
  >
    Neuralign builds a strong foundation that supports reading instruction.
  </h2>

  <div>
    <img
      src="assets/img/slides/learning_pyramid.png"
      width="100%"
    />
  </div>
</section>

<section #einstein>
  <div #text>
    <p
      montserrat
      thin
      mediumSize
      white
    >
      Dyslexics often excel in outside-the-box <br />
      creative and critical thinking. Some of the <br />
      world's greatest celebrities, leaders, artists,<br />
      and entrepreneurs are Dyslexic.³
    </p>
    <p
      montserrat
      thin
      mediumSize
      white
    >
      With the right approach and support, you can<br />
      thrive!
    </p>
  </div>

  <div>
    <img
      src="assets/img/slides/einstein.jpg"
      alt=""
      width="100%"
    />
  </div>
</section>

<div #brain>
  <img
    src="assets/img/brains/colourful-brain-left.webp"
    alt=""
    width="100%"
  />
</div>
