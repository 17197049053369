<div class="mainContainer col-12 px-0">
  <div class="header col-12 p-4 text-center">Subscription info</div>

  <div class="subscriptionInfo mt-3">
    <mat-form-field appearance="outline">
      <mat-label>Price</mat-label>
      <input
        [(ngModel)]="price"
        matInput
        type="number"
        placeholder="Price"
      />
    </mat-form-field>
    <br />

    <mat-form-field appearance="outline">
      <mat-label>Students Included</mat-label>
      <input
        [(ngModel)]="studentsIncluded"
        matInput
        type="number"
        placeholder="Students Included"
      />
    </mat-form-field>
    <br />

    <app-simple-button
      [text]="'SAVE'"
      (click)="save()"
      [disabled]="!checkIfCanSubmit()"
    ></app-simple-button>
  </div>
</div>
