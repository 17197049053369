<lsw-curtain></lsw-curtain>

<app-public-layout>
  <home-hero></home-hero>
  <home-neuralign-def></home-neuralign-def>
  <home-demo></home-demo>
  <home-quotes></home-quotes>
  <home-report></home-report>
  <home-dyslexia-def></home-dyslexia-def>
  <home-awards></home-awards>
  <home-news></home-news>
</app-public-layout>
