<div class="video-wrapper">
  <iframe
    *ngIf="content.url"
    width="100%"
    [src]="getVideoUrl() | safeUrl"
    allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  >
  </iframe>
</div>
