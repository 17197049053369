<lsw-curtain></lsw-curtain>

<app-main-layout
  [breadcrumbs]="[
    { name: 'Home', url: '/' },
    { name: 'Programs', url: '/programs' }
  ]"
>
  <ng-container [ngSwitch]="show">
    <ng-container *ngSwitchCase="'regular'">
      <pricing-regular-org></pricing-regular-org>
    </ng-container>
    <ng-container *ngSwitchCase="'b2c'">
      <pricing-b2c></pricing-b2c>
    </ng-container>
    <ng-container *ngSwitchCase="'outsider'">
      <pricing-outsider></pricing-outsider>
    </ng-container>
  </ng-container>

  <app-awards></app-awards>
  <hr class="hr" />
  <app-what-you-need></app-what-you-need>
</app-main-layout>
