import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BeforeYouStartComponent } from './before-you-start.component';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { BreadcrumbModule } from 'src/app/shared/components/breadcrumb/breadcrumb.module';
import { UserSideMenuModule } from 'src/app/shared/components/user-side-menu/user-side-menu.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialComponentsModule } from 'src/app/shared/modules/material-components.module';
import { LayoutComponentsModule } from 'src/app/shared/components/layout/layout-components.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HeaderModule } from '../header/header.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [BeforeYouStartComponent],
  imports: [
    BrowserModule,
    RouterModule,
    HttpClientModule,
    HeaderModule,
    BreadcrumbModule,
    UserSideMenuModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialComponentsModule,
    LayoutComponentsModule,
    FontAwesomeModule,
    TranslateModule.forChild(),
    MatDialogModule,
    MaterialComponentsModule,
  ],
  exports: [BeforeYouStartComponent],
})
export class BeforeYouStartModule {}
