import { User } from '../models';

export interface Invoice {
  paid: boolean;
  status: string;
}

export interface StripeInvoice {}

export interface PaymentPlan {
  cost: number;
  currency: string;
  id: string;
  subscriptionItemId: string;
  currentPeriodEnd: number;
  lastInvoice: Invoice;
  user: User;
  endDate: number;
  startDate: number;
  completedPayments: number;
  interval: string;
  studentsIncluded: number;
  card: StripeCard;
}

export interface StripeCard {
  brand: string;
  last4: string;
}

export interface StripeEvent {
  length: number;
  pageIndex: number;
  pageSize: number;
  previousPageIndex: number;
}

export interface StripeTax {
  id?: string;
  active?: boolean;
  country?: string;
  created?: number;
  description?: string;
  display_name?: string;
  efective_percentage?: number;
  inclusive?: boolean;
  jurisdiction?: string;
  jurisdiction_level?: null;
  metadata?: unknown;
  percentage?: number;
  state?: string;
  tax_type?: string;
  object?: string;
}
export interface StripeRedirect {
  redirectUrl: string;
}

export enum StripeBundleNickname {
  singleCredit = 'Single Credit',
}

export interface StripeTaxModal {
  id: string;
  taxType?: string;
  taxCountry?: string;
  taxState?: string;
  taxPercentage?: number;
  taxInclusive?: boolean;
  taxActive?: boolean;
}

export interface StripeCouponModal {
  id: string;
  couponAmountOff?: number;
  couponCreated?: number;
  couponCurrency?: string;
  couponDuration?: CouponDurationStripe;
  couponDurationInMonths?: number;
  couponMaxRedemptions?: number;
  couponMetadata?: {
    organizationId: string;
  };
  couponName?: string;
  couponPercentageOff?: number;
  couponRedeemBy?: number;
  couponTimesRedeemed?: number;
  couponValid?: boolean;
}

export enum StripeCouponType {
  AmountOff = 'amount_off',
  PercentOff = 'percent_off',
}

export interface StripeCoupon {
  id: string;
  amount_off?: number;
  created: number;
  currency?: string;
  duration: CouponDurationStripe;
  duration_in_months?: number;
  max_redemptions?: number;
  metadata: {
    organizationId: string;
  };
  name?: string;
  percent_off?: number;
  redeem_by?: number;
  times_redeemed: number;
  valid: boolean;
  object?: string;
}

export enum CouponDurationStripe {
  forever = 'forever',
  once = 'once',
  repeating = 'repeating',
}

export interface StripeDataModal {
  type?: string;
  service: string;
  routeApi: string;
  typeHttp: string;
  stripeDataModal?: {
    data?: StripeTaxModal | StripeCouponModal;
  };
}

export enum StripeFirstPurchaseType {
  YES = 'yes',
  NO = 'no',
}

export enum StripeDecimalMultiplier {
  Hundred = 100,
}

export enum StripeHttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export enum StripeCountryWithState {
  US = 'us',
  CA = 'ca',
}

export enum StripeServices {
  Product = 'product',
  Price = 'price',
  Tax = 'tax_rate',
  Coupon = 'coupon',
}

export enum StripeCurrency {
  CAD = 'cad',
  USD = 'usd',
}
