<div class="col-12 row px-0 mx-0">
  <!-- prettier-ignore -->
  <div class="title col-12 row px-0 mx-0 py-3 text-center justify-content-center modalHeader">
    <h2>Add Token</h2>
  </div>
  <div class="input col-12 row px-3 mx-0 my-2">
    <h3 class="label">Program</h3>
    <mat-select [(ngModel)]="programId">
      <mat-option
        *ngFor="let program of programs"
        [value]="program.id"
      >
        {{ program.name }}
      </mat-option>
    </mat-select>
  </div>
  <div class="input col-12 row px-3 mx-0 my-2">
    <h3 class="label">Allow Complete At Home</h3>
    <mat-select [(ngModel)]="allowCompleteAtHome">
      <mat-option [value]="'true'">Yes</mat-option>
      <mat-option [value]="'false'">No</mat-option>
    </mat-select>
  </div>
  <div class="input col-12 row px-3 mx-0 my-2">
    <h3 class="label">Quantity</h3>
    <input
      class="col-12 py-2"
      matInput
      type="number"
      min="1"
      step="1"
      [(ngModel)]="quantity"
    />
  </div>
  <div
    class="errorMessage col-12 row px-3 mx-0 my-2 text-center"
    *ngIf="errorMessage"
  >
    {{ errorMessage }}
  </div>
  <div class="buttons col-12 px-3 my-3 mx-0">
    <button
      class="col-4 py-3"
      (click)="confirm()"
    >
      Add
    </button>
    <button
      class="offset-4 col-4 py-3 warn"
      (click)="cancel()"
    >
      Cancel
    </button>
  </div>
</div>
