import { MatTableDataSource } from '@angular/material/table';
import { Component, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';

import { Client } from 'src/app/shared/interfaces';
import { ClientsListService } from './clients-list.service';
import { UsersHelperService } from '../../../users-helper.service';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from 'src/app/shared/dialogs/alert/alert.dialog';
import { StudentCreateModalComponent } from '../../../modals/student-create-modal/student-create-modal.component';
import { UsersCreateModalComponent } from '../../../modals/users-create-modal/users-create-modal.component';
import { Subject, takeUntil } from 'rxjs';
import { ManagerActions, Templates } from 'src/app/shared/interfaces/Manager.interface';
import { applyFilter } from 'src/app/shared/helpers/applyFilter';

const CLIENT_SORT_ORDER_KEY = 'clientSortOrder';

@Component({
  selector: 'users-clients-list',
  templateUrl: './clients-list.component.html',
  styleUrls: ['./clients-list.component.scss'],
})
export class ClientsListComponent {
  private destroy$ = new Subject<void>();

  dataSource: MatTableDataSource<any>;
  currentClientId: string;
  template: Templates;
  sortOrderLabel = this._getSortOrderLabel();
  loadingClientId: string | null = null;

  @Output() onGetStudents = new EventEmitter();

  constructor(
    private _clientsListService: ClientsListService,
    private _confirm: ConfirmationService,
    private _usersService: UsersHelperService,
    private cdr: ChangeDetectorRef,
    private _dialog: MatDialog,
  ) {
    this._clientsListService.clientsUpdated$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.loadClients(true);
    });
  }

  async ngOnInit(): Promise<void> {
    this.template = Templates.LOADING;
    await this.loadClients(true);
    this.template = Templates.TABLE;
  }
  async loadClients(refresh = false) {
    this.template = Templates.LOADING;
    const clients = await this._clientsListService.getClients({ refresh });

    if (this.getClientSortOrder() === 'asc') {
      this.dataSource = new MatTableDataSource(clients);
    } else {
      this.dataSource = new MatTableDataSource(clients.reverse());
    }

    this.template = Templates.TABLE;
  }

  public getStudents(id: string): void {
    this.onGetStudents.emit(id);
  }

  public addStudent(id: string): void {
    this._dialog.open(StudentCreateModalComponent, {
      data: {
        type: ManagerActions.CREATE,
        clientId: id,
      },
    });
  }

  public editClient(client: Client): void {
    this._dialog.open(UsersCreateModalComponent, {
      data: {
        type: ManagerActions.UPDATE,
        client: client,
      },
    });
  }

  public resendVerificationEmail(user: any): void {
    this._clientsListService.resendClientVerificationEmail(user);
  }

  public async deleteClient(id): Promise<void> {
    const warningMessage =
      'Are you sure you want to delete this client? All students associated with this client will also be deleted.';
    this._confirm
      .createConfirmation('Warning', warningMessage, 'Yes', 'No')
      .then(async () => {
        this.template = Templates.LOADING;
        await this._clientsListService.deleteClient(id);
      })
      .then(async () => {
        await this.loadClients(true);
        this.template = Templates.TABLE;
      });
  }

  public getEmailVerifiedTooltip(isEmailVerified: boolean | undefined): string {
    return this._usersService.getEmailVerifiedTooltip(isEmailVerified);
  }

  public applyFilter(event: Event): void {
    this.dataSource = applyFilter(event, this.dataSource);
  }

  public isDataSourceEmpty(): boolean {
    return this.dataSource.data.length === 0;
  }

  private toggleClientListSortOrder(): void {
    if (this.getClientSortOrder() === 'asc') {
      window.localStorage.setItem(CLIENT_SORT_ORDER_KEY, 'desc');
    } else {
      window.localStorage.setItem(CLIENT_SORT_ORDER_KEY, 'asc');
    }
  }

  private getClientSortOrder(): string {
    return window.localStorage.getItem(CLIENT_SORT_ORDER_KEY);
  }

  public async reverseClientListOrder() {
    this.toggleClientListSortOrder();
    await this.loadClients(true);
    this.sortOrderLabel = this._getSortOrderLabel();
  }

  private _getSortOrderLabel() {
    const currentSortOrder = this.getClientSortOrder();
    if (currentSortOrder === 'asc') {
      return 'Sorting oldest first';
    } else {
      return 'Sorting newest first';
    }
  }

  public downloadStudentFile(): void {
    this._clientsListService.downloadStudentFile();
  }

  public async importStudentsForFile(e: any, client: Client) {
    this.loadingClientId = client.id;
    this.cdr.detectChanges();

    const checkIsFinished = await this._clientsListService.importStudentsForFile(e, client);
    if (checkIsFinished) {
      this._dialog.open(AlertDialogComponent, {
        width: '400px',
        data: 'All users have been created',
      });
      setTimeout(() => {
        this.loadingClientId = null;
        this.cdr.detectChanges();
      }, 1000);
    } else {
      this._dialog.open(AlertDialogComponent, {
        width: '400px',
        data: 'Error importing users',
      });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
