import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { RestAPIService } from '../../services/rest/rest-api.service';
import { MaterialComponentsModule } from '../../shared/modules/material-components.module';
import { ContactComponent } from './contact.component';
import { LayoutsModule } from 'src/app/shared/layouts/layouts.module';
import { CurtainModule } from 'src/app/shared/components/curtain/curtain.module';

@NgModule({
  declarations: [ContactComponent],
  imports: [
    BrowserModule,
    RouterModule,
    FontAwesomeModule,
    MaterialComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutsModule,
    CurtainModule,
  ],
  providers: [RestAPIService],
})
export class ContactModule {}
