import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  public loadingEvent = new EventEmitter<boolean>();

  constructor() {}

  public ActivateLoading() {
    this.loadingEvent.emit(true);
  }

  public DisableLoading() {
    this.loadingEvent.emit(false);
  }
}
