import { TutorialInfo } from '../../models/tutorial.model';
import { Tutorials } from './tutorial-modal.constants';

export const getTutorialInfo = (index: Tutorials): TutorialInfo => {
  switch (index) {
    case Tutorials.ADD_STUDENTS:
    default:
      return {
        image: '../assets/img/tutorial/Add new.png',
        information:
          'Use this tab to create student profiles. Each person completing a program will need their own unique profile.',
        title: 'Add New',
        videoId: '41VoDC7mbqc',
        videoUrl: 'https://www.youtube.com/embed/41VoDC7mbqc',
      };
    case Tutorials.ACTIVE_STUDENTS:
      return {
        image: '../assets/img/tutorial/B2C Student.png',
        information:
          'Click “Active Students” to access your students. From there you can enter your student’s program page to track their progress and complete exercises that need to be completed at home.',
        title: 'Active Students',
      };
    case Tutorials.STUDENT_CONTROLS:
      return {
        image: '../assets/img/tutorial/StudentControls.png',
        information: 'Use the controls to edit profiles, enter dashboards, archive and assign programs.',
        title: 'Student Controls',
        videoId: '2128440',
        videoUrl: 'https://www.youtube.com/embed/rQ2J6uN-Qm4',
      };
    case Tutorials.PROGRAMS:
      return {
        image: '../assets/img/tutorial/B2C Purchase.png',
        information:
          'Programs are purchased  directly from your dashboard. We use Stripe and do not keep any Credit Card information.',
        title: 'Purchase Programs',
        videoId: 'XXNkY263bo4',
        videoUrl: 'https://www.youtube.com/embed/XXNkY263bo4',
      };
    case Tutorials.READLS:
      return {
        image: '../assets/img/tutorial/B2C Neuralign.png',
        information: 'Visit the Neuralign dashboard to launch the program and track progress',
        title: 'Neuralign',
        videoId: 'gjgGKZjhzCw',
        videoUrl: 'https://www.youtube.com/embed/gjgGKZjhzCw',
      };
    case Tutorials.DEMO:
      return {
        image: '../assets/img/tutorial/Demo.png',
        information:
          'Demo allows the student to get familiar with how the games in Neuralign work.  Try all 3 sections.  Cognitive Therapy, Speed Reading, and Reading Exercises.',
        title: 'All demos',
        videoId: 'ZvuVc4-H3do',
        videoUrl: 'https://www.youtube.com/embed/ZvuVc4-H3do',
      };
    case Tutorials.ORG_TRAINING:
      return {
        image: '../assets/img/tutorial/organization_tutorial_img.png',
        information: 'If you need a refresher on how to use the portal, click on “Tutorials”',
        title: 'Training',
      };
    case Tutorials.B2C_TRAINING:
      return {
        image: '../assets/img/tutorial/b2c_tutorial_img.png',
        information: 'If you need a refresher on how to use the portal, click on “Tutorials”',
        title: 'Training',
      };
    case Tutorials.CLIENT_TRAINING:
      return {
        image: '../assets/img/tutorial/client_tutorial_img.png',
        information: 'If you need a refresher on how to use the portal, click on “Tutorials”',
        title: 'Training',
      };
    case Tutorials.DOCUMENTATION:
      return {
        image: '../assets/img/tutorial/Downloads.png',
        information: 'Here you will find all the documents needed to give to your clients and marketing material.',
        title: 'Documentation',
      };
    case Tutorials.ACTIVE_STUDENTS_CLIENT:
      return {
        image: '../assets/img/tutorial/Client Active Student.png',
        information:
          'Click “Students” to access your active students. From there you can enter your student’s program page to track their progress and complete exercises that need to be completed at home.',
        title: 'Active Students',
      };
    case Tutorials.READLS_CLIENT:
      return {
        image: '../assets/img/tutorial/Client Neuralign page.png',
        information: 'Visit the Neuralign dashboard to launch the program and track progress',
        title: 'Neuralign',
        videoId: 'cSSs0BjgkNs',
        videoUrl: 'https://www.youtube.com/embed/cSSs0BjgkNs',
      };
    case Tutorials.USERS_ORG:
      return {
        image: '../assets/img/tutorial/Users.png',
        information:
          "Click 'Users' to access your list of active students. From there you can edit or enter your student's profile, track their progress, purchase and assign programs, or archive the student's profile.",
        title: 'USERS',
        videoId: 'o-IbN4ykF60',
        videoUrl: 'https://www.youtube.com/embed/o-IbN4ykF60',
      };
    case Tutorials.ACTIVE_USERS:
      return {
        image: '../assets/img/tutorial/Active User.png',
        information:
          'Click on Clients name to access your active students. From there you can enter your student’s program page to track their progress and complete exercises that need to be completed at home.',
        title: 'Active Users',
        videoId: 'idAI_v27Aag',
        videoUrl: 'https://www.youtube.com/embed/yshNCSr9xjc',
      };
    case Tutorials.ADD_USER_ORG:
      return {
        image: '../assets/img/tutorial/Add User.png',
        information:
          'Use this tab to create a client/manager/admin’s profiles. From the client tab you create a student for that client.',
        title: 'Add User',
        videoId: '83d2m65gu40',
        videoUrl: 'https://www.youtube.com/embed/83d2m65gu40',
      };
    case Tutorials.ADD_STUDENT_ORG:
      return {
        image: '../assets/img/tutorial/How to add a student.png',
        information:
          'Use this page to create student profiles. Each person completing a program will need their own unique profile.',
        title: 'Add Student',
        videoId: 'iMLMfPOvZ3o',
        videoUrl: 'https://www.youtube.com/embed/iMLMfPOvZ3o',
      };
    case Tutorials.PURCHASING_CREDITS_ORG:
      return {
        image: '../assets/img/tutorial/Org Purchase.png',
        information:
          'Programs are purchased directly from your dashboard. We use Stripe and do not keep any Credit Card information..',
        title: 'Purchasing Programs',
        videoId: 'FWmmQgygvHU',
        videoUrl: 'https://www.youtube.com/embed/FWmmQgygvHU',
      };
    case Tutorials.NEURALIGN_ORG:
      return {
        image: '../assets/img/tutorial/Org Program page.png',
        information: 'Visit the Neuralign program dashboard to launch the program and track progress',
        title: 'Neuralign',
        videoId: 'a7euKlHqy8M',
        videoUrl: 'https://www.youtube.com/embed/a7euKlHqy8M',
      };
    case Tutorials.MOUSE_TRAINING:
      return {
        image: '../assets/img/Gumball.png',
        information: 'Mouse Training allows the student to get familiar with how to use a mouse.',
        title: 'Mouse Training',
        videoId: 'Zu-nR8TLZo0',
        videoUrl: 'https://www.youtube.com/embed/Zu-nR8TLZo0',
      };
    case Tutorials.ADD_CREDIT_ORG:
      return {
        image: '../assets/img/tutorial/4._Credit_to_student.png',
        information:
          "Press the + sign from your student's links to add a credit to your student, which will then allow you access to the program page",
        title: 'Add credit to your student',
        videoId: '7sBhEMkFqD4',
        videoUrl: 'https://www.youtube.com/embed/7sBhEMkFqD4',
      };
    case Tutorials.ENTERING_STUDENT_PAGE_ORG:
      return {
        image: '../assets/img/tutorial/5._Entering_the_students_program_page..png',
        information:
          'Click on the "Up Arrow" from the student\'s links or clicking on "Neuralign" on the green menu bar will give you access to the program page',
        title: "Entering the student's program page",
        videoId: '3QRSq4uZouI',
        videoUrl: 'https://www.youtube.com/embed/3QRSq4uZouI',
      };
    case Tutorials.REMOTE_ACCESS_ORG:
      return {
        image: '../assets/img/tutorial/6._Remote_access_for_home_use.png',
        information: 'Check off the remote access box to allow your student to complete the program at home',
        title: 'Remote access for home use',
        videoId: 'J77r2T6Tmq0',
        videoUrl: 'https://www.youtube.com/embed/J77r2T6Tmq0',
      };
    case Tutorials.DEMO_ORG:
      return {
        image: '../assets/img/tutorial/Demo.png',
        information: 'Full program Demo for your needs are here',
        title: 'All demos',
        videoId: 'ZvuVc4-H3do',
        videoUrl: 'https://www.youtube.com/embed/ZvuVc4-H3do',
      };
    case Tutorials.TRAINING_ORG:
      return {
        image: '../assets/img/tutorial/organization_tutorial_img.png',
        information: 'If you need a refresher on how to use the portal, click on “Tutorials”',
        title: 'Training',
      };
    case Tutorials.STEP_BY_STEP_ORG:
      return {
        image: '../assets/img/tutorial/NeurAlign text logo.png',
        information:
          'This is your hub to access instructions and demos for all the Neuralign games. Come back as often as you’d like!',
        title: 'Neuralign Step by Step',
      };
    case Tutorials.DOCUMENTS_ORG:
      return {
        image: '../assets/img/tutorial/Documents.png',
        information: 'Here you will find all the documents needed to give to your clients and marketing material',
        title: 'Documents',
      };
    case Tutorials.READLS_2:
      return {
        image: '../assets/img/tutorial/PurchasePage.png',
        information: 'Use any form of payment on our secure site.',
        title: 'Neuralign',
      };
    case Tutorials.BEFORE_YOU_START:
      return {
        image: '',
        information: '',
        title: 'Before you start',
      };
  }
};
