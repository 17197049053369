<footer>
  <div class="wrapper">
    <nav class="site-map">
      <div class="column">
        <a href="https://lsworks.org/about-us">ABOUT US</a>
        <a href="/contact">CONTACT</a>
      </div>
      <div class="column">
        <a href="/terms-of-service">TERMS AND CONDITIONS</a>
        <a href="/privacy-policy">PRIVACY POLICY</a>
      </div>
    </nav>

    <section class="social">
      <ul class="links">
        <li (click)="goToFacebook()">
          <fa-icon [icon]="facebookIcon"></fa-icon>
        </li>
        <li (click)="goToTwitter()">
          <fa-icon [icon]="twitterIcon"></fa-icon>
        </li>
        <li (click)="goToInstagram()">
          <fa-icon [icon]="instagramIcon"></fa-icon>
        </li>
        <li (click)="goToLinkedIn()">
          <fa-icon [icon]="linkedinIcon"></fa-icon>
        </li>
        <li (click)="goToYoutube()">
          <fa-icon [icon]="youtubeIcon"></fa-icon>
        </li>
      </ul>

      <div class="subscribe">
        <label for="email">Stay connected</label>

        <div class="input">
          <input
            id="email"
            type="text"
            [formControl]="email"
            placeholder="Email Address"
          />
          <button (click)="connectEmail()">OK</button>
        </div>
      </div>
    </section>
  </div>
</footer>
