import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { RestAPIService } from '../../services/rest/rest-api.service';
import { MaterialComponentsModule } from 'src/app/shared/modules/material-components.module';
import { BreadcrumbModule } from 'src/app/shared/components/breadcrumb/breadcrumb.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { LayoutComponentsModule } from 'src/app/shared/components/layout/layout-components.module';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { SubscriptionControlCenterComponent } from './subscription-control-center.component';
import { AdjustSubscriptionInfoComponent } from './adjust-subscription-info-dialog/adjust-subscription-info.component';
import { SubscriptionPaymentsDialogComponent } from './subscription-payments.dialog/subscription-payments-dialog.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ResellerSubscriptionComponent } from './reseller-subscription-container/reseller-subscriptions.component';
import { RegularSubscriptionComponent } from './non-reseller-subscription-container/non-reseller-subscription-container.component';
import { AdjustPaymentSettingsComponent } from './adjust-payment-settings-dialog/adjust-payment-settings-dialog';
import { LayoutsModule } from 'src/app/shared/layouts/layouts.module';
import { SubscriptionCancelWarningComponent } from './subscription-cancel-warning/subscription-cancel-warning.component';

@NgModule({
  declarations: [
    SubscriptionControlCenterComponent,
    AdjustSubscriptionInfoComponent,
    SubscriptionPaymentsDialogComponent,
    ResellerSubscriptionComponent,
    RegularSubscriptionComponent,
    AdjustPaymentSettingsComponent,
    SubscriptionCancelWarningComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    HttpClientModule,
    MaterialComponentsModule,
    BreadcrumbModule,
    FontAwesomeModule,
    MatTooltipModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatCardModule,
    ReactiveFormsModule,
    LayoutComponentsModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    LayoutsModule,
  ],
  providers: [RestAPIService],
})
export class SubscriptionControlCenterModule {}
