<div class="menu">
  <div class="bg"></div>
  <ul class="ml-5 hide-sm">
    <li><fa-icon [icon]="homeIcon"></fa-icon></li>
    <li *ngFor="let item of menuItems; let last = last">
      <ng-container *ngIf="!last">
        <a (click)="goto(item.url)">
          {{ item.name }}
        </a>
        >
      </ng-container>
      <ng-container *ngIf="last">
        <a>{{ item.name }}</a>
      </ng-container>
    </li>
  </ul>
</div>
<div class="bottom-header-border"></div>
