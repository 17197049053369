import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from './card.component';
import { ButtonComponent } from './button/button.component';
import { ImageComponent } from './image/image.component';
import { LessonCountComponent } from './lesson-count/lesson-count.component';
import { TitleComponent } from './title/title.component';
import { CoursesSharedModule } from '../../../shared/courses.shared.module';

/**
 * @scope Displays a small card that shows a course title, image and progress indicator.
 */
@NgModule({
  declarations: [CardComponent, ButtonComponent, ImageComponent, LessonCountComponent, TitleComponent],
  imports: [CommonModule, CoursesSharedModule],
  exports: [CardComponent],
})
export class CardModule {}
