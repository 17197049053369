import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { TermsOfServiceComponent } from './terms.component';
import { LayoutsModule } from 'src/app/shared/layouts/layouts.module';

@NgModule({
  declarations: [TermsOfServiceComponent],
  imports: [BrowserModule, LayoutsModule],
})
export class TermsOfServiceModule {}
