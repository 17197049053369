import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faCcAmex, faCcMastercard, faCcVisa, IconDefinition } from '@fortawesome/free-brands-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { LoadingService } from 'src/app/services/utils/loading.service';

declare let stripe: any;

@Component({
  selector: 'adjust-payment-dialog',
  templateUrl: 'adjust-payment-settings-dialog.html',
  styleUrls: ['adjust-payment-settings-dialog.scss'],
})
export class AdjustPaymentSettingsComponent implements OnInit {
  constructor(
    private _rest: RestAPIService,
    @Inject(MAT_DIALOG_DATA) public subscriptionId: string,
    public dialogRef: MatDialogRef<AdjustPaymentSettingsComponent>,
    private loadingService: LoadingService,
  ) {}

  public readonly visa: IconDefinition = faCcVisa;
  public readonly master: IconDefinition = faCcMastercard;
  public readonly amex: IconDefinition = faCcAmex;
  public readonly spinnerIcon: IconDefinition = faSpinner;

  public validInput = {
    cardNumber: { empty: true, complete: false },
    cardExpiry: { empty: true, complete: false },
    cardCvc: { empty: true, complete: false },
    sending: false,
  };

  public updating = false;

  public cardNumberElement;
  public cardExpiryElement;
  public cardCvcElement;
  public cardInfoFormControl = new UntypedFormControl('', [Validators.required, Validators.email]);
  public cardNumber;
  public sendingInformation = false;

  ngOnInit(): void {
    this.stripeSubmitCard();
  }

  ngOnDestroy() {
    this.cardNumberElement.destroy();
  }

  public close() {
    this.dialogRef.close();
  }

  public stripeSubmitCard() {
    const style = {
      base: {
        iconColor: '#666EE8',
        color: 'rgb(33, 37, 41)',
        lineHeight: '40px',
        fontWeight: 400,
        fontFamily: 'Roboto, Helvetica Neue, sans-serif',
        fontSize: '16px',

        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    };

    this.cardNumberElement = stripe.elements().create('card', {
      hidePostalCode: true,
      style,
    });
    this.cardNumberElement.mount('#card-number-element');

    document.querySelector('form').addEventListener('submit', async () => {
      const { token } = await stripe.createToken(this.cardNumberElement);

      this.sendingInformation = true;

      if (token) {
        this.updating = true;
        this.loadingService.ActivateLoading();
        this.sendingInformation = true;
        const updatedSubscription = await this._rest.put('subscriptions/update/paymentMethod', {
          subscriptionId: this.subscriptionId,
          token: token.id,
        });

        if (updatedSubscription) {
          this.dialogRef.close(updatedSubscription);
        }

        this.loadingService.DisableLoading();
        this.updating = false;
        this.sendingInformation = false;
      }
    });
  }
}
