<section #quotesBox>
  <div>
    <p
      montserrat
      mediumSize
      thin
      zeroMargin
    >
      “Through a process called accelerated neuroplasticity, this game-like
      intervention allows the dyslexic brain to build the necessary connections
      needed for effective reading. It truly is life-changing.”
    </p>
    <p
      montserrat
      white
      thin
      zeroMargin
      smallSize
    >
      Dr. Rina Gupta, Psychologist
    </p>
  </div>

  <div>
    <p
      montserrat
      mediumSize
      thin
      zeroMargin
    >
      “Foundational and life-changing.”
    </p>
    <p
      montserrat
      white
      thin
      zeroMargin
      smallSize
    >
      Kirsten López, School Principal
    </p>
  </div>
</section>

<div>
  <img
    src="assets/img/slides/lady-pink-bg.webp"
    alt=""
    width="100%"
  />
</div>
