import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MaterialComponentsModule } from 'src/app/shared/modules/material-components.module';
import { LayoutComponentsModule } from 'src/app/shared/components/layout/layout-components.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ProgramsPricingComponent } from './programs-pricing.component';
import { PortalMenusModule } from 'src/app/shared/modules/portal-menus.module';
import { BundleCardComponent } from './components/bundle-card/bundle-card.component';
import { BundleCardResellerComponent } from './components/bundle-card-reseller/bundle-card-reseller.component';
import { FormsModule } from '@angular/forms';
import { B2CProgramsPricingComponent } from './sections/b2c-pricing/b2c-programs-pricing.component';
import { MatButtonModule } from '@angular/material/button';
import { BundleDetailsTemplateComponent } from './components/templates/bundle-details-template.component';
import { MatRadioModule } from '@angular/material/radio';
import { LayoutsModule } from 'src/app/shared/layouts/layouts.module';
import { CurtainModule } from 'src/app/shared/components/curtain/curtain.module';
import { RoundUpModule } from 'src/app/shared/pipes/round-up/round-up.module';
import { ThreeCardHolderComponent } from './components/three-card-holder/three-card-holder.component';
import { HomeModule } from '../home/home.module';
import { NewBundleCardComponent } from './components/new-bundle-card/new-bundle-card.component';
import { OutsiderPricingComponent } from './sections/outsider-pricing/outsider-pricing.component';
import { AwardsComponent } from './sections/awards/awards.component';
import { WhatYouNeedComponent } from './sections/what-you-need/what-you-need.component';
import { RegularOrgPricingComponent } from './sections/regular-org-pricing/regular-org-pricing.component';
import { CardComponent } from './components/card/card.component';
import { SingleCardHolderComponent } from './components/single-card-holder/single-card-holder.component';
import { HeadingComponent } from './components/heading/heading.component';
import { ParagraphComponent } from './components/paragraph/paragraph.component';
import { CardHeadingComponent } from './components/card-heading/card-heading.component';
import { CardPriceComponent } from './components/card-price/card-price.component';
import { CardBuyBtnComponent } from './components/card-buy-btn/card-buy-btn.component';
import { WebGLComponent } from './sections/web-gl/web-gl.component';
import { CardCreditsComponent } from './components/card-credits/card-credits.component';
import { CardBillIntervalComponent } from './components/card-bill-interval/card-bill-interval.component';

@NgModule({
  declarations: [
    ProgramsPricingComponent,
    B2CProgramsPricingComponent,
    BundleCardComponent,
    BundleCardResellerComponent,
    BundleDetailsTemplateComponent,
    ThreeCardHolderComponent,
    NewBundleCardComponent,
    OutsiderPricingComponent,
    AwardsComponent,
    WhatYouNeedComponent,
    RegularOrgPricingComponent,
    CardComponent,
    SingleCardHolderComponent,
    HeadingComponent,
    ParagraphComponent,
    CardHeadingComponent,
    CardPriceComponent,
    CardBuyBtnComponent,
    WebGLComponent,
    CardCreditsComponent,
    CardBillIntervalComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    PortalMenusModule,
    MaterialComponentsModule,
    LayoutComponentsModule,
    FontAwesomeModule,
    MatButtonModule,
    MatRadioModule,
    LayoutsModule,
    CurtainModule,
    RoundUpModule,
    HomeModule,
  ],
})
export class ProgramsPricingModule {}
