import { LogLevel } from 'src/app/shared/consts/loglevel';
import { Environment } from './environment.model';

export const environment: Environment = {
  production: true,
  environment: 'readls',
  AUTH0_CLIENT_ID: 'AUTH0_CLIENT_ID_PLACEHOLDER',
  AUTH0_DOMAIN: 'AUTH0_DOMAIN_PLACEHOLDER',
  API_URL: 'API_URL_PLACEHOLDER',
  GOOGLE: {
    MAPS_API: 'GOOGLE_MAPS_API_PLACEHOLDER',
    ADWORDS_CONVERSION_ID: 'ADWORDS_CONVERSION_ID_PLACEHOLDER',
    ADWORDS_CONVERSION_LABEL: 'ADWORDS_CONVERSION_LABEL_PLACEHOLDER',
    ANALYTICS_TAG: 'ANALYTICS_TAG_PLACEHOLDER',
    ADWORDS_TAG: 'ADWORDS_TAG_PLACEHOLDER',
  },
  LINKEDIN: {
    ANALYTICS_ID: 'LINKEDIN_ANALYTICS_ID_PLACEHOLDER',
    CONVERSION_ID: 'LINKEDIN_CONVERSION_ID',
  },
  ROOT_DOMAIN_URL: 'ROOT_DOMAIN_URL_PLACEHOLDER',
  CLOUDINARY_CLOUD_NAME: 'CLOUDINARY_CLOUD_NAME_PLACEHOLDER',
  CLOUDINARY_UPLOAD_PRESET: 'CLOUDINARY_UPLOAD_PRESET_PLACEHOLDER',
  WEBGL_PHONEMEFAIR_BASE_URL: 'WEBGL_PHONEMEFAIR_BASE_URL_PLACEHOLDER',
  WEBGL_BASE_URL: 'WEBGL_BASE_URL_PLACEHOLDER',
  WEBGL_CONFIG_JSON: 'WEBGL_CONFIG_JSON_PLACEHOLDER',
  PORTAL_CONSTANTS: {
    PORTAL_LOGO: 'assets/img/NeurAlign text logo.png',
    TAKE_A_TOUR_HEADER_IMAGE: '../../../assets/img/tour/TourMainImage.jpeg',
    TAKE_A_TOUR_TARGET_IMAGE: 'assets/img/NeurAlign text logo.png',
  },
  SENTRY_DSN: 'SENTRY_DSN_PLACEHOLDER',
  LOG_LEVEL: LogLevel.NONE,
  CLARITY_ID: 'CLARITY_ID_PLACEHOLDER',
};
