import { Component, OnInit } from '@angular/core';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'users-privacy-screen',
  templateUrl: './privacy-screen.component.html',
  styleUrls: ['./privacy-screen.component.scss'],
})
export class PrivacyScreenComponent implements OnInit {
  upIcon: IconDefinition = faArrowUp;

  constructor() {}

  ngOnInit(): void {}
}
