<div class="col-12 row px-0 mx-0">
  <!-- prettier-ignore -->
  <div class="title col-12 row px-0 mx-0 py-3 text-center justify-content-center modalHeader">
        <h2>Update Token</h2>
    </div>
  <div class="input col-12 row p-3 mx-0 my-2">
    <h3 class="label">Program</h3>
    <mat-select [(ngModel)]="programId">
      <mat-option
        *ngFor="let program of programs"
        [value]="program.id"
      >
        {{ program.name }}
      </mat-option>
    </mat-select>
  </div>
  <div class="input col-12 row p-3 mx-0 my-2">
    <h3 class="label">Allow Complete At Home</h3>
    <mat-select [(ngModel)]="allowCompleteAtHome">
      <mat-option [value]="true">Yes</mat-option>
      <mat-option [value]="false">No</mat-option>
    </mat-select>
  </div>
  <div class="input col-12 row p-3 mx-0 my-2">
    <h3 class="label">Allow Program rerun</h3>
    <mat-select [(ngModel)]="allowProgressRerun">
      <mat-option [value]="true">Yes</mat-option>
      <mat-option [value]="false">No</mat-option>
    </mat-select>
  </div>
  <div class="buttons col-12 p-3 my-3 mx-0 d-flex justify-content-center">
    <button
      class="col-4 py-3 mx-2"
      (click)="confirm()"
    >
      Save
    </button>
    <button
      class="col-4 py-3 warn mx-2"
      (click)="cancel()"
    >
      Cancel
    </button>
  </div>
</div>
