import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  faPlus,
  faMinus,
  faCheck,
  faTimes,
  faChevronDown,
  faChevronUp,
  faImages,
  faExclamationCircle,
  faFileExport,
  faCircleQuestion,
} from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Theme } from './interfaces/themes.interface';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';
import { isEmpty, get, includes } from 'lodash';
import { getDefaultLevels } from './consts/theme.constants';
import { MatTableDataSource } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { ThemeCreationDialogComponent } from './theme-creation-dialog/theme-creation-dialog.component';
import { Languages } from 'src/app/shared/models/languages.model';
import { Organization } from 'src/app/core/openapi';

@Component({
  selector: 'app-themes',
  templateUrl: './themes.component.html',
  styleUrls: ['./themes.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ThemesComponent implements OnInit {
  @Input() globalThemes: Theme[];
  @Input() globalConfigurations: any;
  @Input() organizationList: Organization[];
  @Input() languagesList: Languages[];
  @Input() categoriesList;
  @Output() reset = new EventEmitter();

  public readonly plusLine: IconDefinition = faPlus;
  public readonly minusLine: IconDefinition = faMinus;
  public readonly save: IconDefinition = faCheck;
  public readonly cancel: IconDefinition = faTimes;
  public readonly collapse: IconDefinition = faChevronDown;
  public readonly close: IconDefinition = faChevronUp;
  public readonly exports: IconDefinition = faFileExport;
  public readonly warningMessage: IconDefinition = faExclamationCircle;
  public readonly interrogationIcon: IconDefinition = faCircleQuestion;
  public readonly themes: IconDefinition = faImages;

  public addingNewTheme = false;
  public defaultTheme: Theme;
  public name: string;
  public lang: string;
  public themeLabel: any;
  public selectedTheme: Theme;
  public loading: boolean;
  public levelCollapsed: string;
  public addingNewLang = false;
  public selectedOption: string;
  public allowedUsers = false;
  public orgArray = [];
  public languagesArray = [];
  public categoriesArray = [];
  public checked = true;
  public unchecked = false;

  public instructionList = {
    appendToGame:
      'Mark as true if the theme games will have a different style. Ex: cdh games have different styles, so it should be true',
    overlay: 'Type of HUD to be used. -1 for none, 1 for default and 2 for cdh',
    variableName: 'Name of the key to be used to find the theme exercise list: Ex: classic for classic games',
    enabledForDemos: 'Mark as true if you want to have this theme avaliable to be selected on the demos page',
  };

  displayedColumns: string[] = ['name', 'enabled'];
  langDisplayedColumns: string[] = ['name', 'code', 'enabled'];
  categoriesDisplayedColumns: string[] = ['name', 'enabled'];
  expandedLang: any;

  dataSource: MatTableDataSource<any>;
  langDataSource: MatTableDataSource<any>;
  categoriesDataSource: MatTableDataSource<any>;

  constructor(
    private _rest: RestAPIService,
    protected _snackBar: MatSnackBar,
    private confirm: ConfirmationService,
    private dialog: MatDialog,
  ) {}

  async ngOnInit() {
    this.orgArray = [].concat(this.organizationList);
    this.languagesArray = [].concat(this.languagesList);
    this.categoriesArray = [].concat(this.categoriesList);
    this.dataSource = new MatTableDataSource(this.orgArray);
    this.langDataSource = new MatTableDataSource(this.languagesArray);
    this.categoriesDataSource = new MatTableDataSource(this.categoriesArray);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.dataSource.filterPredicate = (data, filter: string): boolean => {
      return data.organization
        ? data.organization.name.toLowerCase().includes(filter)
        : data.patron.name.toLowerCase().includes(filter);
    };
  }

  public openNewThemeDialog() {
    const dialog = this.dialog.open(ThemeCreationDialogComponent, {
      data: {
        languages: this.languagesList,
        categories: this.categoriesArray,
      },
      width: '650px',
      height: '750px',
      panelClass: 'theme-modalBox',
    });

    dialog.afterClosed().subscribe((shouldRefresh: boolean) => {
      if (shouldRefresh) {
        this.reset.emit();
      }
    });
  }

  public selectTheme(theme: Theme) {
    this.levelCollapsed = undefined;
    this.selectedTheme = theme;
    this.themeLabel = Object.keys(theme.label);

    if (!this.selectedTheme.variableName) {
      this.selectedTheme.variableName = '';
    }

    if (!this.selectedTheme.languages) {
      this.selectedTheme.languages = [];
    } else {
      this.selectedTheme.languages = this.selectedTheme.languages.filter((l) => this.checkThemeLanguages(l));
    }

    if (!this.selectedTheme.categories) {
      this.selectedTheme.categories = [];
    } else {
      this.selectedTheme.categories = this.selectedTheme.categories.filter((c) => this.checkThemeCategories(c));
    }
  }

  public toggleTheme() {
    this.selectedTheme.isEnabled = !this.selectedTheme.isEnabled;
  }

  public checkThemeLanguages(language): boolean {
    // if the language was deleted on the API we must remove it from the theme languages array

    if (!language) {
      return false;
    }

    return this.languagesArray.find((l) => l.id === language.languageId);
  }

  public checkThemeCategories(category): boolean {
    // if the category was deleted on the API we must remove it from the theme languages array

    if (!category) {
      return false;
    }

    return this.categoriesArray.find((c) =>
      category.id ? c.id === category.id : c.name.toLowerCase() === category.name.toLowerCase(),
    );
  }

  public changeEnabledStatus() {
    this.selectedTheme.enabledToAll = !this.selectedTheme.enabledToAll;

    if (this.selectedTheme.enabledToAll) {
      this.selectedTheme.allowedOrganizations = [];
    }
  }

  public saveLabel() {
    if (!this.lang) {
      return;
    }

    this.selectedTheme.label[this.lang] = '';

    this.selectTheme(this.selectedTheme);

    this.addingNewLang = false;
    this.lang = '';
  }

  public deleteLabel(language) {
    if (!language) {
      return;
    }

    delete this.selectedTheme.label[language];

    this.selectTheme(this.selectedTheme);
  }

  public checkIfLanguageIsEnabled(id) {
    const lang = this.selectedTheme.languages.find((l) => l.languageId === id);

    if (lang) {
      return true;
    } else {
      return false;
    }
  }

  public checkIfCategoryIsEnabled(id: string, name: string) {
    const category = this.selectedTheme.categories.find((l) =>
      l.id ? l.id === id : l.name.toLowerCase() === name.toLowerCase(),
    );

    if (category) {
      return true;
    } else {
      return false;
    }
  }

  public addNewLanguage(language) {
    const themeLangs = this.selectedTheme.languages;

    const lang = themeLangs.find((l) => l.languageId === language.id);

    if (!lang) {
      const newLang = {
        languageId: language.id,
        languageCode: language.languageCode,
        name: language.name,
        enabledLevels: getDefaultLevels(),
      };

      this.selectedTheme.languages.push(newLang);
    } else {
      const selectedLang = this.selectedTheme.languages.find((l) => l.languageId === language.id);
      const index = this.selectedTheme.languages.indexOf(selectedLang);
      this.selectedTheme.languages.splice(index, 1);
    }
  }

  public addNewCategory(category) {
    const themeCategories = this.selectedTheme.categories;

    const enabledCategory = themeCategories.find((c) =>
      c.id ? c.id === category.id : c.name === category.name.toLowerCase(),
    );

    if (!enabledCategory) {
      const newCategory = {
        id: category.id,
        name: category.name,
        title: category.title,
      };

      this.selectedTheme.categories.push(newCategory);
    } else {
      const selectedCategory = this.selectedTheme.categories.find((c) =>
        c.id ? c.id === category.id : c.name === category.name.toLowerCase(),
      );

      const index = this.selectedTheme.categories.indexOf(selectedCategory);
      this.selectedTheme.categories.splice(index, 1);
    }
  }

  public returnLanguageLabel(id) {
    const language = this.languagesArray.find((l) => get(l, 'id') === id);

    if (language) {
      return language.languageCode;
    }
  }

  public async updateTheme() {
    if (isEmpty(this.selectedTheme.label.en_ca)) {
      this._snackBar.open(`Theme english label must have a value!`, 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });

      return;
    } else if (this.isLangsDisabled()) {
      this._snackBar.open(`You must enable at least one language`, 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });

      return;
    } else if (!this.selectedTheme.enabledToAll && this.selectedTheme.allowedOrganizations.length === 0) {
      this._snackBar.open(
        `If the theme is not enabled to all users you must choose the organizations that can use it`,
        'Close',
        {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        },
      );

      return;
    }

    this._rest
      .put('themes/updateTheme', { theme: this.selectedTheme }, { msg: 'Could not put updateTheme' })
      .then(() => {
        this._snackBar.open(`Theme updated!`, 'Close', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      });
  }

  public async deleteTheme() {
    this.confirm
      .createConfirmation('Warning', 'Are you sure you want to delete this theme?', 'Yes', 'No')
      .then(async () => {
        this._rest
          .delete('themes/deleteTheme/' + this.selectedTheme.id, { msg: 'Could not delete themes.' })
          .then(() => {
            this.selectedTheme = undefined;
            this.reset.emit();
          });
      });
  }

  public isLangsDisabled() {
    const booleanArray = [];

    for (const langs of this.selectedTheme.languages) {
      booleanArray.push(langs);
    }

    return booleanArray.every((e) => e === false);
  }

  public addEnabledOrg(acc) {
    const organizationArray = this.selectedTheme.allowedOrganizations || [];

    const findOrg = organizationArray.find((o) => o === acc.id);

    if (findOrg) {
      const index = organizationArray.indexOf(acc.id);
      organizationArray.splice(index, 1);
    } else {
      organizationArray.push(acc.id);
    }

    this.selectedTheme.allowedOrganizations = organizationArray;
  }

  public checkEnabledOrgs(acc): boolean {
    const organizationArray = this.selectedTheme.allowedOrganizations || [];
    const findOrg = organizationArray.find((o) => o === acc.id);

    return findOrg ? true : false;
  }

  public showAllowedUsers() {
    if (!this.allowedUsers) {
      const newArray = this.orgArray.filter((o) => this.checkEnabledOrgs(o));
      this.orgArray = [].concat(newArray);

      this.dataSource = new MatTableDataSource(this.orgArray);
    } else {
      this.orgArray = [].concat(this.organizationList);

      this.dataSource = new MatTableDataSource(this.orgArray);
    }
  }

  public selectAllUsers() {
    const allUsers = this.organizationList.map((o) => o.id);

    this.selectedTheme.allowedOrganizations = allUsers;
  }

  public reverseSelection() {
    const organizationArray = this.selectedTheme.allowedOrganizations || [];
    const allUsers = this.organizationList.map((o) => o.id);

    if (organizationArray.length === 0) {
      return;
    } else {
      const filteredUsers = allUsers.filter((id) => !includes(this.selectedTheme.allowedOrganizations, id));
      this.selectedTheme.allowedOrganizations = filteredUsers;
    }
  }

  public getInstructionMessage(instruction) {
    return this.instructionList[instruction];
  }
}
