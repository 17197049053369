<div class="mainContent">
  <div class="header col-12 px-0 py-3 border">Neuralign</div>

  <div class="content p-3">
    <div class="text p-4">
      The student {{ student }} has a saved game for
      {{ tagName(savedGame.tag) }} on session {{ savedGame.session }}
    </div>

    <div class="col-12 px-0 mx-0 row justify-content-center">
      <div class="col-4 text-center headerOption">Exercise</div>
      <div class="col-4 text-center headerOption">Score</div>
    </div>

    <div class="wrapper w-50 mx-auto px-0">
      <div class="exerciseList col-12 px-0">
        <div
          class="exercises col-12 px-0 mx-0 row justify-content-between"
          *ngFor="let save of exercises"
        >
          <div class="col-4 pr-0 pl-1 text-center options">{{ save.name }}</div>
          <div class="col-4 pr-0 pl-2 text-center options">
            {{ save.score }}
          </div>
        </div>
      </div>
    </div>

    <div class="text p-4">Would you like to load this game ?</div>

    <div class="d-flex mx-auto px-0 col-12 justify-content-center">
      <app-simple-button
        data-cy="load-saved-game"
        (click)="loadSavedGame()"
        class="mx-1"
        [text]="'program.loadGame' | translate"
      ></app-simple-button>
      <app-simple-button
        (click)="playFromBeginning()"
        class="mx-1"
        [text]="'program.playFromBeginning' | translate"
      ></app-simple-button>
    </div>

    <div class="mx-auto px-0 col-12 d-flex justify-content-center">
      <app-simple-button
        (click)="cancel()"
        class="mt-3"
        [text]="'program.cancel' | translate"
      ></app-simple-button>
    </div>
  </div>
</div>
