<lsw-curtain></lsw-curtain>

<app-public-layout>
  <div class="wrapper">
    <div
      class="main-img"
      [ngStyle]="{ 'background-image': 'url(\'' + tourHeaderImage + '\')' }"
    ></div>
    <div class="bg-overlay">
      <div class="container">
        <p>
          The games LSWorks develops incorporate everything we<br />
          know about learning, and where things can go wrong
        </p>
      </div>
    </div>
  </div>

  <div class="container">
    <br /><br />
    <div
      id="ppdiv_2127144"
      class="pp-video-container"
      style="width: 100%; height: auto"
    ></div>
    <br /><br />
  </div>

  <app-take-a-tour-highlight
    title="Thanks to advancements in research, we now know more than ever about what is required of our brain and bodies in order to master the skill of reading."
    subtitle="Thanks to advancements in technology, we now have the ability to deliver the most comprehensive research based learning interventions all around the world."
  >
  </app-take-a-tour-highlight>

  <div class="container">
    <div>
      <div class="targets-title">
        <img [src]="tourTargetImage" />
        <p>Targets:</p>
      </div>
      <div class="targets">
        <app-take-a-tour-target
          *ngFor="let target of targets"
          [target]="target"
        ></app-take-a-tour-target>
      </div>
    </div>

    <div class="games">
      <div *ngFor="let game of games">
        <app-take-a-tour-game-target
          [game]="game"
        ></app-take-a-tour-game-target>
      </div>
    </div>

    <div class="references">
      <p>
        (1)
        <a
          target="_blank"
          href="https://kidshealth.org/en/parents/central-auditory.html"
        >
          https://kidshealth.org/en/parents/central-auditory.html
        </a>
        Reviewed by: Thierry Morlet, PhD
        <br />
        (2)
        <a
          target="_blank"
          href="https://www.understood.org/en/learning-attention-issues/child-learning-disabilities/dyslexia/the-difference-between-dyslexia-and-auditory-processing-disorder"
        >
          https://www.understood.org/en/learning-attention-issues/child-learning-disabilities/dyslexia/the-difference-between-dyslexia-and-auditory-processing-disorder
        </a>
        By Kate Kelly
        <br />
        (3)
        <a
          target="_blank"
          href="https://www.sciencedirect.com/science/article/pii/S0042698908000679"
        >
          https://www.sciencedirect.com/science/article/pii/S0042698908000679
        </a>
        <br />
        (4)
        <a
          target="_blank"
          href="https://www.sciencedirect.com/science/article/pii/S0010027706001272"
        >
          https://www.sciencedirect.com/science/article/pii/S0010027706001272
        </a>
        <br />
        (5)
        <a
          target="_blank"
          href="https://www.hopecliniconline.com/vision-dysfunctions/reading-learning-and-behavioral-problems/"
        >
          https://www.hopecliniconline.com/vision-dysfunctions/reading-learning-and-behavioral-problems/
        </a>
        <br />
        (6)
        <a
          target="_blank"
          href="https://nba.uth.tmc.edu/neuroscience/m/s3/chapter08.html"
        >
          https://nba.uth.tmc.edu/neuroscience/m/s3/chapter08.html
        </a>
        <br />
        (7) A Therapy Resource published by Upbility.net VISUAL PERCEPTION
        SKILLS FOR CHILDREN WITH DYSLEXIA | Visual sequential memory
        <br />
        (8)
        <a
          target="_blank"
          href="https://www.understood.org/en/learning-attention-issues/child-learning-disabilities/executive-functioning-issues/understanding-executive-functioning-issues"
        >
          https://www.understood.org/en/learning-attention-issues/child-learning-disabilities/executive-functioning-issues/understanding-executive-functioning-issues
        </a>
        <br />
        (9) Snapshot: What Executive Functioning Issues Are | understood.org
        <br />
        (10)
        <a
          target="_blank"
          href="https://www.sciencedirect.com/topics/neuroscience/executive-functions"
        >
          https://www.sciencedirect.com/topics/neuroscience/executive-functions
        </a>
        <br />
        (11) A Therapy Resource published by Upbility.net VISUAL PERCEPTION
        SKILLS FOR CHILDREN WITH DYSLEXIA | PART 1:VISUAL CLOSURE
        <br />
        (12)
        <a
          target="_blank"
          href="https://www.simplypsychology.org/working%20memory.html"
        >
          https://www.simplypsychology.org/working%20memory.html
        </a>
        <br />
        (13)
        <a
          target="_blank"
          href="https://www.medicinenet.com/script/main/art.asp?articlekey=7143"
        >
          https://www.medicinenet.com/script/main/art.asp?articlekey=7143
        </a>
        <br />
        (14)
        <a
          target="_blank"
          href="https://www.theliteracybug.com/encoding-decoding-and-understanding/"
        >
          https://www.theliteracybug.com/encoding-decoding-and-understanding/
        </a>
        <br />
        (15)
        <a
          target="_blank"
          href="http://dyslexiahelp.umich.edu/professionals/dyslexia-school/phonological-awareness"
        >
          http://dyslexiahelp.umich.edu/professionals/dyslexia-school/phonological-awareness
        </a>
        <br />
        (16)
        <a
          target="_blank"
          href=""
        ></a
        >https://www.education.vic.gov.au/school/teachers/teachingresources/discipline/english/literacy/readingviewing/Pages/litfocusphonological.aspx
        <br />
        (17) Hill (2016, p.110)
        <br />
        (18) (Adams, 2011)
        <br />
        (19)
        <a
          target="_blank"
          href="https://stevenpinker.com/publications/sequential-processing-lexical-grammatical-and-phonological-information-within-br"
        >
          https://stevenpinker.com/publications/sequential-processing-lexical-grammatical-and-phonological-information-within-br
        </a>
        <br />
        (20)
        <a
          target="_blank"
          href="https://www.maude-leroux.com/the-reading-process-and-timing/"
        >
          https://www.maude-leroux.com/the-reading-process-and-timing/
        </a>
        <br />
        (21)
        <a
          target="_blank"
          href="https://www.researchgate.net/scientific-contributions/38172323_Keith_Rayner"
        >
          https://www.researchgate.net/scientific-contributions/38172323_Keith_Rayner
        </a>
        <br />
        (22)
        <a
          target="_blank"
          href="https://pure.royalholloway.ac.uk/portal/en/publications/comparing-and-validating-methods-of-reading-instruction-using-behavioural-and-neural-findings-in-an-artificial-orthography(11dc627f-1ea3-4e1e-9048-0928c0ff2e38).html"
        >
          https://pure.royalholloway.ac.uk/portal/en/publications/comparing-and-validating-methods-of-reading-instruction-using-behavioural-and-neural-findings-in-an-artificial-orthography(11dc627f-1ea3-4e1e-9048-0928c0ff2e38).html
        </a>
        <br />
        (23)
        <a
          target="_blank"
          href="https://www.semanticscholar.org/topic/Dyslexia/10991"
        >
          https://www.semanticscholar.org/topic/Dyslexia/10991
        </a>
        <br />
        (24)
        <a
          target="_blank"
          href="https://s3.amazonaws.com/academia.edu.documents/46517859/bf0264808620160615-22179-fb6l3c.pdf?AWSAccessKeyId=AKIAIWOWYYGZ2Y53UL3A&Expires=1540063218&Signature=OOFYin4k5dTV0su%2Bxm3Bw%2FR1uEo%3D&response-content-disposition=inline%3B%20filename%3DPhonological_Awareness_Training_and_Reme.pdf"
        >
          https://s3.amazonaws.com/academia.edu.documents/46517859/bf0264808620160615-22179-fb6l3c.pdf?AWSAccessKeyId=AKIAIWOWYYGZ2Y53UL3A&Expires=1540063218&Signature=OOFYin4k5dTV0su%2Bxm3Bw%2FR1uEo%3D&response-content-disposition=inline%3B%20filename%3DPhonological_Awareness_Training_and_Reme.pdf
        </a>
        <br />
        (25)
        <a
          target="_blank"
          href="https://dyslexiaida.org/morphology-101/"
        >
          https://dyslexiaida.org/morphology-101/
        </a>
        <br />
        (26)
        <a
          target="_blank"
          href="https://dyslexiaida.org/morphological-awareness-one-piece-of-the-literacy-pie/"
        >
          https://dyslexiaida.org/morphological-awareness-one-piece-of-the-literacy-pie/
        </a>
        <br />
        (27)
        <a
          target="_blank"
          href="https://www.lexiconreadingcenter.org/what-is-multisensory-teaching-techniques/"
        >
          https://www.lexiconreadingcenter.org/what-is-multisensory-teaching-techniques/
        </a>
        <br />
        (28)
        <a
          target="_blank"
          href="https://www.researchgate.net/publication/290492746_So_Much_to_Read_So_Little_Time_How_Do_We_Read_and_Can_Speed_Reading_Help"
        >
          https://www.researchgate.net/publication/290492746_So_Much_to_Read_So_Little_Time_How_Do_We_Read_and_Can_Speed_Reading_Help
        </a>
        <br />
        (29)
        <a
          target="_blank"
          href="http://www.readingrockets.org/article/phonics-instruction-value-multi-sensory-approach"
        >
          http://www.readingrockets.org/article/phonics-instruction-value-multi-sensory-approach
        </a>
      </p>
    </div>
  </div>
</app-public-layout>
