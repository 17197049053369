import { Injectable } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import * as Auth0Lock from 'auth0-lock';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../../../environments/environment';
import { User, Access, Account } from '../../shared/models/user.model';
import { LoggerService } from '../logger/logger.service';
import { PortalTypeService } from '../portal/portal-type.service';
import {
  b2cSignUpFields,
  b2cSignUp,
  organizationSignUp,
  loginOptions,
  AuthenticationTypes,
  domainUrl,
} from './auth-constants';
import { RenewTokenDialog } from './renew-token-dialog/renew-token-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthErrorDescriptions, AuthErrors, PortalConfig } from './auth-consts/auth-consts';
import { get } from 'lodash';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { UserType } from 'src/app/shared/interfaces';
import { filter } from 'rxjs';
import { Organization } from 'src/app/core/openapi';

// CONSTS
const REDIRECTION_URL = 'redirectionUrl';

/**
 * Root Auth Service.
 *
 * This service provides the application with one place that keeps track of
 * the authentication of the current user.
 *
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private lock: Auth0Lock;
  private signUpLock = undefined;
  public accessToken;
  public idToken;
  public profile;
  public user: User;
  public access: Access;
  public orgAcc: User;
  public authStateChange: EventEmitter<any>;
  public timeBeforeAlert = 5 * 60000; // 5 minutes;
  public renewTokenWarningTimeOut: ReturnType<typeof setTimeout>;
  public promotionalDialogViewed = false;
  public activeConfig: PortalConfig = PortalConfig.DEFAULT;

  constructor(
    private http: HttpClient,
    private router: Router,
    private cookies: CookieService,
    private translate: TranslateService,
    private logger: LoggerService,
    private portalType: PortalTypeService,
    private dialog: MatDialog,
    public auth0: Auth0Service,
  ) {
    this.authStateChange = new EventEmitter();

    this.isAuthenticated();

    const fromSubdomain = window.location.href.split('&from=');

    if (fromSubdomain.length > 1) {
      this.cookies.set(REDIRECTION_URL, fromSubdomain[1], null, null, environment.ROOT_DOMAIN_URL);
    }
  }

  public setOrgSubdomain(name: string) {
    this.cookies.set('name', name);
  }

  public shouldOpenAuth0ErrorWarning(error: string): boolean {
    const errorMessage = get(error, 'error', '');
    const errorDescription = get(error, 'errorDescription', '');

    if (errorMessage === AuthErrors.accessDenied) {
      return true;
    } else if (
      errorMessage === AuthErrors.serverError &&
      errorDescription === AuthErrorDescriptions.unableToConfigurePage
    ) {
      return true;
    }

    return false;
  }

  /**
   * Save accessToken, idToken, and profile in local storage.
   */
  public fillLoggedInfo(authResult, profile): void {
    localStorage.setItem('accessToken', authResult.accessToken);
    localStorage.setItem('idToken', authResult.idToken);
    localStorage.setItem('profile', JSON.stringify(profile));
    localStorage.setItem('expire_at', JSON.stringify(authResult.idTokenPayload.exp * 1000));

    this.accessToken = authResult.accessToken;
    this.idToken = authResult.idToken;
    this.profile = profile;
    this.authStateChange.emit();
    this.redirectUser();
  }

  public openRenewTokenDialogWarning() {
    const dialog = this.dialog.open(RenewTokenDialog, {
      data: {
        timeToExpire: 60000 * 3,
      },
      panelClass: 'modal-border',
      width: '400px',
      height: '250px',
      disableClose: true,
    });

    dialog.afterClosed().subscribe((shouldRenewToken) => {
      if (shouldRenewToken) {
        clearTimeout(this.renewTokenWarningTimeOut);
        this.renewTokenWarningTimeOut = undefined;

        this.auth0
          .getAccessTokenSilently()
          .pipe((token) => token)
          .subscribe((token) => {
            localStorage.setItem('accessToken', token);

            this.accessToken = token;
          });

        this.auth0.idTokenClaims$
          .pipe((idToken) => idToken)
          .subscribe(async (idToken) => {
            localStorage.setItem('idToken', idToken.__raw);
            localStorage.setItem('expire_at', JSON.stringify(idToken.exp * 1000));

            this.idToken = idToken.__raw;
            const newAlert = Math.max(0, idToken.exp * 1000 - this.timeBeforeAlert);
            this.renewTokenWarningTimeOut = setTimeout(() => this.openRenewTokenDialogWarning(), newAlert - Date.now());
          });
      } else {
        this.logout();
      }
    });
  }

  /**
   * Display lock widget.
   */
  public async show() {
    const options = loginOptions();
    this.auth0.loginWithRedirect(options);
  }

  /**
   * Gets the current organization account.
   * @remarks Should this be moved into a service?
   */
  public getOrgAcc() {
    return this.orgAcc;
  }

  public checkTokenExpiration() {
    if (!this.renewTokenWarningTimeOut) {
      clearTimeout(this.renewTokenWarningTimeOut);
      const expireAt = localStorage.getItem('expire_at');

      if (parseInt(expireAt) > Date.now()) {
        const timeToAlert = Math.max(0, Number(expireAt) - this.timeBeforeAlert);
        this.renewTokenWarningTimeOut = setTimeout(() => this.openRenewTokenDialogWarning(), timeToAlert - Date.now());
      }
    }
  }

  /**
   * Gets the current user
   * @remarks Should we create a User Service?
   */
  public async getUser(forceUpdate = false): Promise<any> {
    if (!this.idToken) {
      return Promise.resolve();
    }

    this.checkTokenExpiration();

    if (this.user && !forceUpdate) {
      return Promise.resolve(this.user);
    } else {
      let headers = new HttpHeaders().set('Content-Type', 'application/json');
      headers = headers.append('Authorization', 'Bearer ' + this.idToken);
      headers = headers.append('log', 'true');

      return this.http
        .get(environment.API_URL + '/user/self', { headers })
        .toPromise()
        .then((response: any) => {
          this.user = response ? response.user : undefined;
          this.access = response ? response.account : undefined;
          this.orgAcc = response ? response.orgAcc : undefined;

          // Unique Identifier for clarity
          (window as any)?.clarity?.('identify', this.user.id);
          this.logger.info('User loggedIn');

          localStorage.setItem('LSUserType', this._getUserType(this.user));

          if (this.user.patron && this.user.patron.givenName) {
            this.profile.displayName = this.user.patron.givenName;
          } else if (this.user.organization && this.user.organization.name) {
            this.profile.displayName = this.user.organization.name;
          }

          this.updateLanguage();
          this.updateConfig(response);

          if (this.profile.displayName) {
            localStorage.setItem('profile', JSON.stringify(this.profile));
          }

          return Promise.resolve(this.user);
        })
        .catch(() => {
          if (this.accessToken) {
            this.logout();
          } else {
            this.router.navigate(['/']);
          }
        });
    }
  }

  /**
   * Updates the language based on whether the user is a patron or an organization.
   */
  private updateLanguage() {
    if (this.user.patron) {
      this.translate.setDefaultLang(this.user.patron.language);
    } else if (this.user.organization) {
      this.translate.setDefaultLang(this.user.organization.language);
    }
  }

  public updateConfig(response: User) {
    const organization: Organization = get(response, 'orgAcc.organization', undefined);

    if (!organization) {
      return;
    }

    const isSchool = organization.isSchool;

    switch (isSchool) {
      case true:
        this.activeConfig = PortalConfig.SCHOOL;
        break;

      default:
        this.activeConfig = PortalConfig.DEFAULT;
        break;
    }
  }

  /**
   * Get profile.
   * @question Is this something that could be in a service?
   */
  public getProfile() {
    return this.profile;
  }

  /**
   * Logs the current user out.
   */
  public logout() {
    if (this.renewTokenWarningTimeOut) {
      clearTimeout(this.renewTokenWarningTimeOut);
      this.renewTokenWarningTimeOut = undefined;
    }

    const protocol = window.location.protocol;

    this.auth0.logout({
      logoutParams: {
        returnTo: protocol + '//' + environment.ROOT_DOMAIN_URL,
      },
    });

    // Clean up local storage
    localStorage.removeItem('accessToken');
    localStorage.removeItem('idToken');
    localStorage.removeItem('profile');
    localStorage.removeItem('LSUserType');
    localStorage.removeItem('expire_at');
    localStorage.removeItem('isSessionActive');

    // Emit an unauthenticated event
    this.authStateChange.emit('unauthenticated');

    // Delete cookies
    this.cookies.delete('authResult', null, environment.ROOT_DOMAIN_URL);
    this.cookies.delete('profile', null, environment.ROOT_DOMAIN_URL);
    this.cookies.delete('name', null, environment.ROOT_DOMAIN_URL);
  }

  /**
   * Check if current user is authenticated
   * @remkarks returns true if the user has an accessToken, a profile, and an idToken
   */
  public isAuthenticated(): boolean {
    // Check if access token is correct
    if (this.accessToken !== localStorage.getItem('accessToken')) {
      this.authStateChange.emit();
    }

    // Grab details from local storage
    this.idToken = localStorage.getItem('idToken');
    this.accessToken = localStorage.getItem('accessToken');
    this.profile = localStorage.getItem('profile') ? JSON.parse(localStorage.getItem('profile')) : null;

    // Return true only if all details are present
    return this.accessToken && this.profile && this.idToken;
  }

  /**
   * Check if the user is a patron.
   * @question Could this be put in a User Service?
   */
  public async isPatron(): Promise<boolean> {
    await this.getUser();
    if (this.access) {
      return this.access.role === 'Client' || this.access.level === 'B2C';
    }
    const userType = localStorage.getItem('LSUserType');
    return userType && userType === 'patron';
  }

  /**
   * Check if the user is a portal admin
   * @question Could this be put in a User Service?
   */
  public async isPortalAdmin(): Promise<boolean> {
    if (!this.user) {
      await this.getUser();
    }
    return this.access.role === 'Portal Owner';
  }

  /**
   * Redirects user.
   */
  public async redirectUser(): Promise<void> {
    this.getUser()
      .then(async (user: User) => {
        if (user && user.passwordUpdated === false) {
          this.router.navigate(['register'], { queryParams: { action: 'Password' } });
        } else if (user && user.tokenId) {
          switch (this.access.role) {
            case 'Client':
              this.router.navigate(['students']);
              break;
            case 'Student':
              this.router.navigate(['program/' + user.student.id + '/Neuralign/']);
              break;
            default:
              this.router.navigate(['users']);
              break;
          }
        } else if (!user && this.accessToken) {
          this.logout();
        }
      })
      .catch((err) => {
        this.logger.error(err);
      });
  }

  /**
   * Gets user's type, either patron or organization.
   */
  private _getUserType(user: User): string {
    return user && user.patron ? 'patron' : 'organization';
  }

  public async showSignUpLock(userType: UserType) {
    switch (userType) {
      case UserType.B2C:
        // Ensure that the user is on our root domain url so auth0 dont block the cookies
        window.location = ('//' +
          environment.ROOT_DOMAIN_URL +
          domainUrl +
          '/?b2csignup=true&subdomain=' +
          this.portalType.getPortalOwner.subdomain) as string & globalThis.Location;
        break;
      case UserType.Organization:
        this.auth0.loginWithRedirect(organizationSignUp());
        break;

      default:
        break;
    }
  }

  public triggerB2cSignUp(org: Organization, email: string) {
    this.auth0.loginWithRedirect(b2cSignUp(org, email));
  }

  public async validateAuth0Session(verify_type = '', authenticationType = '') {
    // On the b2c flow we need to create the user after the signup, and if we do we need to ensure we will redirect the user only after the user creation
    let newUserCreated = false;

    this.auth0.user$
      .pipe((user) => user)
      .subscribe(async (user) => {
        localStorage.setItem('profile', JSON.stringify(user));

        this.profile = user;
      });

    this.auth0
      .getAccessTokenSilently()
      .pipe((token) => token)
      .subscribe((token) => {
        localStorage.setItem('accessToken', token);

        this.accessToken = token;
      });

    this.auth0.idTokenClaims$
      // Ensure that the idToken is not null
      .pipe(filter((idToken) => !!idToken))
      .subscribe(async (idToken) => {
        localStorage.setItem('idToken', idToken.__raw);
        localStorage.setItem('expire_at', JSON.stringify(idToken.exp * 1000));

        this.idToken = idToken.__raw;

        // if the user is a b2c we need to create the account after the first sign up
        if (authenticationType === AuthenticationTypes.SIGNUP && verify_type && !newUserCreated) {
          newUserCreated = true;
          await this.createUserBySignUp(verify_type, this.idToken);
          this.redirectUser();
        } else if (!newUserCreated) {
          this.redirectUser();
        }
      });
  }

  public async createUserBySignUp(verify_type: string, idToken: string) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.append('Authorization', 'Bearer ' + idToken);
    headers = headers.append('log', 'true');

    return this.http
      .post(environment.API_URL + '/user/signup/' + this.profile.sub + '/' + verify_type, {
        headers,
      })
      .toPromise()
      .then((response: Account) => {
        return Promise.resolve(response);
      })
      .catch(() => {
        if (this.accessToken) {
          this.logout();
        }
      });
  }

  public buildSignUpLock() {
    const protocol = window.location.protocol;
    const domainUrl = environment.ROOT_DOMAIN_URL;
    const url = protocol + '//' + domainUrl + '/authCallback';

    return new Auth0Lock.Auth0Lock(environment.AUTH0_CLIENT_ID, environment.AUTH0_DOMAIN, {
      allowSignUp: true,
      allowLogin: false,
      allowedConnections: ['Username-Password-Authentication'],
      auth: {
        responseType: 'token id_token',
        scope: 'openid user_metadata',
        redirect: true,
        redirectUrl: url,
      },
      languageDictionary: {
        passwordInputPlaceholder: 'create your password',
        signUpTerms:
          "&nbsp;By signing up, you agree to our <a href='/terms-of-service' target='_new'>terms of service</a> and <a href='/privacy-policy' target='_new'>privacy policy</a>. <br><br> <div><b>You must use Firefox or Chrome browser.</b></div>",
      },
      theme: {
        logo: './assets/img/NeurAlign main logo.png',
        primaryColor: '#3A3372',
      },
      additionalSignUpFields: b2cSignUpFields(this.portalType.getPortalOwner),
    });
  }

  // Logic to check if the user is logging in for the first time in day
  public checkIsSessionActive() {
    const isSessionActive = localStorage.getItem('isSessionActive');
    if (this.isAuthenticated && !isSessionActive) {
      localStorage.setItem('isSessionActive', 'true');
      return true;
    }

    return false;
  }
}
