import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PricingComponent } from './pricing.component';
import { PricingCardComponent } from './components/pricing-card/pricing-card.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LayoutsModule } from 'src/app/shared/layouts/layouts.module';

@NgModule({
  declarations: [PricingComponent, PricingCardComponent],
  imports: [BrowserModule, RouterModule, FontAwesomeModule, LayoutsModule],
  providers: [AuthService],
})
export class PricingModule {}
