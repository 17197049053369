import { PricingBundle } from './interfaces/pricing-bundle.interface';

const basicFeatures = ['Web Based', 'Placement Test', '40+ hours of engaging, research-based games', 'Progress Graphs'];

const fullFeatures = [
  'Web Based',
  'Online Training',
  'Placement Test',
  '40+ hours of engaging, research-based games',
  'Progress Graphs',
  'Access to admin portal',
  'Option to pick levels',
  'Option to customize flow',
  'Create organization managers',
];

export const pricingBundles: PricingBundle[] = [
  {
    id: '0',
    title: 'Home',
    price: 2500,
    includedFeatures: basicFeatures,
  },
  {
    id: '1',
    isProBundle: true,
    title: 'Basic Pro',
    price: 1500,
    includedFeatures: fullFeatures,
    studentsIncluded: 5,
  },
  {
    id: '2',
    isProBundle: true,
    title: 'Pro',
    price: 2000,
    includedFeatures: fullFeatures,
    studentsIncluded: 10,
  },
  {
    id: '3',
    isProBundle: true,
    title: 'Super Pro',
    price: 3000,
    includedFeatures: fullFeatures,
    studentsIncluded: 20,
  },
];
