<app-main-layout>
  <div class="inner-content">
    <h3 align="center">{{ title }}</h3>

    <form
      [formGroup]="formGroup"
      class="col-12"
    >
      <mat-form-field
        appearance="outline"
        class="col-8"
      >
        <mat-label>Title</mat-label>
        <input
          matInput
          required
          placeholder="Title"
          formControlName="title"
        />
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        class="col-4"
      >
        <mat-label>Type</mat-label>
        <mat-select
          placeholder="Type"
          formControlName="type"
        >
          <mat-option
            *ngFor="let type of typeList"
            [value]="type"
            >{{ type }}</mat-option
          >
        </mat-select>
      </mat-form-field>

      <div class="col-12 text-area">
        <button
          type="button"
          mat-mini-fab
          color="warn"
          (click)="addYoutube()"
          title="Add Youtube Video"
        >
          <fa-icon [icon]="youtubeIcon"></fa-icon>
        </button>
        <br />
        <br />

        <mat-form-field appearance="outline">
          <mat-label>Update</mat-label>
          <textarea
            matInput
            required
            formControlName="description"
            rows="5"
          ></textarea>
        </mat-form-field>
      </div>
      <br />

      <div
        class="col-12"
        align="right"
      >
        <button
          class="btn-save"
          mat-raised-button
          color="primary"
          type="button"
          [disabled]="!formGroup.valid"
          (click)="saveForm(formGroup.value)"
        >
          {{ isUpdate ? 'Save' : 'Create' }}
        </button>
      </div>
    </form>
  </div>
</app-main-layout>
