import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';

@Injectable()
export class SignupCompletionGuard {
  constructor(private _auth: AuthService, private _router: Router) {}

  public noTokenAllowedPaths = ['programs-pricing', 'purchase-tokens/:bundleId'];
  public pendingRegisterAllowedPaths = ['organization-update'];

  async canActivate(): Promise<boolean> {
    const user = await this._auth.getUser();

    if (!user) {
      this._router.navigate(['']);
      return false;
    }

    // if user registration is not pending to complete, send it back to the main page

    if (!user.pendingRegistration) {
      this._router.navigate(['/']);
    }

    return true;
  }
}
