<div #wrapper>
  <h2
    montserrat
    extraLargeSize
    primaryColour
    thin
    centerText
  >
    Neuralign provides <br />
    game-changing solutions.
  </h2>

  <div #graph>
    <img
      src="../../../../../assets/img/slides/2-grades-Chart-2.webp"
      width="100%"
    />
  </div>

  <p
    montserrat
    largeSize
    primaryColour
    thin
    centerText
  >
    Our technology is award winning
  </p>

  <div #awards>
    <div>
      <img
        src="../../../../../assets/img/awards/flourish-prize-finalists.webp"
        width="100%"
      />
    </div>
    <div>
      <img
        src="../../../../../assets/img/awards/mindshare.webp"
        width="100%"
      />
    </div>
    <div>
      <img
        src="../../../../../assets/img/awards/lead-to-win.webp"
        width="100%"
      />
    </div>
    <div>
      <img
        src="../../../../../assets/img/awards/codie-awards.webp"
        width="100%"
      />
    </div>
    <div>
      <img
        src="../../../../../assets/img/awards/ges-awards-finalist.webp"
        width="100%"
      />
    </div>
    <div>
      <img
        src="../../../../../assets/img/awards/tech-edvocate-awards.webp"
        width="100%"
      />
    </div>
  </div>
</div>
