import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SectionTypes } from '../utils/course-utils';
import { Course } from 'src/app/pages/configuration-pages/interfaces/global-config.interfaces';

interface EditorData {
  course: Course;
  lessonId: string;
}

@Component({
  selector: 'app-section-editor',
  templateUrl: './section-editor.component.html',
  styleUrls: ['./section-editor.component.scss'],
})
export class SectionEditor implements OnInit {
  public courses = [];
  public loading = true;

  public sectionTypes = SectionTypes;
  public sectionTitle: string;
  public sectionType: string;

  @Output() reset = new EventEmitter();

  constructor(
    public rest: RestAPIService,
    public snack: MatSnackBar,
    public confirm: ConfirmationService,
    private dialogRef: MatDialogRef<SectionEditor>,
    @Inject(MAT_DIALOG_DATA) public editorData: EditorData,
  ) {}

  async ngOnInit() {}

  public getSectionTypes() {
    return Object.keys(this.sectionTypes);
  }

  public shouldEnableButton(): boolean {
    return this.sectionTypes != undefined && this.sectionType != undefined;
  }

  public async saveSection() {
    try {
      const newSection = {
        title: this.sectionTitle,
        type: this.sectionType,
        draft: true,
      };

      const createdSection = await this.rest.post(
        `/course/${this.editorData.course.id}/lesson/${this.editorData.lessonId}/addSection`,
        newSection,
      );

      if (createdSection) {
        this.dialogRef.close(createdSection);
      }
    } catch (error) {
      this.snack.open('Could not create this section!', 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }
  }
}
