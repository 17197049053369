import { Injectable } from '@angular/core';
import { PricingBundle } from '../../pricing/interfaces/pricing-bundle.interface';

@Injectable({
  providedIn: 'root',
})
export class SelectBundleService {
  constructor() {}

  public selectedBundle: PricingBundle;
  public bundleList: PricingBundle[] = [];

  public monthlyBundleList: PricingBundle[] = [];
  public yearlyBundleList: PricingBundle[] = [];
}
