import { Component, OnInit } from '@angular/core';
import { PricingBundle } from '../../../pricing/interfaces/pricing-bundle.interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import {
  b2cMonthlyBundle,
  b2cWeeklyBundle,
  defaultB2CBundles,
} from '../../../pricing/default-readls-bundles/default-readls-bundles.constants';
import { get } from 'lodash';
import { faShoppingCart, faSpinner, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { SelectBundleService } from '../../services/select-bundle.service';
import { Router } from '@angular/router';
import { testimonials } from './testimonials';
import { B2cPaymentTypes } from '../../../users/components/adjust-b2c-prices/constants/b2c-prices.constants';
import { cloneDeep } from 'lodash';
import { Organization } from 'src/app/core/openapi';

@Component({
  selector: 'pricing-b2c',
  templateUrl: './b2c-programs-pricing.component.html',
  styleUrls: ['./b2c-programs-pricing.component.scss'],
})
export class B2CProgramsPricingComponent implements OnInit {
  public cartIcon: IconDefinition = faShoppingCart;
  public bundle: PricingBundle = defaultB2CBundles;
  public monthlyBundleOption = b2cMonthlyBundle;
  public weeklyBundleOption = b2cWeeklyBundle;
  public showBundle = false;
  public allowSplitPayment = false;
  private MONTHLY_SUBSCRIPTION_PERIOD = 3;
  private WEEKLY_SUBSCRIPTION_PERIOD = 12;
  private MONTHLY_INSTALLMENT_PERIOD = 12;
  private YEARLY_INSTALLMENT_PERIOD = 1;
  private splitPaymentAdditionalCost = 0.2; // 20%
  public priceBundles = [];
  public testimonials = testimonials;
  public paymentType = B2cPaymentTypes.SinglePayment;
  public readonly spinnerIcon: IconDefinition = faSpinner;
  public fullPrice = 1500;

  public loading = true;

  constructor(
    public _authService: AuthService,
    public _rest: RestAPIService,
    public bundleService: SelectBundleService,
    private _router: Router,
  ) {}

  public async ngOnInit() {
    const user = await this._authService.getUser();
    if (user.patron.orgId) {
      const res = await this._rest.get('organization/self', { msg: 'Could not get organization.' });
      this.bundle.price = res.organization.b2cTokenPrice ? res.organization.b2cTokenPrice : this.bundle.price;
      this.bundle.currency = res.organization.b2cCurrency ? res.organization.b2cCurrency : this.bundle.currency;
      this.getPaymentType(res.organization);

      switch (this.paymentType) {
        case B2cPaymentTypes.SplittedPayment:
          this.setRecurringPaymentInfo();
          this.priceBundles = [this.weeklyBundleOption, this.bundle, this.monthlyBundleOption];
          break;
        case B2cPaymentTypes.MonthlyInstallmentPayment:
          this.priceBundles = [this.getMonthlyBundle(this.bundle), this.getYearlyBundle(this.bundle)];
          break;
        default:
          this.priceBundles = [this.bundle];
          break;
      }

      this.showBundle = true;

      this.loading = false;
    }
  }

  public getMonthlyBundle(bundle: PricingBundle) {
    // Clone the original bundle to create the monthly version
    const monthlyBundle = cloneDeep(bundle);
    monthlyBundle.paymentType = B2cPaymentTypes.MonthlyInstallmentPayment;
    monthlyBundle.subscription = true;
    monthlyBundle.interval = 'month';
    monthlyBundle.title = 'Monthly';
    monthlyBundle.subscriptionPeriod = this.MONTHLY_INSTALLMENT_PERIOD;

    return monthlyBundle;
  }

  public getYearlyBundle(bundle: PricingBundle) {
    // Clone the original bundle to create the yearly version
    const yearlyBundle = cloneDeep(bundle);
    yearlyBundle.paymentType = B2cPaymentTypes.MonthlyInstallmentPayment;
    yearlyBundle.subscription = true;
    yearlyBundle.interval = 'year';
    yearlyBundle.title = 'Yearly';
    yearlyBundle.subscriptionPeriod = this.YEARLY_INSTALLMENT_PERIOD;
    yearlyBundle.price = yearlyBundle.price * 12;

    return yearlyBundle;
  }

  public getPaymentType(organization: Organization) {
    if (!organization.b2cAllowedPaymentTypes) {
      organization.b2cAllowedPaymentTypes = [B2cPaymentTypes.SinglePayment, B2cPaymentTypes.SplittedPayment];
    }

    if (!organization.b2cPaymentType) {
      this.paymentType = get(organization, 'allowB2cSplitPayments', false)
        ? B2cPaymentTypes.SplittedPayment
        : B2cPaymentTypes.SinglePayment;
    } else {
      const isPaymentTypeAllowed = organization.b2cAllowedPaymentTypes.includes(organization.b2cPaymentType);

      if (isPaymentTypeAllowed) {
        this.paymentType = organization.b2cPaymentType as B2cPaymentTypes;
      } else {
        this.paymentType = B2cPaymentTypes.SinglePayment;
      }
    }
  }

  public setRecurringPaymentInfo() {
    this.bundle.interval = 'One time payment';

    this.monthlyBundleOption = Object.assign(
      {},
      this.monthlyBundleOption,
      this.buildRecurringPaymentBundle(this.MONTHLY_SUBSCRIPTION_PERIOD),
    );

    this.weeklyBundleOption = Object.assign(
      {},
      this.weeklyBundleOption,
      this.buildRecurringPaymentBundle(this.WEEKLY_SUBSCRIPTION_PERIOD),
    );
  }

  public buildRecurringPaymentBundle(period: number) {
    const fullPrice = this.bundle.price + this.bundle.price * this.splitPaymentAdditionalCost;

    return {
      price: fullPrice / period,
      subscriptionPeriod: period,
      currency: this.bundle.currency,
    };
  }

  public addToCart(bundle) {
    this.bundleService.selectedBundle = bundle;
    this._router.navigate(['purchase-tokens/' + this.bundle.id], { queryParams: { ...this.bundle } });
  }

  public getPaymentsAmount(bundle: PricingBundle) {
    const period = bundle.subscriptionPeriod;
    const amountMessage = period === 1 ? 'One time payment' : `${period} equal payments*`;

    return amountMessage;
  }

  public triggerInstallmentWarning(): PricingBundle | null {
    const installmentOption = this.priceBundles.find(
      (b: PricingBundle) => b.paymentType === B2cPaymentTypes.MonthlyInstallmentPayment,
    );

    return installmentOption;
  }

  public isInstallmentPayment(bundle: PricingBundle): boolean {
    return bundle.paymentType === B2cPaymentTypes.MonthlyInstallmentPayment;
  }

  public isSplittedPayment(bundle: PricingBundle): boolean {
    return bundle.paymentType === B2cPaymentTypes.SplittedPayment;
  }

  public getDiscountPrice(bundle: PricingBundle): number {
    if (!bundle) {
      return 0;
    }

    const savingAmount = this.fullPrice - bundle.price;

    return Math.floor((savingAmount / this.fullPrice) * 100);
  }
}
