import { environment } from 'src/environments/environment';
import {
  UNITY_LOADER_URL,
  UNITY_DATA_URL,
  UNITY_FRAMEWORK_URL,
  UNITY_CODE_URL,
  UNITY_ASSET_BUNDLE_PATH,
  PHONEMEFAIR_LOADER,
  PHONEMEFAIR_DATA,
  PHONEMEFAIR_FRAMEWORK,
  PHONEMEFAIR_WASM,
} from './game.constants';

export const getUnityConfig = () => {
  return {
    loaderUrl: `${environment.WEBGL_BASE_URL}${UNITY_LOADER_URL}`,
    dataUrl: `${environment.WEBGL_BASE_URL}${UNITY_DATA_URL}`,
    frameworkUrl: `${environment.WEBGL_BASE_URL}${UNITY_FRAMEWORK_URL}`,
    codeUrl: `${environment.WEBGL_BASE_URL}${UNITY_CODE_URL}`,
    streamingAssetsUrl: `${environment.WEBGL_BASE_URL}${UNITY_ASSET_BUNDLE_PATH}`,
  };
};

export const getPhonemefairUnityConfig = () => {
  return {
    loaderUrl: `${environment.WEBGL_PHONEMEFAIR_BASE_URL}${PHONEMEFAIR_LOADER}`,
    dataUrl: `${environment.WEBGL_PHONEMEFAIR_BASE_URL}${PHONEMEFAIR_DATA}`,
    frameworkUrl: `${environment.WEBGL_PHONEMEFAIR_BASE_URL}${PHONEMEFAIR_FRAMEWORK}`,
    codeUrl: `${environment.WEBGL_PHONEMEFAIR_BASE_URL}${PHONEMEFAIR_WASM}`,
    streamingAssetsUrl: undefined,
  };
};
