<div>
  <div class="header">Progress</div>

  <div class="mainContent">
    <div *ngIf="!isAssessmentProgress()">
      <table
        mat-table
        [dataSource]="dataSource"
        class="w-100"
      >
        <ng-container matColumnDef="game">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="text-center"
          >
            Game
          </th>
          <td
            mat-cell
            *matCellDef="let progress"
            class="text-center"
          >
            {{progress.name}}
          </td>
        </ng-container>

        <ng-container matColumnDef="level">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="text-center"
          >
            Level
          </th>
          <td
            mat-cell
            *matCellDef="let progress"
            class="text-center"
          >
            <select
              [(ngModel)]="progress.level"
              class="progressSelect"
              *ngIf="progress.level"
            >
              <option
                *ngFor="let studentLevel of studentLevels"
                value="{{ studentLevel.level | lowercase}}"
              >
                {{ studentLevel.level }}
              </option>
            </select>

            <div *ngIf="!progress.level">---</div>
          </td>
        </ng-container>

        <ng-container matColumnDef="score">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="text-center"
          >
            Score
          </th>
          <td
            mat-cell
            *matCellDef="let progress"
            class="text-center"
          >
            <input
              class="scoreInput"
              matInput
              type="number"
              min="0"
              step="1"
              [(ngModel)]="progress.score"
            />
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns;"
        ></tr>
      </table>

      <div class="d-flex justify-content-center my-4">
        <app-simple-button
          text="Save "
          (click)="save(data)"
          class="mx-2"
        ></app-simple-button>

        <app-simple-button
          text="Cancel"
          (click)="close()"
          class="mx-2"
        >
        </app-simple-button>
      </div>
    </div>

    <div *ngIf="isAssessmentProgress()">
      <table
        mat-table
        [dataSource]="dataSource"
        class="w-100"
      >
        <ng-container matColumnDef="game">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="text-center"
          >
            Game
          </th>
          <td
            mat-cell
            *matCellDef="let progress"
            class="text-center"
          >
            {{data.tag | titlecase}}
          </td>
        </ng-container>

        <ng-container matColumnDef="level">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="text-center"
          >
            Level
          </th>
          <td
            mat-cell
            *matCellDef="let progress"
            class="text-center"
          >
            <select
              [(ngModel)]="progress.level"
              class="progressSelect"
              *ngIf="progress.level"
            >
              <option
                *ngFor="let studentLevel of studentLevels"
                value="{{ studentLevel.assessmentLevel }}"
              >
                {{ returnStudentLevel(studentLevel.assessmentLevel) }}
              </option>
            </select>

            <div *ngIf="!progress.level">---</div>
          </td>
        </ng-container>

        <ng-container matColumnDef="score">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="text-center"
          >
            Score
          </th>
          <td
            mat-cell
            *matCellDef="let progress"
            class="text-center"
          >
            <input
              class="scoreInput"
              matInput
              type="number"
              min="0"
              step="1"
              [(ngModel)]="progress.score"
            />
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns;"
        ></tr>
      </table>

      <div class="d-flex justify-content-center my-4">
        <app-simple-button
          text="Save "
          (click)="save(data)"
          class="mx-2"
        ></app-simple-button>

        <app-simple-button
          text="Cancel"
          (click)="close()"
          class="mx-2"
        >
        </app-simple-button>
      </div>
    </div>
  </div>
</div>
