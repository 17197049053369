<div class="button-container">
  <main-control
    label="Previous Lesson"
    (click)="handle('prev-lesson')"
    [icon]="icons.prevLesson"
    [disabled]="isPrevLessonDisabled"
    tooltip="Go to the previous lesson"
    size="large"
  ></main-control>

  <main-control
    *ngIf="section.type === 'slides'"
    label="Previous Slide"
    (click)="handle('prev-slide')"
    [icon]="icons.prevItem"
    [disabled]="slide.isFirstSlide"
    tooltip="Previous slide"
  ></main-control>

  <main-control
    *ngIf="section.type === 'slides'"
    label="Next Slide"
    (click)="handle('next-slide')"
    [icon]="icons.nextItem"
    [disabled]="slide.isLastSlide"
    tooltip="Next slide"
  ></main-control>

  <main-control
    *ngIf="section.type === 'quiz'"
    label="Previous Question"
    (click)="handle('prev-question')"
    [icon]="icons.prevItem"
    [disabled]="quiz.isFirstQuestion"
    tooltip="Previous question"
  ></main-control>

  <main-control
    *ngIf="section.type === 'quiz'"
    label="Next Question"
    (click)="handle('next-question')"
    [icon]="icons.nextItem"
    [disabled]="isNextQuestionDisabled"
    tooltip="Next question"
  ></main-control>

  <main-control
    [label]="nextLessonButtonLabel"
    (click)="handle('next-lesson')"
    [icon]="icons.nextLesson"
    [disabled]="false"
    tooltip="Go to the next lesson"
    size="large"
  ></main-control>
</div>
