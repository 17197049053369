import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { get } from 'lodash';
import { createNewTheme, getDefaultLevels } from '../consts/theme.constants';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { validateTheme } from '../theme-validator/theme.validator';
import { CustomSnackBar } from 'src/app/shared/components/custom-snackbar/custom-snackbar.component';
import { ThemeCategory, ThemeLanguage } from '../interfaces/themes.interface';
import { Category, Language } from 'src/app/pages/configuration-pages/interfaces/global-config.interfaces';

@Component({
  selector: 'theme-creation-dialog',
  templateUrl: './theme-creation-dialog.component.html',
  styleUrls: ['./theme-creation-dialog.component.scss'],
})
export class ThemeCreationDialogComponent implements OnInit {
  public themeName: string;
  public themeVariableName: string;
  public languageList: Language[] = [];
  public categoriesList: Category[] = [];

  public enabledLanguages: ThemeLanguage[] = [];
  public enabledCategories: ThemeCategory[] = [];

  langDataSource: MatTableDataSource<any>;
  langDisplayedColumns: string[] = ['name', 'code', 'enabled'];

  categoriesDataSource: MatTableDataSource<any>;
  categoriesDisplayedColumns: string[] = ['name', 'enabled'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _rest: RestAPIService,
    private dialogRef: MatDialogRef<ThemeCreationDialogComponent>,
    protected _snackBar: MatSnackBar,
  ) {}

  public ngOnInit(): void {
    this.langDataSource = new MatTableDataSource(this.data.languages);
    this.categoriesDataSource = new MatTableDataSource(this.data.categories);
    this.languageList = this.data.languages;
    this.categoriesList = this.data.categories;
  }

  public returnLanguageLabel(id) {
    const language = this.languageList.find((l) => get(l, 'id') === id);

    if (language) {
      return language.languageCode;
    }
  }

  public addLanguage(language) {
    const lang = this.enabledLanguages.find((l) => l.languageId === language.id);

    if (!lang) {
      const newLang = {
        languageId: language.id,
        languageCode: language.languageCode,
        enabledLevels: getDefaultLevels(),
        name: language.name,
      };

      this.enabledLanguages.push(newLang);
    } else {
      const selectedLang = this.enabledLanguages.find((l) => l.languageId === language.id);
      const index = this.enabledLanguages.indexOf(selectedLang);
      this.enabledLanguages.splice(index, 1);
    }
  }

  public addNewCategory(category) {
    const enabledCategory = this.enabledCategories.find((c) => c.name === category.name);

    if (!enabledCategory) {
      const newCategory = {
        id: category.id,
        name: category.name,
        title: category.title,
      };

      this.enabledCategories.push(newCategory);
    } else {
      const selectedCategory = this.enabledCategories.find((c) => c.id === category.id);
      const index = this.enabledCategories.indexOf(selectedCategory);
      this.enabledCategories.splice(index, 1);
    }
  }

  public createTheme() {
    const newTheme = createNewTheme(this.themeName, this.enabledCategories, this.enabledLanguages);

    const { isValid, errors } = validateTheme(newTheme);

    if (isValid) {
      this._rest
        .post('themes/newTheme', { theme: newTheme }, { msg: 'Could not post theme.' })
        .then(() => {
          this._snackBar.open(`Theme created!`, 'Close', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });

          this.dialogRef.close(true);
        })
        .catch((err) => {
          this._snackBar.open('Could not create this theme ' + err.message, 'Close', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        });
    } else {
      // To be able to add all the errors in one single snack bar we create a custom component to handle this
      this._snackBar.openFromComponent(CustomSnackBar, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        data: errors,
      });
    }
  }

  public closeDialog() {
    this.dialogRef.close();
  }
}
