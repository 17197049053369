import { Component, OnInit } from '@angular/core';
import { faGlobe, faDollarSign, faCircleDollarToSlot } from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PricingBundle } from '../../pricing/interfaces/pricing-bundle.interface';
import { GlobalConfiguration } from '../interfaces/global-config.interfaces';

@Component({
  selector: 'app-region-configurations',
  templateUrl: './region-configurations.component.html',
  styleUrls: ['../configurations.style.scss'],
})
export class RegionConfigurationsComponent implements OnInit {
  public readonly reservedRegions: IconDefinition = faGlobe;
  public readonly taxesIcon: IconDefinition = faDollarSign;
  public readonly bundlesIcon: IconDefinition = faCircleDollarToSlot;

  public loading: boolean;
  public globalConfigurations: GlobalConfiguration;
  public bundleList: PricingBundle[];
  public selectedOption = 'reservedRegions';

  constructor(private _rest: RestAPIService, protected _snackBar: MatSnackBar) {}

  async ngOnInit() {
    this.loading = true;
    try {
      await this.getGlobalConfiguration();
    } catch (error) {
      // @TODO add warning
      console.log(error);
    } finally {
      this.loading = false;
    }
  }

  public async getGlobalConfiguration() {
    this.globalConfigurations = await this._rest.get('globalConfig/config', { msg: 'Could not get global config.' });
    this.bundleList = this.globalConfigurations.bundles;
  }

  public reset() {
    this.ngOnInit();
  }

  public setLoading = (loading: boolean) => {
    this.loading = loading;
  };
}
