<div class="mainContainer">
  <div class="header">Neuralign</div>

  <canvas
    #pdfCanvas
    hidden
  ></canvas>

  <div
    class="content d-flex justify-content-center align-items-center"
    *ngIf="!uploadingPDF"
  >
    <input
      (change)="onFileSelected($event)"
      type="file"
      id="file-input"
      accept=".pdf"
      style="display: none"
    />

    <button
      mat-raised-button
      class="mx-2"
      id="file-upload-button"
    >
      Add PDF
    </button>
    <button
      mat-raised-button
      class="mx-2"
      (click)="addImage()"
    >
      Add image
    </button>
  </div>

  <div
    *ngIf="uploadingPDF"
    class="uploadingPdf"
  >
    <div>
      <div>Uploading PDF pages</div>

      <div>{{ currentUploadPage }} / {{ pdfTotalPages }}</div>

      <br />

      <div>
        <fa-icon
          [icon]="spinnerIcon"
          [spin]="true"
        ></fa-icon>
      </div>
    </div>
  </div>
</div>
