import moment from 'moment';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { get } from 'lodash';

import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { faMinus, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Account } from 'src/app/shared/models';
import { AlertDialogComponent } from 'src/app/shared/dialogs/alert/alert.dialog';
import { Client } from 'src/app/shared/interfaces';
import { Organization } from 'src/app/core/openapi';
import { Coupon } from 'src/app/shared/interfaces/Coupon.interface';

interface Suggestions {
  name?: string;
  givenName?: string;
  familyName?: string;
}
interface ModalData {
  clients: Client[];
  organizations: Organization[];
}

@Component({
  selector: 'app-coupon-modal',
  templateUrl: './coupons-modal.component.html',
  styleUrls: ['./coupons-modal.component.scss'],
})
export class CouponModalComponent {
  public readonly minusLine: IconDefinition = faMinus;

  public couponForm: Coupon = {
    code: '',
    discountAmount: 0,
    discountPercentage: 0,
    limit: 0,
    expiration: new Date(),
  };

  // Modal Config
  public couponType: 'amount' | 'percentage' = 'amount';
  public suggestions: Suggestions[] = [];
  public usersToCoupon: Account[] = [];
  public clients: Client[] = [];
  public organizations: Organization[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModalData,
    public dialogRef: MatDialogRef<CouponModalComponent>,
    private _rest: RestAPIService,
    private dialog: MatDialog,
  ) {
    data?.clients ? (this.clients = data.clients) : (this.clients = []);
    data?.organizations ? (this.organizations = data.organizations) : (this.organizations = []);
  }

  public async save() {
    try {
      await this._rest.post('admin/coupon', {
        coupon: this.couponForm,
        users: this.usersToCoupon,
      });
      this.close(true);
    } catch (error) {
      this.dialog.open(AlertDialogComponent, {
        width: '400px',
        data: 'Error saving coupon' + error.message,
      });
    }
  }

  public close(status?: boolean) {
    const checkStatus = status ? status : false;
    this.dialogRef.close(checkStatus);
  }

  public handleTimestampToDate(timestamp: number) {
    const data = moment.unix(timestamp);
    const formattedDate = data.format('YYYY-MM-DD');
    return formattedDate;
  }

  public generateCouponCode() {
    const length = 8;
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ123456789';
    let result = '';
    while (result.length < length) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    this.couponForm.code = result;
  }

  public addUserCoupon(user) {
    if (this.usersToCoupon.some((u) => u === user)) {
      return;
    }
    this.usersToCoupon.push(user);
  }

  public removeUserCoupon(user) {
    this.usersToCoupon.splice(this.usersToCoupon.indexOf(user), 1);
  }

  public updateSuggestions(value) {
    if (value.length === 0) {
      return (this.suggestions = []);
    }
    value = value.toLowerCase();
    this.suggestions = [].concat(
      this.clients.filter(
        (v) =>
          (get(v, 'patron.givenName') && get(v, 'patron.givenName').toLowerCase().indexOf(value) >= 0) ||
          (get(v, 'patron.familyName') && get(v, 'patron.familyName').toLowerCase().indexOf(value) >= 0),
      ),
      this.organizations.filter(
        (v) => get(v, 'organization.name') && get(v, 'organization.name').toLowerCase().indexOf(value) >= 0,
      ),
    );
    this.suggestions.forEach((sug, index) => {
      if (this.usersToCoupon.some((u) => u === sug)) {
        this.suggestions.splice(index, 1);
      }
    });
  }
}
