import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { NotFoundComponent } from './404.component';
import { LayoutsModule } from 'src/app/shared/layouts/layouts.module';

@NgModule({
  declarations: [NotFoundComponent],
  imports: [BrowserModule, RouterModule, LayoutsModule],
})
export class NotFoundModule {}
