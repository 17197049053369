import { NgModule } from '@angular/core';
import { BreadcrumbModule } from '../components/breadcrumb/breadcrumb.module';
import { SideMenuModule } from '../../shared/components/side-menu/side-menu.module';
import { UserSideMenuModule } from '../../shared/components/user-side-menu/user-side-menu.module';

@NgModule({
  imports: [BreadcrumbModule, SideMenuModule, UserSideMenuModule],
  exports: [BreadcrumbModule, SideMenuModule, UserSideMenuModule],
})
export class PortalMenusModule {}
