import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'users-layout-two-tables',
  templateUrl: './two-tables.component.html',
  styleUrls: ['./two-tables.component.scss'],
})
export class TwoTablesComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
