import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutsModule } from 'src/app/shared/layouts/layouts.module';
import { LearningSectionPageComponent } from './learning-section.component';
import { CourseCompletionComponent } from './course-completion/course-completion.component';

import { MainViewModule } from './main-view/main-view.module';
import { GridComponent } from './grid/grid.component';
import { LearningSectionHeaderComponent } from './header/header.component';
import { CoursesSharedModule } from '../../shared/courses.shared.module';
import { CurtainModule } from 'src/app/shared/components/curtain/curtain.module';
import { LessonsListModule } from './lessons-list/lessons-list.module';
import { HeaderModule } from './main-view/header/header.module';
import { SafeUrlModule } from 'src/app/shared/pipes/safe-url/safe-url.module';
import { DisplayModule } from './main-view/display/display.module';

@NgModule({
  declarations: [
    LearningSectionPageComponent,
    LearningSectionHeaderComponent,
    CourseCompletionComponent,
    GridComponent,
  ],
  imports: [
    CommonModule,
    LayoutsModule,
    LessonsListModule,
    MainViewModule,
    CoursesSharedModule,
    CurtainModule,
    HeaderModule,
    SafeUrlModule,
    DisplayModule,
  ],
})
export class LearningSectionModule {}
