import { Component, Input } from '@angular/core';
import { Target } from '../../interfaces/target.interface';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCircle, faBullseye } from '@fortawesome/free-solid-svg-icons';
import { GameTarget } from '../../interfaces/game-target.interface';

@Component({
  selector: 'app-take-a-tour-game-target',
  template: ` <div class="game">
    <img [src]="game.imageUrl" />
    <div>
      <fa-icon
        *ngFor="let target of game.targets"
        [icon]="getIcon(target)"
        [style.color]="target.color"
      ></fa-icon>
    </div>
  </div>`,
  styleUrls: ['./game-target.component.scss'],
})
export class TakeATourGameTargetComponent {
  public circleIcon: IconDefinition = faCircle;
  public bullseyeIcon: IconDefinition = faBullseye;

  @Input() game: GameTarget;

  public getIcon(target: Target): IconDefinition {
    return target.iconType === 'circle' ? this.circleIcon : this.bullseyeIcon;
  }
}
