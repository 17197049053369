<app-main-layout
  [breadcrumbs]="[
    { name: 'Home', url: '/' },
    { name: 'Tutorials', url: '/tutorials' }
  ]"
>
  <app-before-you-start
    *ngIf="showBeforeYouStart"
    [isPopup]="true"
    [allowStart]="false"
    (onStart)="listStudents(); showBeforeYouStart = false"
    (close)="showBeforeYouStart = false"
  ></app-before-you-start>

  <app-tutorial-modal
    *ngIf="showTutorial"
    [callback]="onCompleteTutorial"
    [tutorial]="tutorial"
    [isSingle]="isSingle"
  ></app-tutorial-modal>

  <div class="inner-content">
    <h2>Tutorials</h2>

    <p>Click on a specific part of the Tutorial to open help for that page!</p>

    <div>
      <ul
        class="mb-0"
        *ngFor="let list of getTutorialList()"
      >
        <li>
          {{ list.section.toUpperCase() }}
        </li>

        <li
          class="tabbed"
          *ngFor="let tutorial of list.tutorials"
          (click)="openWizard(tutorial)"
        >
          {{ getTutorialName(tutorial) }}
        </li>
      </ul>
      <li
        class="mt-3"
        (click)="openBeforeYouStart()"
      >
        Before you start Neuralign
      </li>
      <li (click)="openFullWizard()">FULL TUTORIAL</li>
    </div>
  </div>
</app-main-layout>
