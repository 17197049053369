import { Component, Input } from '@angular/core';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'main-control',
  templateUrl: './control.component.html',
  styleUrl: './control.component.scss',
})
export class ControlComponent {
  @Input() label: string;
  @Input() icon: IconDefinition;
  @Input() disabled: boolean;
  @Input() tooltip: string = '';
  @Input() size: 'large' | 'default' = 'default';
}
