import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-three-card-holder',
  templateUrl: './three-card-holder.component.html',
  styleUrls: ['./three-card-holder.component.scss'],
})
export class ThreeCardHolderComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
