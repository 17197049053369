import { Component, Input } from '@angular/core';
import { Target } from '../../interfaces/target.interface';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCircle, faBullseye } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-take-a-tour-target',
  template: ` <div class="target">
    <fa-icon
      [icon]="getIcon()"
      [style.color]="target.color"
    ></fa-icon>
    <div>
      <p class="title">
        {{ target.title }}
      </p>
      <p
        class="description"
        [innerHtml]="target.description"
      ></p>
    </div>
  </div>`,
  styleUrls: ['./target.component.scss'],
})
export class TakeATourTargetComponent {
  public circleIcon: IconDefinition = faCircle;
  public bullseyeIcon: IconDefinition = faBullseye;

  @Input() target: Target;

  public getIcon(): IconDefinition {
    return this.target.iconType === 'circle' ? this.circleIcon : this.bullseyeIcon;
  }
}
