import { Injectable } from '@angular/core';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { CourseProgress } from '../../configuration-pages/interfaces/global-config.interfaces';
import { CourseCategoryContent, StudentProgressData } from '../program-interfaces/program-interfaces';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class VideoCategoryService {
  constructor(private rest: RestAPIService, private _snackBar: MatSnackBar) {}

  public async saveVideoCategoryProgress(
    progressData: CourseProgress,
    content: CourseCategoryContent,
    sectionId: string,
  ) {
    await this.rest.put(
      'courseProgress/' + progressData.id + '/lesson/' + content.id + '/completeSection/' + sectionId,
      {},
    );
  }

  public async getVideoCategoryProgress(courseId: string, studentId: string) {
    try {
      const data: StudentProgressData = await this.rest.get(
        '/course/courseId/' + courseId + '/progressData/studentId/' + studentId,
      );

      if (!data) {
        throw new Error('Category data not found');
      }

      return data;
    } catch (error) {
      this._snackBar.open(error.messgae, 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }
  }
}
